import { Component } from '@angular/core';

@Component({
  selector: 'app-claim-history-page',
  template: `
    <app-section>
      <app-claim-breadcrumb></app-claim-breadcrumb>
      <app-claim-history class="tw-w-full"></app-claim-history>
    </app-section>
  `,
  styles: [],
})
export class ClaimHistoryPageComponent {}
