import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, SearchResult } from 'src/app/shared';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-claim-header',
  template: `
    <div class="tw-w-full tw-flex tw-flex-wrap">
      <div class="tw-w-full md:tw-w-[40%]">
        <app-claimant-label
          value
          [claimant]="summary"
          [showNameLabel]="true"
          [nameClass]="'headingbold'"
          class="app-lettercase"
          [disabled]="true"
        >
        </app-claimant-label>
      </div>
      <div class="tw-w-full md:tw-w-[60%]" class="app-pb2">
        <app-claim-label
          value
          [claim]="summary"
          layout="row"
          [showNameLabel]="true"
          [showNameLabel]="true"
          [noLink]="false"
        ></app-claim-label>
      </div>
    </div>
  `,
  styles: [],
})
export class ClaimHeaderComponent extends BaseComponent implements OnInit {
  claimId: string;

  source: string;

  @Input()
  summary: SearchResult;

  @Input()
  label: string = 'LABEL GOES HERE';

  isController: boolean = false;

  constructor(public userRole: UserRoleService) {
    super();
  }
  ngOnInit(): void {
    this.isController = this.userRole.controller;
  }
}
