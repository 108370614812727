import { Injectable } from '@angular/core';
import { ConfigurationSetting, SearchResult } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { ContactInformation } from '../models/ContactInformation';
import { ImportContacts } from '../models/ImportContacts';
import { AdditionalUserDetails } from '../models/additionaluserdetails';
import { FeatureKey } from '../models/featureKey';
import { UserDetails } from './userdetails.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  [x: string]: any;
  claimConfig: ConfigurationSetting;

  get GetConfig() {
    return this._config;
  }

  private _config: ConfigurationSetting = new ConfigurationSetting();
  private _configClientLevel: ConfigurationSetting = new ConfigurationSetting();

  constructor(
    public userdetails: UserDetails,
    private http: Http,
    private utilitiy: UtilityService
  ) {}

  async initializeConfig() {
    if (!this._config.configFeatures) {
      await this.getAdditionalUserInformation();
    }
  }
  async getClaimConfiguration(
    claim: SearchResult
  ): Promise<ConfigurationSetting> {
    //this is populating Claim level features and adding it to _config object
    const featureKeys: FeatureKey = {
      Lob: claim.lob,
      UserOwns: claim.userOwns ?? false,
      IsLiabilityClaim: claim.isLiabilityClaim ?? false,
      StateofJurisdiction: claim.stateofJurisdiction,
      CarrierCode: claim.carrierCode,
      Status: claim.status,
      Type: claim.type,
      IsIntermittent: claim.isIntermittent ? claim.isIntermittent : false,
      Source: claim.source,
      EmpUnum: claim.empUnum,
    };

    this._config.features = await this.http.get<any[]>(
      `/api/claim/GetClaimConfiguration`,
      {
        queryParams: featureKeys,
      }
    );
    return this._config;
  }

  async getAdditionalUserInformation(): Promise<ConfigurationSetting> {
    //this is only populating client level features/all features and client aliases and adding it to _config object
    // only called from dashboard and layout for the first load / other pages call for the _config value = eg: this.claimConfigService.GetConfig.configFeatures.aliases
    this._config.configFeatures = await this.http.get<AdditionalUserDetails>(
      '/api/user/GetAdditionalUserInformation'
    );
    return this._config;
  }

  // LOB level features
  getFeatureAccess(featureName: string) {
    return !this._config.features
      ? false
      : this._config.features.indexOf(featureName.toLowerCase()) > -1;
  }

  // Client Level Features
  getConfigFeatureAccess(featureName: string) {
    if (!this._config.configFeatures) {
      // NOTE: this will not load fast enough on page refresh
      // if you have page refresh bugs use getAsyncConfigFeatureAccess
      this.getAdditionalUserInformation();
    }
    return !this._config.configFeatures
      ? false
      : this._config.configFeatures.features.indexOf(
          featureName.toLowerCase()
        ) > -1;
  }

  async getConfigFeatureAccessAsync(featureName: string): Promise<boolean> {
    await this.initializeConfig();

    return !this._config.configFeatures
      ? false
      : this._config.configFeatures.features.indexOf(
          featureName.toLowerCase()
        ) > -1;
  }

  async GetImportContacts(claim: SearchResult) {
    const claimId = claim.claimId;
    const source = claim.source;
    const lob = claim.lob;
    return await this.http.get<ImportContacts>(
      `/api/claim/${source}/${claimId}/${lob}/GetImportContacts`
    );
  }

  async GetExistingContactPrefernces(claim: SearchResult) {
    const claimId = claim.claimId;
    const source = claim.source;
    const lob = claim.lob;
    return await this.http.get<ImportContacts>(
      `/api/claim/${source}/${claimId}/${lob}/GetContactPrefernces`
    );
  }

  async UpdateContactsInfo(claim: SearchResult, request: ContactInformation) {
    const claimId = claim.claimId;
    const source = claim.source;
    const lob = claim.lob;
    const claimNumber = claim.claimNumber;
    const examinerId = claim.examinerId;
    return await this.http.post<boolean>(
      `/api/claim/${source}/${claimId}/${lob}/${claimNumber}/${examinerId}/update-contact`,
      request
    );
  }

  async getFeatureId(featureName: string) {
    const temp = this._config.configFeatures.featureIds.filter(
      (x) => x.key.toLowerCase() === featureName.toLowerCase()
    );
    return temp.length >= 1 ? temp[0].value : -1;
  }

  async getFeatureLobs(featureName: string) {
    await this.initializeConfig();
    return this._config.configFeatures.featureLobs.filter(
      (x) => x.key.toLowerCase() === featureName.toLowerCase()
    );
  }

  hasFeature(feature: string): boolean {
    return this.GetConfig.configFeatures?.features?.includes(feature);
  }
}
