import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-value',
  template: `
    <div *ngIf="layout === 'row'" class="tw-flex tw-items-center tw-py-0.5">
      <div class="label row-label">
        <ng-container *ngIf="label; else labelContent">{{
          label
        }}</ng-container>
      </div>
      <div class="row-value">
        <div>
          <ng-container *ngIf="value; else valueContent">{{
            value
          }}</ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="layout === 'row-xs'" class="tw-flex tw-items-center tw-py-0.5">
      <div
        *ngIf="label"
        class="label row-xs-label app-pr1 tw-inline-block md:tw-hidden"
        [ngClass]="labelStyle ?? ''"
      >
        <ng-container *ngIf="label; else labelContent">{{
          label
        }}</ng-container>
      </div>
      <div class="row-value">
        <ng-container *ngIf="value; else valueContent">{{
          value
        }}</ng-container>
      </div>
    </div>

    <div *ngIf="layout === 'col' || layout === 'default'">
      <div class="label">
        <ng-container *ngIf="label; else labelContent">{{
          label
        }}</ng-container>
      </div>
      <div>
        <ng-container *ngIf="value; else valueContent">{{
          value
        }}</ng-container>
      </div>
    </div>

    <!-- <div class="app-label app-pr1 tw-inline-block tw-w-[50%] label-vertical-top"> -->
    <div
      *ngIf="layout === 'vertical' && label && (value || valueTemplate)"
      class="tw-flex label-vertical-top labelTemplateClass"
    >
      <div class="row-value tw-w-[50%] labelTemplateClass">
        <ng-container
          *ngIf="label && (value || valueTemplate); else labelContent"
          >{{ label }}</ng-container
        >
      </div>
      <div class="row-value tw-w-[50%]" *ngIf="value">
        <ng-container *ngIf="value; else valueContent">{{
          value
        }}</ng-container>
      </div>
      <div *ngIf="valueTemplate" class="row-value md:tw-w-[50%]">
        <div>
          <ng-container [ngTemplateOutlet]="valueTemplate"
            >{{ value }}
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="layout === 'col-xs'">
      <div class="label tw-block md:tw-hidden" tabindex="0">
        <ng-container *ngIf="label; else labelContent">{{
          label
        }}</ng-container>
      </div>
      <div>
        <ng-container *ngIf="value; else valueContent">{{
          value
        }}</ng-container>
      </div>
    </div>

    <div *ngIf="layout === 'col-row-xs'">
      <div class="tw-inline-block md:tw-hidden">
        <div class="label row-label">
          <ng-container *ngIf="label; else labelContent">{{
            label
          }}</ng-container>
        </div>
        <div class="row-value">
          <ng-container *ngIf="value; else valueContent">{{
            value
          }}</ng-container>
        </div>
      </div>
      <div class="tw-hidden md:tw-block">
        <div class="label">
          <ng-container *ngIf="label; else labelContent">{{
            label
          }}</ng-container>
        </div>
        <div>
          <ng-container *ngIf="value; else valueContent">{{
            value
          }}</ng-container>
        </div>
      </div>
    </div>

    <ng-template #valueContent>
      <ng-content select="[value]"></ng-content>
    </ng-template>
    <ng-template #labelContent>
      <ng-content select="[label]"></ng-content>
    </ng-template>
  `,
  styles: [
    `
      .labelTemplateClass {
        display: flex;
        align-items: center;
      }

      .app-label {
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        font-weight: bold;
        padding-left: 8px;
      }

      .label {
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 16px;
      }

      .col-container {
        padding-bottom: 14px;
      }

      .col-label {
        font-size: 12px;
        font-weight: bold;
      }

      .row-label {
        display: inline-block;
        padding-right: 4px;
      }

      .row-label:after {
        content: ':';
      }

      .row-xs-label:after {
        content: ':';
      }

      .row-value {
        display: inline-block;
        padding-right: 4px;
        line-height: 16px;
        color: var(--color-slate-600);
      }
      :host {
        display: inline-block;
      }
      :host:focus {
        outline-style: solid;
        outline-width: 2px;
        outline-color: #007dbb !important;
        outline-offset: 2px;
      }
    `,
  ],
})
export class ValueComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  icon: string;

  @Input()
  value: any;

  @Input()
  layout: ValueLayout = 'default';

  @Input()
  labelStyle: string;

  @Input()
  valueTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}

type ValueLayout =
  | 'default'
  | 'col'
  | 'row'
  | 'col-xs'
  | 'row-xs'
  | 'col-row-xs'
  | 'col-row-xs-with-icon'
  | 'vertical';
