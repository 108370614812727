import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SearchResult } from 'src/app/shared';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-document-list',
  template: `
    <div class="tw-flex tw-flex-wrap tw-w-full app-pt2 app-pb2">
      <app-action-link
        imageUrl="./assets/images/upload.png"
        title="Upload Documents"
        helpText=""
        [routerLink]=""
        [imageWidth]="60"
        [imageHeight]="53"
        (linkClick)="dialog.open(uploadTemplate)"
      ></app-action-link>
      <ng-template #uploadTemplate>
        <app-dialog
          title="Upload Documents"
          ariaLabel="Upload documents dialog"
          confirmLabel="Upload"
          cancelLabel="Close"
          [showActions]="true"
          [showConfirm]="false"
          [width]="700"
          #dialog
        >
          <app-upload
            [dialogRef]="dialog.dialogRef"
            [claim]="claim"
            [claimId]="claim.claimId"
            [source]="claim.source"
          ></app-upload>
        </app-dialog>
      </ng-template>
    </div>
    <div
      class="tw-w-full app-pt2 app-pb1"
      *ngIf="documentResult && documentResult.length > 0"
    >
      <h3
        matBadgeColor="primary"
        matBadgeSize="medium"
        Class="app-header-3 heading-popup"
        matBadgeOverlap="false"
      >
        My Uploaded Documents
      </h3>
    </div>
    <div class="tw-w-full">
      <app-list
        [dataSource]="documentResult"
        [template]="row"
        [filterEnabled]="false"
        class="app-pt2"
        loadingMessage=""
        noDataIcon="attachment"
        [loaded]="true"
        noDataMessage="<br><strong>No documents available for this claim.</strong>"
        [tableAriaLabel]="'Documents'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <app-list-header
              name="fileName"
              label="File"
              class="tw-w-full md:tw-w-[40%]"
            ></app-list-header>
            <app-list-header
              name="created"
              label="Date Uploaded"
              readOnly
              [sortEnabled]="false"
              class="tw-w-full md:tw-w-[40%]"
            ></app-list-header>
            <app-list-header
              label="Open"
              readOnly
              class="tw-w-full md:tw-w-[20%]"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>
      <ng-template #row let-element>
        <tr
          class="app-pt2 app-pb2 tw-flex tw-flex-wrap tw-w-full tw-items-center"
        >
          <td class="tw-w-full md:tw-w-[40%]">
            <app-value
              [tabIndex]="0"
              label="File Name"
              [value]="element.fileName"
              layout="row-xs"
            ></app-value>
          </td>
          <td class="tw-w-full md:tw-w-[40%]">
            <app-value
              [tabIndex]="0"
              label="created"
              [value]="element.created | format : 'date'"
              layout="row-xs"
            ></app-value>
          </td>
          <td *ngIf="element.dcn !== ''">
            <app-icon-button
              icon="open_in_new"
              label="Open"
              (click)="openfile(element)"
              (keydown.enter)="openfile(element)"
              [attr.aria-label]="'Download file - ' + element.fileName"
              tabindex="0"
              role="button"
            ></app-icon-button>
          </td>
          <td *ngIf="element.dcn === ''">
            <app-value
              class="tw-w-full md:tw-w-[40%] disabled-text"
              [value]="'Processing'"
              label="Open"
              layout="row-xs"
            ></app-value>
          </td>
        </tr>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .pt-2 {
        padding-top: 0.7em;
      }
    `,
  ],
})
export class DocumentListComponent implements OnInit {
  @Input()
  claim: SearchResult;

  documentResult: any[];
  ssoTest: any;

  constructor(
    public session: Session,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public claimConfigService: ConfigurationService,
    private climService: ClaimService
  ) {}
  async ngOnInit() {
    this.documentResult = await this.climService.getDocuments(this.claim);
  }

  openfile(element: any) {
    window.open(`/api/auth/Saml/${element.dcn}/ViewSirDocument`, '_blank');
  }
}
