import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Session } from '../../services/session.service';
import {
  ConfigurationSetting,
  OverlayMode,
  ProgressService,
  SearchResult,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { UnapprovedJobFunction } from '../../models/accommodations-interactions';
import { ConfigurationService, Excel, ExportRequest } from '../..';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { AccommodationsService } from '../../services/accommodations-service';
import { UserActionService } from '../../../shared';

@Component({
  selector: 'app-unapproved-job-functions-panel',
  template: `
    <app-panel
      title="Unapproved Job Functions"
      titleCollapse="Collapse unapproved Job Functions"
      titleExpand="List of unapproved Job Functions"
      class="tw-w-full"
      [expanded]="expanded"
      [labelTemplate]="labelTemplate"
    >
      <app-list
        [dataSource]="listData"
        [template]="row"
        class="app-pt2"
        [noDataIcon]="noDataIcon"
        [noDataMessage]="noDataMessage"
        [noDataThinMessage]="noDataThinMessage"
        [displayInfoToastMsg]="false"
        [exportEnabled]="true"
        exportButtonAriaLabel="Export and download these unapproved job functions as a XLSX file"
        (export)="_export()"
        [ShowSort]="false"
        [tableAriaLabel]="'Unapproved Job Functions'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full">
            <app-list-header
              name="description"
              label="Job Function"
              class="tw-w-full md:tw-w-[90%]"
            ></app-list-header>
            <app-list-header
              *ngIf="canApproveJobFunctions"
              name="accommodationNumber"
              label="Confirm"
              class="tw-w-full md:tw-w-[10%]"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>

      <ng-template #row let-element>
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <td class="tw-w-full md:tw-w-[90%]">
            <app-value layout="col-xs" [value]="element.task" [tabIndex]="0">
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[10%]" *ngIf="canApproveJobFunctions">
            <app-icon-button
              (click)="confirm(element)"
              (keydown.enter)="confirm(element)"
              [attr.aria-label]="
                'Confirm pending job function - ' + element.task
              "
              label="Confirm"
              icon="done"
              tabindex="0"
              role="button"
            ></app-icon-button>
          </td>
        </tr>
      </ng-template>

      <ng-template #labelTemplate>
        @if (listData.length) {
        <mat-icon
          [matBadge]="listData.length"
          matBadgeOverlap="true"
        ></mat-icon>
        }
      </ng-template>

      <ng-template #confirmDialog>
        <app-dialog
          title="Confirmation"
          ariaLabel="Confirmation dialog"
          [showActions]="false"
          [showHeader]="false"
          ariaLabelMessage="Cancel"
          cancelLabel="Cancel"
        >
          <app-dialog-container
            label="Confirm job function"
            [headerId]="'confirmJobFunction'"
            class="container"
            [showHeader]="true"
            [headerLevel]="2"
          >
            <div class="tw-w-full">
              <app-claim-label
                value
                [claim]="claim"
                layout="row"
                [showNameLabel]="true"
                [noLink]="true"
                [hideWatchList]="true"
              ></app-claim-label>
            </div>
            <div class="tw-w-full">
              <app-claimant-label
                value
                [claimant]="claim"
                [showNameLabel]="true"
                [nameClass]="'headingbold'"
                class="app-uppercase"
                [disabled]="true"
              >
              </app-claimant-label>
            </div>
            <form [formGroup]="formGroup">
              <div class="tw-w-full">
                <app-container-control
                  ngDefaultControl
                  [formGroup]="formGroup"
                  formControlName="validJobFunction"
                  class="app-pb3 headingbold"
                  label="Is this a valid job function?"
                >
                  <mat-radio-group aria-label="Is this a valid job function?">
                    <mat-radio-button (change)="toggleValid()" value="Yes"
                      >Yes</mat-radio-button
                    >
                    <span class="app-pl2"></span>
                    <mat-radio-button (change)="toggleValid()" value="No"
                      >No</mat-radio-button
                    >
                  </mat-radio-group>
                </app-container-control>
                @if(showComments){
                <div class="tw-w-full">
                  <app-text-area-control
                    label="Additional Comments *"
                    ngDefaultControl
                    [formGroup]="formGroup"
                    formControlName="comments"
                    [maxLength]="4000"
                    validationControl="Additional comments "
                    [isSingular] = 'false'
                    [rows]="3"
                  ></app-text-area-control>
                </div>
                }
              </div>
            </form>
            <app-action-row>
              <app-button
                right2
                autofocus
                (click)="send()"
                emphasis="High"
                [disabled]="!formGroup.valid"
                >Confirm</app-button
              >
              <app-button
                right1
                id="btnCancel"
                (click)="cancel()"
                emphasis="Low"
                >Cancel</app-button
              >
            </app-action-row>
          </app-dialog-container>
        </app-dialog>
      </ng-template>
    </app-panel>
  `,
  styles: [
    `
      .mat-icon {
        position: relative;
        top: 1px;
        left: -15px;
      }
    `,
  ],
})
export class UnapprovedJobFunctionsPanelComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  expanded: boolean = false;

  @Input()
  unApprovedJobFunctionData: UnapprovedJobFunction[] = [];

  @Output()
  export = new EventEmitter<ExportRequest>();

  @ViewChild('confirmDialog') confirmDialogRef: TemplateRef<any>;

  formGroup: FormGroup = this.formBuilder.group({
    validJobFunction: ['', [Validators.required]],
    comments: [''],
    jobFunctionDetailId: [''],
  });

  listData: UnapprovedJobFunction[] = [];
  noDataIcon: string = 'list_alt';
  noDataMessage: string = 'No unapproved job functions';
  noDataThinMessage: string = '';
  showComments: boolean = false;
  task: string = '';
  canApproveJobFunctions: boolean = false;

  constructor(
    public dialog: MatDialog,
    public session: Session,
    private accommodationsService: AccommodationsService,
    private excel: Excel,
    private formBuilder: FormBuilder,
    public progressService: ProgressService,
    private snackbar: SnackbarService,
    private userAction: UserActionService,
    private configurationService: ConfigurationService
  ) {
    this.canApproveJobFunctions = this.configurationService.hasFeature(
      'accommodationjobfunctionsrequests'
    );
  }

  async ngOnInit() {
    this.listData = this.unApprovedJobFunctionData;
  }

  _export() {
    const request = new ExportRequest();
    request.fileName =
      'Unapproved_Job_Function_' + this.claim.claimNumber + '.xlsx';
    request.sheets = [
      {
        name: 'Return to work',
        rows: this.listData,
        headers: [
          { label: 'Detail Id', value: 'jobFunctionDetailId' },
          { label: 'Job Function', value: 'task' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }

  async confirm(unapprovedJobFunction: UnapprovedJobFunction) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
      arialabelledBy: 'confirmJobFunction',
    };
    this.formGroup.reset();
    this.showComments = false;
    this.formGroup
      .get('jobFunctionDetailId')
      .setValue(unapprovedJobFunction.jobFunctionDetailId);
    this.task = unapprovedJobFunction.task;
    this.dialog.open(this.confirmDialogRef, dialogConfig);
  }

  toggleValid() {
    this.showComments = this.formGroup.get('validJobFunction').value === 'No';
    if (this.showComments) {
      this.formGroup.get('comments').addValidators(customvalidations.required);
    } else {
      this.formGroup.get('comments').clearValidators();
    }
    this.formGroup.get('comments').updateValueAndValidity();
  }

  async send() {
    if (this.formGroup.valid) {
      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.snackbar.show(
          'Preview-only mode. Information not sent.',
          SnackbarType.Info
        );
      } else {
        this.progressService.show(OverlayMode.Spinner, 'Please wait..');
        await this.accommodationsService.jobFunctionRequest(
          this.claim,
          this.formGroup.value['comments'],
          this.formGroup.value['validJobFunction'],
          this.formGroup.value['jobFunctionDetailId'],
          this.task
        );
        this.progressService.hide();
        this.snackbar.show('Submit to TAMS success', SnackbarType.Success);
        this.userAction.setBustCache(true);

        this.listData = this.listData.filter(
          (_) =>
            _.jobFunctionDetailId !==
            this.formGroup.get('jobFunctionDetailId').value
        );
      }
      this.dialog.closeAll();
    }
  }

  cancel() {
    this.dialog.closeAll();
  }
}
