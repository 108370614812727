import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/shared';
import { SearchInformation } from 'src/app/shared/models/search-result';

@Component({
  selector: 'app-search-results',
  template: `
    <app-panel
      title="Search Results"
      [expanded]="expandSearchResults"
      [canExpand]="false"
    >
      <app-toast *ngIf="searchInformation.isMaxCount"
        >The Search Results maximum has been exceeded. Please modify the search
        and try again.
      </app-toast>
      <app-search-results-list
        [searchResults]="searchInformation.searchResults"
      ></app-search-results-list>
    </app-panel>
  `,
  styles: [],
})
export class SearchResultsComponent extends BaseComponent {
  @Input()
  searchInformation: SearchInformation;

  @Input()
  expandSearchResults: boolean = false;

  constructor() {
    super();
  }
}
