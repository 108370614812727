import { Component, OnInit } from '@angular/core';
import { IncidentalAbsenceReason } from '../../models/incidental-absence-reason';
import { BaseComponent, ConfigurationSetting } from 'src/app/shared';
import { Session } from '../../services/session.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-absence-dashboard-page',
  template: `
    <app-section class="tw-w-full">
      <app-claim-breadcrumb title="Absences" />
      <app-absence-search class="tw-w-full"></app-absence-search>
    </app-section>
  `,
  styles: [],
})
export class AbsenceDashboardPageComponent
  extends BaseComponent
  implements OnInit
{
  ClientLevelConfig: ConfigurationSetting;

  reasonData: IncidentalAbsenceReason[]; // = this.getAbsenceReasons();

  constructor(
    public configurationService: ConfigurationService,
    public session: Session
  ) {
    super();
  }

  async ngOnInit() {
    !this.session.user ? await this.session.loadUser() : null;
    this.ClientLevelConfig =
      await this.configurationService.getAdditionalUserInformation();
  }
}
