import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  BaseComponent,
  SearchResult,
  SnackbarService,
  getUrlParams,
} from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { SupportDialogComponent } from '../..';
import {
  DirectDeposit,
  UpdateDirectDepositRequest,
} from '../../models/directdeposit';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DirectDepositService } from '../../services/directdeposit.service';
import { emulator } from '../../services/emulator.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-direct-deposit-page',
  template: `
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        title="Manage direct deposit"
        class="tw-w-full"
        [showTitle]="true"
        [canExpand]="false"
      >
        <app-claim-header
          class="tw-w-full"
          [summary]="summary"
        ></app-claim-header>

        <form [formGroup]="formGroup">
          <ng-container *ngIf="!IsAuthorized">
            <div class="app-pb2 app-pt2" *ngIf="HasNoAccess">
              You do not have access to this feature. If you have any questions,
              please
              <a
                value
                routerLink="/claims"
                routerLinkActive="router-link-active"
                [queryParams]="{
                  source: source,
                  claimId: claimId,
                  focus: 'comm-center'
                }"
                >contact us</a
              >
            </div>
            <div class="app-pb2 app-pt2" *ngIf="HasPreNote">
              <strong
                >Your request for direct deposit is being processed.
              </strong>
              Please allow 7-10 days after your request has been submitted for
              processing to complete. Please note that no changes can be made to
              your direct deposit preferences while your current request is
              being processed.
            </div>
          </ng-container>
          <ng-container *ngIf="IsAuthorized">
            <ng-container *ngIf="status === 'entry'">
              <div class="app-pb2">
                Sign up for direct deposit on your claim to receive payments
                quickly and easily.
              </div>
            </ng-container>
            <div *ngIf="status === 'entry'">
              <div class="toaster-width">
                <app-toast type="Info"
                  >Enrollment in direct deposit does not constitute payment or
                  acceptance of claim. If you have any questions regarding your
                  claim, please contact your {{ examinerLabel }}.
                </app-toast>
              </div>

              <div class="app-pt2"></div>
              <app-container-control
                ngDefaultControl
                [formGroup]="formGroup"
                formControlName="type"
                label="How would you like to receive your payments? *"
                [isLabelBold]="true"
              >
                <mat-radio-group
                  aria-label="Types of payment method"
                  role="radiogroup"
                >
                  <mat-radio-button
                    value="ELECTRONIC"
                    [checked]="currentPaymentMethod === 'ELECTRONIC'"
                    >Electronic</mat-radio-button
                  >
                  <span class="app-pl2"></span>
                  <mat-radio-button
                    value="CHECK"
                    [checked]="currentPaymentMethod === 'CHECK'"
                    >By Mail</mat-radio-button
                  >
                </mat-radio-group>
              </app-container-control>
              <div class="app-pt2"></div>
              <div *ngIf="formGroup.value.type === 'CHECK'">
                <ng-container *ngIf="status === 'entry'">
                  <div class="app-pb2">
                    This is the address your employer has file.
                  </div>
                  <div class="app-pb2">
                    <b>
                      {{ currentAddress }}<br />{{ currentCity }},
                      {{ currentState }} {{ currentZip }}
                    </b>
                  </div>
                  <div class="app-pb2">
                    If you would like to edit this address, you may do so in the
                    Your Contact Information section of the Claim Summary
                    screen.
                  </div>
                  <app-action-row>
                    <app-button right1 emphasis="High" (click)="submitMail()"
                      >Submit</app-button
                    >
                  </app-action-row>
                </ng-container>
              </div>
              <div
                *ngIf="formGroup.value.type === 'ELECTRONIC'"
                class="tw-flex tw-flex-wrap"
              >
                <div class="tw-w-full app-pb2">
                  <div class="tw-w-full">
                    <h2 class="app-header-2">Current bank account</h2>
                  </div>
                  <div class="tw-flex tw-flex-wrap app-pt2 app-pb2">
                    <app-value
                      label="Routing Number"
                      value="{{
                        session?.user?.emulatorContext &&
                        session?.user?.emulatorContext?.isEmulating
                          ? 'XXXXXXXXX'
                          : ddInitialLoad.routingNumber
                      }}"
                      layout="col-row-xs"
                      class="tw-w-full md:tw-w-[25%]"
                    ></app-value>
                    <app-value
                      label="Account Number"
                      value="{{
                        session?.user?.emulatorContext &&
                        session?.user?.emulatorContext?.isEmulating
                          ? 'XXXXXXXXX'
                          : ddInitialLoad.accountNumber
                      }}"
                      layout="col-row-xs"
                      class="tw-w-full md:tw-w-[25%]"
                    ></app-value>
                    <app-value
                      label="Account Type"
                      value="{{
                        session?.user?.emulatorContext &&
                        session?.user?.emulatorContext?.isEmulating
                          ? 'XXXXXXXXX'
                          : currentAccountType
                      }}"
                      layout="col-row-xs"
                      class="tw-w-full md:tw-w-[25%]"
                    ></app-value>

                    <app-value
                      label="Status"
                      value="{{
                        session?.user?.emulatorContext &&
                        session?.user?.emulatorContext?.isEmulating
                          ? 'XXXXXXXXX'
                          : ddInitialLoad.accountStatus
                      }}"
                      layout="col-row-xs"
                      class="tw-w-full md:tw-w-[25%]"
                    ></app-value>
                  </div>
                </div>
                <div class="tw-w-full app-pb3">
                  <h2 class="app-header-2">Update bank account</h2>
                </div>
                <div class="tw-w-full md:tw-w-[50%]">
                  <app-text-control
                    formControlName="routing"
                    [formGroup]="formGroup"
                    label="Routing Number"
                    [password]="!showPassword"
                    ngDefaultControl
                    [validationControl]="'Rounting Number'"
                    [minLengthValue]="routingMinLenght"
                    [maxLength]="9"
                    [IsShowLimit]="false"
                  ></app-text-control>
                  <div
                    *ngIf="
                      this.formGroup.controls['routing'].errors &&
                      this.formGroup.controls['routing'].errors['invaliddata']
                    "
                  ></div>
                  <app-text-control
                    formControlName="routingConfirm"
                    [formGroup]="formGroup"
                    label="Confirm Routing Number"
                    [password]="!showPassword"
                    ngDefaultControl
                    [validationControl]="'Confirm Routing Number'"
                    [minLengthValue]="accountMinLength"
                  ></app-text-control>
                  <div
                    *ngIf="
                      this.formGroup.controls['routingConfirm'].errors &&
                      this.formGroup.controls['routingConfirm'].errors[
                        'missMatchControl'
                      ]
                    "
                  ></div>
                  <app-text-control
                    formControlName="account"
                    [formGroup]="formGroup"
                    label="Account Number"
                    [password]="!showPassword"
                    ngDefaultControl
                    [maxLength]="17"
                    [validationControl]="'Account Number'"
                    [IsShowLimit]="false"
                  ></app-text-control>
                  <div
                    *ngIf="
                      this.formGroup.controls['account'].errors &&
                      this.formGroup.controls['account'].errors['invaliddata']
                    "
                  ></div>
                  <app-text-control
                    formControlName="accountConfirm"
                    [formGroup]="formGroup"
                    label="Confirm Account Number"
                    [password]="!showPassword"
                    [validationControl]="'Confirm Account Number'"
                    ngDefaultControl
                  ></app-text-control>
                  <div
                    *ngIf="
                      this.formGroup.controls['accountConfirm'].errors &&
                      this.formGroup.controls['accountConfirm'].errors[
                        'missMatchControl'
                      ]
                    "
                  ></div>
                  <div class="tw-flex tw-flex-wrap tw-w-full tw-items-center ">
                    <div class="tw-flex-none tw-flex"></div>
                    <div class="tw-grow"></div>
                    <div class="tw-flex-none tw-flex">
                      <mat-checkbox
                        color="primary"
                        labelPosition="after"
                        aria-label="Display values"
                        (change)="changeControlType($event)"
                        [checked]="showPassword"
                      ></mat-checkbox>
                      <span style="color: #67707A Slate 300" class="app-pt1">
                        Display values</span
                      >
                    </div>
                  </div>

                  <div class="tw-w-full">
                    <strong>Type of account *</strong>
                  </div>
                  <app-container-control
                    ngDefaultControl
                    [formGroup]="formGroup"
                    formControlName="accountType"
                    [errorValidationMessage]="''"
                    label=""
                  >
                    <mat-radio-group
                      aria-label="Type of account"
                      role="radiogroup"
                    >
                      <mat-radio-button
                        value="CHECKING"
                        [checked]="oldAccountType === 'CHECKING'"
                        >Checking</mat-radio-button
                      >
                      <span class="app-pl2"></span>
                      <mat-radio-button
                        value="SAVINGS"
                        [checked]="oldAccountType === 'SAVINGS'"
                        >Savings</mat-radio-button
                      >
                    </mat-radio-group>
                  </app-container-control>
                  <div
                    *ngIf="IsElectronicError"
                    class="mat-mdc-form-field-error"
                  >
                    {{ electronicErrorMsg }}
                  </div>
                </div>

                <div class="tw-w-full md:tw-w-[50%] tw-mx-auto">
                  <img
                    src="./assets/images/DDCheck.webp"
                    class="tw-mx-auto"
                    alt=""
                  />
                </div>
                <app-action-row class="tw-w-full">
                  <app-button right1 emphasis="High" (click)="reviewDirect()"
                    >Review</app-button
                  >
                </app-action-row>
              </div>
            </div>
            <div *ngIf="status === 'submitted'">
              <ng-container *ngIf="formGroup.value.type === 'ELECTRONIC'">
                <app-toast type="Success">
                  <div class="app-pb2">
                    <b>Thank you for submitting your direct deposit request.</b>
                  </div>
                  <div class="app-pb2">
                    Please allow 7-10 business days for your request to process.
                    In the meantime, any payments issued will be sent by mail.
                    Any changes you have made will have no impact on payments
                    issued by your employer. If you have any questions, please
                    <a
                      aria-label="Opens Contact Support popup"
                      (click)="openSupportDialog()"
                      role="button"
                      >contact us</a
                    >.
                  </div>
                </app-toast>
              </ng-container>
              <ng-container *ngIf="formGroup.value.type === 'CHECK'">
                <app-toast type="Success">
                  <div class="app-pb2">
                    <b class="boldmsg"
                      >Your request was successfully submitted.</b
                    >
                  </div>
                  <div class="app-pb2">
                    Your request indicating you would not like to receive
                    payments by direct deposit has been processed. Any future
                    payments will be mailed to the address on file. If you would
                    like to enroll for direct deposit, please log into
                    mySedgwick and manage your direct deposit preferences.
                  </div>
                </app-toast>
              </ng-container>
            </div>
            <div *ngIf="status === 'review'">
              <div class="app-pb2">
                <b>Please review the information you entered below.</b>
              </div>

              <div class="tw-flex tw-flex-wrap app-pb2">
                <app-value
                  label="Routing Number"
                  value="{{ reviewRouting }}"
                  layout="col-row-xs"
                  class="tw-w-full md:tw-w-[25%]"
                ></app-value>
                <app-value
                  label="Account Number"
                  value="{{ reviewAccount }}"
                  layout="col-row-xs"
                  class="tw-w-full md:tw-w-[25%]"
                ></app-value>
                <app-value
                  label="Account Type"
                  value="{{ reviewAccountType }}"
                  layout="col-row-xs"
                  class="tw-w-full md:tw-w-[25%]"
                ></app-value>
              </div>

              <div class="app-pb2 app-pt2">
                <app-header-label
                  ><div class="app-header-2">
                    Terms and conditions
                  </div></app-header-label
                >
              </div>
              <div class="app-pb2">
                <p class="app-pb1">
                  {{ legalText }}
                </p>

                <div class="tw-w-full app-pb1">
                  <mat-checkbox
                    color="primary"
                    labelPosition="after"
                    aria-label="I Acknowledge"
                    (change)="acknowledge($event)"
                  ></mat-checkbox>
                  <strong style="color: #67707A Slate 300" class="app-pt1">
                    I Acknowledge *</strong
                  >
                </div>
                <p class="app-pb1">
                  By enrolling in Direct Deposit, you will no longer receive
                  physical pay stubs.
                </p>

                <p class="">
                  I hereby authorize Sedgwick Claims Management Services to
                  initiate credit entries and to initiate, if necessary, debit
                  entries and adjustments for any credit entries in error to my
                  Checking and /or Savings account indicated above and the
                  depository named above, to credit and/or debit the same such
                  account. This authority is to remain in full force and effect
                  until Sedgwick CMS has received written or electronic
                  notification from me of its termination in such time and in
                  such manner as to afford Sedgwick CMS and your bank a
                  reasonable opportunity to act on it. Sedgwick CMS will
                  deactivate all ACH transactions within 7-10 business days of
                  receipt of request.
                </p>
              </div>
              <div>
                <div class="tw-w-full">
                  <strong>I agree with terms and conditions *</strong>
                </div>
                <app-container-control
                  ngDefaultControl
                  [formGroup]="formGroup"
                  formControlName="agree"
                  label=""
                >
                  <mat-radio-group
                    aria-label="Agree with terms and conditions"
                    role="radiogroup"
                  >
                    <mat-radio-button value="yes">Yes</mat-radio-button>
                    <span class="app-pl2"></span>
                    <mat-radio-button value="no" checked="true"
                      >No</mat-radio-button
                    >
                  </mat-radio-group>
                </app-container-control>
              </div>
              <app-action-row class="tw-w-full">
                <app-button
                  right1
                  emphasis="High"
                  (click)="submitDirect()"
                  [disabled]="!directdepositsubmitenable"
                  >Submit</app-button
                >
                <app-button right2 emphasis="Low" (click)="returnBack()"
                  >Back</app-button
                >
              </app-action-row>
            </div>
            <div class="app-pb1"></div>
          </ng-container>
        </form>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      @media only screen and (min-width: 1300px) {
        .toaster-width {
          width: 85% !important;
        }
      }

      .boldmsg {
        font-weight: 600;
      }
    `,
  ],
})
export class DirectDepositPageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  claimId: string;

  source: string;

  //summary: ClaimResult;
  summary: SearchResult = <SearchResult>{};
  electricAccount: bankdetails = new bankdetails();

  oldAccount: string;
  oldCnfAccount: string;
  oldRouting: string;
  oldCnFRouting: string;
  oldAccountType: string = 'CHECKING';
  AgreeCondition: string = 'no';

  IsAuthorized: boolean = true;
  routingMinLenght: number = 9;
  accountMinLength: number = 5;
  status: FormStatus = 'entry';
  showPassword: boolean = false;
  IsElectronicError: boolean = false;
  electronicErrorMsg: string = '';
  IsAcknowledge: boolean = false;
  disabledbtn: boolean = true;
  examinerLabel: string;
  typeSubscription: Subscription;
  formGroup = this.fb.group({
    type: ['', Validators.required],
    accountType: [''],
    routing: [
      this.electricAccount.RoutingNumber,
      [Validators.required, Validators.minLength(this.routingMinLenght)],
    ],
    routingConfirm: [
      this.electricAccount.RoutingNumberConfirm,
      Validators.required,
    ],
    account: [
      this.electricAccount.AccountNumber,
      [Validators.required, Validators.minLength(this.accountMinLength)],
    ],
    accountConfirm: [
      this.electricAccount.AccountNumberConfirm,
      Validators.required,
    ],
    agree: [],
  });

  reviewAccount: string;
  reviewRouting: string;
  reviewAccountType: string;
  ddInitialLoad: DirectDeposit = new DirectDeposit();
  HasNoAccess: boolean = true;
  HasPreNote: boolean = false;

  get currentPaymentMethod() {
    return this.formGroup.get('type').value;
  }
  get AccountType() {
    return this.formGroup.get('type').value;
  }
  get currentAccountType() {
    if (
      this.ddInitialLoad.routingNumber == undefined ||
      this.ddInitialLoad.routingNumber == null ||
      this.ddInitialLoad.routingNumber.length < 2
    ) {
      return '';
    } else {
      return this.ddInitialLoad.accountType.toLowerCase() == 'savings'
        ? 'Savings'
        : 'Checking';
    }
  }
  get OldAccountTypeValue() {
    if (this.formGroup.controls['accountType'].value != '') {
      return this.formGroup.controls['accountType'].value;
    } else {
      return 'CHECKING';
    }
  }
  get directdepositsubmitenable() {
    if (
      this.IsAcknowledge != undefined &&
      this.formGroup.controls['agree'].value != undefined
    ) {
      if (
        this.IsAcknowledge &&
        this.formGroup.controls['agree'].value === 'yes'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get legalText() {
    if (
      this.ddInitialLoad != null &&
      this.ddInitialLoad != undefined &&
      this.ddInitialLoad.legalText != undefined &&
      this.ddInitialLoad.legalText != null
    ) {
      return this.ddInitialLoad.legalText;
    } else {
      return '';
    }
  }

  get currentAddress() {
    if (
      this.ddInitialLoad != null &&
      this.ddInitialLoad != undefined &&
      this.ddInitialLoad.address != undefined &&
      this.ddInitialLoad.address != null
    ) {
      return this.ddInitialLoad.address;
    } else {
      return '';
    }
  }

  get currentCity() {
    if (
      this.ddInitialLoad != null &&
      this.ddInitialLoad != undefined &&
      this.ddInitialLoad.city != undefined &&
      this.ddInitialLoad.city != null
    ) {
      return this.ddInitialLoad.city;
    } else {
      return '';
    }
  }

  get currentState() {
    if (
      this.ddInitialLoad != null &&
      this.ddInitialLoad != undefined &&
      this.ddInitialLoad.state != undefined &&
      this.ddInitialLoad.state != null
    ) {
      return this.ddInitialLoad.state;
    } else {
      return '';
    }
  }

  get currentZip() {
    if (
      this.ddInitialLoad != null &&
      this.ddInitialLoad != undefined &&
      this.ddInitialLoad.zip != undefined &&
      this.ddInitialLoad.zip != null
    ) {
      return this.ddInitialLoad.zip;
    } else {
      return '';
    }
  }

  constructor(
    private claimApi: ClaimService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private activityService: ActivityService,
    private ddservice: DirectDepositService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public inpageAliasService: InpageAliasService,
    public configurationService: ConfigurationService,
    public session: Session,
    private snackbarService: SnackbarService,
    private enumalatorService: emulator
  ) {
    super();

    this.electricAccount.RoutingNumber = '';
    this.electricAccount.RoutingNumberConfirm = '';
    this.electricAccount.AccountNumber = '';
    this.electricAccount.AccountNumberConfirm = '';
  }
  ngAfterViewInit(): void {
    this.cdr.detach();
    this.formGroup.controls['routingConfirm'].addValidators(
      customvalidations.matchTextControls(
        this.formGroup.controls['routing'],
        'The Routing Number entry doesn’t match'
      )
    );

    this.formGroup.controls['accountConfirm'].addValidators(
      customvalidations.matchTextControls(
        this.formGroup.controls['account'],
        'The Account Number entry doesn’t match'
      )
    );

    this.formGroup.controls['routing'].addValidators(
      customvalidations.onlyNumeric('Error : Valid Routing Number is Required')
    );

    this.formGroup.controls['routing'].addValidators(
      customvalidations.RountingValidate()
    );

    this.formGroup.controls['account'].addValidators(
      customvalidations.onlyNumeric('Error : Valid Account Number is Required')
    );

    this.cdr.reattach();
    this.cdr.markForCheck();
  }

  async ngOnInit() {
    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.summary.claimId = this.claimId;
    this.summary.source = this.source;
    this.loadClaim();
    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingular',
      this.configurationService.GetConfig.configFeatures.aliases
    );

    this.typeSubscription = this.formGroup
      .get('type')
      .valueChanges.subscribe((value) => {
        if (value == 'CHECK') {
          this.oldAccount = this.formGroup.controls.account.value;
          this.oldCnfAccount = this.formGroup.controls.accountConfirm.value;
          this.oldRouting = this.formGroup.controls.routing.value;
          this.oldCnFRouting = this.formGroup.controls.routingConfirm.value;
          this.oldAccountType = this.formGroup.controls.accountType.value;
        } else if (value == 'ELECTRONIC') {
          this.oldAccountType = this.OldAccountTypeValue;
          setTimeout(() => {
            this.showPassword = false;
            this.formGroup.controls['account'].setValue(this.oldAccount);
            this.formGroup.controls['accountConfirm'].setValue(
              this.oldCnfAccount
            );
            this.formGroup.controls['routing'].setValue(this.oldRouting);
            this.formGroup.controls['routingConfirm'].setValue(
              this.oldCnFRouting
            );
            this.formGroup.controls['accountType'].setValue(
              this.oldAccountType
            );
          }, 1);
        }
        this.cdr.detectChanges();
      });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  changeControlType(event: any) {
    this.showPassword = event.checked;
  }

  async loadClaim() {
    await this.claimApi.getSummary(this.source, this.claimId).then((result) => {
      if (result != null && result != undefined) {
        this.summary = result;

        this.ddservice
          .getDirectDeposit({
            ClaimUID: this.summary.claimId,
            LineOfBusiness: this.summary.lob,
          })
          .then((ddResult) => {
            if (ddResult != undefined && ddResult != null) {
              this.ddInitialLoad = <DirectDeposit>ddResult;
              this.IsAuthorized = true;
              if (
                this.ddInitialLoad.hasAccessToDirectDeposit != 'Y' ||
                this.ddInitialLoad.isOwnClaim != 'Y'
              ) {
                this.IsAuthorized = false;
                this.HasNoAccess = true;
                this.HasPreNote = false;
              }
              if (
                this.ddInitialLoad?.accountStatus.toLocaleLowerCase() ===
                'account validation'
              ) {
                this.IsAuthorized = false;
                this.HasNoAccess = false;
                this.HasPreNote = true;
              }
              if (
                this.IsAuthorized &&
                this.ddInitialLoad.paymentMethod != undefined &&
                this.ddInitialLoad.paymentMethod.length > 2
              ) {
                this.formGroup.controls['type'].setValue(
                  this.ddInitialLoad.paymentMethod
                );
                this.cdr.detectChanges();
              }
            }
          });
      }
    });
  }

  submitMail() {
    this.createUpdatePayload(true);
  }

  createUpdatePayload = async (IsMail: boolean) => {
    const action = this.formGroup.controls['type'].value;
    const updateDDRequest: UpdateDirectDepositRequest = {
      RoutingNumber: IsMail ? '' : this.formGroup.controls['routing'].value,
      AccountNumber: IsMail ? '' : this.formGroup.controls['account'].value,
      AccountType: this.formGroup.controls['accountType'].value,
      ClaimUID: this.claimId,
      Action: action,
      DataSet: this.summary.lob === 'DS' ? 'DS' : '',
      IsDSLOB: this.summary.lob === 'DS' ? true : false,
    };

    if (updateDDRequest.IsDSLOB) {
      this.typeSubscription.unsubscribe();
      if (updateDDRequest.Action == 'CHECK') {
        updateDDRequest.Action = 'DELETE';
      } else {
        if (
          updateDDRequest.AccountNumber != '' &&
          updateDDRequest.AccountNumber.length > 0
        ) {
          updateDDRequest.Action = 'UPDATE';
        } else {
          updateDDRequest.Action = 'ADD';
        }
      }
    }

    this.enumalatorService.showAuditorInfo();
    await this.ddservice.updateDirectDeposit(updateDDRequest).then((result) => {
      this.activityService.DismissNotificationAlert(
        'DD_A',
        this.summary.claimNumber,
        this.summary.claimId,
        this.summary.source,
        this.summary.userOwns
      );

      this.status = 'submitted';
    });
  };

  returnBack() {
    this.IsAuthorized = true;

    this.status = 'entry';

    setTimeout(() => {
      this.showPassword = false;
      this.formGroup.controls['type'].setValue('ELECTRONIC');
      this.formGroup.controls['account'].setValue(this.oldAccount);
      this.formGroup.controls['accountConfirm'].setValue(this.oldCnfAccount);
      this.formGroup.controls['routing'].setValue(this.oldRouting);
      this.formGroup.controls['routingConfirm'].setValue(this.oldCnFRouting);
      this.formGroup.controls['accountType'].setValue(this.oldAccountType);
    }, 10);

    this.cdr.detectChanges();
  }

  async submitDirect() {
    if (this.directdepositsubmitenable) {
      if (this.formGroup.valid) {
        await this.createUpdatePayload(false);
      }
    }
  }

  reviewDirect(): boolean {
    if (this.formGroup.valid) {
      const accountType = (this.oldAccountType =
        this.formGroup.controls['accountType'].value == null ||
        this.formGroup.controls['accountType'].value == ''
          ? this.oldAccountType
          : this.formGroup.controls['accountType'].value);
      const account = (this.oldAccount =
        this.formGroup.controls['account'].value);
      const routing = (this.oldRouting =
        this.formGroup.controls['routing'].value);
      const accountConfirm = (this.oldCnfAccount =
        this.formGroup.controls['accountConfirm'].value);
      const routingConfirm = (this.oldCnFRouting =
        this.formGroup.controls['routingConfirm'].value);

      const accountError =
        account == '' || accountConfirm == '' || account != accountConfirm;
      const routingError =
        routing == '' || routingConfirm == '' || routing != routingConfirm;

      if (accountType == null || accountType == undefined) {
        this.IsElectronicError = true;
        this.electronicErrorMsg = 'Select account type ';
        return false;
      }
      if (accountError || routingError) {
        this.IsElectronicError = true;
        this.electronicErrorMsg = 'Error : Valid response is required';
        this.formGroup.markAllAsTouched();
        return false;
      } else {
        this.IsElectronicError = false;
        this.electronicErrorMsg = '';
        this.status = 'review';

        this.reviewAccount =
          'XXXX' +
          this.getLastFourDigit(this.formGroup.controls['account'].value);
        this.reviewRouting =
          'XXXX' +
          this.getLastFourDigit(this.formGroup.controls['routing'].value);

        this.reviewAccountType =
          this.formGroup.controls['accountType'].value.toLowerCase() ==
          'savings'
            ? 'Savings'
            : 'Checking';

        return true;
      }
    } else {
      this.formGroup.markAllAsTouched();
      return false;
    }
  }

  acknowledge(event: any) {
    this.IsAcknowledge = event.checked;
  }

  getLastFourDigit(cntVal: string) {
    if (
      this.session.user &&
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      return 'XXXX';
    } else {
      return cntVal.substring(cntVal.length - 4);
    }
  }

  typeChange(event: any) {
    this.cdr.detectChanges();
  }

  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent, {
      disableClose: true,
    });
    dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.dialog.closeAll();
      }
    });
  }
}

type FormStatus = 'entry' | 'review' | 'submitted';

class bankdetails {
  RoutingNumber: string = '';
  RoutingNumberConfirm: string = '';
  AccountNumber: string = '';
  AccountNumberConfirm: string = '';
  AccountType: string = '';
}
