import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  BaseComponent,
  ConfigurationSetting,
  MyClaim,
  NewClaim,
  NotificationItem,
  TabGroupComponent,
  getUrlParams,
} from 'src/app/shared';
import { ClaimInfo, SearchResult } from 'src/app/shared/models/search-result';
import { ClaimDataService } from 'src/app/shared/services/claim-data.service';
import { ClaimantService, DisplayService, UserRoleService } from '../..';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { IClaimant } from '../labels/claimant-label.component';
import { ReportNewClaimComponent } from '../report-new-claim/report-new-claim.component';
import { AccommodationJobFunction } from '../../models/accommodation';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-myclaimspanel',
  template: ` <div id="myclaimspnl">
    <app-panel
      *ngIf="labelTemplateLoaded"
      title="{{ title }}"
      [showTitle]="true"
      [headerLevel]="1"
      [sentenceCase]="false"
      [largeHeader]="true"
      [labelTemplate]="labelTemplate"
      [canExpand]="false"
    >
      <ng-template #labelTemplate>
        <div class="tw-grow tw-items-center tw-justify-between tw-flex">
          <div>
            <a
              role="link"
              tabindex="0"
              *ngIf="showMgrViewLink"
              class="app-pl2 app-header-link"
              (keydown.enter)="redirectToMgrDb()"
              (click)="redirectToMgrDb()"
              >Manager View</a
            >
          </div>
          <span
            class="app-pr2 tw-text-[14px] app-color-slate tw-font-bold"
            *ngIf="!IsController"
            >{{ employeeLabel | titlecase }}</span
          >
        </div>
      </ng-template>
      <div class="tw-min-h-[800px]">
        <app-loading-panel
          [state]="
            !isMyClaimsLoaded() || !labelTemplateLoaded ? 'Loading' : 'Loaded'
          "
          loadingStyle="Image"
          loadingImageSrc="/assets/images/loader/my-claims-ghost.svg"
        >
          <ng-template #content>
            <app-reminders
              [myClaims]="myclaims"
              [commCenterMsgCount]="commCenterMsgCount"
              [activitiesMyClaims]="activitiesMyClaims"
              [mgrAction]="mgrAction"
              [myclaimsLoaded]="myclaimsLoaded"
              [showPaymentAlert]="setPaymentAlertVisible"
              *ngIf="
                myclaimsLoaded &&
                activitiesMyClaims &&
                (userRole.employee || userRole.claimant || isMgrViewingOwnDb) &&
                !userRole.controller
              "
              (focusTab)="handleFocus($event)"
              (filterApplied)="handleApplyFilter()"
            ></app-reminders>
            <app-manager-reminders
              *ngIf="
                myclaimsLoaded &&
                activitiesMyClaims &&
                ((userRole.manager && !isMgrViewingOwnDb) ||
                  userRole.controller)
              "
              [managerActivities]="activitiesMyClaims"
              [commCenterMsgCount]="commCenterMsgCount"
              [accommodationsCount]="accommodationsCount"
              [claims]="myclaims"
              (focusTab)="handleFocus($event)"
              (filterApplied)="handleApplyFilter()"
              [role]="role"
              class="tw-w-full"
            ></app-manager-reminders>
            <app-activity
              id="activityPnl"
              [claimInfo$]="claimInfo$"
              [isHomePage]="true"
              [activities]="activitiesMyClaims"
              (setPaymentAlertBool)="assignPaymentTabBool($event)"
              [isFilterApplied]="isFilterApplied"
              (clearFilter)="handleClearFilter()"
              [focusTab]="focusTab"
              [showTitle]="false"
              [flat]="true"
              [mgrAction]="mgrAction"
              *ngIf="isMyClaimsLoaded()"
              [listOfClaims]="myclaims"
              [ClientLevelConfig]="ClientLevelConfig"
              [accommodationsData]="accommodationsJobFunctions"
            ></app-activity>
          </ng-template>
        </app-loading-panel>
      </div>
    </app-panel>
  </div>`,
  styles: [``],
})
export class MyClaimsPanelComponent extends BaseComponent implements OnInit {
  @ViewChild('dialogReportNewClaim')
  dialogRefReportNewClaim: TemplateRef<any>;

  @ViewChild(TabGroupComponent, { static: false })
  tabGroup: TabGroupComponent;

  @Output()
  focusTab = new EventEmitter<string>();

  @Output()
  setPaymentAlertBool = new EventEmitter<boolean>();

  @Output()
  loaded = new EventEmitter();

  @Input()
  commCenterMsgCount: number = 0;

  @Input()
  isClaim: boolean = false;

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  ClientLevelConfig: ConfigurationSetting;

  @Input()
  claimMyClaims: SearchResult = <SearchResult>{};

  @Input()
  isHomePage: boolean = true;

  @Input()
  myclaims: MyClaim[];

  @Input()
  myclaimsLoaded: boolean = false;

  @Input()
  activitiesMyClaims: any;

  paymentsData: any = [];

  @Input()
  mgrAction: UserActions | null;

  @Input()
  hideNameColumn: boolean = false;

  @Input()
  claimant: IClaimant | null;

  @Input()
  employeeLabel: string;

  @Input()
  accommodationsJobFunctions: AccommodationJobFunction[] = [];

  @Input()
  accommodationsLoaded: boolean = false;

  accommodationsCount: number = 0;

  newClaims: NewClaim[];
  paymentTabVisible: boolean;
  isLoaded: boolean = false;
  yourClaim: boolean = false;
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  myClaims: MyClaim[];
  isIncidentalClient: boolean = false;
  isClaimant: boolean = false;
  isEmployee: boolean = false;
  isAuditor: boolean = false;
  showNewClaims: boolean;
  showNotifications: boolean = true;
  showOffWork: boolean = true;
  showMyClaims: boolean = false;
  hasReportClaim: boolean = false;
  showActivityFilter: boolean;
  reportAnAbsenceVisible: boolean;
  claimCount: number;
  lobs: any = [];
  lobmyclaims = ['PR', 'AU', 'GL'];
  lobExist: boolean = false;
  initialNotifications: NotificationItem[] = [];
  allComponentsLoaded: boolean = false;
  isManager: boolean = false;
  isEmpDb: boolean = false;
  emEmpName: string;
  title: string;
  isFilterApplied = false;
  showMgrViewLink: boolean = false;
  isMgrViewingOwnDb: boolean = false;
  labelTemplateLoaded: boolean = false;

  IsController: boolean = false;
  role: string;
  setPaymentAlertVisible: boolean;
  constructor(
    private session: Session,
    public claimConfigService: ConfigurationService,
    private claimDataService: ClaimDataService,
    public router: Router,
    public dialog: MatDialog,
    public claimService: ClaimService,
    public activityService: ActivityService,
    public userRole: UserRoleService,
    public display: DisplayService,
    private userAction: UserActionService,
    private titleCase: TitleCasePipe,
    public claimantService: ClaimantService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.role = this.session.user.role;
    this.IsController = this.userRole.controller;
    await this.loadLabelTemplate();
    this.labelTemplateLoaded = true;
    this.initialNotifications = this.activitiesMyClaims?.notifications;
    this.myclaims?.forEach((element) => this.lobs.push(element.lineOfBusiness));
    this.lobExist = this.lobs.every((value: string) => {
      return this.lobmyclaims.includes(value);
    });
    if (
      this.activitiesMyClaims != null ||
      this.activitiesMyClaims != undefined
    ) {
      this.isLoaded = true;
    }
    this.isClaimant = this.userRole.claimant;
    this.isEmployee = this.userRole.employee;
    this.isManager = this.userRole.manager;
    this.hasReportClaim = this.display.reportClaim;
    this.isIncidentalClient = this.display.incidentalClient;
    // this.paymentTabVisible = await this.showPaymentTab();
    //this.setPaymentAlertVisible = await this.showPaymentTab();
    //his.setPaymentAlertVisible = this.claimConfigService.getFeatureAccess('payments');
    this.showNewClaims = this.display.newClaims;
    this.activitiesMyClaims?.reminder?.forEach((element: any) => {
      if (element.alertType == 'RTW_CONFIRM_NEEDED_A') {
        this.claimCount = element.importantRemainders?.length;
      }
    });
    this.allComponentsLoaded = true;
    this.subs.sink = this.activityService.focusNotification.subscribe(
      (x: string) => {
        this.handleFocus(x);
      }
    );

    this.accommodationsCount = Array.from(
      new Set(
        this.accommodationsJobFunctions
          .filter(
            (x) =>
              x.decisionId === '1' &&
              x.status === 'Open' &&
              x.requestSubStatusTypeId === '24'
          )
          .map((x) => x.accommodationRequestNumber)
      )
    ).length;

    this.loaded.emit();
  }

  async loadLabelTemplate() {
    if (this.userRole.manager || this.userRole.controller) {
      const params = getUrlParams();

      if (params.emp) {
        //this.showMgrViewLink = true;
        this.title =
          this.titleCase.transform(this.claimant?.name) + ' - claims';
      }
      if (
        this.userRole.manager &&
        (this.mgrAction == UserActions.ViewOwnEmpDb || !params.emp)
      ) {
        this.showMgrViewLink = this.session.user.roles.supervisor;
        this.isMgrViewingOwnDb = true;
        this.title = 'My claims';
      }

      if (this.userRole.controller) {
        this.isMgrViewingOwnDb = true;
        this.showMgrViewLink = false;
        this.title = 'Manager view';
      }
    }
    if (this.userRole.employee || this.userRole.claimant) {
      this.title = 'My claims';
      this.employeeLabel = this.claimantService.GetEmployeeLabel(
        this.session.user?.firstName,
        this.session.user?.lastName,
        null,
        this.session.user?.employeeId
      );
    }
  }

  handleFocus(tabName: string) {
    this.focusTab.emit(tabName);
    const notification = document.getElementById('activityPnl');
    notification ? notification.scrollIntoView() : null;
  }

  get isRtw(): boolean {
    // Return to Work Feature not in scope, so just not displaying if we are on claim and client  doesnt have LOB WC
    //return !this.isClaim && this.claimConfigService.getFeatureAccess('ReturnToWork');
    const hasWc =
      this.session.user.lineOfBusiness.filter((l) => l.lob == 'WC').length > 0;
    return !this.isClaim && hasWc;
  }

  async showPaymentTab() {
    if (!this.isHomePage) {
      return (
        this.claimMyClaims &&
        this.claimConfigService.getFeatureAccess('payments')
      );
    }
    return (
      this.claimConfigService.getConfigFeatureAccess('payments') &&
      !this.session.user.isClaimClosed
    );
  }

  reportClaim() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
    };
    this.dialog.open(ReportNewClaimComponent, dialogConfig);
  }

  handleClearFilter() {
    this.isFilterApplied = false;
    srSpeak('Filter has been cleared', 'polite');
    this.isLoaded = true;
  }

  assignPaymentTabBool(event: any) {
    this.setPaymentAlertVisible = event;
  }

  handleApplyFilter() {
    this.isFilterApplied = true;
    srSpeak(
      'The list has been filtered to show the notifications for a reminder',
      'polite'
    );
    this.isLoaded = true;
  }

  isMyClaimsLoaded() {
    return (
      (this.isLoaded && this.accommodationsLoaded) ||
      (this.myclaimsLoaded && this.accommodationsLoaded) ||
      (this.userRole.controller &&
        this.myclaimsLoaded &&
        !this.accommodationsLoaded)
    );
  }

  isPaymentsLoaded() {
    if (this.myclaimsLoaded === true && this.activitiesMyClaims?.payments) {
      this.paymentsData = this.activitiesMyClaims.payments;
      return true;
    }
    return false;
  }

  redirectToMgrOwnDb() {
    this.userAction.removeClaimant();
    this.userAction.setAction(UserActions.ViewOwnEmpDb);
    this.router.navigate(['/dashboard']);
  }

  redirectToMgrDb() {
    this.userAction.removeClaimant();
    this.userAction.setAction(UserActions.ViewOwnMgrDb);
    this.router.navigate(['/supervisor']);
  }
}
