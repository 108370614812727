import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { BaseControlComponent } from './base-control.component';
import { srSpeak } from 'src/app/shared';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-control',
  template: `
    <div class="app-pr1 app-pb1 datepick">
      <mat-form-field [hintLabel]="hintLabel" [formGroup]="formGroup">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          #input
          [placeholder]="placeHolder"
          [formControlName]="formControlName"
          [matDatepicker]="picker"
          autocomplete="off"
          [min]="minValue"
          [max]="maxValue"
          (dateChange)="onDateChange($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startAt"></mat-datepicker>

        <mat-error
          *ngIf="!formControl.valid"
          class="errormsg"
          [ngClass]="errormsgClass"
          [attr.aria-label]="label + ' is ' + errorMessage"
          >{{ errorMessage }}</mat-error
        >
        <mat-error *ngIf="formControl.hasError('matDatepickerMin')"
          >Date should be inferior</mat-error
        >
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .datepick mat-label {
        color: #67707a;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep .mat-form-field-outline {
        color: #67707a;
      }

      .datepick:focus mat-label {
        color: #171c20;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep .mat-form-field-outline-thick .mat-focused {
        color: #171c20 !important ;
        border-color: #007dbc !important ;
      }
      .errormsg {
        color: #c24533;
      }
      .errormsgabsence {
        color: var(--mdc-theme-error, #f44336) !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep
        .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
        .mat-form-field-outline-thick {
        color: #c24533 !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep
        .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
        .mat-form-field-outline-thick:hover {
        color: #3f464d !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-focused::ng-deep .mat-form-field-outline {
        color: #007dbc !important;
      }
    `,
  ],
})
export class DateControlComponent
  extends BaseControlComponent
  implements OnInit, AfterViewChecked
{
  @HostBinding('attr.ngDefaultControl')
  ngDefaultControl = '';

  @Input()
  errormsgClass: string = '';

  @Input()
  minValue: Date;

  @Input()
  maxValue: Date;

  @Input()
  startAt: Date;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.removeAriaPressed();
    this.setAriaCurrent();
  }

  private removeAriaPressed(): void {
    const buttons = document.querySelectorAll('button[aria-pressed]');
    if (buttons.length > 0) {
      buttons.forEach((button) => {
        button.removeAttribute('aria-pressed');
        button.setAttribute('aria-label', button.getAttribute('title') || '');
        button.removeAttribute('title');
      });
    }
  }

  private setAriaCurrent(): void {
    const selectedDateButton = this.el.nativeElement.querySelector(
      '.mat-calendar-body-selected button'
    );
    if (selectedDateButton) {
      this.renderer.setAttribute(selectedDateButton, 'aria-current', 'date');
    }
  }

  onDateChange(event: any): void {
    const selectedDate = event.value;
    if (selectedDate) {
      const formattedDate = this.datePipe.transform(selectedDate, 'fullDate');
      srSpeak(`Selected date is ${formattedDate}`, 'polite');
    }
  }
}
