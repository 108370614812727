import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoadingState, SearchResult } from 'src/app/shared';
import { CommCenterService, SearchService, Session } from '../..';
import {
  DetailSearchConfiguration,
  SearchRequest,
} from '../search/detail-search-form.component';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-comm-center2-search',
  template: `
    <app-detail-search-form
      [config]="config"
      (search)="searchClaims($event)"
      (clear)="loading = 'Init'"
      [loading]="loading"
      [excludeLeaveClaims]="excludeLeaveClaims"
      loadingImageSrc="/assets/images/loader/search-results-ghost.svg"
    >
      <ng-template #results>
        <app-search-count-row
          [count]="searchResults.length"
        ></app-search-count-row>

        <app-list
          [dataSource]="searchResults"
          [filterEnabled]="true"
          [paginatorEnabled]="true"
          [exportEnabled]="false"
          [template]="row"
          [pageSize]="10"
          (rowClick)="rowClick($event)"
          [tableAriaLabel]="'Search results'"
        >
          <ng-container header>
            <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
              <app-list-header
                name="claimDescription"
                label="Claim"
                class="tw-w-full md:tw-w-[35%]"
                [names]="['claimNumber']"
              ></app-list-header>
              <app-list-header
                name="name"
                label="Name"
                class="tw-w-full md:tw-w-[25%]"
                [names]="['empId']"
              ></app-list-header>
              <app-list-header
                name="status"
                label="Status"
                class="tw-w-full md:tw-w-[15%]"
                [names]="['subStatus']"
              ></app-list-header>
              <app-list-header
                name="date"
                label="Date"
                filterType="Date"
                class="tw-w-full md:tw-w-[10%]"
                [names]="['date']"
              ></app-list-header>
              <app-list-header
                readOnly
                label="Action"
                class="tw-w-full md:tw-w-[15%]"
              ></app-list-header>
              <app-list-header
                name="unread"
                label="New"
                class="tw-hidden"
              ></app-list-header>
            </tr>
          </ng-container>
          <ng-template #row let-element>
            <tr
              class="app-pointer tw-flex tw-flex-wrap tw-w-full tw-items-center"
            >
              <td class="tw-w-full md:tw-w-[35%]">
                <app-value
                  label="Claim"
                  layout="row-xs"
                  labelStyle="pmt-header-init"
                  [tabIndex]="0"
                >
                  <app-claim-label
                    value
                    [claim]="element"
                    layout="row"
                    [disabled]="true"
                    [noLink]="true"
                    [hideWatchList]="false"
                  ></app-claim-label>
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[25%]">
                <app-value label="Name" tabindex="0" layout="row-xs">
                  <app-claimant-label
                    value
                    [claimant]="element"
                    [disabled]="true"
                  ></app-claimant-label>
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[15%]">
                <app-value
                  label="Status"
                  tabindex="0"
                  [value]="[element.status, element.subStatus] | join"
                  layout="row-xs"
                >
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[10%]">
                <app-value
                  label="Date"
                  [tabindex]="element.date ? '0' : '-1'"
                  layout="row-xs"
                >
                  <span value>
                    {{ element.date | format : 'date' }}
                    <span *ngIf="element.unread" class="app-indicator tw-ml-1"
                      >NEW</span
                    >
                  </span>
                </app-value>
              </td>
              <td
                class="tw-w-full md:tw-w-[15%]"
                tabindex="element.conversationDetailsExist ? '0' : '-1'"
              >
                @if (element.conversationDetailsExist) {
                <a
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="
                    'View conversation for ' +
                    element.claimDescription +
                    '-' +
                    element.claimNumber
                  "
                  >View Conversation
                </a>
                } @else if (element.open) {
                <a
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="
                    'Start conversation for ' +
                    element.claimDescription +
                    '-' +
                    element.claimNumber
                  "
                  >Start Conversation
                </a>
                }
              </td>
            </tr>
          </ng-template>
        </app-list>
      </ng-template>
    </app-detail-search-form>
  `,
  styles: ``,
})
export class CommCenter2SearchComponent implements OnInit {
  @Output()
  selected = new EventEmitter<SearchResult>();

  config: DetailSearchConfiguration = new DetailSearchConfiguration();

  loading: LoadingState = 'Init';

  searchResults: SearchResult[];

  excludeLeaveClaims: boolean = false;

  async ngOnInit() {
    this.config.initialSearchIcon = 'message';
    this.config.initialSearchHeader = 'Start conversation';
    this.config.initialSearchMsg =
      'Search for a claim then click start conversation';
    this.config.emptyFormAllowed = false;
    this.config.payType.visible = false;
    this.excludeLeaveClaims =
      this.session.user.leaveType &&
      this.session.user.leaveType.toLowerCase() === 'leavelink'
        ? true
        : false;
  }

  constructor(
    private searchService: SearchService,
    public commCenterService: CommCenterService,
    public session: Session
  ) {}

  async searchClaims(searchRequest: SearchRequest) {
    this.loading = 'Loading';
    const searchInformation = await this.searchService.getSearchResults(
      searchRequest.getParameters()
    );

    this.searchResults = searchInformation.searchResults;
    const conversations =
      await this.commCenterService.getManagerConversations();
    for (const searchResult of searchInformation.searchResults) {
      const r: any = searchResult;
      const searchConv = conversations.find(
        (c) => c.claimId === r.claimId && c.source === r.source
      );
      r.date = searchConv?.date;
      r.unread = searchConv?.unread;
      r.open = r.status.toLowerCase().indexOf('close') < 0;
      r.conversationDetailsExist =
        this.commCenterService.conversationDetailsExist(r.claimId, r.source);
    }

    this.loading = this.searchResults.length > 0 ? 'Loaded' : 'Empty';
    if (this.searchResults && this.searchResults.length === 0) {
      srSpeak(
        'No results.There are no results that match the search',
        'polite'
      );
    }
  }

  rowClick(result: SearchResult) {
    this.selected.emit(result);
  }
}
