import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MySedgwickModule } from './my-sedgwick/my-sedgwick.module';

//const titleSuffix = ' - mySedgwick';

const routes: Routes = [
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: DashboardPageComponent,
  //       title: 'Home' + titleSuffix,
  //     },
  //     {
  //       path: 'claims',
  //       component: ClaimsPageComponent,
  //       title: 'Claims' + titleSuffix,
  //     },
  //     {
  //       path: 'myclaims',
  //       component: ClaimsPageComponent,
  //       canActivate: [ClaimantGuard],
  //       title: 'My Claims' + titleSuffix,
  //     },
  //     //{ path: 'activity', component: ActivityPageComponent },
  //     {
  //       path: 'search',
  //       component: SearchPageComponent,
  //       canActivate: [ManagerGuard],
  //       title: 'Search' + titleSuffix,
  //     },
  //     {
  //       path: 'watchlist',
  //       component: WatchListPageComponent,
  //       canActivate: [ManagerGuard],
  //       title: 'Watch List' + titleSuffix,
  //     },
  //     {
  //       path: 'history',
  //       component: ClaimHistoryPageComponent,
  //       canActivate: [ManagerGuard],
  //       title: 'History' + titleSuffix,
  //     },
  //     //{ path: 'recentclaims', component: RecentClaimsPageComponent },
  //     {
  //       path: 'securityquestions',
  //       component: SecurityQuestionsPageComponent,
  //       title: 'Security Questions' + titleSuffix,
  //     },
  //     {
  //       path: 'documentsign',
  //       component: DocumentSignPageComponent,
  //       title: 'Sign Document' + titleSuffix,
  //     },

  //     {
  //       path: 'help',
  //       component: HelpPageComponent,
  //       title: 'Help' + titleSuffix,
  //     },
  //     {
  //       path: 'design',
  //       component: DesignPageComponent,
  //       title: 'Design' + titleSuffix,
  //     },
  //   ],
  // },
  {
    path: '',
    loadChildren: () => MySedgwickModule,
    //import('./my-sedgwick/my-sedgwick.module').then(
    //  (m) => m.MySedgwickModule
    // ),
  },
  // {
  //   path: 'empty',
  //   component: EmptyPageComponent,
  //   title: 'Empty' + titleSuffix,
  // },
  // {
  //   path: 'unauthorized',
  //   component: UnauthorizedPageComponent,
  //   title: 'Unauthorized' + titleSuffix,
  // },

  // {
  //   path: '**',
  //   component: NotFoundPageComponent,
  //   title: 'Not Found' + titleSuffix,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
