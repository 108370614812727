import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-panel',
  template: `
    <div
      *ngIf="border === 'Empty'"
      class="tw-rounded-lg content tw-w-full tw-h-full tw-overflow-hidden"
      [ngClass]="{
        'tw-drop-shadow-none': !elevated,
        'tw-drop-shadow-xl': elevated,
      }
      "
      [style.min-height]="minHeight"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div
      *ngIf="border !== 'Empty'"
      class="tw-rounded-lg content tw-w-full tw-h-full tw-overflow-hidden tw-bg-white"
      [ngClass]="{
        'flat-panel': border === 'Flat',
        'flat-padded-panel': border === 'FlatPadded',
        'single-panel': border === 'Single',
        'tw-drop-shadow-none': !elevated,
        'tw-drop-shadow-xl': elevated
      }"
      [style.min-height]="minHeight"
    >
      <!-- removed header as a clickable element as it was affecting keyboard access for links coming through in templaterefs 
       my claims link , manager view link, help icon click -->
      <div
        class="tw-w-full tw-flex tw-flex-wrap tw-items-center tw-pt-0.5 tw-pb-1"
        *ngIf="showTitle"
      >
        <ng-container [ngSwitch]="headerLevel">
          <h1
            *ngSwitchCase="1"
            class="tw-grow-0 tw-pr-2 tw-py-2"
            [ngClass]="{
              'app-header-2': !largeHeader,
              'app-header-1': largeHeader
            }"
          >
            {{ sentenceCase ? (title | sentenceCase) : title }}
          </h1>
          <h2
            *ngSwitchCase="2"
            class="tw-grow-0 tw-pr-2 tw-py-2"
            [ngClass]="{
              'app-header-2': !largeHeader,
              'app-header-1': largeHeader
            }"
          >
            {{ sentenceCase ? (title | sentenceCase) : title }}
          </h2>
          <h3
            *ngSwitchCase="3"
            class="tw-grow-0 tw-pr-2 tw-py-2"
            [ngClass]="{
              'app-header-2': !largeHeader,
              'app-header-1': largeHeader
            }"
          >
            {{ sentenceCase ? (title | sentenceCase) : title }}
          </h3>
          <h4
            *ngSwitchCase="4"
            class="tw-grow-0 tw-pr-2 tw-py-2"
            [ngClass]="{
              'app-header-2': !largeHeader,
              'app-header-1': largeHeader
            }"
          >
            {{ sentenceCase ? (title | sentenceCase) : title }}
          </h4>
          <h5
            *ngSwitchCase="5"
            class="tw-grow-0 tw-pr-2 tw-py-2"
            [ngClass]="{
              'app-header-2': !largeHeader,
              'app-header-1': largeHeader
            }"
          >
            {{ sentenceCase ? (title | sentenceCase) : title }}
          </h5>
        </ng-container>
        <div class="tw-grow tw-flex tw-items-left">
          <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
        </div>
        @if( canExpand ){
        <button
          [ngClass]="{ 'app-pointer': canExpand }"
          [attr.aria-expanded]="expanded"
          [attr.aria-label]="
            expanded
              ? 'Collapse ' + title + ' panel'
              : 'Expand ' + title + ' panel'
          "
          [attr.aria-disabled]="!canExpand"
          [attr.aria-controls]="panelId"
          (click)="toggleExpand(canExpand)"
        >
          <mat-icon class="expand-icon">{{
            expanded ? 'expand_less' : 'expand_more'
          }}</mat-icon>
        </button>
        }
      </div>
      <div
        [id]="panelId"
        role="region"
        class="tw-w-full"
        [hidden]="!expanded"
        [class.tw-pt-3]="showTitle"
        [class.tw-pb-4]="showTitle"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [
    `
      .expand-icon {
        color: var(--color-slate);
        cursor: pointer;
      }

      .flat-panel {
        padding: 4px 0px 0px 0px;
        background-color: white;
      }

      .flat-padded-panel {
        padding: 0px 16px 0px 16px;
        background-color: white;
      }

      .single-panel {
        padding: 0px 22px 0px 22px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 12px !important;
      }

      :host {
        display: block;
        padding: 8px;
      }

      @media (max-width: 480px) {
        .single-panel {
          padding: 0px 8px 8px 8px;
          background-color: white;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 12px !important;
        }

        .flat-padded-panel {
          padding: 8px;
          background-color: white;
        }

        :host {
          display: block;
          padding: 4px;
        }
      }

      .content {
        font-size: 14px;
      }
    `,
  ],
})
export class PanelComponent {
  @Input()
  showTitle = true;

  @Input()
  headerLevel: HeaderLevel = 1;

  @Input()
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
    this.elementRef.nativeElement.title = '';
  }

  @Input()
  border: PanelBorderType = 'Single';

  @Input()
  set flat(value: boolean) {
    this.border = value ? 'Flat' : 'Single';
    this.elevated = !value;
  }
  get flat(): boolean {
    return this.border === 'Flat';
  }

  @Input()
  largeHeader = false;

  @Input()
  expanded: boolean = true;

  @Input()
  elevated: boolean = false;

  @Input()
  actionTemplate: TemplateRef<any>;

  @Input()
  labelTemplate: TemplateRef<any>;

  @Input()
  sentenceCase = true;

  @Input()
  canExpand: boolean = true;

  @Input()
  overflowHeight: string = null;

  @Input()
  minHeight: string = null;

  @Input()
  panelId: string = '';

  private _title: string;

  constructor(private elementRef: ElementRef) {}

  toggleExpand(canToggle: boolean = true) {
    if (canToggle && this.canExpand) {
      this.expanded = !this.expanded;
    }
  }
}

export type PanelBorderType =
  | 'Flat'
  | 'FlatPadded'
  | 'Single'
  | 'Empty'
  | 'None';

export type HeaderLevel = 1 | 2 | 3 | 4 | 5;
