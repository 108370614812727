import { AdditionalUserDetails } from "src/app/my-sedgwick/models/additionaluserdetails";

export class ConfigurationSetting {
   //Keeping class with one property. We might have to add few more.
  features: string[]; // filter LOB based features
  lob: string; //Track what LOB properties are loaded.('**')
  configFeatures: AdditionalUserDetails; // this has aliases and client level features
}

export class ConfigurationFeature {
  featureName: string;
  lob: string;
  role: string;
}


