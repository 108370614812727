import { CurrencyPipe, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  transform(value: any, format: FormatType): string {
    try {
      if (format === 'date') {
        const datePipe: DatePipe = new DatePipe('en-US');
        return datePipe.transform(value, 'M/d/yyyy');
      } else if (format === 'dateTime') {
        const datePipe: DatePipe = new DatePipe('en-US');
        return datePipe.transform(value, 'M/d/yyyy h:mm a');
      } else if (format === 'currency') {
        const currencyPipe: CurrencyPipe = new CurrencyPipe('en-US');
        return currencyPipe.transform(value);
      } else if (format === 'phone') {
        const temp: string = <any>value;
        if (temp?.length !== 10) {
          return temp;
        }

        const areaCode = temp.slice(0, 3);
        const exchange = temp.slice(3, 6);
        const lineNumber = temp.slice(6, 10);
        return `(${areaCode}) ${exchange}-${lineNumber}`;
      }
    } catch (error) {
      console.error(error);
    }

    return value;
  }
}

export type FormatType = 'date' | 'dateTime' | 'currency' | 'phone';
