import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { AuthPreference } from '../models/AuthPreference';
import { Schedule } from '../models/Schedule';
import { UserPreference } from '../models/UserPreference';
import { VerifyPreferencesResponse } from '../models/verifyPreferencesResponse';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  constructor(private http: Http, public config: ConfigurationService) {}

  async getManagementNotifications(): Promise<Schedule> {
    return await this.http.get<Schedule>(
      `/api/user/GetManagementNotifications`
    );
  }

  async SetManagementNotifications(request: any): Promise<boolean> {
    return await this.http.post<boolean>(
      `/api/user/SetManagementNotifications`,
      request
    );
  }

  async setPassword(request: any): Promise<any> {
    return await this.http.post<any>(
      `/api/user/SetPasswordAndExpiration`,
      request
    );
  }

  async setSecurityQuestions(request: any) {
    await this.http.post<void>(`/api/user/EditSecurityQuestions`, request);
  }

  async getAuthPreferences(): Promise<AuthPreference> {
    return await this.http.get<AuthPreference>(`/api/user/GetAuthPreferences`, {
      cache: false,
      timeout: 0,
    });
  }

  async verifyPreferenceCode(request: any): Promise<VerifyPreferencesResponse> {
    return await this.http.post<VerifyPreferencesResponse>(
      `/api/user/VerifyCodeAuth`,
      request
    );
  }

  async editPreference(request: any): Promise<any> {
    return await this.http.post<any>(`/api/user/EditAuthPreferences`, request);
  }

  async getCommPreferences(): Promise<UserPreference> {
    if (this.config.getConfigFeatureAccess('notificationpreferences')) {
      return await this.http.get<UserPreference>(
        `/api/user/GetCommPreferences`,
        {
          cache: true,
          timeout: 0,
        }
      );
    } else {
      return null;
    }
  }

  async setCommPreferences(request: any): Promise<boolean> {
    this.http.deleteCacheEntry(`/api/user/GetCommPreferences`);
    return await this.http.post<boolean>(
      `/api/user/SetCommPreferences`,
      request
    );
  }

  async CreateSendCode(request: any): Promise<VerifyPreferencesResponse> {
    const test = await this.http.post<any>('/api/user/CreateSendCode', request);
    return test;
  }
}
