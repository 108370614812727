import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { OffWorkCalendarEntry } from '../../models/off-work-calendar-entry';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import {
  CalendarEvent,
  CsvParams,
  CsvService,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { DatePipe } from '@angular/common';
import { parse } from 'date-fns';
import { StatusEvent } from 'src/app/shared/models/StatusEvent';
import { index } from 'd3';

@Component({
  selector: 'app-off-work-export',
  template: `
    <div class="overlay">
      <div mat-dialog-title class="">
        <app-action-row>
          <h2 left1 class="app-header-2 heading-popup">
            Export claims calendar
          </h2>

          <div right1>
            <button
              mat-icon-button
              (click)="closeModalPopUp()"
              aria-label="ariaLabelMessage"
              tabindex="0"
              class="closebtn"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </app-action-row>
      </div>
      <mat-dialog-content class="modal-body fltleft">
        <div class="tw-w-full line"></div>
        <div class="tw-w-full defaultClr">
          To export CSV, please select date range
        </div>
        <div class="tw-w-full app-pt2 app-pb1">
          <form [formGroup]="formGroup">
            <div class="tw-flex tw-flex-wrap">
              <div class="md:tw-w-[50%] tw-w-full">
                <app-date-control
                  label="Start Date"
                  ngDefaultControl
                  [formGroup]="formGroup"
                  formControlName="startdate"
                  [validationControl]="'Start Date'"
                ></app-date-control>
              </div>
              <div class="md:tw-w-[50%] tw-w-full">
                <app-date-control
                  label="End Date"
                  ngDefaultControl
                  [formGroup]="formGroup"
                  formControlName="enddate"
                  [validationControl]="'End Date'"
                ></app-date-control>
              </div>
            </div>
          </form>
        </div>
        <div
          ng-hide="!showMessage"
          [innerHTML]="showMessage ? errorMessage : ''"
          class="error-validation"
        ></div>
        <div class="tw-flex tw-flex-wrap tw-w-full">
          <div class="md:tw-w-[25%] tw-w-full"></div>
          <div class="md:tw-w-[75%] tw-w-full defaultClr">
            This information is sensitive and should not be distributed.
          </div>
        </div>
        <div class="tw-w-full line"></div>
      </mat-dialog-content>
      <mat-dialog-actions align="end" class="mySedwick-popupFooter">
        <button
          mat-button
          mat-raised-button
          class="btn-info exportbtn roundbtn"
          (click)="exportcsv()"
          [attr.aria-label]="exportAL"
          title="Export records"
        >
          Export
        </button>
        <button
          mat-button
          alt="Close"
          [mat-dialog-close]="true"
          mat-raised-button
          class="btn-info closebtn roundbtn"
          (click)="closeModalPopUp()"
        >
          Back
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
    `
      .line {
        border-top: 1px solid #d2d8e1;
      }
      .exportbtn {
        background-color: var(--color-sedgwick-blue);
        color: #fff;
      }
      .error-validation {
        color: red;
      }
      .defaultClr {
        color: #171c20 !important;
      }
    `,
  ],
})
export class OffWorkExportComponent implements OnInit {
  @Input()
  offworkdata: StatusEvent[] = [];

  @Input()
  offworkEventData: CalendarEvent[] = [];

  showMessage: boolean = false;

  errorMessage: string = '';
  confirmAltText: string = 'Export off work calendar';
  confirmTitleText: string = 'Export';
  closeAltText: string = 'Close';
  closeTitleText: string = 'Back';
  saveAlertAction: string;
  exportAL: string = 'Export records';
  formGroup: FormGroup = this.fb.group({
    startdate: [this.getCurrentDateByCtr(), [Validators.required]],
    enddate: [this.getCurrentDateByCtr(), [Validators.required]],
  });

  getCurrentDateByCtr() {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toISOString().substring(0, 10);
  }
  constructor(
    public dialogRef: MatDialogRef<OffWorkExportComponent>,
    private fb: FormBuilder,
    private csvService: CsvService,
    private datePipe: DatePipe,
    private snackbar: SnackbarService,
    private cdr: ChangeDetectorRef
  ) {
    // console.log((new Date()).toISOString().substring(0,10))
    /*this.formGroup.controls['startdate'].addValidators(
      customvalidations.dateLessThan(
        this.formGroup.controls['enddate'],
        false,
        'Start Date cannot be later than the End Date.'
      )
    );*/

    this.formGroup.controls['enddate'].addValidators(
      customvalidations.greaterThan(
        this.formGroup.controls['startdate'],
        false,
        'End Date cannot be earlier than the Start Date.'
      )
    );
  }
  // ngAfterViewInit(): void {
  //   //return;
  //   this.cdr.detach();
  //   this.formGroup.controls['enddate'].addValidators(
  //     customvalidations.greaterThan(
  //       this.formGroup.controls['startdate'],
  //       false,
  //       'End Date cannot be earlier than the Start Date.'
  //     )
  //   );
  // }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  submitAlertAction(request: any) {}

  closeModalPopUp() {
    this.dialogRef.close();
  }

  compareTwoDatesWithoutTime(firstDate: Date, secondDate: Date) {
    let dateF = new Date(
      firstDate.getFullYear(),
      firstDate.getMonth(),
      firstDate.getDate()
    );
    let dateS = new Date(
      secondDate.getFullYear(),
      secondDate.getMonth(),
      secondDate.getDate()
    );

    if (dateF <= dateS) {
      return true;
    } else {
      return false;
    }
  }

  exportcsv() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid || !this.ValidateDateDifference()) {
      this.showMessage = true;
      return;
    } else if (this.formGroup.valid) {
      this.showMessage = false;
      let csvParams = new CsvParams();
      csvParams.fileName = 'Claims calendar_' + this.getCurrentDate();
      let finalFilterdData: StatusEvent[] = [];
      let selectedStartDate = this.getDateTime(
        this.formGroup.get('startdate').value
      );
      let selectedEndDate = this.getDateTime(
        this.formGroup.get('enddate').value
      );

      if (this.offworkEventData.length > 0) {
        this.offworkEventData.forEach((val, index) => {
          let executefilter: boolean = false;

          if (
            this.compareTwoDatesWithoutTime(
              selectedStartDate,
              new Date(val.end)
            ) &&
            this.compareTwoDatesWithoutTime(
              new Date(val.start),
              selectedEndDate
            )
          ) {
            // if( selectedStartDate <= new Date(val.start) && (selectedEndDate <= new Date(val.end) ) )
            // {

            //     val.data.entry.beginDate =  this.getFormattedDate(val.start) ;
            //     val.data.entry.endDate = this.getFormattedDate(selectedEndDate);
            //     executefilter = true;
            // }

            if (
              !executefilter &&
              selectedStartDate >= new Date(val.start) &&
              selectedEndDate <= new Date(val.end)
            ) {
              val.data.entry.beginDate =
                this.getFormattedDate(selectedStartDate);
              val.data.entry.endDate = this.getFormattedDate(selectedEndDate);
              executefilter = true;
            }

            if (
              !executefilter &&
              selectedStartDate <= new Date(val.start) &&
              selectedEndDate <= new Date(val.end)
            ) {
              val.data.entry.beginDate = this.getFormattedDate(val.start);
              val.data.entry.endDate = this.getFormattedDate(selectedEndDate);
              executefilter = true;
            }

            if (
              !executefilter &&
              selectedStartDate <= new Date(val.start) &&
              selectedEndDate >= new Date(val.end)
            ) {
              val.data.entry.beginDate = this.getFormattedDate(val.start);
              val.data.entry.endDate = this.getFormattedDate(val.end);
              executefilter = true;
            }

            if (
              !executefilter &&
              selectedStartDate >= new Date(val.start) &&
              selectedEndDate >= new Date(val.end)
            ) {
              val.data.entry.beginDate =
                this.getFormattedDate(selectedStartDate);
              val.data.entry.endDate = this.getFormattedDate(val.end);
              executefilter = true;
            }

            //edge case for Leavelink claims as the status is set to absence level status need to adjust to claim level status
            {
              if (
                val.data.entry.lob === 'LV' &&
                val.data.entry.source === 'L'
              ) {
                val.data.entry.status = val.data.status;
              }
            }

            if (executefilter) {
              finalFilterdData.push(val.data.entry);
            }
          }
        });
      }

      this.exportAL = 'export file is downloading';
      if (finalFilterdData.length == 0) {
        this.snackbar.show('No records found to export !', SnackbarType.Info);
      } else {
        finalFilterdData.forEach((val, ind) => {
          if (
            val.lob.toLowerCase() == 'lv' &&
            val.claimDescription.toLowerCase() != 'reduced work schedule' &&
            val.source !== 'L'
          ) {
            //let statusData = val.status.split('-');
            val.status = val.absenceStatus;
            val.subStatus = '';
          } else if (
            val.lob.toLowerCase() == 'lv' &&
            val.claimDescription.toLowerCase() != 'reduced work schedule' &&
            val.source === 'L'
          ) {
            if (val.status) {
              const [status, subStatus] = val.status.split('-');
              val.status = status.trim();
              val.subStatus = subStatus ? subStatus.trim() : '';
            }
          } else if (
            val.claimDescription.toLowerCase() == 'reduced work schedule'
          ) {
            val.status = val.absenceStatus;
            val.subStatus = val.subStatus;
            if (
              val.absenceReason != undefined &&
              val.absenceReason != null &&
              val.absenceReason.trim().length > 2
            ) {
              val.subStatus = val.subStatus + ' ( ' + val.subStatus + ' )';
            } else {
              val.subStatus = 'None ( ' + val.subStatus + ' )';
            }
          }

          if (val.lob.toLowerCase() !== 'lv') {
            if (
              val.statusCode != undefined &&
              val.statusCode.toLowerCase() == 'wp'
            ) {
              val.subStatus = 'Waiting Period';
            }

            if (
              val.statusCode != undefined &&
              val.statusCode.toLowerCase() == 'op'
            ) {
              val.subStatus = 'Pending';
            }
            if (
              val.statusCode != undefined &&
              val.statusCode.toLowerCase() == 'oa'
            ) {
              val.subStatus = 'Approved';
            }

            if (
              val.statusCode != undefined &&
              (val.statusCode.toLowerCase() == 'dp' ||
                val.statusCode.toLowerCase() == 'da' ||
                val.statusCode.toLowerCase() == 'od')
            ) {
              val.status = 'Denied';
            }
          }
        });
      }
      csvParams.rows = finalFilterdData;
      csvParams.keys = [
        'name',
        'claimNumber',
        'claimDescription',
        'beginDate',
        'endDate',
        'status',
        'subStatus',
        'hoursMissed',
        'minutesMissed',
        'absenceReason',
      ];
      csvParams.headers = [
        'Name',
        'Claim Number',
        'Type',
        'First day of absence',
        'Last day of absence',
        'Status',
        'Sub Status',
        'Hours absence',
        'Minutes missed',
        'Reason for absence',
      ];
      csvParams.footerNote =
        'This report contains information that may be confidential and/or privileged. The information is intended solely for the individual or entity running the report and access by anyone else is unauthorized.\n If you are not the individual to whom this information refers any disclosure copying distribution or use of the contents of this information is prohibited and may be unlawful.';
      this.csvService.download(csvParams);
      this.exportAL = 'export file is downloaded';
    }
    //return false;
  }

  getDateTime = (type: string): Date => {
    return new Date(type); //.toUTCString());
  };

  getCurrentDate = (): string => {
    var rightNow = new Date();
    var res = rightNow
      .toISOString()
      .substring(0, 16)
      .replace('T', '')
      .replace(/-/g, '')
      .replace(/:/g, '');
    return res;
  };

  getFormattedDate(selecteddate: Date) {
    let date = new Date(selecteddate);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }
  ValidateDateDifference() {
    var fromDateVal = new Date(this.formGroup.get('startdate').value);
    var toDateVal = new Date(this.formGroup.get('enddate').value);

    if (
      fromDateVal != undefined &&
      fromDateVal != null &&
      fromDateVal.toDateString().length > 7 &&
      toDateVal != undefined &&
      toDateVal != null &&
      toDateVal.toDateString().length > 7
    ) {
      let startDateVal = new Date(fromDateVal);
      let endDateVal = new Date(toDateVal);
      let finalEndDateYear = endDateVal.getFullYear() - 2;
      let finalEndDate = new Date(
        finalEndDateYear,
        endDateVal.getMonth(),
        endDateVal.getDate()
      );

      const monthsDiff =
        (endDateVal.getFullYear() - startDateVal.getFullYear()) * 12 +
        (endDateVal.getMonth() - startDateVal.getMonth());
      // const monthdiff= Math.abs(monthsDiff);
      if (finalEndDate >= startDateVal) {
        this.errorMessage = 'Please select a date range within two years.';
        return false;
      } else {
        this.errorMessage = '';
      }
    }
    return true;
  }
}
