import { Injectable } from '@angular/core';
import { Http, ManagerResult, ManagerSearch } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class SearchApi {
  constructor(private http: Http) {}

  getManagers(
    name: string,
    supervisor: boolean,
    omni: boolean,
    maxResults: number = 10
  ): Promise<ManagerSearch> {
    return this.http
      .get<ManagerResult[]>(
        `/api/manager/${name}/${maxResults}/${supervisor}/${omni}/manager-list`
      )
      .then((managers) => new ManagerSearch(name, managers));
  }

  getDelegators(searchFilter: string): Promise<ManagerSearch> {
    return this.http
      .post<ManagerResult[]>('api/delegation/availableDelegators', {
        searchFilter,
      })
      .then((managers) => new ManagerSearch(searchFilter, managers));
  }
}
