import { Injectable} from '@angular/core';
import {
  Router,
} from '@angular/router';
import { Session } from 'src/app/my-sedgwick';
import { DisplayService } from '../../services/display-service';

@Injectable({
  providedIn: 'root',
})
export class ManageDelegateGuard {
  constructor(
    private router: Router,
    private session: Session,
    private displayService: DisplayService,
  ) {}

  // guard for both manager and controller
  async canActivate() {
    await this.session.loadUser();
    const delegationFeatures = await this.displayService.delegationFeatures();
    if (delegationFeatures.manageOthersDelegates === true || delegationFeatures.manageOwnDelegates === true) {
      return true;
    } else {
      this.router.navigate(['/unauthorized']);
      return false;
    }
  }
}
