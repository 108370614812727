import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-checkbox-control',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxControlComponent),
      multi: true,
    },
  ],
  template: `
    <div class="app-pr1 app-pb1" [formGroup]="formGroup">
      <mat-checkbox
        [checked]="formControl.value"
        [disabled]="disabled"
        (change)="formControl.setValue($event.checked)"
        (blur)="onTouch()"
        >{{ label }} {{ isRequired ? '*' : '' }}</mat-checkbox
      >
      <div *ngIf="hintLabel" class="checkbox-hint">{{ hintLabel }}</div>
      <div>
        <mat-error
          class="checkbox-error"
          *ngIf="formControl.invalid && formControl.touched"
          >{{ errorMessage }}</mat-error
        >
      </div>
    </div>
  `,
  styles: [
    `
      .checkbox-hint {
        font-size: 12px;
        margin-left: 44px;
      }
      .checkbox-error {
        margin-left: 44px;
      }
    `,
  ],
})
export class CheckboxControlComponent
  extends BaseControlComponent
  implements ControlValueAccessor
{
  disabled = false;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {
    super();
  }
  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
