import { Injectable } from '@angular/core';
import { Http, blobToFile } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class Excel {
  constructor(private http: Http) {}

  async export(exportRequest: ExportRequest) {
    const content = await this.http.post<Blob>(
      '/api/excel/export',
      exportRequest,
      { responseType: 'blob' }
    );
    blobToFile(
      content as Blob,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      exportRequest.fileName
    );
  }
}

export class ExportRequest {
  fileName: string;
  sheets: ExportSheet[] = [];
  details: ExportHeader[] = [];
}

export class ExportSheet {
  name: string;
  rows: any[];
  headers: ExportHeader[];
}

export class ExportHeader {
  label: string;
  value?: string;

  // values: date,first,last,header
  format?: ExportHeaderFormat;
}

type ExportHeaderFormat = 'date' | 'first' | 'last' | 'header';
