import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (value?.length !== 10) {
      return value;
    }

    const areaCode = value.slice(0, 3);
    const exchange = value.slice(3, 6);
    const lineNumber = value.slice(6, 10);
    return `(${areaCode}) ${exchange}-${lineNumber}`;
  }
}
