import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastType } from 'src/app/shared';
import { AccountSettingsService } from '../../services/accountsettings.service';
import { emulator } from '../../services/emulator.service';
import { Session } from '../../services/session.service';
import { PasswordComponent } from './password.component';

@Component({
  selector: 'app-change-password',
  template: `
    <app-section title="Change Password"></app-section>

    <div class="flex-left tw-w-full">
      <div class="md:tw-w-[80%]  width-full toaster">
        <app-toast [type]="toastInfo">
          <div>
            <ul>
              <li class="tw-flex">
                Your new password must have the following characteristics:
              </li>
              <br />
              <li class="tw-flex">- Be 8-16 characters long.</li>
              <li class="tw-flex">
                - Contain at least one letter and one number.
              </li>
              <li class="tw-flex">
                - Contain at least one uppercase and one lowercase letter.
              </li>
              <li class="tw-flex">
                - Must contain only one of the following special characters
                &#64;#$%^&+=!
              </li>
              <li class="tw-flex">- Must not contain your username.</li>
              <li class="tw-flex">
                - Be different from your current password and previous 12
                passwords.
              </li>
              <li class="tw-flex">
                - Not be a password used within the last 90 days.
              </li>
            </ul>
          </div>
        </app-toast>
      </div>
      <div class="md:tw-w-[20%] width-full toaster-required">
        <div style="color: #171C20 Slate 600">
          <strong>*Required</strong>
        </div>
      </div>
    </div>
    <div>
      <form
        role="none"
        class="tw-w-full tw-flex tw-flex-wrap tw-flex-col"
        [formGroup]="changePasswordGroup"
        (ngSubmit)="submitPasswordChange()"
      >
        <br />
        <div>
          <app-password
            [oldPasswordText]="oldPasswordText"
            [newPasswordText]="passwordText"
            [retypePasswordText]="confirmPasswordText"
            [parentForm]="changePasswordGroup"
            [parentFormGroupName]="'passwordGroup'"
            formGroupName="passwordGroup"
            class="tw-w-full"
          >
          </app-password>
          <app-toast
            *ngIf="this.toastMessage !== ''"
            id="successMsg"
            [type]="toastType"
            #toastMsg
          >
            {{ this.toastMessage }}
          </app-toast>
        </div>

        <ul role="none" style="margin-top:0px">
          <div dir="ltr" class="row" style="float: right">
            <app-button
              [id]="'submitPwChange'"
              emphasis="High"
              mat-raised-button
              (click)="submitPasswordChange()"
              (keydown.enter)="submitPasswordChange()"
              [arialabel]="'Save password change'"
            >
              Save
            </app-button>
            <span class="app-button-margin"></span>
            <app-button
              [id]="'cancelPwChange'"
              mat-raised-button
              emphasis="Low"
              color="whiteButton"
              (click)="onCancel()"
              (keydown.enter)="onCancel()"
              [arialabel]="'Cancel password changes'"
            >
              Cancel
            </app-button>
          </div>
        </ul>
      </form>
    </div>
  `,
  styles: [
    `
      .toaster {
        float: left;
      }
      .toaster-required {
        text-align: end;
        float: left;
      }

      .span {
        display: block;
        width: 40px;
        /* background: rgb(0,75,113); */
        color: #fff;
        background-color: #035e81;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      .mat-card-instruction {
        display: block;
        width: fit-content;
        margin-right: 0;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 0;
      }

      .displayPnl {
        display: flex;
      }

      @media screen and (min-width: 250px) and (max-width: 560px) {
        .width-full {
          width: 100%;
        }
      }
      @media screen and (min-width: 561px) {
        .Width-70 {
          width: 70%;
        }
        .width-30 {
          width: 30%;
        }
      }
    `,
  ],
})
export class ChangePasswordPageComponent implements OnInit {
  passwordGroup: UntypedFormGroup;
  changePasswordGroup: UntypedFormGroup;
  username: any;
  submitPressed: boolean;
  oldPasswordText = 'Old Password';
  passwordText = 'New Password';
  confirmPasswordText = 'Retype Password';
  linkId: string;
  token: string;
  hide: boolean = true;
  toastMessage: string = '';
  toastType: ToastType;
  toastInfo: ToastType = 'Info';
  @ViewChild(PasswordComponent) passwordQuestions: PasswordComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public session: Session,
    private el: ElementRef,
    private accountService: AccountSettingsService,
    private enumalatorService: emulator
  ) {}

  ngOnInit(): void {
    this.changePasswordGroup = this.buildPasswordForm();
    this.username = this.session.user.userName;
  }
  submitPasswordChange(): void {
    this.submitPressed = true;
    const passwordControl = this.passwordQuestions;
    //let confirmPasswordControl = this.passwordGroup.get('confirmPassword');

    if (this.changePasswordGroup.invalid) {
      this.passwordQuestions.passwordGroup.markAllAsTouched();
      // Currently with how the password formGeroup component was designed showing error messages
      // Is not as easy as it should be. Not displaying any error message currently if they
      // click submit with out touching the passwords. Might need to be reexamined
      for (const key of Object.keys(
        this.passwordQuestions.passwordGroup.controls
      )) {
        if (this.passwordQuestions.passwordGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl.focus();
          break;
        }
      }
    } else {
      this.changePassword();
    }
  }
  buildPasswordForm(): UntypedFormGroup {
    return this.fb.group({
      passwordGroup: [],
    });
  }
  onCancel(): void {
    if (this.changePasswordGroup.value.passwordGroup != null) {
      this.changePasswordGroup.value.passwordGroup.reset();
      this.toastMessage = '';
    }
  }

  async changePassword() {
    if (this.changePasswordGroup.valid) {
      this.enumalatorService.showAuditorInfo();
      const request: any = {
        OldPassword: this.changePasswordGroup.value.passwordGroup.get(
          'oldPassword'
        ).value
          ? this.changePasswordGroup.value.passwordGroup.get('oldPassword')
              .value
          : '',
        NewPassword: this.changePasswordGroup.value.passwordGroup.get(
          'newPassword'
        ).value
          ? this.changePasswordGroup.value.passwordGroup.get('newPassword')
              .value
          : '',
        Salt: '',
        SecurityQuestion: '',
        SecurityAnswer: '',
        SecurityQuestion2: '',
        SecurityAnswer2: '',
        SecurityQuestion3: '',
        SecurityAnswer3: '',
        SecurityQuestion4: '',
        SecurityAnswer4: '',
        SecurityQuestion5: '',
        SecurityAnswer5: '',
      };

      const result = await this.accountService.setPassword(request);

      if (result != 0) {
        this.toastMessage = 'Your password has been successfully changed';
        this.toastType = 'Success';
      } else {
        this.toastMessage =
          'Your password could not be changed. Please confirm it meets the above requirements and try again.';
        this.toastType = 'Error';
      }
      /*          }
           ,
          (error) => {

          } */
      //);

      //this.router.navigate(['/']);
    }
  }
  buildDto(): any {
    return {
      //oldPassword: this.passwordGroup.get('newPassword').value,
      oldPassword: '',
      newPassword:
        this.changePasswordGroup.value.passwordGroup.get('newPassword').value, //this.changePasswordGroup.get('passwordGroup.confirmPassword').value,
      linkId: this.linkId ?? '',
      username: this.username,
      token: this.token,
    };
  }
}
