import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserActionService, deepCopy } from 'src/app/shared';
import {
  ConfigurationService,
  Excel,
  ExportRequest,
  UserRoleService,
} from '../..';
import { Router } from '@angular/router';
import {
  PendingAccommodation,
  PendingAccommodationPanels,
} from '../../models/accommodation';
import { EncryptionService } from '../../services/encryption.service';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-pending-accommodations',
  template: `
    <app-list
      [dataSource]="accomodations"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      loadingMessage=""
      noDataIcon="search"
      noDataMessage="No results"
      noDataThinMessage="There are no results that match the search"
      [pageSizeOptions]="[5, 10, 25]"
      [pageSize]="5"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these pending accomodations as a XLSX file"
      [tableAriaLabel]="'Pending omni Accommodations'"
      (export)="_export()"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimNumber"
            label="Claim"
            sortLabel="Claim"
            class="tw-w-full md:tw-w-[35%]"
            [names]="['claimNumber']"
          ></app-list-header>
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[20%]"
            [names]="['empId']"
          ></app-list-header>
          <app-list-header
            name="type"
            label="Type"
            class="tw-w-full md:tw-w-[15%]"
            [names]="['']"
          ></app-list-header>
          <app-list-header
            name="beginDate"
            label="Begin"
            filterType="Date"
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
          <app-list-header
            name="endDate"
            label="End"
            filterType="Date"
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
          <app-list-header
            label="Actions"
            readOnly
            class="tw-w-full md:tw-w-[5%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>
    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[35%]">
          <app-value label="Claim" layout="row-xs">
            <app-claim-label value [claim]="element" layout="row">
            </app-claim-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label value [claimant]="element"></app-claimant-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Type"
            [value]="element.type"
            [tabindex]="element.type ? '0' : '1'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%]">
          <app-value
            label="Begin"
            [tabindex]="element.beginDate ? '0' : '1'"
            [value]="element.beginDate | date : 'MM/dd/yyyy'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%]">
          <app-value
            label="End"
            [tabindex]="element.endDate ? '0' : '1'"
            [value]="element.endDate | date : 'MM/dd/yyyy'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-[10%]" label="Actions">
          <app-value class="tw-w-full" label="Actions" layout="row-xs">
            <app-icon-button
              value
              mat-icon-button
              icon="more_vert"
              label="Actions"
              [matMenuTriggerFor]="actionMenu"
              tabindex="0"
              role="button"
              (click)="toggleMenu()"
              (keydown.enter)="toggleMenu()"
              [attr.aria-label]="
                'Actions menu for claim - ' + element.claimNumber
              "
              [attr.aria-expanded]="isActionsMenuExpanded"
            >
              <mat-menu #actionMenu="matMenu">
                <h3 class="tw-w-full app-pl2 action-header">Actions</h3>
                <button mat-menu-item (click)="viewJobFunctions(element)">
                  <span class="action-links">View job functions</span>
                </button>
                <button
                  *ngIf="canApproveJobFunctions"
                  mat-menu-item
                  (click)="approveJobFunctions(element)"
                >
                  <span class="action-links">Approve job functions</span>
                </button>
                <button
                  *ngIf="canApproveAccommodationRequests"
                  mat-menu-item
                  (click)="approveAccommodations(element)"
                >
                  <span class="action-links">Approve accommodations</span>
                </button>
              </mat-menu>
            </app-icon-button>
          </app-value>
        </td>
      </tr>
    </ng-template>
  `,
  styles: `
  .action-links {
    color: #007dbc;
  text-decoration: none;
  font-weight: bold;
  }
  .action-header {
    font-weight:bold;
  }
 `,
})
export class PendingAccomodationsComponent {
  @Input()
  accomodations: PendingAccommodation[];

  @Input()
  excelName: string = 'Claim Search.xlsx';

  @Input()
  sheetName: string = 'Claim Search';

  @Output()
  export = new EventEmitter<ExportRequest>();

  canApproveJobFunctions: boolean = false;
  canApproveAccommodationRequests: boolean = false;
  isActionsMenuExpanded: boolean = false;

  constructor(
    private excel: Excel,
    public userRole: UserRoleService,
    public claimConfigService: ConfigurationService,
    private router: Router,
    private encrypt: EncryptionService,
    private userActions: UserActionService,
    private configurationService: ConfigurationService
  ) {
    this.canApproveJobFunctions = this.configurationService.hasFeature(
      'accommodationjobfunctionsrequests'
    );
    this.canApproveAccommodationRequests = this.configurationService.hasFeature(
      'accommodationsrequest'
    );
  }

  async viewJobFunctions(element: any) {
    await this.userActions.setClaimant(element);

    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.ApproveJobFunctions,
        userOwns: element.userOwns,
      },
    });
  }
  approveJobFunctions(element: any) {
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.UnapproveJobFunctions,
        userOwns: element.userOwns,
      },
    });
  }
  approveAccommodations(element: any) {
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.UnapprovedAccommodations,
        userOwns: element.userOwns,
      },
    });
  }

  _export() {
    const request = new ExportRequest();
    const claimRows = deepCopy(this.accomodations);
    request.fileName = this.excelName;

    request.sheets = [
      {
        name: this.sheetName,
        rows: claimRows,
        headers: [
          { label: 'Line of Business', value: 'lineOfBusiness' },
          { label: 'Claim Type', value: 'claimDescription' },
          { label: 'Claim Number', value: 'claimNumber' },
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'empId' },
          { label: 'Type', value: 'type' },
          { label: 'Begin Date', value: 'beginDate', format: 'date' },
          { label: 'End Date', value: 'endDate', format: 'date' },
        ],
      },
    ];

    this.export.emit(request);
    this.excel.export(request);
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
