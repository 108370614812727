import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseComponent, getUrlParams, SearchResult } from 'src/app/shared';
import { ClaimService } from '../../services/claim.service';
import { Session } from '../..';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-learning-center-header',
  template: `
    <div class="tw-flex tw-items-start tw-w-full tw-flex-col md:tw-flex-row">
      <div class="tw-hidden md:tw-block app-pr2 tw-flex-none">
        <img
          src="./assets/images/mySedgwick_Helpful_Link_Characters_2.png"
          width="186"
          height="193"
          alt=""
        />
      </div>

      <div class="app-pr2 tw-grow">
        <app-loading-panel
          [skeletonLines]="5"
          [state]="loaded ? 'Loaded' : 'Loading'"
        >
          <ng-template #content>
            <div class="tw-w-full">
              <span style="font-weight: bold;font-size: 18px" class="titlecase"
                >Hello {{ this.session.user.firstName | titlecase }}</span
              ><span
                class="Sentencecase"
                style="font-weight: normal;font-size: 18px"
                >, welcome to the learning center</span
              >
              <ul class="tw-list-disc app-pl3 app-pt1" style="font-size: 16px">
                <li class="list-item">
                  Here you can learn about all aspects of the claims process.
                </li>
                <li class="list-item" *ngIf="claim && !lobChanged">
                  {{ claim.claimDescription | titlecase }} has been selected
                  based on your last visited claim
                  <a
                    class="tw-whitespace-nowrap"
                    routerLink="/claims"
                    [queryParams]="{
                      claimId,
                      source
                    }"
                    >{{ claim.claimDescription | titlecase }} -
                    {{ claim.claimNumber }}</a
                  >.
                </li>
                <li
                  class="list-item"
                  *ngIf="loaded && showClaimTypes && !claimId"
                >
                  Learn more about other claim types by selecting them from the
                  right menu.
                </li>
              </ul>
            </div>
          </ng-template>
        </app-loading-panel>
      </div>

      <div
        class="tw-flex-none side-border app-pr3 tw-pt-6 md:tw-pt-0"
        *ngIf="loaded && showClaimTypes && !claimId"
      >
        <form [formGroup]="formGroup" class="tw-w-full tw-flex tw-flex-wrap">
          <app-radio-button-control
            formControlName="lob"
            [formGroup]="formGroup"
            label="I would like to learn more about..."
            ariaLabel="I would like to learn more about..."
            labelProperty="label"
            valueProperty="value"
            [items]="items"
            ngDefaultControl
            class="tw-w-full"
          ></app-radio-button-control>
        </form>
      </div>
    </div>
  `,
  styles: [
    `
      .side-border {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        height: 100%;
        padding-left: 16px;
      }

      .list-item {
        padding-bottom: 6px;
      }
    `,
  ],
})
export class LearningCenterHeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  claimId: string;

  source: string;

  claim: SearchResult;

  loaded = false;

  showClaimTypes = true;

  lobChanged: boolean = false;

  @Input()
  isMgr: boolean;

  @Input()
  lob: string;

  @Input()
  items: any[];

  @Output()
  lobChange = new EventEmitter<string>();

  formGroup = this.fb.group({
    lob: [' '],
  });

  constructor(
    private fb: FormBuilder,
    public session: Session,
    private claimService: ClaimService
  ) {
    super();
  }

  async ngOnInit() {
    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.formGroup.get('lob').setValue(this.lob);

    this.subs.sink = this.formGroup.controls['lob'].valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((value) => {
        if (value != 'LA') {
          this.lobChange.emit(value);
          this.lobChanged = value !== this.lob;
        }
      });

    this.showClaimTypes = this.items.length > 1;

    if (this.claimId) {
      this.claim = await this.claimService.getSummary(
        this.source,
        this.claimId
      );
      this.formGroup.controls['lob'].setValue(this.claim.lob);
      this.loaded = true;
    } else {
      this.loaded = true;
    }
  }
}
