import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChatService } from '../..';
import { Session } from '../../services/session.service';

declare function doLiveChat(): any;
declare function HandleChatPopup(): any;

@Component({
  selector: 'app-technical-difficulties',
  template: `
    <div class="page-container tw-w-full">
      <div class="alert alert-danger technical-diff">
        We are currently experiencing technical difficulties.
        <br /><br />
        We apologize for any inconvenience.
        <br /><br />
        You can go back to the
        <a tabindex="0" (click)="logOut()" class="spanLink">login page</a> and
        try again. <br /><br />
        Please feel free to call us at the following numbers and times:
        <br /><br />
        Live Chat help is available from 8:00 a.m. until 5:00 p.m. central time,
        Monday through Friday.
        <br /><br />
        Sedgwick Technical Support is available from 8:00 a.m. to 7:00 p.m.,
        central time, Monday through Friday.
        <br /><br />
        Toll-free Number: (866) 647-7610
        <br /><br />
        Toll Number: (901) 415-7800

        <div *ngIf="hasSessionId">
          <span>
            <br />
            Please have the following information available.
            <br /><br />
            <span>
              Session ID: <span>{{ sessionId }} </span>
              <br />
              <span>
                {{ currentDate }}
              </span>
            </span>
          </span>
        </div>
      </div>
      <span style="display:block;">
        <button
          mat-button
          aria-label="Live chat"
          tabindex="0"
          mat-raised-button
          color="primary"
          (click)="showLiveChat()"
          class="openLiveChatBtn"
          id="openLiveChatBtn"
        >
          <mat-icon
            aria-hidden="true"
            aria-label="chat bubble icon"
            class="chatBubble"
            >chat_bubble</mat-icon
          >
          LIVE CHAT
        </button>
        <span
          id="displayLocation"
          style="line-height:25px;display:block;width: inherit;"
        ></span>
      </span>
    </div>
  `,
  styles: [
    `
      .technical-diff {
        width: max-content;
        padding: 20px;
      }
      .page-container {
        margin: 20px;
      }
      @media (max-width: 480px) {
        .page-container {
          margin: 0px;
        }
      }
    `,
  ],
  providers: [DatePipe],
})
export class TechnicalDifficultiesComponent implements OnInit {
  hasSessionId: boolean;
  sessionId: string;
  currentDate: string = new Date().toString();
  window: any;
  lhnHocButton: any;
  displayLocation: any;
  passVar: any;

  constructor(
    private datePipe: DatePipe,
    public session: Session,
    private chat: ChatService
  ) {
    this.currentDate = this.datePipe.transform(
      this.currentDate,
      'M/d/yyyy, h:mm:ss a'
    );
  }

  ngOnInit(): void {
    this.sessionId = this.session.user.sessionId;
    if (this.sessionId != undefined) {
      this.hasSessionId = true;
    }

    this.passVar = {
      Flag: '',
      top: '',
      left: window.innerWidth,
    };

    doLiveChat(); //Initialize Live Chat from index.html
    this.chat.close();
  }

  logOut() {
    this.session.logoff();
  }

  showLiveChat() {
    HandleChatPopup();
  }
}
