import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogMessage } from '../models/log-message';
import { LogApi } from './api/log-api.service';
import { Session } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  static callCount = 0;

  constructor(
    private router: Router,
    private logApi: LogApi,
    private session: Session
  ) {}

  handleError(error: any): void {
    // eslint-disable-next-line no-console
    console.error(error);

    try {
      this.logError(error);
    } finally {
      if (!error?.stack?.includes('cdn.botframework.com')) {
        this.router.navigate(['technicaldifficulties']);
      }
    }
  }

  /**
   * Reports errors to api. Stops reporting after 10 errors.
   * Do not report http errors. Http errors are logged by api.
   */
  private logError(error: any) {
    if (
      error instanceof HttpErrorResponse ||
      ErrorHandlerService.callCount++ >= 10
    ) {
      return;
    }

    const logMessage = new LogMessage();
    let stack: string = error.stack;
    if (!stack) {
      const obj: any = {};
      Error.captureStackTrace(obj, this.handleError);
      stack = obj.stack;
    }

    logMessage.message = `${error.message} UserName: ${this.session?.user?.userName} ErrorCount: ${ErrorHandlerService.callCount} StackTrace: ${stack}`;
    logMessage.requestId = error.traceId || 'RequestId';
    logMessage.sessionId = this.session?.user?.sessionId;
    logMessage.identifier = window.location.pathname + window.location.search;
    this.logApi.log(logMessage, 'Error');
  }
}
