import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatService, ConfigurationService } from '../..';

declare function doLiveChat(): any;
declare function HandleChatPopup(): any;

@Component({
  selector: 'app-support-dialog',
  template: `
    <div id="contactSupportModal">
      <app-popup-header
        [headerTitle]="title"
        (CloseTabOut)="SprtPopupTabOut()"
      ></app-popup-header>
      <mat-dialog-content class="mySedwick-popupContent">
        @if(!hasCareyChat){
        <br aria-hidden="true" />
        <span class="right10">
          Live Chat help is available from 8:00 a.m. until 5:00 p.m. central
          time, Monday through Friday.
          <br aria-hidden="true" />
          <button
            mat-button
            aria-label="Live chat"
            tabindex="0"
            mat-raised-button
            color="primary"
            (click)="tryOpenLiveChat()"
            class="openLiveChatBtn"
            id="openLiveChatBtn"
          >
            <mat-icon
              aria-hidden="true"
              aria-label="chat bubble icon"
              class="chatBubble"
              >chat_bubble</mat-icon
            >
            LIVE CHAT
          </button>
          <span
            id="displayLocation"
            fxLayout=""
            fxFlexAlign="left"
            style="line-height:25px;display:block;width: inherit;"
          ></span>
        </span>
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        <br aria-hidden="true" />
        }
        <div
          fxFlexAlign="space-around"
          class="margin-5"
          class="tw-flex tw-flex-wrap tw-flex-col"
          id="moreoptionparent"
        >
          @if(!hasCareyChat){
          <div
            tabindex="0"
            fxLayout="row"
            role="button"
            class="expandCollapseHover"
            id="spnmoresupportoption"
            (keyup.enter)="collapseExpand(nextAction)"
            (click)="collapseExpand(nextAction)"
            [attr.aria-expanded]="!doCollapse"
          >
            <mat-icon
              aria-hidden="false"
              class="mat-primary"
              style="color:#01334c !important;"
            >
              {{ termsIcon }}
            </mat-icon>
            More support options
          </div>
          }
          <label
            id="expandSection"
            [ngClass]="{
              'expand-collapse': !doCollapse,
              'expand-collapse-hidden': doCollapse
            }"
          >
            <br aria-hidden="true" /><br aria-hidden="true" />
            <ng-container *ngTemplateOutlet="techSupport"></ng-container>
          </label>
        </div>
        <br aria-hidden="true" /><br aria-hidden="true" />
      </mat-dialog-content>

      <mat-dialog-actions align="end" class="mySedwick-popupFooter">
        <button
          mat-button
          [mat-dialog-close]="true"
          aria-label="OK"
          mat-raised-button
          color="primary"
          style="background-color: #01334c !important;"
          (click)="doClose()"
        >
          <mat-icon aria-hidden="true" aria-label="Check icon">check</mat-icon>
          OK
        </button>
      </mat-dialog-actions>
    </div>

    <ng-template #techSupport>
      <span class="right10">
        Sedgwick Technical Support is available from 8:00 a.m. to 7:00 p.m.,
        central time, Monday through Friday.
      </span>
      <br aria-hidden="true" /><br aria-hidden="true" />
      <span aria-label="Toll free Number 8666477610 .">
        Toll-free Number : (866) 647-7610
      </span>
      <br aria-hidden="true" />
      <span aria-label="Toll Number 9014157800">
        Toll Number : (901) 415-7800
      </span>
    </ng-template>

    <ng-template #chatWarning>
      <app-dialog-container
        [headerLevel]="2"
        label="Chat in progress"
        [headerId]="'chatInProgress'"
        aria-labelledby="'chatInProgress'"
      >
        <div class="tw-pt-2 tw-pb-4 tw-w-80">
          Press new chat to close the active chat session and start a new chat.
        </div>
        <app-action-row class="tw-pt-4">
          <app-button
            right2
            emphasis="High"
            matDialogClose
            (click)="openLiveChat()"
            >New chat</app-button
          >
          <app-button right1 emphasis="Low" matDialogClose>Cancel</app-button>
        </app-action-row>
      </app-dialog-container>
    </ng-template>
  `,
  styles: [
    `
      .support-dialog {
        max-width: 500px;
      }

      .expand-collapse {
        //height: auto;
        width: inherit;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 20px;
        padding-bottom: 10px;
      }
      .expand-collapse-hidden {
        display: none;
        height: 0px;
        max-height: 0px;
        width: inherit;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 20px;
        padding-bottom: 40px;
      }

      .login-icon {
        margin-right: 5px;
      }

      .expandCollapseHover {
        cursor: pointer;
      }
      .mat-mdc-button:focus {
        border: 2px solid white !important;
        outline: 3px solid black !important;
      }
      #spnmoresupportoption:focus {
        border: 2px solid white !important;
        outline: 3px solid black !important;
      }
    `,
  ],
})
export class SupportDialogComponent implements OnInit, OnDestroy {
  @ViewChild('displayLocation') myDiv: ElementRef;

  @ViewChild('chatWarning')
  chatWarning: TemplateRef<any>;

  doCollapse: boolean;
  termsIcon: string;
  nextAction: string;
  window: any;
  lhnHocButton: any;
  displayLocation: any;
  elementByClassForLiveChat: any;
  passVar: any;
  isVisible: boolean;
  title: string = 'Technical Support';
  hasCareyChat: boolean = false;

  constructor(
    private dialogRef: MatDialog,
    private careyChat: ChatService,
    public dialog: MatDialog,
    public configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.isVisible = true;
    this.termsIcon = 'arrow_right';
    this.hasCareyChat = this.configurationService.getFeatureAccess('careychat');
    if (!this.hasCareyChat) {
      doLiveChat();
    }
    this.doCollapse = !this.hasCareyChat;
  }

  tryOpenLiveChat() {
    if (this.careyChat.isActive()) {
      setTimeout(() => {
        this.dialog.open(this.chatWarning);
      }, 200);
    } else {
      HandleChatPopup();
    }
    this.doClose();
  }

  openLiveChat() {
    if (this.careyChat.isActive()) {
      this.careyChat.close();
    }
    HandleChatPopup();
  }

  ngOnDestroy(): void {
    this.doClose();
  }

  doClose(): void {
    this.isVisible = false;
    this.dialogRef.closeAll();
  }

  collapseExpand(action: string): void {
    if (action == 'collapse') {
      this.termsIcon = 'arrow_right';
      this.doCollapse = true;
      this.nextAction = 'expand';
    } else {
      this.termsIcon = 'arrow_drop_down';
      this.doCollapse = false;
      this.nextAction = 'collapse';
    }
  }

  SprtPopupTabOut() {
    const data = document.getElementById('openLiveChatBtn');
    const getmore = document.getElementById('spnmoresupportoption');
    if (data != undefined && data != null) {
      if (data.style.display != 'none;') {
        data.focus();
      } else {
        if (getmore != undefined && getmore != null) {
          getmore.focus();
        }
      }
    } else if (getmore != undefined && getmore != null) {
      getmore.focus();
    }
  }
}
