import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeToBulletPoints',
})

export class PipeToBulletPoints implements PipeTransform {
  
  transform(value: string, separator: string = "|"): string[] {
    if (value === null || value === undefined)
      return [];
    else
      return value.split(separator);
  }
}
