import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-select-control',
  template: `
    <div class="app-pr1 app-pb1">
      <mat-form-field [formGroup]="formGroup">
        <mat-label>{{ label }}</mat-label>
        <mat-select
          #select
          [formControlName]="formControlName"
          autocomplete="off"
          [placeholder]="placeHolder"
          [multiple]="multiple"
          (selectionChange)="selectionChange($event)"
          (openedChange)="openedChange($event)"
          aria-haspopup="listbox"
          aria-controls="optionsList"
          aria-autocomplete="none"
        >
          <div id="optionsList" role="listbox">
            <mat-option
              *ngIf="multiple"
              (click)="toggleAllSelection()"
              (keydown.enter)="toggleAllSelection()"
              [value]="selectAllValue"
              [class.select-all]="false"
            >
              <span>Select All</span>
            </mat-option>

            <mat-option
              color="accent"
              *ngFor="let option of options"
              [value]="option.value"
              >{{ option.label }}</mat-option
            >
          </div>
        </mat-select>
        <mat-error *ngIf="formControl.invalid">{{ errorMessage }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      ::ng-deep .mat-mdc-option:focus.mdc-list-item,
      .mat-mdc-option.mat-mdc-option-active {
        border: 2px solid #67707a !important;

        /* border-radius: 50px 20px; */
      }
    `,
  ],
})
export class SelectControlComponent
  extends BaseControlComponent
  implements OnInit
{
  @ViewChild('select')
  select: MatSelect;

  allChecked: boolean = false;

  allIndeterminate: boolean = false;

  @Input()
  options: SelectOption[] = [];

  @Input()
  multiple: boolean = false;

  selectAllValue = 'selectAll';

  constructor(private renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {}

  toggleAllSelection() {
    this.allChecked = !this.allChecked;
    this.allIndeterminate = false;
    if (this.allChecked) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    this.updatePseudoCheckboxState();
  }

  selectionChange(selectChange: MatSelectChange) {
    const selectedValues = selectChange.value;
    this.allChecked = selectedValues.length === this.options.length;
    this.allIndeterminate = selectedValues.length > 0 && !this.allChecked;

    if (!this.allChecked) {
      this.select.options.forEach((item: MatOption) => {
        if (item.value === 'selectAll') {
          item.deselect();
        }
      });
    }
    this.updatePseudoCheckboxState();
  }

  openedChange(opened: boolean) {
    if (opened) {
      this.updateSelectAllState();
      // this.attachSpaceKeyListener();
      this.updatePseudoCheckboxState();
      this.select.panel.nativeElement.addEventListener('mouseleave', () => {
        this.select.close();
      });
    }
  }

  updateSelectAllState() {
    const selectedValues = this.select.value || [];
    this.allChecked = selectedValues.length === this.options.length;
    this.allIndeterminate = selectedValues.length > 0 && !this.allChecked;
  }

  updatePseudoCheckboxState() {
    const selectAllOption = this.select.options.find(
      (option) => option.value === 'selectAll'
    );
    if (selectAllOption) {
      const pseudoCheckbox = selectAllOption
        ._getHostElement()
        .querySelector('.mat-pseudo-checkbox');
      pseudoCheckbox?.setAttribute('aria-hidden', 'false');
      if (pseudoCheckbox) {
        pseudoCheckbox.classList.toggle(
          'mat-pseudo-checkbox-checked',
          this.allChecked
        );
        pseudoCheckbox.classList.toggle(
          'mat-pseudo-checkbox-indeterminate',
          this.allIndeterminate
        );
        pseudoCheckbox.classList.toggle(
          'mat-pseudo-checkbox-unchecked',
          !this.allChecked && !this.allIndeterminate
        );
      }
    }
  }
}

export class SelectOption {
  label: string;
  value: any;
}
