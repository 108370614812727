export class Claim {
  name: string;
  dateOpened: string;
  claimId: string;
  source: string;
  dataSet: string;
  coverageDescription: string;
  claimNumber: string;
  claimDescription: string;
  claimLevelFeatures: [];
  status: string;
  userOwns: boolean;
  isIntermittent: boolean;
  lineOfBusiness: string;
  empId: string;
  empUnum: string;
}
