import { Component, OnInit } from '@angular/core';
import {
  DetailSearchConfiguration,
  OmniApi,
  SearchRequest,
  SearchService,
} from 'src/app/my-sedgwick';
import { PendingAccommodation } from 'src/app/my-sedgwick/models/accommodation';
import { LoadingState } from 'src/app/shared';

@Component({
  selector: 'app-pending-accomodations-page',
  template: `
    <app-section>
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        title="Pending Accommodations"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
      >
        <div class="tw-min-h-[510px] tw-w-full">
          <app-detail-search-form
            #searchForm
            [config]="config"
            (search)="search($event)"
            [loading]="loading"
          >
            <ng-template #results>
              <app-search-count-row
                resultPlural="results"
                [count]="pendingAccommodations.length"
              ></app-search-count-row>
              <app-pending-accommodations
                [accomodations]="pendingAccommodations"
                class="tw-w-full"
                excelName="Pending Accommodations.xlsx"
                sheetName="Pending Accommodations"
              >
              </app-pending-accommodations>
            </ng-template>
          </app-detail-search-form>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: [``],
})
export class PendingAccomodationsPageComponent implements OnInit {
  config = new DetailSearchConfiguration();
  loading: LoadingState;
  pendingAccommodations: PendingAccommodation[];

  constructor(private OmniApi: OmniApi, private searchService: SearchService) {
    this.config.key = 'omni-dashboard:pending-accommodations';
    this.config.ssn.visible = false;
    this.config.status.visible = false;
    this.config.lob.visible = false;
    this.config.supervisor.visible = false;
    this.config.includeIndirect.visible = false;
    this.config.startDate.visible = false;
    this.config.endDate.visible = false;
    this.config.employeeId.widthPercent = 25;
    this.config.initialSearch = true;
    this.config.initialSearchResultsOnClear = true;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = true;
    this.config.expanded = false;
    this.config.payType.visible = false;
    this.config.maxBackDays = 30;
  }

  ngOnInit(): void {
    this.config.startDate.widthPercent = 25;
    this.config.endDate.widthPercent = 25;
  }

  async search(request: SearchRequest) {
    this.loading = 'Loading';

    const params = request.getParameters();
    params.status = ['O'];
    params.lob = ['AD'];
    params.excludeSupervisor = true;
    const searchInformation =
      await this.OmniApi.getPendingAccomodationsJobFunctions(params);
    this.pendingAccommodations = searchInformation;
    this.loading = this.pendingAccommodations.length > 0 ? 'Loaded' : 'Empty';
  }
}
