import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { reportNewClaim } from 'src/app/my-sedgwick/functions/report-newclaim';
import { scrollToElement } from 'src/app/shared';
import { SubSink } from 'subsink';
import { DynamicEventBindService } from '../..';
import { ExternalLinkDialogComponent } from '../../../shared/components/controls/dialog/external-link-dialog.component';
import { ConfigurationService } from '../../services/configuration.service';
import { LearningCenterService } from '../../services/learning-center.service';
import { OpenFileService } from '../../services/openfile.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-learning-center-ada',
  template: `
    <div #dynamicContentContainer class="tw-w-full tw-flex">
      <!-- CONTENT -->
      <div class="tw-w-full">
        <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'">
          <ng-template #content>
            <!-- INTRODUCTION -->
            <h2
              class="app-lc-header-2"
              id="lc-intro"
              *ngIf="isIntroduction"
              role="heading"
            >
              Introduction
            </h2>
            <div *ngIf="isMgr; then mgrIntro; else nonMgrIntro"></div>
            <ng-template #mgrIntro>
              <div class="app-lc-text">
                <p
                  [innerHTML]="learningCenter.introductionResults[0]?.value"
                ></p>
              </div>
              <h2
                *ngIf="isWhatisADA"
                [innerHTML]="learningCenter.introductionResults[1]?.value"
                class="app-lc-header-2"
                role="heading"
              ></h2>

              <div class="app-lc-text tw-flex">
                <div class="tw-grow">
                  <p
                    [innerHTML]="learningCenter.introductionResults[2]?.value"
                  ></p>
                  <p
                    [innerHTML]="learningCenter.introductionResults[3]?.value"
                  ></p>
                </div>

                <div class="tw-flex-none app-pl2 tw-hidden md:tw-block">
                  <img
                    src="./assets/images/img_LC_introphone_desktop.webp"
                    width="69"
                    height="100"
                    alt=""
                    aria-hidden="true"
                    role="img"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template #nonMgrIntro>
              <div class="app-lc-text">
                <p
                  [innerHTML]="learningCenter.introductionResults[0]?.value"
                ></p>
              </div>
              <div class="app-lc-header" *ngIf="isWhatisADA">
                {{ learningCenter.introductionResults[1].value }}
              </div>
              <div class="app-lc-text tw-flex">
                <div class="tw-grow">
                  <p
                    [innerHTML]="learningCenter.introductionResults[2]?.value"
                  ></p>
                  <p
                    [innerHTML]="learningCenter.introductionResults[3]?.value"
                  ></p>
                </div>

                <div class="tw-flex-none app-pl2 tw-hidden md:tw-block">
                  <img
                    src="./assets/images/img_LC_introphone_desktop.webp"
                    width="69"
                    height="100"
                    alt=""
                    aria-hidden="true"
                    role="img"
                  />
                </div>
              </div>
            </ng-template>

            <!-- INTRODUCTION END-->
            <!-- ADA Details-->
            <h2 class="app-lc-header-2" id="lc-details" role="heading">
              ADA Details
            </h2>
            <div *ngIf="isMgr; then mgrDetail; else nonMgrDetail"></div>
            <ng-template #mgrDetail>
              <div>
                <p
                  class="app-lc-details-header"
                  *ngIf="learningCenter.adaDetails[1]?.value"
                  [innerHTML]="learningCenter.adaDetails[0]?.value"
                ></p>
                <span [innerHTML]="learningCenter.adaDetails[1].value"></span>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[3]?.value"
                    [innerHTML]="learningCenter?.adaDetails[2].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[3].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[5]?.value"
                    [innerHTML]="learningCenter?.adaDetails[4].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[5].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[7]?.value"
                    [innerHTML]="learningCenter?.adaDetails[6].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[7].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[9]?.value"
                    [innerHTML]="learningCenter?.adaDetails[8].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[9].value"
                  ></span>
                </div>
              </div>
            </ng-template>
            <ng-template #nonMgrDetail>
              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[1]?.value"
                    [innerHTML]="learningCenter?.adaDetails[0].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[1].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[3]?.value"
                    [innerHTML]="learningCenter?.adaDetails[2].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[3].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[5]?.value"
                    [innerHTML]="learningCenter?.adaDetails[4].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[5].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[7]?.value"
                    [innerHTML]="learningCenter?.adaDetails[6].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[7].value"
                  ></span>
                </div>
              </div>

              <div class="tw-w-full tw-flex app-pb1 app-pt1 ng-star-inserted">
                <div>
                  <p
                    class="app-lc-details-header"
                    *ngIf="learningCenter.adaDetails[9]?.value"
                    [innerHTML]="learningCenter?.adaDetails[8].value"
                  ></p>
                  <span
                    [innerHTML]="learningCenter?.adaDetails[9].value"
                  ></span>
                </div>
              </div>
            </ng-template>
            <!-- SUBMITTING CLAIM INFO END-->
            <!-- SUBMITTING CLAIM INFO-->

            <h2 class="app-lc-header-2" id="lc-claim" role="heading">
              ADA Submitting Claim Informations
            </h2>
            <div *ngIf="isMgr; then mgrSubmitInfo; else nonMgrSubmitInfo"></div>
            <ng-template #mgrSubmitInfo>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[0]?.value
                }}"
                icon="1"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[1].value
                  "
                ></p>
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[2]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[3]?.value
                }}"
                icon="2"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[4].value
                  "
                ></p>
                <ng-container *ngIf="isShown">
                  <p
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[5].value
                    "
                  ></p>
                </ng-container>
              </app-learning-center-section>

              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[6]?.value
                }}"
                icon="3"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[7].value
                  "
                ></p>
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[8]?.value
                  "
                ></p>
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[9].value
                  "
                ></p>

                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[10].value
                  "
                ></p>

                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[11].value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                *ngIf="
                  this.learningCenter.submittingclaiminformations[12].value
                "
                label="{{
                  learningCenter.submittingclaiminformations[12]?.value
                }}"
                icon="4"
                layout="bullet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
                >
                  <app-learning-center-section
                    *ngIf="
                      this.learningCenter.submittingclaiminformations[13]
                        .value &&
                      this.claimConfigService.getFeatureAccess(
                        'UploadDocuments'
                      ) &&
                      isMgr &&
                      isShown
                    "
                    class="tw-basis-1/4 app-color-teal"
                    layout="icon-small"
                    icon="upload_file"
                  >
                    <a
                      tabindex="0"
                      label="Documents"
                      class="go-to-documents-upload"
                      >Upload Documents</a
                    >
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[14]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="mail"
                  >
                    Mail:<br />
                    <span
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[14]?.value
                      "
                    ></span>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[15]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="fax"
                  >
                    Fax:<br />
                    <span
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[15]?.value
                      "
                    ></span>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[16]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="laptop_mac"
                  >
                    Email:<br />
                    <span
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[16]?.value
                      "
                    ></span>
                  </app-learning-center-section>
                </div>
              </app-learning-center-section>
            </ng-template>
            <ng-template #nonMgrSubmitInfo>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[0]?.value
                }}"
                icon="1"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[1].value
                  "
                ></p>
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[2]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[3]?.value
                }}"
                icon="2"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[4].value
                  "
                ></p>
                <ng-container *ngIf="isShown">
                  <p
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[5].value
                    "
                  ></p>
                </ng-container>
              </app-learning-center-section>

              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[6]?.value
                }}"
                icon="3"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[7]?.value
                  "
                ></p>
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[8]?.value
                  "
                ></p>
                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[9]?.value
                  "
                ></p>

                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[10]?.value
                  "
                ></p>

                <p
                  [innerHTML]="
                    learningCenter?.submittingclaiminformations[11]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                *ngIf="
                  this.learningCenter.submittingclaiminformations[12].value
                "
                label="{{
                  learningCenter.submittingclaiminformations[12]?.value
                }}"
                icon="4"
                layout="bullet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
                >
                  <app-learning-center-section
                    *ngIf="
                      this.learningCenter.submittingclaiminformations[13]
                        .value &&
                      this.claimConfigService.getFeatureAccess(
                        'UploadDocuments'
                      ) &&
                      !isMgr &&
                      isShown
                    "
                    class="tw-basis-1/4 app-color-teal"
                    layout="icon-small"
                    icon="upload_file"
                  >
                    <a
                      tabindex="0"
                      label="Documents"
                      class="go-to-documents-upload"
                      >Upload Documents</a
                    >
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[14]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="mail"
                  >
                    Mail:<br />
                    <span
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[14]?.value
                      "
                    ></span>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[15]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="fax"
                  >
                    Fax:<br />
                    <span
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[15]?.value
                      "
                    ></span>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[16]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="laptop_mac"
                  >
                    Email:<br />
                    <span
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[16]?.value
                      "
                    ></span>
                  </app-learning-center-section>
                </div>
              </app-learning-center-section>
            </ng-template>
            <!-- SUBMITTING CLAIM INFO END-->
            <!-- WHATS NEXT-->
            <h2
              class="app-lc-header-2"
              id="lc-what"
              *ngIf="isWhatsNext"
              role="heading"
            >
              ADA What's Next
            </h2>
            <div *ngIf="isMgr; then mgrWhat; else nonMgrWhat"></div>
            <ng-template #mgrWhat>
              <div class="right-wrap app-pl2 tw-hidden md:tw-block">
                <img
                  src="./assets/images/img_LC_WhatsNext_computer.webp"
                  width="141"
                  height="100"
                  alt=""
                  role="img"
                />
              </div>
              <p [innerHTML]="learningCenter.whatsnexts[0]?.value"></p>
              <p
                *ngIf="
                  (isShown && learningCenter.whatsnexts[3]?.value) ||
                  (learningCenter.whatsnexts[4]?.value &&
                    learningCenter.whatsnexts[5]?.value) ||
                  (learningCenter.whatsnexts[7]?.value &&
                    learningCenter.whatsnexts[8]?.value &&
                    learningCenter.whatsnexts[9]?.value &&
                    learningCenter.whatsnexts[9]?.value) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[5]?.value &&
                    learningCenter.whatsnexts[6]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[1]?.value"
              ></p>
              <div>
                <app-learning-center-section
                  *ngIf="
                    isShown &&
                    (learningCenter.whatsnexts[3]?.value ||
                      learningCenter.whatsnexts[4]?.value ||
                      learningCenter.whatsnexts[5]?.value)
                  "
                  label="{{ learningCenter.whatsnexts[2]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <span
                    [innerHTML]="learningCenter.whatsnexts[3]?.value"
                  ></span>
                  <span
                    [innerHTML]="learningCenter.whatsnexts[4]?.value"
                  ></span>
                  <span
                    [innerHTML]="learningCenter.whatsnexts[5]?.value"
                  ></span>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.whatsnexts[8]?.value"
                  label="{{ learningCenter.whatsnexts[6]?.value }}"
                  icon="contact_phone"
                  layout="icon"
                >
                  <p>
                    {{
                      learningCenter.whatsnexts[7]?.value +
                        ' ' +
                        learningCenter.whatsnexts[8]?.value +
                        ' ' +
                        learningCenter.whatsnexts[9]?.value
                    }}
                  </p>
                </app-learning-center-section>
              </div>
            </ng-template>
            <ng-template #nonMgrWhat>
              <div class="right-wrap app-pl2 tw-hidden md:tw-block">
                <img
                  src="./assets/images/img_LC_WhatsNext_computer.webp"
                  width="141"
                  height="100"
                  alt=""
                  role="img"
                />
              </div>
              <p [innerHTML]="learningCenter.whatsnexts[0]?.value"></p>
              <p
                *ngIf="
                  (isShown && learningCenter.whatsnexts[3]?.value) ||
                  (learningCenter.whatsnexts[4]?.value &&
                    learningCenter.whatsnexts[5]?.value) ||
                  (learningCenter.whatsnexts[7]?.value &&
                    learningCenter.whatsnexts[8]?.value &&
                    learningCenter.whatsnexts[9]?.value &&
                    learningCenter.whatsnexts[9]?.value) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[5]?.value &&
                    learningCenter.whatsnexts[6]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[1]?.value"
              ></p>

              <div>
                <app-learning-center-section
                  *ngIf="
                    isShown &&
                    (learningCenter.whatsnexts[3]?.value ||
                      learningCenter.whatsnexts[4]?.value ||
                      learningCenter.whatsnexts[5]?.value)
                  "
                  label="{{ learningCenter.whatsnexts[2]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <span
                    [innerHTML]="learningCenter.whatsnexts[3]?.value"
                  ></span>
                  <span
                    [innerHTML]="learningCenter.whatsnexts[4]?.value"
                  ></span>
                  <span
                    [innerHTML]="learningCenter.whatsnexts[5]?.value"
                  ></span>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.whatsnexts[8]?.value"
                  label="{{ learningCenter.whatsnexts[6]?.value }}"
                  icon="contact_phone"
                  layout="icon"
                >
                  <p>
                    {{
                      learningCenter.whatsnexts[7]?.value +
                        ' ' +
                        learningCenter.whatsnexts[8]?.value +
                        ' ' +
                        learningCenter.whatsnexts[9]?.value
                    }}
                  </p>
                </app-learning-center-section>
              </div>
            </ng-template>
            <h2 class="app-lc-header-2" id="lc-after" role="heading">
              ADA After The Decision is Made
            </h2>

            <div class="app-lc-text tw-flex">
              <div class="tw-grow">
                <div *ngIf="isMgr; then mgrNext; else nonMgrNext"></div>
                <ng-template #mgrNext>
                  <b [innerHTML]="learningCenter.afterDecide[0]?.value"></b
                  ><br />
                  <p [innerHTML]="learningCenter.afterDecide[1]?.value"></p>
                  <p [innerHTML]="learningCenter.afterDecide[2]?.value"></p>
                </ng-template>
                <ng-template #nonMgrNext>
                  <b [innerHTML]="learningCenter.afterDecide[0]?.value"></b
                  ><br />
                  <p [innerHTML]="learningCenter.afterDecide[1]?.value"></p>
                  <p [innerHTML]="learningCenter.afterDecide[2]?.value"></p>
                </ng-template>
              </div>
            </div>
            <h2
              class="app-lc-header-2"
              id="lc-how"
              *ngIf="!isShown"
              role="heading"
            >
              How Do All of These Claims Work?
            </h2>
            <div *ngIf="!isShown" class="text">
              Wondering what types of claims apply to what situations? Take a
              look at the table below for a quick overview.
            </div>

            <ng-container *ngIf="!isShown">
              <table class="tw-w-full" aria-label="Claims Information">
                <thead>
                  <tr>
                    <th style="width:20%" colspan="1" scope="col"></th>
                    <th style="width:20%" class="table-header" scope="col">
                      Short Term Disability
                    </th>
                    <th style="width:20%" class="table-header" scope="col">
                      FLMA/State Leave
                    </th>
                    <th style="width:20%" class="table-header" scope="col">
                      Workers' Compensation
                    </th>
                    <th style="width:20%" class="table-header" scope="col">
                      ADA
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="table-header-left" scope="row">
                      Unable to work due to an injury that occured outside of
                      work
                    </th>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to work due to an injury that occured outside of work checked"
                        tabindex="0"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to work due to an injury that occured outside of work checked"
                        tabindex="0"
                        scope="row"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <th class="table-header-left" scope="row">
                      Unable to work due to an injury that occured at work
                    </th>
                    <td></td>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to work due to an injury that occured at work checked"
                        tabindex="0"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to work due to an injury that occured at work checked"
                        tabindex="0"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <th class="table-header-left" scope="row">
                      Unable to work 2 days every month due to my own chronic
                      health condition
                    </th>
                    <td></td>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to work 2 days every month due to my own chronic health
            condition checked"
                        tabindex="0"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <th scope="row" class="table-header-left">
                      Unable to work for 3 weeks to care for an ill family
                      member
                    </th>
                    <td></td>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to work for 3 weeks to care for an ill family member checked"
                        tabindex="0"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row" class="table-header-left">
                      Unable to be totally successful at work due to limited
                      functionality
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="check-container">
                      <mat-icon
                        class="check"
                        aria-label="Unable to be totally successful at work due to limited functionality checked"
                        tabindex="0"
                        aria-hidden="false"
                        >check</mat-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </ng-template>
        </app-loading-panel>
      </div>

      <!-- RHS MENU -->
      <div
        class="tw-hidden lg:tw-block tw-flex-none tw-max-w-[220px] app-lc-menu"
      >
        <app-sticky-menu>
          <div class="app-pt2">
            <div class="app-lc-sticky-link"><b>ADA</b></div>
            <div class="app-lc-sticky-link">
              <a
                (click)="scrollToElement('lc-intro', false, 64)"
                *ngIf="isIntroduction"
                >Introduction</a
              >
            </div>
            <div class="app-lc-sticky-link">
              <a
                (click)="scrollToElement('lc-details', false, 64)"
                *ngIf="isADADetails"
                >ADA Details</a
              >
            </div>
            <div class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-claim', false, 64)"
                >ADA Submitting Claim Information</a
              >
            </div>
            <div class="app-lc-sticky-link">
              <a
                (click)="scrollToElement('lc-what', false, 64)"
                *ngIf="isWhatsNext"
                >ADA What's Next</a
              >
            </div>

            <div class="app-lc-sticky-link">
              <a
                (click)="scrollToElement('lc-after', false, 64)"
                *ngIf="isAfterDecision"
                >ADA After The Decision is Made</a
              >
            </div>
            <div *ngIf="!isShown" class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-how', false, 64)"
                >How Do All of These Claims Work</a
              >
            </div>
          </div>
        </app-sticky-menu>
      </div>
    </div>
  `,
  styles: [
    `
      table td {
        border-color: silver;
        border-style: solid;
        border-width: 0 1px 1px 0;
      }

      .tblcolumn {
        width: 20%;
      }
      .app-lc-header-2 {
        color: var(--color-teal);
        font-size: 28px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-details-header {
        //color: var(--color-teal);
        color: #171c20;
        font-size: 20px;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .left-wrap {
        float: left;
      }
      .right-wrap {
        float: right;
      }
      .img-pd-btm {
        padding-bottom: 75px;
      }
      .center {
        text-align: center;
      }
      .mat-icon {
        margin: auto;
      }
      p + p {
        margin-top: 10px;
      }
      .app-p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .app-lc-header-details {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        text-align: center;
        padding: 20px 5px 10px 5px;
      }
      .app-lc-details {
        color: #fff;
        background-color: #026595;
        border-radius: 10px;
        margin: 15px auto;
        margin-top: 25px;
        display: table;
        width: 95%;
        padding-top: 0px;
        overflow: hidden;
        line-height: 200px;
      }
      .img_div {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        text-align: center;
        padding: 20px 5px 10px 5px;
      }
      .table-container {
      }
      .table-container > div {
        border-color: silver;
        border-style: solid;
        border-width: 0 1px 1px 0;
      }
      .check-container {
        text-align: center;
        vertical-align: middle;
        line-height: 48px;
      }
      .check {
        font-size: 48px;
        width: 48px;
        height: 48px;
        color: var(--color-teal);
      }
    `,
  ],
})
export class LearningCenterAdaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  isMgr: boolean;

  @Input()
  learningCenter: any;

  @Input()
  loading: boolean;
  isShown: boolean = false;
  scrollToElement = scrollToElement;
  @Input()
  lcType: string;
  isReportClaimLinkBound: boolean = false;
  isOpenfile: boolean = false;
  documentUrl: string;
  isLearnMoreLinkBound: boolean = false;
  isLearnMore: boolean = false;
  mappings: any;
  isIntroduction: boolean = false;
  isWhatisADA: boolean = false;
  isADADetails: boolean = false;
  isSubmittingClaim: boolean = false;
  isWhatsNext: boolean = false;
  isAfterDecision: boolean = false;

  @ViewChild('dynamicContentContainer') container: ElementRef;

  private unlistens: (() => void)[] = [];
  constructor(
    private session: Session,
    public learningCenterService: LearningCenterService,
    public dialog: MatDialog,
    public openFileService: OpenFileService,
    public router: Router,
    private bindEvents: DynamicEventBindService,
    public claimConfigService: ConfigurationService
  ) {}
  subs = new SubSink();
  ngAfterViewInit() {
    //angular's innerHTML attribute doesnt bind click events so have to do this
    this.unlistens = this.bindEvents.bindDynamicEvents(
      this.container,
      this.mappings
    );
  }
  async ngOnInit() {
    this.buildDocumentLink();
    this.checkHeaderValues();
    this.mappings = [
      {
        selector: '.report-new-claim',
        action: this.reportClaim.bind(this),
      },
      {
        selector: '.medical-provider',
        action: this.openFileLink.bind(this),
      },
      {
        selector: '.learn-more',
        action: this.openLearnMore.bind(this),
      },
      {
        selector: '.go-to-personal-notif',
        route: '/PreferencesPage',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-comm-center',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'comm-center',
        },
      },
      {
        selector: '.go-to-pharmacy-card',
        route: '/pharmacy-card',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-documents',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'DOCUMENTS',
        },
      },
      {
        selector: '.go-to-documents-upload',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: true,
        },
      },
      {
        selector: '.go-to-documents-show',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: false,
        },
      },
    ];
    this.checkViaClaim();
  }
  buildDocumentLink() {
    if (!this.isMgr)
      this.documentUrl = `/claims?source=${this.learningCenter.claimInfo.source}&claimId=${this.learningCenter.claimInfo.claimId}&focus=DOCUMENTS`;
  }

  openFileLink(event: MouseEvent) {
    event.stopPropagation();
    this.openFileService.openfile();
  }

  reportClaim(event: MouseEvent) {
    event.preventDefault();
    reportNewClaim(this.dialog);
  }

  openLearnMore(event: MouseEvent) {
    event.preventDefault();
    const element = event.target as HTMLElement;
    let url = element.getAttribute('href');
    const dialogRef = this.dialog.open(ExternalLinkDialogComponent);
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(url, '_blank');
      }
    });
  }
  checkViaClaim() {
    if (this.learningCenter.claimInfo.claimId != null) {
      this.isShown = !this.isShown;
    }
  }
  checkHeaderValues() {
    if (this.checkArrayValue(this.learningCenter.introductionResults, 0)) {
      this.isIntroduction = true;
    }
    if (this.checkArrayValue(this.learningCenter.introductionResults, 2)) {
      this.isWhatisADA = true;
    }
    if (this.checkArrayValue(this.learningCenter.adaDetails, 0)) {
      this.isADADetails = true;
    }
    if (this.checkArrayValue(this.learningCenter.whatsnexts, 0)) {
      this.isWhatsNext = true;
    }
    if (this.checkArrayValue(this.learningCenter.afterDecide, 0)) {
      this.isAfterDecision = true;
    }
  }
  checkArrayValue(arr: any[], index: number) {
    const arrVal = arr.slice();
    if (index > 0) {
      arrVal.splice(index - 1, 1);
      return arrVal.some((obj) => obj.value !== '');
    } else {
      return arrVal.some((obj) => obj.value !== '');
    }
  }
  ngOnDestroy(): void {
    this.unlistens.forEach((unlisten) => unlisten());
    this.subs.unsubscribe();
  }
}
