import { Http } from 'src/app/shared/services/http.service';
import { Injectable } from '@angular/core';
import {
  Accommodation,
  AccommodationJobFunction,
  AccommodationListing,
} from '../models/accommodation';
import { AccommodationsInteractions } from '../models/accommodations-interactions';
import { SearchResult } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { UserActionService } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class AccommodationsService {
  private apiUrl = '/api/accommodations';

  constructor(private http: Http, private userAction: UserActionService) {}

  getAccommodationsJobFunctions(
    claimUid: string,
    userRole: string,
    currentEmpUnum?: string
  ): Promise<AccommodationJobFunction[]> {
    let url = `${this.apiUrl}/getAccommodationsJobFunctions/${claimUid}/${userRole}/${currentEmpUnum}`;
    if (currentEmpUnum) {
      url += `?currentEmpUnum=${currentEmpUnum}`;
    }
    return this.http.get<AccommodationJobFunction[]>(url);
  }

  getManagerAccommodations(
    includeIndirects: boolean,
    userAction: UserActions,
    excludeSupervisor: boolean,
    empUnum: string
  ) {
    const url = `${this.apiUrl}/getManagerAccommodations/${includeIndirects}/${empUnum}/${excludeSupervisor}/${userAction}`;
    return this.http.get<AccommodationJobFunction[]>(url);
  }

  getAccommodationSummary(
    recordSource: string,
    claimUid: string
  ): Promise<any[]> {
    const url = `${this.apiUrl}/getAccommodationSummary/${recordSource}/${claimUid}`;
    return this.http.get<any[]>(url);
  }

  getAccommodationListing(
    empUnum: string,
    userOwns: boolean
  ): Promise<AccommodationListing[]> {
    const url = `${this.apiUrl}/getAccommodationListing/${empUnum}/${userOwns}`;
    return this.http.get<AccommodationListing[]>(url);
  }

  getAccommodationsInteractions(
    claimUid: string,
    empUnum: string
  ): Promise<AccommodationsInteractions> {
    const url = `${this.apiUrl}/getAccommodationsInteractions/${claimUid}/${empUnum}`;
    return this.http.get<AccommodationsInteractions>(url, {
      cache: this.userAction.getBustCache() ? false : true,
    });
  }

  jobFunctionRequest(
    claim: SearchResult,
    comments: string,
    validJobFunction: string,
    accommodationId: string,
    task: string
  ) {
    if (!comments) {
      comments = 'NA';
    }
    const dueDate = 'NA';
    const completedDate = 'NA';
    const request = {
      claimNumber: claim.claimNumber,
      lob: claim.lob,
      noteText: comments,
      dueDate: dueDate,
      completedDate: completedDate,
      status: validJobFunction === 'Yes' ? true : false,
      accommodationNumber: claim.claimNumber,
      accommodationId: accommodationId.toString(),
      task: task,
    };
    return this.http.post<boolean>(
      `${this.apiUrl}/JobFunctionRequest`,
      request
    );
  }

  saveAccommodation(accommodationDetails: any): Promise<boolean> {
    const url = `${this.apiUrl}/SaveAccommations`;
    return this.http.post<boolean>(url, accommodationDetails);
  }
}
