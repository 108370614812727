import { Injectable } from '@angular/core';
import { UserActions } from '../models/user';
import { IClaimant } from '../../my-sedgwick/components/labels/claimant-label.component';

@Injectable({
  providedIn: 'root',
})
export class UserActionService {
  private _bustCache: boolean = false;
  constructor() {}

  setAction(action: UserActions) {
    sessionStorage.setItem('mgrAction', JSON.stringify(UserActions[action]));
  }

  getAction(): UserActions | null {
    const actionString = sessionStorage.getItem('mgrAction');
    if (actionString !== null && actionString !== 'undefined') {
      const action = JSON.parse(actionString);
      return UserActions[action as keyof typeof UserActions];
    }
    return null;
  }

  setClaimant(claimant: IClaimant) {
    sessionStorage.setItem('claimant', JSON.stringify(claimant));
  }

  getClaimant(): IClaimant | null {
    const temp = sessionStorage.getItem('claimant');
    if (temp != null && temp != undefined) {
      return JSON.parse(temp) as IClaimant;
    }
    return null;
  }

  removeClaimant() {
    sessionStorage.removeItem('claimant');
  }

  setBustCache(value: boolean): void {
    sessionStorage.setItem('bustCache', String(value));
  }

  getBustCache(): boolean {
    return sessionStorage.getItem('bustCache') === 'true';
  }
}
