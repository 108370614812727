import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  BaseComponent,
  ConfigurationSetting,
  MyClaim,
  SearchResult,
  SelectOption,
  SnackbarService,
  SnackbarType,
  UserActionService,
  getUrlParams,
} from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { UserActions } from 'src/app/shared/models/user';
import { ConfigurationService } from '../../services/configuration.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { WorkScheduleService } from '../../services/workschedule.service';

@Component({
  selector: 'app-my-work-schedule-page',
  template: `
    <app-section class="tw-w-full">
      <!-- <app-page-header [label]="'Work Schedule'" *ngIf="claimId === null">
        <div class="container-header app-pb1 ">
          <div class="tw-flex tw-w-full tw-items-center">
            <div class="tw-flex-grow">
              <div class="tw-item-center tw-flex">
                <app-header-label class="tw-flex-none" type="Header1">
                  <div
                    class="app-pl2 tw-font-bold app-color-slate"
                    style="color:#67707a;"
                  >
                    {{ 'Work Schedule' }}
                  </div>
                </app-header-label>
              </div>
            </div>
          </div>
        </div>
      </app-page-header> -->
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb class="tw-w-[90%]"></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        [title]="title"
        [canExpand]="false"
        class="tw-w-full"
      >
        <app-loading-panel
          [state]="isClaimLoaded ? 'Loading' : 'Loaded'"
          loadingStyle="Image"
          loadingImageSrc="/assets/images/loader/my-claims-ghost.svg"
        >
          <ng-template #content>
            <div *ngIf="claimId !== null" class="app-pb2 tw-flex tw-flex-wrap">
              <div class="tw-w-[50%] ">
                <app-claimant-label
                  value
                  [claimant]="claim"
                  [showNameLabel]="true"
                  [disabled]="!userRole.manager || claim.userOwns"
                  [nameClass]="'headingbold'"
                >
                </app-claimant-label>
              </div>

              <div class="app-pb2 tw-w-[50%]">
                <app-claim-label
                  value
                  [claim]="claim"
                  layout="row"
                  [showNameLabel]="true"
                  [hideWatchList]="!(userRole.manager || userRole.controller)"
                ></app-claim-label>
              </div>
            </div>

            <ng-container *ngIf="!editMode">
              <div class="tw-w-full tw-flex tw-flex-wrap tw-py-2">
                <div class="tw-w-full md:tw-hidden tw-block app-pb1">
                  <app-button
                    emphasis="High"
                    *ngIf="showUpdate"
                    (click)="editMode = true"
                    >Update work schedule</app-button
                  >
                </div>
                <div class="tw-w-full md:tw-hidden tw-block app-pb1 app-pl1">
                  <div class="app-header-4">Current work schedule</div>
                </div>
                <app-action-row class="tw-w-full tw-hidden md:tw-block">
                  <div class="app-header-4" left1>Current work schedule</div>
                  <app-button
                    emphasis="High"
                    *ngIf="showUpdate"
                    (click)="editMode = true"
                    right1
                    >Update work schedule</app-button
                  ></app-action-row
                >

                <app-list
                  [dataSource]="currentWorkSchedule"
                  [fixScrollOverflowHeight]="'190px'"
                  [paginatorEnabled]="false"
                  [template]="row"
                  [ShowSort]="false"
                  [loaded]="isLoading"
                  class="app-pt2 tw-w-full "
                  [noDataIcon]="isLoading ? '' : noDataIcon"
                  overflowHeight="190px"
                  [noDataMessage]="
                    isLoading
                      ? ''
                      : 'No current work schedule information available'
                  "
                  [tableAriaLabel]="'Current work schedule'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full">
                      <app-list-header
                        label="Week"
                        class="tw-w-full md:tw-w-[20%]"
                      ></app-list-header>
                      <app-list-header
                        label="Saturday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Sunday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Monday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Tuesday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Wednesday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Thursday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Friday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Total"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                </app-list>

                <ng-template #row let-element role="rowgroup">
                  <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                    <td class="tw-w-full md:tw-w-[20%]">
                      <app-value
                        label="Week"
                        layout="row-xs"
                        [value]="element.effectiveDate"
                        [tabindex]="element.effectiveDate ? '0' : '-1'"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        label="Saturday"
                        layout="row-xs"
                        [value]="element.saturday | number"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.sunday | number"
                        label="Sunday"
                        layout="row-xs"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.monday | number"
                        label="Monday"
                        layout="row-xs"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.tuesday | number"
                        label="Tuesday"
                        [tabindex]="0"
                        layout="row-xs"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.wednesday | number"
                        label="Wednesday"
                        layout="row-xs"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.thursday | number"
                        label="Thursday"
                        layout="row-xs"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.friday | number"
                        label="Friday"
                        layout="row-xs"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [value]="element.total | number"
                        label="Total"
                        layout="row-xs"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                  </tr>
                </ng-template>

                <div class="tw-flex tw-flex-wrap tw-w-full app-pt2">
                  <div class="tw-w-full">
                    <div class="app-header-4">Previous work schedule</div>
                  </div>
                </div>
                <app-loading-panel
                  [state]="isPreviousLoading ? 'Loading' : 'Loaded'"
                  loadingStyle="Image"
                  loadingImageSrc="/assets/images/loader/my-claims-ghost.svg"
                >
                  <ng-template #content> </ng-template>
                </app-loading-panel>
                <app-list
                  [dataSource]="previousWorkSchedule"
                  [template]="row"
                  [fixScrollOverflowHeight]="'190px'"
                  [paginatorEnabled]="false"
                  [ShowSort]="false"
                  class="app-pt2 tw-w-full "
                  [loaded]="isPreviousLoading"
                  [noDataIcon]="isPreviousLoading ? '' : noDataIcon"
                  overflowHeight="190px"
                  [noDataMessage]="
                    isPreviousLoading
                      ? ''
                      : 'No previous work schedule information available'
                  "
                  [tableAriaLabel]="'Previous work schedule'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full">
                      <app-list-header
                        label="Week"
                        class="tw-w-full md:tw-w-[20%]"
                      ></app-list-header>
                      <app-list-header
                        label="Saturday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Sunday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Monday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Tuesday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Wednesday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Thursday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Friday"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Total"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                </app-list>
              </div>
              <ng-template #row let-element>
                <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                  <td class="tw-w-full md:tw-w-[20%]">
                    <app-value
                      [value]="element.effectiveDate"
                      label="Week"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.saturday | number"
                      label="Saturday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.sunday | number"
                      label="Sunday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.monday | number"
                      label="Monday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.tuesday | number"
                      label="Tuesday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.wednesday | number"
                      label="Wednesday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.thursday | number"
                      label="Thursday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.friday | number"
                      label="Friday"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[10%]">
                    <app-value
                      [tabIndex]="0"
                      [value]="element.total | number"
                      label="Total"
                      layout="row-xs"
                    >
                    </app-value>
                  </td>
                </tr>
              </ng-template>
            </ng-container>

            <form
              *ngIf="editMode"
              [formGroup]="formGroup"
              class="tw-flex tw-flex-wrap"
            >
              <div class="tw-w-full" *ngIf="sucessmsg">
                <app-toast type="Success">
                  Thank you for your update. A note will be sent to the
                  {{ examinerLabel }} who will execute the change in our claims
                  system. The {{ examinerLabel }}
                  will contact you as needed for additional information
                </app-toast>
              </div>
              <div *ngIf="!sucessmsg" class="tw-flex tw-flex-wrap">
                <div
                  class="app-header-3 tw-w-full tw-pb-3 col-black"
                  *ngIf="IsDriver"
                >
                  Work program
                </div>

                <div class="tw-w-full sm:tw-w-[50%]" *ngIf="IsDriver">
                  What is
                  {{ ownClaim ? 'your ' : 'the '
                  }}{{ ownClaim ? '' : employeeSingularPossessive }}
                  work program or work schedule?
                </div>
                <app-select-control
                  formControlName="program"
                  [formGroup]="formGroup"
                  label="Program"
                  ngDefaultControl
                  [options]="programOption"
                  class="tw-w-full sm:tw-w-[50%]"
                  *ngIf="IsDriver"
                ></app-select-control>

                <div class="tw-w-full sm:tw-w-[50%]" *ngIf="IsDriver">
                  What day of the week does
                  {{ ownClaim ? 'your ' : 'the '
                  }}{{ ownClaim ? '' : employeeSingularPossessive }}
                  program start?
                </div>
                <app-select-control
                  formControlName="dayOfWeek"
                  [formGroup]="formGroup"
                  label="Day of week"
                  ngDefaultControl
                  class="tw-w-full sm:tw-w-[50%]"
                  [options]="weekOption"
                  *ngIf="IsDriver"
                ></app-select-control>
                <ng-container *ngIf="isDisplayElements">
                  <div class="tw-w-full tw-pb-2">
                    We need to gather
                    {{ ownClaim ? 'your' : 'the ' }}
                    {{ ownClaim ? '' : employeeSingularPossessive }}
                    normal work {{ programLabel }}.
                  </div>
                  <ul class="tw-list-disc tw-list-inside tw-pb-4">
                    <li
                      *ngIf="
                        IsMiliatryLeaveClaim ||
                        IsSalaried ||
                        IsHourlyEMP ||
                        IsDriver
                      "
                    >
                      Enter
                      {{ ownClaim ? 'your' : 'the ' }}
                      {{ ownClaim ? '' : employeeSingularPossessive }}
                      scheduled work hours for each day.
                    </li>
                    <li
                      *ngIf="
                        IsMiliatryLeaveClaim ||
                        IsSalaried ||
                        IsHourlyEMP ||
                        IsDriver
                      "
                    >
                      Use zero hours for any days
                      {{ ownClaim ? 'you are' : 'the '
                      }}{{ ownClaim ? '' : employeeAlias + ' is' }}
                      not scheduled.
                    </li>
                    <li
                      *ngIf="
                        IsMiliatryLeaveClaim ||
                        IsSalaried ||
                        IsHourlyEMP ||
                        IsDriver
                      "
                    >
                      Week one should be the week that includes
                      {{ ownClaim ? 'your' : 'the ' }}
                      {{ ownClaim ? '' : employeeSingularPossessive }} first day
                      missed from work.
                    </li>
                    <li *ngIf="IsDriver">
                      {{ ownClaim ? 'Your' : 'The ' }}
                      {{ ownClaim ? '' : employeeSingularPossessive }} program
                      or 4 week work program is required. We use this
                      information to calculate your average base pay. It is
                      critical this information is accurate or pay may be
                      incorrect.
                    </li>
                    <li *ngIf="IsDriver">
                      If {{ ownClaim ? 'you' : 'the ' }}
                      {{ ownClaim ? '' : employeeAlias }} would like to use
                      {{ ownClaim ? 'your' : 'the ' }}
                      {{ ownClaim ? '' : employeeSingularPossessive }} available
                      PTO for pay during the Waiting Period or any unpaid
                      portion of your leave,
                      {{
                        ownClaim
                          ? 'please speak with your'
                          : 'please have them speak with their'
                      }}
                      manager / HR representative.
                    </li>
                    <li *ngIf="IsSalaried || IsDriver">
                      The work schedules are required to calculate
                      {{ ownClaim ? 'your' : 'the ' }}
                      {{ ownClaim ? '' : employeeSingularPossessive }} average
                      base pay for disability and paid leaves. It is critical
                      this information is accurate or pay may be incorrect.
                    </li>
                    <li>
                      Do not include any extra days which are not part of
                      {{ ownClaim ? 'your' : 'the ' }}
                      {{ ownClaim ? '' : employeeSingularPossessive }} normal
                      schedule
                    </li>
                  </ul>

                  <div class="tw-w-full tw-pt-4">
                    <div
                      formArrayName="weeks"
                      class="tw-w-full"
                      *ngFor="let week of weeks.controls; let i = index"
                    >
                      <div
                        class="tw-w-full tw-flex  tw-flex-wrap tw-pb-4"
                        [formGroupName]="i"
                      >
                        <div class="tw-w-full sm:tw-w-[8%]">
                          <div class="tw-font-bold tw-pt-4">
                            Week {{ i + 1 }}
                          </div>
                        </div>

                        <app-text-control
                          formControlName="sun"
                          label="Sunday"
                          validationControl="Sunday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <app-text-control
                          formControlName="mon"
                          label="Monday"
                          validationControl="Monday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <app-text-control
                          formControlName="tue"
                          label="Tuesday"
                          validationControl="Tuesday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <app-text-control
                          formControlName="wed"
                          label="Wednesday"
                          validationControl="Wednesday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <app-text-control
                          formControlName="thu"
                          label="Thursday"
                          validationControl="Thursday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <app-text-control
                          formControlName="fri"
                          label="Friday"
                          validationControl="Friday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <app-text-control
                          formControlName="sat"
                          label="Saturday"
                          validationControl="Saturday"
                          ngDefaultControl
                          [formGroup]="week"
                          mask="separator.2"
                          class="tw-w-[33%] sm:tw-w-[12%]"
                        >
                        </app-text-control>
                        <div class="tw-w-[33%] sm:tw-w-[8%]">
                          <div class="tw-pt-4">
                            <a
                              class="tw-px-1"
                              (keyup.enter)="copyWeek(week)"
                              (click)="copyWeek(week)"
                              tabindex="0"
                              [attr.aria-label]="'Copy week ' + (i + 1)"
                              >Copy</a
                            >

                            <a
                              (click)="pasteWeek(week)"
                              (keyup.enter)="pasteWeek(week)"
                              [attr.aria-label]="'Paste into week ' + (i + 1)"
                              tabindex="0"
                              >Paste</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <app-action-row class="tw-w-full tw-pt-2">
                <app-button
                  *ngIf="!sucessmsg"
                  emphasis="High"
                  right2
                  (click)="updateworkschedule()"
                  >Update</app-button
                >
                <app-button *ngIf="!sucessmsg" right1 (click)="closeupdate()"
                  >Cancel</app-button
                >
              </app-action-row>
            </form>

            <ng-template #schedule let-data="data">
              <div class="tw-w-full tw-flex tw-flex-wrap tw-py-2">
                <app-action-row class="tw-w-full tw-pt-2">
                  <div left1 class="app-header-3 ">{{ data.label }}</div>
                  <app-button emphasis="High" right1 (click)="editMode = true"
                    >Update work schedule</app-button
                  >
                </app-action-row>
                <app-list
                  [dataSource]="data.dataSource"
                  [template]="row"
                  [paginatorEnabled]="false"
                  class="tw-w-full app-pr1 app-pb2"
                  overflowHeight="200px"
                  [tableAriaLabel]="'Work schedule'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full">
                      <app-list-header
                        name="week"
                        label="week"
                        class="tw-w-[20%]"
                      />
                      <app-list-header
                        name="sat"
                        label="saturday"
                        class="tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        name="sun"
                        label="sunday"
                        class="tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        name="mon"
                        label="monday"
                        class="tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        name="tue"
                        label="tuesday"
                        class="tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        name="wed"
                        label="wednesday"
                        class="tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        name="thu"
                        label="thursday"
                        class="tw-w-[10%]"
                      />
                      <app-list-header
                        name="fri"
                        label="friday"
                        class="tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        name="total"
                        label="total"
                        class="tw-w-[10%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                  <ng-template #row let-element>
                    <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                      <td class="tw-w-full md:tw-w-[20%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >week: </span
                        ><span class="tw-inline">{{ element.week }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >monday: </span
                        ><span class="tw-inline">{{ element.mon }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >tuesday: </span
                        ><span class="tw-inline">{{ element.tue }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >wednesday: </span
                        ><span class="tw-inline">{{ element.wed }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >thursday: </span
                        ><span class="tw-inline">{{ element.thu }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >friday: </span
                        ><span class="tw-inline">{{ element.fri }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >saturday: </span
                        ><span class="tw-inline">{{ element.sat }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >sunday: </span
                        ><span class="tw-inline">{{ element.sun }}</span>
                      </td>
                      <td class="tw-w-full md:tw-w-[10%]">
                        <span
                          class="app-label tw-block md:tw-hidden tw-w-fit tw-inline"
                          >total: </span
                        ><span class="tw-inline">{{ element.total }}</span>
                      </td>
                    </tr>
                  </ng-template>
                </app-list>
              </div>
            </ng-template>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      .pdt15 {
        padding-top: 15px;
      }
      ::ng-deep .mat-mdc-form-field-error-wrapper {
        padding: 0 13px !important;
      }

      .col-black {
        color: #171c20 !important;
      }
    `,
  ],
})
export class MyWorkSchedulePageComponent
  extends BaseComponent
  implements OnInit
{
  workSchedule: WorkScheduleItem[] = [];
  currentWorkSchedule: WorkScheduleItem[] = [];
  previousWorkSchedule: WorkScheduleItem[] = [];
  programOption: SelectOption[] = WorkProgram.ProgramOptions;
  weekOption: SelectOption[] = WorkProgram.WeekStartOptions;

  title: string;
  isLoading: boolean = true;
  isClaimLoaded: boolean = true;
  isPreviousLoading: boolean = true;
  employeeAlias: string;
  examinerLabel: string;
  employeeSingularPossessive: string;
  myWorkSchedule = false;
  isDisplayElements: boolean = true;

  editMode = false;
  sucessmsg = false;

  formGroup: FormGroup;

  copiedWeek: any;

  noDataIcon: string = 'date_range';
  claimConfig: ConfigurationSetting;
  warnThreshold: number;
  myClaims: MyClaim[];
  InvalidDecimalData: string = 'Invalid value';
  showUpdate: boolean = false;
  status: string;
  claim: SearchResult = <SearchResult>{};
  claimId: string;
  source: string;
  employeeType: string;
  ownClaim: boolean = true;
  mgrAction: UserActions | null;
  programLabel: string;
  IsMiliatryLeaveClaim: boolean = false;

  get weeks(): FormArray<FormGroup> {
    return this.formGroup.get('weeks') as FormArray;
  }

  get IsDriver(): boolean {
    if (this.employeeType == 'D' && !this.IsMiliatryLeaveClaim) {
      return true;
    } else {
      return false;
    }
  }

  get IsSalaried(): boolean {
    if (this.employeeType === 'S' && !this.IsMiliatryLeaveClaim) {
      return true;
    } else {
      return false;
    }
  }

  get IsHourlyEMP(): boolean {
    if (this.employeeType === 'H' && !this.IsMiliatryLeaveClaim) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private fb: FormBuilder,
    public session: Session,
    public userRole: UserRoleService,
    private snackBarService: SnackbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userAction: UserActionService,
    private claimConfigService: ConfigurationService,
    private workScheduleService: WorkScheduleService,
    public inpageAliasService: InpageAliasService
  ) {
    super();
    //const params = getUrlParams();
    //this.myWorkSchedule = !params.empId;

    this.formGroup = this.fb.group({
      program: [null],
      dayOfWeek: [null],
      weeks: this.fb.array([]),
    });
  }
  async ngOnInit() {
    this.isLoading = true;
    this.isPreviousLoading = true;
    this.isClaimLoaded = true;
    const aliases = await this.claimConfigService.GetConfig.configFeatures
      .aliases;
    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingular',
      aliases
    );

    this.employeeAlias = await this.inpageAliasService.replaceAliases(
      'employeeSingular',
      aliases
    );

    this.employeeSingularPossessive =
      await this.inpageAliasService.replaceAliases(
        'employeeSingularPossessive',
        aliases
      );
    await this.loadClaimFromUrl();
    const loggedInUser = {
      EmpUnum:
        this.claimId != null ? this.claim.empUnum : this.session.user.empUnum,
      ContNum: this.session.user.contNum,
      StartDay: 6,
    };

    this.title = this.claimId ? 'Work schedule' : 'My work schedule';

    /*this.employeeType =
      this.claimId != null
        ? this.claim.employeeType
        : this.session.user.employeeType;*/
    this.employeeType =
      this.claimId != null
        ? this.claim.employeeType != undefined &&
          this.claim.employeeType != null &&
          this.claim.employeeType.trim().toLowerCase() != 'null'
          ? this.claim.employeeType
          : this.session.user.employeeType
        : this.session.user.employeeType;
    if (!this.IsMiliatryLeaveClaim) {
      if (this.IsSalaried) {
        this.addWeek();
        this.addWeek();
        this.addWeek();
        this.programLabel = 'schedule';
      } else if (this.IsHourlyEMP) {
        this.addWeek();
        this.addWeek();
        this.programLabel = 'schedule';
      } else if (this.IsDriver) {
        this.formGroup.controls['program'].setValidators([Validators.required]);
        this.formGroup.controls['dayOfWeek'].setValidators([
          Validators.required,
        ]);
        if (this.claim.lob == 'DS') {
          this.addWeekWithoutValidation();
          this.addWeekWithoutValidation();
          this.addWeekWithoutValidation();
          this.addWeekWithoutValidation();
        } else {
          this.addWeek();
          this.addWeek();
          this.addWeek();
          this.addWeek();
        }
        this.session.loadUser();
        this.isDisplayElements = this.claim.lob == 'LV' ? true : false;
        this.programLabel = 'program';
      }
    }
    const customeWorkSchedule =
      await this.workScheduleService.getcutomworkschedule(loggedInUser);

    if (customeWorkSchedule != undefined && customeWorkSchedule.length > 0) {
      customeWorkSchedule.forEach((val, ind) => {
        if (val.workScheduleType == 'Current') {
          this.currentWorkSchedule = val.weeks;
        }
        if (val.workScheduleType == 'Previous') {
          this.previousWorkSchedule = val.weeks;
        }
      });
    }
    this.isLoading = false;
    this.isPreviousLoading = false;

    this.subs.sink = this.formGroup.controls['program'].valueChanges.subscribe(
      (value) => {
        if (this.claim != null && this.claim != undefined && this.IsDriver) {
          if (value == 'other' || this.claim.lob == 'LV') {
            this.isDisplayElements = true;
            this.weeks.controls.forEach((val, ind) => {
              val.controls['mon'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['mon'].updateValueAndValidity();
              val.controls['tue'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['tue'].updateValueAndValidity();
              val.controls['wed'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['wed'].updateValueAndValidity();
              val.controls['thu'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['thu'].updateValueAndValidity();
              val.controls['fri'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['fri'].updateValueAndValidity();
              val.controls['sat'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['sat'].updateValueAndValidity();
              val.controls['sun'].setValidators([
                Validators.required,
                Validators.max(20),
                customvalidations.IsNumeric(this.InvalidDecimalData),
              ]);
              val.controls['sun'].updateValueAndValidity();
            });
          } else {
            this.isDisplayElements = false;
            this.weeks.controls.forEach((val, ind) => {
              val.controls['mon'].setValidators(null);
              val.controls['mon'].updateValueAndValidity();
              val.controls['tue'].setValidators(null);
              val.controls['tue'].updateValueAndValidity();
              val.controls['wed'].setValidators(null);
              val.controls['wed'].updateValueAndValidity();
              val.controls['thu'].setValidators(null);
              val.controls['thu'].updateValueAndValidity();
              val.controls['fri'].setValidators(null);
              val.controls['fri'].updateValueAndValidity();
              val.controls['sat'].setValidators(null);
              val.controls['sat'].updateValueAndValidity();
              val.controls['sun'].setValidators(null);
              val.controls['sun'].updateValueAndValidity();
            });
          }
        }
      }
    );
  }

  private async loadClaimFromUrl() {
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
    if (this.claimId) {
      const claimData = await lastValueFrom(
        this.http.get<SearchResult>(
          `/api/claim/${this.source}/${this.claimId}/${'NA'}/summary`
        )
      );
      this.setClaim(claimData);

      if (
        this.claim != null &&
        this.claim.type != undefined &&
        this.claim.type != null &&
        this.claim.lob == 'LV' &&
        this.claim.type.toLowerCase() == 'military'
      ) {
        this.IsMiliatryLeaveClaim = true;
        this.programLabel = 'schedule';
      } else {
        this.IsMiliatryLeaveClaim = false;
      }
      if (this.IsMiliatryLeaveClaim) {
        this.addWeek();
        this.addWeek();
        this.addWeek();
      }
    } else {
      this.isClaimLoaded = false;
    }
  }

  private async setClaim(claim: SearchResult) {
    this.isClaimLoaded = false;
    if (claim === null) {
      return;
    }
    this.claim = claim;

    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
      if (this.mgrAction !== UserActions.ViewOwnClaim) {
        this.ownClaim = false;
      }
    }

    this.status = this.claim.status;
    this.claimConfig = await this.claimConfigService.getClaimConfiguration(
      claim
    );
    this.showUpdate =
      this.status.toLowerCase().includes('open') &&
      this.claimConfigService.getFeatureAccess('updateworkschedule');

    if (this.claim.lob == 'DS' && this.IsDriver) {
      this.isDisplayElements = false;
    }
  }

  copyWeek(week: FormGroup) {
    this.copiedWeek = week.value;
  }

  pasteWeek(week: FormGroup) {
    if (this.copiedWeek) {
      week.setValue(this.copiedWeek);
    }
  }

  private addWeek() {
    const week = this.fb.group({
      mon: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
      tue: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
      wed: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
      thu: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
      fri: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
      sat: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
      sun: [
        null,
        [
          Validators.required,
          Validators.max(20),
          customvalidations.IsNumeric(this.InvalidDecimalData),
        ],
      ],
    });
    this.weeks.push(week);
  }

  private addWeekWithoutValidation() {
    const week = this.fb.group({
      mon: [null, []],
      tue: [null, []],
      wed: [null, []],
      thu: [null, []],
      fri: [null, []],
      sat: [null, []],
      sun: [null, []],
    });
    this.weeks.push(week);
  }

  closeupdate() {
    this.sucessmsg = false;
    this.editMode = false;
    this.clearAll();
  }

  async updateworkschedule() {
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackBarService.show(
        'Preview-only mode. Information not sent.',
        SnackbarType.Info
      );
    } else {
      if (
        !this.IsDriver ||
        (this.IsDriver &&
          this.claim.lob == 'DS' &&
          this.formGroup.controls['program'].value == 'other') ||
        (this.IsDriver && this.claim.lob == 'LV')
      ) {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
          await this.workScheduleService
            .updateWeeklyWorkSchedule(this.createwsrequest())
            .then((res) => {
              this.sucessmsg = true;
              this.clearAll();
            })
            .finally(() => {});
        }
      } else if (
        this.IsDriver &&
        this.claim.lob == 'DS' &&
        this.formGroup.controls['program'].value != 'other'
      ) {
        this.formGroup.controls['program'].markAsTouched();
        this.formGroup.controls['dayOfWeek'].markAsTouched();
        if (this.formGroup.valid) {
          await this.workScheduleService
            .updateWeeklyWorkSchedule(this.createwsrequestForDriver())
            .then((res) => {
              this.sucessmsg = true;
              this.clearAll();
            })
            .finally(() => {});
        }
      }
    }
  }

  clearAll() {
    this.formGroup.controls['program'].reset(null);
    this.formGroup.controls['dayOfWeek'].reset(null);
    this.weeks.controls.forEach((val, index) => {
      val.controls['mon'].reset();
      val.controls['tue'].reset();
      val.controls['wed'].reset();
      val.controls['thu'].reset();
      val.controls['fri'].reset();
      val.controls['sat'].reset();
      val.controls['sun'].reset();
    });

    if (this.claim.lob == 'DS' && this.IsDriver) {
      this.isDisplayElements = false;
    }
  }

  createwsrequestForDriver() {
    const wsUpdateData: any = {
      Lob: this.claim.lob,
      ClaimId: this.claim.claimId,
      FileNumber: this.claim.claimNumber,
      UserOwns: this.claim.userOwns,
      NoteType: 'workschedule',
      PtoDays: '',
      CaseType: this.claim.source,
      WorkSchedule: {
        IntakeWorkWeeks: [
          {
            WeekNumber: 0,
            DaysWorked: 0,
            IntakeDays: [
              {
                DayOfWeek: 0,
                Minutes: 0,
                Hours: 0,
                FormattedHours: '',
                McfNumber: '',
              },
            ],
          },
        ],
      },
    };

    return wsUpdateData;
  }

  createwsrequest() {
    const wsUpdateData: any = {
      Lob: this.claim.lob,
      ClaimId: this.claim.claimId,
      FileNumber: this.claim.claimNumber,
      UserOwns: this.claim.userOwns,
      NoteType: 'workschedule',
      PtoDays: '',
      CaseType: this.claim.source,
      WorkSchedule: {
        IntakeWorkWeeks: JSON.parse(
          JSON.stringify(this.implementIntakeWorkWeeksData())
        ),
      },
    };

    return wsUpdateData;
  }

  implementIntakeWorkWeeksData() {
    const wsIntakeWeeks: any[] = [];
    let IntakeDays: any[] = [];

    this.weeks.controls.forEach((val, ind) => {
      let daysWorked = 0;
      IntakeDays = [];
      if (parseInt(val.controls['sun'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 0,
        Minutes: '0',
        Hours: parseInt(val.controls['sun'].value),
        FormattedHours: '',
        McfNumber: '',
      });

      if (parseInt(val.controls['mon'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 1,
        Minutes: '0',
        Hours: parseInt(val.controls['mon'].value),
        FormattedHours: '',
        McfNumber: '',
      });
      if (parseInt(val.controls['tue'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 2,
        Minutes: '0',
        Hours: parseInt(val.controls['tue'].value),
        FormattedHours: '',
        McfNumber: '',
      });
      if (parseInt(val.controls['wed'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 3,
        Minutes: '0',
        Hours: parseInt(val.controls['wed'].value),
        FormattedHours: '',
        McfNumber: '',
      });
      if (parseInt(val.controls['thu'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 4,
        Minutes: '0',
        Hours: parseInt(val.controls['thu'].value),
        FormattedHours: '',
        McfNumber: '',
      });
      if (parseInt(val.controls['fri'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 5,
        Minutes: '0',
        Hours: parseInt(val.controls['fri'].value),
        FormattedHours: '',
        McfNumber: '',
      });
      if (parseInt(val.controls['sat'].value) > 0) {
        daysWorked = daysWorked + 1;
      }
      IntakeDays.push({
        DayOfWeek: 6,
        Minutes: '0',
        Hours: parseInt(val.controls['sat'].value),
        FormattedHours: '',
        McfNumber: '',
      });

      wsIntakeWeeks.push({
        WeekNumber: (Number(ind) + 1).toString(),
        DaysWorked: daysWorked,
        IntakeDays: JSON.parse(JSON.stringify(IntakeDays)),
      });
    });

    return wsIntakeWeeks;
  }
}

class WorkScheduleItem {
  effectiveDate: string;
  weekNumber: string;
  saturday: number;
  sunday: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  total: number;
}

class WorkProgram {
  public static ProgramOptions: SelectOption[] = [
    { label: 'Regular', value: 'regular' },
    { label: '7 on 7 off', value: '7 on 7 off' },
    { label: '6 on 6 off', value: '6 on 6 off' },
    { label: '5 on 5 off', value: '5 on 5 off' },
    { label: '4 on 4 off', value: '4 on 4 off' },
    { label: '3 Day Thursdays', value: '3 day thursdays' },
    { label: '3 Day Fridays', value: '3 day fridays' },
    { label: '32 Driver', value: '32 driver' },
    { label: '63 Driver', value: '63 driver' },
    { label: '62 Driver', value: '62 driver' },
    { label: '5253', value: '5253' },
    { label: 'Other', value: 'other' },
  ];

  public static WeekStartOptions: SelectOption[] = [
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
  ];
}
