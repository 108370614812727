import { EventEmitter, Injectable, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  Activity,
  MessageBus,
  NewClaim,
  NotificationItem,
} from 'src/app/shared';
import {
  AlertActionType,
  SaveAlertActionRequest,
} from 'src/app/shared/models/alert';
import { Http } from 'src/app/shared/services/http.service';
import { UserActionService } from '../../shared';
import { ActivityRequest } from '../../shared/models/activity';
import { Restriction } from '../components/activity/restriction-detail.component';
import { UserRoleService } from './user-role.service';
import { Session } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  alertActions = signal<SaveAlertActionRequest[]>([]);

  alertSelected = new EventEmitter<string>();
  notificationCount = new EventEmitter<number>();
  messageCount = new EventEmitter<string>();
  focusNotification = new EventEmitter<string>();

  constructor(
    private http: Http,
    private userRole: UserRoleService,
    private userAction: UserActionService,
    private messageBus: MessageBus,
    private session: Session
  ) {}

  private subjectName = new Subject<any>(); //need to create a subject

  sendUpdate(count: boolean) {
    //the component that wants to update something, calls this fn
    this.subjectName.next({ text: count }); //next() will feed the value in Subject
  }

  getUpdate(): Observable<any> {
    //the receiver component calls this function
    return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  async postActivities(path: string, activityRequest: ActivityRequest) {
    return this.http.post<NotificationItem[]>(path, activityRequest, {});
  }

  async getActivities(path: string, activityRequest: ActivityRequest) {
    return this.http.post<Activity>(path, activityRequest, {
      cache: this.userAction.getBustCache() ? false : true,
    });
  }

  async saveAlertAction(request: SaveAlertActionRequest) {
    const actions = this.alertActions();
    this.alertActions.set([...actions, request]);
    this.messageBus.publish('alert-action', request);
    return this.http.post<number>('/api/alert', request);
  }

  async DismissNotificationAlert(
    triggerType: string,
    claimNumber: string,
    claimId: string,
    claimSource: string,
    userOwns: boolean,
    type?: AlertActionType
  ) {
    if (
      sessionStorage.getItem('triggerKey') !== null &&
      sessionStorage.getItem('alertMasterId') !== null
    ) {
      const alertAction: SaveAlertActionRequest = {
        action: 'DISMISSED - VOE',
        alertMasterUid: Number(sessionStorage.getItem('alertMasterId')),
        sourceSystem: claimSource,
        claimNumber: claimNumber.replace(' - ', ''),
        claimId: claimId,
        userOwns: userOwns,
        type: type,
      };

      await this.saveAlertAction(alertAction);
      sessionStorage.removeItem('triggerKey');
      sessionStorage.removeItem('alertMasterId');
    }
    this.userAction.setBustCache(true);
  }

  filterNewClaimAlerts(newClaims: NewClaim[], alertSelected: string) {
    return newClaims.filter((item) => item.alertId !== alertSelected);
  }

  filterNotifications(
    notifications: NotificationItem[],
    alertSelected: string
  ) {
    const tempNotifications = notifications.filter(
      (item) => item.alertId !== alertSelected
    );
    const tempLength = Number(tempNotifications.length);
    this.notificationCount.emit(tempLength >= 99 ? 99 : tempLength);

    return tempNotifications;
  }

  async getRestrictions(
    source: string,
    claimId: string,
    lob: string
  ): Promise<Restriction> {
    return await this.http.get<Restriction>(
      `/api/restriction/${source}/${claimId}/${lob}/restrictions`
    );
  }

  async getWorkStatusInfo(
    source: string,
    claimId: string,
    lob: string
  ): Promise<WorkStatus[]> {
    //await lastValueFrom(this.http.get<WorkStatus[]>(`/api/claim/${this.source}/${this.claimId}/${this.claim.lob}/WorkStatus`));
    return await this.http.get<WorkStatus[]>(
      `/api/claim/${source}/${claimId}/${lob}/WorkStatus`
    );
  }

  setFocusToNotification(index?: string) {
    const tab = index != null ? index : '';
    this.focusNotification.emit(tab);
  }
}

class WorkStatus {
  workStatus?: string;
  duration?: string;
  benefitBegin?: Date;
  benefitEnd?: Date;
  type?: string;
  benefitStatus?: string;
}

class WorkSchedule {
  StartDate?: Date;
  EffectiveDate?: string;
  WeekNumber?: number;
  Sunday?: number;
  Monday?: number;
  Tuesday?: number;
  Wednesday?: number;
  Thursday?: number;
  Friday?: number;
  Saturday?: number;
  Total?: number;
}
