import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChange,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  BaseComponent,
  NotificationItem,
  SearchResult,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { UserRoleService } from '../..';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { RecentClaimService } from '../../services/recent-claim.service';
import { Session } from '../../services/session.service';
import { DismissDialogComponent } from './dismiss-dialog.component';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-notifications',
  template: `
    <app-loading-panel [state]="!notificationsLoaded ? 'Loading' : 'Loaded'">
      <ng-template #content>
        <div id="toastMessage" class="toastwidth" *ngIf="showFilter">
          <app-toast type="Info">
            This list has been filtered to show the notifications for a
            reminder.
          </app-toast>
        </div>
        <div id="clrBtn" class="btnClearDiv" *ngIf="showFilter">
          <button mat-button id="clear" class="btnClear" (click)="clear()">
            Clear filter
          </button>
        </div>

        <div
          id="notificationList"
          style="padding-top:30px;"
          *ngIf="notifications"
        >
          <app-list
            [dataSource]="notifications"
            [template]="row"
            class="app-pt2"
            [filterEnabled]="true"
            [DefaultSortColumn]="
              isSupervisorRole && !hideNameColumn ? 'date' : ''
            "
            DefaultSortDir="desc"
            [noDataIcon]="noDataIcon"
            [noDataMessage]="noDataMessage"
            [toastInfoMessage]="toastInfoMessage"
            [displayInfoToastMsg]="displayInfoToastMsg"
            [toastErrorMessage]="toasErrorMsg"
            [displayErrorToastMsg]="displayErrorToastMsg"
            [tableAriaLabel]="'Notifications'"
          >
            <ng-container header>
              <tr class="tw-flex tw-flex-wrap tw-w-full">
                <app-list-header
                  name="claimNumber"
                  label="Claim"
                  class="tw-w-full md:tw-w-[40%]"
                  [names]="['claimDescription']"
                ></app-list-header>
                <app-list-header
                  name="name"
                  label="Name"
                  class="tw-w-full md:tw-w-[10%]"
                  *ngIf="!isClaimant && !hideNameColumn"
                  [names]="['firstName', 'empId']"
                ></app-list-header>
                <app-list-header
                  name="message"
                  label="Message"
                  class="tw-w-full md:tw-w-[30%]"
                ></app-list-header>
                <app-list-header
                  name="date"
                  label="Date"
                  filterType="Date"
                  [sortEnabled]="true"
                  class="tw-w-full md:tw-w-[10%]"
                ></app-list-header>
                <app-list-header
                  label="Dismiss"
                  readOnly
                  class="tw-w-full md:tw-w-[10%]"
                ></app-list-header>
              </tr>
            </ng-container>
          </app-list>
        </div>

        <ng-template #row let-element let-i="index">
          <tr
            #claimRow
            class="tw-flex tw-flex-wrap tw-w-full tw-items-center"
            [attr.data-index]="i"
          >
            <td class="tw-w-full md:tw-w-[40%]">
              <app-value label="Claim" layout="row-xs">
                <span value>
                  <app-claim-label
                    *ngIf="element.type !== 'OPT_IN_A'"
                    [noLink]="false"
                    [claim]="element"
                    layout="row"
                    (displayWatchListErrorMsg)="
                      displayWatchListErrorMsg($event)
                    "
                  ></app-claim-label>
                </span>
              </app-value>
            </td>
            <td
              class="tw-w-full md:tw-w-[10%]"
              *ngIf="!isClaimant && !hideNameColumn"
            >
              <app-value label="Name" layout="row-xs">
                <span value
                  ><app-claimant-label
                    [claimant]="element"
                    [disabled]="isDisabled"
                  ></app-claimant-label
                ></span>
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[30%]">
              <app-value
                label="Message :"
                [tabindex]="element.message ? '0' : '1'"
                layout="col-xs"
              >
                <div value class="message">
                  {{ element.message }}

                  <div *ngIf="element.action">
                    <a href="javascript:void(0);" (click)="goToAction(element)">
                      {{ element.action.title }}
                    </a>
                  </div>
                </div>
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[10%]">
              <app-value
                label="Date"
                [tabindex]="element.date ? '0' : '1'"
                [value]="element.date | date : 'MM/dd/yyyy'"
                layout="row-xs"
              >
              </app-value>
            </td>

            <td class="tw-w-full md:tw-w-[10%] app-pb1">
              <div class="tw-hidden md:tw-block">
                <app-icon-button
                  icon="highlight_off"
                  label="Dismiss"
                  [attr.aria-label]="
                    'Dismiss notification for - ' + element.claimLabel
                  "
                  (click)="dismiss(element, i)"
                  (keydown.enter)="dismiss(element, i)"
                  tabindex="0"
                  role="button"
                >
                </app-icon-button>
              </div>
              <div class="tw-block md:tw-hidden">
                <a
                  class="tw-block md:tw-hidden"
                  [attr.aria-label]="'Dismiss ' + element.claimLabel"
                  (click)="dismiss(element, i)"
                >
                  <b>Dismiss</b>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
      </ng-template></app-loading-panel
    >
  `,
  styles: [
    `
      .message {
        max-width: 250px;
        word-wrap: normal;
        word-break: normal;
        font-size: 13px;
        padding: 4px 12px 0 0;
      }

      .toastwidth {
        width: 45%;
        padding-top: 30px;
      }
      .btnClearDiv {
        width: 45%;
        padding-top: 10px;
        padding-bottom: 20px;
      }
      .btnClear {
        float: right;
        background-color: #ffffff !important;
        color: #3f464d !important;
        border: 1.5px solid #3f464d !important;
        font-weight: bold !important;
      }
      .btnClear:hover {
        background-color: #f1f4f7 !important;
      }
      .hidden {
        display: hidden;
      }
    `,
  ],
})
export class NotificationsComponent extends BaseComponent implements OnInit {
  @Input()
  notifications: NotificationItem[];

  @Input()
  showFilter: boolean = false;

  @Input()
  showClaimLink: boolean = true;

  @Input()
  actualNotifications: NotificationItem[];

  defaultNotifications: NotificationItem[];

  @Input()
  ntnMyClaims: any;

  isManager: boolean = false;

  @Input()
  notificationsLoaded: boolean = false;

  @Input()
  mgrAction: UserActions | null;

  @Output() clearFilterEvent = new EventEmitter<void>();

  @ViewChildren('claimRow') claimRows: QueryList<ElementRef>;

  btnConfirmTitleText: string = 'Dismiss Notification';
  btnConfirmAltText: string = 'Press Confirm to dismiss test';
  JSON: any = JSON;
  Math: any = Math;
  rnd: string = Math.floor(Math.random() * 9999).toString();
  noDataIcon: string = 'notifications_none';
  noDataMessage: string = '<strong>No notifications</strong>';
  maxToastMessage: string =
    'Please use the Search page to find a claim that does not have an off work record in this list.';
  saveAlertRequest: SaveAlertActionRequest;
  isClaimant: boolean = this.session.user.role === 'HRM' ? true : false;
  displayErrorToastMsg: boolean = false;
  displayInfoToastMsg: boolean = false;
  toastInfoMessage: string;
  toasErrorMsg: string;
  isSupervisorRole: boolean = false;
  hideNameColumn: boolean = true;
  @Input()
  isFilterApplied: boolean = false;
  isDisabled: boolean;
  constructor(
    public dialog: MatDialog,
    private activityService: ActivityService,
    public session: Session,
    private router: Router,
    private recentClaimService: RecentClaimService,
    private snackbarService: SnackbarService,
    private http: HttpClient,
    private claimService: ClaimService,
    public userRole: UserRoleService,
    private userAction: UserActionService,
    private renderer: Renderer2
  ) {
    super();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // if (mainDataChange) {
    if (changes != null) {
      const change = changes['notifications'];

      if (change != undefined) {
        if (
          change.currentValue != null &&
          change.currentValue != undefined &&
          this.defaultNotifications == null
        ) {
          this.defaultNotifications = change.currentValue;
        }
      }
    }
  }

  ngOnInit(): void {
    this.isSupervisorRole = this.userRole.supervisor;
    if (this.isSupervisorRole) {
      const mgrAction = this.userAction.getAction();
      if (mgrAction == UserActions.ViewOwnMgrDb) {
        this.hideNameColumn = false;
      }

      if (this.userAction.getAction() == UserActions.ViewOwnMgrDb) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    }
    this.defaultNotifications = this.notifications;
    this.activityService.notificationCount.emit(
      Number(
        this.notifications !== null && this.notifications !== undefined
          ? this.notifications?.length
          : 0
      )
    );
    this.subs.sink = this.activityService.alertSelected.subscribe(
      (x: string) => {
        this.notifications = this.activityService.filterNotifications(
          this.notifications,
          x
        );
      }
    );

    if (this.notifications?.length > 200) {
      this.displayInfoToastMsg = true;
      this.toastInfoMessage =
        'Please use the Search page to find a claim that does not have notifications in this list.';
    }
    //console.log("Notifications", this.notifications);
    this.notificationsLoaded = true;
  }

  clear() {
    this.notifications = this.defaultNotifications;
    if (!this.userRole.manager) {
      this.ntnMyClaims.notifications = this.notifications;
    }
    this.isFilterApplied = false;
    this.clearFilterEvent.emit();
  }

  dismiss(claim: any, index: number) {
    if (
      this.session.user &&
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackbarService.show(
        'Preview only mode.  dismiss action was not actually taken.',
        SnackbarType.Info
      );
      return;
    }
    this.saveAlertRequest = new SaveAlertActionRequest({
      alertMasterUid: Number(claim.alertId),
      action: 'DISMISSED - VOE',
      sourceSystem: claim.source,
      claimNumber: claim.claimNumber,
      claimId: claim.claimId,
      lob: claim.lineOfBusiness,
      userOwns: claim.userOwns,
      type: 'Notification',
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      saveAlertRequest: this.saveAlertRequest,
    };
    const dialogRef = this.dialog.open(DismissDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
      this.focusNextClaim(index, result === 'dismissed');
    });
  }

  focusNextClaim(currentIndex: number, wasDismissed: boolean) {
    const claimArray = this.claimRows.toArray();
    const nextIndex = currentIndex;
    if (nextIndex < claimArray.length) {
      const nextClaimElement = claimArray[nextIndex].nativeElement;
      if (wasDismissed) {
        const firstFocusableElement = nextClaimElement.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      } else {
        const dismissButton = nextClaimElement.querySelector(
          'app-icon-button[icon="highlight_off"]'
        );
        if (dismissButton) {
          dismissButton.focus();
        }
      }
    }
  }

  displayWatchListErrorMsg(event: any) {
    this.displayErrorToastMsg = true;
    this.toasErrorMsg =
      this.recentClaimService.displayWatchListErrorMsg1(event);
  }

  getClaimClassHeader() {
    return this.isClaimant
      ? 'tw-w-full md:tw-w-[45%]'
      : 'tw-w-full md:tw-w-[30%]';
  }
  getClaimClassValue() {
    return this.isClaimant
      ? 'tw-w-full md:tw-w-[45%]'
      : 'tw-w-full md:tw-w-[30%] ';
  }

  async goToAction(el: NotificationItem) {
    this.snackbarService.show('Loading...', SnackbarType.Info);

    let optionalParams = {};
    let filter = '';

    const defaultParams = {
      claimId: el.claimId,
      source: el.source,
      alertMasterUid: el.alertId,
      lob: el.lineOfBusiness,
      claimNumber: el.claimNumber,
    };

    switch (el.type) {
      case 'OPT_IN_A':
        optionalParams = {
          focus: 'changepreferences',
        };
        break;
      case 'RTW_CONFIRM_NEEDED_A':
        optionalParams = {
          claimId: el.claimId,
          source: el.source,
          rtw: 'confirm',
          rtwType: el.message.toLowerCase().includes('full')
            ? 'Full'
            : el.message.toLowerCase().includes('restricted')
            ? 'Restricted'
            : null,
          rtwDate: el.date,
        };

        break;
      case 'MC_NEEDED_A':
        filter = 'medicare consent';
        break;
      case 'MROI_NEEDED_A':
        filter = 'medicare beneficiary';
        break;
      case 'ROI_NEEDED_A':
        if (el.message.toLowerCase().includes('medical authorization')) {
          filter = 'medical authorization';
        } else {
          filter = 'release of information';
        }
        break;
      case 'ROR_NEEDED_A':
        filter = 'reimbursement agreement';
        break;
      case 'MEDDOC_NEEDED_A':
        optionalParams = {
          focus: 'documents',
          showDialog: true,
        };
        break;
      default:
    }

    const triggerKey = `${el.type}_${el.claimNumber}_${el.source}`;

    sessionStorage.removeItem('triggerKey');
    sessionStorage.removeItem('alertMasterId');
    sessionStorage.setItem('triggerKey', triggerKey);
    sessionStorage.setItem('alertMasterId', el.alertId);
    this.userAction.setClaimant(el);
    //debugger;
    if (filter !== '') {
      const eSigns = [
        'OPT_IN_A',
        //'RTW_CONFIRM_NEEDED_A',
        'MC_NEEDED_A',
        'MROI_NEEDED_A',
        'ROI_NEEDED_A',
        'ROR_NEEDED_A',
      ];

      if (
        (el.type === 'ROI_NEEDED_A' || el.type === 'ROR_NEEDED_A') &&
        el.action.action === ''
      ) {
        el.action.action = '/claims';
      }
      const originalAction = el.action.action;

      if (eSigns.includes(el.type)) {
        el.action.action = 'e-sign';
      }

      const claim = await lastValueFrom(
        this.http.get<SearchResult>(
          `/api/claim/${el.source}/${el.claimId}/${'NA'}/summary`
        )
      );
      const forms = await this.claimService.getOnlineForm(claim);

      //localStorage.setItem(triggerKey, el.alertId);

      const filteredForms = forms.filter(function (_) {
        return (
          _.name.toLowerCase().includes(filter) ||
          _.type.toLowerCase().includes(filter)
        );
      });
      if (filteredForms && filteredForms.length > 0) {
        optionalParams = {
          flNum: filteredForms[0]?.doc,
          form: filteredForms[0]?.form,
          name: filteredForms[0]?.name,
          lossState: claim?.lossState,
          doc: filteredForms[0]?.doc,
          source: el?.source,
          claimId: el?.claimId,
          type: filteredForms[0]?.type,
        };
      } else {
        if (el.action.action == 'e-sign') {
          el.action.action = originalAction;
        }
        optionalParams = {
          focus: 'documents',
          rnd: this.rnd,
        };
      }
    }
    this.router.navigate([el.action.action], {
      queryParams: {
        ...defaultParams,
        ...optionalParams,
      },
    });
    // }
  }
}
