import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-label',
  template: `<div >
    <ng-content></ng-content>
  </div>`,
  styles: [],
})
export class HeaderLabelComponent implements OnInit {
  @Input()
  type: HeaderLabelType = 'Header2';

  constructor() {}

  ngOnInit(): void {}

  get headerClass() {
    return {
      'app-header-1': this.type === 'Header1',
      'app-header-2': this.type === 'Header2',
      'app-header-3': this.type === 'Header3',
    };
  }
}

type HeaderLabelType = 'Header1' | 'Header2' | 'Header3';
