export function stripPeriod(value: string): string {
  if (!value || !value.endsWith('.')) {
    return value;
  }

  // more than one period found, ending period is permitted
  if (value.indexOf('.') < value.length - 1) {
    return value;
  }

  // only one period, remove it.
  return value.replace('.', '');
}
