import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import {
  AddressPipe,
  BaseComponent,
  Claim,
  CsvParams,
  CsvService,
  Payment,
  SearchResult,
  ToastType,
} from 'src/app/shared';
import { PaymentInfo, PaymentRequest } from 'src/app/shared/models/payment';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';
import { UtilityService } from '../../services/utility.service';
@Component({
  selector: 'app-payment',
  template: `
    <app-loading-panel [state]="!paymentsLoaded ? 'Loading' : 'Loaded'">
      <ng-template #content>
        <div class="paymentMessage app-pb1" *ngIf="showPaymentDate">
          <app-toast type="Info"
            >Your next payment will be sent on
            {{ nextPaymentDate | date : 'MM/dd/yyyy' }}
          </app-toast>
        </div>
        <app-list
          [dataSource]="isHomePage ? paymentsDataMyClaims : paymentData"
          [template]="row"
          class="app-pt2"
          [exportEnabled]="true"
          exportButtonAriaLabel="Export and download these payments as a XLSX file"
          [paginatorTemplate]="historyMessage"
          noDataIcon="monetization_on"
          [noDataMessage]="noDataMessage"
          (export)="export()"
          *ngIf="paymentsLoaded"
          [tableAriaLabel]="'Payments'"
        >
          <ng-container header>
            <tr class="tw-flex tw-flex-wrap tw-w-full">
              <app-list-header
                name="issueDate"
                label="Issue Date"
                class="tw-w-full md:tw-w-[15%]"
              ></app-list-header>
              <app-list-header
                name="payee"
                label="Payee"
                class="tw-w-full md:tw-w-[21%]"
              ></app-list-header>
              <app-list-header
                name="amount"
                label="Amount"
                class="tw-w-full md:tw-w-[15%]"
              >
              </app-list-header>
              <app-list-header
                name="paymentMethod"
                label="Payment Method"
                class="tw-w-full md:tw-w-[20%]"
              >
              </app-list-header>
              <app-list-header
                name="fromDate"
                label="Detail"
                class="tw-w-full md:tw-w-[28%]"
              ></app-list-header>
            </tr> </ng-container
        ></app-list>
        <ng-template #row let-element>
          <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <td
              label="Issue Date"
              layout="row-xs"
              class="tw-w-full md:tw-w-[15%]"
            >
              <span value>
                <app-value
                  class="inlineBlock helpIcon"
                  [value]="element.issueDate | format : 'date'"
                  [tabIndex]="0"
                ></app-value>
                <div
                  class="inlineBlock"
                  #tooltip="matTooltip"
                  [matTooltip]="element.issueDateHelpText"
                  matTooltipPosition="below"
                  (mouseenter)="$event.stopImmediatePropagation()"
                  (mouseleave)="$event.stopImmediatePropagation()"
                  tabindex="0"
                  (click)="tooltip.toggle()"
                  (keydown.enter)="tooltip.toggle()"
                >
                  <mat-icon
                    alt="Issue Date information"
                    class="helpIcon smallhelpicon"
                    >help_outline</mat-icon
                  >
                </div>
              </span>
            </td>
            <td class="tw-w-full md:tw-w-[21%]">
              <app-value label="payee" layout="row-xs">
                <span value>
                  <span
                    class="hideIconMobile"
                    *ngIf="element.payee && element.payee !== ''"
                  >
                    <span *ngIf="showPayeeIcon(element)"
                      ><img
                        class="inlineBlock"
                        src="./assets/images/Employee.png"
                        width="25"
                        height="30"
                        alt=""
                    /></span>
                    <span *ngIf="showMedicalIcon(element)"
                      ><img
                        class="inlineBlock"
                        src="./assets/images/Medical_Provider.png"
                        width="25"
                        height="30"
                        alt=""
                    /></span>
                  </span>
                  <span
                    class="text-left-margin"
                    tabindex="0"
                    *ngIf="!(session?.user && session?.user?.emulatorContext)"
                    >{{ element.payee }}</span
                  >
                  <span
                    class="text-left-margin"
                    *ngIf="session?.user && session?.user?.emulatorContext"
                    tabindex="0"
                    >{{ obfuscatedText(element.payee, false) }}</span
                  >
                </span>
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[15%]">
              <app-value label="amount" layout="row-xs" [tabIndex]="0">
                <span value>
                  <span
                    *ngIf="!(session?.user && session?.user?.emulatorContext)"
                    >{{ element.amount | currency }}</span
                  >
                  <span
                    *ngIf="session?.user && session?.user?.emulatorContext"
                    >{{ obfuscatedText(element.amount, true) }}</span
                  >
                </span>
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[20%]">
              <app-value
                label="Payment Method"
                layout="row-xs"
                multilineHeaderStyle="pmt-header"
              >
                <span value>
                  <div class="app-pt1 app-pb1">
                    <span tabindex="0">
                      {{ getPaymentMethodText(element) }}
                    </span>
                    <span *ngIf="element.voided" class="void" tabindex="0"
                      >VOID</span
                    >
                    <div
                      tabindex="0"
                      *ngIf="element.documentType === 'Voucher'"
                      class="inlineBlock"
                      #tooltipPaymentMethod="matTooltip"
                      [matTooltip]="voucherHelpText"
                      matTooltipPosition="below"
                      (mouseenter)="$event.stopImmediatePropagation()"
                      (mouseleave)="$event.stopImmediatePropagation()"
                      (click)="tooltipPaymentMethod.toggle()"
                      (keydown.enter)="tooltipPaymentMethod.toggle()"
                    >
                      <mat-icon alt="Voucher information" class="helpIcon"
                        >help_outline</mat-icon
                      >
                    </div>
                  </div>
                </span>
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[28%]">
              <app-value>
                <span value>
                  <div *ngIf="isHomePage">
                    <strong class="label row-label detailstrong"
                      >CLAIM:
                    </strong>
                    <a
                      [routerLink]="['/claims']"
                      [queryParams]="{
                        claimId: element.claimId,
                        source: element.source
                      }"
                      >{{ element.claim }}</a
                    >
                  </div>
                  <div *ngIf="element.lob === 'DS'" tabindex="0">
                    <strong class="label row-label">GROSS-NET:&nbsp;</strong>
                    <span
                      *ngIf="!(session?.user && session?.user?.emulatorContext)"
                      >{{ element.billedAmount | currency }}</span
                    >
                    <span
                      *ngIf="session?.user && session?.user?.emulatorContext"
                      >{{ obfuscatedText(element.billedAmount, true) }}</span
                    >
                    -
                    <span
                      *ngIf="!(session?.user && session?.user?.emulatorContext)"
                      >{{ element.amount | currency }}</span
                    >
                    <span
                      *ngIf="session?.user && session?.user?.emulatorContext"
                      >{{ obfuscatedText(element.amount, true) }}</span
                    >
                    <div
                      class="inlineBlock"
                      #tooltip="matTooltip"
                      [matTooltip]="grossNetHelpText"
                      matTooltipPosition="below"
                      (mouseenter)="$event.stopImmediatePropagation()"
                      (mouseleave)="$event.stopImmediatePropagation()"
                      (click)="tooltip.toggle()"
                    >
                      <mat-icon alt="Gross Net information" class="helpIcon"
                        >help_outline</mat-icon
                      >
                    </div>
                  </div>
                  <div
                    tabindex="0"
                    *ngIf="
                      !(session?.user && session?.user?.emulatorContext) &&
                      element.lob !== 'DS'
                    "
                    [ngClass]="
                      !element.showAddress ? 'displayNone' : 'md:tw-block'
                    "
                  >
                    <strong
                      class="label row-label detailstrong"
                      *ngIf="element.showAddress"
                      >MAILING ADDRESS:</strong
                    ><br />
                    <span
                      [innerHtml]="
                        addresssPipe.transform(element.mailingAddress)
                      "
                    ></span>
                  </div>
                  <div
                    tabindex="0"
                    *ngIf="
                      (!isHomePage && showFromToDate) ||
                      element.lob === 'DS' ||
                      element.lob === 'WC'
                    "
                    class="fromTo"
                  >
                    <strong class="label row-label detailstrong"
                      >FROM-TO:&nbsp;</strong
                    >
                    <app-value
                      [value]="element.fromDate | format : 'date'"
                    ></app-value
                    >&nbsp;-&nbsp;<app-value
                      style="display:inline"
                      [value]="element.toDate | format : 'date'"
                    ></app-value>
                  </div>
                  <div *ngIf="element.icn && element.vendorId">
                    <span>
                      <a
                        *ngIf="
                          !(session?.user && session?.user?.emulatorContext)
                        "
                        class="link"
                        (click)="downloadFile(element)"
                        [routerLink]=""
                        target="_blank"
                        >Explanation of Bill Review</a
                      >
                      <span
                        *ngIf="session?.user && session?.user?.emulatorContext"
                        >Explanation of Bill Review</span
                      >
                    </span>
                  </div>
                </span>
              </app-value>
            </td>
          </tr>
        </ng-template>
        <ng-template #historyMessage>
          <i *ngIf="!isHomePage">Payment history for past 12 months.</i>
          <i *ngIf="isHomePage"
            >Payment history on open claims for the past 12 months.</i
          >
          <div
            class="inlineBlock"
            #tooltip="matTooltip"
            [matTooltip]="paymentHistoryHelpText"
            matTooltipPosition="below"
            (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="$event.stopImmediatePropagation()"
            (click)="tooltip.toggle()"
          >
            <mat-icon
              alt="Payment History information"
              class="helpIcon smallhelpicon"
              >help_outline</mat-icon
            >
          </div>
        </ng-template>
        <ng-template #payment let-payment>
          <div class="app-pt1 app-pb1">
            {{ payment.paymentMethod }}
            <span *ngIf="payment.voided" class="void">VOID</span>
          </div>
          <div *ngIf="payment.paymentMethod === 'Check'" class="app-pb1">
            <span>addresssPipe.transform(payment.mailingAddress)</span>
          </div>
        </ng-template>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .address {
        font-size: 12px;
      }

      .paymentMessage {
        max-width: 400px;
      }

      .void {
        background-color: var(--color-light-grey);
        color: black;
        font-weight: bold;
        padding: 4px;
        border-radius: 4px;
        margin-left: 4px;
      }

      .displayNone {
        display: none;
      }
      #explanation::ng-deep.row-xs-label:after {
        content: '';
      }

      .fromTo > *,
      .inlineBlock {
        display: inline-block !important;
      }

      .helpIcon {
        vertical-align: middle;
      }

      @media (max-width: 800px) {
        .hideIconMobile {
          display: none;
          visibility: hidden;
        }
      }
      .detailstrong {
        font-size: 11px !important;
      }
      .smallhelpicon {
        font-size: 18px !important;
        height: 18px;
        width: 18px;
        margin-left: 4px;
      }
      .text-left-margin {
        margin-left: 4px;
      }
    `,
  ],
  providers: [AddressPipe],
})
export class PaymentComponent extends BaseComponent implements OnChanges {
  voucherHelpText: string =
    "Voucher is an advised amount to pay provided to your employer by Sedgwick. The amount listed is the gross amount before deductions and a net amount will be provided through your employer's payroll process.";
  issueDateCheckHelpText: string = 'Date when the payment was mailed.';
  issueDateDirectDepositHelpText: string =
    'Date when the payment was deposited.';
  issueDateVoucherHelpText: string =
    "Date when an advised amount to pay was sent to your employer to be issued through your employer's payroll process.";
  paymentHistoryFirst: string =
    'Requests for additional details or dates may be sent to your ';
  paymentHistoryLast: string = " through mySedgwick's Communication Center.";
  examinerTemplate: string = '%examiner%';
  paymentHistoryHelpText: string = '';
  myClaimsNoData: string =
    '<br><strong>No payments have been issued for your open claim(s)</strong>';
  claimSummaryNoData: string =
    '<br><strong>No payments have been issued for this claim</strong>';
  noDataMessage: string = '';
  grossNetHelpText: string =
    'Gross is the payment amount before deductions. Net is the payment amount after deductions.';

  @Input()
  payments: Payment[];
  @Input()
  paymentsLoaded: boolean = false;

  @Input()
  claim: SearchResult;
  clientMessage: string;

  @Input()
  claims: SearchResult[];

  @Input()
  showTitle = true;
  @Input()
  paymentData: Payment[];

  @Input()
  allPaymentData: Payment[] = [];

  @Input()
  isHomePage = false;

  localPaymentsData: Payment[] = [];

  @Input()
  paymentsDataMyClaims: any[];

  @Input()
  paymentMyClaims: any[];

  isClaimant: boolean = false;

  paymentInfo: PaymentInfo;

  nextPaymentDate: Date;
  showPaymentDate: boolean;
  showFromToDate: boolean;
  toastMsg: string;
  toastIcon: string;
  toastType: ToastType;
  displayToastNoDataMsg: boolean = false;
  isPayments: boolean;
  isDisplayDetailsColumn: boolean = false;
  isEmulating: boolean = false;
  isLoaded: boolean = false;

  payeeIconLobList: string[] = ['DS', 'GL', 'AU', 'PR'];

  constructor(
    public claimConfigService: ConfigurationService,
    public claimService: ClaimService,
    public addresssPipe: AddressPipe,
    private utilityService: UtilityService,
    private csvService: CsvService,
    private datePipe: DatePipe,
    public session: Session,
    private configurationService: ConfigurationService,
    public inpageAliasService: InpageAliasService
  ) {
    super();

    this.isEmulating =
      this.session.user &&
      this.session.user?.emulatorContext &&
      this.session.user?.emulatorContext?.isEmulating;
  }

  async ngOnInit(): Promise<void> {
    this.localPaymentsData = this.paymentsDataMyClaims;
    this.isClaimant =
      this.session.user.role.toUpperCase() === 'HRM' &&
      this.session.user.authenticationType === 'CLM';
    this.noDataMessage = this.isHomePage
      ? this.myClaimsNoData
      : this.claimSummaryNoData;
    this.paymentData = [];
    this.session.user ? await this.session.loadUser() : null;
    if (
      this.claim?.listClientMessage &&
      this.claim?.listClientMessage.length > 0
    ) {
      this.clientMessage = this.claim?.listClientMessage.find(
        (x) => x.keyTab == 'Payments'
      )?.message;
    }
    if (!this.isHomePage && (this.claim != null || this.claim != undefined)) {
      this.paymentInfo = await this.claimService.getPayments(
        this.buildPaymentRequest(this.claim)
      );
      this.paymentData = this.paymentInfo.payments;
      this.nextPaymentDate = this.paymentInfo.nextPaymentDue;
      this.showPaymentDate = this.nextPaymentDate != null;
      this.showFromToDate = this.paymentInfo.showFromToDate;
      this.isPayments =
        this.paymentInfo.payments && this.paymentInfo.payments.length > 0;
      if (this.isPayments) {
        this.isDisplayDetailsColumn =
          this.paymentInfo.payments.find((x) => x.icn && x.vendorId) != null &&
          this.paymentInfo.payments.find((x) => x.icn && x.vendorId) !=
            undefined
            ? true
            : false;
      }
      if (this.showPaymentDate) {
        this.displayToastNoDataMsg = true;
        this.toastMsg =
          this.nextPaymentDate != null
            ? 'Your next payment will be sent on ' +
              this.datePipe.transform(this.nextPaymentDate, 'MM/dd/yyyy')
            : 'Your next payment will be sent on ';
        this.toastIcon = 'info';
        this.toastType = 'Info';
      }
    }

    const lobConfigs = await this.configurationService.getFeatureLobs(
      'payments'
    );
    if (this.isHomePage) {
      const userClaims = this.paymentMyClaims;
      const allPaymentDataTemp = <any>[];
      let paymentInfoTemp;
      if (this.localPaymentsData) {
        for (let i = 0; i < this.localPaymentsData.length; i++) {
          const claim = userClaims.find(
            (x) => x.claimId === this.localPaymentsData[i].claimId
          );
          if (
            !claim ||
            claim.status === 'Closed' ||
            !lobConfigs.some(
              (x) => x.value === claim.lineOfBusiness && x.key === 'payments'
            )
          ) {
            continue;
          } else {
            paymentInfoTemp = this.localPaymentsData[i];
            paymentInfoTemp.claim =
              claim.claimDescription + ' - ' + claim.claimNumber;
            paymentInfoTemp.claimId = claim.claimId;
            paymentInfoTemp.source = claim.source;
            paymentInfoTemp.lob = claim.dataSet;
            allPaymentDataTemp.push(paymentInfoTemp);
          }
        }
        this.paymentsDataMyClaims = allPaymentDataTemp;
        this.isPayments = this.paymentsDataMyClaims.length > 0;
        this.showFromToDate = true;
        if (this.isPayments) {
          this.isDisplayDetailsColumn =
            this.paymentsDataMyClaims.find((x) => x.icn && x.vendorId) !=
              null &&
            this.paymentsDataMyClaims.find((x) => x.icn && x.vendorId) !=
              undefined
              ? true
              : false;
        }
      }
    }

    if (this.paymentData) {
      this.setIssueDateHelpText(this.paymentData);
      //this.isLoaded = true;
      this.paymentsLoaded = true;
    }

    if (this.paymentsDataMyClaims) {
      this.setIssueDateHelpText(this.paymentsDataMyClaims);
      this.paymentsLoaded = true;
    }

    this.paymentHistoryHelpText =
      this.paymentHistoryFirst +
      (await this.inpageAliasService.replaceAliases(
        'examinerSingular',
        this.configurationService.GetConfig.configFeatures.aliases
      )) +
      this.paymentHistoryLast;
  }

  buildPaymentRequest(claim: SearchResult): PaymentRequest {
    const userClaim = claim.userOwns ?? false;
    return new PaymentRequest({
      claimKeys: [
        {
          lob: claim.lob,
          claimId: claim.claimId,
          source: claim.source,
          userOwns: userClaim,
          claimNumber: claim.claimNumber,
        },
      ],
    });
  }
  buildPaymentRequestFromClaim(claim: Claim): PaymentRequest {
    const userClaim = claim.userOwns ?? false;
    return new PaymentRequest({
      claimKeys: [
        {
          lob: claim.dataSet,
          claimId: claim.claimId,
          source: claim.source,
          userOwns: userClaim,
          claimNumber: claim.claimNumber,
        },
      ],
    });
  }

  addToArray(
    key: string,
    header: string,
    csvParams: CsvParams //, keys: string[],  headers: string[]
  ) {
    csvParams.keys.push(key);
    csvParams.headers.push(header);
  }

  showPayeeIcon(payment: Payment) {
    //WC
    if (payment.lob?.toUpperCase() === 'WC' && payment.nameType === 'C') {
      return true;
    }
    //DS, GL, AU, PR
    if (
      this.payeeIconLobList.indexOf(payment.lob?.toUpperCase()) > -1 &&
      payment.nameType === 'C'
    ) {
      return true;
    }

    return false;
  }

  showMedicalIcon(payment: Payment) {
    // WC
    if (payment.lob?.toUpperCase() === 'WC' && payment.nameType === 'P') {
      return true;
    }
    //DS, GL, AU, PR
    if (
      this.payeeIconLobList.indexOf(payment.lob?.toUpperCase()) > -1 &&
      payment.nameType === 'P'
    ) {
      return true;
    }

    return false;
  }

  generateExportColumns(csvParams: CsvParams) {
    this.addToArray('issueDate', 'Issue Date', csvParams);
    this.addToArray('paymentMethod', 'Payment Method', csvParams);
    this.addToArray('voided', 'Payment Voided', csvParams);
    if (!this.isEmulating) {
      this.addToArray('mailingAddress', 'Mailing Address', csvParams);
      this.addToArray('city', 'Mailing City', csvParams);
      this.addToArray('state', 'Mailing State', csvParams);
      this.addToArray('zipcode', 'Mailing ZipCode', csvParams);
      this.addToArray('payee', 'Payee', csvParams);
      this.addToArray('amount', 'Amount', csvParams);
    }
    this.addToArray('fromDate', 'From Date', csvParams);
    this.addToArray('toDate', 'To Date', csvParams);

    return csvParams;
  }

  export() {
    const csvParams = new CsvParams();
    csvParams.rows = this.paymentData;

    const tempPaymentData: any[] = [];
    const actualData = this.isHomePage
      ? this.paymentsDataMyClaims
      : this.paymentData;

    for (let i = 0; i < actualData.length; i++) {
      let combinedStreetAddress = actualData[i].mailingAddress.street1;
      combinedStreetAddress += actualData[i].mailingAddress.street2
        ? ' ' + actualData[i].mailingAddress.street2
        : '';
      const isVoucher = actualData[i].documentType === 'Voucher';
      tempPaymentData.push({
        issueDate: actualData[i].issueDate,
        paymentMethod: isVoucher ? 'Voucher' : actualData[i].paymentMethod,
        voided: actualData[i].voided,
        fromDate: actualData[i].fromDate,
        toDate: actualData[i].toDate,
        mailingAddress:
          isVoucher || !actualData[i].showAddress || actualData[i].lob === 'DS'
            ? ''
            : combinedStreetAddress,
        city:
          isVoucher || !actualData[i].showAddress || actualData[i].lob === 'DS'
            ? ''
            : actualData[i].mailingAddress.city,
        state:
          isVoucher || !actualData[i].showAddress || actualData[i].lob === 'DS'
            ? ''
            : actualData[i].mailingAddress.state,
        zipcode:
          isVoucher || !actualData[i].showAddress || actualData[i].lob === 'DS'
            ? ''
            : actualData[i].mailingAddress.zipCode,
        payee: actualData[i].payee,
        amount: actualData[i].amount,
      });
    }

    csvParams.rows = tempPaymentData;
    csvParams.keys = [];
    csvParams.headers = [];

    this.generateExportColumns(csvParams); //, keys, header

    this.csvService.download(csvParams);
  }
  async downloadFile(payment: Payment) {
    try {
      this.claimService
        .downloadPaymentDocument(
          this.isHomePage ? payment.claimId : this.claim.claimId,
          payment.icn,
          payment.vendorId
        )
        .then((resp: any) => {
          if (resp && resp?.content) {
            const filename = resp.content.headers[0].value[0];

            const contentType = resp.content.headers[1].value[0];
            const arrayBuffer = resp.content.headers[2].value[0];
            if (filename && contentType && arrayBuffer) {
              const binaryString = window.atob(arrayBuffer);
              const binaryLen = binaryString.length;
              const bytes = new Uint8Array(binaryLen);
              for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
              }
              if (bytes) {
                const blob = new Blob([bytes], { type: contentType });
                this.utilityService.downloadFile(blob, filename);
              }
            }
          }
        });
    } catch (error) {
      console.dir(error);
    }
  }
  obfuscatedText(value: any, isAmount: boolean): string {
    let newValue = '';
    if (isAmount) {
      const name = '$*';
      newValue = name.concat(Array(value.toString().length - 1).join('*'));
    } else {
      const splitName = value.split(' ');
      const firstpart = splitName[0];
      const lastpart = splitName[1];

      const value1 = firstpart
        ? '*'.concat(Array(firstpart.toString().length).join('*'))
        : '';
      const value2 = lastpart
        ? '*'.concat(Array(lastpart.toString().length).join('*'))
        : '';
      if (value1) {
        newValue = value1;
      }
      if (value2 && value1) {
        newValue = value1 + ' ' + value2;
      }
    }
    return newValue;
  }

  setIssueDateHelpText(payments: Payment[]) {
    for (let i = 0; i < payments.length; i++) {
      if (payments[i].documentType === 'Voucher') {
        payments[i].issueDateHelpText = this.issueDateVoucherHelpText;
      } else if (payments[i].paymentMethod == 'Check') {
        payments[i].issueDateHelpText = this.issueDateCheckHelpText;
      } else if (payments[i].paymentMethod == 'Direct Deposit') {
        payments[i].issueDateHelpText = this.issueDateDirectDepositHelpText;
      }
    }
  }

  getPaymentMethodText(element: Payment) {
    switch (element.documentType) {
      case 'Voucher':
        return 'Voucher';
    }
    return element.paymentMethod;
  }

  async ngOnChanges(changes: any) {
    if (changes.paymentsDataMyClaims?.currentValue && this.isHomePage) {
      const lobConfigs = await this.configurationService.getFeatureLobs(
        'payments'
      );
      const userClaims = this.paymentMyClaims;
      const allPaymentDataTemp = <any>[];
      let paymentInfoTemp;
      if (this.paymentsDataMyClaims) {
        for (let i = 0; i < this.paymentsDataMyClaims.length; i++) {
          const claim = userClaims.find(
            (x) => x.claimId === this.paymentsDataMyClaims[i].claimId
          );
          if (
            !claim ||
            claim.status === 'Closed' ||
            lobConfigs.filter((x) => x.value === claim.dataSet)?.length === 0
          ) {
            continue;
          } else {
            paymentInfoTemp = this.paymentsDataMyClaims[i];
            paymentInfoTemp.claim =
              claim.claimDescription + ' - ' + claim.claimNumber;
            paymentInfoTemp.claimId = claim.claimId;
            paymentInfoTemp.source = claim.source;
            paymentInfoTemp.lob = claim.dataSet;
            allPaymentDataTemp.push(paymentInfoTemp);
          }
        }
      }
      this.paymentsDataMyClaims = allPaymentDataTemp;
      this.isPayments = this.paymentsDataMyClaims.length > 0;
      this.showFromToDate = true;
      if (this.isPayments) {
        this.isDisplayDetailsColumn =
          this.paymentsDataMyClaims.find((x) => x.icn && x.vendorId) != null &&
          this.paymentsDataMyClaims.find((x) => x.icn && x.vendorId) !=
            undefined
            ? true
            : false;
      }
    }
    if (this.paymentData) {
      this.setIssueDateHelpText(this.paymentData);
    }
    if (this.paymentsDataMyClaims) {
      this.setIssueDateHelpText(this.paymentsDataMyClaims);
    }
  }
}
