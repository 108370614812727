import { Component, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import {
  BaseComponent,
  LoadingState,
  SearchResult,
  getUrlParams,
} from 'src/app/shared';
import {
  DetailSearchConfiguration,
  SearchRequest,
  SearchService,
  Session,
  UserRoleService,
} from '../..';
import { Title } from '@angular/platform-browser';
import { srSpeak } from '../../../shared';

@Component({
  selector: 'app-search-page',
  template: `
    <app-section>
      <app-claim-breadcrumb></app-claim-breadcrumb>
      <app-panel
        [title]="this.showEmployeeSearch ? 'Search' : 'Claim search'"
        [largeHeader]="true"
        [canExpand]="false"
        class="tw-w-full tw-min-h-[1000px]"
      >
        <div role="search">
          <app-tab-group [hideSingleTab]="true" [tabGroupId]="'searchTabGroup'">
            <app-tab
              label="Employees"
              id="employeesTab"
              featureName="Employees"
              *ngIf="showEmployeeSearch"
              [template]="employee"
            >
              <ng-template #employee>
                <div class="tw-pt-4"></div>
                <app-detail-search-form
                  #employeeSearchForm
                  [config]="employeeConfig"
                  (search)="searchEmployees($event)"
                  (clear)="employeeLoading = 'Init'"
                  [loading]="employeeLoading"
                  loadingImageSrc="/assets/images/loader/search-results-ghost.svg"
                >
                  <ng-template #results>
                    <app-search-count-row
                      [count]="employeeSearchResults.length"
                      resultSingle="result"
                      resultPlural="results"
                    ></app-search-count-row>
                    <app-search-employees-list
                      [searchResults]="employeeSearchResults"
                      class="tw-w-full"
                      (export)="employeeSearchForm.appendExport($event)"
                    />
                  </ng-template>
                </app-detail-search-form>
              </ng-template>
            </app-tab>
            <app-tab
              label="3rd Party Claimants"
              id="claimsTab"
              *ngIf="showClaimSearch"
              [template]="claimant"
            >
              <ng-template #claimant>
                <div class="tw-pt-4"></div>
                <app-detail-search-form
                  #claimSearchForm
                  [config]="config"
                  (search)="searchClaims($event)"
                  (clear)="loading = 'Init'"
                  [loading]="loading"
                  loadingImageSrc="/assets/images/loader/search-results-ghost.svg"
                >
                  <ng-template #results>
                    <app-search-count-row
                      [count]="searchResults.length"
                    ></app-search-count-row>
                    <app-search-results-list
                      [searchResults]="searchResults"
                      class="tw-w-full"
                      (export)="claimSearchForm.appendExport($event)"
                    />
                  </ng-template>
                </app-detail-search-form>
              </ng-template>
            </app-tab>
          </app-tab-group>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: [``],
})
export class SearchPageComponent extends BaseComponent implements OnInit {
  config = new DetailSearchConfiguration();

  employeeConfig = new DetailSearchConfiguration();

  searchResults: SearchResult[];

  employeeSearchResults: SearchResult[];

  loading: LoadingState = 'Init';

  employeeLoading: LoadingState = 'Init';

  showEmployeeSearch: boolean;

  showClaimSearch: boolean;

  constructor(
    public searchService: SearchService,
    public userRole: UserRoleService,
    public session: Session,
    private titleService: Title
  ) {
    super();
  }

  async ngOnInit() {
    if (this.userRole.auditor) {
      this.showClaimSearch =
        this.session.user.hasLiabilityLob ||
        (this.session.user.registrationMethod === 'CLAIM' &&
          this.session.user.lineOfBusiness.some(
            (lobObj) => lobObj.lob === 'WC' || lobObj.lob === 'DS'
          ));

      this.showEmployeeSearch =
        this.session.user.registrationMethod === 'HR' ||
        this.session.user.registrationMethod === 'NONE';
    } else {
      this.showClaimSearch = true;
      this.showEmployeeSearch = false;
    }

    this.initClaimConfig(
      this.userRole.auditor && this.session.user.hasLiabilityLob
    );
    this.initEmployeeConfig();
  }

  async searchClaims(searchRequest: SearchRequest) {
    this.loading = 'Loading';
    const parameters = searchRequest.getParameters();
    if (this.userRole.auditor) {
      parameters.IsLiabilityClaimSearch = this.session.user.hasLiabilityLob;
    }
    const searchInformation = await this.searchService.getSearchResults(
      parameters
    );

    this.searchResults = searchInformation.searchResults;
    this.loading = this.searchResults.length > 0 ? 'Loaded' : 'Empty';
    if (this.searchResults && this.searchResults.length === 0) {
      srSpeak('There are no results that match the search', 'polite');
    }
  }

  async searchEmployees(searchRequest: SearchRequest) {
    this.employeeLoading = 'Loading';
    const searchInformation = await this.searchService.getSearchResults(
      searchRequest.getParameters()
    );

    this.employeeSearchResults = searchInformation.searchResults;
    this.employeeLoading =
      this.employeeSearchResults.length > 0 ? 'Loaded' : 'Empty';
    if (this.employeeSearchResults && this.employeeSearchResults.length === 0) {
      srSpeak('There are no results that match the search', 'polite');
    }
  }

  private initClaimConfig(isAuditorLiabilitySearch: boolean) {
    this.config.key = 'search-page-claims';
    this.config.managerLookupSupervisor = true;
    this.config.managerLookupOmni = true;
    this.config.emptyFormAllowed = false;
    this.config.initialValue = {
      includeIndirectReports: true,
    };

    this.config.employeeId.visible = !this.userRole.controller;
    this.config.includeIndirect.visible = !this.userRole.controller;
    this.config.supervisor.visible = !this.userRole.controller;
    this.config.maxBackDays = null;

    // checking query string parameters. Quick search
    // passes search inputs on query string
    const params = getUrlParams();
    if (!isEmpty(params)) {
      this.config.initialValue = Object.assign(
        params,
        this.config.initialValue
      );
      this.config.initialSearch = true;
      this.config.clearParameters = true;
    }

    if (isAuditorLiabilitySearch) {
      this.config.employeeId.visible = false;
      this.config.managerLookupOmni = false;
      this.config.managerLookupSupervisor = false;
      this.config.includeIndirect.visible = false;
      this.config.limitLobsToLiability = true;
    }

    this.config.payType.visible =
      this.session.user.employeeType &&
      (this.session.user.roles.supervisor ||
        this.session.user.roles.omni ||
        this.session.user.roles.overseer);
  }

  private initEmployeeConfig() {
    this.employeeConfig.key = 'search-page-employees';
    this.employeeConfig.managerLookupSupervisor = true;
    this.employeeConfig.managerLookupOmni = true;
    this.employeeConfig.emptyFormAllowed = false;
    this.employeeConfig.initialValue = {
      includeIndirectReports: true,
    };

    this.employeeConfig.setVisibility(false);
    this.employeeConfig.firstName.visible = true;
    this.employeeConfig.lastName.visible = true;
    this.employeeConfig.employeeId.visible = true;
    this.employeeConfig.initialSearchMsg =
      'Employee search results will appear here.';
  }
}
