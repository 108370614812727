import {
  NgModule,
  Component,
  Input,
  ChangeDetectorRef,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChange,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AccountSettingsService } from 'src/app/my-sedgwick/services/accountsettings.service';
import { Http } from '../../services/http.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { EmailComponent } from './email.component';
import { PhoneComponent } from './phone.component';
@Component({
  selector: 'app-toggle-component',
  inputs: ['changeTrigger', 'emailAddressText', 'phoneNumberText'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <br />
      <form
        role="none"
        class="tw-flex tw-flex-wrap tw-flex-col"
        [formGroup]="toggleGroup"
      >
        <div>
          <mat-slide-toggle
            formControlName="emailEnabledForm"
            (change)="toggleForm($event, 'email')"
          >
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="font-style-authPref"
              ><b>Email :</b><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> </span
            ><span>{{ emailAddressLable }} </span><span></span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            ><span
              ><button *ngIf="showEditEmail" (click)="EditEmailOrPhone(emailAddressText, 'email')">
                <span
                  ><mat-icon class="edit-icon"
                    >mode_edit</mat-icon
                  ></span
                >
                <span>&nbsp;&nbsp;</span>
                <span class="edit-span" *ngIf="showEditEmail">Edit</span>
              </button></span
            >
          </mat-slide-toggle>
          <div *ngIf="editEmailComponent">
            <app-email-component
              [toggleFormGroup]="toggleGroup"
              formGroupName="emailGroup"
              [isCommPrefComp]="isFromCommPrefComponent"
              [showEmailAddressForm]="closeFieldsFormEmail"
              [showErrorMessage]="shwErrMsg"
              [emailMatch]="emailAddMatch"
              *ngIf="viewModelLoaded"
              (emailAuthCodeChanged)="emailAutCodeChanged($event)"
            >
            </app-email-component>
          </div>
        </div>
        <br />
        <div>
          <mat-slide-toggle
            formControlName="mobileEnabledForm"
            (change)="toggleForm($event, 'phone')"
          >
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="font-style-authPref"
              ><b>Text/SMS :</b><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
            <span *ngIf="phoneLable != null">{{ phoneLable }} </span
            ><span></span> <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
            <button *ngIf="showEditPhone" (click)="EditEmailOrPhone(phoneNumberText, 'phone')">
              <mat-icon class="edit-icon"
                >mode_edit</mat-icon
              >
               <span [class]="showspanphone">&nbsp;&nbsp;</span>
              <span class="edit-span" *ngIf="showEditPhone">Edit</span>
            </button>
          </mat-slide-toggle>
          <div *ngIf="editPhoneComponent">
            <app-phone-component
              formGroupName="phoneGroup"
              [isCommPrefComp]="isFromCommPrefComponent"
              *ngIf="viewModelLoaded"
              (phoneAuthCodeChanged)="phoneAutCodeChanged($event)"
            ></app-phone-component>
          </div>
        </div>
      </form>
    </div>
  `,

  styles: [
    `
      .edit-span {
        font-size: 15px;
        color: #007dbc;
        padding-left: 25px;
      }
      .edit-icon {
        font-size: 20px;
        color: #007dbc;
        position: absolute;
      }
      .not_show_span {
        display: none;
      }
      .show_span {
        display: block;
      }
    `,
  ],
})
export class ToggleComponent implements AfterViewInit {
  @Input() formGroupName: string;
  emailGroupComp: FormGroup;
  @Input()
  emailAddressText: string;
  emailValue: string = 't@t.com';
  @Input()
  phoneNumberText: string;
  @Output() onFormGroupChange: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  @Output()
  displayButtons = new EventEmitter();
  @Input()
  mfaComp: boolean;
  @Input()
  commPrefComp: boolean = false;
  @Output()
  clearFields = new EventEmitter();
  @Input()
  editEmailComponent = false;
  @Input()
  editPhoneComponent = false;
  @Input()
  changeAuthenticationComp: boolean = false;
  phoneLable: string;
  showspanphone: string = 'not_show_span';
  emailAddress: string;

  @ViewChildren(EmailComponent) 
  emailComp: QueryList<EmailComponent>;

  @ViewChildren(PhoneComponent) 
  mobilePhoneComp: QueryList<PhoneComponent>;

  confirmEmailAddress: string;
  toggleGroup: FormGroup;
  mobileNumber: string;
  emailAddressLable: string;
  request: any;
  isPhoneEnabled: boolean;
  isEmailEnabled: boolean;
  viewModelLoaded: boolean = true;
  @Input()
  closeFieldsFormEmail: boolean = false;
  @Input()
  closeFieldsFormphone: boolean = false;
  @Output()
  AuthCodeChanged = new EventEmitter();
  @Input()
  showEditEmail: boolean = false;
  @Input()
  showEditPhone: boolean = false;
  @Output()
  editComp = new EventEmitter();
  @Input()
  shwErrMsg: boolean;
  isFromCommPrefComponent: boolean = false;
  isEmailToggleupdated: boolean = false;
  isMobileToggleupdated: boolean = false;
  isChangeAuthenticationComponent: boolean = false;
  @Input()
  emailAddMatch: boolean = true;
  @Output()
  closeToastMessage = new EventEmitter();
  @Output()
  enableSaveButton: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private accountService: AccountSettingsService,
    private el: ElementRef,
    private rootFormGroup: FormGroupDirective,
    private http: Http
  ) {}

  ngAfterViewInit(): void {
    this.emailComp.changes.subscribe((comps: QueryList<EmailComponent>) => {
      if (comps.length > 0) {
        comps.first.emailAddressInput.nativeElement.focus();
      }
    })
    this.mobilePhoneComp.changes.subscribe((comps: QueryList<PhoneComponent>) => {
      if (comps.length > 0) {
        comps.first.mobileNumberInput.nativeElement.focus();
      }
    })
  }
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['mfaComp'];
    let change2: SimpleChange = changes['editEmailComponent'];
    let change3: SimpleChange = changes['editPhoneComponent'];
    let change4: SimpleChange = changes['emailAddressText'];
    let change5: SimpleChange = changes['showEditEmail'];
    let change6: SimpleChange = changes['shwErrMsg'];
    let change7: SimpleChange = changes['changeTrigger'];
    let change8: SimpleChange = changes['phoneNumberText'];
    let change9: SimpleChange = changes['commPrefComp'];
    let change10: SimpleChange = changes['emailToggleUpdated'];
    let change11: SimpleChange = changes['mobileToggleUpdated'];
    let change12: SimpleChange = changes['changeAuthenticationComp'];
    let change13: SimpleChange = changes['emailAddMatch'];
    let change14: SimpleChange = changes['showEditPhone'];

    if (change != undefined) {
      if (change.currentValue != null && change.currentValue != undefined) {
        this.mfaComp = change.currentValue;
      }
    }
    if (change2 != undefined) {
      if (change2.currentValue != null && change2.currentValue != undefined) {
        this.editEmailComponent = change2.currentValue;
      }
    }
    if (change3 != undefined) {
      if (change3.currentValue != null && change3.currentValue != undefined) {
        this.editPhoneComponent = change3.currentValue;
      }
    }
    if (change4 != undefined) {
      this.emailValue = '';
      if (change4.currentValue != null && change4.currentValue != undefined) {
        this.emailAddressText = change4.currentValue;
        if (this.emailAddressText) {
          this.emailAddressLable = this.obfuscatedEmail(this.emailAddressText);
        }
      }
    }
    if (change5 != undefined) {
      if (change5.currentValue != null && change5.currentValue != undefined) {
        this.showEditEmail = change5.currentValue;
      }
    }
    if (change6 != undefined) {
      if (change6.currentValue != null && change6.currentValue != undefined) {
        this.shwErrMsg = change6.currentValue;
      }
    }
    if (change8 != undefined) {
      if (change8.currentValue != null && change8.currentValue != undefined)
        this.phoneNumberText = change8.currentValue;
      if (this.phoneNumberText) {
        this.phoneLable = this.obfuscatedPhone(this.phoneNumberText);
      }
    }
    if (change9 != undefined) {
      if (change9.currentValue != null && change9.currentValue != undefined) {
        this.isFromCommPrefComponent = change9.currentValue;
      }
    }
    if (change10 != undefined) {
      if (change10.currentValue != null && change10.currentValue != undefined) {
        this.isEmailToggleupdated = change10.currentValue;
      }
    }
    if (change11 != undefined) {
      if (change11.currentValue != null && change11.currentValue != undefined) {
        this.isMobileToggleupdated = change11.currentValue;
      }
    }
    if (change12 != undefined) {
      if (change12.currentValue != null && change12.currentValue != undefined) {
        this.isChangeAuthenticationComponent = change12.currentValue;
      }
    }
    if (change13 != undefined) {
      if (change13.currentValue != null && change13.currentValue != undefined) {
        this.emailAddMatch = change13.currentValue;
      }
    }
    if (change14 != undefined) {
      if (change14.currentValue != null && change14.currentValue != undefined) {
        this.showEditPhone = change14.currentValue;
      }
    }
  }
  ngOnInit(): void {
    this.toggleGroup = this.rootFormGroup.control.get(
      'toggleGroup'
    ) as FormGroup;

    var emailGroup = this.fb.group({
      emailAddress: [
        '',
        [
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      confirmEmailAddress: [
        '',
        [
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
    });

    var emailGroupEnterCode = this.fb.group({
      enterAuthCode: [''],
    });

    var mobileGroupEnterCode = this.fb.group({
      enterAuthCode: [''],
    });

    var phoneGroup = this.fb.group({
      mobilenumber: [''],
    });
    this.toggleGroup.addControl('emailGroup', emailGroup);
    this.toggleGroup.addControl('emailGroupEnterCode', emailGroupEnterCode);
    this.toggleGroup.addControl('phoneGroup', phoneGroup);
    this.toggleGroup.addControl('mobileGroupEnterCode', mobileGroupEnterCode);
    this.displayButtons.emit(false);
    if (this.toggleGroup.controls['emailEnabledForm'].touched) {
      this.showEditEmail = false;
      this.viewModelLoaded = true;
    }
    if (this.toggleGroup.controls['mobileEnabledForm'].touched) {
      this.showEditPhone = false;
      this.viewModelLoaded = true;
    }
    this.changeDetector.detectChanges();

    if(this.phoneLable != null)
    {
      this.showspanphone = 'show_span'
    }
  }
  EditEmailOrPhone(event: any, requestType: any) {
    this.closeToastMessage.emit(true);
    if (requestType == 'email') {
      this.editEmailComponent = true;

      this.clearFields.emit('email');
      this.closeFieldsFormEmail = false;
      this.mfaComp = false;
      this.commPrefComp = false;
      this.viewModelLoaded = true;
      if (event !== null && event !== undefined) {
        this.toggleGroup.get('emailGroup').get('emailAddress').setValue(event);
        this.toggleGroup
          .get('emailGroup')
          .get('confirmEmailAddress')
          .setValue(event);
      }
      this.fnValidateEmailForm(
        this.toggleGroup.controls['emailEnabledForm'].value
      );
      this.editComp.emit('email');
      this.displayButtons.emit(true);
    }
    if (requestType == 'phone') {
      this.editPhoneComponent = true;

      this.clearFields.emit('phone');
      this.closeFieldsFormphone = false;
      this.viewModelLoaded = true;
      if (event !== null && event !== undefined) {
        this.toggleGroup.get('phoneGroup').get('mobilenumber').setValue(event);
      }
      this.fnValidateMobileForm(
        this.toggleGroup.controls['mobileEnabledForm'].value
      );
      this.editComp.emit('phone');
      this.displayButtons.emit(true);
    }
    this.enableSaveButton.emit(true);
  }
  public toggleForm(event: MatSlideToggleChange, type: string) {
    this.closeToastMessage.emit(true);
    this.enableSaveButton.emit(true);
    var data = this.toggleGroup.controls['phoneGroup'] as FormGroup;
    var phoneData = data.controls['mobilenumber'].value;
    var data1 = this.toggleGroup.controls['emailGroup'] as FormGroup;
    var emailData = data1.controls['emailAddress'].value;
    if (type === 'phone') {
      this.isPhoneEnabled = event.checked;
      this.fnValidateMobileForm(this.isPhoneEnabled);
      if (this.isPhoneEnabled) {
        if (this.phoneLable) {
          this.showEditPhone = true;
        } else {
          this.editPhoneComponent = true;
          if (this.isChangeAuthenticationComponent)
            this.displayButtons.emit(true);
        }
        this.viewModelLoaded = true;
      } else if (!this.isPhoneEnabled) {
        this.showEditPhone = false;
        if (this.isChangeAuthenticationComponent) {
          if (!this.isPhoneEnabled && !this.isEmailEnabled)
            this.displayButtons.emit(false);
        }

        this.editPhoneComponent = false;
        this.viewModelLoaded = true;
      }
    } else if (type === 'email') {
      this.isEmailEnabled = event.checked;
      this.fnValidateEmailForm(this.isEmailEnabled);
      if (this.isEmailEnabled) {
        if (this.emailAddressLable) {
          this.showEditEmail = true;
        } else {
          this.editEmailComponent = true;
          if (this.isChangeAuthenticationComponent)
            this.displayButtons.emit(true);
        }
        this.viewModelLoaded = true;
      } else if (!this.isEmailEnabled) {
        this.showEditEmail = false;
        this.editEmailComponent = false;
        if (this.isChangeAuthenticationComponent) {
          if (!this.isPhoneEnabled && !this.isEmailEnabled)
            this.displayButtons.emit(false);
        }
        this.viewModelLoaded = true;
      }
    }
  }
  emailAutCodeChanged(event: any) {
    if (event) this.AuthCodeChanged.emit('emailAuth');
  }
  phoneAutCodeChanged(event: any) {
    if (event) this.AuthCodeChanged.emit('phoneAuth');
  }
  obfuscatedEmail(email: any): string {
    var splitEmail = email.split('@');
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return name
      .substring(0, 1)
      .concat(Array(name.length - 1).join('*'))
      .concat(name.substring(name.length, name.length - 1))
      .concat('@')
      .concat(domain);
  }
  obfuscatedPhone(phone: any): string {
    var splitPhone = phone.split('-');
    var lastpart =
      splitPhone.length >= 2 ? splitPhone[2] : splitPhone[0].slice(-4);
    var fstString = '***-***-';
    return fstString.concat(lastpart);
  }
  fnValidateEmailForm(enabled: boolean): void {
    if (enabled != true) {
      this.toggleGroup
        .get('emailGroup')
        .get('emailAddress')
        .setValidators(null);
      this.toggleGroup.get('emailGroup').get('emailAddress').clearValidators();
      this.toggleGroup
        .get('emailGroup')
        .get('emailAddress')
        .updateValueAndValidity();
      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .setValidators(null);
      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .clearValidators();
      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .addValidators(
          customvalidations.matchTextControls(
            this.toggleGroup.get('emailGroup').get('emailAddress'),
            'The email addresses do not match.'
          )
        );
      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .updateValueAndValidity();
    }
    if (enabled === true) {
      this.toggleGroup
        .get('emailGroup')
        .get('emailAddress')
        .setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ]);

      this.toggleGroup
        .get('emailGroup')
        .get('emailAddress')
        .updateValueAndValidity();

      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ]);

      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .addValidators(
          customvalidations.matchTextControls(
            this.toggleGroup.get('emailGroup').get('emailAddress'),
            'The email addresses do not match.'
          )
        );
      this.toggleGroup
        .get('emailGroup')
        .get('confirmEmailAddress')
        .updateValueAndValidity();
    }
  }
  fnValidateMobileForm(enabled: boolean): void {
    if (enabled != true) {
      this.toggleGroup
        .get('phoneGroup')
        .get('mobilenumber')
        .setValidators(null);
      this.toggleGroup.get('phoneGroup').get('mobilenumber').clearValidators();
      this.toggleGroup
        .get('phoneGroup')
        .get('mobilenumber')
        .updateValueAndValidity();
    }
    if (enabled === true) {
      this.toggleGroup
        .get('phoneGroup')
        .get('mobilenumber')
        .setValidators([
          Validators.required,
          Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
        ]);
      this.toggleGroup
        .get('phoneGroup')
        .get('mobilenumber')
        .updateValueAndValidity();
    }
  }
}
