import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OpenFileService {
  constructor(private http: HttpClient) {}

  async openfile() {
    const controllerAction = `/api/claim/GetDirectorDocument`;
    await this.http
      .get<Result>(controllerAction, {})
      .subscribe(async (data: any) => {
        this.buildform(data.postURL, {
          SAMLFields: data.samlFields,
          TARGET: 'TALISPOINT',
        });
      });
  }

  buildform(url: string, inputs: any) {
    const form = document.createElement('form');

    form.method = 'post';
    form.target = '_blank';
    form.style.display = 'none';
    form.action = url;

    for (const inputName in inputs) {
      const input = document.createElement('input');

      input.setAttribute('type', 'hidden');
      input.setAttribute('name', inputName);
      input.setAttribute('id', inputName);
      input.setAttribute('value', inputs[inputName]);
      form.append(input);
    }

    if (
      navigator.userAgent.indexOf('Chrome') !== -1 ||
      navigator.userAgent.indexOf('MSIE ') !== -1 ||
      navigator.userAgent.indexOf('Edge') !== -1 ||
      navigator.userAgent.indexOf('Trident') !== -1
    ) {
      // do not remove target attr
    } else {
      form.removeAttribute('target');
    }
    const divs = document.body.querySelectorAll('div')
    divs[divs.length-1].appendChild(form);
    form.submit();
  }
}

export class Result {
  isSuccessful: boolean;
  message?: string;
  isLoggedOff: boolean;
  sAMLFields?: string;
  postURL: string;
}
