import { Injectable, signal } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { SearchInformation, SearchParameters } from 'src/app/shared';
import {
  Delegatee,
  Delegatees,
  Delegator,
} from '../components/delegate/act-delegate-page.component';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService, SnackbarType } from '../../shared';
@Injectable({
  providedIn: 'root',
})
export class DelegationService {
  currentDelegatees = signal<Delegatee[]>([]);

  searchParameters: SearchParameters;

  searchInfomration: SearchInformation;

  private delegateNameSource = new BehaviorSubject<string>('');
  delegateName$ = this.delegateNameSource.asObservable();

  constructor(private http: Http, private snackbarService: SnackbarService) {}

  clear() {
    if (this.searchInfomration != undefined || this.searchInfomration != null) {
      this.searchInfomration.searchResults = null;
      this.searchInfomration.isMaxCount = false;
      this.searchParameters = null;
    }
  }

  updateDelegateName(name: string) {
    this.delegateNameSource.next(name);
  }

  async getDelegateSearchResults(
    searchParameters: any,
    ownEmpUnum?: string
  ): Promise<Delegatees> {
    const body = {
      searchParameters: searchParameters,
      filteredDelegatorEmpUnum: ownEmpUnum,
    };
    return await this.http.post<Delegatees>(
      '/api/delegation/search-delegatees',
      body
    );
  }

  async loadUpdateDelegatees(empUnum: string) {
    await this.getDelegatees(empUnum);
  }

  async getDelegatees(delegatorId: string) {
    const response = await this.http.get<Delegatees>(
      `/api/delegation/getdelegatees/${delegatorId}`,
      { cache: false, timeout: 0 }
    );
    this.currentDelegatees.set(response.delegatees);
  }

  async getDelegators(delegateeId: string): Promise<Delegatees> {
    const response = await this.http.get<Delegatees>(
      `/api/delegation/getdelegators/${delegateeId}`
    );
    return response;
  }

  async upsertDeleteDelegatee(
    delegatee: Delegatee,
    filteredDelegator?: Delegator
  ) {
    const d = delegatee;
    d.dateEffective = new Date(d.dateEffective).toISOString();
    d.dateExpire = new Date(d.dateExpire).toISOString();

    const body = {
      newDelegatee: d,
      filteredDelegator: filteredDelegator,
    };

    const response = await this.http.post<boolean>(
      '/api/delegation/upsert-delete-delegatee',
      body
    );

    if (response) {
      await this.loadUpdateDelegatees(
        filteredDelegator ? filteredDelegator?.delegatorEmpUnum : ''
      );
    } else {
      this.snackbarService.show('Error adding delegate', SnackbarType.Error);
    }
  }
}
