import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  AbsenceService,
  ConfigurationService,
  DisplayService,
  IncidentalAbsence,
  IncidentalAbsenceReason,
  Session,
  UserRoleService,
} from '../..';
import { ConfigurationSetting, getUrlParams } from '../../../shared';
import { AbsencePageComponent } from '../absence/absence-page.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-absence-search',
  template: `
    <app-panel [title]="panelTitle" [largeHeader]="true" [canExpand]="false">
      <app-action-row
        class="tw-w-full"
        *ngIf="display.reportClaimSignal() && hasReportClaim"
      >
        <app-button right1 emphasis="High" (click)="employeeSearch()"
          >Submit an absence</app-button
        >
      </app-action-row>

      <app-tab-group
        *ngIf="loadingDone"
        [selectedIndex]="selectedIndex"
        [tabGroupId]="'absenceTabGroup'"
      >
        <app-tab
          label="Off Work"
          [badge]=""
          [visible]="showOffWork"
          [template]="newAbsences"
          name="offWork"
        >
          <ng-template #newAbsences>
            <app-new-absences *ngIf="showOffWork"></app-new-absences>
          </ng-template>
        </app-tab>
        <app-tab
          label="Search"
          [badge]=""
          [visible]="showSearch"
          [template]="absenceSearch"
          name="search"
        >
          <ng-template #absenceSearch
            ><app-absence-case-search
              *ngIf="showSearch"
            ></app-absence-case-search>
          </ng-template>
        </app-tab>
        <app-tab
          label="My Absences"
          [badge]=""
          [template]="myAbsences"
          name="my"
          [visible]="!session.isDelegating()"
        >
          <ng-template #myAbsences>
            <div class="app-pt3"></div>
            <app-my-absence-grid
              [reasonData]="reasonData"
              [myAbsenceData]="absenceData"
            ></app-my-absence-grid>
          </ng-template>
        </app-tab>
      </app-tab-group>
      <ng-template #dialogReportNewClaim>
        <app-dialog
          title="Confirmation"
          [width]="700"
          [showActions]="false"
          [showHeader]="false"
          ariaLabel="Confirmation dialog"
          ariaLabelMessage="Close announcement popup"
        >
          <app-report-new-claim></app-report-new-claim>
        </app-dialog>
      </ng-template>
    </app-panel>
  `,
  styles: [],
})
export class AbsenceSearchComponent implements OnInit {
  public abpagescom: AbsencePageComponent;
  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  absenceData: IncidentalAbsence[] = [];
  showOffWork: boolean = false;
  showSearch: boolean = false;
  selectedIndex = 2;
  loadingDone: boolean = false;
  reasonData: IncidentalAbsenceReason[] = [];
  panelTitle: string =
    this.session.user.role.toUpperCase() == 'EMPLOYEE' ||
    this.session.user.role.toUpperCase() == 'CLAIMANT'
      ? 'My Absences'
      : 'Absences';
  hasReportClaim = false;
  clientLevelConfig: ConfigurationSetting;
  isIncAbsence: boolean = false;

  constructor(
    public session: Session,
    public dialog: MatDialog,
    private myabsense: AbsenceService,
    private config: ConfigurationService,
    public userrole: UserRoleService,
    private router: Router,
    public display: DisplayService
  ) {}

  async ngOnInit() {
    !this.session.user ? await this.session.loadUser() : null;
    this.clientLevelConfig = await this.config.getAdditionalUserInformation();
    const listAbsenceOffWork = await this.myabsense.getMyAbsence();
    this.absenceData = this.myabsense.mergeDateToString(listAbsenceOffWork);
    //this.absenceData = await this.myabsense.getMyAbsence();
    this.showOffWork = this.config.getConfigFeatureAccess('IncidentalOffWork');
    this.showSearch = this.config.getConfigFeatureAccess('IncidentalSearch');
    this.isIncAbsence = this.config.getConfigFeatureAccess('IncidentalAbsence');
    if (this.showOffWork && (this.userrole.omni || this.userrole.overseer)) {
      // for omni and overseer default the tab to Search (when offWork is being shown)
      this.selectedIndex = 1;
    } else {
      this.selectedIndex = 0;
    }
    this.reasonData = await this.myabsense.getAbsenceReasons();

    this.loadingDone = true;
    this.hasReportClaim =
      !this.session.user.hasIntake ||
      this.session.user.hasIntake === undefined ||
      (this.isIncAbsence && this.userrole.employee)
        ? false
        : true;

    const params = getUrlParams();
    if (params?.focus === 'my') {
      this.selectedIndex = 2;
    } else if (params?.focus === 'search') {
      this.selectedIndex = 0;
    }
  }

  employeeSearch() {
    if (this.config.getConfigFeatureAccess('IncidentalAbsences5')) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Confirmation',
      };
      this.dialog.open(this.dialogRefReportNewClaim, dialogConfig);
    } else {
      this.router.navigate(['employeeSearch'], {});
    }
  }
}
