import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from 'src/app/my-sedgwick/services/utility.service';
import { Address } from '../models/address';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  constructor(private utility: UtilityService){

  }
  transform(value: Address, ...args: unknown[]): string {
    if(this.utility.isNullOrEmpty(value?.street1))
      return '';
    else
      return `${value.street1}${value?.street2 ? ', '+value?.street2+ '<br>' : '<br>'}
       ${(value?.city && (value?.state || value?.zipCode)) ? (value?.city+', ' + value?.state + ' '+ value?.zipCode) : (value?.state+ ' '+ value?.zipCode)}`;
  }
}
