import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationSetting, MessageBus, SearchResult } from 'src/app/shared';
import { Contact } from '../../models/Contact';
import { ConfigurationService } from '../../services/configuration.service';
import { InpageAliasService } from '../../services/inpage-alias.service';

@Component({
  selector: 'app-contact-list',
  template: `
    <app-loading-panel [state]="loaded ? 'Loaded' : 'Loading'">
      <ng-template #content>
        <div class="app-pt3 app-pb2 tw-flex tw-flex-wrap">
          <app-value
            *ngIf="examiner?.name && examinerName"
            label="{{ examinerLabel }}"
            value="{{ examinerName }}"
            class="tw-w-[50%] md:tw-w-[17%]"
            layout="col"
          ></app-value>
          <app-value
            *ngIf="
              examiner?.address?.street1 ||
              examiner?.address?.street2 ||
              examiner?.address?.city ||
              examiner?.address?.state ||
              examiner?.address?.zipCode
            "
            label="Mailing Address"
            class="tw-w-[50%] md:tw-w-[23%]"
            layout="col"
          >
            <span
              *ngIf="
                examiner?.address?.street1 ||
                examiner?.address?.street2 ||
                examiner?.address?.city ||
                examiner?.address?.state ||
                examiner?.address?.zipCode
              "
              value
              [innerHTML]="examiner?.address | address"
            >
            </span>
          </app-value>

          <app-value
            *ngIf="examiner?.phone"
            label="Phone Number"
            value="{{ examiner?.phone }}"
            class="tw-w-[50%] md:tw-w-[15%]"
            layout="col"
            icon="phone"
          ></app-value>

          <app-value
            *ngIf="examiner?.email"
            label="Email"
            value="{{ examiner?.email }}"
            class="tw-flex tw-flex-wrap tw-w-full sm:tw-w-[50%] md:tw-w-[25%]"
            layout="col"
            icon="mail_outline"
          ></app-value>

          <app-value
            *ngIf="examiner?.fax"
            label="Fax Number"
            value="{{ examiner?.fax }}"
            class="tw-w-[50%] md:tw-w-[15%]"
            layout="col"
            icon="fax"
          ></app-value>
        </div>
        <div class="app-pb3 tw-w-full">
          <div class="app-pb3 tw-w-full">
            <div style="max-width: 430px">
              <app-action-link
                title="Contact {{ examinerLabel }}"
                helpText="Contact your {{
                  examinerLabel
                }} through Communication Center"
                (linkClick)="messageBus.publish('comm-center-messages:focus')"
                clickableicon="mail_outline"
              ></app-action-link>
            </div>
          </div>
          <!--  -->
          <div *ngIf="claimConfigService.getFeatureAccess('addressbook')">
            <app-list
              *ngIf="contactsDB?.length > 0"
              [dataSource]="contactsDB"
              [template]="row"
              class="app-pt2"
              [filterEnabled]="false"
              [tableAriaLabel]="'Contact list'"
            >
              <ng-container header>
                <tr class="tw-flex tw-flex-wrap tw-w-full">
                  <app-list-header
                    name="name"
                    label="Name"
                    class="tw-w-full md:tw-w-[30%]"
                    [sortEnabled]="true"
                  ></app-list-header>
                  <app-list-header
                    name="fullAddress"
                    label="Address"
                    class="tw-w-full md:tw-w-[30%]"
                    [sortEnabled]="true"
                  ></app-list-header>
                  <app-list-header
                    name="contact"
                    label="Contact"
                    readOnly
                    class="tw-w-full md:tw-w-[30%]"
                  ></app-list-header>
                </tr>
              </ng-container>
            </app-list>
            <ng-template #row let-element>
              <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                <td class="tw-w-full md:tw-w-[30%]">
                  <app-value label="Name" layout="row-xs">
                    <span value>
                      {{ element.name }}
                      <div class="type">{{ element.type }}</div>
                    </span>
                  </app-value>
                </td>
                <td class="tw-w-full md:tw-w-[30%]">
                  <app-value label="Address" layout="row-xs">
                    <span
                      *ngIf="
                        element?.address?.street1 ||
                        element?.address?.street2 ||
                        element?.address?.city ||
                        element?.address?.state ||
                        element?.address?.zipCode
                      "
                      value
                      [innerHTML]="element.address | address"
                    >
                    </span>
                  </app-value>
                </td>
                <td class="tw-w-full md:tw-w-[30%]">
                  <app-value label="" layout="col">
                    <span value>
                      <div class="app-pb1 app-pt1">
                        <div *ngIf="element.phone" class="contact">
                          <mat-icon class="icon">phone</mat-icon
                          >{{ element.phone }}
                          <span *ngIf="element.ext" class="contact"
                            >Ext {{ element.ext }}</span
                          >
                        </div>
                        <div *ngIf="element.email" class="contact">
                          <mat-icon class="icon">email</mat-icon
                          >{{ element.email }}
                        </div>
                        <div *ngIf="element.fax" class="contact">
                          <mat-icon class="icon">fax</mat-icon>{{ element.fax }}
                        </div>
                      </div>
                    </span>
                  </app-value>
                </td>
              </tr>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .type {
        font-size: 12px;
      }

      .contact {
        height: 24px;
        line-height: 24px;
      }

      .icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
        color: var(--color-input-grey);
        vertical-align: middle;
        margin-right: 8px;
      }
    `,
  ],
})
export class ContactListComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  clientConfig: ConfigurationSetting;

  examinerLabel: string;

  examiner: Contact;

  contactsDB: Contact[];

  examinerAddress: string;

  examinerName: string;

  loaded = false;

  constructor(
    public claimConfigService: ConfigurationService,
    public dialog: MatDialog,
    public inpageAliasService: InpageAliasService,
    public messageBus: MessageBus
  ) {}
  async ngOnInit() {
    if (this.claim.claimId) {
      const list = await this.claimConfigService.GetImportContacts(this.claim);
      if (list != undefined && list?.listExaminer) {
        this.examiner = list?.listExaminer[0];
      }

      this.examinerName = this.examiner.formattedName;
      const LOB = ['WC', 'LV', 'DS'];

      if (
        list != undefined &&
        list?.listAddressBook &&
        !LOB.includes(this.claim?.lob)
      ) {
        this.contactsDB = list?.listAddressBook;
      }

      this.examinerLabel = await this.inpageAliasService.replaceAliases(
        'examinerSingular',
        this.claimConfigService.GetConfig.configFeatures.aliases
      );
    }

    this.loaded = true;
  }
}
