import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-header',
  template: `
    <div class="flex-row modal-header">
      <button
        type="button"
        style="float:right"
        cdkfocusinitial
        mat-button
        [mat-dialog-close]="true"
        (keydown)="PopupCloseTabOut($event)"
      >
        X
      </button>
      <h2 mat-dialog-title md-color="red" class="popupheading">
        {{ title }}
      </h2>
    </div>
  `,
  styles: [
    `
      .popupheading {
        font: 400 15px/24px Roboto, 'Helvetica Neue', sans-serif !important;
        letter-spacing: normal !important;
        margin: 0 0 16px !important;

        font-size: 20px !important;
        padding-left: 30px !important;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        padding-bottom: 30px !important;
        border-bottom: 1px solid #eee !important;
      }
    `,
  ],
})
export class PopupHeaderComponent implements OnInit {
  @Input() headerTitle: string;
  @Output() CloseTabOut: EventEmitter<any> = new EventEmitter();
  title: string;
  constructor() {}

  ngOnInit(): void {
    this.title = this.headerTitle;
  }

  PopupCloseTabOut(event: any): boolean {
    if (event.keyCode == 9) {
      this.CloseTabOut.emit(true);
      return false;
    }

    return true;
  }
}
