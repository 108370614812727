import { Component, Input, OnInit } from '@angular/core';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-toggle-control',
  template: `
    <div class="app-pr1 app-pb1" [formGroup]="formGroup">
      <mat-slide-toggle [formControlName]="formControlName" autocomplete="off"
        ><span [ngClass]="_labelClass"
          >{{ label }} {{ isRequired ? '*' : '' }}</span
        ></mat-slide-toggle
      >
      <div *ngIf="hintLabel" class="checkbox-hint">{{ hintLabel }}</div>
      <div>
        <mat-error
          class="checkbox-error"
          *ngIf="formControl.invalid && formControl.touched"
          >{{ errorMessage }}</mat-error
        >
      </div>
    </div>
  `,
  styles: [
    `
      .checkbox-hint {
        font-size: 12px;
        margin-left: 44px;
      }
      .checkbox-error {
        margin-left: 44px;
      }
      .label {
        padding-left: 8px;
      }
    `,
  ],
})
export class ToggleControlComponent
  extends BaseControlComponent
  implements OnInit
{
  @Input()
  labelClass: string;

  _labelClass: string;

  ngOnInit(): void {
    this._labelClass = `label ${this.labelClass}`;
  }
}
