import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationItem, scrollToElement } from 'src/app/shared';
import { ActivityRequest } from 'src/app/shared/models/activity';
import { UserActions } from 'src/app/shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-notifications-header',
  template: `
    <div
      class="tw-w-full"
      *ngIf="this.welcomeMessageInfo && this.welcomeMessageInfo.length > 0"
    >
      <app-welcome [welcomeMessageInfo]="welcomeMessageInfo"></app-welcome>
    </div>
    <div class="tw-flex-grow">
      <div class="tw-item-center tw-flex"></div>
    </div>
  `,
  styles: [
    `
      .message-container {
        max-width: 300px;
        font-size: 13px;
      }
      .help-icon {
        margin-left: 4px;
        color: var(--color-input-grey);
        cursor: pointer;
        vertical-align: middle;
      }
      .align-right {
        text-align: right;
        margin-right: 10px;
      }
    `,
  ],
})
export class NotificationsHeaderComponent implements OnInit {
  @Output()
  focusTab = new EventEmitter<string>();

  welcomeMessageInfo: any;
  countLoaded: boolean = true;
  notificationCount: number = 0;
  maxBadgeCount = 99;
  unreadConversationMessagesCount: number = 0;
  activityRequest: ActivityRequest;
  initialNotifications: NotificationItem[] = [];
  mgrAction: UserActions | null;
  loaded: boolean = false;
  xSmall$: Observable<boolean>;

  constructor(
    public session: Session,
    private activityService: ActivityService,
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  async ngOnInit() {
    this.welcomeMessageInfo = await this.session.GetWelcomeMessage();
  }

  setCommCenterFocus() {
    const commcenter = document.getElementById('commCenterPnl');
    if (commcenter != null) {
      commcenter.scrollIntoView();
    }
  }

  setNotificationFocus(type: any) {
    const notification = document.getElementById('activitypnl');
    if (notification != null) {
      this.activityService.setFocusToNotification(type);
      scrollToElement('activitypnl', false, 65);
    }
  }

  unreadMessagesWithMax = (): number => {
    return this.unreadConversationMessagesCount > 99
      ? this.maxBadgeCount
      : this.unreadConversationMessagesCount;
  };

  handleFocus(tabName: string) {
    this.focusTab.emit(tabName);
  }
}
