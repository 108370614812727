import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClaimantService, IncidentalAbsence } from '../..';
import { ActivityService } from '../../services/activity.service';

@Component({
  selector: 'app-absence-delete-dialog',
  template: `
    <app-dialog
      title="Confirmation"
      ariaLabel="Confirmation dialog"
      [showActions]="false"
      [showHeader]="false"
      ariaLabelMessage="Cancel"
      cancelLabel="Cancel"
    >
      <app-dialog-container
        label="Remove Absence"
        class="container"
        [showHeader]="true"
        [headerLevel]="2"
      >
        Please confirm to remove this absence.
        <div class="tw-w-full app-pt4">
          <app-action-row class="tw-w-full">
            <app-button right2 autofocus emphasis="High" (click)="onSubmit()"
              >Submit</app-button
            >
            <app-button
              right1
              id="btnCancel"
              (click)="this.dialog.closeAll()"
              emphasis="Low"
              >Cancel</app-button
            >
          </app-action-row>
        </div>
      </app-dialog-container>
    </app-dialog>
  `,
  styles: [],
})
export class AbsenceDeleteDialogComponent {
  @Input()
  dialogRef: MatDialogRef<any>;

  @Input()
  absence: IncidentalAbsence;

  constructor(
    public dialog: MatDialog,
    private claimantService: ClaimantService,
    private activityService: ActivityService
  ) {}

  async onSubmit() {
    await this.claimantService.DeleteAbsence(this.absence.absenceUid);
    this.activityService.alertSelected.emit(this.absence.absenceUid);
    this.dialog.closeAll();
  }
}
