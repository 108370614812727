import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserActionService, getUrlParams, join } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { ClaimService, ClaimantService } from '..';
import { SentenceCasePipe } from '../../shared';
import { EncryptionService } from './encryption.service';
import { Session } from './session.service';
import { UserRoleService } from './user-role.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _items = new BehaviorSubject<BreadcrumbItem[]>([]);
  items = this._items.asObservable();
  tempItems: any;
  claimant: any;
  mgrAction: UserActions | null;

  private rootPath: string;

  private rootLabel: string;

  constructor(
    private encrypt: EncryptionService,
    private userActions: UserActionService,
    private titleCasePipe: TitleCasePipe,
    private userRole: UserRoleService,
    private sentencecase: SentenceCasePipe,
    private session: Session,
    public claimService: ClaimService,
    private claimantService: ClaimantService
  ) {}

  public async build() {
    this.restoreRoot();
    const path = document.location.pathname;
    const title = document.title.replace(' - mySedgwick', '');
    const params = getUrlParams();
    // the default strategy for a page is to create a root element
    const pathLower = path?.toLowerCase();

    try {
      switch (pathLower) {
        case '/claims':
        case '/return-to-work':
        case '/e-sign':
        case '/comm-center':
        case '/account-settings':
        case '/managedirectdeposit':
        case '/mileage-reimbursement':
        case '/pharmacy-card':
        case '/confirm-return-to-work':
        case '/help':
        case '/leavebalancesummary':
        case '/reportanabsence':
        case '/upload-documents-in-documentstab':
        case '/mileage-reimbursement-edit':
        case '/mileage-reimbursement-review':
        case '/my-work-schedule':
        case '/accommodations-pending':
          await this.buildClaim(title, path);
          break;
        case pathLower.startsWith('/summary/') ? pathLower : '':
          this.buildSummary(title, path);
          break;
        case '/preferencespage':
          if (params.claimId) {
            this.buildClaim(title, path);
          } else {
            this.buildRoot(title, path);
          }
          break;
        case '/dashboard':
          {
            if (
              this.userRole.employee ||
              (this.userRole.manager && !params.emp)
            ) {
              this.buildRoot(title, path);
            } else if (this.userRole.manager && params.emp) {
              this.buildEmployee();
            }
          }
          break;
        default:
          this.buildRoot(title, path);
          break;
      }
    } catch (error) {
      this.setGenericError(error);
    }
  }

  private removeDuplicates(items: BreadcrumbItem[]): BreadcrumbItem[] {
    const uniqueItems: BreadcrumbItem[] = [];

    items.forEach((item) => {
      if (
        !uniqueItems.find(
          (uniqueItem) =>
            uniqueItem.label?.toLowerCase() === item.label?.toLowerCase() &&
            uniqueItem.path === item.path
        )
      ) {
        uniqueItems.push(item);
      }
    });

    return uniqueItems;
  }

  private setItems(items: BreadcrumbItem[]) {
    const uniqueItems = this.removeDuplicates(items);
    this._items.next(uniqueItems);

    // save the breadcrumb to session storge so we can restore it after a page refresh
    sessionStorage.setItem(
      'breadcrumb' + this.session.user.sessionId,
      JSON.stringify(uniqueItems)
    );
  }

  private restoreRoot(): void {
    // root is null, this was in initial load or page refresh
    if (!this.rootPath && !this.rootLabel) {
      // if we have a breadcrumn in storage, apply it
      const breadcrumbData = sessionStorage.getItem(
        'breadcrumb' + this.session.user.sessionId
      );
      if (breadcrumbData) {
        const items: BreadcrumbItem[] = JSON.parse(breadcrumbData);
        this.rootPath = items[0].path;
        this.rootLabel = items[0].label;
      }
    }
  }

  private buildRoot(label: string, path: string): void {
    this.rootLabel = label;
    this.rootPath = path;

    this.setItems([
      {
        label,
        path,
        ariaLabel: this.rootLabel,
      },
    ]);
  }

  private buildSummary(label: string, path: string): void {
    this.setItems([
      {
        label: 'Summary view',
        path: '/summary',
        ariaLabel: 'Redirects to Summary view',
      },
      {
        label: label,
        path: path,
        ariaLabel: 'Redirects to Summary view',
      },
    ]);
  }

  private async buildClaim(title: string, path: string) {
    const params = getUrlParams();
    const commCenterPath = '/communication-center';
    const claimId = params.claimId;
    const source = params.source;
    this.mgrAction = this.userActions.getAction();
    const items: BreadcrumbItem[] = [];
    if (
      (this.userRole.manager &&
        ((this.mgrAction !== UserActions.ViewOwnClaim &&
          this.mgrAction !== UserActions.ViewOwnEmpDb) ||
          params.emp)) ||
      this.userRole.controller ||
      path === commCenterPath
    ) {
      items.push({
        label: this.rootLabel,
        path: this.rootPath,
        ariaLabel: 'Redirect to' + this.rootLabel,
      });
    }

    if ((claimId && source) || path === commCenterPath) {
      this.claimant = await this.getClaimant();
      // if root is my-claims, then do not show employee segment
      if (
        this.claimant &&
        this.rootPath !== '/my-claims' &&
        !this.claimant.userOwns &&
        !this.userRole.controller
      ) {
        const empID = this.claimant?.empId
          ? this.claimant.empId
          : this.claimant.employeeID
          ? this.claimant.employeeID
          : this.claimant.employeeId; //as multiple objects are being used as claimant need to check each property

        items.push({
          label: this.titleCasePipe.transform(
            join([this.claimant.name, empID])
          ),
          path: '/dashboard',
          queryParams: {
            emp: this.encrypt.encrypt(empID),
          },
          ariaLabel: 'Redirect to Employee Dashboard for ' + this.claimant.name,
        });
      }
      if (
        (this.claimant &&
          path !== '/my-claims' &&
          this.claimant.userOwns &&
          !this.userRole.controller) ||
        (path === commCenterPath && this.rootPath === commCenterPath)
      ) {
        items.push({
          label: this.sentencecase.transform('My claims'),
          path: '/dashboard',
          ariaLabel: 'Redirect to Employee dashboard',
        });
      }

      if (
        this.claimant &&
        this.claimant.claimDescription &&
        this.claimant.claimNumber &&
        claimId &&
        source
      ) {
        items.push({
          label: join([
            this.titleCasePipe.transform(this.claimant?.claimDescription),
            this.claimant?.claimNumber,
          ]),
          path: '/claims',
          queryParams: {
            claimId,
            source,
          },
          ariaLabel: 'Redirect to claim ' + this.claimant?.claimNumber,
        });
      }

      // add another segment for claim child pages such as return-to-work
      if (path !== '/claims' && path !== '/upload-documents-in-documentstab') {
        items.push({
          label: path === '/e-sign' ? params.name : title,
          path,
          queryParams: params,
          ariaLabel: 'Redirect to Esign page',
        });
      }

      this.setItems(items);
    } else {
      // These routes can be a root page or accessed from
      // a claim
      if (
        path === '/account-settings' ||
        path === '/help' ||
        path === '/my-work-schedule'
      ) {
        this.buildRoot(title, path);
      }
      if (path === '/communication-center') {
        items.push({
          label: title,
          path,
          queryParams: params,
          ariaLabel: 'Redirect to Communication Center',
        });

        this.setItems(items);
      }
    }
  }

  private async buildEmployee() {
    this.claimant = await this.getClaimant();
    const params = getUrlParams();
    this.mgrAction = this.userActions.getAction();
    this.tempItems = [];

    this.tempItems.push({
      label: this.rootLabel,
      path: this.rootPath,
    });

    const employeeId = await this.claimantService.getEmpId();
    if (employeeId && this.claimant && this.claimant.name) {
      this.tempItems.push({
        label: this.titleCasePipe.transform(
          join([this.claimant.name, employeeId])
        ),
        path: '/dashboard',
        queryParams: { emp: params.emp },
        ariaLabel: 'Redirect to employee dashboard',
      });
    }

    this.setItems(this.tempItems);
  }

  private buildSupervsior(title: string, path: string): void {
    this.buildRoot(title, path);
  }

  private setGenericError(error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    this.setItems([
      {
        label: 'Breadcrumb Error',
        path: null,
        ariaLabel: 'error',
      },
    ]);
  }

  private async getClaimant() {
    let claimant = null;
    const params = getUrlParams();
    const claimId = params.claimId;
    const source = params.source;
    const lob = params.lob;
    if (source && claimId) {
      claimant = await this.claimService.getSummary(
        source,
        claimId,
        false,
        false,
        lob
      );
    } else {
      claimant = this.userActions.getClaimant();
    }
    return claimant;
  }
}

export class BreadcrumbItem {
  label: string;
  path: string;
  queryParams?: any;
  ariaLabel: string;
}
