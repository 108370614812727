/**
 * saves a blob to a file
 */
export function blobToFile(
  content: Blob,
  contentType: string,
  fileName: string
): void {
  const blob = new Blob([content], { type: contentType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}
