import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { HelpItem } from '../models/HelpItem';

@Injectable({
  providedIn: 'root',
})
export class HelpfulLinkService {
  constructor(private http1: Http) {}

  async getHelpfulLinks(lcType: string, excludeContent: boolean = false) {
    return this.http1.get<HelpItem[]>(`/api/user/${lcType}/GetHelpfulLinks`, {
      queryParams: {
        excludeContent,
      },
      delay: excludeContent ? 1000 : 0,
    });
  }
}
