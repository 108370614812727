import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSince',
})
export class TimeSincePipe implements PipeTransform {
  transform(date: Date, ...args: unknown[]): unknown {
    if (!date) {
      return null;
    }

    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
      return this.format(interval, 'year');
    }

    interval = seconds / 2592000;
    if (interval > 1) {
      return this.format(interval, 'month');
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return this.format(interval, 'day');
    }

    interval = seconds / 3600;
    if (interval > 1) {
      return this.format(interval, 'hour');
    }

    interval = seconds / 60;
    if (interval > 1) {
      return this.format(interval, 'minute');
    }

    return this.format(seconds, 'second');
  }

  private format(interval: number, unit: string): string {
    let floor = Math.floor(interval);
    if (floor < 1) {
      floor = 1;
    }

    const plural = floor > 1 ? 's' : '';
    return `${floor} ${unit}${plural} ago`;
  }
}
