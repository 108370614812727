export class IncidentalAbsenceSearch {
  employeeId: string = null;
  absenceNumber: string = null;
  firstName: string = null;
  lastName: string = null;
  startDate: Date = null;
  endDate: Date = null;
  reason: string = null;
  includeIndirectReports: boolean = true;
  empUnum: string = null;
  //includeIndirects: boolean;
}
