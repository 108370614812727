import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { Router } from '@angular/router';

type EventMapping = {
  selector: string;
  action: (event: MouseEvent | KeyboardEvent, params?: any) => void;
  data?: any;
  params?: any;
  route?: string;
  queryParams?: { [key: string]: any };
};

@Injectable({
  providedIn: 'root',
})
export class DynamicEventBindService {
  private renderer: Renderer2;
  constructor(
    private router: Router,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  bindDynamicEvents(container: ElementRef, eventMappings: EventMapping[]) {
    let unlistens: (() => void)[] = [];
    if (container) {
      for (let mapping of eventMappings) {
        const elements = container.nativeElement.querySelectorAll(
          mapping.selector
        );
        elements.forEach((element: HTMLElement) => {
          if (mapping.route) {
            const hrefValue = this.router
              .createUrlTree([mapping.route], {
                queryParams: mapping.queryParams,
              })
              .toString();
            this.renderer.setAttribute(element, 'href', hrefValue);
          }
          const clickUnlisten = this.renderer.listen(
            element,
            'click',
            (event) => {
              this.handleEvent(event, mapping);
            }
          );
          const keypressUnlisten = this.renderer.listen(
            element,
            'keypress',
            (event) => {
              if ((event as KeyboardEvent).key === 'Enter') {
                this.handleEvent(event, mapping);
              }
            }
          );
          unlistens.push(clickUnlisten, keypressUnlisten);
        });
      }
    }
    return unlistens;
  }

  private handleEvent(
    event: MouseEvent | KeyboardEvent,
    mapping: EventMapping
  ) {
    event.stopPropagation();
    if (mapping.route) {
      this.router.navigate([mapping.route], {
        queryParams: mapping.queryParams,
      });
    } else if (mapping.action) {
      mapping.action(event, mapping);
    }
  }
}
