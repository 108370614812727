import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-page',
  template: `
    <app-section title="Claims"
      ><app-activity [showTitle]="false" class="tw-w-full"></app-activity
    ></app-section>
  `,
  styles: [],
})
export class ActivityPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
