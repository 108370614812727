import { Pipe, PipeTransform } from '@angular/core';
import { isInteger } from 'lodash';

@Pipe({
  name: 'numberToWord',
})
export class NumberToWordPipe implements PipeTransform {
  private onesAndTeens: string[] = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  private tens: string[] = [
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  transform(value: number): string {
    if(value && isInteger(value) && Number(value) >= 0) {
      return this.convertToWord(Number(value), true);
    }
    return value.toString();
  }

  convertToWord(value: number, includeZero: boolean): any {
    if(value === 0){
      return includeZero ? this.onesAndTeens[value] : '';
    }
    if(value < 20) {
      return this.onesAndTeens[value];
    }
    if(value < 100) {
      return this.tens[Math.trunc(value / 10 - 2)] + ' ' + this.convertToWord(value % 10, false);
    }
    if(value < 1000) {
      return this.convertToWord(Math.trunc(value / 100), false) + ' hundred ' + this.convertToWord(value % 100, false);
    }
    if(value < 1000000) {
      return this.convertToWord(Math.trunc(value / 1000), false) + ' thousand ' + this.convertToWord(value % 1000, false);
    }

    return value;
  }
}
