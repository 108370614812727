import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  BaseComponent,
  ConfigurationSetting,
  ListComponent,
  RouterService,
  SearchResult,
  SelectOption,
  States,
} from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import {
  MileageDetail,
  MileageTravelExpense,
  UserMileageReimbursementDetail,
} from '../../models/Mileages';
import { HistoryInfo } from '../../models/historyinfo';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MileageService } from '../../services/mileage.service';
import { Session } from '../../services/session.service';
import { UserDetails } from '../../services/userdetails.service';
import { MileageReimbursementExpenseComponent } from './mileage-expense-dialog.component';

@Component({
  selector: 'app-mileage-details',
  template: `
    <div class="app-pt3 tw-w-full tw-flex tw-flex-wrap">
      <form [formGroup]="formGroup" class="tw-flex tw-flex-wrap tw-w-full">
        <div class="app-header-2">Please enter travel information</div>
        <br />
        <div class="tw-flex tw-flex-wrap tw-w-full">
          <app-date-control
            formControlName="travelDate"
            [formGroup]="formGroup"
            label="Date of service"
            ngDefaultControl
            [validationControl]="'Date of service'"
            class="tw-w-full md:tw-w-[25%]"
            [errormsgClass]="'dateError'"
          ></app-date-control>
          <app-select-control
            formControlName="travelType"
            (ngModelChange)="travelTypeChanged()"
            [formGroup]="formGroup"
            label="How did you get there?"
            [options]="travelTypeOptions"
            [multiple]="false"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-select-control>
          <app-select-control
            formControlName="travelReason"
            [formGroup]="formGroup"
            label="Reason for travel?"
            [options]="travelReasonOptions"
            [multiple]="false"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-select-control>
          <app-text-control
            formControlName="travelDest"
            [formGroup]="formGroup"
            label="Where did you go?"
            [validationControl]="'Where did you go'"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-text-control>
          <div class="app-header-3">Starting address</div>
          <br />
          <div class="tw-flex tw-flex-wrap tw-w-full">
            <app-text-control
              formControlName="startingAddress1"
              [formGroup]="formGroup"
              label="Address line 1"
              [validationControl]="'Address line 1'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[50%]"
            ></app-text-control>
            <app-text-control
              formControlName="startingAddress2"
              [formGroup]="formGroup"
              label="Address line 2"
              [validationControl]="'Where did you go?'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[50%]"
            ></app-text-control>
            <app-text-control
              formControlName="startingCity"
              [formGroup]="formGroup"
              label="City"
              [validationControl]="'City'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[45%]"
            ></app-text-control>
            <app-select-control
              formControlName="startingState"
              [formGroup]="formGroup"
              label="State"
              [options]="states"
              ngDefaultControl
              class="tw-w-full md:tw-w-[35%]"
              [(ngModel)]="startingState"
              (keypress)="onTouched()"
              (click)="onTouched()"
            ></app-select-control>
            <app-text-control
              formControlName="startingZip"
              [formGroup]="formGroup"
              label="Zip code"
              [validationControl]="'Zip code'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[20%]"
            ></app-text-control>
          </div>
          <div class="tw-w-full">
            <div class=" limittoastwidth">
              <div *ngIf="invalidZipStart">
                <app-toast *ngIf="invalidZipStart" type="Warning">
                  Error : Please enter a valid State / Zip Code combination
                </app-toast>
                <br />
              </div>
              <div *ngIf="invalidZipStart">
                <app-toast
                  *ngIf="invalidCityStart"
                  type="Warning"
                  class="limittoastwidth"
                >
                  The City could not be verified. Please verify the City you
                  entered. Click Next to continue.
                </app-toast>
                <br />
              </div>
            </div>
          </div>
          <br />
          <div class="app-header-3">Ending address</div>
          <br />
          <div class="tw-flex tw-flex-wrap tw-w-full">
            <app-text-control
              formControlName="endingAddress1"
              [formGroup]="formGroup"
              label="Address line 1"
              [validationControl]="'Address line 1'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[50%]"
            ></app-text-control>
            <app-text-control
              formControlName="endingAddress2"
              [formGroup]="formGroup"
              label="Address line 2"
              [validationControl]="'Where did you go?'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[50%]"
            ></app-text-control>
            <app-text-control
              formControlName="endingCity"
              [formGroup]="formGroup"
              label="City"
              [validationControl]="'City'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[45%]"
            ></app-text-control>
            <app-select-control
              formControlName="endingState"
              [formGroup]="formGroup"
              label="State"
              [options]="states"
              ngDefaultControl
              class="tw-w-full md:tw-w-[35%]"
              (keypress)="onTouched()"
              (click)="onTouched()"
            ></app-select-control>
            <app-text-control
              formControlName="endingZip"
              [formGroup]="formGroup"
              label="Zip code"
              [validationControl]="'Zip code'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[20%]"
            ></app-text-control>
          </div>
          <div
            *ngIf="
              formGroup.controls['travelType'].value === travelTypeRequired
            "
          >
            <div class="app-header-3">Travel distance</div>
            <br />
            <div class="tw-flex tw-flex-wrap tw-w-full">
              <app-select-control
                formControlName="travelDistanceType"
                [formGroup]="formGroup"
                label="Round trip?"
                [options]="travelDistanceTypeOptions"
                [multiple]="false"
                ngDefaultControl
                class="tw-w-full md:tw-w-[50%]"
              ></app-select-control>
              <app-text-control
                formControlName="travelDistance"
                [formGroup]="formGroup"
                label="Total miles"
                [validationControl]="'Total miles'"
                ngDefaultControl
                class="tw-w-full md:tw-w-[50%]"
              ></app-text-control>
            </div>
          </div>
          <br />
          <br />
          <div class="tw-w-full">
            <div class=" limittoastwidth">
              <app-toast
                *ngIf="invalidZipEnd"
                type="Warning"
                class="limittoastwidth"
              >
                Error : Please enter a valid State / Zip Code combination
              </app-toast>
              <app-toast
                *ngIf="invalidCityEnd"
                type="Warning"
                class="limittoastwidth"
              >
                The City could not be verified. Please verify the City you
                entered. Click Next to continue.
              </app-toast>
            </div>
          </div>
          <div class="app-header-2">Please enter travel expenses</div>
          <div class="tw-flex tw-flex-wrap tw-w-full">
            <button
              mat-button
              alt="Add expense"
              emphasis="High"
              (click)="addExpense()"
              class="btn-info roundbtn reportNewClmBtn pdl15 pdr15"
              mat-raised-button
              tabindex="0"
            >
              Add expense
            </button>
          </div>
          <div
            class="tw-w-full"
            *ngIf="reimbursementDetailData.Expenses.length ?? 0 > 0"
          >
            <app-list
              #expensesList
              [dataSource]="reimbursementDetailData.Expenses"
              [template]="row"
              [paginatorEnabled]="false"
              loadingMessage=""
              noDataIcon="attachment"
              [loaded]="true"
              noDataMessage="<br><strong>No documents available for this claim.</strong>"
              [tableAriaLabel]="'Expenses'"
            >
              <ng-container header>
                <tr class="tw-flex tw-flex-wrap tw-w-full">
                  <app-list-header
                    name="expenseType"
                    label="Expense Type"
                    class="tw-w-[50%]"
                  ></app-list-header>
                  <app-list-header
                    name="amountPaid"
                    label="Amount Paid"
                    class="tw-w-[25%]"
                  ></app-list-header>
                  <app-list-header
                    name="actions"
                    label="Actions"
                    class="tw-w-[25%]"
                  ></app-list-header>
                </tr>
              </ng-container>
            </app-list>

            <ng-template #row let-element let-index="index">
              <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                <td class="tw-w-full md:tw-w-[50%]">
                  <app-value
                    label="Expense Type"
                    layout="row-xs"
                    [value]="element.expenseType"
                    [tabindex]="0"
                  >
                  </app-value>
                </td>
                <td class="tw-w-full md:tw-w-[25%]">
                  <app-value
                    label="Amount Paid"
                    layout="row-xs"
                    [value]="element.amountPaid | format : 'currency'"
                    [tabindex]="0"
                  >
                  </app-value>
                </td>
                <td class="ExpenseActions" class="tw-w-full md:tw-w-[25%]">
                  <app-value label="Actions" layout="row-xs">
                    <app-action-row value class="tw-w-full">
                      <app-icon-button
                        class="tw-hidden md:tw-block"
                        icon="delete_outline"
                        label="Delete"
                        right1
                        tabindex="0"
                        role="button"
                        [attr.aria-label]="'Delete Expense for trip'"
                        (click)="deleteExpense(index)"
                        (keydown.enter)="deleteExpense(index)"
                      ></app-icon-button>
                      <app-button
                        class="md:tw-hidden tw-block"
                        label="Delete"
                        right1
                        (click)="deleteExpense(index)"
                        (keydown.enter)="deleteExpense(index)"
                        >Remove</app-button
                      >
                      <app-icon-button
                        class="tw-hidden md:tw-block"
                        icon="edit"
                        label="Edit"
                        tabindex="0"
                        role="button"
                        right2
                        [attr.aria-label]="'Edit Expense for trip'"
                        (click)="editExpense(index)"
                        (keydown.enter)="editExpense(index)"
                      ></app-icon-button>
                      <app-button
                        class="md:tw-hidden tw-block"
                        label="Edit"
                        right2
                        (click)="editExpense(index)"
                        (keydown.enter)="editExpense(index)"
                        >Edit</app-button
                      >
                    </app-action-row>
                  </app-value>
                </td>
                <div class="ExpenseActionsMobile">
                  <div class="tw-w-full">
                    <app-button
                      class="md:tw-hidden tw-block"
                      label="Edit"
                      (click)="editExpense(index)"
                      left1
                      >Edit</app-button
                    >
                  </div>
                  <div class="tw-w-full">
                    <app-button
                      class="md:tw-hidden tw-block"
                      label="Delete"
                      left1
                      (click)="deleteExpense(index)"
                      >Remove</app-button
                    >
                  </div>
                </div>
              </tr>
            </ng-template>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [
    `
      .ExpenseActionsMobile {
        display: block;
        flex-direction: row;
      }
      .ExpenseActions {
        display: none;
      }
      @media (min-width: 700px) {
        .ExpenseActions {
          display: block;
        }
        .ExpenseActionsMobile {
          display: none;
        }
      }
      .limittoastwidth {
        max-width: 700px;
      }
      .prevpage {
        color: #007dbc;
        font-weight: bold;
        font-size: 14pt;
      }
      .prevpage:hover {
        text-decoration: none;
        border-bottom: 1px solid #035e81;
      }
      .currentpage {
        color: #171c20 !important;
        opacity: 1 !important;
      }

      .content {
        overflow-y: scroll;
        background-color: #f0f4f7;
      }
      .pdt15 {
        padding-top: 15px;
      }
      .pdb15 {
        padding-bottom: 15px;
      }
      .app-header-3 {
        color: black;
        padding-bottom: 15px;
      }
      .app-header-2 {
        padding-bottom: 15px;
      }
      ::ng-deep .dateError {
        margin-bottom: 15px !important;
        color: #0000ff;
      }
    `,
  ],
})
export class MileageReimbursementDetailsComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  claim: SearchResult;

  @Input()
  mileageData: MileageDetail;

  @Input()
  reimbursementDetailData: UserMileageReimbursementDetail;

  @Input()
  startingState: string;

  @Output()
  onShowSummary: EventEmitter<any> = new EventEmitter();

  @ViewChild('expensesList', { static: false })
  expenseListComponent: ListComponent;

  expenseDialogRef: MatDialogRef<MileageReimbursementExpenseComponent>;
  travelTypeRequired: string = 'Personal';

  maxMilesLimit: number = 999.9;
  formGroup = this.fb.group({
    travelDate: [
      '',
      [
        Validators.required,
        customvalidations.dateInFuture(
          'The date of service cannot be a future date'
        ),
      ],
    ],
    travelType: ['', [Validators.required]],
    travelReason: ['', [Validators.required]],
    travelDest: ['', [Validators.required]],
    startingAddress1: ['', [Validators.required]],
    startingAddress2: [''],
    startingCity: ['', [Validators.required]],
    startingState: ['', [Validators.required]],
    startingZip: [
      '',
      [
        Validators.required,
        customvalidations.IsNumeric(),
        customvalidations.validZipCode,
      ],
    ],
    endingAddress1: ['', [Validators.required]],
    endingAddress2: [''],
    endingCity: ['', [Validators.required]],
    endingState: ['', [Validators.required]],
    endingZip: [
      '',
      [
        Validators.required,
        customvalidations.IsNumeric(),
        customvalidations.validZipCode,
      ],
    ],
    travelDistanceType: ['', [Validators.required]],
    travelDistance: [
      '',
      [
        Validators.required,
        customvalidations.IsDecimal(),
        customvalidations.numberLessThan(
          this.maxMilesLimit,
          'Please enter valid miles less than or equal to ' + this.maxMilesLimit
        ),
      ],
    ],
  });
  startAddr1: string;
  startAddr2: string;
  startCity: string;
  startState: string;
  startZip: string;
  travelTypeOptions: SelectOption[] = [];
  travelReasonOptions: SelectOption[] = [];
  travelDistanceTypeOptions: SelectOption[] = [
    {
      value: 'YES',
      label: 'Yes',
    },
    {
      value: 'NO',
      label: 'No',
    },
  ];
  states: SelectOption[] = States.options;
  claimId: string = '';

  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);

  claimConfig: ConfigurationSetting;
  expenses: MileageTravelExpense[];

  hasReturnToWork: boolean;
  isClaimant: boolean = this.session.user.role === 'HRM' ? true : false;
  showContactPrefs: boolean = this.isClaimant ? true : false;

  expenseIndex: number = -1;
  invalidZipStart: boolean = false;
  invalidCityStart: boolean = false;
  invalidZipEnd: boolean = false;
  invalidCityEnd: boolean = false;

  hasUploadDocs: boolean;
  historyinfo: HistoryInfo = <HistoryInfo>{};

  searching: boolean = true;
  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    public claimService: ClaimService,
    public mileageService: MileageService,
    public session: Session,
    public routerService: RouterService,
    public userdetails: UserDetails,
    public alertService: ActivityService,
    public claimConfigService: ConfigurationService
  ) {
    super();
  }

  async ngOnInit() {
    this.searching = true;
    this.getTravelDistanceOptions();
    this.getTravelReasonOptions();
    this.getTravelTypeOptions();

    if (this.reimbursementDetailData) {
      console.log('SETTING REIMBIURSEMENT DATA', this.reimbursementDetailData);
      this.cdr.detectChanges();
      setTimeout(() => {
        this.formGroup.controls['startingAddress1'].setValue(
          this.reimbursementDetailData.startAddr1
        );
        this.formGroup.controls['startingAddress2'].setValue(
          this.reimbursementDetailData.startAddr2
        );
        this.formGroup.controls['startingCity'].setValue(
          this.reimbursementDetailData.startCity
        );
        this.formGroup.controls['startingState'].setValue(
          this.reimbursementDetailData.startState
        );
        this.formGroup.controls['startingZip'].setValue(
          this.reimbursementDetailData.startZip
        );
        this.formGroup.controls['endingAddress1'].setValue(
          this.reimbursementDetailData.endAddr1
        );
        this.formGroup.controls['endingAddress2'].setValue(
          this.reimbursementDetailData.endAddr2
        );
        this.formGroup.controls['endingCity'].setValue(
          this.reimbursementDetailData.endCity
        );
        this.formGroup.controls['endingState'].setValue(
          this.reimbursementDetailData.endState
        );
        this.formGroup.controls['endingZip'].setValue(
          this.reimbursementDetailData.endZip
        );
        if (
          this.reimbursementDetailData.travelType === this.travelTypeRequired
        ) {
          setTimeout(() => {
            this.formGroup.controls['travelDistance'].setValue(
              this.reimbursementDetailData.travelDistance.toString()
            );
            this.formGroup.controls['travelDistanceType'].setValue(
              this.reimbursementDetailData.travelDistanceType
            );
          }, 10);
        }
        if (
          this.mileageData.roundTrip !== undefined &&
          (this.mileageData.minMilesLimit !== undefined ||
            this.mileageData.maxMilesLimit !== undefined)
        ) {
          this.formGroup.controls['travelDistance'].addValidators([
            customvalidations.MileageReimbursementTotalMiles(
              this.formGroup.controls['travelDistanceType'],
              this.mileageData.minMilesLimit,
              this.mileageData.maxMilesLimit,
              this.mileageData.roundTrip
            ),
          ]);
        }
        this.formGroup.controls['travelType'].setValue(
          this.reimbursementDetailData.travelType
        );
        this.formGroup.controls['travelReason'].setValue(
          this.reimbursementDetailData.travelReason
        );
        this.formGroup.controls['travelDest'].setValue(
          this.reimbursementDetailData.travelDestination
        );
        this.cdr.reattach();
        this.cdr.detectChanges();
      }, 10);
    } else if (this.mileageData) {
      setTimeout(() => {
        this.formGroup.controls['startingAddress1'].setValue(
          this.mileageData.claimAddr1
        );
        this.formGroup.controls['startingAddress2'].setValue(
          this.mileageData.claimAddr2
        );
        this.formGroup.controls['startingCity'].setValue(
          this.mileageData.claimCity
        );
        this.formGroup.controls['startingState'].setValue(
          this.mileageData.claimState
        );
        this.formGroup.controls['startingZip'].setValue(
          this.mileageData.claimZip
        );
      }, 10);
    }
  }

  getTravelDistanceOptions(): SelectOption[] {
    if (!this.mileageData.travelDistanceTypes) {
      return [];
    }
    const opts = this.mileageData.travelDistanceTypes.map((r) => {
      return {
        label: r,
        value: r,
      } as SelectOption;
    });

    this.travelDistanceTypeOptions = opts;
    return opts;
  }

  getTravelReasonOptions(): SelectOption[] {
    if (!this.mileageData.travelReasons) {
      return [];
    }
    const opts = this.mileageData.travelReasons.map((r) => {
      return {
        label: r,
        value: r,
      } as SelectOption;
    });

    this.travelReasonOptions = opts;
    return opts;
  }

  getTravelTypeOptions(): SelectOption[] {
    if (!this.mileageData.travelTypes) {
      return [];
    }
    const opts = this.mileageData.travelTypes.map((r) => {
      return {
        label: r,
        value: r,
      } as SelectOption;
    });

    this.travelTypeOptions = opts;
    return opts;
  }
  onTouched(): void {
    //    this.removeSuccess = null;
  }

  @Output()
  populateReimbursementData(): any {
    const curCityInvalidStatusStart = this.invalidCityStart;
    const curZipInvalidStatusStart = this.invalidZipStart;
    const curCityInvalidStatusEnd = this.invalidCityEnd;
    const curZipInvalidStatusEnd = this.invalidZipEnd;
    // Need to remove validators on travelDistance and travelDistanceType if they aren't visible
    if (
      this.formGroup.controls['travelType'].value !== this.travelTypeRequired
    ) {
      this.formGroup.controls['travelDistance'].setValidators(null);
      this.formGroup.controls['travelDistance'].setErrors(null);
      this.formGroup.controls['travelDistanceType'].setValidators(null);
      this.formGroup.controls['travelDistanceType'].setErrors(null);
      this.formGroup.updateValueAndValidity();

      console.log('CLEARD VALIDATION!!!!');
    }
    if (this.invalidZipStart) {
      this.formGroup.controls['startingZip'].setErrors(null);

      this.invalidZipEnd = false;
    }
    if (this.invalidZipEnd) {
      this.formGroup.controls['endingZip'].setErrors(null);
    }
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      // || this.formGroup.errors != null) {
      console.log('FORM GROUP INVALID', this.formGroup);
      return false;
    }
    const address = {
      City: this.formGroup.controls['startingCity'].value,
      State: this.formGroup.controls['startingState'].value,
      Zip: this.formGroup.controls['startingZip'].value,
    };

    return this.mileageService.verifyCityStateZip(address).then((v: any) => {
      this.invalidZipStart = !(v.validZip === true);

      if (this.invalidZipStart) {
        this.formGroup.controls['startingZip'].setErrors({
          customMsg:
            'Error : Please enter a valid State / Zip Code combination',
        });
        //        this.invalidZipEnd = false;

        //      return false;
      }
      this.invalidCityStart = !(v.validCity === true);

      if (curCityInvalidStatusStart === false && this.invalidCityStart) {
        return false;
      }

      const endAddress = {
        City: this.formGroup.controls['endingCity'].value,
        State: this.formGroup.controls['endingState'].value,
        Zip: this.formGroup.controls['endingZip'].value,
      };

      return this.mileageService
        .verifyCityStateZip(endAddress)
        .then((ev: any) => {
          console.log('VERIFY END CITY', ev.validCity === undefined);
          this.invalidZipEnd = !(
            ev.validZip === true && ev.validZip !== undefined
          );

          if (this.invalidZipEnd) {
            this.formGroup.controls['endingZip'].setErrors({
              customMsg:
                'Error : Please enter a valid State / Zip Code combination',
            });
            return false;
          }

          this.invalidCityEnd = !(
            ev.validCity === true && ev.validCity !== undefined
          );

          if (curCityInvalidStatusEnd === false && this.invalidCityEnd) {
            return false;
          }
          this.formGroup.markAllAsTouched();
          if (!this.formGroup.valid && this.formGroup.errors) {
            return false;
          }
          console.log(
            'setting data for reimbursement',
            this.reimbursementDetailData
          );
          this.reimbursementDetailData.startAddr1 =
            this.formGroup.controls['startingAddress1'].value;
          this.reimbursementDetailData.startAddr2 =
            this.formGroup.controls['startingAddress2'].value;
          this.reimbursementDetailData.startCity =
            this.formGroup.controls['startingCity'].value;
          this.reimbursementDetailData.startState =
            this.formGroup.controls['startingState'].value;
          this.reimbursementDetailData.startZip =
            this.formGroup.controls['startingZip'].value;
          this.reimbursementDetailData.endAddr1 =
            this.formGroup.controls['endingAddress1'].value;
          this.reimbursementDetailData.endAddr2 =
            this.formGroup.controls['endingAddress2'].value;
          this.reimbursementDetailData.endCity =
            this.formGroup.controls['endingCity'].value;
          this.reimbursementDetailData.endState =
            this.formGroup.controls['endingState'].value;
          this.reimbursementDetailData.endZip =
            this.formGroup.controls['endingZip'].value;
          this.reimbursementDetailData.travelType =
            this.formGroup.controls['travelType'].value;

          this.reimbursementDetailData.travelReason =
            this.formGroup.controls['travelReason'].value;
          this.reimbursementDetailData.travelDistanceType =
            this.formGroup.controls['travelDistanceType'].value;
          this.reimbursementDetailData.travelDistance = Number(
            this.formGroup.controls['travelDistance'].value
          );
          this.reimbursementDetailData.travelDate = new Date(
            this.formGroup.controls['travelDate'].value
          );
          this.reimbursementDetailData.travelDestination =
            this.formGroup.controls['travelDest'].value;
          console.log(
            'FINISHED setting data for reimbursement',
            this.reimbursementDetailData
          );

          this.onShowSummary.emit();
          return true;
        });
    });

    //  }).then((f) => {return f;});
    //}).then((f) => {return f;});
  }
  openTravelExpenseComponent(): void {
    const expenseData = this.getCurrentExpense();
    //    this.formGroup.markAllAsTouched();
    //    if (!this.formGroup.valid) {
    //      return;

    //    }
    //this.populateReimbursementData();
    /*
const address = {
      City: this.reimbursementDetailData.startCity,
      State: this.reimbursementDetailData.startState,
      Zip: this.reimbursementDetailData.startZip,
    };

    const t = this.mileageService.verifyCityStateZip(address).then((v: any) => {
      this.invalidCity = !(v.validCity === true);
      this.invalidZip = !(v.validZip === true);

    });
    console.log('ADDRESS VERIFICATION', t);

*/
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mileageData: this.mileageData,
      reimbursementDetailData: this.reimbursementDetailData,
    };

    this.expenseDialogRef = this.dialog.open(
      MileageReimbursementExpenseComponent,
      dialogConfig
    );
    const instance = this.expenseDialogRef.componentInstance;
    instance.dialogRef = this.expenseDialogRef;
    instance.reimbursementDetailData = this.reimbursementDetailData;
    instance.expenseIndex = this.expenseIndex;
    instance.claim = this.claim;
    instance.allowTotalCost =
      this.formGroup.controls['travelType'].value !== this.travelTypeRequired;

    instance.expenseDefaultData = expenseData;
    this.subs.sink = instance.onUpdateExpense.subscribe((e) =>
      this.updateExpense(e)
    ); // = this.updateExpense;
  }

  getCurrentExpense = (): MileageTravelExpense => {
    if (this.expenseIndex === -1) {
      const defaultData: MileageTravelExpense = {
        amountPaid: null,
        expenseType: null,
        Documents: [],
      };

      return defaultData;
    } else {
      return this.reimbursementDetailData.Expenses[this.expenseIndex];
    }
    /*
      console.log('ADDING DEFAULT EXPENSE DATA');
      this.reimbursementDetailData.Expenses.push(<MileageTravelExpense>{
        expenseType: null,
        amountPaid: null,
        Documents: [],
      });

      this.expenseIndex = this.reimbursementDetailData.Expenses.length -1;
    } */
  };

  /*
  getExpenses = () => {
    console.log('GETTING EXPENSES', this.reimbursementDetailData.Expenses);
    return this.reimbursementDetailData.Expenses ?? [];
  };
  */

  updateExpense = (data: any) => {
    //this.cdr.detach();
    setTimeout(() => {
      if (this.expenseIndex === -1) {
        this.reimbursementDetailData.Expenses.push(<MileageTravelExpense>{});
        this.expenseIndex = this.reimbursementDetailData.Expenses.length - 1;
      }
      const expenseData = data[0];
      //debugger;
      this.reimbursementDetailData.Expenses[this.expenseIndex] = expenseData; // {...data[0]} as MileageTravelExpense;

      if (this.expenseListComponent) {
        this.expenseListComponent.updateDataSource();
      }
      //this.cdr.reattach();
      //this.cdr.detectChanges();

      if (data[0].closeDialog === true) {
        this.expenseDialogRef.close();
      }
    });
    //return;
    /*
    this.cdr.detach();
    this.reimbursementDetailData.Expenses[this.expenseIndex] = data;
    this.expenses = this.reimbursementDetailData.Expenses;
    this.cdr.reattach();
    this.cdr.detectChanges();
    console.log("AFTER ADDING EXPENSE DETAIL EXPENSES", this.reimbursementDetailData);
    */
  };

  deleteExpense(index: number) {
    this.reimbursementDetailData.Expenses.splice(index, 1);
    this.expenseListComponent.updateDataSource();
    return;
  }
  editExpense(index: number) {
    this.expenseIndex = index;
    this.openTravelExpenseComponent();
    return;
  }

  addExpense() {
    this.expenseIndex = -1;
    this.openTravelExpenseComponent();
    return;
  }
  travelTypeChanged() {
    if (
      this.mileageData.roundTrip !== undefined &&
      (this.mileageData.minMilesLimit !== undefined ||
        this.mileageData.maxMilesLimit !== undefined)
    ) {
      this.formGroup.controls['travelDistance'].addValidators([
        customvalidations.MileageReimbursementTotalMiles(
          this.formGroup.controls['travelDistanceType'],
          this.mileageData.minMilesLimit,
          this.mileageData.maxMilesLimit,
          this.mileageData.roundTrip
        ),
      ]);
    }

    if (
      this.formGroup.controls['travelType'].value === this.travelTypeRequired
    ) {
      this.reimbursementDetailData.Expenses =
        this.reimbursementDetailData.Expenses.filter(
          (e) => e.expenseType !== 'Total cost'
        );
    }
  }
}
