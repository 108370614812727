import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Announcements } from '../../models/announcements';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-announcements',
  template: `
    <ng-container *ngIf="announcements == null">
      <mat-spinner
        color="accent"
        diameter="40"
        mode="indeterminate"
      ></mat-spinner>
    </ng-container>

    <div class="overlay" *ngIf="announcements != null">
      <div *ngFor="let item of announcements">
        <span class="AnnouncementHeaderH2">{{ item?.announcementsName }}</span>
        <span *ngFor="let item1 of item.announcementDetails">
          <p [innerHTML]="item1?.announcementsText"></p>
          <br />
          <hr style="color:#D2D8E1 !important" aria-hidden="true" />
          <hr style="color:#D2D8E1 !important" />
        </span>
        <br />
      </div>
      <div>
        <img
          src="/assets/images/splash_peoplerow.png"
          width="500"
          height="70"
          style="align:centre"
          loading="lazy"
          aria-hidden="true"
          alt=""
        />
      </div>
      <hr style="color:#D2D8E1 !important" aria-hidden="true" />
      <hr style="color:#D2D8E1 !important" />
      <mat-dialog-actions align="end" class="mySedwick-popupFooter">
        <mat-checkbox
          style="color: #67707A;"
          aria-label="Do not show these announcements on future logins"
          (change)="doNotShowAnnouncement($event)"
          class="pad-right-5"
          >Do not show again</mat-checkbox
        >
        <button
          mat-button
          aria-label="Close this popup"
          [mat-dialog-close]="true"
          aria-label="OK"
          mat-raised-button
          class="btn-info"
          (click)="acceptedAnnouncements()"
        >
          OK
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
    `
      .close.mat-mdc-button {
        float: right;
        position: relative;
      }
      .pad-right-5 {
        padding-right: 2em;
      }
      .btn-info {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background: #007dbc;
      }
      .btn-info:hover {
        background: #035e81;
      }
      .AnnouncementHeaderH2 {
        color: #171c20;
        font-size: 17px;
        font-weight: bold;
      }
    `,
  ],
})
export class AnnouncementsComponent {
  announcements: Announcements[];
  doNotShowIds: any = [];
  // @Input()
  // dialogRef: MatDialogRef<any>;
  title: string = 'Announcements';
  index: number = 0;
  announcementChecked: boolean = false;
  showAnnouncements: boolean = false;
  constructor(
    private http: HttpClient,
    public session: Session,
    public dialogRef: MatDialogRef<AnnouncementsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.announcements = data != null ? data.announcements : [];
  }
  close(result: string = null) {
    sessionStorage.setItem('closed-announcements', 'true');
    this.dialogRef.close(result);
  }
  async ngOnInit() {
    if (this.announcements == null || this.announcements.length == 0) {
      this.closeModalPopUp();
    }
    this.dialogRef.afterClosed().subscribe(() => {
      sessionStorage.setItem('closed-announcements', 'true');
    });
  }
  async acceptedAnnouncements() {
    if (this.announcementChecked && !this.session.user.isEmulating) {
      const announcementIds: any = [];
      this.announcements
        .flatMap((x) => x.announcementDetails)
        .forEach((y) => {
          announcementIds.push(y.announcementsId);
        });
      this.http
        .post<void>(
          `/api/user/Announcements/setAnnouncementsDismiss`,
          announcementIds
        )
        .subscribe(() => {
          this.close();
        });
    } else {
      this.close();
    }
    sessionStorage.setItem('closed-announcements', 'true');
  }
  closeModalPopUp() {
    this.dialogRef.close();
  }
  async doNotShowAnnouncement(event: any) {
    this.announcementChecked = event.checked;
  }
}
