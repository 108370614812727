import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  BaseComponent,
  DialogComponent,
  Random,
  TextAreaControlComponent,
  SearchResult,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';

import { IncidentalAbsence } from '../../models/incidental-absence';
import { AbsenceService } from '../../services/absence.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-remove-absence-dialog',
  template: `
    <div
      class="tw-flex tw-flex-wrap tw-w-full app-pb2 app-pt2"
      style="overflow-x: hidden"
      title=""
    >
      <app-dialog
        title="Remove Absence"
        ariaLabel="Remove absence dialog"
        [showActions]="true"
        [confirmOnce]="true"
        (confirmEvent)="removeAbsence()"
        [confirmLabel]="'Confirm'"
        [cancelLabel]="'Cancel'"
        [btnConfirmAltText]="'Confirm'"
        [btnConfirmTitleText]="'Confirm'"
        [btnCloseAltText]="'Cancel'"
        [btnCloseTitleText]="'Cancel'"
        [showHeader]="true"
      >
        <mat-label *ngIf="removeSuccess === false">
          Please confirm to remove this absence
        </mat-label>

        <!--<mat-label>{{ 'Please confirm to remove Abcence Number ' + absence.absenceNumber }}</mat-label>-->
        <div class="messages">
          <app-toast type="Info" *ngIf="removeSuccess === true"
            >The absence has been removed. Please allow up to two hours for the
            updates to be visible in mySedgwick.
          </app-toast>
          <app-toast type="Error" *ngIf="updateError === true"
            >There was an error removing the absence.
          </app-toast>
        </div>
        <span id="placeholder">&nbsp;</span>
      </app-dialog>
    </div>
  `,
  styles: [
    `
      .dialog-title {
        padding-bottom: 18px;
      }

      .closebtn {
        float: right;
        cursor: pointer;
        color: black;
        font-weight: bold;
        margin-top: -40px;
        /*font-size: 18px;*/
      }

      .messages {
        max-width: 270px;
      }
      .errorMsg {
        font-weight: bold;
        color: red;
      }
      ::ng-deep .commCenterName {
        font-weight: bold;
      }
      @media only screen and (max-device-width: 480px) {
        .icon {
          display: none;
        }

        ::ng-deep .commactionbtn {
          padding-right: 30px !important;
        }
      }

      .messageDate {
        float: right;
        margin-right: 10px;
      }
      .examiner-container {
      }
      .icon {
        font-size: 40px;
        width: 40px;
        height: 40px;
        color: var(--color-accent);
        margin-top: 18px;
      }
      .user-message {
        --background-color: #c9edfc;
        background-color: #e0effe;
        --background-color: #eff7ff;

        border-radius: 8px;
        --padding: 12px 16px 12px 16px;
        margin-left: 8px;
      }
      .message-div {
        margin-right: 10px;
        padding: 12px 16px 12px 16px;
      }
      .examiner-message {
        background-color: #f6f6f6;
        border-radius: 8px;
        --padding: 12px 16px 12px 16px;
        margin-left: 8px;
      }
      .sender {
        font-size: 12px;
        margin-left: 24px;
        font-weight: bold;
        padding-bottom: 4px;
        color: var(--color-input-grey);
      }
    `,
  ],
})
export class RemoveAbsenceDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input()
  dialogRef: MatDialogRef<any>;

  @Input()
  absence: IncidentalAbsence;

  removeSuccess: boolean = false;
  updateError: boolean = false;
  isUpdating: boolean = false;
  errorMessage: String = '';

  async removeAbsence(): Promise<string> {
    if (this.isUpdating) {
      return '';
    }
    this.updateError = false;
    this.removeSuccess = false;
    this.absence.absenceStatus = 'Cancelled';
    this.absence.ClaimLevelFeatures = [];
    this.isUpdating = true;
    /* let path = `/api/manager/absences`;
    await lastValueFrom(
      this.http.post<boolean>(
        path + '/edit-absence',
        this.absence
      )
    ).then((r) => { */
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackbar.show(
        'Preview-only mode. Information not sent.',
        SnackbarType.Info
      );
      this.isUpdating = false;
      this.removeSuccess = true;
      
    } else {

    const returnValue = await this.absenceService.editRemoveAbsence(this.absence);
    if (returnValue !== undefined && returnValue) {
      this.isUpdating = false;
      this.removeSuccess = true;
    } else if (returnValue !== undefined && returnValue === false) {
      this.isUpdating = false;
      this.removeSuccess = false;
      this.updateError = true;
    }
  }

    //});
    return '';
  }
  constructor(
    private fb: UntypedFormBuilder,
    private random: Random,
    private absenceService: AbsenceService,
    private session:Session,
    private snackbar:SnackbarService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    if (this.dialogRef) {
      //this.messageTextArea.focus();
    }
  }

  ngOnInit(): void {}
  closePopUp() {
    this.dialogRef.close();
  }
}
