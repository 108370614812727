import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigurationSetting } from 'src/app/shared';
import { AdditionalUserDetails } from '../models/additionaluserdetails';
import { Session } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetails {
  claimConfig: ConfigurationSetting;
  session: Session;
  constructor() { }
  get Features() {
    return this._featuresList;
  }

  private _featuresList: ConfigurationSetting;

  private additionalUserInformation: AdditionalUserDetails;

  //need specific user roles for LC 
  public getUserRole(user: any) {
    if (user.role.toUpperCase() === "HRM" && user.authenticationType === "CLM") {
      return "CLAIMANT";
    }
    else if (user.role.toUpperCase() === "HRM" && user.authenticationType !== "CLM") {
      return "EMPLOYEE";
    }
    else if (user.role.toUpperCase() !== "HRM" && user.authenticationType !== "CLM") {
      return user.role.toUpperCase();
    }
    else {
      return user.role.toUpperCase();
    }
  }
}
