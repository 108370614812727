import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportNewClaimComponent } from 'src/app/my-sedgwick/components/report-new-claim/report-new-claim.component';

export function reportNewClaim(dialog: MatDialog) {
  // Create a dialog configuration
  const dialogConfig = new MatDialogConfig();
  dialogConfig.data = {
    title: 'Confirmation',
  };

  // Open the dialog
  dialog.open(ReportNewClaimComponent, dialogConfig);
}
