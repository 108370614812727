import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { RestrictionTable } from '../../models/Restrictions';
import { Restriction } from './restriction-detail.component';

@Component({
  selector: 'app-restrictions',
  template: `
    <app-list
      [dataSource]="restrictionTable"
      [template]="row"
      [filterEnabled]="false"
      class="app-pt2"
      loadingMessage=""
      noDataIcon="work_outline"
      [loaded]="true"
      [tableAriaLabel]="'Restrictions'"
      noDataMessage="<br><strong>No current work restrictions</strong>"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <app-list-header
            name="from"
            label="Working with Restrictions"
            class="tw-w-full md:tw-w-[20%]"
            filterType="Date"
          ></app-list-header>
          <app-list-header
            class="tw-w-full md:tw-w-[20%]"
            name="fromRecommended"
            label="Recommended Dates"
            filterType="Date"
          ></app-list-header>
          <app-list-header
            name="type"
            label="Type"
            class="tw-w-full md:tw-w-[20%]"
          ></app-list-header>
          <app-list-header
            name="activity"
            label="Activity"
            class="tw-w-full md:tw-w-[20%]"
          ></app-list-header>
          <app-list-header
            name="restriction"
            label="Restriction"
            class="tw-w-full md:tw-w-[20%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element>
      <tr
        class="app-pt2 app-pb2 tw-flex tw-flex-wrap tw-w-full tw-items-center"
      >
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value
            label="Working with Restrictions"
            layout="row-xs"
            [tabindex]="0"
          >
            <span value *ngIf="element.from != '' || element.to != ''">
              {{ element.from | format : 'date' }} -
              {{ element.to | format : 'date' }}
            </span>
            <span value *ngIf="element.from == '' || element.to == ''">
              {{ element.from }}
              {{ element.to }}
            </span>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value label="Recommended Dates" layout="row-xs" [tabindex]="0">
            <span
              value
              *ngIf="
                element.fromRecommended != '' || element.toRecommended != ''
              "
              >{{ element.fromRecommended | format : 'date' }} -
              {{ element.toRecommended | format : 'date' }}</span
            >
            <span
              value
              *ngIf="
                element.fromRecommended == '' || element.toRecommended == ''
              "
            >
              {{ element.fromRecommended | format : 'date' }}
              {{ element.toRecommended | format : 'date' }}
            </span>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value
            [tabindex]="0"
            label="Type"
            [value]="element.type"
            layout="row-xs"
          ></app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value
            [tabindex]="0"
            label="Activity"
            [value]="element.activity"
            layout="row-xs"
          ></app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value
            [tabindex]="0"
            label="Restriction"
            [value]="element.restriction"
            layout="row-xs"
          ></app-value>
        </td>
      </tr>
    </ng-template>
  `,
  styles: [],
})
export class RestrictionsComponent implements OnInit {
  // @Input()
  // restrictions: Restriction[];
  @Input()
  restrictionTable: RestrictionTable[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) this.restrictionTable = data.restrctions;
  }

  // @Input()
  // restrictionData: RestrictionTable[];

  async ngOnInit() {}
}
