import { Injectable, OnDestroy, signal } from '@angular/core';
import { RecentClaim, Session } from 'src/app/my-sedgwick';
import { SnackbarService, SnackbarType } from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { Http } from 'src/app/shared/services/http.service';
import { HistoryInfo } from '../models/historyinfo';
import { ListConfig } from '../models/listconfig';

@Injectable({
  providedIn: 'root',
})
export class RecentClaimService implements OnDestroy {
  watchList = signal<RecentClaim[]>([]);

  initialized = false;

  constructor(
    private http: Http,
    private snackbar: SnackbarService,
    private session: Session
  ) {
    this.session.isEmulating$.subscribe((isEmulating) => {
      this.loadWatchList();
    });
  }

  ngOnDestroy(): void {
    this.session.isEmulating$.unsubscribe();
  }

  async initWatchList() {
    if (!this.initialized) {
      await this.loadWatchList();
      this.initialized = true;
    }
  }

  async loadWatchList() {
    try {
      const watchList = await this.http.get<RecentClaim[]>(
        '/api/watch-list/GetWatchList',
        { cache: false, timeout: 0 }
      );
      this.watchList.set(watchList);
    } catch (error) {
      this.snackbar.show('Error loading watch list', SnackbarType.Error);
    }
  }

  async addToWatchList(claim: ClaimInfo): Promise<string> {
    let result: string = '';

    const data1 = await this.http.get<ListConfig>(
      '/api/watch-list/GetWatchListConfig'
    );

    try {
      if (this.watchList().length < data1.maxWatchListCount) {
        await this.addWatchList(claim);
        this.snackbar.show('Claim added to watch list');
        result = 'success';
      } else {
        result = 'The WatchList is full';
        this.snackbar.show(
          'The watch list is full. Additional claims can be added when one or more claims have been removed.',
          SnackbarType.Warning
        );
      }
    } catch (error) {
      this.snackbar.show('Error updating watch list', SnackbarType.Error);
    }

    return result;
  }

  private async addWatchList(claim: any) {
    const obj = <RecentClaim>{
      claimId: claim.claimId,
      source: claim.source,
      lob: claim.lob ? claim.lob : claim.lineOfBusiness,
      name: claim?.name,
      status: '',
      openDate: new Date().toISOString(),
      description: '',
      ProcUnit: '',
      VendorId: '',
      FileNumber: claim.claimNumber,
      HrMasterId: '',
      claimDescription: claim.claimDescription,
      claimNumber: claim.claimNumber,
      empUnum: claim.empUnum,
      empId: claim.empId,
    };
    await this.http.post<RecentClaim>(
      `/api/watch-list/${obj.source}/${obj.claimId}/${obj.lob}/SaveWatchList`,
      obj
    );
    await this.loadWatchList();
  }

  async deleteWatchList(watchList: string[]) {
    try {
      await this.http.post<RecentClaim[]>(
        '/api/watch-list/DeleteWatchList',
        watchList
      );
      this.snackbar.show('Claim removed from watch list');
      await this.loadWatchList();
    } catch (error) {
      this.snackbar.show('Error updating watch list', SnackbarType.Error);
    }
  }

  async deleteWatchListItem(source: string, claimId: string) {
    await this.deleteWatchList([claimId]);
  }

  displayWatchListErrorMsg1(event: any): string {
    return 'The Watch List is full. Additional claims can be added when one or more claims have been removed.';
  }

  saveToHistory(
    source: string,
    claimId: string,
    lob: string,
    historyinfo: any
  ) {
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      // For Auditor Emulation dont save history
    } else {
      this.http.post<void>(
        `/api/recent-claims/${source}/${claimId}/${lob}/SaveHistory`,
        historyinfo
      );
    }
  }

  getToHistory(): any {
    return this.http.get<HistoryInfo[]>('/api/recent-claims/GetHistory', {
      cache: false,
      timeout: 0,
    });
  }


}
