import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[create]',
})
export class CreateDirective {
  @Output()
  created = new EventEmitter<ElementRef>();

  constructor(private el: ElementRef) {
    // this.created.emit(el);
    setTimeout(() => this.created.emit(el), 100);
  }
}
