import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressDialogComponent } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private overlay: MatDialogRef<ProgressDialogComponent>;

  private _showing = false;

  get showing(): boolean {
    return this._showing;
  }

  constructor(private dialog: MatDialog) {}

  show(mode: OverlayMode = OverlayMode.Spinner, message: string = ''): void {
    this._showing = true;
    if (mode === OverlayMode.Message) {
      this.overlay = this.dialog.open(ProgressDialogComponent, {
        backdropClass: 'spinner-overlay-backdrop',
        disableClose: true,
        restoreFocus: false,
        data: message,
        minWidth: '400px',
      });
    } else if (mode === OverlayMode.Spinner) {
      this.overlay = this.dialog.open(ProgressDialogComponent, {
        panelClass: 'spinner-overlay',
        backdropClass: 'spinner-overlay-backdrop',
        disableClose: true,
        restoreFocus: false,
      });
    }
  }

  hide(): void {
    this._showing = false;
    if (this.overlay) {
      this.overlay.close();
    }
  }
}

export enum OverlayMode {
  None,
  Spinner,
  Message,
}
