import { Component, OnInit } from '@angular/core';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../../shared';

@Component({
  selector: 'app-footer',
  template: `<div
    class="footer tw-grow-0 tw-grid tw-grid-cols-1 tw-justify-items-center "
    role="contentinfo"
  >
    <div
      class="tw-flex tw-flex-wrap tw-max-w-screen-xl tw-w-full tw-items-center"
    >
      <div class="tw-grow">
        <img
          src="/assets/images/SDWK_PrimaryLogo_White.webp"
          width="155"
          height="37"
          loading="lazy"
          class="tw-pr-2"
          style="margin:auto;"
          alt="Sedgwick Logo"
        />
      </div>
      <div
        class="tw-grow-0 tw-pt-2  tw-flex tw-flex-col sm:tw-flex-row tw-gap-1"
        style="margin:auto;"
      >
        <a
          aria-label="Opens Technical Support popup"
          (click)="openSupportDialog()"
          (keydown.enter)="openSupportDialog()"
          role="button"
          class="app-pr3 app-uppercase app-pb2 app-pt2"
          tabindex="0"
          >TECHNICAL SUPPORT</a
        >
        <a
          label="Site Map"
          routerLink="/sitemap"
          class="app-pr3 app-uppercase app-pb2 app-pt2"
          >Site Map</a
        >
        <a
          href="https://www.sedgwick.com/global-privacy-policy"
          target="privacy-notice"
          class="app-pr3 app-uppercase app-pb2 app-pt2"
          >Privacy Policy</a
        >
        <a
          href="https://www.sedgwick.com/terms-conditions"
          target="privacy-notice"
          class="app-pr3 app-uppercase app-pb2 app-pt2"
          >Terms and Conditions</a
        >
        <a
          href="{{ helpUrl }}"
          target="privacy-notice"
          class="app-pr3 app-uppercase app-pb2 app-pt2"
          >Help</a
        >
        <div class="app-pr3 app-uppercase app-pb2 app-pt2">
          <span>{{ appCompany }}</span>
        </div>
      </div>
    </div>
  </div> `,
  styles: [
    `
      .footer a {
        color: white;
      }
      .footer a:hover {
        color: #ffffff;
        text-decoration: solid underline #ffffff 2px !important;
        cursor: pointer;
      }
      .footer a:focus {
        border: 2px solid black !important;
        outline: 2px solid white !important;
      }
      .footer span {
        color: #ffffff;
        padding: 0 7px 0 7px;
      }
    `,
  ],
})
export class FooterComponent extends BaseComponent implements OnInit {
  appCompany: string = '© #YEAR SEDGWICK';
  helpUrl: string;
  development = false;

  constructor(public dialog: MatDialog) {
    super();
    this.development = !environment.production;
  }

  ngOnInit() {
    this.appCompany = this.appCompany.replace(
      '#YEAR',
      new Date().getFullYear().toString()
    );
    this.helpUrl = environment.helpUrl;
  }

  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent, {
      disableClose: true,
    });
    this.subs.sink = dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.dialog.closeAll();
      }
    });
  }
}
