import { Injectable } from '@angular/core';
import {
  SearchConfiguration,
  SearchInformation,
  SearchParameters,
  SelectOption,
} from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchParameters: SearchParameters;

  searchInfomration: SearchInformation;
  //searchResults: SearchResult[];
  constructor(private http: Http) {}
  clear() {
    if (this.searchInfomration != undefined || this.searchInfomration != null) {
      this.searchInfomration.searchResults = null;
      this.searchInfomration.isMaxCount = false;
      this.searchParameters = null;
    }
  }

  async getSearchResults(
    searchParameters: any,
    stopCache = false
  ): Promise<SearchInformation> {
    await this.http.get<string>('/api/user/antiforgery');
    return await this.http.post<SearchInformation>(
      '/api/search?',
      searchParameters,
      {
        cache: !stopCache,
      }
    );
  }

  async GetStatus() {
    return await this.http.get<SelectOption[]>(`api/search/status`);
  }

  getConfiguration() {
    return this.http.get<SearchConfiguration>(`api/search/config`);
  }
}
