import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchResult, deepCopy } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { Excel, ExportRequest, UserRoleService } from '../..';

@Component({
  selector: 'app-search-employees-list',
  template: `
    <app-list
      [dataSource]="searchResults"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      loadingMessage=""
      noDataIcon="search"
      noDataMessage="No results"
      noDataThinMessage="There are no results that match the search"
      [pageSizeOptions]="[5, 10, 25]"
      [pageSize]="10"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these employees as a XLSX file"
      (export)="_export()"
      [tableAriaLabel]="'Search Results'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[25%]"
            [names]="['name', 'empId']"
          ></app-list-header>

          <app-list-header
            name="workPhone"
            label="Phone"
            class="tw-w-full md:tw-w-[25%]"
          >
          </app-list-header>
          <app-list-header
            name="locationCode"
            label="Location"
            class="tw-w-full md:tw-w-[25%]"
          >
          </app-list-header>
          <app-list-header
            name="jobTitle"
            label="Title"
            class="tw-w-full md:tw-w-[25%]"
          >
          </app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element role="rowgroup">
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label
              value
              [claimant]="element"
              [disabled]="!claimantLabelLink"
            >
            </app-claimant-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value
            label="Work Phone"
            [tabindex]="element.workPhoneNumber ? '0' : '1'"
            layout="row-xs"
            [value]="element.workPhoneNumber | phone"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value
            label="Location Code"
            [tabindex]="element.locationCode ? '0' : '1'"
            layout="row-xs"
            [value]="element.locationCode"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value
            label="Job Title"
            [tabindex]="element.jobTitle ? '0' : '1'"
            layout="row-xs"
            [value]="element.jobTitle"
          >
          </app-value>
        </td>
      </tr>
    </ng-template>
  `,
  styles: ``,
})
export class SearchEmployeesListComponent {
  @Input()
  searchResults: SearchResult[];

  @Input()
  excelName: string = 'Employee Search.xlsx';

  @Input()
  sheetName: string = 'Employee Search';

  @Output()
  export = new EventEmitter<ExportRequest>();

  userAction = UserActions.ViewOwnMgrDb;

  claimantLabelLink = false;

  constructor(private excel: Excel, public userRole: UserRoleService) {
    this.claimantLabelLink = this.userRole.manager || this.userRole.auditor;
  }

  _export() {
    const request = new ExportRequest();
    const claimRows = deepCopy(this.searchResults);
    request.fileName = this.excelName;
    request.sheets = [
      {
        name: this.sheetName,
        rows: claimRows,
        headers: [
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'employeeId' },
          { label: 'Work Phone', value: 'workPhoneNumber' },
          { label: 'Location Code', value: 'locationCode' },
          { label: 'Job Title', value: 'jobTitle' },
        ],
      },
    ];

    this.export.emit(request);
    this.excel.export(request);
  }
}
