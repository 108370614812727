import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Session } from '../../services/session.service';
import {
  ConfigurationSetting,
  OverlayMode,
  ProgressService,
  SearchResult,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { AccommodationsService } from '../../services/accommodations-service';
import { UnapprovedAccomodation } from '../../models/accommodations-interactions';
import { Excel, ExportRequest } from '../../services/excel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { ConfigurationService } from '../..';

@Component({
  selector: 'app-unapproved-accommodations-panel',
  template: `
    <app-panel
      title="Unapproved Accommodations"
      titleCollapse="Collapse unapproved Accommodations"
      titleExpand="List of unapproved Accommodations"
      class="tw-w-full"
      [expanded]="expanded"
      [labelTemplate]="labelTemplate"
    >
      <app-list
        [dataSource]="listData"
        [template]="row"
        class="app-pt2"
        [noDataIcon]="noDataIcon"
        [noDataMessage]="noDataMessage"
        [noDataThinMessage]="noDataThinMessage"
        [displayInfoToastMsg]="false"
        [exportEnabled]="true"
        exportButtonAriaLabel="Export and download these unapproved accomodations as a XLSX file"
        (export)="_export()"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full">
            <app-list-header
              name="type"
              label="Type"
              class="tw-w-full md:tw-w-[20%]"
            ></app-list-header>
            <app-list-header
              name="impact"
              label="Duration"
              class="tw-w-full md:tw-w-[10%]"
            ></app-list-header>
            <app-list-header
              name="beginDate"
              label="Dates"
              class="tw-w-full md:tw-w-[20%]"
            ></app-list-header>
            <app-list-header
              name="description"
              label="Description"
              class="tw-w-full md:tw-w-[40%]"
            ></app-list-header>
            <app-list-header
              *ngIf="canApproveAccommodations"
              name="accommodationNumber"
              label="Confirm"
              class="tw-w-full md:tw-w-[10%]"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>

      <ng-template #row let-element>
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <app-value
            class="tw-w-full md:tw-w-[20%]"
            layout="row-xs"
            [value]="element.type"
            label="Type"
          >
          </app-value>
          <app-value
            class="tw-w-full md:tw-w-[10%]"
            layout="row-xs"
            [value]="element.impact"
            label="Duration"
          >
          </app-value>
          <app-value
            class="tw-w-full md:tw-w-[20%]"
            layout="row-xs"
            label="Date"
          >
            <span value>
              {{ element.beginDate | format : 'date' }}
              @if(element.beginDate || element.endDate){ - }
              {{ element.endDate | format : 'date' }}
            </span>
          </app-value>
          <app-value
            class="tw-w-full md:tw-w-[40%]"
            layout="row-xs"
            [value]="element.description"
            label="Description"
          >
          </app-value>
          <div class="tw-w-full md:tw-w-[10%]" *ngIf="canApproveAccommodations">
            <app-icon-button
              label="Confirm"
              icon="done"
              role="button"
              [attr.aria-label]="
                'Confirm pending accommodation - ' + element.description
              "
              (click)="confirm(element)"
              (keydown.enter)="confirm(element)"
            ></app-icon-button>
          </div>
        </tr>
      </ng-template>

      <ng-template #labelTemplate>
        @if (listData.length) {
        <mat-icon
          [matBadge]="listData.length"
          matBadgeOverlap="true"
        ></mat-icon>
        }
      </ng-template>
      <ng-template #confirmDialog>
        <app-dialog
          title="Confirmation"
          ariaLabel="Confirmation dialog"
          [showActions]="false"
          [showHeader]="false"
          ariaLabelMessage="Cancel"
          cancelLabel="Cancel"
        >
          <app-dialog-container
            label="Confirm accommodation"
            class="container"
            [showHeader]="true"
            [headerLevel]="2"
            [headerId]="'confirmAccommodation'"
          >
            <div class="tw-w-full">
              <app-claim-label
                value
                [claim]="claim"
                layout="row"
                [showNameLabel]="true"
                [noLink]="true"
                [hideWatchList]="true"
              ></app-claim-label>
            </div>
            <div class="tw-w-full">
              <app-claimant-label
                value
                [claimant]="claim"
                [showNameLabel]="true"
                [nameClass]="'headingbold'"
                class="app-uppercase"
                [disabled]="true"
              >
              </app-claimant-label>
            </div>
            <form [formGroup]="formGroup">
              <div class="tw-w-full">
                <app-container-control
                  ngDefaultControl
                  [formGroup]="formGroup"
                  formControlName="validAccommodation"
                  class="app-pb3 headingbold"
                  label="Do you agree with this accommodation?"
                >
                  <mat-radio-group aria-label="Is this a valid job function?">
                    <mat-radio-button (change)="toggleValid()" value="Approved"
                      >Approved</mat-radio-button
                    >
                    <span class="app-pl2"></span>
                    <mat-radio-button (change)="toggleValid()" value="Reject"
                      >Reject</mat-radio-button
                    >
                  </mat-radio-group>
                </app-container-control>
                @if(showComments){
                <div class="tw-w-full">
                  <app-text-area-control
                    label="Additional Comments *"
                    ngDefaultControl
                    [formGroup]="formGroup"
                    formControlName="comments"
                    [maxLength]="4000"
                    validationControl="Additional comments"
                    [isSingular] = 'false'
                    [rows]="3"
                  ></app-text-area-control>
                </div>
                }
              </div>
            </form>
            <app-action-row>
              <app-button
                right2
                autofocus
                (click)="send()"
                emphasis="High"
                [disabled]="!formGroup.valid"
                >Confirm</app-button
              >
              <app-button
                right1
                id="btnCancel"
                (click)="cancel()"
                emphasis="Low"
                >Cancel</app-button
              >
            </app-action-row>
          </app-dialog-container>
        </app-dialog>
      </ng-template>
    </app-panel>
  `,
  styles: [
    `
      .mat-icon {
        position: relative;
        top: 1px;
        left: -15px;
      }
    `,
  ],
})
export class UnapprovedAccommodationsPanelComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  expanded: boolean = false;

  @Output()
  export = new EventEmitter<ExportRequest>();

  ShowSort: boolean = true;

  @ViewChild('confirmDialog') confirmDialogRef: TemplateRef<any>;

  listData: UnapprovedAccomodation[] = [];
  noDataIcon: string = 'data_usage';
  noDataMessage: string = 'No unapproved accommodations';
  noDataThinMessage: string = '';
  showComments: boolean = false;
  description: string = '';
  canApproveAccommodations: boolean = false;

  @Input()
  unApprovedAccommodations: UnapprovedAccomodation[] = [];
  constructor(
    public dialog: MatDialog,
    public session: Session,
    private accommodationsService: AccommodationsService,
    private excel: Excel,
    private formBuilder: FormBuilder,
    public progressService: ProgressService,
    private snackbar: SnackbarService,
    private configurationService: ConfigurationService
  ) {
    this.canApproveAccommodations = this.configurationService.hasFeature(
      'accommodationsrequest'
    );
  }

  formGroup: FormGroup = this.formBuilder.group({
    validAccommodation: ['', [Validators.required]],
    comments: [''],
    accommodationId: [''],
    accommodationNumber: [''],
  });

  ngOnInit() {
    this.listData = this.unApprovedAccommodations;
    if (!(this.listData != undefined && this.listData.length > 0)) {
      this.ShowSort = false;
    }
    //console.log(this.listData);
  }

  toggleValid() {
    this.showComments =
      this.formGroup.get('validAccommodation').value === 'Reject';
    if (this.showComments) {
      this.formGroup.get('comments').addValidators(customvalidations.required);
    } else {
      this.formGroup.get('comments').clearValidators();
    }
    this.formGroup.get('comments').updateValueAndValidity();
  }

  async confirm(unapprovedAccomodations: UnapprovedAccomodation) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
      arialabelledby: 'confirmAccommodation',
    };
    this.formGroup.reset();
    this.showComments = false;
    this.formGroup
      .get('accommodationId')
      .setValue(unapprovedAccomodations.accommodationId);
    this.formGroup
      .get('accommodationNumber')
      .setValue(unapprovedAccomodations.accommodationNumber);

    this.description =
      unapprovedAccomodations.description == undefined
        ? ''
        : unapprovedAccomodations.description;
    this.dialog.open(this.confirmDialogRef, dialogConfig);
  }

  _export() {
    const request = new ExportRequest();
    request.fileName =
      'Unapproved_Accommodation_' + this.claim.claimNumber + '.xlsx';
    request.sheets = [
      {
        name: 'Unapproved Accommodation',
        rows: this.listData,
        headers: [
          { label: 'Type', value: 'type' },
          { label: 'Duration', value: 'impact' },
          { label: 'Start Date', value: 'beginDate', format: 'date' },
          { label: 'End Date', value: 'endDate', format: 'date' },
          { label: 'Description', value: 'description' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }

  cancel() {
    this.description = '';
    this.dialog.closeAll();
  }

  async send() {
    if (this.formGroup.valid) {
      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.snackbar.show(
          'Preview-only mode. Information not sent.',
          SnackbarType.Info
        );
      } else {
        this.progressService.show(OverlayMode.Spinner, 'Please wait..');

        const inputedData: any = {
          accommodationNumber: this.claim.claimNumber,
          empUnum: this.session.user.empUnum,
          approvedAccommodations: this.getAcceptedAccommodationDetail(),
          rejectedAccommodations: this.getRejectedAccommodationDetail(),
        };

        this.accommodationsService
          .saveAccommodation(inputedData)
          .then(() => {
            this.listData = this.listData.filter(
              (_) =>
                _.accommodationNumber !==
                  this.formGroup.get('accommodationNumber').value &&
                _.accommodationId !==
                  this.formGroup.get('accommodationId').value
            );

            if (!(this.listData != undefined && this.listData.length > 0)) {
              this.ShowSort = false;
            }
            this.snackbar.show('Submit to TAMS success', SnackbarType.Success);
          })
          .finally(() => {
            this.progressService.hide();
          });
      }

      this.dialog.closeAll();
    }
  }

  getAcceptedAccommodationDetail() {
    let accomm: any[] = [];
    if (!this.showComments) {
      accomm = [
        {
          accommodationId: '' + this.formGroup.get('accommodationId').value,
          description: this.description,
          accomNumber: this.formGroup.get('accommodationNumber').value,
          comment: '',
        },
      ];
    }
    return accomm;
  }
  getRejectedAccommodationDetail() {
    let accomm: any[] = [];
    if (this.showComments) {
      accomm = [
        {
          accommodationId: '' + this.formGroup.get('accommodationId').value,
          description: this.description,
          accomNumber: this.formGroup.get('accommodationNumber').value,
          comment: this.formGroup.get('comments').value,
        },
      ];
    }
    return accomm;
  }
}
