import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-end-claimant-preview',
  template: `
    <div class="overlay">
      <div mat-dialog-title class="">
        <app-action-row>
          <h2 left1 class="app-header-2 heading-popup">{{ title }}</h2>
          <div right1>
            <button
              mat-icon-button
              (click)="closeModalPopUp()"
              aria-label="ariaLabelMessage"
              tabindex="0"
              class="closebtn"
              alt="close"
              title="close"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </app-action-row>
      </div>
      <mat-dialog-content class="modal-body">
        <div class="pad-top-2">
          <p>{{ callout }}</p>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end" class="mySedwick-popupFooter">
        <button
          mat-button
          alt="Cancel and go back"
          [mat-dialog-close]="true"
          mat-raised-button
          class="btn-info closebtn roundbtn"
          (click)="closeModalPopUp()"
        >
          Cancel
        </button>
        <button
          mat-button
          alt="Continue to end session"
          [mat-dialog-close]="true"
          mat-raised-button
          class="btn-info continuebtn roundbtn"
          (click)="stopEmulation()"
        >
          Continue
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
    `
      .titleheading {
        color: #171c20 !important;
      }
      .close.mat-button {
        float: right;
        position: relative;
        color: #67707a;
      }
      .modal-body {
        height: 100px;
      }
      .pad-right-5 {
        padding-right: 2em;
      }
      .pad-top-2 {
        padding-top: 2em;
      }
      .closepnl {
        float: left;
        text-align: right;
      }
      .continuebtn {
        background-color: #007dbc !important;
        color: #fff;
      }
      .continuebtn:hover {
        background-color: #035e81 !important;
      }
      .closebtn {
        background-color: #ffffff !important;
        color: #3f464d;
        border: 1px solid #3f464d;
      }
      .closebtn:hover {
        background-color: #f1f4f7 !important;
      }
      .roundbtn {
        border-radius: 24px;
      }
    `,
  ],
})
export class EndClaimantPreviewComponent {
  title: string = 'Confirmation';
  callout: string = `This will end your current preview of this account. Continue?`;

  constructor(
    public dialogRef: MatDialogRef<EndClaimantPreviewComponent>,
    public session: Session,
    private router: Router
  ) {}

  closeModalPopUp() {
    this.dialogRef.close();
  }

  async stopEmulation() {
    await this.session.stopEmulation('/');
  }
}
