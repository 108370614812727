import { Component, Input } from '@angular/core';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-text-area-control',
  template: `
    <div class="app-pr1 app-pb1 txtareadetail">
      <mat-form-field [hintLabel]="hintLabel" [formGroup]="formGroup">
        <mat-label>{{ label }}</mat-label>
        <textarea
          matInput
          #input
          [attr.maxlength]="maxLength"
          [placeholder]="placeHolder"
          [formControlName]="formControlName"
          [rows]="rows"
          autocomplete="off"
          [attr.disabled]="disabled ? 'disabled' : null"
          [aria-describedby]="ariaDescribedBy"
        >
        ></textarea
        >
        <mat-hint align="end" *ngIf="maxLength > 0"
          >{{ input.value?.length || 0 }}/{{ maxLength }}</mat-hint
        >
        <mat-error *ngIf="formControl.invalid" class="errormsg">{{
          errorMessage
        }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      .txtareadetail {
        color: #67707a !important;
        border-color: #67707a !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .txtareadetail::ng-deep .mat-form-field-outline {
        color: #67707a;
      }

      .txtareadetail:focus {
        color: #171c20 !important;
        border: #007dbc !important;
      }

      .txtareadetail:hover {
        color: #67707a !important;
        border: #3f464d !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .txtareadetail:focus::ng-deep .mat-form-field-outline {
        color: #007dbc;
      }
    `,
  ],
})
export class TextAreaControlComponent extends BaseControlComponent {
  @Input()
  maxLength: number;

  @Input()
  rows: number = 3;

  @Input()
  disabled = false;

  @Input()
  ariaDescribedBy: string;

  getElementByXpath(path: string) {
    return document.evaluate(
      path,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
  }
}
