import { Injectable } from '@angular/core';
import { Session } from 'src/app/my-sedgwick';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  constructor(private session: Session) {}

  get claimant(): boolean {
    return (
      (this.session.user.role.toUpperCase() === 'HRM' &&
        this.session.user.authenticationType === 'CLM') ||
      (this.session.user.role.toUpperCase() === 'HRM' &&
        this.session.user.authenticationType === 'EMULATEE')
    );
  }

  get employee(): boolean {
    return (
      (this.session.user.role.toUpperCase() === 'HRM' &&
        this.session.user.authenticationType === 'HRM') ||
      (this.session.user.role.toUpperCase() === 'HRM' &&
        this.session.user.authenticationType === 'EMULATEE') ||
      (this.session.user.role.toUpperCase() === 'HRM' &&
        this.session.user.authenticationType === 'SAML')
    );
  }

  get controller(): boolean {
    return this.session.user.role.toUpperCase() === 'CONTROLLER';
  }

  get internalAuditor(): boolean {
    return (
      this.session.user.role.toUpperCase() === 'AUDITOR' &&
      this.session.user.userType.toUpperCase() == 'INTERNALAUDITOR'
    );
  }

  get externalAuditor(): boolean {
    return (
      this.session.user.role.toUpperCase() === 'AUDITOR' &&
      this.session.user.userType.toUpperCase() == 'EXTERNALAUDITOR'
    );
  }

  get auditor(): boolean {
    return this.session.user.role.toUpperCase() === 'AUDITOR';
  }

  /**
   * Returns true if user is supervisor, overseer or omni.
   */
  get manager(): boolean {
    return (
      this.session.user.role.toUpperCase() === 'SUPERVISOR' ||
      this.session.user.role.toUpperCase() === 'OMNI' ||
      this.session.user.role.toUpperCase() === 'OVERSEER'
    );
  }

  get omni(): boolean {
    return this.session.user.role.toUpperCase() === 'OMNI';
  }

  get overseer(): boolean {
    return this.session.user.role.toUpperCase() === 'OVERSEER';
  }

  get supervisor(): boolean {
    return this.session.user.role.toUpperCase() === 'SUPERVISOR';
  }

  get hasSsnSearch(): boolean {
    return this.session.user.hasSsnSearch;
  }

  get getAccessibleRoles(): string[] {
    return this.session.user.accessibleRoles;
  }
}
