import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { te } from 'date-fns/locale';
import { ConfigurationSetting, DialogComponent, SearchResult } from 'src/app/shared';
import { RTWResponseStatus } from '../../models/rtw-response';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { ReturnToWorkEditComponent } from './return-to-work-edit.component';

@Component({
  selector: 'app-submit-return-to-work-dialog',
  template: `
  <div id="rtweditpnl">
    <app-dialog
      title="Submit Return To Work"
      ariaLabel="Submit return to work dialog"
      confirmLabel="Submit"
      (afterClosed)="resetData($event)" 
      [SaveAlt]="successAlt"
      [CancelAlt]="cancelAlt"
      (confirmEvent)="submitedit($event)"
      [showConfirm]="!isDisplaySuccess"
      #rtweditdialog>
    <ng-container *ngIf="!isDisplaySuccess" >
      <app-return-to-work-edit [claim] = "claim"  #returntoworkedit (AfterSuccess)="confirmationMessage($event)"
      ></app-return-to-work-edit>
    </ng-container>


    <div *ngIf="isDisplaySuccess">
        <br/>
        <app-toast type="Success">
            {{afterRtwSuccessMessage}}
        </app-toast>

        <app-upload  [claim] = "claim" [claimId]="claim.claimId"
            [source]="claim.source" [dialogRef]="rtweditdialog.dialogRef" [hideHeader]="true" [showClose]="true"
                      *ngIf="allowFileUpload">
        </app-upload>
    </div>
    </app-dialog>
  </div>
  `,
  styles: [`
      #rtweditpnl ::ng-deep .mat-accent
      {
        background-color:#fff !important;
        color:#3F464D!important;
        margin-left:3px;
      }
      #rtweditpnl ::ng-deep .mat-accent:hover
      {
        background-color:#F1F4F7 !important;
      }

      #rtweditpnl ::ng-deep .mat-mdc-unelevated-button
      {
        background-color:#007DBC !important;
        color:#fff!important;
      }
      #rtweditpnl ::ng-deep .mat-mdc-unelevated-button:hover
      {
        background-color:#035E81 !important;
      }
    `
  ]
})
export class SubmitReturnToWorkDialogComponent {
  claim: SearchResult;
  dialogData: any;

  // @Input()
  // claimConfig: ConfigurationSetting;

  ClaimNumber : string;
  afterRtwSuccessMessage:string;

  isDisplaySuccess : boolean = false;
  isUploadDisplay:boolean = false;
  successAlt:string="Submit Return to Work";
  cancelAlt:string="Cancel Return to Work";
  allowFileUpload: boolean = false;

  @ViewChild('returntoworkedit') returntoworkedit:ReturnToWorkEditComponent;
  @ViewChild('rtweditdialog') rtwdialoag : DialogComponent;

  constructor(public session: Session,
    public dialog: MatDialog,
    public route:ActivatedRoute,
    public claimConfigService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any){
    this.dialogData = data != null ? data : null;
    this.allowFileUpload = this.dialogData.uploadFile;
    this.setDataForRtw();
  }

  submitedit(eve: any){
    this.afterRtwSuccessMessage = "";
    this.returntoworkedit.ClaimID = this.ClaimNumber;
    this.returntoworkedit.LOB = this.claim.lob;
    this.returntoworkedit.submitreturntowork();
  }

  confirmationMessage(event:RTWResponseStatus){
    this.isDisplaySuccess = true;
    this.afterRtwSuccessMessage = event.msg;
    this.isUploadDisplay = event.isSuccess;
    this.rtwdialoag.showActions = !event.isSuccess;
  }

  resetData(event:string){
    this.isDisplaySuccess = false;
    this.afterRtwSuccessMessage = "";
  }

  setDataForRtw(): void{
    let tempData = this.dialogData.claim;
    let claimData = {
      claimNumber: tempData.claimNumber,
      claimId: tempData.claimId,
      name: tempData.name,
      employeeId: tempData.empId,
      source: tempData.source,
      lob: tempData.lineOfBusiness,
      claimDescription: tempData.claimDescription,
      lossDate: tempData.from,
      examinerName: tempData.examinerName, //'hardcoded name',
      examinerEmail: tempData.examinerEmail,
      status: tempData.status, //'hardCoded status',
      type: tempData.type, //'hardcoded type'
      substatus: tempData.subStatus,
    };
    // ExaminerName: this.claim.examinerName,
    //     ExaminerEmail: this.claim.examinerEmail != undefined ? this.claim.examinerEmail : '',
    //     LossDate: this.claim.lossDate,
    //     Name: this.claim.name,
    //     Type: this.claim.type,
    //     Status: this.claim.status,
    //     SubStatus: this.claim.subStatus  != undefined ? this.claim.subStatus : ''
    this.claim = claimData as unknown as SearchResult;
   }

}
