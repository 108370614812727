/**
 * Add a temporal div with text to be readed by screen reader.
 * @param text
 */
export function srSpeak(text: string, type: string) {
  const el = document.createElement('div');
  const id = 'speak-' + Date.now();
  el.setAttribute('id', id);
  el.setAttribute('aria-live', type);
  el.setAttribute('role', 'status');
  el.classList.add('sr-only');
  document.body.appendChild(el);

  window.setTimeout(function () {
    document.getElementById(id).innerHTML = text;
  }, 100);

  window.setTimeout(function () {
    document.body.removeChild(document.getElementById(id));
  }, 1000);
}
