import { Component, Input, OnInit } from '@angular/core';
import { TimelineDetail } from 'src/app/shared/components/controls/timeline/timeline-detail';
import { TimelineItem } from '../../models/Timeline';
import { SearchResult } from 'src/app/shared';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-claim-timeline',
  template: `
    <app-loading-panel
      [state]="loading ? 'Loading' : 'Loaded'"
      [skeletonLines]="2"
    >
      <ng-template #content>
        <app-timeline>
        <app-timeline-item
            *ngFor="let item of items"
            [label]="item.date | format : 'date'"
            [description]="item.description"
            [description1]="item.description1"
            [description2]="item.description2"
            [date]="item.date"
            [status]="item.status"
            [details]="item.details"
            [dateList]="item.dateList"
            tabindex="0"
          ></app-timeline-item>
        </app-timeline>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [],
})
export class ClaimTimelineComponent implements OnInit {
  @Input()
  source: string;

  @Input()
  claimId: string;

  @Input()
  lob: string;

  @Input()
  claim: SearchResult;

  @Input()
  tracker?: TimelineItem[];

  @Input()
  isFromCertifications: boolean = false;
  items: TimelineItem[] = [];

  loading = true;

  constructor(public session: Session) {}

  ngOnInit(): void {
    this.loadTimeTracker();
  }

  loadTimeTracker() {
    this.items =
      this.isFromCertifications && this.tracker && this.tracker.length > 0
        ? this.tracker
        : this.claim.claimTrackerDetails;
    if (this.items != undefined && this.items.length > 0) {
      this.items.forEach((value, index) => {
        if (value.items.length > 0) {
          value.details = [];
          value.items.forEach((val) => {
            const temp = new TimelineDetail();
            temp.status = val.status;
            temp.description = val.description;
            temp.description1 = val.description1;
            temp.description2 = val.description2;
            value.details.push(temp);
          });
        } else {
          value.details = null;
        }
      });
    }
    this.loading = false;
  }
}
