import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { LogService } from 'src/app/my-sedgwick';
import { BaseComponent } from 'src/app/shared';
import { SearchResult } from 'src/app/shared/models/search-result';
import { TabComponent } from './tab.component';

@Component({
  selector: 'app-tab-group',
  template: `
    @if(hideSingleTab && visibleTabs.length === 1) {
    <ng-container *ngTemplateOutlet="visibleTabs[0].template"></ng-container>
    } @else {
    <mat-tab-group
      id="tabGroupId"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      animationDuration="0"
      preserveContent
      [(selectedIndex)]="_selectedIndex"
      (selectedIndexChange)="tabIndexChange($event)"
      *ngIf="
        ((isSmall$ | async) === false && mode === 'Responsive') ||
        mode === 'Tab'
      "
    >
      <mat-tab *ngFor="let tab of visibleTabs; let i = index">
        <ng-template mat-tab-label>
          <span
            (click)="logFeature(tab.featureName, tab.label)"
            [matBadge]="tab.badge"
            class="tab-label tabgroup-expansion tabgroup-label tabgroup-label-hover"
            matBadgeOverlap="false"
            >{{ tab.label }}</span
          >
        </ng-template>
        <ng-template matTabContent>
          <ng-container
            *ngIf="tab.visited"
            [ngTemplateOutlet]="tab.template"
          ></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <mat-accordion
      multi="false"
      class="accordion"
      *ngIf="
        ((isSmall$ | async) && mode === 'Responsive') || mode === 'Accordion'
      "
    >
      <mat-expansion-panel
        class="mat-elevation-z0 bottom-border"
        *ngFor="let tab of visibleTabs; let i = index"
        [expanded]="i === _selectedIndex"
        (afterExpand)="tabIndexChange((_selectedIndex = i))"
      >
        <mat-expansion-panel-header>
          <div [class.badge-header]="tab.badge !== null" class="panel-header">
            <span [matBadge]="tab.badge" matBadgeOverlap="false">{{
              tab.label
            }}</span>
          </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <ng-container
            *ngIf="tab.visited"
            [ngTemplateOutlet]="tab.template"
          ></ng-container>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    }
  `,
  styles: [
    `
      .panel-header {
        font-size: 16px;
        color: var(--color-sedgwick-blue) !important;
        font-weight: bold;
      }

      .badge-header {
        margin-top: 12px;
        padding-top: 12px;
        margin-bottom: 18px;
        padding-bottom: 0px;
      }

      .bottom-border {
        border-bottom: 2px solid var(--color-light-grey);
      }

      ::ng-deep .accordion .mat-expansion-panel {
      }

      ::ng-deep .accordion .mat-expansion-panel-header {
        padding: 0 8px 0 8px;
        margin: 0;
      }
      ::ng-deep .accordion .mat-expansion-panel-body {
        padding: 8px;
        margin: 0;
      }

      ::ng-deep .mat-tab-label {
        color: var(--color-sedgwick-blue) !important;
        text-transform: uppercase;
      }

      ::ng-deep .mat-mdc-tab-body-content {
        width: 100%;
        overflow: hidden !important;
      }

      .tab-label {
        color: var(--color-sedgwick-blue) !important;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: normal;
      }

      ::ng-deep .mat-ink-bar {
        height: 4px !important;
      }

      .mat-badge-content {
        background-color: var(--color-input-grey);
      }

      .tabgroup-expansion ::ng-deep .mat-expansion-panel-header-title {
        color: #171c20;
      }

      .tabgroup-label ::ng-deep .tab-label-active {
        color: #035e81;
        font-weight: bold;
        text-decoration-color: var(--color-sedgwick-blue) !important;
        background-color: #f1f4f7;
      }

      .tabgroup-label-hover ::ng-deep .tab-label:hover {
        text-decoration: underline;
        text-decoration-color: #035e81;
        border-bottom: 2px solid #d2d8e1;
      }

      .tab-info {
        background-color: #f1f4f7;
        color: #035e81;
      }
      @media (min-width: 700px) {
        ::ng-deep .mat-mdc-tab-labels {
          border-bottom: 1px;
          border-style: solid;
          border-color: #dedede;
        }
      }
    `,
  ],
})
export class TabGroupComponent extends BaseComponent implements OnInit {
  @Input()
  selectedIndex: number;

  _selectedIndex: number = -1;

  tabs: TabComponent[] = [];

  @Input()
  mode: TabGroupMode = 'Responsive';

  @Input()
  sectionName: string = '';

  @Input()
  claim: SearchResult;

  /**
   * If hidSingleTab is true, the tab header and mobile accordion will be hidden
   * if only one tab exists.
   */
  @Input()
  hideSingleTab = false;

  @Input()
  selectedTab = new EventEmitter<string>();

  @Output()
  tabChange = new EventEmitter<TabComponent>();

  @Output()
  isLoaded = new EventEmitter<boolean>();

  private _tabs: TabComponent[] = [];

  private _previousIndex = -1;

  @Input()
  selectedTabName: string = '';

  @Input()
  tabGroupId: string = '';

  get visibleTabs(): TabComponent[] {
    if (this._tabs.findIndex((x) => x.label == 'New Claims') != -1) {
      this.forceTabToLeft('New Claims');
    }

    if (this._tabs.findIndex((x) => x.label == 'Payments') != -1) {
      this.forceTabToLeft('Payments');
    }

    if (this._tabs.findIndex((x) => x.label == 'Summary') != -1) {
      this.forceTabToLeft('Summary');
    }
    return this._tabs.filter((tab) => tab.visible);
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public logService: LogService
  ) {
    super();
  }

  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 768px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    this.subs.sink = this.selectedTab.subscribe((v) => {
      const index = this._tabs.findIndex(
        (i) => i.label.toLowerCase() == v.toLowerCase()
      );

      if (index > -1) {
        const str = v;
        const str2 = str.charAt(0).toUpperCase() + str.slice(1);
        this.selectTab(str2);
      }
    });

    setTimeout(() => {
      this.render();
    }, 0);
  }

  addTab(tab: TabComponent) {
    this.tabs.push(tab);
  }

  forceTabToLeft(tabName: string) {
    if (this._tabs.length > 2) {
      const index = this._tabs.findIndex((x) => x.label == tabName);
      const element = this._tabs[index];
      this._tabs.splice(index, 1);
      this._tabs.splice(0, 0, element);
    }
  }

  render() {
    this._tabs = this.tabs;
    this.tabIndexChange(this.selectedIndex);
    this._selectedIndex = this.selectedIndex;
    this.isLoaded.emit(true);
    if (this.selectedTabName.length > 0) {
      this.selectTab(this.selectedTabName);
    }
  }

  tabIndexChange(index: number) {
    const tab = this.visibleTabs[index];
    if (tab) {
      tab.visited = true;
    }

    if (this._previousIndex != index) {
      this._previousIndex = index;
      this.tabChange.emit(tab);
    }
  }

  selectTab(tabName: string) {
    const index = this.visibleTabs.findIndex(
      (x) => x.featureName?.toLowerCase() === tabName?.toLowerCase()
    );

    if (index > -1) {
      this.selectedIndex = index;
      this.tabIndexChange(this.selectedIndex);
      this._selectedIndex = this.selectedIndex;
      setTimeout(() => {
        const tabHeaders = document.querySelectorAll('.mdc-tab--active');
        tabHeaders.forEach((header) => {
          if (header instanceof HTMLElement) {
            header.focus();
          }
          return;
        });
      }, 0);
    }
  }

  logFeature(featureName: string, label: string) {
    if (featureName) {
      this.logService.LogFeature(
        this.claim,
        featureName,
        this.sectionName + ' - ' + label
      );
    }
  }
}

export type TabGroupMode = 'Responsive' | 'Tab' | 'Accordion';
