import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../../services/session.service';
import { EulaAgreements } from '../../models/eulaagreements';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnnouncementsComponent } from '../announcements/announcements.component';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-enduser-licenseagreement',
  template: `
    <div *ngFor="let i of eulaAgreements.slice(index, index + 1)">
      <div [innerHTML]="i.agreementText"></div>
      <div style="float: right; margin-top:10px;">
        <button
          mat-button
          emphasis="High"
          title="Accept User Agreement and License"
          alt="Accept User Agreement and License"
          class="btn-agree"
          (click)="licenseAgreed(true, i.agreementId, i.contNum, index)"
        >
          I agree
        </button>
        &nbsp;
        <button
          mat-button
          title="Cancel and exit"
          alt="Cancel and exit"
          class="btn-cancel"
          (click)="licenseCancelled(false)"
        >
          Cancel
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .btn-cancel {
        background-color: #ffffff !important;
        color: #3f464d !important;
        border: 1px solid #3f464d !important;
        border-radius: 16px;
      }
      .btn-cancel:hover {
        background-color: #f1f4f7 !important;
      }
      .btn-agree {
        color: #ffffff !important;
        background-color: #007dbc !important;
        font-weight: bold;
        border-radius: 16px;
      }
      .btn-agree:hover {
        background-color: #035e81 !important;
      }
    `,
  ],
})
export class EnduserLicenseagreementComponent implements OnInit {
  showTitle = true;
  eulaBinding: boolean = true;
  index: number = 0;
  @Input() hasEulaAgreement: boolean;
  @Input() eulaAgreements: any;
  @Output() acceptedEulaAgreement = new EventEmitter<boolean>();

  dialogRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    public session: Session,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit() {}

  public async licenseAgreed(input: any, id: any, contNum: any, index: any) {
    this.deleteMsg(id);
    if (index == this.eulaAgreements.length - 1) {
      this.dialog.open(AnnouncementsComponent);
    }
    var data = await lastValueFrom(
      this.http.post<boolean>(
        `/api/user/Eula/${id}/${contNum}/accepteulaagreements`,
        null
      )
    );
    if (data) {
      this.index++;
      if (this.index === this.eulaAgreements.length) {
        sessionStorage.setItem('eulaAccepted', 'true');
      }
    }
  }

  public licenseCancelled(input: any) {
    //this.router.navigate(['/unauthorized']);
    this.session.logoff();
  }

  public deleteMsg(id: any) {
    if (this.eulaAgreements.length - 1 == this.index) {
      this.eulaBinding = false;
      this.hasEulaAgreement = false;
      this.acceptedEulaAgreement.emit(this.hasEulaAgreement);
    }
  }
}
