import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { AccommodationsService } from '../../services/accommodations-service';
import { Session } from '../../services/session.service';
import {
  Accommodation,
  AccommodationJobFunction,
  HistoryAccommodation,
  PendingAccommodation,
} from '../../models/accommodation';
import { ActivatedRoute } from '@angular/router';
import { HistoryInfo } from '../../models/historyinfo';
import { UserRoleService } from '../../services/user-role.service';
import { ConfigurationSetting } from 'src/app/shared/models/configuration/claim-configuration';
import { ConfigurationService } from '../../services/configuration.service';
import { MyClaim, SearchResult, UserActionService } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { ClaimService } from '../../services/claim.service';

@Component({
  selector: 'app-accommodation-page',
  template: `
    <div *ngIf="isLoaded" fxLayout="row" fxLayoutAlign="end right">
      <div *ngIf="showPendingHistoryRadio" align="end">
        <mat-radio-group
          aria-live="polite"
          aria-label="show pending or history button selector"
          name="pendingHistoryRadio"
          (change)="pendingHistoryChange($event)"
        >
          <mat-radio-button [checked]="true" [value]="true"
            >Pending</mat-radio-button
          >
          <mat-radio-button [value]="false">View History</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="row">
      <app-loading-panel [state]="!isLoaded ? 'Loading' : 'Loaded'">
        <ng-template #content>
          <app-accommodation-list
            [showPending]="isPending"
            [pendingAccommodations]="pendingAccomm"
            [historyAccommodations]="historyAccomm"
            [isClaimSummary]="isClaimSummary"
            [allowInteractions]="!claim || (claim && !claim.userOwns)"
            [enableClaimantLink]="enableClaimantLink"
            [myClaims]="myClaims"
          ></app-accommodation-list>
        </ng-template>
      </app-loading-panel>
    </div>
  `,
  styles: `
  `,
})
export class AccommodationPageComponent implements OnInit {
  @Input()
  userOwns: boolean = false;

  @Input()
  ClientLevelConfig: ConfigurationSetting;

  @Input()
  claim: SearchResult;

  @Input()
  accommodations: AccommodationJobFunction[] = [];

  myClaims: MyClaim[];

  historyinfo: HistoryInfo = <HistoryInfo>{};

  pendingAccomm: PendingAccommodation[] = [];
  historyAccomm: HistoryAccommodation[] = [];

  showPendingHistoryRadio: boolean = true;
  isPending: boolean = true;

  isLoaded: boolean = false;

  @Input()
  isClaimSummary: boolean = false;

  enableClaimantLink: boolean = false;
  managerAction: UserActions;

  constructor(
    private accommodationsService: AccommodationsService,
    private route: ActivatedRoute,
    private sessionService: Session,
    private userRoleService: UserRoleService,
    private configurationService: ConfigurationService,
    private userAction: UserActionService,
    private claimService: ClaimService
  ) {}

  async ngOnInit(): Promise<void> {
    this.managerAction = this.userAction.getAction();
    this.enableClaimantLink =
      this.managerAction === UserActions.ViewOwnMgrDb ||
      this.managerAction === UserActions.MgrViewEmpClaim;

    this.showPendingHistoryRadio =
      (this.managerAction === UserActions.MgrViewEmpDb ||
        this.isClaimSummary) &&
      this.managerAction !== UserActions.ViewOwnClaim;

    if (this.managerAction === UserActions.ViewOwnClaim) {
      this.isPending = false;
    }

    if (this.isClaimSummary) {
      await this.loadClaimAccommodations();
    }
    this.buildAccommArrays();
  }

  async loadClaimAccommodations() {
    const currentEmpUnum =
      this.userRoleService.manager && !this.claim.userOwns
        ? this.claim.empUnum
        : null;
    await this.accommodationsService
      .getAccommodationsJobFunctions(
        this.claim?.claimId ? this.claim.claimId : null,
        this.claim.userOwns ? 'HRM' : this.sessionService.user.role,
        currentEmpUnum
      )
      .then((data) => {
        this.accommodations = data;
      });
    this.myClaims = await (<any>(
      this.claimService.getClaimsEmpUNum(this.claim.empUnum)
    ));
  }

  pendingHistoryChange($event: MatRadioChange) {
    this.isPending = $event.value;
  }

  buildAccommArrays() {
    const pendingTempArray: PendingAccommodation[] = [];
    const historyTempArray: HistoryAccommodation[] = [];
    if (!this.accommodations || !this.accommodations.length) {
      this.isLoaded = true;
      return;
    }
    //console.log('accoms', this.accommodations);
    this.accommodations.forEach((acc) => {
      if (
        acc.status === 'Open' &&
        !acc.userOwns &&
        acc.requestSubStatusTypeId === '24' &&
        (acc.decisionId === '1' || acc.jobFunctionStatusId === '1')
      ) {
        const pendingTemp: PendingAccommodation = {
          lineOfBusiness: acc.lineOfBusiness,
          accommodationRequestNumber: acc.accommodationRequestNumber,
          type: acc.accommodationDescription,
          begin: acc.searchDetailsStartDate,
          end: acc.searchDetailsEndDate,
          claimType: acc.claimType,
          recordId: acc.recordId,
          recordSource: acc.recordSource,
          empUnum: acc.empUnum,
          employeeId: acc.employeeId,
          lob: acc.lob,
          firstName: acc.firstName,
          lastName: acc.lastName,
          name: acc.name,
          userOwns: acc.userOwns,
        };
        pendingTempArray.push(pendingTemp);
      }
    });
    this.accommodations.forEach((acc) => {
      if (
        (acc.type !== 'JobFunction' &&
          !acc.userOwns &&
          (acc.decisionId === '1' ||
            acc.decisionId === '2' ||
            acc.decisionId === '3' ||
            acc.decisionId === '6')) ||
        (acc.type !== 'JobFunction' && acc.userOwns && acc.decisionId === '2')
      ) {
        const historyTemp: HistoryAccommodation = {
          lineOfBusiness: acc.lineOfBusiness,
          accommodationRequestNumber: acc.accommodationRequestNumber,
          status: acc.status,
          subStatus: acc.requestSubStatusTypeText,
          requestType: acc.requestType,
          decision: acc.decision,
          decisionDate: acc.decisionDate,
          begin: acc.startDate,
          end: acc.endDate,
          longTerm: acc.tempPerm === 'Long Term' ? 'Yes' : 'No',
          type: acc.accommodationDescription,
          subType: acc.accommSubtypeDescription,
          description: acc.description,
          recordId: acc.recordId,
          recordSource: acc.recordSource,
          empUnum: acc.empUnum,
          employeeId: acc.employeeId,
          lob: acc.lob,
          firstName: acc.firstName,
          lastName: acc.lastName,
          leaveFileNumber: acc.leaveFileNumber,
          leaveClaim: this.isClaimSummary ? this.getLeaveClaim(acc) : null,
          name: acc.name,
          claimType: acc.claimType,
          decisionId: acc.decisionId,
          userOwns: acc.userOwns,
        };
        historyTempArray.push(historyTemp);
      }
    });
    this.pendingAccomm = pendingTempArray;
    this.historyAccomm = historyTempArray;
    //console.log('pending', this.pendingAccomm);
    //console.log('history', this.historyAccomm);

    if (this.pendingAccomm.length === 0 && this.historyAccomm.length === 0) {
      this.showPendingHistoryRadio = false;
    }
    this.isLoaded = true;
  }

  getLeaveClaim(accomm: Accommodation) {
    if (
      !this.myClaims ||
      !this.myClaims.length ||
      this.myClaims.length === 0 ||
      !accomm ||
      !accomm.leaveFileNumber ||
      accomm.leaveFileNumber === ''
    ) {
      return null;
    } else {
      return this.myClaims.find(
        (x) => x.claimNumber === accomm.leaveFileNumber
      );
    }
  }
}
