import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  Claim,
  ConfigurationSetting,
  MyClaim,
  SearchResult,
  SentenceCasePipe,
  TabComponent,
  getUrlParams,
} from 'src/app/shared';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { AccommodationsService } from '../../services/accommodations-service';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { OverlayComponent } from '../../../shared';
import { DisplayService } from '../../services/display-service';

@Component({
  selector: 'app-claim-detail',
  template: `
    <app-panel
      [title]="(claim.claimDescription | titlecase) + ' - ' + claim.claimNumber"
      [showTitle]="true"
      minHeight="540px"
      [labelTemplate]="multipleClaims ? labelTemplate : emptylabelTemplate"
      [sentenceCase]="false"
      [headerLevel]="1"
      [largeHeader]="false"
      [canExpand]="false"
    >
      <ng-container>
        <ng-template #labelTemplate>
          <a
            role="button"
            tabindex="0"
            class="claim-link app-pl2 app-pr4"
            (click)="selectClaim($event)"
            (keydown.enter)="selectClaim($event)"
            cdkOverlayOrigin
            #viewDifferentClaimOverlayOrigin="cdkOverlayOrigin"
            aria-haspopup="menu"
            aria-expanded="false"
            aria-controls="viewDiffClaimOverlay"
            >View a different claim</a
          >
          <app-overlay
            [id]="'viewDiffClaimOverlay'"
            [origin]="viewDifferentClaimOverlayOrigin"
            #viewDifferentClaimOverlay
            [overlayClassName]="'viewDifferentClaimOverlay'"
          >
            <div class="example-menu ">
              <ng-container *ngFor="let data of claimInfo">
                <div class="tw-p-1">
                  <app-claim-label
                    role="menuitem"
                    *ngIf="claimId !== data.claimId"
                    layout="row"
                    [claim]="data"
                  ></app-claim-label>
                  <app-claim-label
                    role="menuitem"
                    *ngIf="claimId === data.claimId"
                    layout="row"
                    [claim]="data"
                    [noLink]="true"
                  ></app-claim-label>
                </div>
              </ng-container>
            </div>
          </app-overlay>
        </ng-template>
      </ng-container>

      <ng-template #emptylabelTemplate> </ng-template>

      <app-tab-group
        [tabGroupId]="'claimDetailTabGroup'"
        [selectedIndex]="selectedIndex"
        [selectedTabName]="selectedTab"
        [selectedTab]="tabChange"
        (isLoaded)="setFocus(true)"
        animationDuration="0"
        class="app-pt1"
        sectionName="Summary"
        [claim]="claim"
      >
        <app-tab label="Summary" featureName="Summary" [template]="summary">
          <ng-template #summary>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'SummaryDetails'"
            >
            </app-custom-message>
            <app-claim-summary [claim]="claim"></app-claim-summary>
            <app-accommodation-page
              *ngIf="claim.lob === 'AD'"
              [isClaimSummary]="true"
              [claim]="claim"
            ></app-accommodation-page>
          </ng-template>
        </app-tab>

        <app-tab
          [visible]="paymentTabVisible"
          label="Payments"
          id="paymentsTab"
          featureName="Payments"
          [template]="payments"
        >
          <ng-template #payments>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'Payments'"
            >
            </app-custom-message>
            <app-loading-panel
              [state]="!paymentTabVisible ? 'Loading' : 'Loaded'"
            >
              <ng-template #content>
                <app-payment [claim]="claim" [isHomePage]="false"></app-payment>
              </ng-template>
            </app-loading-panel>
          </ng-template>
        </app-tab>
        <app-tab
          label="Work Status"
          featureName="WorkStatus"
          [visible]="
            this.claimConfigService.getFeatureAccess('WorkStatus') && !isWFA
          "
          [template]="workStatus"
        >
          <ng-template #workStatus>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'WorkStatus'"
            >
            </app-custom-message>
            <app-workstatus [claim]="claim"></app-workstatus>
          </ng-template>
        </app-tab>

        <app-tab
          label="Benefits"
          featureName="Benefits"
          [visible]="
            this.claimConfigService.getFeatureAccess('Benefits') && isWFA
          "
          [template]="benefits"
        >
          <ng-template #benefits>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'Benefits'"
            >
            </app-custom-message>
            <app-benefits
              *ngIf="
                this.claimConfigService.getFeatureAccess('Benefits') && isWFA
              "
              [claim]="claim"
            ></app-benefits>
          </ng-template>
        </app-tab>
        <app-tab
          label="Work Schedule"
          featureName="WorkSchedule"
          [template]="schedule"
          [visible]="
            this.claimConfigService.getFeatureAccess('WorkSchedule') &&
            !this.claimConfigService.getConfigFeatureAccess(
              'customworkschedule'
            )
          "
        >
          <ng-template #schedule>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'WorkSchedule'"
            >
            </app-custom-message>
            <app-work-schedule [claim]="claim"></app-work-schedule>
          </ng-template>
        </app-tab>

        <app-tab
          label="Certifications"
          [visible]="
            this.claimConfigService.getFeatureAccess('Certifications') &&
            claim.lob === 'LV'
          "
          [template]="certifications"
        >
          <ng-template #certifications>
            <app-certifications
              [claim]="claim"
              *ngIf="claim.lob === 'LV'"
            ></app-certifications>
          </ng-template>
        </app-tab>

        <app-tab
          label="Restrictions"
          featureName="Restrictions"
          [visible]="this.claimConfigService.getFeatureAccess('Restrictions')"
          [template]="restrictions"
        >
          <ng-template #restrictions>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'Restrictions'"
            >
            </app-custom-message>
            <app-restriction-detail
              [claimId]="claimId"
              [source]="source"
              [claim]="claim"
              *ngIf="claim.lob !== 'LV'"
            ></app-restriction-detail>
          </ng-template>
        </app-tab>
        <app-tab
          label="Leave Balance"
          featureName="LeaveBalance"
          [visible]="this.claimConfigService.getFeatureAccess('LeaveBalance')"
          [template]="leave"
        >
          <ng-template #leave>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'LeaveBalance'"
            >
            </app-custom-message>
            <app-leave [claim]="claim"></app-leave>
          </ng-template>
        </app-tab>
        <app-tab
          label="Paid Time Off"
          featureName="PaidTimeOff"
          [visible]="IsPTOEnable"
          [template]="pto"
        >
          <ng-template #pto>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'PTOELECTION'"
            >
            </app-custom-message>
            <app-pto [claim]="claim"></app-pto>
          </ng-template>
        </app-tab>

        <app-tab
          label="Documents"
          name="Documents"
          featureName="Documents"
          [visible]="
            this.claimConfigService.getFeatureAccess('UploadDocuments') ||
            this.claimConfigService.getFeatureAccess('MyDocuments')
          "
          [template]="documents"
        >
          <ng-template #documents>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'ViewDocuments'"
            >
            </app-custom-message>

            <app-upload-page [showSection]="false"></app-upload-page>
          </ng-template>
        </app-tab>

        @if (claim.source !== 'L') {
        <app-tab
          *ngIf="display.importantContacts"
          label="Important Contacts"
          featureName="ImportantContacts"
          [template]="contacts"
        >
          <ng-template #contacts>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'ImportantContacts'"
            >
            </app-custom-message>
            <app-contact-list [claim]="claim" [claimConfig]="claimConfig">
            </app-contact-list>
          </ng-template>
        </app-tab>
        }
      </app-tab-group>
    </app-panel>
  `,
  styles: [
    `
      .claim-link {
        font-size: 16px;
        white-space: nowrap;
      }

      .example-menu {
        min-width: 180px;
        background-color: rgb(255, 255, 255);
        padding: 12px;
        border-radius: 12px;
        overflow: scroll;
        max-height: 500px;
      }

      .example-menu-inner {
        display: inline-flex;
        flex-direction: column;
        font-size: 14px;
      }
    `,
  ],
})
export class ClaimDetailComponent implements OnInit {
  readonly buttonSize = 26;
  claimId: string;
  source: string;

  JSON: any = JSON;

  @Input()
  claim: SearchResult;

  myClaims: MyClaim[];
  multipleClaims: boolean = false;

  claimInfo: Claim[] = [];

  @Input()
  claims: SearchResult[] = null;

  focus: string;
  selectedIndex: number;

  tabChange = new EventEmitter<string>();

  @Input()
  claimConfig: ConfigurationSetting;

  selectedTab: string = '';
  focusDocumentUpload: boolean = false;

  tabs: TabComponent[] = [];

  private _tabs: TabComponent[] = [];

  IsPTOEnable: boolean = false;

  paymentTabVisible: boolean = false;
  isWFA: boolean;
  viewDifferentClaimOverlayOrigin: CdkOverlayOrigin;
  claimsMenuVisible = false;

  @ViewChild(OverlayComponent) overlayComponent: OverlayComponent;

  constructor(
    public claimConfigService: ConfigurationService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private sentencecase: SentenceCasePipe,
    public session: Session,
    public dialog: MatDialog,
    private accommodationService: AccommodationsService,
    public display: DisplayService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isWFA =
      this.claim.lob == 'LV' || this.claim.lob == 'DS' ? true : false;
    this.claimId = this.claim.claimId;
    this.source = this.claim.source;
    const listofRemovedFeatures = this.claim.featuresNotAllowedForCurrentClaim;
    this.IsPTOEnable =
      this.claimConfigService.getFeatureAccess('ptoelection') &&
      listofRemovedFeatures &&
      listofRemovedFeatures.indexOf('ptoelection') < 0;

    const params = getUrlParams();
    this.focus = params.focus;

    if (
      this.claim != undefined &&
      this.claim != null &&
      this.claim.claimDescription != undefined &&
      this.claim.claimDescription != null
    ) {
      this.claim.claimDescription = this.sentencecase.transform(
        this.claim.claimDescription.toLowerCase()
      );
    }

    this.paymentTabVisible = await this.showPaymentTab();
    if (
      this.session.user.authenticationType !== 'CLM' &&
      this.session.user.authenticationType !== 'CONTROLLER' &&
      this.session.user.authenticationType !== 'EMULATEE' // will need to remove after auditor gets implemented for Hr employees
    ) {
      this.myClaims = await (<any>(
        this.claimService.getListClaimsExceptCurrent(
          this.claim.claimId,
          this.claim.source
        )
      ));
      if (this.myClaims.length >= 1) {
        this.multipleClaims = true;
      }
    }
  }

  setFocus(loaded: boolean) {
    if (loaded) {
      this.focus = this.route.snapshot.queryParamMap
        .get('focus')
        ?.toLowerCase();
      if (this.focus?.length > 0) {
        this.tabChange.emit(this.focus);
      } else {
        this.selectedIndex = 0;
      }
    }
  }

  async showPaymentTab(): Promise<boolean> {
    return this.claimConfigService.getFeatureAccess('payments');
  }

  async selectClaim(event: Event) {
    this.claimInfo = [];
    this.myClaims.forEach((value) => {
      const claim = new Claim();
      claim.claimDescription = value.claimDescription;
      claim.claimNumber = value.claimNumber;
      claim.claimId = value.claimId;
      claim.source = value.source;
      claim.lineOfBusiness = value.lineOfBusiness;
      claim.empId = value.empId;
      claim.empUnum = value.empUnum;
      claim.name = value.name;
      claim.userOwns = value.userOwns;
      this.claimInfo.push(claim);
    });

    if (this.multipleClaims) {
      this.overlayComponent.opened = true;
    }
  }
}
