import { AbstractControl, ValidationErrors } from '@angular/forms';

export function alphaValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (/^[A-Za-z ]*$/.test(value)) {
    return null;
  } else {
    return { alpha: 'Error : Letters and spaces only' };
  }
}
