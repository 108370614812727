import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import {
  CdkOverlayOrigin,
  ScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-overlay',
  template: `
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="origin"
      [cdkConnectedOverlayOpen]="opened"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
      (backdropClick)="backdropClicked()"
      (detach)="backdropClicked()"
      [cdkConnectedOverlayBackdropClass]="'app-overlay-light-backdrop'"
      [cdkConnectedOverlayFlexibleDimensions]="true"
    >
      <div
        [ngClass]="overlayClassName"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
        #overlayContent
      >
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  styles: [],
})
export class OverlayComponent extends BaseComponent {
  @Input()
  origin: CdkOverlayOrigin;

  @Input()
  opened: boolean = false;

  @Input()
  overlayClassName: string;

  @ViewChild('overlayContent') overlayContent: ElementRef;

  scrollStrategy: ScrollStrategy;

  constructor(
    scrollStrategyOptions: ScrollStrategyOptions,
    private router: Router
  ) {
    super();
    this.scrollStrategy = scrollStrategyOptions.close();
    this.subs.sink = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((_) => {
        this.opened = false;
      });
  }

  public close() {
    this.opened = false;
  }

  public open() {
    //console.log(this.origin);
    this.opened = true;
    //autocapture focus not working on history overlay due to racecondition so manually setting it on first focusable element for WCAG
    setTimeout(() => {
      const firstFocusableElement =
        this.overlayContent.nativeElement.querySelector(
          'a[href], button:not([tabindex="-1"]), input, select, textarea, .mdc-tab[role="tab"]:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'
        );
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }, 500);
  }

  public backdropClicked() {
    this.opened = false;
  }
}
