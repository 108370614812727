import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-dashboard-page',
  template: ` <app-dashboard-page> </app-dashboard-page> `,
  styles: [``],
})
export class ManagerDashboardPageComponent {
  constructor() {}
}
