import { Component } from '@angular/core';

@Component({
  selector: 'app-skip-to-main',
  template: `
    <a
      href="#main-content"
      class="skip-link white-focus-border"
      (click)="onClick($event)"
    >
      Skip to main content
    </a>
  `,
  styles: [
    `
      .skip-link {
        position: absolute;
        top: -40px;
        left: 0;
        background: #007dbc;
        color: white;
        padding: 8px;
        z-index: 100;
        transition: top 0.3s;
      }
      .skip-link:focus {
        top: 0;
      }
    `,
  ],
})
export class SkipToMainComponent {
  constructor() {}

  onFocus() {}

  onBlur() {}

  onClick(event: MouseEvent) {
    event.preventDefault();
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      const focusableElement = mainContent.querySelector(
        'a[href], button:not([tabindex="-1"]), input, select, textarea, .mdc-tab[role="tab"]:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement;
      if (focusableElement) {
        focusableElement.focus({ preventScroll: true });
      }
    }
  }
}
