export class SearchParameters {
  claimId: string | null;
  claimNumber: string | null;
  client: string | null;
  status: string[] | null;
  firstName: string | null;
  lastName: string | null;
  employeeId: string | null;
  ssn: string | null;
  ssnLastFour: string | null;
  startDate: Date | null;
  endDate: Date | null;
  openDate: Date | null;
  birthDate: Date | null;
  tag: string | null;
  lob: string[] | null;
  dsClaimType: string[] | null;
  dsClaimSubstatus: string[] | null;
  lvClaimType: string[] | null;
  lvClaimSubstatus: string[] | null;
  lvAbsenceType: string[] | null;
  lvCausedBy: string[] | null;
  managerEmpUnum: string | null;
  includeIndirectReports: boolean | null;
  excludeSupervisor: boolean | null;
  payType: string[] | null;
  alertType: string[] | null;
  includeIncidental: boolean | null = false;
}
