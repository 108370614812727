import { FormGroup } from '@angular/forms';

export function dateOrderValidator(
  startDateControlName: string,
  endDateControlName: string,
  message: string
) {
  return function (frm: FormGroup): any {
    const startDate = frm.get(startDateControlName).value;
    const endDate = frm.get(endDateControlName).value;
    const errors: any = frm.get(startDateControlName).errors ?? {};
    if (startDate && endDate && Date.parse(startDate) > Date.parse(endDate)) {
      errors.dateorder = message;
    } else {
      delete errors.dateorder;
    }

    frm
      .get(startDateControlName)
      .setErrors(Object.keys(errors).length ? errors : null);

    return null;
  };
}
