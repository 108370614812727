import { DatePipe } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogClose,
  MatDialogConfig,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseComponent,
  ProgressService,
  SearchResult,
  SnackbarService,
  SnackbarType,
  UserActionService,
  getUrlParams,
  scrollToElement,
} from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { Http } from 'src/app/shared/services/http.service';
import {
  CommCenterService,
  ConfigurationService,
  LogService,
  SendDocumentDialogComponent,
  Session,
  UserRoleService,
} from '../..';
import { DocumentRequest } from '../../models/document';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { emulator } from '../../services/emulator.service';
import { SubmittedFormsRequest } from '../../models/submittedFormsRequest';

@Component({
  selector: 'app-upload-page',
  template: `
    <ng-container *ngIf="!showSection">
      <ng-container *ngTemplateOutlet="body"></ng-container>
    </ng-container>
    <app-section class="tw-w-full" *ngIf="showSection">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        title="Documents"
        class="tw-w-full app-pb4"
        [canExpand]="false"
      >
        <app-claim-header class="tw-w-full"></app-claim-header>

        <ng-container *ngTemplateOutlet="body"></ng-container>
      </app-panel>
    </app-section>

    <ng-template #body>
      <!-- <div class="app-pt1 app-pb2">
        <b>
          Here you can
          <a (click)="scrollToElement('view')">view documents</a>,
          <a (click)="scrollToElement('sign')">electronically sign documents</a>
          or
          <a (click)="scrollToElement('upload')">upload your own documents</a>.
        </b>
      </div> -->

      <div class="tw-flex tw-w-full tw-flex-wrap">
        <div
          [class]="
            showFullMyDocAndUpload
              ? 'tw-w-full app-pr2'
              : 'tw-w-full md:tw-w-[60%] app-pr2'
          "
          *ngIf="isMyDocuments || isUploadDocuments || viewuploadeddocuments"
        >
          <div id="divMyDocumnets" *ngIf="isMyDocuments">
            <h3
              id="view"
              matBadgeColor="primary"
              matBadgeSize="medium"
              Class="app-header-3-custom app-pt2"
              matBadgeOverlap="false"
            >
              My Documents
            </h3>
            <app-loading-panel
              [skeletonLines]="5"
              [state]="loading ? 'Loading' : 'Loaded'"
            >
              <ng-container content>
                <br />
                <app-list
                  [dataSource]="documentResult"
                  [template]="rowView"
                  [paginatorEnabled]="false"
                  noDataIcon="attachment"
                  [noDataMessage]="documentMessage"
                  [tableAriaLabel]="'My documents'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full">
                      <app-list-header
                        name="fileName"
                        label="Document"
                        class="tw-w-[60%]"
                      ></app-list-header>
                      <app-list-header
                        name="created"
                        label="Date"
                        class="tw-w-[20%]"
                      ></app-list-header>
                      <app-list-header
                        name="action"
                        label="Open"
                        readOnly
                        class="tw-w-[20%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                </app-list>

                <ng-template #rowView let-element>
                  <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                    <td class="tw-w-full of-x-hid md:tw-w-[60%]">
                      <app-value label="Document" layout="row-xs">
                        <div value class="tw-flex tw-items-center">
                          <div>{{ element.fileName }}</div>
                          <span
                            *ngIf="element.readStatus !== 'y'"
                            class="newbadges"
                            >New</span
                          >
                        </div>
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[20%]" c>
                      <app-value
                        label="Date"
                        layout="row-xs"
                        [value]="element.created | format : 'date'"
                      >
                      </app-value>
                    </td>
                    <td
                      class="tw-w-full md:tw-w-[10%]"
                      label="Open"
                      layout="row-xs"
                    >
                      <div value *ngIf="!isEmulating">
                        <span
                          class="tw-w-full md:tw-hidden tw-block"
                          label="Open"
                        >
                          <a (click)="openSirUrl(element)">
                            <b>Open</b>
                          </a>
                        </span>

                        <app-icon-button
                          [size]="buttonSize"
                          icon="open_in_new"
                          (click)="openSirUrl(element)"
                          (keydown.enter)="openSirUrl(element)"
                          [attr.aria-label]="'Open file ' + element.fileName"
                          tabindex="0"
                          role="button"
                        ></app-icon-button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </ng-container>
            </app-loading-panel>
          </div>
          <div *ngIf="isUploadDocuments || viewuploadeddocuments">
            <h3
              *ngIf="viewuploadeddocuments"
              id="upload"
              matBadgeColor="primary"
              matBadgeSize="medium"
              Class="app-header-3-custom app-pt2 app-pb2"
              matBadgeOverlap="false"
            >
              Upload Documents
            </h3>
            <app-action-row>
              <app-button
                *ngIf="isUploadDocuments"
                id="fileInputBtn"
                left1
                icon="upload"
                emphasis="High"
                [loading]="running"
                (click)="openupload()"
                >Upload</app-button
              >
            </app-action-row>
            <div class="tw-w-full app-pt2 app-pb2">
              <app-list
                [dataSource]="uploadedFiles"
                [template]="rowUpload"
                class="app-pt2"
                [filterEnabled]="false"
                [paginatorEnabled]="false"
                noDataIcon="attachment"
                [noDataMessage]="uploadMessage"
                [tableAriaLabel]="'Uploaded files'"
              >
                <ng-container header>
                  <tr class="tw-flex tw-flex-wrap tw-w-full">
                    <app-list-header
                      name="fileName"
                      label="Document"
                      class="tw-w-full md:tw-w-[55%]"
                    ></app-list-header>
                    <app-list-header
                      name="created"
                      label="Date"
                      class="tw-w-full md:tw-w-[20%]"
                    >
                    </app-list-header>
                    <app-list-header
                      name="status"
                      label="Status"
                      class="tw-w-full md:tw-w-[15%]"
                    >
                    </app-list-header>
                    <app-list-header
                      name=""
                      label="Open"
                      readOnly
                      class="tw-w-full md:tw-w-[10%]"
                    ></app-list-header>
                  </tr>
                </ng-container>
              </app-list>
              <ng-template #rowUpload let-element>
                <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                  <td class="tw-w-full of-x-hid md:tw-w-[55%]">
                    <app-value
                      label="File"
                      layout="row-xs"
                      [tabindex]="0"
                      [value]="element.fileName"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[20%]">
                    <app-value
                      label="Date"
                      [tabindex]="0"
                      layout="row-xs"
                      [value]="element.created | format : 'date'"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[15%]">
                    <app-value label="Status" layout="row-xs" [tabindex]="0">
                      <div value>
                        <div
                          *ngIf="element.status === 'error'; else noError"
                          class="status status-error"
                        >
                          <mat-icon>error_outline</mat-icon
                          ><span class="app-pl1"
                            >Error: {{ element.statusMessage }}</span
                          >
                        </div>
                        <ng-template #noError>
                          <div class="status">{{ element.status }}</div>
                        </ng-template>
                        <div *ngIf="element.dcn === '' && !element.status">
                          <span>Pending</span>
                        </div>
                        <div *ngIf="element.dcn !== '' && !element.status">
                          <span>Completed</span>
                        </div>
                      </div>
                    </app-value>
                  </td>

                  <td
                    class="tw-w-full md:tw-w-[10%]"
                    label="Open"
                    layout="row-xs"
                  >
                    <div value>
                      <span
                        class="tw-w-full md:tw-hidden tw-block"
                        label="Open"
                        *ngIf="element.dcn !== '' && !isEmulating"
                      >
                        <a
                          (click)="openSirUrl(element)"
                          (keydown.enter)="openSirUrl(element)"
                        >
                          <b>Open</b>
                        </a>
                      </span>

                      <app-icon-button
                        *ngIf="element.dcn !== '' && !isEmulating"
                        [size]="buttonSize"
                        icon="open_in_new"
                        (keydown.enter)="openSirUrl(element)"
                        (click)="openSirUrl(element)"
                        role="button"
                        tabindex="0"
                        [attr.aria-label]="'Open file ' + element.fileName"
                      ></app-icon-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="tw-w-full md:tw-w-[40%]" *ngIf="isMyForms">
          <h3
            id="sign"
            matBadgeColor="primary"
            matBadgeSize="medium"
            Class="app-header-3-custom heading-popup app-pt2"
            matBadgeOverlap="false"
          >
            My Forms
          </h3>
          <app-loading-panel
            [skeletonLines]="5"
            [state]="loading ? 'Loading' : 'Loaded'"
          >
            <ng-container content>
              <br />
              <app-list
                [dataSource]="formResult"
                [template]="rowSign"
                [paginatorEnabled]="false"
                noDataIcon="edit_document"
                [noDataMessage]="formMessage"
                [tableAriaLabel]="'Forms'"
              >
                <ng-container header>
                  <tr class="tw-flex tw-flex-wrap tw-w-full">
                    <app-list-header
                      name="name"
                      label="Document"
                      class="tw-w-[80%]"
                    ></app-list-header>
                    <app-list-header
                      name="action"
                      label="Open"
                      class="tw-w-[20%]"
                    ></app-list-header>
                  </tr>
                </ng-container>
              </app-list>

              <ng-template #rowSign let-element>
                <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                  <td class="tw-w-full md:tw-w-[80%]">
                    <app-value
                      [tabindex]="0"
                      label="Document"
                      layout="row-xs"
                      [value]="element.name"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full md:tw-w-[20%]">
                    <app-value label="Actions" layout="row-xs">
                      <span value>
                        <app-icon-button
                          (click)="esign(element)"
                          (keydown.enter)="esign(element)"
                          tabindex="0"
                          role="button"
                          icon="open_in_new"
                          label="Open"
                          [size]="buttonSize"
                          *ngIf="!isEmulating"
                          [attr.aria-label]="'Esign for ' + element.fileName"
                        ></app-icon-button>
                      </span>
                    </app-value>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </app-loading-panel>
        </div>
      </div>

      <div class="tw-w-full app-pb2"></div>
    </ng-template>
    <ng-template #dialogUploadDocument>
      <app-dialog
        title="Upload Documents"
        ariaLabel="Upload documents dialog"
        [width]="750"
        [showActions]="false"
        [showHeader]="true"
        ariaLabelMessage="Close announcement popup"
      >
        <div id="divUploadDocument" *ngIf="isUploadDocuments">
          <p class="tw-w-full">
            Please upload your file below. Up to 10 files can be selected. Size
            limit is 6MB per file. The following formats are supported: .jpg,
            .png, .gif, .pdf, .rtf, .tif, .txt & .docx. Do not upload password
            protected documents as Sedgwick will not be able to open them.
          </p>

          <div class="app-pt2 app-pb1">
            <input
              type="file"
              class="file-input"
              accept=".jpg,.jpeg, .png, .gif, .pdf, .rtf, .tif, .txt, .docx"
              (change)="uploadDocuments($event)"
              (click)="fileUpload.value = null"
              #fileUpload
              multiple
              id="fileInput"
            />
            <app-action-row>
              <app-button
                id="fileInputBtn1"
                left1
                emphasis="High"
                [disabled]="disableAddFiles"
                (click)="fileUpload.click()"
                >Add Files</app-button
              >
            </app-action-row>
          </div>
          <div *ngIf="enableToastMsg">
            <br />
            <app-toast type="Success">
              {{ enableSuccessMsg }}
            </app-toast>
          </div>
          <app-loading-panel
            [skeletonLines]="5"
            [state]="loading ? 'Loading' : 'Loaded'"
          >
            <ng-container content>
              <div
                class="tw-w-full listScroll"
                *ngIf="files !== undefined && files.length > 0"
              >
                <app-list
                  [dataSource]="files"
                  [template]="rowUpload"
                  class="app-pt2"
                  [filterEnabled]="false"
                  [paginatorEnabled]="false"
                  noDataIcon="attachment"
                  [noDataMessage]="uploadMessage"
                  [tableAriaLabel]="'Uploaded files'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full">
                      <app-list-header
                        name="fileName"
                        label="Document"
                        class="tw-w-full md:tw-w-[55%]"
                      ></app-list-header>
                      <app-list-header
                        name="status"
                        label="Status"
                        class="tw-w-full md:tw-w-[35%]"
                      >
                      </app-list-header>
                      <app-list-header
                        name="action"
                        label="Remove"
                        readOnly
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                </app-list>
                <ng-template #rowUpload let-element>
                  <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                    <td class="tw-w-full of-x-hid md:tw-w-[55%]">
                      <app-value
                        label="File"
                        layout="row-xs"
                        [value]="element.fileName"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[35%]">
                      <app-value label="Status" layout="row-xs">
                        <div value>
                          <div
                            *ngIf="element.status === 'error'; else noError"
                            class="status status-error"
                          >
                            <mat-icon>error_outline</mat-icon
                            ><span class="app-pl1"
                              >Error: {{ element.statusMessage }}</span
                            >
                          </div>
                          <ng-template #noError>
                            @if(element.status){
                            <div
                              class="status"
                              *ngIf="element.status !== 'uploading'"
                              tabindex="0"
                            >
                              {{ element.status }}
                            </div>
                            } @else {
                            <span tabindex="0">{{
                              element.dcn ? 'Completed' : 'Pending'
                            }}</span>
                            }
                          </ng-template>
                        </div>
                      </app-value>
                    </td>

                    <td value>
                      <span
                        class="tw-w-full md:tw-hidden tw-block"
                        label="Remove"
                      >
                        <a (click)="removeFile(element)">
                          <b>Remove</b>
                        </a>
                      </span>
                      <app-icon-button
                        [size]="buttonSize"
                        icon="highlight_off"
                        tabindex="0"
                        role="button"
                        [attr.aria-label]="'Remove file ' + element.fileName"
                        (click)="removeFile(element)"
                        (keydown.enter)="removeFile(element)"
                        *ngIf="
                          element.newlyAdded && element.status !== 'Pending'
                        "
                      ></app-icon-button>

                      <app-icon-button
                        [size]="buttonSize"
                        tabindex="0"
                        role="button"
                        icon="open_in_new"
                        (click)="openSirUrl(element)"
                        (keydown.enter)="openSirUrl(element)"
                        [attr.aria-label]="'Open file ' + element.fileName"
                        *ngIf="element.dcn !== '' && !isEmulating"
                      ></app-icon-button>
                    </td>
                  </tr>
                </ng-template>
              </div>
            </ng-container>
          </app-loading-panel>
        </div>
        <div class="app-pt2 app-pb1">
          <app-action-row>
            <app-button
              id="fileInputBtn1"
              icon="upload"
              right2
              emphasis="High"
              (click)="UploadDocumentsOnServer()"
              [loading]="running"
              [disabled]="!enableUpdateBtn"
              >Upload</app-button
            >
            <app-button
              right1
              emphasis="Low"
              (click)="closeUpload()"
              [loading]="running"
              >Close</app-button
            >
          </app-action-row>
        </div>
      </app-dialog>
    </ng-template>
  `,
  styles: [
    `
      .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
      .of-x-hid {
        overflow-x: hidden;
      }
      .file-input {
        display: none;
      }

      .listScroll {
        overflow-y: scroll;
        height: 160px;
      }

      .status {
        display: flex;
        align-items: center;
      }

      .status-success {
        color: var(--color-green);
      }

      .status-error {
        color: var(--color-red);
      }

      .status-pending {
        color: var(--color-accent);
      }
      .status-new {
        color: var(--color-input-grey);
      }
      .newbadges {
        padding-left: 10px;
        background-color: #00364d;
        color: #fff;
        margin-left: 4px;
        border-radius: 15px;
        padding-right: 10px;
        padding-top: 1px;
        padding-bottom: 3px;
        font-size: 14px;
        font-weight: bold;
      }

      .app-header-3-custom {
        font-size: 16px;
        color: #171c20;
        font-weight: bold;
      }
      .claimListDialog {
        width: 80%;
        height: auto;
        min-height: 'calc(100vh - 90px)';
      }
    `,
  ],
})
export class UploadPageComponent extends BaseComponent implements OnInit {
  scrollToElement = scrollToElement;

  SendDocumentDialogComponent = SendDocumentDialogComponent;
  @ViewChild('dialogUploadDocument') dialogRefUploadDocument: TemplateRef<any>;

  @Input()
  showSection = true;

  @Input()
  claim: SearchResult;

  showFullMyDocAndUpload: boolean = false;
  isViewDocuments: boolean = false;
  isUploadDocuments: boolean = false;
  isMyDocuments: boolean = false;
  isMyForms: boolean = true;
  isOldUploadDisplay: boolean = false;
  myDocumentNameVal: string = 'Claim Correspondence';
  focus: string;
  files: FileUpload[] = [];
  uploadedFiles: FileUpload[] = [];
  running = false;
  claimId: string;
  source: string;
  claimantName: string;
  claimNumber: string;
  pageSize: number = 0;
  foundFile: number = 0;
  showDialog: boolean = false;
  enableUpdateBtn: boolean = false;
  doc: DocumentRequest;
  formMessage: string = '<br><strong>Loading ...</strong>';
  documentMessage: string = '<br><strong>Loading ...</strong>';
  uploadMessage: string = '<br><strong>Loading ...</strong>';
  loading: boolean = true;
  mgrAction: UserActions | null;
  isMrgViewingEmpClaim: boolean = false;

  private readonly PENDING = 'Pending';
  private readonly maxFiles = 10;
  private readonly maxSizeMB = 6;
  private readonly extensions = [
    'jpg',
    'png',
    'gif',
    'pdf',
    'rtf',
    'tif',
    'txt',
    'docx',
  ];

  documents = [
    {
      name: 'Claim Correspondence',
      action: 'some url',
      date: Date.now(),
    },
  ];

  documentResult: any[];
  formResult: any[];

  //documentsSign: Array<any> = new Array();

  readonly buttonSize = 26;

  isEmulating: boolean = false;
  viewuploadeddocuments: boolean = false;
  enableToastMsg: boolean = false;
  enableSuccessMsg: string =
    'Thank you.  A list of your uploaded document(s) for this claim will be available in the Documents tab once they have been successfully processed by the claim system.  This could take up to 24 hours.';
  disableAddFiles: boolean = false;

  constructor(
    public dialog: MatDialog,
    private claimService: ClaimService,
    private router: Router,
    private http: Http,
    private httpClient: HttpClient,
    public logService: LogService,
    public progressService: ProgressService,
    private cdr: ChangeDetectorRef,
    private config: ConfigurationService,
    private session: Session,
    private route: ActivatedRoute,
    private enumalatorService: emulator,
    public claimConfigService: ConfigurationService,
    private activityService: ActivityService,
    private commCenterService: CommCenterService,
    private snackbar: SnackbarService,
    private datePipe: DatePipe,
    private userAction: UserActionService,
    public userRole: UserRoleService
  ) {
    super();
    this.gridData();
    //this.dialogRefUploadDocument
  }

  async ngOnInit() {
    this.mgrAction = this.userAction.getAction();
    const params = getUrlParams();
    this.isMrgViewingEmpClaim =
      params.emp &&
      (this.mgrAction === UserActions.MgrViewEmpClaim ||
        this.mgrAction === UserActions.MgrViewEmpSubpage);
    setTimeout(() => {
      this.focus = this.route.snapshot.queryParamMap.get('focus');
      if (this.focus != null) {
        if (this.focus.toLowerCase() === 'documents' && this.showDialog) {
          const element: HTMLElement = document.getElementById(
            'fileInputBtn'
          ) as HTMLElement;
          if (element != null) {
            element.click();
          }
        }
      }

      this.isEmulating =
        this.session.user &&
        this.session.user?.emulatorContext &&
        this.session.user?.emulatorContext?.isEmulating;
    }, 10);
  }

  fileUploadtest(): any {
    this.enableToastMsg = false;
  }

  async gridData() {
    await this.hasDocuments();

    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.showDialog =
      params.showDialog != undefined &&
      params.showDialog.toLowerCase().trim() == 'true'
        ? true
        : false;

    this.claim = await this.http.get<SearchResult>(
      `/api/claim/${this.source}/${this.claimId}/${'NA'}/summary`
    );

    this.formResult = await this.claimService.getOnlineForm(this.claim);

    if (!this.config.getFeatureAccess('CAKaiser')) {
      this.formResult = this.formResult.filter(function (_) {
        return !_.name.toLowerCase().includes('ca kaiser');
      });
    }
    if (!this.config.getFeatureAccess('HIKaiser')) {
      this.formResult = this.formResult.filter(function (_) {
        return !_.name.toLowerCase().includes('hi kaiser');
      });
    }
    if (!this.config.getFeatureAccess('MedicalAuthorization')) {
      this.formResult = this.formResult.filter(function (_) {
        return !_.name.toLowerCase().includes('medical authorization');
      });
    }
    if (!this.config.getFeatureAccess('MedicareConsent')) {
      this.formResult = this.formResult.filter(function (_) {
        return !_.name.toLowerCase().includes('medicare consent');
      });
    }
    if (!this.config.getFeatureAccess('MedicareBeneficiary')) {
      this.formResult = this.formResult.filter(function (_) {
        return !_.name.toLowerCase().includes('medicare beneficiary');
      });
    }

    this.uploadedFiles = await this.claimService.getDocuments(this.claim);
    this.documentResult = this.uploadedFiles.filter(
      (f) => f.fileName.trim() == 'Claim Correspondence'
    );
    this.uploadedFiles = this.uploadedFiles.filter(
      (f) => f.fileName.trim() != 'Claim Correspondence'
    );
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      if (this.uploadedFiles[i].dcn.trim() == '') {
        this.uploadedFiles[i].status = this.PENDING;
      }
      if (this.uploadedFiles[i].dcn.trim() != '') {
        this.uploadedFiles[i].status = 'Completed';
      }
    }

    if (this.formResult.length == 0) {
      this.formMessage =
        '<br><strong>No forms available for this claim.</strong>';
    }
    if (this.documentResult.length == 0) {
      this.documentMessage =
        '<br><strong>No documents available for this claim.</strong>';
    }
    if (this.uploadedFiles.length == 0) {
      this.uploadMessage =
        '<br><strong>No documents uploaded for this claim.</strong>' +
        '\n' +
        ' Uploaded documents can take up to 24 hours to display.';
    }

    this.loading = false;
  }

  pageChange() {
    this.pageSize = 2;
  }

  removeFile(fileUpload: FileUpload) {
    this.files.splice(this.files.indexOf(fileUpload), 1);
    this.files = [...this.files];
    //this.enableUpdateBtn = this.files.length > 0;
    this.enableUpdateBtn =
    this.files.findIndex((x) => x.status == 'uploading') > -1;

  }

  removeFileUploaded(fileUpload: FileUpload) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(fileUpload), 1);
    this.uploadedFiles = [...this.uploadedFiles];
  }

  removeAll() {
    this.files = [];
  }

  removeAllExisting() {
    this.uploadedFiles = [];
  }

  async uploadDocuments(event: any) {
    this.enableUpdateBtn = false;
    // this.running = true;
    let count = 1;
    // step 1 - read the selected files and validate file properties
    for (const file of event.target.files) {
      const fileUpload = new FileUpload();
      fileUpload.file = file;
      fileUpload.status = 'uploading';
      fileUpload.fileName = file.name;
      fileUpload.created = new Date().toString();
      fileUpload.dcn = '';
      fileUpload.newlyAdded = true;
      const parts = file.name.split('.');
      const ext = parts[parts.length - 1].toLowerCase();
      // validate file size, file count, file extension and duplicates
      // would be great to validate magic here.
      if (count > this.maxFiles) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Maximum file count is ${this.maxFiles}`;
      } else if (file.size > this.maxSizeMB * 1000000) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Maximum file size is ${this.maxSizeMB} MB`;
      } else if (this.files.find((f) => f.fileName === file.name)) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Duplicate file found.`;
      } else if (!this.extensions.find((x) => x === ext)) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Unsupported file type.`;
      }
      count++;
      if (this.enableToastMsg) {
        this.enableToastMsg = false;
      }
      this.files = [...this.files, fileUpload];
    }

    if (this.files.length > 0) {
      this.enableUpdateBtn =
        this.files.findIndex((x) => x.status == 'uploading') > -1;
    }
  }

  async UploadDocumentsOnServer() {
    this.running = false;
    // step 2 - upload files to back end.
    let i = 0;

    this.enumalatorService.showAuditorInfo();
    for (const file of this.files) {
      // once a status is assigned, no further upload is permitted.
      if (file.status !== 'uploading') {
        continue;
      }

      // the upload http call goes here
      const formData = new FormData();
      formData.append('file0', file.file, file.file.name);
      this.LogFeature();
      this.subs.sink = this.httpClient
        .post(
          `/api/claim/${this.source}/${this.claimId}/${this.claim.lob}/${
            this.claim.vendorId
          }/${this.claim.procUnit}/${this.claim.claimNumber}/${
            this.claim.empUnum ? this.claim.empUnum : null
          }/UploadFile`,

          formData,
          { reportProgress: true, observe: 'events' }
        )
        .subscribe({
          next: (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              file.progress = Math.round((100 * event.loaded) / event.total);
              if (file.progress === 100) {
                file.status = 'processing';
                this.disableAddFiles = true;
              } else {
                file.status = 'uploading';
              }
            } else if (event.type === HttpEventType.Response) {
              if (event.body) {
                i--;
                const conf: FileUploadConfirmation =
                  event.body as FileUploadConfirmation;
                if (conf.code === 'Success') {
                  file.status = this.PENDING;
                  this.UpdateButtons();
                  this.enableSuccessMsg =
                    'Thank you.  A list of your uploaded document(s) for this claim will be available in the Documents tab once they have been successfully processed by the claim system.  This could take up to 24 hours.';
                  const fileToPushToList = file;
                  fileToPushToList.newlyAdded = false;
                  //this.uploadedFiles.push(fileToPushToList); we are not showing Pending files in the list so removing this
                  //this.cdr.detectChanges();
                  //this.cdr.markForCheck();
                } else {
                  file.status = 'error';
                  if (this.enableToastMsg) {
                    this.enableToastMsg = false;
                  }
                  file.statusMessage =
                    conf.statusDescription ?? 'Password protected file found.';
                  this.disableAddFiles = false;
                  this.enableUpdateBtn = false;
                }
                if (i == 0) {
                  this.progressService.hide();
                  this.running = false;
                  for (let i = 0; i < this.files.length; i++) {
                    if (
                      this.files[i].status == 'error' &&
                      this.files[i].statusMessage
                        .toLowerCase()
                        .indexOf('maximum file count') >= 0 &&
                      this.getPendingFiles().length < this.maxFiles
                    ) {
                      this.files[i].status = 'error';
                      this.files[i].statusMessage =
                        'Maximum file count is ' + this.maxFiles;
                      if (this.enableToastMsg) {
                        this.enableToastMsg = false;
                      }
                      this.enableUpdateBtn = false;
                      this.render();
                    }
                  }

                  // debugger;
                  this.activityService.DismissNotificationAlert(
                    'MEDDOC_NEEDED_A',
                    this.claim.claimNumber,
                    this.claim.claimId,
                    this.claim.source,
                    this.claim.userOwns
                  );
                  this.enableUpdateBtn =
                    this.files.findIndex((x) => x.status == 'uploading') > -1;
                  this.render();
                }
              }
            }
          },
          error: (err: HttpErrorResponse) => {
            this.disableAddFiles = false;
            this.enableUpdateBtn = false;
            console.error('Error returned from upload post', err);
            file.status = 'error';
            file.statusMessage = 'Please login again and try again.';
          },
          // Removing auto-closure based on US 216823
          // complete: () => {
          //   // this.closeUpload();
          // },
        });
      await new Promise((r) => setTimeout(r, 1000));

      i++;
      // set status to pending if the server has accepted the file.
      // The status will change to complete once the document is loaded into SIR.
      file.status =
        i == 0 ? this.PENDING : i == 1 ? this.PENDING : 'Processing';
      if (file.status == 'error') {
        this.enableUpdateBtn = false;
        file.statusMessage = 'Message from backend';
      }
      if (file.status == this.PENDING) {
        if (!this.enableToastMsg) {
          this.UpdateButtons();
        }
      }
    }

    this.running = false;
  }

  UpdateButtons() {
    if (this.files.filter((_) => _.status !== this.PENDING && _.status !== "error").length === 0) {
      this.enableToastMsg = true;
      this.disableAddFiles = false;
    }

    if (this.files.filter((_) => _.status !== 'processing').length > 0) {
      this.enableUpdateBtn = false;
      this.disableAddFiles = false;
    }

    if (this.files.filter((_) => _.status === 'processing').length > 0) {
      this.disableAddFiles = true;
    }
  }

  LogFeature() {
    this.logService.LogFeature(
      this.claim,
      'UploadDocuments',
      'Actvity - Upload'
    );
  }

  getPendingFiles = (): FileUpload[] => {
    return this.files.filter((f) => f.status === this.PENDING);
  };

  render() {
    //this.cdr.detach();
    this.cdr.detectChanges();

    this.cdr.markForCheck();

    //this.uploadTable.updateDataSource();
  }

  async hasDocuments() {
    this.isMyDocuments =
      (await this.claimConfigService.getFeatureAccess('mydocuments')) &&
      !this.isMrgViewingEmpClaim;

    this.isMyForms = this.claimConfigService.getFeatureAccess('MyForm');

    this.isUploadDocuments =
      this.claimConfigService.getFeatureAccess('UploadDocuments');
    if (!this.isMyForms) {
      this.showFullMyDocAndUpload = true;
    }

    this.viewuploadeddocuments = this.claimConfigService.getFeatureAccess(
      'viewuploadeddocuments'
    );
  }

  closeUpload() {
    if (this.files != undefined && this.files.length > 0) {
      this.files = this.files.filter(function (ele) {
        return !(ele.status == 'uploading' || ele.status == 'error');
      });
    }
    this.render();
    this.uploadedFiles = [...this.uploadedFiles];
    this.enableToastMsg = false;
    this.disableAddFiles = false;
    this.dialog.closeAll();
  }

  markDocumentRead(doc: any): boolean {
    this.doc = {
      userId: this.session.user.userId,
      documentOwner: this.session.user.userName,
      dcn: doc.dcn,
      contNum: this.session.user.contNum,
      documentRead: 'y',
      documentUrl: null,
      sacStatusHeaderUid: doc.sacHeaderUID,
    };
    const path = '/api/claim/mark-as-read';
    try {
      this.claimService.markAsRead(path, this.doc);
      return true;
    } catch (error) {
      return false;
    }
  }

  async openSirUrl(element: any) {
    window.open(`/api/auth/Saml/${element.dcn}/ViewSirDocument`, '_blank');
  }

  async esign(element: any) {
    const warnings: string[] = [];
    const request = new SubmittedFormsRequest();
    request.source = this.claim?.source;
    request.claimId = this.claim?.claimId;
    request.claimNumber = this.claim?.claimNumber;
    request.formType = element.type;
    request.vendorID = this.claim?.vendorId ? this.claim?.vendorId : '0';
    request.officeNum = this.claim?.procUnit;
    const submittedForms = await this.claimService.getSubmittedForms(
      this.claim?.source,
      this.claim?.claimId,
      this.claim?.lob,
      request
    );
    if (submittedForms) {
      const roiDate = submittedForms.responseText.filter(
        (_) => _.key === 'ReleaseOfInformation.ROIDate'
      );
      if (roiDate.length && roiDate[0].value?.length) {
        warnings.push(
          `You have already submitted the ${element.name} form ${roiDate[0].value}. No further action is required.`
        );
      }
    }
    if (warnings.length) {
      this.snackbar.show(warnings[0], SnackbarType.Error);
    } else {
      this.router.navigate(['/e-sign'], {
        queryParams: {
          flNum: element.doc,
          form: element.form,
          type: element.type,
          name: element.name,
          downloadOnly: element.downloadOnly,
          lossState: this.claim.lossState,
          doc: element.doc,
          source: this.source,
          claimId: this.claimId,
        },
      });
    }
  }

  openupload() {
    /*if(this.files != undefined && this.files.length > 0)
    {
      this.files = this.files.filter(function(ele){
      return !(ele.status == 'uploading' || ele.status == 'error') ;
      });
    }*/
    this.enableToastMsg = false;
    this.files = [];
    this.enableUpdateBtn = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Upload Documents',
      panelClass: 'claimListDialog',
    };
    const dlg = this.dialog.open(this.dialogRefUploadDocument, dialogConfig);
    //dlg.addPanelClass('app-dlg-padding');
    dlg.updateSize('750px');
  }
}

export class FileUpload {
  fileName: string;
  file?: File;
  status?: string;
  statusMessage?: string;
  date?: Date;
  progress?: number;
  dcn: string;
  created: string;
  sACHeaderUID: string;
  readStatus: string;
  newlyAdded?: boolean;
}

export interface FileUploadConfirmation {
  code: string;
  confirmationNumber: string;
  statusDescription: string;
}
