import { Component, Input, OnInit } from '@angular/core';
import { SearchResult } from 'src/app/shared';
import { ClaimService, WorkSchedule } from '../..';

@Component({
  selector: 'app-work-schedule',
  template: `
    <app-loading-panel [state]="!isLoaded ? 'Loading' : 'Loaded'">
      <ng-template #content>
        <div
          *ngFor="let week of workSchedule?.workWeeks"
          class="border-left-none"
        >
          <div class="app-pt1 tw-w-full tw-justify-items-center tw-w-full">
            <app-value
              layout="row"
              label="Effective Date"
              value="{{
                claim.source === 'T'
                  ? (claim.dateFirstAbsent | format : 'date')
                  : (week.startDate | format : 'date')
              }}"
            ></app-value>
          </div>
          <div class="tw-font-bold tw-text-center">
            WEEK {{ week.weekNumber }} HOURS: TOTAL
            {{
              week.sundayMinutes +
                week.mondayMinutes +
                week.tuesdayMinutes +
                week.wednesdayMinutes +
                week.thursdayMinutes +
                week.fridayMinutes +
                week.saturdayMinutes
            }}
          </div>
          <div class="tw-flex tw-justify-center">
            <div class="tw-w-full md:tw-w-[70%]  bdr-all">
              <div class="tw-grid tw-grid-cols-7">
                <div class="header">Sun</div>
                <div class="header">Mon</div>
                <div class="header">Tues</div>
                <div class="header">Wed</div>
                <div class="header">Thurs</div>
                <div class="header">Fri</div>
                <div class="header">Sat</div>
              </div>
              <div class="tw-grid tw-grid-cols-7">
                <div class="day">
                  {{ week.sundayMinutes }}
                </div>
                <div class="day">
                  {{ week.mondayMinutes }}
                </div>
                <div class="day">
                  {{ week.tuesdayMinutes }}
                </div>
                <div class="day">
                  {{ week.wednesdayMinutes }}
                </div>
                <div class="day">
                  {{ week.thursdayMinutes }}
                </div>
                <div class="day">
                  {{ week.fridayMinutes }}
                </div>
                <div class="day">
                  {{ week.saturdayMinutes }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .header {
        color: white;
        background-color: var(--color-sedgwick-green);
        font-weight: bold;
        text-align: center;
        padding: 2px 0px 2px 0px;
        font-size: 12px;
        border: 1px solid black;
      }

      .day {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding: 8px 0px 8px 0px;
        border: 1px solid black;
        color: var(--color-sedgwick-green);
      }

      .label {
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        padding: 8px 8px 8px 0px;
        -border: 1px solid var(--color-light-grey);
      }

      .border-left-none {
        &:not(:first:child) {
          border-left: none;
        }
      }
      .bdr-all {
        border: 1px solid black;
      }
    `,
  ],
})
export class WorkScheduleComponent implements OnInit {
  @Input()
  claim: SearchResult;

  isLoaded: boolean = false;
  workSchedule: WorkSchedule;

  constructor(private claimService: ClaimService) {}
  async ngOnInit() {
    await this.getWorkScheduleInfo();
    this.isLoaded = true;
  }

  async getWorkScheduleInfo() {
    this.workSchedule = await this.claimService.getWorkScheduleInfo(
      this.claim.source,
      this.claim.claimId,
      this.claim.lob,
      this.claim.claimNumber.replace(/-|\s/g, '') //temp replace if claimNumber is messed up
    );
  }
}
