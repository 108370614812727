import { Component, Input, OnInit } from '@angular/core';
import { InpageAliasService, OmniDashboard } from 'src/app/my-sedgwick';
import { BaseComponent } from 'src/app/shared';

@Component({
  selector: 'app-off-work-card',
  template: `
    <app-panel
      [border]="'Empty'"
      class="tw-cursor-pointer"
      routerLink="/summary/off-work"
      [canExpand]="false"
      tabindex="-1"
    >
      <div
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white app-bg-color-sedgwick-blue container"
      >
        <app-loading-panel
          [state]="omniDashboard ? 'Loaded' : 'Loading'"
          loadingStyle="DotWhite"
          [error]="error"
        >
          <ng-template #content>
            <div class="tw-flex tw-flex-col tw-items-center">
              <h2 class="tw-text-[26px] app-mt8 tw-font-light" role="heading">
                {{ employeeAlias | titlecase }} off work
              </h2>
              <div class="tw-text-[140px] tw-font-bold tw-leading-none">
                {{ omniDashboard.employeesOffWork }}
              </div>
              <div class="tw-text-[13px] tw-leading-none tw-font-light"></div>
              <div class="app-mb5 app-pt4">
                <app-button
                  emphasis="Low"
                  arialabel="View details of employees off work"
                  [role]="'link'"
                >
                  View Details
                </app-button>
              </div>
            </div>
          </ng-template>
        </app-loading-panel>
      </div>
    </app-panel>
  `,
  styles: [
    `
      .container {
        height: 425px;
      }
    `,
  ],
})
export class OffWorkCardComponent extends BaseComponent implements OnInit {
  @Input()
  omniDashboard: OmniDashboard;

  @Input()
  error: any;

  employeeAlias: string;

  constructor(private aliases: InpageAliasService) {
    super();
  }

  async ngOnInit() {
    this.employeeAlias = await this.aliases.get('employeePlural');
  }
}
