export class LogMessage {
  message: string = '';
  logType: string = '';
  elapsedMs: number = 0.0;
  machineName: string = '';
  threadId: string = '';
  requestId: string = '';
  logCategory: string = '';
  identifier: string = '';
  sessionId: string = '';
  processName: string = '';
  processId: string = '';
}
