import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MyClaim, UserActionService } from 'src/app/shared';
import { IntakeService } from '../../services/intake.service';
import { LogService } from '../../services/log.service';
import { DisplayService } from '../../services/display-service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-claim-list-report-absence',
  template: `
    <div>
      <ng-container *ngIf="isBusy">
        <mat-spinner
          color="accent"
          diameter="40"
          mode="indeterminate"
        ></mat-spinner>
      </ng-container>

      <app-dialog-container
        [headerId]="'confirmAbsence'"
        label="Confirm absence"
        [headerLevel]="2"
        class="container dialog-container"
        aria-labelledby="'confirmAbsence'"
        [showHeader]="true"
        [twoColHeader]="true"
      >
        <div>
          <div class="tw-w-full">
            <div>
              <span
                >Are you reporting an absence related to any of the following
                open claims?</span
              >
            </div>
            <div>
              <span
                >If yes, click on the absence reporting icon next to the
                appropriate claim number to view the form.</span
              >
            </div>
            <div *ngIf="showContinue">
              <div>
                <span
                  >If no, click the Continue button to proceed with reporting a
                  claim in a new tab (or window). Ensure that any Popup Blockers
                  are disabled. Refer to browser or device documentation for
                  more information.</span
                >
              </div>
            </div>
            <div *ngIf="!showContinue">
              <div>
                <span
                  >If no, click the back button to return to the previous
                  screen.</span
                >
              </div>
            </div>
          </div>

          <app-list
            class="tw-w-full"
            [template]="row"
            [dataSource]="filteredClaims"
            #iLeaveClaims
            [tableAriaLabel]="'Absences'"
          >
            <ng-container header>
              <tr class="tw-flex tw-flex-wrap tw-w-full">
                <app-list-header
                  name="dateFirstAbsent"
                  label="Leave Begin"
                  class="tw-w-full md:tw-w-[20%]"
                ></app-list-header>
                <app-list-header
                  name="claimType"
                  label="Type"
                  class="tw-w-full md:tw-w-[25%]"
                ></app-list-header>
                <app-list-header
                  name="claimNumber"
                  label="Claim Number"
                  class="tw-w-full md:tw-w-[40%]"
                ></app-list-header>
                <app-list-header
                  name="reportAnAbsence"
                  label="Report an Absence"
                  class="tw-w-full md:tw-w-[15%]"
                  [sortEnabled]="false"
                ></app-list-header>
              </tr>
            </ng-container>

            <ng-template #row let-element>
              <tr
                class="app-pt2 app-pb2 tw-flex tw-flex-wrap tw-w-full tw-items-center"
              >
                <td class="tw-w-full md:tw-w-[20%]">
                  <app-value
                    label="Leave Begin"
                    [value]="element.dateFirstAbsent | format : 'date'"
                    [tabIndex]="0"
                    layout="row-xs"
                  ></app-value>
                </td>
                <td class="tw-w-full md:tw-w-[25%]">
                  <app-value
                    label="Duration"
                    [value]="element.claimType"
                    [tabIndex]="0"
                    layout="row-xs"
                  ></app-value>
                </td>
                <td class="tw-w-full md:tw-w-[40%]">
                  <app-value label="Type" layout="row-xs" [tabIndex]="0">
                    <div
                      class="colorswblue-claimtype"
                      value
                      (keydown.enter)="goToClaim(element)"
                      (click)="goToClaim(element)"
                    >
                      {{ element.claimDescription }} - {{ element.claimNumber }}
                    </div>
                  </app-value>
                </td>

                <td class="tw-w-full md:tw-w-[15%]">
                  <app-value label="Report Absence" layout="row-xs">
                    <div value class="colorswblue">
                      <span
                        ><app-icon-button
                          mat-icon-button
                          color="accent"
                          icon="edit"
                          tabindex="0"
                          role="button"
                          altText="Report an absence"
                          [attr.aria-label]="
                            'Report an Absence for claim - ' +
                            element.claimDescription +
                            ' - ' +
                            element.claimNumber
                          "
                          (click)="goToReportAbsence(element)"
                          (keydown.enter)="goToReportAbsence(element)"
                        ></app-icon-button>
                      </span>
                      <span
                        ><a
                          class="tw-block md:tw-hidden"
                          (click)="goToReportAbsence(element)"
                          tabindex="0"
                        >
                          <b class="tw-text-[16px]">edit</b>
                        </a></span
                      >
                    </div>
                  </app-value>
                </td>
              </tr></ng-template
            >
          </app-list>
        </div>
        <app-action-row class="tw-pt-4 ">
          <app-button
            *ngIf="redirectToSmartly"
            right2
            emphasis="High"
            matDialogClose
            (click)="confirm()"
            >Continue</app-button
          >
          <app-button right1 emphasis="Low" matDialogClose>Back</app-button>
        </app-action-row>
      </app-dialog-container>
    </div>
  `,
  styles: [
    `
      .titleheading {
        color: #171c20 !important;
      }
      .close.mat-button {
        float: right;
        position: relative;
        color: #67707a;
      }
      .modal-body {
        height: 100%;
        display: inline-table !important;
      }
      .pad-right-5 {
        padding-right: 2em;
      }
      .pad-top-2 {
        padding-top: 2em;
      }
      .closepnl {
        float: left;
        text-align: right;
      }
      .continuebtn {
        background-color: #007dbc !important;
        color: #fff;
      }
      .continuebtn:hover {
        background-color: #035e81 !important;
      }
      .closebtn {
        background-color: #ffffff !important;
        color: #3f464d;
        border: 1px solid #3f464d;
      }
      .closebtn:hover {
        background-color: #f1f4f7 !important;
      }
      .roundbtn {
        border-radius: 24px;
      }
      .colorswblue {
        color: var(--color-sedgwick-blue) !important;
        margin-top: 0px;
        padding-bottom: 20px;
      }

      .colorswblue-claimtype {
        color: var(--color-sedgwick-blue) !important;
        margin-top: 0px;
      }

      .colorswblue:hover {
        color: #035e81;
        text-decoration: solid underline #035e81 2px;
        cursor: pointer;
      }

      .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
      .header {
        flex-shrink: 0;
      }
      .scroller {
        flex-grow: 1;
        overflow: auto;
        min-height: 2em;
      }
      @media (max-width: 667px) {
        ::ng-deep .tw-pr-2 {
          padding-right: 0px;
        }
      }
    `,
  ],
})
export class ClaimListReportAbsenceComponent implements OnInit {
  @Input()
  claims: MyClaim[];

  @Input()
  showContinue: boolean = false;

  @Output() confirmEvent = new EventEmitter();

  @Input()
  title: string = 'Confirmation';

  @Input()
  redirectToSmartly: boolean;

  @ViewChild('reportClaim')
  reportClaim: TemplateRef<any>;

  isBusy: boolean = false;

  filteredClaims: MyClaim[];

  public constructor(
    private router: Router,
    private intakeService: IntakeService,
    public logService: LogService,
    public displayService: DisplayService,
    public dialog: MatDialog,
    private userAction: UserActionService
  ) {}

  async ngOnInit() {
    this.getClaims();
  }

  getClaims() {
    const temp: MyClaim[] = [];
    this.claims.forEach((element) => {
      if (
        element.status === 'Open' &&
        element.lineOfBusiness === 'LV' &&
        element.claimDescription.toLowerCase().indexOf('intermittent') > -1
      ) {
        temp.push(element);
      }
    });
    this.filteredClaims = temp;
  }

  goToReportAbsence(element: MyClaim) {
    this.userAction.setClaimant(element);
    //Rout to the Report Absence page - URL change
    this.router.navigate(['/reportanabsence'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
      },
    });
    this.dialog.closeAll();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goToReportNewClaim() {}

  async confirm() {
    this.logService.LogFeature(
      undefined,
      'ReportNewClaim',
      'Navigation' + ' - ' + 'Report New Claim'
    );
    this.isBusy = true;
    const response = await this.intakeService.getSmartlyUrl();
    window.open(response.url, '_blank');
  }

  goToClaim(element: MyClaim) {
    this.userAction.setClaimant(element);
    this.router.navigate(['/claims'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        lob: element.lineOfBusiness,
      },
    });
    this.dialog.closeAll();
  }
}
