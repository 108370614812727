import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appOverflow]',
})
export class OverflowDirective implements OnInit {
  @Input()
  overflowHeight: string;

  @Input()
  overflowScrollBottom = false;

  constructor(public element: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    if (this.overflowHeight) {
      const el = this.element.nativeElement;
      el.style.overflowY = 'auto';
      el.style.maxHeight = this.overflowHeight;
      setTimeout(() => {
        if (this.overflowScrollBottom) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 0);
    }
  }
}
