/**
 * scrolls to the element's id. Handles angular's sidenav content scrollbar.
 */
export function scrollToElement(
  elementId: string,
  scrollTop: boolean = false,
  margin: number = 0
) {
  const el = document.getElementById(elementId);
  if (scrollTop) {
    document.querySelector('.mat-sidenav-content').scrollTo({
      top: 0,
      behavior: 'auto',
    });
    return;
  }
  if (!el) {
    return;
  }
  const wins = document.getElementsByTagName('mat-sidenav-content');
  if (margin && wins) {
    const win = wins[0];
    win.scroll(0, 0);
    const rect = el.getBoundingClientRect();
    win.scrollTo({
      top: rect.top - margin,
      left: 0,
      behavior: 'smooth',
    });
  } else {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  // const rect = el.getBoundingClientRect();
  // const y = rect.top + window.scrollY-80;
  // alert(y);
  // const isVisible = isElementInViewport(rect);
  // if (!isVisible) {
  //   document.querySelector('.mat-sidenav-content').scrollTo({
  //     top: y,
  //     behavior: 'smooth',
  //   });
  // }
}

function isElementInViewport(rect: DOMRect) {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top + 100 <=
      (window.innerHeight || document.documentElement.clientHeight)
  );
}
