import { Component, Input, Renderer2 } from '@angular/core';
import { Session } from '../..';
import { LearningCenterLiabilityBaseComponent } from '../learning-center/learning-center-liability-base.component';
import { Router } from '@angular/router';
import { DynamicEventBindService } from '../../services/bind-dynamic-events.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-learning-center-pr',
  template:
    '<app-learning-center-liability-base [learningCenter] = learningCenter  [isMgr] = isMgr [lcType]=lcType [loading] = loading lobFullName="Property Liability"/>',
})
export class LearningCenterPrComponent extends LearningCenterLiabilityBaseComponent {
  @Input()
  override isMgr: boolean;

  @Input()
  override learningCenter: any;

  @Input()
  override loading: boolean;

  @Input()
  override lcType: string;

  constructor(
    session: Session,
    router: Router,
    bindEvents: DynamicEventBindService,
    claimConfigService: ConfigurationService
  ) {
    super(session, router, bindEvents, claimConfigService);
  }
}
