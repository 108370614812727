import { Injectable } from '@angular/core';
import { isBoolean } from 'lodash';
import { UtilityService } from 'src/app/my-sedgwick/services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private utility: UtilityService) {}
  download(csvParams: CsvParams) {
    const rows = csvParams.rows;

    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    var headerData = '';
    if (
      csvParams.headerNote != undefined &&
      csvParams.headerNote != null &&
      csvParams.headerNote.length > 1
    ) {
      for (var k = 0; k < csvParams.headerNote.length; k++) {
        headerData = headerData + csvParams.headerNote[k] + '\n';
      }

      for (var t = 0; t < csvParams.headerNoteBefore; t++) {
        headerData = headerData + '\n';
      }
    }

    let csvContent =
      csvParams.headers.join(separator) +
      '\n' +
      rows
        .map((row: any) => {
          return csvParams.keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              /*cell =
                cell instanceof Date
                  ? cell.toLocaleString():
                  isBoolean(cell)
                  ? cell == true ? 'Yes' : 'No':
                  cell instanceof Object ?
                  row[k].street1? row[k].street1 : '' + " "  + row[k].street2? row[k].street2 : '' +  row[k].city? row[k].city : '' +  row[k].state? row[k].state : ''+  row[k].zipCode? row[k].zipCode : ''
                  : cell.toString().replace(/"/g, '""');*/
              if (
                isNaN(cell) &&
                !isNaN(Date.parse(cell)) &&
                !cell.includes('Restriction') // "Restriction n" parces as a valid date but we dont want these as date
              ) {
                if (csvParams.includeTimeWithDates === true) {
                  cell = new Date(cell).toLocaleTimeString([], {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  });
                } else {
                  cell = new Date(cell).toLocaleDateString();
                }
              } else if (isBoolean(cell)) {
                cell = cell == true ? 'Yes' : 'No';
              } else if (k.includes('.')) {
                let objList = k.split('.');
                cell = row[objList[0]][objList[1]];
              } else {
                cell = cell.toString().replace(/"/g, '""');
                //cell = "\'" + cell;
              }
              if (cell == undefined || cell == null) {
                cell = '';
              } else if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              } else if (!isNaN(cell)) {
                cell = '="' + cell + '"';
                //cell = '"' + cell + '"';
              }

              return cell;
            })
            .join(separator);
        })
        .join('\n');

    if (
      csvParams.footerNote != undefined &&
      csvParams.footerNote != null &&
      csvParams.footerNote.length > 1
    ) {
      for (var i = 0; i < csvParams.footerAfterRows; i++) {
        for (var j = 0; j < csvParams.headers.length; j++) {
          csvContent = csvContent + separator;
        }
        csvContent = csvContent + '\n';
      }
      csvContent = csvContent + csvParams.footerNote;
    }

    if (
      csvParams.headerNote != undefined &&
      csvParams.headerNote != null &&
      csvParams.headerNote.length > 1
    ) {
      csvContent = headerData + csvContent;
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    this.utility.downloadFile(blob, csvParams.fileName);
  }
}

export class CsvParams {
  /**
   * Property names on rowData objects. Keys are written in order to csv.
   */
  keys: string[];
  /**
   * Header labels for keys. Labels are written in order to csv.
   */
  headers: string[];
  /**
   * Array of objects to be written to csv file
   */
  rows: any[];
  /**
   * The csv file name
   */
  fileName: string = 'export.csv';
  /**
   * Indicator to include time with Date values
   */
  includeTimeWithDates: boolean = false;

  footerAfterRows: number = 5;

  footerNote: string = '';

  headerNote: any[];
  headerNoteBefore: number = 3;
}
