import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help-button',
  template: `
    <button
      tabindex="0"
      role="button"
      aria-label="Open Help Information"
      [attr.aria-describedby]="id"
      (click)="detailOverlay.open()"
      (keyup.enter)="detailOverlay.open()"
      [attr.aria-expanded]="detailOverlay.opened ? 'true' : 'false'"
    >
      <mat-icon
        class="help-icon"
        cdkOverlayOrigin
        [style.width]="size + 'px'"
        [style.height]="size + 'px'"
        [style.font-size]="size + 'px'"
        #detailOrigin="cdkOverlayOrigin"
        >help_outline</mat-icon
      >
    </button>

    <app-overlay [origin]="detailOrigin" #detailOverlay>
      <app-panel
        [showTitle]="false"
        *ngIf="detailOverlay.opened"
        [canExpand]="false"
      >
        <div
          role="tooltip"
          [id]="id"
          class="app-pt1 tw-pb-2 message-container"
          [innerHTML]="message"
        ></div>
      </app-panel>
    </app-overlay>
  `,
  styles: [
    `
      .message-container {
        max-width: 300px;
        font-size: 13px;
      }
      .help-icon {
        margin-left: 4px;
        color: var(--color-input-grey);
        cursor: pointer;
        vertical-align: middle;
      }
    `,
  ],
})
export class HelpButtonComponent {
  @Input()
  size: number = 18;

  @Input()
  message: string = 'TODO: provide message';

  @Input()
  id: string = 'tooltipId';
}
