import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseComponent, MessageBus } from 'src/app/shared';

@Component({
  selector: 'app-sticky-menu',
  template: `<div class="tw-w-full">
    <div [style.padding-top.px]="padding"></div>
    <div #anchorMenuContent class="headingbold">
      <ng-content> </ng-content>
    </div>
  </div>`,
  styles: [],
})
export class StickyMenuComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  padding: number = 0;

  offset: number = 0;

  @ViewChild('anchorMenuContent') menuContent: ElementRef;

  constructor(private messageBus: MessageBus) {
    super();
  }

  ngOnInit(): void {
    // get initial offet of content

    this.subs.sink = this.messageBus
      .topic('layout:scroll')
      //.pipe(auditTime(0))
      .subscribe((event) => {
        if (event.target.scrollTop > this.offset - 40) {
          this.padding = event.target.scrollTop - this.offset + 40;
        } else {
          this.padding = 0;
        }
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const rect = this.menuContent.nativeElement.getBoundingClientRect();
      this.offset = rect.y - 80;
    }, 0);
  }
}
