import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { BaseComponent } from '../components/base.component';

@Injectable({
  providedIn: 'root',
})
export class RouterService extends BaseComponent {
  previousUrl: string;

  currentUrl: string;

  rootUrl: string;

  rootDescription: string;

  _root: Root;

  get root(): Root {
    return this._root;
  }

  constructor(private router: Router) {
    super();
    this.subs.sink = this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
      });

    const root = sessionStorage.getItem('router-root');
    if (
      root === 'home' ||
      root === 'search' ||
      root === 'recent-claims' ||
      root === 'my-claims' ||
      root === 'watch-list' ||
      root === 'manager-home'
    ) {
      this._root = root;
    } else {
      this._root = 'my-claims';
    }
    this.setRoot(this._root);
  }

  setRoot(root: Root) {
    this._root = root;
    if (root === 'search') {
      this.rootUrl = '/search';
      this.rootDescription = 'search';
    } else if (root === 'watch-list') {
      this.rootUrl = '/watchlist';
      this.rootDescription = 'watch list';
    } else if (root === 'recent-claims') {
      this.rootUrl = '/history';
      this.rootDescription = 'history';
    } else if (root === 'my-claims') {
      this.rootUrl = '/dashboard';
      this.rootDescription = 'my claims';
    } else if (root === 'manager-home') {
      this.rootUrl = '/supervisor';
      this.rootDescription = 'Manager View';
    } else if (root === 'summary-view') {
      this.rootUrl = '/summary';
      this.rootDescription = 'Summary View';
    } else {
      this.rootUrl = '/';
      this.rootDescription = 'my claims';
    }

    sessionStorage.setItem('router-root', root);
  }
}

type Root =
  | 'home'
  | 'search'
  | 'watch-list'
  | 'recent-claims'
  | 'my-claims'
  | 'manager-home'
  | 'summary-view';
