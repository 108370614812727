import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  BaseComponent,
  ListComponent,
  ProgressService,
  SearchResult,
} from 'src/app/shared';
import { emulator } from '../../services/emulator.service';
import { LogService } from '../../services/log.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-upload',
  template: ` <div>
    <div class="tw-flex">
      <div class="tw-w-[40%]">
        <app-claimant-label
          value
          [claimant]="claim"
          [showNameLabel]="true"
          [nameClass]="'uploadFileClaimName'"
          [disabled]="!userRole.manager"
          *ngIf="!hideHeader"
        ></app-claimant-label>
      </div>
      <div class="app-pb2 tw-w-[60%]" *ngIf="!hideHeader">
        <app-claim-label
          value
          [claim]="claim"
          layout="row"
          [disabled]="true"
          [showNameLabel]="true"
          [noLink]="true"
        ></app-claim-label>
      </div>
    </div>
    <app-toast type="Info"
      >Size limit is 6MB per file. The following formats are supported:
      {{ GetSupportedExtensions('display') }}.</app-toast
    >
    <div class="app-pt2 app-pb2 tw-block md:tw-hidden">
      <input
        type="file"
        class="file-input"
        accept="{{ GetSupportedExtensions('accept') }}"
        (change)="fileSelected($event)"
        (click)="fileUpload.value = null"
        #fileUpload
        multiple
        id="fileInput"
      />
      <app-action-row>
        <app-button left1 emphasis="Medium" (click)="fileUpload.click()"
          >Add file(s)</app-button
        >
        <app-button
          left2
          icon="upload"
          emphasis="High"
          (click)="running = true; uploadDocuments()"
          [disabled]="running || getPendingFiles().length === 0"
          >Upload</app-button
        >

        <app-button
          left3
          emphasis="Low"
          (click)="removeAll()"
          [disabled]="running"
          >Remove all</app-button
        >
      </app-action-row>
    </div>
    <div class="app-pt2 app-pb2 tw-hidden md:tw-block">
      <input
        type="file"
        class="file-input"
        accept="{{ GetSupportedExtensions('accept') }}"
        (change)="fileSelected($event)"
        (click)="fileUpload.value = null"
        #fileUpload
        multiple
        id="fileInput"
      />
      <app-action-row>
        <app-button left1 emphasis="Medium" (click)="fileUpload.click()"
          >Add file(s)</app-button
        >
        <app-button
          left2
          icon="upload"
          emphasis="High"
          (click)="running = true; uploadDocuments()"
          [disabled]="running || getPendingFiles().length === 0"
          >Upload</app-button
        >

        <app-button
          right1
          emphasis="Low"
          (click)="removeAll()"
          [disabled]="running"
          >Remove all</app-button
        >
      </app-action-row>
    </div>
    <div>
      <app-list
        [noDataMessage]="'Add file(s) then press Upload.'"
        [dataSource]="this.files"
        [template]="row"
        class="app-pt2"
        [filterEnabled]="false"
        [paginatorEnabled]="false"
        [includeNoDataIcon]="false"
        [customNoDataLayoutClass]="
          ' app-pl2 app-pt1 app-pb1 tw-w-full md:tw-w-[100%]'
        "
        [loaded]="false"
        [tableAriaLabel]="'Uploads'"
        #uploadTable
        >>
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full">
            <app-list-header
              name="fileName"
              label="File"
              class="tw-w-full md:tw-w-[60%] app-pl1"
            ></app-list-header>
            <app-list-header
              name="status"
              label="status"
              class=" app-pl1 tw-w-full  status status-success md:tw-w-[30%]"
            ></app-list-header>
            <app-list-header
              name=""
              label="Remove"
              readOnly
              class="tw-w-full md:tw-w-[10%]"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>
      <ng-template #row let-element>
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <td class="app-pr1 tw-w-full md:tw-w-[60%]">
            <app-value
              [value]="element.fileName"
              label="FileName"
              [tabindex]="0"
              layout="row-xs"
            ></app-value>
          </td>
          <td
            class="{{
              'app-pl1 tw-w-full md:tw-w-[30%] status ' +
                getStatusClass(element.status)
            }}"
          >
            <app-value
              label="status"
              layout="row-xs"
              [tabindex]="0"
              [value]="getStatusValue(element)"
            ></app-value>
          </td>

          <td class="tw-w-full md:tw-w-[10%]">
            <div class="md:tw-block tw-hidden">
              <button
                mat-icon-button
                color="accent"
                (click)="removeFile(element)"
                [disabled]="!canRemoveFile(element)"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <div class="md:tw-hidden tw-block">
              <app-button
                emphasis="Low"
                (click)="removeFile(element)"
                [disabled]="!canRemoveFile(element)"
                >Remove</app-button
              >
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template #dismissTemplate let-element>
        <div class="md:tw-block tw-hidden">
          <button
            mat-icon-button
            color="accent"
            (click)="removeFile(element)"
            (keydown.enter)="removeFile(element)"
            [disabled]="!canRemoveFile(element)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div class="md:tw-hidden tw-block">
          <app-button
            emphasis="Low"
            (click)="removeFile(element)"
            (keydown.enter)="removeFile(element)"
            [disabled]="!canRemoveFile(element)"
            >Remove</app-button
          >
        </div>
      </ng-template>
      <ng-template #statusTemplate let-element>
        <div *ngIf="element.status === 'pending'" class="status status-pending">
          <span class="app-pl1"
            >Uploading...
            <span *ngIf="element.progress || 0 > 0"
              >{{ element.progress }} %</span
            >
          </span>
        </div>
        <div
          *ngIf="element.status === 'processing'"
          class="status status-pending"
        >
          <span class="app-pl1">Processing...</span>
        </div>
        <div *ngIf="element.status === 'success'" class="status status-success">
          <mat-icon>done</mat-icon>
          <span class="app-pl1">Success</span>
        </div>
        <div *ngIf="element.status === 'error'" class="status status-error">
          <div>
            <span>Error:</span>
            <span>{{ element.statusMessage + '!!!!!!' }}</span>
          </div>
        </div>
      </ng-template>
    </div>
    <div mat-dialog-actions align="end" *ngIf="showClose">
      <app-button id="btnCancel" (click)="close()" emphasis="Low">{{
        cancelLabel
      }}</app-button>
    </div>
  </div>`,
  styles: [
    `
      ::ng-deep .uploadFileClaimName {
        font-weight: bold;
      }
      .file-input {
        display: none;
      }
      .status {
        font-weight: bold;
        display: flex;
        align-items: center;
        font-weight: bold;
      }
      .status-success {
        color: var(--color-green);
      }
      .status-error {
        color: var(--color-red);
      }
      .status-uploading {
        color: var(--color-sedgwick-blue);
      }
      .status-pending {
        color: var(--color-sedgwick-blue);
      }
    `,
  ],
})
export class UploadComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  files: FileUpload[] = [];

  @Input()
  dialogRef: MatDialogRef<any>;

  @Input()
  claimId: string;

  @Input()
  source: string;

  @Input()
  claim: SearchResult;

  @Input()
  hideHeader: boolean = false;

  @Input()
  showClose: boolean = false;

  progress: number;
  message: string;
  @Output() public onUploadFinished = new EventEmitter();

  @ViewChild('uploadTable')
  uploadTable: ListComponent;

  isRecordAdded: boolean = false;
  fileUpdates = new EventEmitter<FileUpload[]>();
  running = false;
  cancelLabel: string = 'Close';

  ngAfterViewInit(): void {
    if (this.dialogRef) {
      this.uploadTable.updateDataSource(); //.focus();
    }
  }

  getPendingFiles = (): FileUpload[] => {
    return this.files.filter((f) => f.status === 'pending');
  };
  getStatusValue = (elem: FileUpload): string => {
    switch (elem.status) {
      case 'success':
        return 'Success!';
      case 'pending':
        return 'Pending upload';
      case 'uploading': {
        if (elem.progress || 0 > 0) {
          return 'Uploading...' + elem.progress + ' %';
        } else {
          return 'Pending';
        }
      }
      case 'processing':
        return 'Processing';
      case 'error':
        return 'Error: ' + elem.statusMessage;
      default:
        return elem.status;
    }
  };
  getStatusClass = (status: string): string => {
    switch (status) {
      case 'success':
        return 'status-success';
      case 'uploading':
      case 'processing':
        return 'status-uploading';
      case 'error':
        return 'status-error';
      default:
        return 'status-pending';
    }
  };

  /*
  filesObservable = () => {
    this.fileUpdates.emit(this.files);
  };
  */

  private readonly maxFiles = 10;

  private readonly maxSizeMB = 6;

  private readonly extensions = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.pdf',
    '.rtf',
    '.tif',
    '.txt',
    '.doc',
    '.docx',
  ];

  GetSupportedExtensions = (type: string): string => {
    const exts = this.extensions.map((e) => e);
    if (type === 'accept') {
      return this.extensions.join(',');
    } else if (type === 'display') {
      let response = '';
      for (let e = 0; e < exts.length - 1; e++) {
        response += exts[e];
        if (e < exts.length - 2) {
          response += ' , ';
        }
      }
      response += ' & ' + exts[exts.length - 1];
      return response;
      /*
      var extsJoined = exts.join(' , ');
      var i = extsJoined.lastIndexOf(' , ');
      extsJoined[i] = ' & ' +
      let ret =  + ' & ' + this.extensions[this.extensions.length-1];
      return ret; */
    } else {
      return this.extensions.join(' , ');
    }
  };

  constructor(
    public progressService: ProgressService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    public logService: LogService,
    private enumalatorService: emulator,
    public userRole: UserRoleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.fileUpdates.subscribe((x: FileUpload[]) => {
      this.files = x;
    });
    this.getPendingFiles();
  }

  filter(data: any) {
    this.files = data;
    this.isRecordAdded = true;
  }

  canRemoveFile(fileUpload: FileUpload): boolean {
    return fileUpload.status !== 'success';
  }

  removeFile(fileUpload: FileUpload) {
    this.files.splice(this.files.indexOf(fileUpload), 1);
    this.render();
  }

  removeAll() {
    /*for(let f of this.files) {
      this.removeFile(f);
    }*/
    //this.render();
    this.files = this.files.filter((f) => f.status === 'success');
    this.render();
    //this.uploadTable._dataSource._updatePaginator(0);
    this.render();
    //return;
    //let removed = this.files.filter((f) => f.status === 'success');
    //this.files = removed;
    //this.render();

    //console.log('removed all files, switching to first page in table',  this.uploadTable.paginator.pageSize);
    //    this.uploadTable.pageChange(pEvt);
    //this.uploadTable.paginator.firstPage(); //.paginate(0, this.uploadTable.paginator.pageSize);
    //this.render();
    //this.uploadTable.updateDataSource();
  }

  getFiles() {
    return this.files;
  }

  fileSelected(event: any): void {
    this.isRecordAdded = false;

    for (const file of event.target.files) {
      const fileUpload = new FileUpload();
      fileUpload.file = file;
      fileUpload.fileName = this.sanitizeFilename(file.name);
      fileUpload.status = 'pending';
      const parts = file.name.split('.');
      const ext = '.' + parts[parts.length - 1].toLowerCase();
      // validate file size, file count, file extension and duplicates
      // would be great to validate magic here.
      if (
        this.files.filter((f) => f.status === 'pending').length >= this.maxFiles
      ) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Maximum file count is ${this.maxFiles}`;
      } else if (file.size > this.maxSizeMB * 1000000) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Maximum file size is ${this.maxSizeMB} MB`;
      } else if (
        this.files.find(
          (f) =>
            f.file.size === file.size &&
            f.file.name === file.name &&
            f.file.lastModified === file.lastModified
        )
      ) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Duplicate file found.`;
      } else if (!this.extensions.find((x) => x === ext)) {
        fileUpload.status = 'error';
        fileUpload.statusMessage = `Unsupported file type.`;
      }

      this.isRecordAdded = false;
      this.files.push(fileUpload);
      //this.uploadTable._dataSource.data.push(fileUpload);
      this.isRecordAdded = true;
      this.uploadTable.pageSize += 1;
      this.uploadTable.updateDataSource();
      //if (this.uploadTable.paginator) {
      // this.uploadTable.paginate(this.uploadTable.paginator.getNumberOfPages()-1, this.uploadTable.paginator.pageSize);
      //}
    }

    this.render();
    //this.uploadTable._dataSource.data
    //  this.uploadTable.paginate(this.uploadTable.paginator.getNumberOfPages(), this.uploadTable.paginator.pageSize);
  }

  render() {
    //this.cdr.detach();
    this.cdr.detectChanges();

    this.cdr.markForCheck();

    this.uploadTable.updateDataSource();
  }

  async uploadDocuments() {
    if (this.files.length > 0) {
      this.progressService.show();
      let i = 0;

      for (let f = 0; f < this.files.length; f++) {
        //} file of this.files) {
        const file = this.files[f];

        // once a status is assigned, no further upload is permitted.
        if (file.status !== 'pending') {
          continue;
        }

        file.status = 'pending';

        const formData = new FormData();
        formData.append('file0', file.file, file.file.name);

        i++;
        this.LogFeature();
        this.enumalatorService.showAuditorInfo();
        this.subs.sink = this.http
          .post(
            `/api/claim/${this.source}/${this.claimId}/${this.claim.lob}/${this.claim.vendorId}/${this.claim.procUnit}/${this.claim.claimNumber}/UploadFile`,
            formData,
            { reportProgress: true, observe: 'events' }
          )
          .subscribe({
            next: (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                file.progress = Math.round((100 * event.loaded) / event.total);
                if (file.progress === 100) {
                  file.status = 'processing';
                } else {
                  file.status = 'uploading';
                }
              } else if (event.type === HttpEventType.Response) {
                if (event.body) {
                  i--;
                  const conf: FileUploadConfirmation =
                    event.body as FileUploadConfirmation;

                  if (conf.code === 'Success') {
                    file.status = 'success';
                  } else {
                    file.status = 'error';
                    file.statusMessage =
                      conf.statusDescription ??
                      'Password protected file found.';
                  }
                  if (i == 0) {
                    this.progressService.hide();
                    this.running = false;
                    for (let i = 0; i < this.files.length; i++) {
                      if (
                        this.files[i].status == 'error' &&
                        this.files[i].statusMessage
                          .toLowerCase()
                          .indexOf('maximum file count') >= 0 &&
                        this.getPendingFiles().length < 10
                      ) {
                        this.files[i].status = 'pending';
                        this.files[i].statusMessage = 'Pending';
                        this.render();
                      }
                    }
                    this.render();
                  }
                }
              }
            },
            error: (err: HttpErrorResponse) => {
              console.log('Error returned from upload post', err);
              file.status = 'error';
              file.statusMessage = 'Please login again and try again.';
            },
          });
      }
    }
  }

  sanitizeFilename(filename: string): string {
    return filename.includes(':') ? filename.replace(/:/g, '_') : filename;
  }

  close() {
    this.dialogRef.close();
  }

  LogFeature() {
    this.logService.LogFeature(
      this.claim,
      'UploadDocuments',
      'Actvity - Upload'
    );
  }
}

export class FileUpload {
  fileName: string;
  file: File;
  status: string;
  statusMessage: string;
  progress: number;
}

export interface FileUploadConfirmation {
  code: string;
  confirmationNumber: string;
  statusDescription: string;
}
