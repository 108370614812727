import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ConfigurationService,
  DismissDialogComponent,
  Excel,
  ExportRequest,
  Session,
  UserRoleService,
} from 'src/app/my-sedgwick';
import { OmniNotifications } from 'src/app/my-sedgwick/models/accommodation';
import { UnapprovedJobFunction } from 'src/app/my-sedgwick/models/accommodations-interactions';
import { AccommodationsService } from 'src/app/my-sedgwick/services/accommodations-service';
import { ActivityService } from 'src/app/my-sedgwick/services/activity.service';
import {
  ProgressService,
  SnackbarService,
  SnackbarType,
  deepCopy,
} from 'src/app/shared';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';

@Component({
  selector: 'app-notifications-list',
  template: `
    <app-list
      [dataSource]="notifications"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      loadingMessage=""
      noDataIcon="search"
      noDataMessage="No notifications"
      noDataThinMessage="There are no results that match the search"
      [pageSizeOptions]="[5, 10, 25]"
      [pageSize]="5"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these notifications as a XLSX file"
      [tableAriaLabel]="'Notifications'"
      (export)="_export()"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimDescription"
            label="Claim"
            sortLabel="Claim Type"
            class="tw-w-full md:tw-w-[25%]"
            [names]="['claimNumber']"
          ></app-list-header>
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[20%]"
            [names]="['empId']"
          ></app-list-header>
          <app-list-header
            name="message"
            label="Message"
            class="tw-w-full md:tw-w-[35%]"
            [names]="['']"
          ></app-list-header>
          <app-list-header
            name="date"
            label="Date"
            filterType="Date"
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
          <app-list-header
            label="Dismiss"
            readOnly
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>
    <ng-template #row let-element role="rowgroup">
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value label="Claim" layout="row-xs">
            <app-claim-label value [claim]="element" layout="row">
            </app-claim-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label value [claimant]="element"></app-claimant-label>
          </app-value>
        </td>
        <td class="tw-w-full app-pr2 md:tw-w-[35%]">
          <app-value
            label="Message"
            [tabindex]="element.message ? '0' : '1'"
            [value]="element.message"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%]">
          <app-value
            label="Date"
            [tabindex]="element.date ? '0' : '1'"
            [value]="element.date | date : 'MM/dd/yyyy'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%] app-pb1">
          <div class="tw-hidden md:tw-block">
            <app-icon-button
              icon="highlight_off"
              label="Dismiss"
              tabindex="0"
              role="button"
              (click)="dismiss(element)"
              (keydown.enter)="dismiss(element)"
              [attr.aria-label]="
                'Dismiss notification for claim ' +
                element.claimDescription +
                ' ' +
                element.claimNumber
              "
            >
            </app-icon-button>
          </div>
          <div class="tw-block md:tw-hidden">
            <a
              class="tw-block md:tw-hidden"
              (click)="dismiss(element)"
              (keydown.enter)="dismiss(element)"
              tabindex="0"
            >
              <b>Dismiss</b>
            </a>
          </div>
        </td>
      </tr>
    </ng-template>
  `,
  styles: [
    `
      .container {
        width: 400px;
        height: 420px;
      }
    `,
  ],
})
export class NotificationsListComponent implements OnInit {
  @Input()
  notifications: OmniNotifications[];

  @Input()
  excelName: string = 'Claim Search.xlsx';

  @Input()
  sheetName: string = 'Claim Search';

  @Output()
  export = new EventEmitter<ExportRequest>();

  claim: any;
  showComments: boolean = false;
  listData: UnapprovedJobFunction[] = [];
  saveAlertRequest: SaveAlertActionRequest;

  constructor(
    private excel: Excel,
    public userRole: UserRoleService,
    public claimConfigService: ConfigurationService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public session: Session,
    public progressService: ProgressService,
    private snackbarService: SnackbarService,
    private accommodationsService: AccommodationsService,
    private activityService: ActivityService
  ) {}

  formGroup: FormGroup = this.formBuilder.group({
    validJobFunction: ['', [Validators.required]],
    comments: [''],
    jobFunctionDetailId: [''],
  });

  ngOnInit(): void {
    this.activityService.alertSelected.subscribe((sub: string) => {
      this.notifications = this.notifications.filter(
        (_) => _.alertMasterUid.toString() !== sub
      );
    });
  }

  toggleValid() {
    this.showComments = this.formGroup.get('validJobFunction').value === 'No';
    if (this.showComments) {
      this.formGroup.get('comments').addValidators(customvalidations.required);
    } else {
      this.formGroup.get('comments').clearValidators();
    }
    this.formGroup.get('comments').updateValueAndValidity();
  }

  dismiss(claim: any) {
    if (
      this.session.user &&
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackbarService.show(
        'Preview only mode.  dismiss action was not actually taken.',
        SnackbarType.Info
      );
      return;
    }
    this.saveAlertRequest = new SaveAlertActionRequest({
      alertMasterUid: Number(claim.alertMasterUid),
      action: 'DISMISSED - VOE',
      sourceSystem: claim.source,
      claimNumber: claim.claimNumber,
      claimId: claim.claimId,
      lob: claim.lineOfBusiness,
      userOwns: claim.userOwns,
      type: 'Notification',
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      saveAlertRequest: this.saveAlertRequest,
    };
    this.dialog.open(DismissDialogComponent, dialogConfig);
  }

  _export() {
    const request = new ExportRequest();
    const claimRows = deepCopy(this.notifications);
    request.fileName = this.excelName;

    request.sheets = [
      {
        name: this.sheetName,
        rows: claimRows,
        headers: [
          { label: 'Line of Business', value: 'lineOfBusiness' },
          { label: 'Claim Type', value: 'claimDescription' },
          { label: 'Claim Number', value: 'claimNumber' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Employee ID', value: 'empId' },
          { label: 'Message', value: 'message' },
          { label: 'Date', value: 'date', format: 'date' },
        ],
      },
    ];

    this.export.emit(request);
    this.excel.export(request);
  }
}
