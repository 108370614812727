import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { LogApi, LogMessage } from '..';

declare const webchat: any;

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private loading: boolean = false;

  chatInit: any = null;

  source: string;

  claimId: string;

  lob: string;

  constructor(private http: Http, private logApi: LogApi) {}

  async initialize(
    source: string,
    claimId: string,
    lob: string
  ): Promise<boolean> {
    this.loading = true;

    try {
      if (this.chatInit) {
        this.onDestroy();
      }

      this.chatInit = await this.http.get(
        `/api/chat/${source}/${claimId}/${lob}/token`,
        { cache: false, timeout: 1000 * 60 }
      );

      webchat.config({
        webchat: {
          baseApiUrl: this.chatInit.baseApiUrl,
        },
      });

      const dataForTokenGeneration = {
        username: this.chatInit.username,
        application: this.chatInit.application,
        clientid: this.chatInit.clientid,
        lob: this.chatInit.lob,
        sor: this.chatInit.sor,
        claimId: this.chatInit.claimId,
      };

      const liveChatClose = document.getElementById('lhnWindowCloser');
      if (liveChatClose) {
        liveChatClose.focus();
        liveChatClose.click();
      }

      webchat.initChat(dataForTokenGeneration, this.chatInit?.claimData);
    } catch (e: any) {
      this.logChatError(e);
      throw e;
    }

    this.loading = false;
    return await Promise.resolve(true);
  }

  onDestroy(): boolean {
    this.chatInit = null;
    return true;
  }

  async close() {
    if (this.chatInit) {
      await webchat.endConversation();
      this.chatInit = null;
    }
    this.loading = false;
    return true;
  }

  isActive() {
    return this.loading || document.getElementById('chatcontainer');
  }

  private logChatError(e: any) {
    if (e?.message?.incudes && !e.message.incudes('Http failure')) {
      const message = new LogMessage();
      message.message = `message: Error,\n conversationId: ${
        this.chatInit?.conversationId
      },\n claimId: ${this.claimId},\n source: ${
        this.source
      },\n message: ${JSON.stringify(e).replaceAll(':', '')}`;
      this.logApi.log(message, 'Error');
    }
    throw new Error('Unable to open chat at this time');
  }
}
