import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { SearchService } from 'src/app/my-sedgwick';
import { SearchConfiguration, SelectOption, deepCopy } from 'src/app/shared';
import { InpageAliasService } from '../..';
import { ConfigurationService } from '../../services/configuration.service';
import { LogService } from '../../services/log.service';
import { QuickSearchService } from '../../services/quick-search.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-quick-search',
  template: `
    <app-panel
      title="Search Claims"
      [showTitle]="showTitle"
      [largeHeader]="true"
      [flat]="flat"
      [canExpand]="false"
      role="dialog"
      aria-modal="true"
      [ariaLabel]="'Search Claims'"
    >
      <div *ngIf="!showTitle" class="app-pt3"></div>
      <form>
        <mat-form-field class="tw-w-full">
          <mat-label>{{ label }} </mat-label>
          <input
            matInput
            [matAutocomplete]="auto"
            [formControl]="myControl"
            #search
            (blur)="blurEmitter.emit()"
          />
          <button mat-icon-button matSuffix color="accent" [disabled]="true">
            <mat-icon class="cglass">search</mat-icon>
          </button>

          <mat-autocomplete
            #auto="matAutocomplete"
            [autoActiveFirstOption]="true"
            (optionSelected)="optionSelected($event)"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async; let i = index"
              [value]="option"
            >
              <ng-container *ngIf="i === 0">
                <b>{{ option.label }}</b>
              </ng-container>
              <ng-container *ngIf="i > 0">
                {{ option.label }}
              </ng-container>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </app-panel>
  `,
  styles: [
    `
      ::ng-deep .mat-mdc-option:focus.mdc-list-item,
      .mat-mdc-option.mat-mdc-option-active {
        border: 2px solid #67707a !important;
      }
    `,
  ],
})
export class QuickSearchComponent implements OnInit {
  @Input()
  showTitle = true;

  @Input()
  includeIndirects: boolean;

  @Output()
  searchCompleted = new EventEmitter();

  @Output()
  blurEmitter = new EventEmitter();

  @Output()
  optionSelectedEmitter = new EventEmitter();

  @Input()
  flat = false;

  @Input()
  getSearchResults = false;

  @ViewChild('search')
  searchElement: ElementRef;

  myControl = new UntypedFormControl();

  options: SearchOption[] = [];

  filteredOptions: Observable<SearchOption[]>;

  empIdAlias: string = 'Employee Id';
  searchConfig: SearchConfiguration;

  label: string;

  constructor(
    private router: Router,
    public logService: LogService,
    public session: Session,
    private quickSearchService: QuickSearchService,
    private configurationService: ConfigurationService,
    private inpageAliasService: InpageAliasService,
    public searchService: SearchService,
    private titleCase: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.searchOptions(value))
    );
    const tempIdAlias = await this.inpageAliasService.replaceAliases(
      'employeeIdSingular',
      this.configurationService.GetConfig.configFeatures.aliases
    );
    this.empIdAlias =
      tempIdAlias === 'employeeIdSingular' ? 'Employee Id' : tempIdAlias;

    this.label = `Search for Claim Number, Name, ${this.titleCase.transform(
      this.empIdAlias
    )} ....`;
    this.searchConfig = await this.searchService.getConfiguration();
  }

  LogFeature() {
    this.logService.LogFeature(undefined, 'Search', 'Search - Overlay');
  }

  async optionSelected(event: MatAutocompleteSelectedEvent) {
    const searchOption = event.option.value as SearchOption;
    this.myControl.setValue(searchOption.value);

    this.quickSearchService.currentSearchOption.set(searchOption);

    this.optionSelectedEmitter.emit(searchOption);

    if (!this.getSearchResults) {
      const queryParams: any = {};
      queryParams[searchOption.name] = searchOption.value;

      // Because we could already be on the search route, we call this
      // navigteByUrl call first to ensure the search route is reloaded.
      await this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['search'], {
            queryParams,
          });
        });
      this.LogFeature();
      this.searchCompleted.emit();
    } else {
      console.log('QUICK SEARCH OPTION', searchOption);
      //const searchConfig = await this.searchService.getConfiguration();

      // searchConfig[searchOption.name] = searchOption.value;
      try {
        console.log('QUICK SEARCH CONFIG', this.searchConfig);
        const searchRequest = { ...this.getSearchRequest(this.searchConfig) };
        searchRequest[searchOption.name] = searchOption.value;
        searchRequest['includeIndirectReports'] = this.includeIndirects;
        console.log('QUICK SEARCH REQUEST', searchRequest);
        const searchResults = await this.searchService.getSearchResults(
          searchRequest
        ); //{ [searchOption.name]: searchOption.value});
        this.searchCompleted.emit(searchResults);
      } catch (e) {
        this.searchCompleted.emit({ searchResults: [] });
      }
    }
  }
  getMultiselect(value: any, options: SelectOption[]) {
    return value ?? options.map((x) => x.value);
  }

  getSearchRequest(searchConfig: any) {
    const result = deepCopy(searchConfig);
    result.lob = this.getMultiselect(result.lob, searchConfig.lineOfBusiness);
    result.status = this.getMultiselect(null, searchConfig.status);
    console.log(result.status);
    result.dsClaimType = this.getMultiselect(
      result.dsClaimType,
      searchConfig.claimTypeDs
    );
    result.dsClaimSubstatus = this.getMultiselect(
      result.dsClaimSubstatus,
      searchConfig.claimSubstatusDs
    );
    result.lvClaimType = this.getMultiselect(
      result.lvClaimType,
      searchConfig.claimTypeLv
    );
    result.lvAbsenceType = this.getMultiselect(
      result.lvAbsenceType,
      searchConfig.absenceTypeLv
    );
    result.lvClaimSubstatus = this.getMultiselect(
      result.lvClaimSubstatus,
      searchConfig.absenceSubstatusLv
    );
    result.lvCausedBy = this.getMultiselect(
      result.lvCausedBy,
      searchConfig.causedBy
    );

    result.managerEmpUnum = result?.supervisor?.empUnum;
    result.includeIndirectReports = result.includeIndirectReports === 'on';

    if (result.startDate) {
      result.startDate = new Date(result.startDate).toISOString();
    }
    if (result.endDate) {
      result.endDate = new Date(result.endDate).toISOString();
    }
    return result;
  }

  private searchOptions(value: string): SearchOption[] {
    if (!value || typeof value !== 'string' || value.length === 1) {
      return [];
    } else {
      const options = [];

      // enable name search if letter and spaces only.
      if (/^[A-Za-z ]*$/.test(value)) {
        options.push(
          SearchOption.lastName(value),
          SearchOption.firstName(value)
        );
      }

      options.push(SearchOption.claimNumber(value));
      if (this.session.user.roles.manager) {
        options.push(SearchOption.employeeId(value, this.empIdAlias));
      }

      return options;
    }
  }

  private isFullName(value: string): boolean {
    if (!value) {
      return false;
    }

    const items = value.split(' ').filter((x) => x);

    return items.length === 2;
  }
}

export class SearchOption {
  static lastName(value: string): SearchOption {
    return new SearchOption(
      'lastName',
      `Search last name begins with ${value}`,
      value
    );
  }

  static firstName(value: string): SearchOption {
    return new SearchOption(
      'firstName',
      `Search first name begins with ${value}`,
      value
    );
  }

  static claimNumber(value: string): SearchOption {
    return new SearchOption(
      'claimNumber',
      `Search claim number begins with ${value}`,
      value
    );
  }

  static employeeId(value: string, empIdAlias: string): SearchOption {
    return new SearchOption(
      'employeeId',
      `Search ${empIdAlias} begins with ${value}`,
      value
    );
  }

  static ssn(value: string): SearchOption {
    return new SearchOption(
      'ssn',
      `Search social security number equal to ${value}`,
      value
    );
  }

  static fullName(value: string): SearchOption {
    return new SearchOption(
      'fullName',
      `Search full name equal to ${value}`,
      value
    );
  }

  constructor(
    public name: string,
    public label: string,
    public value: string
  ) {}
}
