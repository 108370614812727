import { Component, OnInit } from '@angular/core';
import { getUrlParams } from 'src/app/shared';
import { AbsenceService, EncryptionService, IncidentalAbsence } from '../..';

@Component({
  selector: 'app-absence-page',
  template: `
    <app-section>
      <app-absence-summary
        class="tw-w-full"
        [absences]="absences"
        [sentenceCase]="false"
        title="Absences for {{ name | titlecase }} - {{ empId }}"
      ></app-absence-summary>
    </app-section>
  `,
  styles: [],
})
export class AbsencePageComponent implements OnInit {
  absences: IncidentalAbsence[] = [];

  name: any;

  empId: any;

  constructor(
    private absenceService: AbsenceService,
    private encrypt: EncryptionService
  ) {}

  async ngOnInit() {
    const params = getUrlParams();
    if (params.value) {
      const value = JSON.parse(this.encrypt.decrypt(params.value));
      this.name = value.name;
      this.empId = value.empId;
      const listAbsenceOffWork = await this.absenceService.getEmployeeAbsences(
        value.empUnum
      );
      this.absences = this.absenceService.mergeDateToString(listAbsenceOffWork);
    }
  }
}
