import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  encrypted: string = '';
  decrypted: string = '';

  constructor() {}

  encrypt(value: string): string {
    this.encrypted = CryptoJS.TripleDES.encrypt(
      value,
      '#489ljkdfs7lkjsdfkj'
    ).toString();
    return this.encrypted;
  }

  decrypt(value: string): string {
    this.decrypted = CryptoJS.TripleDES.decrypt(
      value,
      '#489ljkdfs7lkjsdfkj'
    ).toString(CryptoJS.enc.Utf8);
    return this.decrypted;
  }
}
