import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-dialog-container',
  template: `
    <div
      class="dialog-container tw-px-4 tw-pt-2 tw-pb-3 tw-text-[14px] tw-bg-white tw-flex tw-flex-col tw-h-full tw-max-h-full"
    >
      <app-action-row
        class=" tw-flex-shrink-0"
        *ngIf="showHeader"
        [hasTwoColHeader]="twoColHeader"
      >
        <h2
          [id]="headerId"
          left1
          role="heading"
          class="app-header-2 tw-text-wrap"
          *ngIf="headerLevel === 2"
        >
          {{ label }}
        </h2>
        <h3
          [id]="headerId"
          left1
          role="heading"
          class="app-header-3 tw-truncate"
          *ngIf="headerLevel === 3"
        >
          {{ label }}
        </h3>
        <div right1>
          <button
            mat-icon-button
            matDialogClose
            (click)="onClearClicked()"
            aria-label="Close Modal"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </app-action-row>
      <div id="dialogContent" class="tw-flex-grow tw-overflow-auto tw-p-4">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [``],
})
export class DialogContainerComponent implements OnInit {
  @Input() label: string;
  @Input() showHeader: boolean = true;
  templateToRender: boolean = true;
  @Output() clearClicked = new EventEmitter<void>();
  @Input() ariaLabel: string = '';
  @Input()
  headerLevel = 2;
  @Input()
  headerId: string = '';
  @Input() twoColHeader: boolean = false;

  constructor(private rendered: Renderer2) {}
  ngOnInit() {}

  onClearClicked() {
    this.clearClicked.emit();
  }
}
