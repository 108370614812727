import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  BaseComponent,
  ConfigurationSetting,
  DialogComponent,
  SearchResult,
} from 'src/app/shared';
import { RTWResponseStatus } from '../../models/rtw-response';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { ReturnToWorkEditComponent } from '../activity/return-to-work-edit.component';

@Component({
  selector: 'app-action',
  template: `
    <app-panel title="Actions">
      <ng-container
        *ngIf="this.claimConfigService.getFeatureAccess('ReturnToWork')"
      >
        <div class="border"></div>
        <app-action-link
          imageUrl="./assets/images/text_60x.png"
          title="Submit a Return to Work Date"
          helpText=""
          [imageWidth]="60"
          [imageHeight]="53"
          (linkClick)="dialog.open(rtwTemplate)"
        ></app-action-link>
        <ng-template #rtwTemplate>
          <app-dialog
            title="Submit Return To Work"
            ariaLabel="Submit return to work dialog"
            confirmLabel="Submit"
            cancelLabel="Close"
            (afterClosed)="resetData($event)"
            [SaveAlt]="successAlt"
            [CancelAlt]="cancelAlt"
            (confirmEvent)="submitedit($event)"
            [showConfirm]="!isDisplaySuccess"
            #rtweditdialog
          >
            <ng-container *ngIf="!isDisplaySuccess">
              <app-return-to-work-edit
                [claim]="claim"
                #returntoworkedit
                (AfterSuccess)="confirmationMessage($event)"
              ></app-return-to-work-edit>
            </ng-container>

            <div *ngIf="isDisplaySuccess">
              <br />
              <app-toast type="Success">
                {{ afterRtwSuccessMessage }}
              </app-toast>

              <app-upload
                [claim]="claim"
                [claimId]="claim.claimId"
                [showClose]="true"
                [source]="claim.source"
                [dialogRef]="rtweditdialog.dialogRef"
                [hideHeader]="true"
                *ngIf="
                  this.claimConfigService.getFeatureAccess('UploadDocuments')
                "
              >
              </app-upload>
            </div>
          </app-dialog>
        </ng-template>
      </ng-container>

      <ng-container
        *ngIf="this.claimConfigService.getFeatureAccess('UploadDocuments')"
      >
        <div class="border"></div>
        <app-action-link
          imageUrl="./assets/images/upload.png"
          title="Upload Documents"
          helpText=""
          [routerLink]=""
          [imageWidth]="60"
          [imageHeight]="53"
          [queryParams]="{
            focus: 'documents',
            source: claim.source,
            claimId: claim.claimId,
            showDialog: true
          }"
          routerLink="/upload-documents-in-documentstab"
        ></app-action-link>
        <ng-template #uploadTemplate>
          <app-dialog
            title="Upload Documents"
            ariaLabel="Upload documents dialog"
            confirmLabel="Upload"
            cancelLabel="Close"
            [showActions]="true"
            [showConfirm]="false"
            [width]="700"
            #dialog
          >
            <app-upload
              [dialogRef]="dialog.dialogRef"
              [claim]="claim"
              [claimId]="claim.claimId"
              [source]="claim.source"
            ></app-upload>
          </app-dialog>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="claim.userOwns">
        <div class="border"></div>
        <app-action-link
          imageUrl="./assets/images/text_60x.png"
          title="Enroll in Electronic Updates"
          helpText="Recieve SMS/Email regarding your claim, payments and more"
          [imageWidth]="60"
          [imageHeight]="53"
        ></app-action-link>
      </ng-container>

      <ng-container *ngIf="claim.userOwns">
        <div class="border"></div>
        <app-action-link
          imageUrl="./assets/images/img_DirectDeposit_100x.png"
          title="Enroll in Direct Deposit"
          [imageWidth]="100"
          [imageHeight]="46"
        ></app-action-link>
      </ng-container>

      <ng-container *ngIf="claim.userOwns">
        <div class="border"></div>
        <app-action-link
          imageUrl="./assets/images/pic_medicalcard_40x.png"
          title="View Medical Card"
          [imageWidth]="40"
          [imageHeight]="47"
        ></app-action-link>
      </ng-container>

      <ng-container>
        <div class="border"></div>
        <app-action-link
          imageUrl="./assets/images/learningcenter_60x.png"
          [imageWidth]="60"
          [imageHeight]="45"
          title="Visit helpful resources"
          helpText="Learn more about the claims process, view FAQs and helpful links"
          routerLink="/help"
          [queryParams]="{
            focus: 'learningcenter',
            claimId: claim.claimId,
            source: claim.source,
            claim: claim.claimDescription + ' - ' + claim.claimNumber,
            lob: claim.lob,
            origin: 'claimSummary'
          }"
        ></app-action-link>
      </ng-container>
    </app-panel>
  `,
  styles: [
    `
      .border {
        margin: 10px 0px 10px 0px;
        border-bottom: 1px solid #0000001f;
      }
    `,
  ],
})
export class ActionComponent extends BaseComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  claimConfig: ConfigurationSetting;

  ClaimNumber: string;
  afterRtwSuccessMessage: string;

  isDisplaySuccess: boolean = false;
  isUploadDisplay: boolean = false;
  successAlt: string = 'Submit Return to Work';
  cancelAlt: string = 'Cancel Return to Work';

  @ViewChild('returntoworkedit') returntoworkedit: ReturnToWorkEditComponent;
  @ViewChild('rtweditdialog') rtwdialoag: DialogComponent;

  constructor(
    public session: Session,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public claimConfigService: ConfigurationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.afterRtwSuccessMessage = '';
    this.subs.sink = this.route.queryParams.subscribe(
      (querystr: { [x: string]: string }) => {
        this.ClaimNumber = querystr['claimId'];
      }
    );
  }

  submitedit(eve: any) {
    this.afterRtwSuccessMessage = '';
    this.returntoworkedit.ClaimID = this.ClaimNumber;
    this.returntoworkedit.LOB = this.claim.lob;
    this.returntoworkedit.submitreturntowork();
  }

  confirmationMessage(event: RTWResponseStatus) {
    this.isDisplaySuccess = true;
    this.afterRtwSuccessMessage = event.msg;
    this.isUploadDisplay = event.isSuccess;
    this.rtwdialoag.showActions = !event.isSuccess;
  }

  resetData(event: string) {
    this.isDisplaySuccess = false;
    this.afterRtwSuccessMessage = '';
  }
}
