import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarDialogComponent } from '..';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public show(message: string): SnackbarRef;
  public show(message: string, type: SnackbarType): SnackbarRef;
  public show(
    message: string,
    type: SnackbarType,
    durationMs: number
  ): SnackbarRef;
  public show(config: SnackbarConfig): SnackbarRef;
  public show(
    messageOrConfig: string | SnackbarConfig,
    type: SnackbarType = SnackbarType.Info,
    durationMs: number = 5000,
    delayMs: number = 0,
    dismiss: boolean = false
  ): SnackbarRef {
    let config = new SnackbarConfig();
    if (typeof messageOrConfig === 'string') {
      config.message = messageOrConfig;
      config.type = type;
      config.durationMs = durationMs;
      config.delayMs = delayMs;
      config.dismiss = dismiss;
    } else {
      config = Object.assign(config, messageOrConfig);
    }

    return this._show(config);
  }

  public cancel(snackbarRef: SnackbarRef) {
    if (snackbarRef) {
      clearTimeout(snackbarRef.timer);
      snackbarRef.ref?.dismiss();
    }
  }

  private _show(config: SnackbarConfig): SnackbarRef {
    const result = new SnackbarRef();
    let style: string[];
    switch (config.type) {
      case SnackbarType.Error:
        style = ['snackbar-error'];
        break;
      case SnackbarType.Success:
        style = ['snackbar-success'];
        break;
      case SnackbarType.Warning:
        style = ['snackbar-warning'];
        break;
      case SnackbarType.Info:
        style = ['snackbar-info'];
        break;
      case SnackbarType.Basic:
        style = ['snackbar-basic'];
        break;
      default:
        style = [];
        break;
    }

    const data = {
      type: config.type,
      style,
      message: config.message,
      dismiss: config.dismiss,
    };

    result.timer = setTimeout(() => {
      result.ref = this.snackBar.openFromComponent(SnackbarDialogComponent, {
        data: data,
        duration: config.durationMs,
        panelClass: style,
      });
    }, config.delayMs);

    return result;
  }
}

export enum SnackbarType {
  Info,
  Error,
  Success,
  Warning,
  Basic,
}

export class SnackbarRef {
  timer: any;
  ref: MatSnackBarRef<any>;
}

export class SnackbarConfig {
  /**
   * Message to display on snack bar.
   */
  message?: string;

  /**
   * Type of snack bar. Defaults to Info.
   */
  type?: SnackbarType = SnackbarType.Info;

  /**
   * Display duration in milliseconds. Defaults to 5 seconds.
   */
  durationMs?: number = 5000;

  /**
   * Delays snackbar display in milliseconds. Default is 0.
   */
  delayMs?: number = 0;

  /**
   * Shows dismiss link in the snackbar. Default is false.
   */
  dismiss?: boolean = false;
}
