import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RestrictionTable } from '../../models/Restrictions';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { RestrictionsComponent } from './restrictions.component';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ActivityService } from '../../services/activity.service';

@Component({
  selector: 'app-restriction-detail',
  template: `
    <div
      id="restrictionDetailDiv"
    >
      <form
        role="none"
        class="tw-flex tw-flex-wrap tw-flex-col"
        class=""
        [formGroup]="restrictionDetailForm"
        autocomplete="off"
      >

        <div class="tw-w-full">

          <!-- Commentting out section since its not configured per requirements yet
          <div fxFlex="100" class="app-header-3-line">
          <div class="app-header-3-line tw-w-full">

            Absence History - Work Status
          </div>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Days Off Work"
            value="{{ dow }}"
          ></app-value>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Days Restricted Duty"
            value="{{ drd }}"
          ></app-value>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Lost Days - TTD Temporary Total Disability"
            value="{{ ldttd }}"
          ></app-value>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Lost Days - TPD Temporary Partial Disability"
            value="{{ ldtpd }}"
          ></app-value>

          <div class="app-header-3-line tw-w-full">Compensation Rates</div>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Temporary Total"
            value="{{ crtt }}"
          ></app-value>

          <app-value
           class="tw-w-[50%] md:tw-w-[25%]"
            label="Temporary Partial"
            value="{{ crtp }}"
          ></app-value>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Permanent Total"
            value="{{ crpt }}"
          ></app-value>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Permanent Partial Disability Scheduled"
            value="{{ crppds }}"
          ></app-value>

          <app-value
            class="tw-w-[50%] md:tw-w-[25%]"
            label="Permanent Partial Disability Scheduled"
            value="{{ crppdu }}"
          ></app-value>-->

          <!-- <div class="app-header-3-line tw-w-full">Restrictions</div> -->

          <app-restrictions *ngIf="isLoaded" class="tw-w-full"
          [restrictionTable]="restrictionTable"></app-restrictions>

  `,
  styles: [
    `
           .container1,
           .container2 {
             text-align: center;
           }

           .icon-display {
             transform: scale(3);
           }

           .noDataDiv {
             text-align: center;
           }
         `,
  ],
})
export class RestrictionDetailComponent implements OnInit {
  restrictionTable: RestrictionTable[];
  dow: string;
  drd: string;
  ldttd: string;
  ldtpd: string;
  crtt: string;
  crtp: string;
  crpt: string;
  crppds: string;
  crppdu: string;

  isLoaded: boolean = false;

  @Input()
  absenceHistoryWorkStatus: AbsenceHistoryWorkStatus[];
  compensationRates: CompensationRates[];
  @Input()
  source: string;
  @Input()
  claimId: string;

  @Input()
  claim:SearchResult;

  @Output()
  //restrictionTables: RestrictionTable[];
  restriction: Restriction;

  @ViewChild('restrictionChild') restrictionChild: RestrictionsComponent;

  restrictionDetailForm: FormGroup = this.fb.group({});

  constructor(private fb: FormBuilder, private activityService: ActivityService) {}

  async ngOnInit() {
    this.restriction = await this.activityService.getRestrictions(this.source, this.claimId, this.claim.lob);

    this.isLoaded = true;
    if (
      this.restriction.listRestrictionsTable?.length > 0
    ) {
      this.restrictionTable = this.restriction.listRestrictionsTable;
      // I dont know if this restrictionChild is needed or not, it was throwing null when trying to set to this value, but I see it as ViewChild
      //this.restrictionChild.restrictionData = this.restrictionTable;
    }
  }
}

export class AbsenceHistoryWorkStatus {
  daysOffWork?: string;
  daysRestrictedDuty?: string;
  lostDaysTtd?: string;
  lostDaysTpd?: string;
}

export class CompensationRates {
  temporaryTotal?: string;
  temporaryPartial?: string;
  permanentTotal?: string;
  permanentPartialDisabilityScheduled?: string;
  permanentPartialDisabilityUnscheduled?: string;
}

export class Restriction {
  listAbsenceHistoryWorkStatus?: AbsenceHistoryWorkStatus[];
  listCompensationRates?: CompensationRates[];
  listRestrictionsTable?: RestrictionTable[];
}
