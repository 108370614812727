import { Injectable, WritableSignal, signal } from '@angular/core';

/**
 * This service stores values in memory for sharing across component boundaries and lifetimes.
 * The values are written to signals to support responsive bindings.
 */
@Injectable({
  providedIn: 'root',
})
export class StateService {
  private _items = new Map<string, WritableSignal<any>>();

  set<T>(key: string, value: T) {
    this._items.set(key, signal(value));
  }

  has(key: string): boolean {
    return this._items.has(key);
  }

  get(key: string): any {
    return this.getSignal(key)();
  }

  getSignal(key: string): WritableSignal<any> {
    let result = this._items.get(key);
    if (!result) {
      result = signal(null);
      this._items.set(key, result);
    }

    return result;
  }

  delete(key: string): void {
    this._items.delete(key);
  }

  clear(): void {
    this._items.clear();
  }
}
