import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-preferences-page',
  template: `
    <app-section>
      <app-claim-breadcrumb></app-claim-breadcrumb>
      <app-panel
        title="Account settings"
        [showTitle]="true"
        class="tw-w-full"
        [largeHeader]="true"
        [panelId]="'accountSettingsPanel'"
        [canExpand]="false"
      >
        <div class="panel-title">
          <app-panel
            title="Change Password"
            [showTitle]="true"
            [largeHeader]="false"
            [headerLevel]="2"
            *ngIf="isChangePasswordTAB === true"
            [panelId]="'changePassword'"
            [expanded]="false"
            [canExpand]="true"
          >
            <app-change-password class="tw-w-full"></app-change-password>
          </app-panel>
        </div>

        <div class="panel-title">
          <app-panel
            title="Change Multi-Factor Authentication"
            [showTitle]="true"
            [headerLevel]="2"
            [largeHeader]="false"
            [expanded]="isMFAExpanded"
            [panelId]="'changeMFA'"
            [canExpand]="true"
            *ngIf="
              this.config.getConfigFeatureAccess('mfaenabled') &&
              this.isMFA === true
            "
          >
            <app-change-authentication-preferences-page
              (isMFAPanelExpanded)="isMFAPanelExpanded($event)"
            ></app-change-authentication-preferences-page>
          </app-panel>
        </div>

        <div class="panel-title">
          <!-- <app-panel *ngIf="this.config.getConfigFeatureAccess('notificationpreferences')" -->
          <app-panel
            *ngIf="
              this.config.getConfigFeatureAccess('notificationpreferences')
            "
            title="Change Communication Preferences"
            [showTitle]="true"
            [largeHeader]="false"
            [headerLevel]="2"
            [panelId]="'changeCommPref'"
            [expanded]="currentFocusTab === 'changepreferences' ? true : false"
            [canExpand]="true"
          >
            <app-communication-pref-page></app-communication-pref-page>
          </app-panel>
        </div>

        <div class="panel-title">
          <app-panel
            title="Manager Preference"
            [showTitle]="true"
            [largeHeader]="false"
            [panelId]="'changeMgrPrefs'"
            [headerLevel]="2"
            *ngIf="isManagerTextingPreferences === true"
            [expanded]="false"
          >
            <app-management-notification></app-management-notification>
          </app-panel>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      #prefpagediv ::ng-deep .mat-expansion-panel-header-title {
        color: #171c20;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      #prefpagediv ::ng-deep .mat-tab-label-active {
        color: #035e81;
        background-color: #f1f4f7;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      #prefpagediv ::ng-deep .mat-tab-label:hover {
        text-decoration: underline;
        text-decoration-color: #035e81;

        border-bottom: 2px solid #d2d8e1;
      }
      #activitypnl ::ng-deep .mat-tab-label-active {
        color: #007dbc;
        font-weight: bold;
        border-bottom: 2px solid #007dbc;

        border-bottom: 2px solid #d2d8e1;
      }

      .tab-info {
        background-color: #f1f4f7;
        color: #035e81;
      }
      .panel-title ::ng-deep .mat-expansion-panel-header-title {
        color: var(--color-sedgwick-blue) !important;
        text-transform: uppercase;
        font-size: inherit !important;
      }
    `,
  ],
})
export class PreferencesPageComponent implements OnInit {
  openCommPrefTab: boolean = false;
  isManagerTextingPreferences = false;
  isChangeCommunicationPreferencesAccordian = false;
  currentFocusTab = '';
  isChangePasswordTAB = true;
  isMFA = true;
  isSecurityQuestionsTAB = true;
  isAuditor: boolean = false;
  claimId: string = '';
  source: string = '';
  claimDesc: string = '';
  lob: string;

  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    public config: ConfigurationService,
    private session: Session,
    public claimConfigService: ConfigurationService,
    private userRole: UserRoleService
  ) {}
  selectedIndex = 0;
  isMFAExpanded: boolean = false;

  isMFAPanelExpanded(event: any) {
    this.isMFAExpanded = event;
  }

  async ngOnInit() {
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
    this.claimDesc = this.route.snapshot.queryParamMap.get('claim');
    this.lob = this.route.snapshot.queryParamMap.get('lob');
    this.currentFocusTab = this.route.snapshot.queryParamMap.get('focus');
    !this.session.user ? await this.session.loadUser() : null;
    this.isManagerTextingPreferences =
      (await this.config.getConfigFeatureAccessAsync(
        'ManagerTextingPreferences'
      )) && this.userRole.manager;
    if (this.session.user.role.toUpperCase() == 'CONTROLLER') {
      this.isMFA = false;
    }
    this.isChangePasswordTAB =
      this.session.user.userType === 'SSO' ||
      !this.session.user.clientCustomizations.ChangePassword
        ? false
        : true;
    if (this.session.user.userType === 'SSO') {
      this.isChangePasswordTAB = false;
      this.isSecurityQuestionsTAB = false;
      this.isMFA = false;
    }
  }
}
