import { Injectable, signal } from '@angular/core';
import { ConfigurationService } from '../../my-sedgwick/services/configuration.service';
import { Session } from '../../my-sedgwick/services/session.service';
import { UserRoleService } from '../../my-sedgwick/services/user-role.service';
import { ConfigurationSetting, UserActionService } from '../../shared';
import { OutboundSSO, UserActions } from '../../shared/models/user';
import { DelegationService } from './delegation.service';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  mgrAction: UserActions | null;
  clientLevelConfig: ConfigurationSetting;
  actAsDelegateSignal = signal<boolean>(false); // using signals as values were not refresing on template on refresh,start,stop delegation
  reportClaimSignal = signal<boolean>(false);
  constructor(
    private session: Session,
    private userRole: UserRoleService,
    private claimConfigService: ConfigurationService,
    private userAction: UserActionService,
    private delegationService: DelegationService
  ) {}

  async loadClientConfig() {
    this.clientLevelConfig =
      await this.claimConfigService.getAdditionalUserInformation();
  }
  /**
   * Display quick search component to managers
   * @returns
   */
  get quickSearch(): boolean {
    return this.userRole.controller || this.userRole.manager ? true : false;
  }

  /**
   * Display new claims component
   * @returns
   */
  get newClaims(): boolean {
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    return this.userRole.claimant ||
      this.userRole.employee ||
      this.userRole.auditor ||
      (this.userRole.manager && this.mgrAction == UserActions.ViewOwnEmpDb)
      ? false
      : true;
  }

  /**
   * Display off work components to managers
   * @returns
   */
  get offWork(): boolean {
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    return this.userRole.claimant ||
      this.userRole.employee ||
      this.userRole.auditor ||
      /*      (this.userRole.manager && sessionStorage.getItem('empEmUNum') != null) ||
      (this.userRole.manager &&
        sessionStorage.getItem('isMgrOwnClaims') == 'true') ||*/
      (this.userRole.manager && this.mgrAction == UserActions.ViewOwnEmpDb)
      ? false
      : true;
  }
  /**
   * Display return to work component
   * @returns
   */
  get rtw(): boolean {
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    return this.userRole.claimant ||
      this.userRole.employee ||
      this.userRole.auditor ||
      this.userRole.controller ||
      // (this.userRole.manager && this.mgrAction == UserActions.ViewOwnEmpDb) ||
      (this.userRole.manager && this.mgrAction == UserActions.ViewOwnEmpDb)
      ? false
      : true;
  }

  /**
   * Display my claims component
   * @returns
   */
  get myClaims(): boolean {
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    return (
      this.userRole.claimant ||
      this.userRole.employee ||
      (this.userRole.manager && this.mgrAction == UserActions.MgrViewEmpDb) ||
      (this.userRole.manager && this.mgrAction == UserActions.ViewOwnEmpDb)
    );
  }

  /**
   * Display accommodations component
   * @returns
   */
  get accommodations(): boolean {
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    return (
      this.session.user.lineOfBusiness.filter((item) => item.lob == 'AD')
        .length > 0 &&
      this.mgrAction != UserActions.ViewOwnEmpDb &&
      !this.userRole.employee
    );
  }

  get accommodationsCard(): boolean {
    return (
      this.session.user.lineOfBusiness.filter((item) => item.lob == 'AD')
        .length > 0
    );
  }
  get incidentalClient(): boolean {
    /* NOTE - Client is Incidental Absence client if one of following features is turned on.

    For any specific feature which drives other things we will use feature specific check

    */
    return (
      this.claimConfigService.getConfigFeatureAccess('IncidentalAbsences5') ||
      this.claimConfigService.getConfigFeatureAccess('IncidentalAbsence')
    );
  }

  /**
   * Display history component
   * @returns
   */
  get history(): boolean {
    return this.userRole.claimant ||
      this.userRole.employee ||
      this.userRole.auditor ||
      !this.session.user.clientCustomizations.History
      ? false
      : true;
  }
  /**
   * Display communication center
   * @returns
   */
  get commCenter(): boolean {
    return this.session.user.clientCustomizations.CommunicationCenter;
  }
  /**
   * Display search component
   * @returns
   */
  get search(): boolean {
    return this.userRole.claimant ||
      this.userRole.employee ||
      !this.session.user.clientCustomizations.ClaimSearch
      ? false
      : true;
  }
  /**
   * Display watchlist component
   * @returns
   */
  get watchList(): boolean {
    return this.userRole.claimant ||
      this.userRole.employee ||
      this.userRole.auditor ||
      !this.session.user.clientCustomizations.WatchList
      ? false
      : true;
  }

  get payments(): boolean {
    return this.claimConfigService.getFeatureAccess('payments');
  }

  get myWorkSchedule(): boolean {
    return (
      !this.userRole.claimant &&
      !this.userRole.controller &&
      this.claimConfigService.getConfigFeatureAccess('customworkschedule')
    );
  }

  get claimTracker(): boolean {
    return (
      (this.userRole.claimant ||
        this.userRole.controller ||
        this.userRole.employee ||
        this.userRole.manager) &&
      this.session.user.clientCustomizations.ClaimTracker
    );
  }

  get helpPage(): boolean {
    return this.session.user.clientCustomizations.HelpfulResources;
  }

  get reportClaim(): boolean {
    const canReportClaim =
      ((!this.session.isDelegating() &&
        this.session.user.hasIntake &&
        !this.userRole.auditor) ||
        (this.session.isDelegating() &&
          this.session.currDelegatee()?.reportClaim &&
          !this.userRole.auditor)) &&
      this.session.user.clientCustomizations.ReportNewClaim;
    this.reportClaimSignal.set(canReportClaim);
    return canReportClaim;
  }

  get outboundSSO(): OutboundSSO {
    return this.session.user.outboundSSO != null &&
      (this.session.user.outboundSSO.ssoLinkEmployee != null ||
        this.session.user.outboundSSO.ssoLinkManager != null)
      ? this.session.user.outboundSSO
      : null;
  }

  get offWorkCalendar(): boolean {
    const lobs = this.session.user.lineOfBusiness.filter(
      (item) => item.lob == 'DS' || item.lob == 'LV'
    );
    if (
      lobs.length > 0 &&
      this.session.user.clientCustomizations.ClaimsCalendar
    ) {
      return true;
    } else {
      return false;
    }
  }

  get leavebalancesummary(): boolean {
    return (
      this.claimConfigService.getConfigFeatureAccess('leavebalance') &&
      !this.session.user.isTerminated &&
      !this.userRole.claimant
    );
  }

  get reportIntermittentAbsence(): boolean {
    return this.reportIntermittentAbsenceAdditionalCheck;
  }

  get reportIntermittentAbsenceByLob(): boolean {
    return (
      this.reportIntermittenAbsenceByLOB &&
      this.reportIntermittentAbsenceAdditionalCheck
    );
  }

  get reportIntermittentAbsenceAdditionalCheck(): boolean {
    return (
      (!this.session.isDelegating() &&
        (this.userRole.employee || this.userRole.manager)) ||
      (this.session.isDelegating() &&
        this.session.currDelegatee() !== null &&
        this.session.currDelegatee()?.addAbsences)
    );
  }

  get learningCenter(): boolean {
    return this.claimConfigService.getConfigFeatureAccess('learningcenter');
  }

  get reportIntermittenAbsence(): boolean {
    return this.claimConfigService.getConfigFeatureAccess(
      'SUBMITINTERMITTENTABSENCES'
    );
  }

  get reportIntermittenAbsenceByLOB(): boolean {
    return this.claimConfigService.getFeatureAccess(
      'submitintermittentabsences'
    );
  }

  async delegationFeatures(): Promise<DelegationFeatures> {
    const delegationFeatures: DelegationFeatures = {
      showDelegationMenu: false,
      actAsDelegate: false,
      manageOthersDelegates: false,
      manageOwnDelegates: false,
    };
    await this.loadClientConfig();

    delegationFeatures.showDelegationMenu =
      this.claimConfigService.getConfigFeatureAccess('delegation') &&
      (this.userRole.manager || this.userRole.employee);
    if (delegationFeatures.showDelegationMenu) {
      const delegators = await this.delegationService.getDelegators(
        this.session.user.empUnum
      );

      delegationFeatures.actAsDelegate =
        this.clientLevelConfig.configFeatures.features.indexOf(
          'actasdelegate'
        ) > -1 &&
        delegators.delegatees.length > 0 &&
        this.session.user.clientCustomizations.ActAsDelegate;
      this.actAsDelegateSignal.set(delegationFeatures.actAsDelegate);
      delegationFeatures.manageOthersDelegates =
        this.clientLevelConfig.configFeatures.features.indexOf(
          'manageotherdelegate'
        ) > -1 &&
        this.userRole.manager &&
        this.session.user.clientCustomizations.ManageDelegates;
      delegationFeatures.manageOwnDelegates =
        this.clientLevelConfig.configFeatures.features.indexOf(
          'managedelegate'
        ) > -1 &&
        this.userRole.manager &&
        this.session.user.clientCustomizations.ManageDelegates;
    }
    return delegationFeatures;
  }

  /**
   * display Summary view
   * @returns
   */
  summaryView(): boolean {
    return (
      (this.session.user.accessibleRoles.includes('OMNI') ||
        this.session.user.accessibleRoles.includes('OVERSEER')) &&
      !this.session.user.isTerminated &&
      this.session.user.clientCustomizations.SummaryDashboard
    );
  }

  /**
   * display Manager view
   * @returns
   */
  managerView(): boolean {
    return (
      this.session.user.accessibleRoles.includes('SUPERVISOR') &&
      !this.session.user.isTerminated &&
      this.session.user.clientCustomizations.ManagerDashboard
    );
  }

  /**
   * display controller Manager view
   * @returns
   */
  controllerView(): boolean {
    return this.session.user.roles.controller;
  }

  /**
   * display my claims view
   * @returns
   */
  myClaimsView(): boolean {
    return (
      this.session.user.accessibleRoles.includes('EE') &&
      //(this.session.user.role == 'HRM' || ) &&
      this.session.user.role !== 'AUDITOR' &&
      this.session.user.role !== 'CONTROLLER' &&
      this.session.user.clientCustomizations.EmployeeDashboard
    );
  }

  /**
   * display notifications
   * @returns
   */
  get notifications(): boolean {
    return this.session.user.clientCustomizations.Notifications;
  }

  /**
   * display important Contacts
   * @returns
   */
  get importantContacts(): boolean {
    return this.session.user.clientCustomizations.ImportantContacts;
  }

  /**
   * display claim Activity
   * @returns
   */
  get claimActivity(): boolean {
    return this.session.user.clientCustomizations.ClaimActivity;
  }
}

interface DelegationFeatures {
  showDelegationMenu: boolean;
  actAsDelegate: boolean;
  manageOthersDelegates: boolean;
  manageOwnDelegates: boolean;
}
