import {
  AfterViewInit,
  Component,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseComponent,
  SearchResult,
  ConfigurationSetting,
  getUrlParams,
} from 'src/app/shared';
import { BehaviorSubject } from 'rxjs';
import { ClaimService } from '../../services/claim.service';
import {
  ConfigurationService,
  InpageAliasService,
  LogService,
  Session,
} from 'src/app/my-sedgwick';
import { UserDetails } from '../../services/userdetails.service';
import { PharmacyCard } from 'src/app/shared/models/pharmacy-card';
import { ClaimDataService } from 'src/app/shared/services/claim-data.service';
import { ClaimInfo } from 'src/app/shared/models/search-result';

@Component({
  selector: 'app-pharmacy-card-page',
  template: `
    <app-section class="tw-w-full">
      <div *ngIf="!printMode" class="tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        title="Pharmacy Card"
        class="tw-w-full"
        [flat]="printMode"
        [canExpand]="false"
        [headerLevel]="1"
      >
        <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'">
          <ng-template #content>
            <iframe
              class="print-frame"
              *ngIf="!printMode"
              (load)="printLoaded()"
              id="print-frame"
            >
            </iframe>
            <div class="tw-w-full tw-flex tw-flex-wrap">
              <div class="tw-w-full md:tw-w-[40%] titlecase">
                <app-value
                  label="Claimant"
                  layout="col-row-xs"
                  value="{{ claim.name }}"
                >
                </app-value>
              </div>
              <div class="tw-w-full md:tw-w-[60%]" class="app-pb2">
                <app-value
                  *ngIf="printMode"
                  label="Claim"
                  layout="col-row-xs"
                  value="{{
                    (claim.claimDescription | titlecase) +
                      ' - ' +
                      claim.claimNumber
                  }}"
                >
                </app-value>
                <app-value *ngIf="!printMode" label="Claim" layout="col-row-xs">
                  <a
                    value
                    routerLink="/claims"
                    [queryParams]="{
                      claimId: claimId,
                      source: source
                    }"
                    >{{
                      (claim.claimDescription | titlecase) +
                        ' - ' +
                        claim.claimNumber
                    }}</a
                  >
                </app-value>
              </div>
            </div>
            <app-action-row *ngIf="!printMode">
              <app-button
                icon="print"
                [loading]="printing"
                left1
                emphasis="High"
                (click)="print()"
              >
                Print
              </app-button>
            </app-action-row>

            <div id="print-section" class="print-section" *ngIf="!loading">
              <div class="app-pb1 app-pt3">
                <app-header-label class="headingbold" type="Header2"
                  >Front</app-header-label
                >
              </div>

              <div class="card-container" id="print-section" *ngIf="!loading">
                <div class="card-section-1 tw-w-full">
                  The pharmacy benefit card is ONLY to be used for medications
                  prescribed for your work related injury
                </div>
                <div class="card-section-2 tw-w-full tw-flex tw-flex-wrap">
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Claim Number"
                    layout="row"
                    [value]="claim.claimNumber"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Phone"
                    layout="row"
                    [value]="pharmacyCard.pharmacyPhone"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Network"
                    layout="row"
                    [value]="pharmacyCard.network"
                  ></app-value>

                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Type"
                    layout="row"
                    [value]="claim.claimDescription"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="RxBIN"
                    layout="row"
                    [value]="pharmacyCard.bin"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="RxPCN"
                    layout="row"
                    [value]="pharmacyCard.pcn"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="RxGROUP"
                    layout="row"
                    [value]="pharmacyCard.rxGroup"
                  ></app-value>
                </div>
                <div class="card-section-3 tw-w-full tw-flex tw-flex-wrap">
                  <div class="tw-w-full md:tw-w-1/3 print:tw-w-1/3">
                    <img
                      src="./assets/images/pic_medicalcard.png"
                      width="190"
                      height="223"
                      alt=""
                    />
                  </div>
                  <div class="tw-w-full md:tw-w-2/3 print:tw-w-2/3 ">
                    <div
                      class="tw-flex tw-flex-wrap tw-justify-start tw-w-full"
                    >
                      <app-value
                        class="titlecase tw-basis-1/2 md:tw-w-[50%]"
                        label="Name"
                        layout="row"
                        [value]="pharmacyCard.claimantName"
                      ></app-value>
                      <app-value
                        class="titlecase tw-basis-1/2 md:tw-w-[50%]"
                        label="Phone"
                        layout="row"
                        [value]="pharmacyCard.claimantPhone"
                      ></app-value>

                      <app-value
                        class="titlecase tw-basis-1/2 md:tw-w-[50%]"
                        label="Employer"
                        layout="row"
                        [value]="pharmacyCard.employer"
                      ></app-value>
                      <app-value
                        class="titlecase tw-basis-1/2 md:tw-w-[50%]"
                        label="Fax"
                        layout="row"
                        [value]="pharmacyCard.claimantFax"
                      ></app-value>

                      <app-value
                        class="titlecase tw-basis-1/2 md:tw-w-[50%]"
                        label="Date of Injury"
                        layout="row"
                        [value]="pharmacyCard.dateEvent | format : 'date'"
                      ></app-value>
                      <app-value
                        class="titlecase tw-basis-1/2 md:tw-w-[50%]"
                        label="Mailing Address"
                        layout="col"
                      >
                        <span
                          value
                          [innerHTML]="
                            pharmacyCard.claimantMailingAddress | address
                          "
                        >
                        </span>
                      </app-value>

                      <div class=" tw-basis-full ">
                        <div class="app-pb1">
                          <b>Pharmacist Instructions</b>
                        </div>
                        <div class="app-pl3">
                          <ul class="tw-list-disc">
                            <li
                              *ngFor="
                                let instruction of pharmacyCard.pharmacistInstructions
                                  | pipeToBulletPoints
                              "
                            >
                              {{ instruction | sentenceCase }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="!pharmacyCard.participatingPharmacies"
                  class="card-section-4 tw-w-full tw-flex tw-flex-wrap"
                >
                  <div class="tw-w-full tw-text-center app-pb1">
                    Participating pharmacies include but are not limited to:
                  </div>
                  <div class="tw-basis-1/4"></div>
                  <div class="tw-basis-1/4 tw-text-center">Walgreens</div>
                  <div class="tw-basis-1/4 tw-text-center">Walmart</div>
                  <div class="tw-basis-1/4"></div>

                  <div class="tw-basis-1/4"></div>
                  <div class="tw-basis-1/4 tw-text-center">CVS</div>
                  <div class="tw-basis-1/4 tw-text-center">Safeway</div>
                  <div class="tw-basis-1/4"></div>

                  <div class="tw-basis-1/4"></div>
                  <div class="tw-basis-1/4 tw-text-center">Kroger</div>
                  <div class="tw-basis-1/4 tw-text-center">Rite Aid</div>
                  <div class="tw-basis-1/4"></div>
                </div>
                <div
                  *ngIf="pharmacyCard.participatingPharmacies"
                  class="card-section-4 tw-w-full tw-flex tw-flex-wrap"
                >
                  <div class="tw-w-full tw-text-center app-pb1">
                    Participating pharmacies include but are not limited to:
                  </div>
                  <div
                    *ngFor="
                      let pharmacy of pharmacyCard.participatingPharmacies;
                      let i = index
                    "
                    class="tw-basis-1/4 tw-text-center"
                  >
                    <div>{{ pharmacy }}</div>
                  </div>
                </div>
              </div>
              <div class="pagebreak"></div>

              <div class="app-pb1 app-pt3 pbMargins">
                <app-header-label class="headingbold" type="Header2"
                  >Back</app-header-label
                >
              </div>

              <div class="card-container" id="print-section" *ngIf="!loading">
                <div class="card-section-1 tw-w-full">
                  The pharmacy benefit card is ONLY to be used for medications
                  prescribed for your work related injury
                </div>
                <div class="card-section-2 tw-w-full tw-flex tw-flex-wrap">
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Claim Number"
                    layout="row"
                    [value]="claim.claimNumber"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Phone"
                    layout="row"
                    [value]="pharmacyCard.pharmacyPhone"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Network"
                    layout="row"
                    [value]="pharmacyCard.network"
                  ></app-value>

                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="Type"
                    layout="row"
                    [value]="claim.claimDescription"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="RxBIN"
                    layout="row"
                    [value]="pharmacyCard.bin"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="RxPCN"
                    layout="row"
                    [value]="pharmacyCard.pcn"
                  ></app-value>
                  <app-value
                    class="tw-w-[50%] md:tw-w-[33%]"
                    label="RxGROUP"
                    layout="row"
                    [value]="pharmacyCard.rxGroup"
                  ></app-value>
                </div>
                <div class="card-section-3 tw-w-full tw-flex tw-flex-wrap">
                  <div class="tw-w-full md:tw-w-1/3 print:tw-w-1/3">
                    <img
                      src="./assets/images/pic_medicalcard.png"
                      width="190"
                      height="223"
                      alt=""
                    />
                  </div>
                  <div class="tw-w-full md:tw-w-2/3 print:tw-w-2/3 ">
                    <div class="app-pb1">
                      <b>Instructions for Your Medical Providers</b>
                    </div>
                    <div class="app-pl3">
                      <ul class="tw-list-disc">
                        <li>
                          For authorization of services please fax medical
                          records and request to Sedgwick Utilization Review at
                          877-922-7236.
                        </li>
                        <li>
                          Please ensure that the claim number is forwarded with
                          all documentation and medical bills.
                        </li>
                        <li>
                          Please mail medical bills to the Claims
                          {{ examinerLabel }}
                          mailing address listed above.
                        </li>
                        <li>
                          If you have any questions related to claimant care,
                          please contact Sedgwick Utilization Review at
                          866-286-0281.
                        </li>
                        <li>
                          For bill payment status go to
                          providerselfservice.sedgwickcms.net
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card-section-4 tw-w-full tw-flex tw-flex-wrap">
                  <div class="tw-w-full tw-text-center app-pb1">
                    <span><b>Scheduling Services</b></span
                    ><br />
                    <span>
                      For all Sedgwick Specialty Services Contact Provider
                      Connection: 1-87-SEDGWICK (1-877-334-9425)</span
                    >
                  </div>

                  <div class="hidden md:block md:tw-w-1/4"></div>
                  <div class="tw-w-1/2 md:tw-basis-1/4  tw-text-center">
                    Physical Therapy
                  </div>
                  <div class="tw-w-1/2 md:tw-basis-1/4  tw-text-center">
                    Transportation
                  </div>
                  <div class="hidden md:block md:tw-w-1/4"></div>

                  <div class="hidden md:block md:tw-w-1/4"></div>
                  <div class="tw-w-1/2 md:tw-basis-1/4  tw-text-center">
                    Durable Medical Equipment
                  </div>
                  <div class="tw-w-1/2 md:tw-basis-1/4  tw-text-center">
                    Translation
                  </div>
                  <div class="hidden md:block md:tw-w-1/4"></div>

                  <div class="hidden md:block  md:tw-w-1/4"></div>
                  <div class="tw-w-1/2 md:tw-basis-1/4  tw-text-center">
                    Diagnostics
                  </div>
                  <div class="tw-w-1/2 md:tw-basis-1/4  tw-text-center">
                    Dental
                  </div>
                  <div class="hidden md:block  md:tw-w-1/4"></div>
                </div>
              </div>
              <div class="app-pb8"></div>
            </div>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      .card-container {
        max-width: 800px;
        border: 1px solid #f2f2f2;
        border-radius: 20px;
      }
      .card-section-1 {
        border-radius: 20px 20px 0 0;
        background-color: #035e81;
        color: white;
        padding: 20px;
      }
      .card-section-2 {
        background-color: #f1f4f7;
        padding: 20px;
        color: #171c20;
      }
      .card-section-3 {
        background-color: white;
        padding: 20px;
        color: #171c20;
      }
      .card-section-4 {
        background-color: #67707a;
        color: white;
        padding: 20px;
        border-radius: 0 0 20px 20px;
      }
      .print-frame {
        position: absolute;
        left: -100%;
        height: 100px;
        width: 100px;
      }
      .print-section {
        print-color-adjust: exact;
      }
      @media print {
        .pagebreak {
          clear: both;
          page-break-after: always;
          break-after: page;
        }
      }
      .titlecase {
        text-transform: lowercase;
        text-transform: capitalize;
      }
      .pbMargins {
        margin-top: 10em;
      }
    `,
  ],
})
export class PharmacyCardPageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  claim: SearchResult = <SearchResult>{};

  claimId: string;
  source: string;
  lob: string;
  isIncidentalClient: boolean = false;
  pharmacyCard: PharmacyCard;
  loading = true;
  printMode = false;
  printing = false;
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  claimConfig: ConfigurationSetting;
  examinerLabel: string;
  aliases: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public session: Session,
    public userdetails: UserDetails,
    public logService: LogService,
    public claimService: ClaimService,
    private claimDataService: ClaimDataService,
    private configurationService: ConfigurationService,
    public inpageAliasService: InpageAliasService
  ) {
    super();
  }
  ngAfterViewInit(): void {}
  async ngOnInit() {
    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;

    this.subs.sink = this.claimDataService.currentClaim$.subscribe((claim) => {
      if (claim) {
        this.claim = claim;
      }
    });

    if (
      this.claim === null ||
      this.claim === undefined ||
      Object.keys(this.claim).length === 0
    ) {
      this.claim = await this.claimService.getSummary(
        this.source,
        this.claimId
      );
    }

    this.setClaim(this.claim);
    this.loadClaim();

    this.aliases = JSON.parse(sessionStorage.getItem('aliases'));
    if (!this.aliases) {
      this.aliases = this.configurationService.GetConfig.configFeatures.aliases;
      sessionStorage.setItem('aliases', JSON.stringify(this.aliases));
    }

    if (!this.aliases) this.aliases = sessionStorage.getItem('aliases');
    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingularPossessive',
      this.aliases
    );

    this.subs.sink = this.route.url.subscribe((url) => {
      this.printMode = url[0].path === 'pharmacy-card-print';
    });
  }

  override ngOnDestroy() {
    sessionStorage.removeItem('aliases');
  }

  private async setClaim(claim: SearchResult) {
    if (claim === null || claim === undefined) {
      return;
    }
    this.claimId = claim.claimId;
    this.claimInfo$.next(claim);
    this.claim = claim;
    this.lob = claim.lob;
  }

  print() {
    this.printing = true;
    let el: any = document.getElementById('print-frame');
    this.claimDataService.setClaim(this.claim);
    el.contentWindow.location = `/pharmacy-card-print?claimId=${this.claim.claimId}&source=${this.claim.source}`;
  }

  private async loadClaim() {
    const pharmacyCardRequest = this.claimService.getPharmacyCard(
      this.claim.source,
      this.claim.claimId,
      this.claim.lob
    );

    this.pharmacyCard = await pharmacyCardRequest;
    this.loading = false;

    if (this.printMode) {
      setTimeout(() => {
        this.printing = true;
        window.print();
      }, 500);
    }
  }

  printLoaded() {
    this.printing = false;
  }
}
