import { Component, ViewChild } from '@angular/core';
import {
  DetailSearchConfiguration,
  OmniApi,
  SearchRequest,
} from 'src/app/my-sedgwick';
import {
  ListComponent,
  LoadingState,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';

@Component({
  selector: 'app-return-to-work-list-page',
  template: `
    <app-section>
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        title="Returning to Work"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
      >
        <div class="tw-min-h-[510px] tw-w-full">
          <app-detail-search-form
            #searchForm
            [config]="config"
            (search)="search($event)"
            (clear)="clear()"
            [loading]="loading"
          >
            <ng-template #AccommodationMsg>
              <div class="app-pt1" *ngIf="showMsg">
                <app-toast type="Info">
                  If an accommodation has a related leave claim, then the
                  returning to work item will appear with the leave claim. If
                  you don't find what you are looking for, please refine your
                  search.
                </app-toast>
              </div>
            </ng-template>
            <ng-template #results>
              <app-search-count-row
                [count]="returnToWorks.length"
                resultSingle="item"
                resultPlural="items"
              ></app-search-count-row>
              <app-return-to-work
                [returnToWork]="returnToWorks"
                class="tw-w-full"
                (export)="searchForm.appendExport($event)"
              />
            </ng-template>
          </app-detail-search-form>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: ``,
})
export class ReturnToWorkListPageComponent {
  userAction = UserActions.ViewOwnMgrDb;
  config = new DetailSearchConfiguration();

  returnToWorks: any[];
  showMsg: boolean = false;

  loading: LoadingState;

  @ViewChild('searchForm', { static: false }) searchForm: ListComponent;

  constructor(private omniApi: OmniApi, private snackbar: SnackbarService) {
    this.config.key = 'omni-dashboard:return-to-work';
    this.config.ssn.visible = false;
    this.config.supervisor.visible = false;
    this.config.includeIndirect.visible = false;
    this.config.employeeId.widthPercent = 25;
    this.config.initialSearch = true;
    this.config.initialSearchResultsOnClear = true;
    this.config.startDate.visible = true;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = true;
    this.config.expanded = false;
    this.config.payType.visible = false;
  }

  async search(request: SearchRequest) {
    this.loading = 'Loading';
    this.returnToWorks = await this.omniApi.getRtws(request.getParameters());
    this.loading = this.returnToWorks.length > 0 ? 'Loaded' : 'Empty';
    if (this.searchForm.formGroup.value.lob?.includes('AD')) {
      //this.snackbar.show(
      //  "If an accommodation has a related leave claim, then the returning to work item will appear with the leave claim. If you don't find what you are looking for, please refine your search.",
      //  SnackbarType.Info
      //);
      this.showMsg = true;
    } else {
      this.showMsg = false;
    }
  }
  async clear() {
    this.showMsg = false;
  }
}
