import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-label',
  template: `
    @if(type === 'Image'){
    <img [src]="imageSrc" class="tw-w-full tw-animate-pulse" />
    } @else if(type === 'Box') {
    <div
      class="box tw-w-full tw-py-2 tw-animate-pulse"
      [style.height.px]="_height"
    ></div>
    } @else if(type === 'Skeleton') {
    <div
      class="skeleton tw-w-full tw-py-4 tw-animate-pulse"
      [style.height.px]="_skeletonHeight"
    ></div>
    } @else {
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full"
    >
      <div *ngIf="type === 'Dot'" class="dot-loader-slate-2"></div>
      <div *ngIf="type === 'DotWhite'" class="dot-loader-white-2"></div>
      <div class="tagline-header app-mt3 app-pb1">{{ header }}</div>
      <div class="tagline-message app-pb3" *ngIf="message">
        {{ message }}
      </div>
    </div>
    }
  `,
  styles: [
    `
      .tagline-header {
        font-size: 18px;
        font-weight: bold;
        color: var(--color-slate);
      }

      .tagline-message {
        color: var(--color-slate);
      }

      .box {
        background-color: var(--color-light-grey);
      }

      .skeleton {
        background: repeating-linear-gradient(
          to bottom,
          white 0px,
          white 20px,
          var(--color-light-grey) 20px,
          var(--color-light-grey) 40px
        );
      }

      /* https://cssloaders.github.io/ */
      .dot-loader-white-2,
      .dot-loader-white-2:before,
      .dot-loader-white-2:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: bblFadInOut 1.8s infinite ease-in-out;
      }
      .dot-loader-white-2 {
        color: #fff;
        font-size: 7px;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
      }
      .dot-loader-white-2:before,
      .dot-loader-white-2:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .dot-loader-white-2:before {
        left: -3.5em;
        animation-delay: -0.32s;
      }
      .dot-loader-white-2:after {
        left: 3.5em;
      }

      .dot-loader-slate-2,
      .dot-loader-slate-2:before,
      .dot-loader-slate-2:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: bblFadInOut 1.8s infinite ease-in-out;
      }
      .dot-loader-slate-2 {
        color: var(--color-slate);
        font-size: 7px;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
      }
      .dot-loader-slate-2:before,
      .dot-loader-slate-2:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .dot-loader-slate-2:before {
        left: -3.5em;
        animation-delay: -0.32s;
      }
      .dot-loader-slate-2:after {
        left: 3.5em;
      }

      @keyframes bblFadInOut {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
    `,
  ],
})
export class LoadingLabelComponent {
  @Input()
  header: string;

  @Input()
  message: string;

  @Input()
  iconColor = 'var(--color-slate)';

  @Input()
  type: LoadingLabelType;

  @Input()
  imageSrc: string;

  _height: number = 200;

  @Input()
  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = value;
    this._skeletonHeight = Math.round(value / 40) * 40;
  }

  _skeletonHeight: number = 200;
}

export type LoadingLabelType =
  | 'Dot'
  | 'DotWhite'
  | 'Label'
  | 'Box'
  | 'Skeleton'
  | 'Image';
