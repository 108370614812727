import { trigger } from '@angular/animations';

// export const fadeInAnimation = trigger('fadeInAnimation', [
//   transition(':enter', [
//     style({ height: '*', opacity: 0 }),
//     animate('150ms ease-in', style({ height: '*', opacity: 1 })),
//   ]),
// ]);

export const fadeInAnimation = trigger('fadeInAnimation', []);
