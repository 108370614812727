import { Component, OnInit } from '@angular/core';
import {
  DetailSearchConfiguration,
  SearchRequest,
  SearchService,
} from 'src/app/my-sedgwick';
import { LoadingState, SearchResult } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';

@Component({
  selector: 'app-open-claims-page',
  template: `
    <app-section>
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        title="Open claims"
        [largeHeader]="true"
        [canExpand]="false"
        class="tw-w-full"
      >
        <div class="tw-min-h-[510px] tw-w-full">
          <app-detail-search-form
            #searchForm
            [config]="config"
            (search)="search($event)"
            [loading]="loading"
          >
            <ng-template #results>
              <app-search-count-row
                [count]="openClaims.length"
              ></app-search-count-row>
              <app-search-results-list
                [searchResults]="openClaims"
                [hideCustomFields]="true"
                class="tw-w-full"
                (export)="searchForm.appendExport($event)"
                excelName="Open Claims.xlsx"
                sheetName="Open Claims"
              />
            </ng-template>
          </app-detail-search-form>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: ``,
})
export class OpenClaimsPageComponent implements OnInit {
  userAction = UserActions.ViewOwnMgrDb;
  config = new DetailSearchConfiguration();
  loading: LoadingState;
  openClaims: SearchResult[];

  constructor(private searchService: SearchService) {
    this.config.key = 'omni-dashboard:open-claims';
    this.config.ssn.visible = false;
    this.config.status.visible = false;
    this.config.employeeId.widthPercent = 25;
    this.config.initialSearch = true;
    this.config.initialSearchResultsOnClear = true;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = true;
    this.config.expanded = false;
    this.config.payType.visible = false;
  }

  ngOnInit(): void {
    this.config.lob.widthPercent = 33;
    this.config.startDate.widthPercent = 33;
    this.config.endDate.widthPercent = 33;
  }

  async search(request: SearchRequest) {
    this.loading = 'Loading';
    const params = request.getParameters();
    params.status = ['O', 'I'];
    params.excludeSupervisor = true;
    const searchInformation = await this.searchService.getSearchResults(params);
    this.openClaims = searchInformation.searchResults;
    this.loading = this.openClaims.length > 0 ? 'Loaded' : 'Empty';
  }
}
