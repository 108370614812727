import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
export interface PhoneFormValues {
  mobilenumber: string;
}
@Component({
  selector: 'app-phone-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-container role="none">
    <br />
    <br />
    <div class="tw-w-[68%]">
      <ul role="none" class="md:tw-w-[70%]">
        <li role="none">
          <span *ngIf="!isFromCommPrefComponent" class="text-email"
            >Enter the mobile phone number including area code where you would
            like to recieve your Multi-Factor Authentication codes. Standard
            text messaging rates will apply.</span
          >
          <span *ngIf="isFromCommPrefComponent" class="text-email"
            >Enter the mobile phone number including area code where you would
            like to receive your status updates. Standard text messaging rates
            will apply.</span
          >
        </li>
      </ul>
    </div>
    <div class="tw-w-[60%]" id="PhoneComp">
      <br />
      <form [formGroup]="phoneGroup">
        <ul role="none" class="md:tw-w-[70%]">
          <li role="none">
            <mat-form-field>
              <mat-label>Phone Number</mat-label>
              <input
                matInput
                type="tel"
                required
                id="mobilenumber"
                #mobilenumber
                formControlName="mobilenumber"
                ngDefaultControl
                fxFlex="33"
                fxFlex.lt-md="100"
                pattern="^[0-9]{3}-[0-9]{3}-[0-9]{4}$"
                (keypress)="insertMobileDash('mobilenumber', $event)"
              />
              <mat-error
                *ngIf="phoneGroup.get('mobilenumber').hasError('pattern')"
              >
                <span>Error : Please enter numbers only.</span>
                <br />
                <span
                  >Error : Please enter a valid phone number with area
                  code.</span
                >
              </mat-error>
              <mat-error
                *ngIf="phoneGroup.get('mobilenumber').hasError('required')"
              >
                <span>Error : Phone number is required.</span>
              </mat-error>
            </mat-form-field>
          </li>
        </ul>
      </form>
    </div>
  </ng-container> `,
  styles: [
    `
      #phoneComp ::ng-deep .mat-mdc-form-field-error-wrapper {
        margin-top: -24px !important;
        position: inherit !important;
        margin-bottom: 7px !important;
      }

      .flex-outer,
      .flex-inner {
        list-style-type: none;
        padding: 0;
      }

      .flex-outer li,
      .flex-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .flex-inner {
        padding: 0 8px;
        justify-content: space-between;
      }

      .flex-outer > li:not(:last-child) {
        margin-bottom: 20px;
      }

      .flex-outer li label,
      .flex-outer li p {
        padding: 8px;
        letter-spacing: 0.09em;
      }

      .flex-outer div {
        padding: 8px;
        font-weight: 500;
        letter-spacing: 0.09em;
      }

      .flex-outer > li > label,
      .flex-outer li p {
        flex: 1 0 120px;
        max-width: 280px;
      }

      .flex-outer > li > label + *,
      .flex-inner {
        flex: 1 0 220px;
      }

      .flex-outer li p {
        margin: 0;
      }
      .input {
        color: #67707a;
        border-color: #67707a;
      }
      .input:focus {
        color: #171c20;
        border: #007dbc;
      }

      .input:hover {
        color: #67707a;
        border: #3f464d;
      }

      .error-message-style {
        margin-bottom: 2px;
      }

      .flex-outer li button {
        margin-left: auto;
      }

      .flex-inner li {
        width: 100px;
      }

      .mb-20 {
        margin-bottom: 20px;
      }
      .mt-20 {
        margin-top: 20px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-underline {
        border-bottom: 1px solid var(--dark-blue);
      }
      .light-blue {
        color: #0075a3;
      }
      label {
        margin-left: 3%;
      }

      .mat-mdc-form-field {
        width: 350px !important;
      }

      .flex-container {
        display: flex;
        flex-direction: row;
      }
      .flex-left {
        width: 20%;
      }
      .flex-left1 {
        width: 33%;
      }

      .flex-mid {
        width: 17%;
      }
      .flex-mid1 {
        width: 33%;
      }
      .flex-right {
        width: 50%;
      }
      .flex-right1 {
        width: 33%;
      }
      .text-email {
        font-weight: bold;
      }

      @media (max-width: 480px) {
        .flex-left {
          width: 15%;
        }
        .flex-mid {
          width: 0%;
        }
        .flex-right {
          width: 55%;
        }
        ::ng-deep .mat-mdc-text-field-wrapper {
          width: 100%;
        }
      }
    `,
  ],
})
export class PhoneComponent implements OnInit {
  @Input() formGroupName: string;
  phoneGroup: FormGroup;
  mobileGroupEnterCode: FormGroup;
  @Input()
  isCommPrefComp: boolean = false;
  @Input()
  showPhoneForm: boolean = true;
  @Output()
  phoneAuthCodeChanged = new EventEmitter();

  @ViewChild('mobilenumber')
  mobileNumberInput: ElementRef;

  isFromCommPrefComponent: boolean = false;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change2: SimpleChange = changes['isCommPrefComp'];
    if (change2 != undefined) {
      if (change2.currentValue != null && change2.currentValue != undefined) {
        this.isFromCommPrefComponent = change2.currentValue;
      }
    }
  }

  ngOnInit(): void {
    this.phoneGroup = this.rootFormGroup.control.get('phoneGroup') as FormGroup;
    this.mobileGroupEnterCode = this.rootFormGroup.control.get(
      'mobileGroupEnterCode'
    ) as FormGroup;
    // this.createFormControl();
    this.changeDetector.detectChanges();
  }

  createFormControl() {
    this.mobileGroupEnterCode.addControl(
      'enterAuthCode',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{6}$'),
      ])
    );
    this.phoneGroup.addControl(
      'mobilenumber',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
      ])
    );
  }
  onValueChange() {
    this.phoneAuthCodeChanged.emit(true);
  }
  insertMobileDash(text: string, event: any) {
    var key = event.keyCode || event.which;

    if (key > 47 && key < 58) {
      var inputValue = this.phoneGroup.get(text).value;
      if (inputValue != null && inputValue != '') {
        if (inputValue.trim().length == 3 && key !== 8) {
          inputValue = inputValue + '-';
          this.phoneGroup.get(text).setValue(inputValue);
        }
        if (inputValue.trim().length == 7 && key !== 8) {
          inputValue = inputValue + '-';
          this.phoneGroup.get(text).setValue(inputValue);
        }
      }
    } else {
      return false;
    }
    return true;
  }
}
