export class NoteRequest {
  contractNumber: string;
  claimUid: string;
  uid: string;
  noteType: string;
  noteMessage: string;
  noteLevel: string;
  examiner: string;
  createdBy: string;
  auditNoteFlag: boolean;
  user_ID: string;
  user_Name: string;
  needDiary: boolean;
  fileNumber: string;
}
