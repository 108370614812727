import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchResult, deepCopy } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import {
  ConfigurationService,
  Excel,
  ExportRequest,
  Session,
  UserRoleService,
} from '../..';

@Component({
  selector: 'app-search-results-list',
  template: `
    <app-list
      [dataSource]="searchResults"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      loadingMessage=""
      noDataIcon="search"
      noDataMessage="No results"
      noDataThinMessage="There are no results that match the search"
      [pageSizeOptions]="[5, 10, 25]"
      [pageSize]="5"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these search results as a XLSX file"
      (export)="_export()"
      [tableAriaLabel]="'Claim Search Results'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimDescription"
            label="Claim"
            sortLabel="Claim Type"
            class="tw-w-full md:tw-w-[30%]"
            [names]="['claimDescription', 'claimNumber']"
          ></app-list-header>
          <app-list-header
            name="claimNumber"
            label="Claim Number"
            class="tw-hidden"
          ></app-list-header>
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[25%]"
            [names]="['empId']"
          ></app-list-header>
          <app-list-header
            name="status"
            label="Status"
            class="tw-w-full md:tw-w-[20%]"
            [names]="['status', 'subStatus']"
          >
          </app-list-header>
          <app-list-header
            name="openDate"
            label="Details"
            filterType="Date"
            class="tw-w-full md:tw-w-[25%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[30%]">
          <app-value label="Claim" layout="row-xs" [tabIndex]="0">
            <app-claim-label
              value
              [claim]="element"
              layout="row"
              [disabled]="!claimLabelLink"
            ></app-claim-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label
              value
              [claimant]="element"
              [disabled]="!claimantLabelLink"
            >
            </app-claimant-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value
            label="Status"
            [tabindex]="element.status ? '0' : '1'"
            [value]="
              element.status +
              (element.subStatus && !userRole.controller
                ? ' - ' + element.subStatus
                : '')
            "
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value
            class="tw-w-full"
            [label]="
              element.lineOfBusiness === 'LV' ||
              element.lineOfBusiness === 'DS' ||
              element.lineOfBusiness === 'AD'
                ? 'Begin Date'
                : 'Date of Loss'
            "
            [tabindex]="element.lineOfBusiness ? '0' : '1'"
            [value]="
              (element.lineOfBusiness === 'WC' ||
              element.lineOfBusiness === 'DS' ||
              element.lineOfBusiness === 'AD' ||
              element.lineOfBusiness === 'AU' ||
              element.lineOfBusiness === 'GL' ||
              element.lineOfBusiness === 'PR'
                ? element.openDate
                : element.source === 'L'
                ? element.firstAbsentDate
                : element.beginDate
              ) | format : 'date'
            "
            layout="row"
          ></app-value>
          <app-value
            class="tw-w-full"
            *ngIf="element.lineOfBusiness === 'LV'"
            label="Leave Type"
            [value]="element.leaveType"
            [tabindex]="element.leaveType ? '0' : '1'"
            layout="row"
          >
          </app-value>
          @if(session.user.employeeType && !hideCustomFields){
          <app-value
            class="tw-w-full"
            *ngIf="element.market"
            label="Market"
            [tabindex]="element.market ? '0' : '1'"
            [value]="element.market"
            layout="row"
          >
          </app-value>
          <app-value
            class="tw-w-full"
            *ngIf="element.facility"
            label="Facility"
            [tabindex]="element.facility ? '0' : '1'"
            [value]="element.facility"
            layout="row"
          >
          </app-value>
          @if(showWorkSchedule){
          <a
            class="tw-w-full"
            *ngIf="element.source !== 'R'"
            label="Work schedule"
            [queryParams]="{
              claimId: element.claimId,
              source: element.source
            }"
            routerLink="/my-work-schedule"
            >Work schedule</a
          >
          } }
        </td>
      </tr>
    </ng-template>
  `,
  styles: ``,
})
export class SearchResultsListComponent implements OnInit {
  @Input()
  searchResults: SearchResult[];

  @Input()
  hideCustomFields: boolean = false;

  @Input()
  excelName: string = 'Claim Search.xlsx';

  @Input()
  sheetName: string = 'Claim Search';

  @Output()
  export = new EventEmitter<ExportRequest>();

  userAction = UserActions.ViewOwnMgrDb;

  claimantLabelLink = false;
  claimLabelLink = false;
  showWorkSchedule = false;

  constructor(
    public session: Session,
    private excel: Excel,
    public userRole: UserRoleService,
    public claimConfigService: ConfigurationService
  ) {
    this.claimantLabelLink = this.userRole.manager || this.userRole.auditor;
    this.claimLabelLink = this.userRole.manager || this.userRole.controller;
  }

  async ngOnInit() {
    await this.session.loadUser();
    this.showWorkSchedule =
      this.claimConfigService.getConfigFeatureAccess('customworkschedule');
  }

  _export() {
    const request = new ExportRequest();
    const claimRows = deepCopy(this.searchResults);
    request.fileName = this.excelName;

    for (const claim of claimRows) {
      if (claim.lineOfBusiness === 'LV') {
        claim.openDate = null;
        if (claim.source === 'L') {
          claim.beginDate = claim.firstAbsentDate;
        }
      }

      if (claim.lineOfBusiness === 'LV' && claim.source === 'L') {
        claim.beginDate = claim.firstAbsentDate;
      }

      if (claim.lineOfBusiness === 'DS') {
        claim.beginDate = claim.openDate;
        claim.openDate = null;
      }
    }

    request.sheets = [
      {
        name: this.sheetName,
        rows: claimRows,
        headers: [
          { label: 'Line of Business', value: 'lineOfBusiness' },
          { label: 'Claim Type', value: 'claimDescription' },
          { label: 'Claim Number', value: 'claimNumber' },
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'empId' },
          { label: 'Status', value: 'status' },
          { label: 'Substatus', value: 'subStatus' },
          {
            label: 'Date of Loss',
            value: 'openDate',
            format: 'date',
          },
          { label: 'Begin Date', value: 'beginDate', format: 'date' },
          { label: 'Leave Type', value: 'leaveType' },
        ],
      },
    ];

    if (this.session.user.employeeType) {
      request.sheets[0].headers.push(
        { label: 'Market', value: 'market' },
        { label: 'Facility', value: 'facility' }
      );
    }

    this.export.emit(request);
    this.excel.export(request);
  }
}
