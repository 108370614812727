import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SnackbarType } from '../../services/snackbar.service';

@Component({
  selector: 'app-snackbar-dialog',
  template: `
    <app-action-row>
      <div left1>
        {{ data.message }}
      </div>
      @if(data.dismiss){
      <a
        right1
        tabindex="0"
        [style.color]="linkColor"
        (click)="snackBarRef.dismiss()"
      >
        Dismiss
      </a>
      }
    </app-action-row>
  `,
  styles: [``],
})
export class SnackbarDialogComponent {
  linkColor: string;

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.linkColor = data.type === SnackbarType.Basic ? '' : 'white';
  }
}

export class SnackbarData {
  style: Array<string>;
  message: string;
  dismiss: boolean;
  type: SnackbarType;
}
