import { Injectable, WritableSignal, signal } from '@angular/core';
import { SearchOption } from '../components/search/quick-search.component';

@Injectable({
  providedIn: 'root',
})
export class QuickSearchService {
  currentSearchOption: WritableSignal<SearchOption> = signal(null);

  getSearchOption():SearchOption {
    return this.currentSearchOption();
  }
  constructor() {}
}
