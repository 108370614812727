import { Component, Inject, ViewChild, Input, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent, DialogComponent } from 'src/app/shared';
import { AbsenceService } from '../../services/absence.service';
import { IncidentalAbsence } from '../../models/incidental-absence';
import { Session, WorkSchedule, WorkScheduleService } from '../..';
import { WorkWeek } from '../../models/work-week';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';

@Component({
  selector: 'app-incidental-work-schedule-dialog',
  template: `
    <app-dialog
      #submitAbsence
      title="Incidental Work Schedule"
      ariaLabel="Confirmation dialog"
      [width]="1000"
      [showActions]="true"
      [showHeader]="true"
      confirmLabel="Submit"
      btnConfirmAltText="Submit"
      btnConfirmTitleText="Submit"
      cancelLabel="Cancel"
      btnCloseAltText="Cancel"
      btnCloseTitleText="Cancel"
      [showCancel]="!this.submitSuccessful && !submitted"
      [showConfirm]="!this.submitSuccessful && !submitted"
      (confirmEvent)="submit()"
      (cancelEvent)="cancel()"
    >
      <!--       <app-dialog-container
        id="outerDialog"
        class="container"
      > -->
      <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'" [width]="90">
        <ng-template #content>
          <app-http-error
            class="tw-w-full"
            [httpErrorResponse]="null"
          ></app-http-error>
          <form
            [formGroup]="formGroup"
            (ngSubmit)="(null)"
            class="tw-flex tw-flex-wrap tw-w-full"
            style="max-height:450px;"
          >
            <ng-container *ngIf="!submitSuccessful && !submitted">
              <div class="tw-w-full app-pb1">
                <a
                  (click)="
                    openClient(
                      absence.empUnum,
                      absence.firstName + ' ' + absence.lastName,
                      absence.empId
                    )
                  "
                  *ngIf="!userOwnsAbsence; else noLink"
                >
                  <span
                    >{{ absence.firstName }} {{ absence.lastName }} -
                    {{ absence.empId }}</span
                  >
                </a>
                <ng-template #noLink>
                  <b
                    >{{ absence.firstName }} {{ absence.lastName }} -
                    {{ absence.empId }}</b
                  >
                </ng-template>
                <br />
                <br />
                We need to gather your normal work schedule.<br />
                <ul>
                  <li>• Enter your scheduled hours of work for each day.</li>
                  <li>• Use zero hours for any days you are not scheduled.</li>
                  <li>• Week 1 represents the current work week.</li>
                  <li>• Week 2 represents the following work week.</li>
                </ul>
                <br />
              </div>
              <div
                formArrayName="weeks"
                class="tw-w-full tw-pt-4"
                *ngFor="let week of weeks.controls; let i = index"
              >
                <div
                  class="tw-w-full tw-flex  tw-flex-wrap tw-pb-4"
                  [formGroupName]="i"
                >
                  <div class="tw-w-full sm:tw-w-[8%]">
                    <div class="tw-font-bold tw-pt-4">Week {{ i + 1 }}</div>
                  </div>

                  <app-text-control
                    formControlName="sun"
                    label="Sunday"
                    validationControl="Sunday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <app-text-control
                    formControlName="mon"
                    label="Monday"
                    validationControl="Monday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <app-text-control
                    formControlName="tue"
                    label="Tuesday"
                    validationControl="Tuesday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <app-text-control
                    formControlName="wed"
                    label="Wednesday"
                    validationControl="Wednesday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <app-text-control
                    formControlName="thu"
                    label="Thursday"
                    validationControl="Thursday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <app-text-control
                    formControlName="fri"
                    label="Friday"
                    validationControl="Friday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <app-text-control
                    formControlName="sat"
                    label="Saturday"
                    validationControl="Saturday"
                    ngDefaultControl
                    [formGroup]="week"
                    mask="separator.2"
                    class="tw-w-[33%] sm:tw-w-[12%]"
                  >
                  </app-text-control>
                  <div class="tw-w-[33%] sm:tw-w-[8%]">
                    <div class="tw-pt-4">
                      <a
                        class="tw-px-1"
                        (keyup.enter)="copyWeek(week)"
                        (click)="copyWeek(week)"
                        tabindex="0"
                        [attr.aria-label]="'Copy week ' + (i + 1)"
                        >Copy</a
                      >

                      <a
                        (click)="pasteWeek(week)"
                        (keyup.enter)="pasteWeek(week)"
                        [attr.aria-label]="'Paste into week ' + (i + 1)"
                        tabindex="0"
                        >Paste</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="submitSuccessful && !submitted">
              <strong>Please review your changes.</strong><br />
              <p>
                <strong
                  >{{ absence.firstName }} {{ absence.lastName }} -
                  {{ absence.empId }}</strong
                >
              </p>
              <div
                formArrayName="weeks"
                class="tw-w-full"
                *ngFor="let week of weeks.controls; let i = index"
              >
                <p>
                  <strong>Week {{ i + 1 }}: &nbsp;</strong>
                  SUN: {{ week.get('sun')?.value }} &nbsp; MON:
                  {{ week.get('mon')?.value }} &nbsp; TUE:
                  {{ week.get('tue')?.value }} &nbsp; WED:
                  {{ week.get('wed')?.value }} &nbsp; THU:
                  {{ week.get('thu')?.value }} &nbsp; FRI:
                  {{ week.get('fri')?.value }} &nbsp; SAT:
                  {{ week.get('sat')?.value }} &nbsp;
                </p>
              </div>
            </ng-container>
            <div class="tw-w-full" *ngIf="submitted && submitSuccessful">
              <app-toast type="Success">
                Your changes have been submitted and will be available soon.
              </app-toast>
            </div>
            <div class="tw-w-full" *ngIf="submitted && !submitSuccessful">
              <app-toast type="Error"> Submission Failed </app-toast>
            </div>
          </form>
        </ng-template>
      </app-loading-panel>
      <!--       </app-dialog-container> -->
    </app-dialog>
  `,
  styles: [``],
})
export class IncidentalWorkScheduleDialogComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('submitAbsence', { static: false }) submitAbsence: DialogComponent;

  @Input()
  dialogRef: MatDialogRef<any>;

  @Input()
  absence: IncidentalAbsence;

  loading: boolean = true;
  userOwnsAbsence: boolean = false;
  formGroup: FormGroup;
  showError: boolean = false;
  responseSuccess: boolean = false;
  response: any;
  copiedWeek: any;
  InvalidDecimalData: string = 'Invalid value';
  schedule: WorkSchedule;
  isReviewing: boolean = true;
  reviewed: boolean = false;
  submitted: boolean = false;
  submitSuccessful: boolean = false;
  submitFail: boolean = false;
  inputValidators: Array<any> = [
    Validators.required,
    Validators.max(20),
    customvalidations.IsNumeric,
  ];

  get weeks(): FormArray<FormGroup> {
    return this.formGroup.get('weeks') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public absenceService: AbsenceService,
    public workScheduleService: WorkScheduleService,
    private session: Session,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();

    this.formGroup = this.fb.group({
      program: [null],
      dayOfWeek: [null],
      weeks: this.fb.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.userOwnsAbsence =
      this.session.user.empUnum === this.absence.empUnum ? true : false;

    this.schedule = await this.workScheduleService.GetIncidentalWorkSchedule(
      this.absence.empUnum,
      this.absence.absenceNumber
    );

    let count = 0;
    this.schedule.workWeeks.forEach((_: WorkWeek) => {
      const week = this.fb.group({
        mon: [_.mondayMinutes / 60, this.inputValidators],
        tue: [_.tuesdayMinutes / 60, this.inputValidators],
        wed: [_.wednesdayMinutes / 60, this.inputValidators],
        thu: [_.thursdayMinutes / 60, this.inputValidators],
        fri: [_.fridayMinutes / 60, this.inputValidators],
        sat: [_.saturdayMinutes / 60, this.inputValidators],
        sun: [_.sundayMinutes / 60, this.inputValidators],
      });
      this.weeks.push(week);
      count++;
    });

    // show at least two weeks (max set at server)
    const min = 2;
    for (let c = count; c < min; c++) {
      const week = this.fb.group({
        mon: [null, this.inputValidators],
        tue: [null, this.inputValidators],
        wed: [null, this.inputValidators],
        thu: [null, this.inputValidators],
        fri: [null, this.inputValidators],
        sat: [null, this.inputValidators],
        sun: [null, this.inputValidators],
      });
      this.weeks.push(week);
    }

    this.loading = false;
  }

  copyWeek(week: FormGroup) {
    this.copiedWeek = week.value;
  }

  pasteWeek(week: FormGroup) {
    if (this.copiedWeek) {
      week.setValue(this.copiedWeek);
    }
  }

  async submit() {
    for (let w = 0; w < this.weeks.value.length; w++) {
      this.schedule.workWeeks[w].mondayMinutes = Math.floor(
        this.weeks.value[w].mon * 60
      );
      this.schedule.workWeeks[w].tuesdayMinutes = Math.floor(
        this.weeks.value[w].tue * 60
      );
      this.schedule.workWeeks[w].wednesdayMinutes = Math.floor(
        this.weeks.value[w].wed * 60
      );
      this.schedule.workWeeks[w].thursdayMinutes = Math.floor(
        this.weeks.value[w].thu * 60
      );
      this.schedule.workWeeks[w].fridayMinutes = Math.floor(
        this.weeks.value[w].fri * 60
      );
      this.schedule.workWeeks[w].saturdayMinutes = Math.floor(
        this.weeks.value[w].sat * 60
      );
      this.schedule.workWeeks[w].sundayMinutes = Math.floor(
        this.weeks.value[w].sun * 60
      );
    }
    await this.workScheduleService.UpdateIncidentalWorkSchedule(
      this.schedule,
      this.absence.empUnum
    );

    this.submitted = true;
    this.submitSuccessful = true;
  }

  cancel() {
    this.dialogRef.close();
  }

  /*   review() {
    if (this.formGroup.valid) {
      this.isReviewing = true;
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  back() {
    this.isReviewing = false;
  } */

  openClient(empUnum: string, name: string, empId: string) {
    this.absenceService.navigateToAbsences(empUnum, name, empId);
    this.dialog.closeAll();
  }
}
