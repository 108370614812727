import { Injectable } from '@angular/core';
import { AnswersKey, TemplateValue } from 'src/app/my-sedgwick';
import { FormContent, SearchResult } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { SendMessage } from '../../models/Communications';

@Injectable({
  providedIn: 'root',
})
export class FormApi {
  constructor(private http: Http) {}

  getTemplate(
    source: string,
    claimId: string,
    lob: string,
    state: string,
    claimNumber: string,
    formId: string
  ) {
    if (!state) {
      state = 'XX';
    }
    const request = {
      lineOfBusiness: lob,
      claimNumber: claimNumber,
      state: state,
      formId: formId,
    };
    return this.http.post<FormContent>(
      `/api/template/${source}/${claimId}/${lob}/template`,
      request
    );
  }

  getPdf(
    source: string,
    claimId: string,
    lob: string,
    state: string,
    claimNumber: string,
    formId: string
  ) {
    if (!state) {
      state = 'XX';
    }
    return this.http.post<Blob>(
      `/api/template/${source}/${claimId}/${lob}/${state}/${claimNumber}/${formId}/pdf`,
      null,
      {
        responseType: 'blob',
      }
    );
  }

  getValues(source: string, claimId: string, lob: string) {
    return this.http.get<Array<TemplateValue>>(
      `/api/template/${source}/${claimId}/${lob}/values`
    );
  }

  savePdf(formId: string, spanish: boolean, answers: Array<AnswersKey>) {
    return this.http.post<Blob>(
      `/api/template/${formId}/${spanish}/savepdf`,
      answers,
      {
        responseType: 'blob',
      }
    );
  }

  sendToSIR(
    source: string,
    claimId: string,
    claim: SearchResult,
    fileName: string,
    fileContents: Blob,
    formName: string
  ) {
    debugger;
    const formData = new FormData();
    formData.append(formName, fileContents, fileName);
    this.http.post(
      `/api/claim/${source}/${claimId}/${claim.lob}/${claim.vendorId}/${claim.procUnit}/${claim.claimNumber}/UploadFile`,
      formData,
      {}
    );
  }

  sendToJURIS(
    source: string,
    claimId: string,
    lob: string,
    examiner: string,
    formName: string,
    state: string,
    fileContents: FormContent
  ) {
    if (!examiner) {
      examiner = 'XX';
    }
    this.http.post(
      `/api/template/${source}/${claimId}/${lob}/${examiner}/${formName}/${state}/uploadJuris`,
      fileContents
    );
  }

  async saveMessage(
    source: string,
    claimId: string,
    lob: string,
    sendMessageRequest: SendMessage
  ): Promise<any> {
    const path = `/api/form/releaseofinformation/${source}/${claimId}/${lob}`;
    return this.http.post<boolean>(
      path + '/send-release-info',
      sendMessageRequest
    );
  }
}
