export const FormParts = new Map<string, string>([
  [
    'claimant',
    '<span class="sub-text"><input placeholder=" Claimant Name*" value="{{claimant-value}}" id="claimant-value" type="text" class="web-form" style="width:300px;" required="required"></span>',
  ],
  [
    'clientname',
    '<span class="sub-text"><input placeholder=" Client Name*" value="{{client-name-value}}" id="client-name-value" type="text" class="web-form web-form-required" style="width:300px;" required="required"></span>',
  ],
  [
    'claimnumber',
    '<span class="sub-text"><input placeholder=" Claim Number*" value="{{claim-number-value}}" id="claim-number-value" type="text" class="web-form" style="width:300px;" required="required"></span>',
  ],
  [
    'checkbox',
    '<span class="sub-text web-form"><input type="checkbox"></span>',
  ],
  [
    'checked',
    '<span class="sub-text web-form"><input type="checkbox" checked="checked"></span>',
  ],
  [
    'date',
    '<span class="sub-text blue-required"><input type="date" class="web-form web-form-required" style="width:125px;" required="required"></span>',
  ],
  [
    'dateofbirth',
    '<span class="sub-text blue-required"><input type="date" value="{{date-of-birth-value}}" id="date-of-birth-value" class="web-form web-form-required" style="width:125px;" required="required"></span>',
  ],
  [
    'dateofinjury',
    '<span class="sub-text blue-required"><input type="date" value="{{date-of-injury-value}}" id="date-of-injury-value" class="web-form web-form-required" style="width:125px;" required="required"></span>',
  ],
  [
    'employeeid',
    '<span class="sub-text"><input placeholder=" Employee Id*" value="{{employeeid-value}}" id="employeeid-value" type="text" class="web-form" style="width:300px;" required="required"></span>',
  ],
  [
    'examiner',
    '<span class="sub-text"><input placeholder=" Examiner*" value="{{examiner-value}}" id="examiner-value" type="text" class="web-form web-form-required" style="width:300px;" required="required"></span>',
  ],
  [
    'examineraddress',
    '<span class="sub-text"><input placeholder=" Examiner Address*" value="{{examiner-address-value}}" id="examiner-address-value" type="text" class="web-form" style="width:300px;" required="required"></span>',
  ],
  [
    'examinerphone',
    '<span class="sub-text"><input placeholder=" Examiner Phone*" value="{{examiner-phone-value}}" id="examiner-phone-value" type="text" class="web-form web-form-required" style="width:300px;" required="required"></span>',
  ],
  [
    'freeform',
    '<span class="sub-text" style="width:100%"><textarea placeholder=" Required*" class="web-form web-form-required" style="width:99%;" required="required"></textarea></span><br>',
  ],
  [
    'initials',
    '<span class="sub-text"><input placeholder=" Initial*" type="text" class="web-form web-form-required" style="width:75px;" required="required"></span>',
  ],
  [
    'name',
    '<span class="sub-text"><input placeholder=" Name*" value="{{name-value}}" id="name-value" type="text" class="web-form web-form-required" style="width:300px;" required="required"></span>',
  ],
  ['sedgwickaddress', 'Sedgwick CMS at P O BOX 14442 LEXINGTON, KY 40512'],
  [
    'sex',
    '<span class="sub-text"><span class="web-form web-form-group"><input type="radio" id="male{group-unique-id}" name="sex{group-unique-id}" class="web-form" value="male"><label for="male{group-unique-id}">Male</label> <input type="radio" id="female{group-unique-id}" id="" name="sex{group-unique-id}" class="web-form" value="female"><label for="female{group-unique-id}">Female</label></span></span>',
  ],
  [
    'signature',
    '<span class="sub-text"><input placeholder=" Sign*" type="text" class="web-form web-form-required" style="width:300px;" required="required"></span>',
  ],
  [
    'social',
    '<span class="sub-text"><input placeholder=" Social Security Number*" value="{{social-value}}" id="social-value" type="text" class="web-form" style="width:300px;" required="required"></span>',
  ],
  [
    'textbox',
    '<span class="sub-text"><input placeholder=" Required*" type="text" class="web-form web-form-required" style="width:300px;" required="required"></span>',
  ],
  [
    'textboxOptional',
    '<span class="sub-text"><input placeholder=" Optional" type="text" style="width:300px;" class="web-form"></span>',
  ],
  [
    'today',
    '<span class="sub-text blue-required"><input type="date" value="{{today-value}}" id="today-value" class="web-form web-form-required" style="width:125px;" required="required"></span>',
  ],
  [
    'yesno',
    '<span class="sub-text"><span class="web-form web-form-group"><input type="radio" id="yes{group-unique-id}" name="yesno{group-unique-id}" class="web-form web-form-required" value="yes" required="required"><label for="yes{group-unique-id}">Yes</label> <input type="radio" id="no{group-unique-id}" name="yesno{group-unique-id}" class="web-form web-form-required" value="no" required="required"><label for="no{group-unique-id}" class="blue-required">No</label></span></span>',
  ],
]);
