import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent, Http } from 'src/app/shared';
import {
  AccessibleSupervisors,
  EncryptionService,
  IncidentalAbsence,
  IncidentalAbsenceReason,
  IncidentalAbsenceSearch,
} from '..';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AbsenceService {
  constructor(
    private http: Http,
    private router: Router,
    private encrypt: EncryptionService,
    private claimConfigService: ConfigurationService
  ) {}

  async getMyAbsence() {
    return await this.http.get<IncidentalAbsence[]>(
      `/api/absence/GetIncidentalAbsence`
    );
  }

  removeDuplicates(absencelist: IncidentalAbsence[]) {
    const finalAbsenceList: IncidentalAbsence[] = [];
    absencelist.forEach((value, index) => {
      let isExists: boolean = false;
      finalAbsenceList.forEach((valinner) => {
        if (
          value.empUnum == valinner.empUnum &&
          value.absenceStartDate == valinner.absenceStartDate &&
          value.absenceEndDate == valinner.absenceEndDate &&
          value.absenceReason == valinner.absenceReason &&
          value.absenceNumber == valinner.absenceNumber &&
          isExists == false
        ) {
          isExists = true;
        }
      });
      if (isExists == false) {
        finalAbsenceList.push(value);
        isExists = false;
      }
    });
    return [...finalAbsenceList];
  }

  async getAbsenceReasons() {
    return await this.http.get<IncidentalAbsenceReason[]>(
      '/api/absence/reasons'
    );
  }

  async generateEventsFromAbsence(absencelist: IncidentalAbsence[]) {
    const anotherFinal = absencelist; //this.removeDuplicates(absencelist);
    const isIncAbsence = await this.claimConfigService.getConfigFeatureAccess(
      'IncidentalAbsence'
    );
    const eventslst = anotherFinal.map((x) => {
      const eventItem: CalendarEvent = new CalendarEvent();

      eventItem.start = this.convertstringdatetoDate(x.absenceStartDate);
      eventItem.end = this.convertstringdatetoDate(
        new Date(x.absenceEndDate).setDate(
          new Date(x.absenceEndDate).getDate() + 1
        )
      );
      eventItem.allDay = true;
      eventItem.title =
        x.firstName +
        ' ' +
        x.lastName +
        '-' +
        x.absenceReason +
        ' - ' +
        x.absenceStatus;
      eventItem.backgroundColor = isIncAbsence
        ? this.setBackGroundColorForEvents(x.absenceStatus)
        : '#007dbc';

      eventItem.extendedProps = x;
      return eventItem;
    });

    return eventslst;
  }

  convertstringdatetoDate(requestDate: any) {
    const tempDate = new Date(requestDate);
    return new Date(tempDate.toDateString());
  }

  getOffWorkAbsence(searchParameters: any) {
    return this.http.post<IncidentalAbsence[]>(
      `/api/absence/GetAbsenceOffWork`,
      searchParameters
    );
  }

  getEmployeeAbsences(EmpUnum: any) {
    return this.http.post<IncidentalAbsence[]>(
      `/api/absence/${EmpUnum}/employeeAbsenceDetail`,
      null
    );
  }

  async GetAccessibleSupervisors() {
    return await this.http.get<AccessibleSupervisors[]>(
      `/api/absence/GetAccessibleSupervisors`
    );
  }

  async getSearchAbsences(
    searchRequest: IncidentalAbsenceSearch
  ): Promise<any> {
    const path = `/api/manager/absences`;
    const response = await this.http.post<IncidentalAbsence[]>(
      path + '/search-absences',
      searchRequest,
      { cache: true }
    );
    return this.mergeDateToString(response);
  }

  async editRemoveAbsence(editAbsence: IncidentalAbsence): Promise<any> {
    const path = `/api/manager/absences`;
    return await this.http.post<boolean>(path + '/edit-absence', editAbsence);
  }

  async editIncidentalAbsence(
    editIncidentalAbsence: IncidentalAbsence
  ): Promise<any> {
    const path = `/api/manager/absences`;
    return await this.http.post<string>(
      path + '/edit-incidental-absence',
      editIncidentalAbsence,
      { responseType: 'text' as 'json' }
    );
  }

  mergeDateToString(arr: IncidentalAbsence[]) {
    arr.forEach((val) => {
      if (val.absenceEndDate != null && val.absenceEndDate != undefined) {
        val.absenceEndDateStr =
          new Date(val.absenceEndDate).getMonth() +
          1 +
          '/' +
          new Date(val.absenceEndDate).getDate() +
          '/' +
          new Date(val.absenceEndDate).getFullYear();
      }
      if (val.absenceStartDate != null && val.absenceStartDate != undefined) {
        val.absenceStartDateStr =
          new Date(val.absenceStartDate).getMonth() +
          1 +
          '/' +
          new Date(val.absenceStartDate).getDate() +
          '/' +
          new Date(val.absenceStartDate).getFullYear();
      }
      if (val.estimatedRTWDate != null && val.estimatedRTWDate != undefined) {
        val.estimatedRTWDateStr =
          new Date(val.estimatedRTWDate).getMonth() +
          1 +
          '/' +
          new Date(val.estimatedRTWDate).getDate() +
          '/' +
          new Date(val.estimatedRTWDate).getFullYear();
      }
    });
    return arr;
  }

  async navigateToAbsences(empUnum: any, name: any, empId: any) {
    if (empUnum) {
      this.router.navigate(['absences'], {
        queryParams: {
          value: this.encrypt.encrypt(
            JSON.stringify({
              name,
              empId,
              empUnum,
            })
          ),
        },
      });
    }
  }

  setBackGroundColorForEvents(status: string) {
    let eleStatus: string = 'Approved';
    if (status.toLocaleLowerCase().indexOf('denied') > -1) {
      eleStatus = 'Denied';
    } else if (status.toLocaleLowerCase().indexOf('waiting') > -1) {
      eleStatus = 'Waiting';
    } else if (status.toLocaleLowerCase().indexOf('pending') > -1) {
      eleStatus = 'Pending';
    } else if (status.toLocaleLowerCase().indexOf('approved') > -1) {
      eleStatus = 'Approved';
    }

    return {
      Approved: '#3f7c4f',
      Reported: '#007dbc',
      Denied: '#c24533',
      Waiting: '#67707a',
      Pending: '#007dbc',
    }[eleStatus];
  }
}
