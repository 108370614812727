import { Component, Input, OnInit } from '@angular/core';
import { SearchResult } from 'src/app/shared';
import { CommCenterService } from '../..';
import { ConversationSummary } from '../../models/ConversasationSummary';

@Component({
  selector: 'app-comm-center2-messages-card',
  template: `
    <app-panel
      title="Communication center"
      [headerLevel]="2"
      [largeHeader]="false"
      [canExpand]="true"
    >
      <app-loading-panel
        [state]="summary ? 'Loaded' : 'Loading'"
        loadingStyle="Box"
        [loadingHeight]="400"
      >
        <ng-template #content>
          <app-comm-center2-messages
            *ngIf="summary"
            [summary]="summary"
            [headerEnabled]="false"
            overflowHeight="500px"
            [focus]="focus"
            [claim]="claim"
          />
        </ng-template>
      </app-loading-panel>
    </app-panel>
  `,
  styles: ``,
})
export class CommCenter2MessagesCardComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  focus: boolean;

  summary: ConversationSummary;

  constructor(public commCenterService: CommCenterService) {}

  async ngOnInit() {
    this.summary = await this.commCenterService.createClaimConversation(
      this.claim
    );
  }
}
