import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import {
  AbsenceService,
  ClaimService,
  CommCenterService,
  ConfigurationService,
  DisplayService,
  DynamicEventBindService,
  InpageAliasService,
  Session,
  UserRoleService,
} from '../..';
import { MyClaim, NotificationItem, getUrlParams } from '../../../shared';
import { OutboundSSO, UserActions } from '../../../shared/models/user';
import { UserActionService } from '../../../shared/services/user-action.service';
import { OutboundSsoService } from '../../services/outbound-sso.service';
import { ReportNewClaimComponent } from '../report-new-claim/report-new-claim.component';

@Component({
  selector: 'app-manager-reminders',
  template: `
    <!-- mobile view -->
    <div #dynamicContentContainer>
      <div class="tw-items-start tw-w-full tw-flex tw-flex-wrap lg:tw-hidden">
        <div class="tw-w-full">
          <ng-container *ngTemplateOutlet="reminders"></ng-container>
        </div>
        <div class="tw-w-full">
          <ng-container *ngTemplateOutlet="links"></ng-container>
        </div>
      </div>

      <div class="tw-items-start tw-w-full tw-hidden lg:tw-flex">
        <div class="tw-px-4  tw-flex-none ">
          @if(claims){
          <img
            src="./assets/images/mySedgwick_Helpful_Link_Characters_3.webp"
            width="194"
            height="193"
            alt=""
          />
          } @else{
          <img
            src="./assets/images/Manager_View_Icon.webp"
            width="194"
            height="193"
            alt=""
          />
          }
        </div>
        <div class="app-pr2 tw-grow tw-h-full">
          <ng-container *ngTemplateOutlet="reminders"></ng-container>
        </div>
        <div class="tw-flex-none side-border app-pr3 app-pb2">
          <ng-container *ngTemplateOutlet="links"></ng-container>
        </div>
      </div>

      <ng-template #reminders>
        <app-loading-panel
          [state]="loaded ? 'Loaded' : 'Loading'"
          class="tw-h-full"
        >
          <ng-template #content>
            <div class="tw-w-full">
              <span style="font-weight: bold;font-size: 18px"
                >Hello {{ currentUserName | titlecase }}</span
              ><span
                style="font-weight: normal;font-size: 18px"
                *ngIf="!hasClaims && !hasReminders"
                >,</span
              >
              <!--Adding a role check for controller as a part of bug fix #204027 -->
              <span
                style="font-weight: normal;font-size: 18px"
                *ngIf="
                  !hasReminders && hasClaims && this.accommodationsCount === 0
                "
                >, @if(role !== 'CONTROLLER') {there are currently no important
                reminders for your employee(s)' claim(s).
                <a
                  tabindex="0"
                  (click)="redirectToLearningCenter()"
                  (keydown.enter)="redirectToLearningCenter()"
                  *ngIf="!hasClaims"
                  >learn about claims</a
                >} @if(role === 'CONTROLLER') {do you want to
                <a
                  tabindex="0"
                  role="button"
                  (keydown.enter)="reportClaim()"
                  (click)="reportClaim()"
                  >{{ reportClaimLabel }}
                </a>
                or
                <a
                  tabindex="0"
                  (click)="redirectToLearningCenter()"
                  (keydown.enter)="redirectToLearningCenter()"
                  >learn about claims</a
                >
                ?}
              </span>
              <span
                style="font-weight: normal;font-size: 18px"
                *ngIf="
                  hasReminders ||
                  (this.accommodationsCount > 0 && !hasReminders)
                "
                >, here are some important reminders.
              </span>
              <span
                style="font-weight: normal;font-size: 18px"
                *ngIf="!hasReminders"
              >
                @if(!hasClaims){ do you want to }
                <a
                  tabindex="0"
                  (keydown.enter)="reportClaim()"
                  *ngIf="hasReportClaim && !hasClaims"
                  (click)="reportClaim()"
                  >{{ reportClaimLabel }}</a
                >
                @if(hasReportClaim && !hasClaims){ or }
                <a
                  tabindex="0"
                  *ngIf="!hasClaims"
                  (click)="redirectToLearningCenter()"
                  (keydown.enter)="redirectToLearningCenter()"
                  >learn about claims</a
                >
                @if(!hasClaims){?}
              </span>
              <div class="tw-list-disc app-pl3 app-pt2" style="font-size: 16px">
                <ng-container class="tw-w-full">
                  <ul
                    class="tw-list-disc app-pl3 app-pt1"
                    style="font-size: 16px"
                  >
                    <div *ngIf="showReminders()">
                      <li
                        class="list-item"
                        *ngFor="
                          let reminder of managerActivities
                            ?.managerRemindersList?.managerReminders;
                          let i = index
                        "
                        [innerHTML]="reminder.message"
                        [attr.data-index]="i"
                      ></li>
                    </div>
                    <li
                      *ngIf="this.accommodationsCount > 0 && hasReminders"
                      class="list-item"
                    >
                      <span
                        >Review is needed on
                        <a
                          tabindex="0"
                          (click)="redirectAccomdation()"
                          (keydown.enter)="redirectAccomdation()"
                          [attr.aria-label]="
                            'Review ' +
                            this.accommodationsCount +
                            ' accommodations'
                          "
                          >{{ this.accommodationsCount | numberToWord }}</a
                        >
                        accommodation(s).</span
                      >
                    </li>
                    <li
                      *ngIf="this.accommodationsCount > 0 && !hasReminders"
                      class="list-item"
                    >
                      <span
                        >Review is needed on
                        <a
                          tabindex="0"
                          (click)="redirectAccomdation()"
                          (keydown.enter)="redirectAccomdation()"
                          >{{ this.accommodationsCount | numberToWord }}</a
                        >
                        accommodation(s).</span
                      >
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </app-loading-panel>
      </ng-template>
      <ng-template #dialogSelectClaim>
        <app-dialog
          [showActions]="false"
          [showHeader]="false"
          ariaLabel="Select claim dialog"
          ariaLabelMessage="Close announcement popup"
        >
          <app-claim-list-report-absence
            title="Confirmation"
            [claims]="claims"
            [redirectToSmartly]="redirectToSmartly"
          >
          </app-claim-list-report-absence>
        </app-dialog>
      </ng-template>
      <ng-template #links>
        <div
          class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
          *ngIf="outboundSSO?.ssoLinkManager"
        >
          <img
            src="./assets/images/SSO_link_icon.png"
            height="30"
            width="30"
            class="tw-hidden lg:tw-block"
            alt=""
          />

          <a
            class="big-link app-pl1"
            role="link"
            tabindex="0"
            (click)="
              outboundSsoService.openSsoUrl(
                outboundSSO.ssoType,
                outboundSSO.ssoEndPoint
              )
            "
            >{{ outboundSSO.ssoLinkManager }}</a
          >
        </div>
        <div
          class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
          tabindex="-1"
          *ngIf="hasReportClaim"
        >
          <img
            src="./assets/images/Report_claim.png"
            height="30"
            width="30"
            class="tw-hidden lg:tw-block"
            alt=""
          />
          <a
            tabindex="0"
            (keydown.enter)="reportClaim()"
            class="big-link app-pl1"
            (click)="reportClaim()"
            role="button"
            >{{ reportClaimLabel }}</a
          >
        </div>
        <div
          class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
          tabindex="-1"
          *ngIf="showReportAbsence()"
        >
          <img
            src="./assets/images/Intermittent_absence.png"
            height="30"
            width="30"
            class="tw-hidden lg:tw-block"
            alt=""
          />
          <a
            tabindex="0"
            (keydown.enter)="selectClaim()"
            class="big-link app-pl1"
            (click)="selectClaim()"
            role="button"
            >Report an absence</a
          >
        </div>
        <div
          class="tw-flex tw-items-center app-pt1 "
          *ngIf="incidentalAbsences5"
        >
          <img
            src="./assets/images/Intermittent_absence.png"
            height="30"
            width="30"
            class="tw-hidden lg:tw-block"
            alt=""
          />
          <a
            tabindex="0"
            class="big-link app-pl1"
            (click)="navigateToAbsences()"
            (keydown.enter)="navigateToAbsences()"
            >View absences</a
          >
        </div>
        <div
          class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
          (click)="redirectToCommCenter()"
          (keydown.enter)="redirectToCommCenter()"
          *ngIf="!isIncidentalClient"
          tabindex="-1"
        >
          <img
            src="./assets/images/Comm_center_icon.png"
            height="30"
            width="30"
            class="tw-hidden lg:tw-block"
            alt=""
          />
          <a
            class="big-link app-pl1 mat-badge-accent"
            [matBadge]="commCenterService.unreadMessageCount()"
            matBadgeOverlap="false"
            tabindex="0"
            role="link"
            >Communication center</a
          >
        </div>
        <div
          class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
          tabindex="-1"
          *ngIf="hasLearningCenter"
        >
          <img
            src="./assets/images/Helpful_resources_icon.png"
            height="30"
            width="30"
            class="tw-hidden lg:tw-block"
            alt=""
          />
          <a
            tabindex="0"
            role="link"
            (keydown.enter)="redirectToLearningCenter()"
            class="big-link app-pl1"
            (click)="redirectToLearningCenter()"
            >Helpful resources</a
          >
        </div>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .big-link {
        font-size: 16px;
      }

      .small-link {
        font-size: 14px;
        margin: 6px;
      }

      .side-border {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        height: 100%;
        padding-left: 16px;
      }

      .list-item {
        padding-bottom: 12px;
      }

      .claimListDialog {
        width: 80%;
        height: auto;
        min-height: 'calc(100vh - 90px)';
      }
    `,
  ],
})
export class ManagerRemindersComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  role: string = 'Manager';

  @Input()
  managerActivities: any;

  @Input()
  claims: MyClaim[];

  @Input()
  commCenterMsgCount: number = 0;

  @Input()
  accommodationsCount: number = 0;

  @Output()
  filterApplied = new EventEmitter<boolean>();

  @Output()
  focusTab = new EventEmitter<string>();

  mappings: any;
  hasReportClaim: boolean = false;
  hasIntermittentAbsence: boolean = false;
  currentUserName: string;
  hasReminders: boolean = false;
  hasLearningCenter: boolean = false;
  maxBadgeCount = 99;
  loaded: boolean = false;
  originalNotifications: any;
  outboundSSO: OutboundSSO;
  hasOpenClaims: boolean = false;
  multipleOpenClaims: boolean = false;
  redirectToSmartly: boolean = true;
  isIncidentalClient: boolean = false;
  @ViewChild('dynamicContentContainer') container: ElementRef;
  @ViewChild('dialogSelectClaim') dialogSelectClaim: TemplateRef<any>;
  private unlistens: (() => void)[] = [];
  hasClaims: boolean;
  pendingAccomDationRemainder: string = '[count of pending accommodations]';
  myClaimsDashboard: boolean;
  reportClaimLabel: string = 'Report a new claim';
  incidentalAbsences5 = false;

  constructor(
    private session: Session,
    private bindEvents: DynamicEventBindService,
    private dialog: MatDialog,
    private display: DisplayService,
    private userAction: UserActionService,
    private router: Router,
    private userRole: UserRoleService,
    public outboundSsoService: OutboundSsoService,
    public claimConfigService: ConfigurationService,
    public commCenterService: CommCenterService,
    private route: ActivatedRoute,
    public claimService: ClaimService,
    public inpageAliasService: InpageAliasService,
    private absenceService: AbsenceService
  ) {}

  subs = new SubSink();

  async ngOnInit() {
    this.hasReportClaim = this.display.reportClaim;
    if (this.hasReportClaim) {
      this.reportClaimLabel = await this.inpageAliasService.replaceAliases(
        'ReportClaim',
        this.claimConfigService.GetConfig.configFeatures.aliases
      );
    }

    if (this.accommodationsCount > 0) {
      this.pendingAccomDationRemainder =
        this.pendingAccomDationRemainder.replace(
          '[count of pending accommodations]',
          this.accommodationsCount.toString()
        );
    }
    this.originalNotifications = [...this.managerActivities.notifications];
    this.currentUserName = this.session.user?.firstName;
    this.hasIntermittentAbsence = this.display.reportIntermittentAbsence;
    this.hasLearningCenter = this.display.learningCenter;
    this.hasReminders =
      this.managerActivities?.managerRemindersList?.managerReminders.filter(
        (n: any) => n.message != null && n.message != undefined
      ).length > 0
        ? true
        : false;

    this.outboundSSO = this.display.outboundSSO;

    const params = getUrlParams();
    this.myClaimsDashboard =
      window.location.pathname === '/dashboard' && !params.emp;
    this.hasClaims =
      !this.myClaimsDashboard || this.claims?.length > 0 ? true : false;
    this.loaded = true;
    this.isIncidentalClient = this.claimConfigService.getFeatureAccess(
      'SUBMITINTERMITTENTABSENCES'
    );
    this.mappings = [
      {
        selector: '.act-on-reminder',
        action: this.actOnReminders.bind(this),
      },
    ];
    // moving the below block from ngViewAfterinit to oninit as this was affecting the dynamic binding of events

    this.incidentalAbsences5 = this.claimConfigService.getConfigFeatureAccess(
      'IncidentalAbsences5'
    );

    this.checkOpenClaims();
  }

  async ngAfterViewInit() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 0));
      if ((await this.checkReminders()) && this.container) {
        this.unlistens = await this.bindEvents.bindDynamicEvents(
          this.container,
          this.mappings
        );
      }
    } catch (error) {
      console.error('Error binding dynamic events:', error);
    }
  }

  checkReminders(): boolean {
    return (
      this.managerActivities?.managerRemindersList?.managerReminders.filter(
        (n: any) => n.message != null && n.message != undefined
      ).length > 0
    );
  }

  showReminders() {
    return this.accommodationsCount > 0 || this.checkReminders();
  }

  setupMapping() {
    this.mappings = [
      {
        selector: '.act-on-reminder',
        action: this.actOnReminders.bind(this),
      },
    ];
  }

  async actOnReminders(event: MouseEvent) {
    const element = event.currentTarget as HTMLElement;
    const reminderIndex = element.parentElement?.getAttribute('data-index')
      ? element.parentElement.getAttribute('data-index')
      : null;
    const intIdx = reminderIndex ? parseInt(reminderIndex) : 0;
    const reminder =
      this.managerActivities.managerRemindersList.managerReminders[intIdx];

    if (reminder.type === 'RTW_CONFIRMATION_RM') {
      if (reminder.claimKeys.length == 1) {
        let alert;
        if (this.managerActivities && this.managerActivities.returnToWork) {
          if (reminder && reminder.alertMasterIds.length > 0) {
            alert = this.managerActivities.returnToWork.find(
              (al: { alertMasterId: any }) =>
                al.alertMasterId === reminder.alertMasterIds[0]
            );
          }
        }
        this.router.navigate(['/return-to-work'], {
          queryParams: {
            claimId: alert.claimId,
            source: alert.source,
            rtw: 'confirm',
            rtwType: alert.rtwType,
            alertMasterUid: alert.alertMasterId,
            type: alert.type,
            rtwDate: this.userRole.supervisor
              ? alert.rtwDate
              : this.userRole.omni || this.userRole.overseer
              ? alert.rtwDate
              : '',
          },
        });
      }
      if (reminder.claimKeys.length > 1) {
        this.focusTab.emit('returntowork');
      }
    } else if (reminder.type === 'MEDDOC_NEEDED_AM') {
      this.filterNotifications(reminder);
    }
  }
  filterNotifications(reminder: any) {
    this.loaded = false;
    const filteredNotifications = this.originalNotifications.filter(
      (notificationItem: NotificationItem) =>
        reminder.alertMasterIds.includes(notificationItem.alertId)
    );
    if (filteredNotifications.length > 0) {
      this.managerActivities.notifications = [...filteredNotifications];
      this.scrollToNotification();
    }
    this.filterApplied.emit(true);
    this.focusTab.emit('Notifications');
    this.loaded = true;
  }

  scrollToNotification() {
    const notification = document.getElementById('mgrActivityPnl');
    if (notification != null) {
      notification.scrollIntoView();
    }
  }

  reportClaim() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
    };
    const dlg = this.dialog.open(ReportNewClaimComponent, dialogConfig);
    dlg.addPanelClass('app-dlg-padding');
    dlg.updateSize('700px', '450px');
  }

  reportIntermittentAbsence() {}

  checkOpenClaims() {
    let count: number = 0;
    if (!this.claims) {
      return;
    }
    this.claims.forEach(function (claim) {
      if (
        claim.status === 'Open' &&
        claim.lineOfBusiness === 'LV' &&
        claim.claimDescription.toLowerCase().indexOf('intermittent') > -1
      ) {
        count++;
      }
    });
    if (count > 0) {
      this.hasOpenClaims = true;
    }
    if (count > 1) {
      this.multipleOpenClaims = true;
    }
  }

  showReportAbsence() {
    if (!this.claims) {
      return false;
    } else {
      return (
        this.claims.findIndex(
          (claim) =>
            claim.status === 'Open' &&
            claim.lineOfBusiness === 'LV' &&
            claim.isIntermittent &&
            claim.claimLevelFeatures.indexOf('submitintermittentabsences') > -1
        ) > -1 && this.display.reportIntermittentAbsence
      );
    }
  }

  selectClaim() {
    if (this.claimConfigService.getConfigFeatureAccess('leavelinksso')) {
      this.claimService.loadIntermittentAbsence('', '', null);
    } else {
      if (this.hasOpenClaims) {
        if (this.multipleOpenClaims) {
          this.redirectToSmartly = false;
          this.dialog.open(this.dialogSelectClaim, {
            panelClass: 'claimListDialog',
          });
        } else {
          const queryParams: any = {};
          const element = this.claims.find(
            (claim) =>
              claim.status === 'Open' &&
              claim.lineOfBusiness === 'LV' &&
              claim.claimDescription.toLowerCase().indexOf('intermittent') > -1
          );
          queryParams.claimId = element.claimId;
          queryParams.source = element.source;
          this.userAction.setClaimant(element);
          this.router.navigate(['/reportanabsence'], {
            queryParams,
          });
        }
      }
    }
  }

  UnreadMsgCount() {
    return this.commCenterMsgCount > 99
      ? this.maxBadgeCount
      : this.commCenterMsgCount;
  }

  setCommCenterFocus() {
    const commcenter = document.getElementById('commCenterPnl');
    if (commcenter != null) {
      commcenter.scrollIntoView();
    }
  }

  redirectToLearningCenter() {
    this.userAction.setAction(UserActions.ViewOwnMgrDb);
    this.route.url.subscribe((urlSegments) => {
      if (urlSegments.length > 0) {
        const firstPathSegment = urlSegments[0].path;
        this.router.navigate(['help'], {
          queryParams: {
            focus: 'learningcenter',
            origin: firstPathSegment,
          },
        });
      }
    });
  }

  redirectToCommCenter() {
    this.router.navigate(['communication-center'], {});
  }

  ngOnDestroy(): void {
    this.unlistens.forEach((unlisten) => unlisten());
    this.subs.unsubscribe();
  }
  redirectAccomdation() {
    this.focusTab.emit('accommodations');
  }

  navigateToAbsences() {
    if (location.pathname === '/supervisor') {
      // supervisor dashboard, navigate to off work
      this.router.navigate(['/absence-dashboard'], {
        queryParams: {
          focus: 'offWork',
        },
      });
    } else if (this.claims.length > 0) {
      // employee view, navigate to employee's absences
      const claim = this.claims[0];
      this.absenceService.navigateToAbsences(
        claim.empUnum,
        claim.name,
        claim.empId
      );
    } else {
      const claimant = this.userAction.getClaimant();
      this.absenceService.navigateToAbsences(
        claimant?.empUnum,
        claimant?.name,
        claimant?.empId
      );
    }
  }
}
