import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MemoryCache {
  private _items = new Map<string, CacheEntry>();

  set(key: string, value: any, expireMs: number = 0) {
    let expires: number = null;
    if (expireMs > 0) {
      expires = Date.now() + expireMs;
    }
    this._items.set(key, new CacheEntry(value, expires));
  }

  has(key: string): boolean {
    return this._items.has(key);
  }

  get(key: string): CacheEntry {
    const item = this._items.get(key);
    if (!item) {
      return null;
    }

    if (item.expires && item.expires < Date.now()) {
      this.delete(key);
      return null;
    } else {
      return item;
    }
  }

  delete(key: string): void {
    this._items.delete(key);
  }

  deleteRange(startsWith: string): void {
    for (const key of this._items.keys()) {
      if (key.startsWith(startsWith)) {
        this._items.delete(key);
      }
    }
  }

  clear(): void {
    this._items.clear();
  }
}

class CacheEntry {
  constructor(public value: any, public expires: number) {}
}
