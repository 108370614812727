import { Component } from '@angular/core';
import { Session } from 'src/app/my-sedgwick';
import { BaseComponent } from '../../../shared';

@Component({
  selector: 'app-preview-mode',
  template: `
    <div class="preview-banner">
      <app-toast type="Info" class="toast-header wrap-content"
        >&nbsp;&nbsp;
        <span>
          Below is a preview only screen for
          {{ session.user.firstName }}&nbsp;{{ session.user.lastName }}.
        </span>
      </app-toast>
    </div>
  `,
  styles: [
    `
      .preview-banner {
        padding-top: 10px;
        padding-left: 10px;
      }
      .toast-header {
        line-height: 24px;
        font-weight: normal;
        font-size: medium;
      }
      .wrap-content {
        white-space: normal !important;
      }
      .mat-toolbar-row,
      .mat-toolbar-single-row {
        white-space: normal !important;
      }
    `,
  ],
})
export class PreviewModeComponent extends BaseComponent {
  constructor(public session: Session) {
    super();
  }
}
