import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-dialog',
  template: `
    <div mat-dialog-content *ngIf="message">
      <app-progress
        [type]="message ? 'Bar' : 'Spinner'"
        [message]="message"
      ></app-progress>
    </div>

    <ng-container *ngIf="!message">
      <mat-spinner
        color="accent"
        diameter="40"
        mode="indeterminate"
      ></mat-spinner>
    </ng-container>
  `,
  styles: [],
})
export class ProgressDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public message: string) {}
}
