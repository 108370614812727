import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  HistoryAccommodation,
  PendingAccommodation,
  PendingAccommodationPanels,
} from '../../models/accommodation';
import { Excel, ExportRequest } from '../../services/excel.service';
import { Router } from '@angular/router';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MyClaim, UserActionService } from 'src/app/shared';
import { EncryptionService } from '../../services/encryption.service';
import { UserActions } from 'src/app/shared/models/user';
import { IClaimant } from '../labels/claimant-label.component';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-accommodation-list',
  template: `
    <app-list
      *ngIf="showPending"
      [dataSource]="pendingAccommodations"
      [template]="row"
      class="tw-w-full app-pt2"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these pending accommodations as a XLSX file"
      [paginatorTemplate]="showListHelpText ? helpTextTemplate : null"
      noDataIcon="list_alt"
      noDataHeader="No Accommodations"
      noDataMessage="Use the Search feature to find a claim"
      (export)="exportPending()"
      [filterEnabled]="true"
      [ShowSort]="true"
      [forceHeader]="!pendingAccommodations?.length ? false : true"
      [tableAriaLabel]="'Pending Accommodations'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            *ngIf="!isClaimSummary"
            name="accommodationRequestNumber"
            label="CLAIM"
            sortLabel="Claim"
            [sortEnabled]="!isClaimSummary"
            class="tw-w-full md:tw-w-[30%]"
          ></app-list-header>
          <app-list-header
            *ngIf="!isClaimSummary"
            name="name"
            label="NAME"
            [sortEnabled]="false"
            class="tw-w-full md:tw-w-[22%]"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            name="type"
            label="TYPE"
            sortLabel="Type"
            class="tw-w-full"
            [ngClass]="isClaimSummary ? 'md:tw-w-[60%]' : 'md:tw-w-[20%]'"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            name="begin"
            label="BEGIN"
            sortLabel="Begin"
            class="tw-w-full"
            [ngClass]="isClaimSummary ? 'md:tw-w-[14%]' : 'md:tw-w-[8%]'"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            name="end"
            label="END"
            class="tw-w-full"
            sortLabel="End"
            [ngClass]="isClaimSummary ? 'md:tw-w-[14%]' : 'md:tw-w-[8%]'"
          ></app-list-header>
          <app-list-header
            *ngIf="allowInteractions"
            name=""
            label="ACTIONS"
            class="tw-w-full md:tw-w-[10%]"
            [sortEnabled]="false"
          ></app-list-header>
        </tr>
      </ng-container>
      <ng-template #row let-element>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <td class="tw-w-full md:tw-w-[30%]">
            <app-value *ngIf="!isClaimSummary" label="claim" layout="row-xs">
              <app-claim-label
                value
                [claim]="{
                claimId: element.recordId,
                claimDescription: element.lineOfBusiness,
                claimNumber: element.accommodationRequestNumber,
                empId: element.employeeId,
                empUnum: element.empUnum,
                lob: element.lob,
                source: element.recordSource,
                name: element.firstName + ' ' + element.lastName,
              }"
                layout="row"
              ></app-claim-label>
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[22%]">
            <app-value
              *ngIf="!isClaimSummary"
              label="name"
              layout="row-xs"
              tabindex="0"
            >
              <app-claimant-label
                value
                [claimant]="{
                  firstName: element.firstName,
                  lastName: element.lastName,
                  empId: element.employeeId,
                  claimId: element.claimId,
                  empUnum: element.empUnum,
                  employeeID: element.employeeId,
                  name: element.firstName + ' ' + element.lastName,
              }"
                [disabled]="!enableClaimantLink"
                layout="row"
              ></app-claimant-label>
            </app-value>
          </td>
          <td
            class="tw-w-full"
            [ngClass]="isClaimSummary ? 'md:tw-w-[60%]' : 'md:tw-w-[20%]'"
          >
            <app-value
              label="type"
              [tabindex]="element.type ? '0' : '-1'"
              layout="row-xs"
              [value]="element.type"
            >
            </app-value>
          </td>
          <td
            class="tw-w-full"
            [ngClass]="isClaimSummary ? 'md:tw-w-[14%]' : 'md:tw-w-[8%]'"
          >
            <app-value
              label="begin"
              [tabindex]="element.begin ? '0' : '-1'"
              layout="row-xs"
              [value]="element.begin | format : 'date'"
            >
            </app-value>
          </td>
          <td
            class="tw-w-full"
            [ngClass]="isClaimSummary ? 'md:tw-w-[14%]' : 'md:tw-w-[8%]'"
          >
            <app-value
              label="end"
              [tabindex]="element.end ? '0' : '-1'"
              layout="row-xs"
              [value]="element.end | format : 'date'"
            >
            </app-value>
          </td>
          <td
            class="tw-w-full md:tw-w-[10%]"
            label="Actions"
            *ngIf="allowInteractions"
          >
            <app-value class="tw-w-full" layout="row-xs">
              <app-icon-button
                value
                mat-icon-button
                icon="more_vert"
                label="Actions"
                [ariaLabelText]="
                  'Actions menu for ' +
                  element.firstName +
                  ' ' +
                  element.lastName +
                  ' accommodation request ' +
                  element.accommodationRequestNumber
                "
                [attr.aria-label]="
                  'Actions menu for ' +
                  element.firstName +
                  ' ' +
                  element.lastName +
                  ' accommodation request ' +
                  element.accommodationRequestNumber
                "
                [matMenuTriggerFor]="actionMenu"
                tabindex="0"
                role="button"
                (click)="toggleMenu()"
                (keydown.enter)="toggleMenu()"
                [attr.aria-expanded]="isActionsMenuExpanded"
              >
                <mat-menu
                  #actionMenu="matMenu"
                  role="menu"
                  (closed)="toggleMenu()"
                >
                  <h3 class="tw-w-full app-pl2 action-header">Actions</h3>
                  <button mat-menu-item (click)="approveJobFunctions(element)">
                    <span class="action-links">View job functions</span>
                  </button>
                  <button
                    *ngIf="showJobFunctions"
                    mat-menu-item
                    (click)="unapproveJobFunctions(element)"
                  >
                    <span class="action-links">Approve job functions</span>
                  </button>
                  <button
                    *ngIf="showApproveAccommodations"
                    mat-menu-item
                    (click)="unapproveAccommodations(element)"
                  >
                    <span class="action-links">Approve accommodations</span>
                  </button>
                </mat-menu>
              </app-icon-button>
            </app-value>
          </td>
        </tr></ng-template
      >
    </app-list>
    <app-list
      *ngIf="!showPending"
      [dataSource]="historyAccommodations"
      [template]="row"
      class="app-pt2"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these history accomodations as a XLSX file"
      noDataIcon="list_alt"
      noDataHeader="No Accommodations"
      noDataMessage="Use the Search feature to find a claim"
      (export)="exportHistory()"
      [filterEnabled]="true"
      [ShowSort]="true"
      [forceHeader]="!historyAccommodations?.length ? false : true"
      [tableAriaLabel]="'Accommodations History'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            *ngIf="!isClaimSummary"
            name="claim"
            label="CLAIM"
            sortLabel="Claim"
            class="tw-w-[30%]"
          ></app-list-header>
          <app-list-header
            *ngIf="!hideNameColumn"
            name="name"
            label="NAME"
            class="tw-w-[20%]"
            [sortEnabled]="false"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            name="detail"
            label="DETAIL"
            sortLabel="Detail"
            [ngClass]="isClaimSummary ? 'tw-w-[70%]' : 'tw-w-[50%]'"
            [sortEnabled]="false"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            sortLabel="Status"
            name="status"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            sortLabel="Request Type"
            name="requestType"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            sortLabel="Decision"
            name="decision"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            sortLabel="Decision Date"
            name="decision"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            sortLabel="Begin"
            name="begin"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            sortLabel="Long Term"
            name="longTerm"
          ></app-list-header>
          <app-list-header
            *ngIf="true"
            class="hide-sort-headers"
            name="type"
            sortLabel="Type"
          ></app-list-header>
          <app-list-header
            *ngIf="isClaimSummary"
            name="associatedClaims"
            label="Associated Claims"
            class="tw-w-[30%]"
            sortLabel="Leave"
          >
          </app-list-header>
        </tr>
      </ng-container>
      <ng-template #row let-element>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <td class="tw-w-full md:tw-w-[30%]" *ngIf="!isClaimSummary">
            <app-value label="claim" class="tw-w-full" layout="row-xs">
              <app-claim-label
                value
                [claim]="{
                  claimId: element.recordId,
                  claimDescription: element.lineOfBusiness,
                  claimNumber: element.accommodationRequestNumber,
                  empId: element.employeeId,
                  empUnum: element.empUnum,
                  lob: element.lob,
                  source: element.recordSource,
                  name: element.firstName + ' ' + element.lastName,
                }"
                layout="row"
              ></app-claim-label>
            </app-value>
          </td>
          <td
            *ngIf="!hideNameColumn"
            value
            class="tw-flex tw-flex-wrap tw-w-full md:tw-w-[20%]"
          >
            <app-claimant-label
              [claimant]="{
                  firstName: element.firstName,
                  lastName: element.lastName,
                  empId: element.employeeId,
                  claimId: element.claimId,
                  empUnum: element.empUnum,
                  employeeID: element.employeeId,
                  name: element.firstName + ' ' + element.lastName,
              }"
              [disabled]="!enableClaimantLink"
            ></app-claimant-label>
          </td>
          <td
            value
            class="tw-flex tw-flex-wrap tw-w-full"
            [ngClass]="'md:tw-w-[70%]'"
          >
            <div class="tw-w-full md:tw-w-[37%]">
              <app-value layout="row" class="tw-w-full" label="STATUS">
                <span value
                  >{{ element.status }} - {{ element.subStatus }}</span
                >
              </app-value>
              <app-value
                layout="row"
                label="REQUEST TYPE"
                [value]="element.requestType"
                class="tw-w-full"
              >
              </app-value>
            </div>
            <div class="tw-w-full md:tw-w-[30%]">
              <app-value
                layout="row"
                class="tw-w-full"
                label="DECISION"
                [value]="element.decision"
              >
              </app-value>
              <app-value
                *ngIf="element.decisionDate"
                layout="row"
                class="tw-w-full"
                label="DECISION DATE"
                [value]="element.decisionDate | format : 'date'"
              >
              </app-value>
            </div>
            <div class="tw-w-full md:tw-w-[33%]">
              <app-value layout="row" class="tw-w-full" label="BEGIN">
                <span *ngIf="element.begin && element.end" value
                  >{{ element.begin | format : 'date' }} -
                  {{ element.end | format : 'date' }}</span
                >
                <span *ngIf="element.begin && !element.end" value
                  >{{ element.begin | format : 'date' }}
                </span>
              </app-value>
              <app-value
                layout="row"
                class="tw-w-full"
                label="LONG TERM"
                [value]="element.longTerm"
              >
              </app-value>
            </div>
            <div class="tw-flex tw-flex-wrap tw-w-full">
              <app-value layout="row" class="tw-w-full" label="TYPE">
                <span value>{{ element.type }} - {{ element.subType }}</span>
              </app-value>
              <app-value
                layout="row"
                class="tw-w-full"
                label="DESCRIPTION"
                [value]="element.description"
              ></app-value>
            </div>
          </td>
          <div class="tw-w-full md:tw-w-[30%]" *ngIf="isClaimSummary">
            <app-value
              name="associatedClaim"
              label="LEAVE"
              class="tw-w-full"
              layout="row"
            >
              <app-claim-label
                *ngIf="element.leaveClaim"
                value
                [showDescription]="false"
                [claim]="element.leaveClaim"
                [hideWatchList]="true"
              >
              </app-claim-label>
            </app-value>
          </div>
        </tr>
      </ng-template>
    </app-list>
    <ng-template #helpTextTemplate>
      <p>{{ helpText }}</p>
    </ng-template>
  `,
  styles: `
    .action-links {
      color: #007dbc;
    text-decoration: none;
    font-weight: bold;
    }
    .action-header {
      font-weight:bold;
    }

    .hide-sort-headers {
      display:none;
      visibility:hidden;
    }
  `,
})
export class AccommodationListComponent implements OnInit {
  @Input()
  pendingAccommodations: PendingAccommodation[];

  @Input()
  historyAccommodations: HistoryAccommodation[];

  @Input()
  showPending: boolean;

  @Input()
  isClaimSummary: boolean = false;

  @Input()
  allowInteractions: boolean = false;

  @Input()
  enableClaimantLink: boolean = false;

  @Input()
  myClaims: MyClaim[];

  @Output()
  export = new EventEmitter<ExportRequest>();

  showJobFunctions: boolean = true;
  showApproveAccommodations: boolean = true;
  showActions: boolean = true;
  showListHelpText: boolean = false;
  isActionsMenuExpanded: boolean = false;
  hideNameColumn: boolean = true;
  helpText: string = '';

  constructor(
    private excel: Excel,
    private router: Router,
    private sessionService: Session,
    private userRoleService: UserRoleService,
    private configurationService: ConfigurationService,
    private encrypt: EncryptionService,
    private userAction: UserActionService
  ) {
    this.showJobFunctions = this.configurationService.hasFeature(
      'accommodationjobfunctionsrequests'
    );
    this.showApproveAccommodations = this.configurationService.hasFeature(
      'accommodationsrequest'
    );
  }
  async ngOnInit() {
    const managerAction = this.userAction.getAction();
    this.showActions =
      this.userRoleService.manager &&
      this.isClaimSummary &&
      managerAction === UserActions.MgrViewEmpClaim;
    this.showListHelpText = managerAction === UserActions.ViewOwnMgrDb;

    if (managerAction == UserActions.ViewOwnMgrDb) {
      this.hideNameColumn = false;
    }
    if (this.showListHelpText) {
      await this.buildHelpText();
    }
  }

  unapproveJobFunctions(element: any) {
    if (this.userRoleService.manager) {
      this.setClaimant(element);
    }
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.recordId,
        source: element.recordSource,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.UnapproveJobFunctions,
        userOwns: element.userOwns,
      },
    });
  }

  approveJobFunctions(element: any) {
    if (this.userRoleService.manager) {
      this.setClaimant(element);
    }
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.recordId,
        source: element.recordSource,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.ApproveJobFunctions,
        userOwns: element.userOwns,
      },
    });
  }

  unapproveAccommodations(element: any) {
    if (this.userRoleService.manager) {
      this.setClaimant(element);
    }
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.recordId,
        source: element.recordSource,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.UnapprovedAccommodations,
        userOwns: element.userOwns,
      },
    });
  }

  async setClaimant(claimant: IClaimant) {
    await this.userAction.setClaimant(claimant);
    await this.userAction.setAction(UserActions.MgrViewEmpDb);
  }

  exportPending() {
    const request = new ExportRequest();
    request.fileName =
      'PendingAccommodations' + Date.now().toString() + '.xlsx';
    request.sheets = [
      {
        name: 'Pending Accommodation',
        rows: this.pendingAccommodations,
        headers: [
          { label: 'Line of Business', value: 'lob' },
          { label: 'Claim Type', value: 'type' },
          { label: 'Claim Number', value: 'accommodationRequestNumber' },
          { label: 'First Name', value: 'firstName', format: 'first' },
          { label: 'Last Name', value: 'lastName' },
          { label: 'Employee ID', value: 'employeeId' },
          { label: 'Type', value: 'type' },
          { label: 'Begin', value: 'begin', format: 'date' },
          { label: 'End', value: 'end', format: 'date' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }

  exportHistory() {
    const request = new ExportRequest();
    request.fileName = 'AccommodationHistory' + Date.now().toString() + '.xlsx';
    request.sheets = [
      {
        name: 'View History',
        rows: this.historyAccommodations,
        headers: [
          { label: 'Line of Business', value: 'lob' },
          { label: 'Claim Type', value: 'claimType' },
          { label: 'Type', value: 'type' },
          { label: 'SubType', value: 'subType' },
          { label: 'Claim Number', value: 'accommodationRequestNumber' },
          { label: 'First Name', value: 'firstName', format: 'first' },
          { label: 'Last Name', value: 'lastName', format: 'last' },
          { label: 'Employee ID', value: 'employeeId' },
          { label: 'Status', value: 'status' },
          { label: 'Substatus', value: 'subStatus' },
          { label: 'Request Type', value: 'requestType' },
          { label: 'Decision', value: 'decision' },
          { label: 'Decision Date', value: 'decisionDate', format: 'date' },
          { label: 'Begin', value: 'begin', format: 'date' },
          { label: 'End', value: 'end', format: 'date' },
          { label: 'Long Term', value: 'longTerm' },
          { label: 'Description', value: 'description' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }

  async buildHelpText() {
    let hasJobFunctionsApproval = false;
    let hasRegularAccommodations = false;

    this.pendingAccommodations.forEach((x) => {
      if (x.type === 'Job Functions Approval') {
        hasJobFunctionsApproval = true;
      }
    });

    this.pendingAccommodations.forEach((x) => {
      if (x.type !== 'Job Functions Approval') {
        hasRegularAccommodations = true;
      }
    });

    if (hasJobFunctionsApproval && hasRegularAccommodations) {
      this.helpText = 'Pending Accommodations and Job Functions Approval';
    } else if (hasJobFunctionsApproval && !hasRegularAccommodations) {
      this.helpText = 'Job Functions Approval';
    } else if (hasRegularAccommodations && !hasJobFunctionsApproval) {
      this.helpText = 'Pending Accommodations';
    } else {
      this.helpText = 'Pending Accommodations and Job Functions Approval';
    }
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
