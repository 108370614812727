/**
 * Replace string characters with "replaceChar" value to hide values.
 * @param value
 * @param replaceChar
 * @returns string
 */
export function hideString(value: string, replaceChar: string): string {
    // Handle null or empty string, returns empty string
    if (!value){
        return '';
    }

    // Replace string characters with * exception blank space, dot and at.
    if (replaceChar !== '') {
        let result = '';
        for (const char of value) {
            if (char === ' ' || char === '.' || char === '@') {
                result += char; // Keep the character as is
            } else {
                result += replaceChar; // Replace with the desired character
            }
        }
        return result;
    }

    return '';
}