import { Component, Input, OnInit } from '@angular/core';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-radio-button-control',
  template: `<div class="app-pr1 app-pb1" [formGroup]="formGroup">
    <div>
      <b>{{ label }} {{ isRequired ? '*' : '' }}</b>
    </div>
    <mat-radio-group
      aria-live="polite"
      [attr.aria-label]="ariaLabel"
      [formControlName]="formControlName"
      autocomplete="off"
      role="radiogroup"
      class="vert-group"
    >
      <mat-radio-button
        class="app-pr2"
        *ngFor="let item of items"
        [value]="!valueProperty ? item : item[valueProperty]"
        >{{ !labelProperty ? item : item[labelProperty] }}</mat-radio-button
      >
    </mat-radio-group>
    <div *ngIf="hintLabel" class="redio-hint">{{ hintLabel }}</div>
    <div>
      <mat-error
        class="redio-error"
        *ngIf="formControl.invalid && formControl.touched"
        >{{ errorMessage }}</mat-error
      >
    </div>
  </div> `,
  styles: [
    `
      .vert-group {
        display: flex;
        flex-direction: column;
      }
      .redio-hint {
        font-size: 12px;
        margin-left: 10px;
      }
      .redio-error {
        margin-left: 10px;
      }
    `,
  ],
})
export class RadioButtonControlComponent
  extends BaseControlComponent
  implements OnInit
{
  @Input()
  items: any[];

  @Input()
  valueProperty: string;

  @Input()
  labelProperty: string;

  @Input()
  ariaLabel: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
