import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectOption } from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import {
  AbsenceService,
  ConfigurationService,
  IncidentalAbsence,
  IncidentalAbsenceReason,
  IncidentalAbsenceSearch,
  Session,
} from '../..';
import { ManagerResult, srSpeak } from '../../../shared';

@Component({
  selector: 'app-absence-case-search',
  template: `
    <div class="app-pt3 tw-w-full tw-flex tw-flex-wrap">
      <form [formGroup]="formGroup" class="tw-flex tw-flex-wrap tw-w-full">
        <app-text-control
          formControlName="empId"
          [formGroup]="formGroup"
          label="Employee Id"
          [validationControl]="'Employee Id'"
          ngDefaultControl
          class="tw-w-full md:tw-w-[33%]"
          [errorValidationMessage]="'White space and special characters'"
        ></app-text-control>
        <app-text-control
          formControlName="firstName"
          [formGroup]="formGroup"
          label="First Name"
          ngDefaultControl
          class="tw-w-full md:tw-w-[33%]"
          [validationControl]="'First Name'"
          [minLengthValue]="2"
          [errorValidationMessage]="'White space and special characters'"
        ></app-text-control>
        <app-text-control
          formControlName="lastName"
          [formGroup]="formGroup"
          label="Last Name"
          ngDefaultControl
          class="tw-w-full md:tw-w-[34%]"
          [validationControl]="'Last Name'"
          [minLengthValue]="2"
          [errorValidationMessage]="'White space and special characters'"
        ></app-text-control>

        <app-text-control
          formControlName="caseNumber"
          [formGroup]="formGroup"
          [label]="
            isIncidentalAbsence5Feature ? 'Reference Number' : 'Absence Number'
          "
          [validationControl]="
            isIncidentalAbsence5Feature ? 'Reference Number' : 'Absence Number'
          "
          ngDefaultControl
          class="tw-w-full md:tw-w-[25%]"
          [errorValidationMessage]="'White space and special characters'"
        ></app-text-control>
        <app-select-control
          formControlName="reason"
          [formGroup]="formGroup"
          label="Reason"
          [options]="reasonOptions"
          [multiple]="true"
          ngDefaultControl
          class="tw-w-full md:tw-w-[25%]"
        ></app-select-control>

        <app-date-control
          formControlName="fromDate"
          [formGroup]="formGroup"
          label="From Date"
          ngDefaultControl
          [minValue]="startingDate"
          [validationControl]="'From Date'"
          class="tw-w-full md:tw-w-[25%]"
        ></app-date-control>
        <app-date-control
          formControlName="toDate"
          [formGroup]="formGroup"
          label="To Date"
          [minValue]="startingDate"
          ngDefaultControl
          [errormsgClass]="'errormsgabsence'"
          class="tw-w-full md:tw-w-[25%]"
          [validationControl]="'To Date'"
        ></app-date-control>
        <app-manager-selector
          formControlName="managers"
          [formGroup]="formGroup"
          [label]="mgrText"
          ngDefaultControl
          [managerLookupSupervisor]="true"
          class="tw-w-full md:tw-w-[33%]"
          [managerLookupOmni]="managerLookupOmni"
          [hintLabel]="mgrText"
          (supervisorSelected)="onSupervisorSelected($event)"
        >
        </app-manager-selector>
        <app-checkbox-control
          formControlName="includeIndirectReports"
          [formGroup]="formGroup"
          label="Include indirect reports"
          class="tw-w-full md:tw-w-[25%]"
        >
          ></app-checkbox-control
        >
        <div
          ng-hide="!showMessage"
          left1
          [innerHTML]="
            showMessage
              ? 'Error : Please Enter at least one search criteria.'
              : ''
          "
          class="error-validation md:tw-hidden tw-block tw-w-full "
        ></div>
        <app-action-row class="tw-w-full">
          <div
            ng-hide="!showMessage"
            left1
            [innerHTML]="
              showMessage
                ? 'Error : Please enter at least one search criteria.'
                : ''
            "
            class="error-validation md:tw-block tw-hidden"
          ></div>
          <app-button
            mat-button
            right2
            emphasis="High"
            (click)="search()"
            [loading]="loading"
            type="submit"
            class="btn-search roundbtn"
          >
            Search
          </app-button>
          <app-button mat-button right1 emphasis="Low" (click)="reset()">
            Clear
          </app-button>
        </app-action-row>
      </form>

      <div class="app-pt1 app-pb1 tw-w-full" *ngIf="isAbsenseShow">
        <app-header-label type="Header2">
          <div class="app-header-3">{{ absenceCount }} Absences</div>
        </app-header-label>
        <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'">
        </app-loading-panel>
      </div>
      <div *ngIf="isAbsenseShow && !loading" class="tw-w-full">
        <app-absence-grid
          class="tw-w-full"
          [absences]="absences"
          [reasonData]="reasonData"
          [showButtons]="false"
          [employeeLinkEnabled]="true"
          [filterEnabled]="true"
          noDataIcon="search"
          noDataMessage="No results"
          noDataThinMessage="There are no results that match the search"
          toastInfoMessage="Maximum results are displayed, consider adding additional search parameters."
          [displayInfoToastMsg]="exceedrecordlimit"
        ></app-absence-grid>
      </div>
    </div>
  `,
  styles: [
    `
      .error-validation {
        color: red;
      }

      .btn-clear {
        background-color: #ffffff !important;
        color: #3f464d !important;
        border: 1px solid #3f464d !important;
        font-weight: bold !important;
      }
      .btn-clear:hover {
        background-color: #f1f4f7 !important;
      }
      .btn-search {
        color: #ffffff !important;
        background-color: #007dbc !important;
        font-weight: bold;
      }
      ::ng-deep .mat-mdc-form-field-error-wrapper {
        padding: 0 7px !important;
      }
    `,
  ],
})
export class AbsenceCaseSearchComponent implements OnInit {
  @Input()
  initialSearch = false;

  @Input()
  initialValue: IncidentalAbsenceSearch;
  filterByManagerEmpUnum: string = '';
  mgrText: string = 'Filter by manager';
  reasonData: IncidentalAbsenceReason[] = [];
  httpErrorResponse: HttpErrorResponse;
  isAbsenseShow: boolean = false;
  loading = false;
  searching = true;
  showMessage: boolean = false;
  absences: IncidentalAbsence[] = [];
  exceedrecordlimit: boolean = false;
  isIncidentalAbsence5Feature: boolean = false;
  reasonOptions: SelectOption[] = [];
  managerLookupOmni: boolean = false;
  managerLookupSupervisor: boolean = false;
  startingDate: Date = new Date(
    new Date().setFullYear(new Date().getFullYear() - 2)
  );

  formGroup = this.fb.group({
    empId: [],
    firstName: ['', [Validators.minLength(2)]],
    lastName: ['', [Validators.minLength(2)]],
    caseNumber: [],
    reason: [],
    fromDate: [null],
    toDate: [null],
    includeIndirectReports: [true],
    managers: [null],
  });

  absenceCount: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private myabsense: AbsenceService,
    private config: ConfigurationService,
    private session: Session
  ) {
    this.formGroup.controls['fromDate'].addValidators(
      customvalidations.dateLessThan(
        this.formGroup.controls['toDate'],
        false,
        'Error : From Date cannot be later than the To Date.'
      )
    );

    this.formGroup.controls['toDate'].addValidators(
      customvalidations.dateGreaterThan(
        this.formGroup.controls['fromDate'],
        false,
        'Error : To Date cannot be earlier than the From Date.'
      )
    );

    this.formGroup.controls['firstName'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
    this.formGroup.controls['lastName'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
    this.formGroup.controls['empId'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
    this.formGroup.controls['caseNumber'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
  }

  async ngOnInit() {
    this.reasonData = await this.myabsense.getAbsenceReasons();
    this.reasonOptions = this.reasonData
      .filter((rd) => rd.parentId === '0')
      .map((rd): SelectOption => {
        return {
          value: rd.reasonDesc,
          label: rd.reasonDesc,
        };
      });
    this.managerLookupOmni =
      this.session.user.roles.omni || this.session.user.roles.overseer;
    if (this.initialValue) {
      this.formGroup.setValue({
        caseNumber: this.initialValue.absenceNumber,
        empId: this.initialValue.employeeId,
        firstName: this.initialValue.firstName,
        lastName: this.initialValue.lastName,
        reason: this.initialValue.reason,
        fromDate: this.initialValue.startDate,
        toDate: this.initialValue.endDate,
        includeIndirectReports: this.initialValue.includeIndirectReports,
        managers: null,
      });

      this.formGroup.controls['fromDate'].updateValueAndValidity();
      this.formGroup.controls['toDate'].updateValueAndValidity();
    }

    if (this.initialSearch) {
      this.search();
    }

    this.isIncidentalAbsence5Feature = await this.config.getConfigFeatureAccess(
      'IncidentalAbsences5'
    );
  }

  async search() {
    this.httpErrorResponse = null;
    this.exceedrecordlimit = false;
    this.formGroup.markAllAsTouched();
    if (
      (this.formGroup.controls['lastName'].value == '' ||
        this.formGroup.controls['lastName'].value == null) &&
      (this.formGroup.controls['firstName'].value == '' ||
        this.formGroup.controls['firstName'].value == null) &&
      (this.formGroup.controls['empId'].value == '' ||
        this.formGroup.controls['empId'].value == null) &&
      (this.formGroup.controls['toDate'].value == null ||
        this.formGroup.controls['toDate'].value == '') &&
      (this.formGroup.controls['fromDate'].value == null ||
        this.formGroup.controls['fromDate'].value == '') &&
      this.formGroup.controls['reason'].value == null &&
      (this.formGroup.controls['caseNumber'].value == '' ||
        this.formGroup.controls['caseNumber'].value == null) &&
      (this.formGroup.controls['managers'].value == '' ||
        this.formGroup.controls['managers'].value == null)
    ) {
      this.showMessage = true;
      return;
    } else {
      // debugger;
      this.showMessage = false;
    }

    if (this.formGroup.valid) {
      this.loading = true;
      this.searching = true;
      this.isAbsenseShow = true;
      this.absences = [];
      const searchRequest: IncidentalAbsenceSearch = {
        employeeId: this.formGroup.controls['empId'].value || null,
        startDate: new Date(
          this.formGroup.controls['fromDate'].value == null
            ? undefined
            : this.formGroup.controls['fromDate'].value
        ),
        endDate: new Date(
          this.formGroup.controls['toDate'].value == null
            ? undefined
            : this.formGroup.controls['toDate'].value
        ),
        reason: this.formGroup.controls['reason'].value || null,
        absenceNumber: this.formGroup.controls['caseNumber'].value || null,
        firstName: this.formGroup.controls['firstName'].value || null,
        lastName: this.formGroup.controls['lastName'].value || null,
        empUnum: this.filterByManagerEmpUnum ? this.filterByManagerEmpUnum : '',
        includeIndirectReports: this.formGroup.get('includeIndirectReports')
          .value
          ? true
          : false,
      };

      this.absences = await this.myabsense.getSearchAbsences(searchRequest);
      this.absenceCount = this.absences.length.toString();

      if (this.absences != undefined && this.absences.length >= 200) {
        this.exceedrecordlimit = true;
        this.searching = false;
      }

      this.searching = false;
      this.loading = false;
      return;
    }
  }

  reset() {
    this.absences = [];
    this.isAbsenseShow = false;
    this.formGroup.reset();
    this.formGroup.controls['fromDate'].setValue(undefined);
    this.formGroup.controls['toDate'].setValue(undefined);
  }

  employeeClicked() {
    this.router.navigate(['/absences']);
  }

  async onSupervisorSelected(supervisor: ManagerResult) {
    this.filterByManagerEmpUnum = supervisor ? supervisor.empUnum : '';
    srSpeak('Your own manager dashboard loaded', 'polite');
  }
}

export class SearchIncidentalAbsence {
  type: string;
  absenceNumber: string;
  absenceId: string;
  startDate: Date;
  endDate: Date;
  shifts: number;
  estimatedRtw: Date;
}
