import { Component } from '@angular/core';
import { UserActionService } from 'src/app/shared';
import { UserRoleService } from '../../services/user-role.service';
import { UserActions } from 'src/app/shared/models/user';

@Component({
  selector: 'app-summary-dashboard',
  template: `
    <app-section *ngIf="!userrole.auditor">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb
          [helpText]="summaryHelpText"
        ></app-claim-breadcrumb>
      </div>
      <app-notifications-header class="tw-w-full"></app-notifications-header>
      <app-panel class="tw-w-full" [border]="'Empty'" [canExpand]="false">
        <div class="tw-w-full">
          <app-card-dashboard></app-card-dashboard>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: [``],
})
export class SummaryDashboardComponent {
  summaryHelpText: string = '';
  constructor(
    public userrole: UserRoleService,
    private userAction: UserActionService
  ) {
    this.userAction.setAction(UserActions.ManagerSummaryView);

    if (userrole.omni) {
      this.summaryHelpText = 'The Summary View includes all employees.';
    }
    if (userrole.overseer) {
      this.summaryHelpText =
        'The Summary View includes all employees in which you have oversight or administrator responsibilities.';
    }
  }

  async ngOnInit() {
    this.userAction.setAction(UserActions.ManagerSummaryView);
  }
}
