import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function existingDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      //avoid validation if empty
    }
    const errors: ValidationErrors = {};
    const todayDate = new Date(control.value);
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 731);
    const pastDate = new Date('01/01/2000')
    if ((todayDate > futureDate) || (todayDate < pastDate)) {
      errors['existingDate'] = `Invalid Date`;
    }
    return Object.keys(errors).length ? errors : null;
  };
}
