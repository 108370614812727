export interface Accommodation {
  accommodationDescription: string;
  accommodationId: string;
  accommSubtypeDescription: string;
  tempPerm: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  decision: string;
  decisionId: string;
  decisionDate?: Date;
  leaveFileNumber: string;
  accommodationRequestNumber: string;
  lineOfBusiness: string;
  status: string;
  requestSubStatusTypeId: string;
  requestSubStatusTypeText: string;
  requestType: string;
  searchDetailsStartDate?: Date;
  searchDetailsEndDate?: Date;
  recordId: string;
  recordSource: string;
  empUnum: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  requestSubstatusTypeId: string;
  lob: string;
  name: string;
}

export interface AccommodationJobFunction {
  accommodationDescription: string;
  accommodationId: string;
  accommSubtypeDescription: string;
  tempPerm: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  decision: string;
  decisionId: string;
  decisionDate?: Date;
  leaveFileNumber: string;
  accommodationRequestNumber: string;
  lineOfBusiness: string;
  status: string;
  requestSubStatusTypeId: string;
  requestSubStatusTypeText: string;
  requestType: string;
  searchDetailsStartDate?: Date;
  searchDetailsEndDate?: Date;
  recordId: string;
  recordSource: string;
  empUnum: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  requestSubstatusTypeId: string;
  lob: string;
  name: string;
  jobFunctionStatusId: string;
  jobFunctionTask: string;
  type: string;
  claimType: string;
  userOwns: boolean;
}

export interface AccommodationListing {
  accommRequestSubStatus?: string;
  beginDate?: Date;
  caseApprovedBeginDate?: Date;
  caseApprovedEndDate?: Date;
  contNum: string;
  dateReportedToTpa: Date;
  empUnum: string;
  employeeId: string;
  employeeSsn: string;
  endDate?: Date;
  fileNumber: string;
  firstName: string;
  hrMasterUid: string;
  lastName: string;
  lineOfBusiness: string;
  recordId: string;
  recordSource: string;
  requestType: string;
  status: string;
  workState: string;
  claimType: string;
  name: string;
  userOwns: boolean;
}

export interface PendingAccommodation {
  lineOfBusiness: string;
  accommodationRequestNumber: string;
  type: string;
  begin?: Date;
  end?: Date;
  claimType: string;
  recordId: string;
  recordSource: string;
  empUnum: string;
  employeeId: string;
  lob: string;
  firstName: string;
  lastName: string;
  name: string;
  userOwns: boolean;
}

export interface OmniNotifications {
  claimNumber: string;
  message: string;
  lineOfBussines: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  notificationType: string;
  begin?: Date;
  end?: Date;
  date: Date;
  alertMasterUid: string;
}

export interface HistoryAccommodation {
  lineOfBusiness: string;
  accommodationRequestNumber: string;
  status: string;
  subStatus: string;
  requestType: string;
  decision: string;
  decisionDate?: Date;
  begin?: Date;
  end?: Date;
  longTerm: string;
  type: string;
  subType: string;
  description: string;
  recordId: string;
  recordSource: string;
  empUnum: string;
  employeeId: string;
  lob: string;
  firstName: string;
  lastName: string;
  leaveFileNumber: string;
  leaveClaim?: any;
  name: string;
  claimType: string;
  decisionId: string;
  userOwns: boolean;
}

export enum PendingAccommodationPanels {
  Null,
  ApproveJobFunctions,
  UnapproveJobFunctions,
  UnapprovedAccommodations,
}
