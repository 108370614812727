import { isEmpty } from './is-empty';

/**
 * Joins values with seperator. Empty values are not included. If error, returns values?.toString.  Default seperator is ' - '.
 */
export function join(values: any[], seperator: string = ' - '): string {
  let result: string = '';
  try {
    result = values?.filter((x) => !isEmpty(x)).join(seperator);
  } catch (error) {
    result = values?.toString();
  }

  return result;
}
