import { Injectable } from '@angular/core';
import { emulator } from './emulator.service';

@Injectable({
  providedIn: 'root',
})
export class OutboundSsoService {
  constructor(private enumalatorService: emulator) {}
  /**
   * Opens url in _blank tab. Url is determined by ssoType parameter.
   * @param ssoType
   */
  openSsoUrl(ssoType: string, ssoEndPoint: string) {
    if (this.enumalatorService.showAuditorInfo()) {
      return;
    } else {
      window.open(ssoEndPoint, '_blank');
    }
  }
}
