import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ListComponent, SearchResult, deepCopy } from 'src/app/shared';
import { ClaimService, CommCenterService, UserRoleService } from '../..';
import { SaveMessageActionRequest } from '../../models/Communications';
import { ConversationSummary } from '../../models/ConversasationSummary';
import { Session } from '../../services/session.service';
import { MessageBus } from '../../../shared';

@Component({
  selector: 'app-comm-center2-claims',
  template: `
    <app-loading-panel
      [error]="loadingError"
      loadingStyle="Skeleton"
      [loadingHeight]="320"
      [state]="loading ? 'Loading' : 'Loaded'"
    >
      <ng-template #content>
        <div>
          <app-list
            #conversationsList
            [dataSource]="conversations"
            [filterEnabled]="true"
            [paginatorEnabled]="false"
            [template]="row"
            overflowHeight="275px"
            [rowSelectEnabled]="false"
            (rowClick)="
              selected = $event;
              conversationSelected.emit($event);
              messageBus.publish('comm-center-messages:focus')
            "
            [clickedItem]="selected"
            [tableAriaLabel]="'Communications'"
          >
            <ng-container header>
              <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                <app-list-header
                  name="claimDescription"
                  label="Claim"
                  [class]="modestyle"
                  [names]="['claimNumber']"
                ></app-list-header>
                <app-list-header
                  *ngIf="mode === 'Manager'"
                  name="name"
                  label="Name"
                  class="tw-w-full md:tw-w-[20%]"
                  [names]="['empId']"
                ></app-list-header>
                <app-list-header
                  name="status"
                  label="Status"
                  class="tw-w-full md:tw-w-[15%]"
                  [names]="['status', 'subStatus']"
                ></app-list-header>
                <app-list-header
                  name="date"
                  label="Date"
                  class="tw-w-full md:tw-w-[15%]"
                  [names]="['date']"
                ></app-list-header>
                <app-list-header
                  readOnly
                  label="Action"
                  class="tw-w-full md:tw-w-[20%]"
                ></app-list-header>
                <app-list-header
                  name="unread"
                  label="New"
                  class="tw-hidden"
                ></app-list-header>
              </tr>
            </ng-container>
            <ng-template #row let-element>
              <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                <td [class]="modestyle">
                  <app-value
                    label="Claim"
                    layout="row-xs"
                    [tabindex]="0"
                    labelStyle="pmt-header-init"
                  >
                    <app-claim-label
                      value
                      [claim]="element"
                      layout="row"
                      [disabled]="true"
                      [hideWatchList]="false"
                    ></app-claim-label>
                  </app-value>
                </td>
                <td *ngIf="mode === 'Manager'" class="tw-w-full md:tw-w-[20%]">
                  <app-value label="Name" [tabindex]="0" layout="row-xs">
                    <app-claimant-label
                      value
                      [claimant]="element"
                      [disabled]="true"
                    ></app-claimant-label>
                  </app-value>
                </td>
                <td class="tw-w-full md:tw-w-[15%]">
                  <app-value
                    label="Status"
                    [value]="[element.status, element.subStatus] | join"
                    [tabindex]="0"
                    layout="row-xs"
                  >
                  </app-value>
                </td>
                <td class="tw-w-full md:tw-w-[15%]">
                  <app-value
                    label="Date"
                    layout="row-xs"
                    [tabindex]="element.date ? '0' : '-1'"
                  >
                    <span value>
                      {{ element.date | format : 'date' }}
                      <span *ngIf="element.unread" class="app-indicator tw-ml-1"
                        >NEW</span
                      >
                      <span class="app-indicator tw-ml-1" *ngIf="element.read"
                        >READ</span
                      >
                    </span>
                  </app-value>
                </td>
                <td>
                  @if (element.details?.length > 0) {
                  <span class='label md-action-label'>ACTION: </span>
                  <a
                    class="tw-w-full md:tw-w-[20%]"
                    role="button"
                    tabindex="0"
                    [attr.aria-label]="
                      'View conversation for ' +
                      element.claimDescription +
                      '-' +
                      element.claimNumber
                    "
                    >View conversation</a
                  >
                  } @else if(element.status?.toUpperCase().indexOf('CLOSE') ===
                  -1) {
                  <span class='label md-action-label'>ACTION: </span>
                  <a
                    class="tw-w-full md:tw-w-[20%]"
                    role="button"
                    tabindex="0"
                    [attr.aria-label]="
                      'Start conversation for ' +
                      element.claimDescription +
                      '-' +
                      element.claimNumber
                    "
                    >Start conversation</a
                  >
                  }
                </td>
              </tr>
            </ng-template>
          </app-list>
        </div>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [`
      @media (min-width: 768px) {
        .md-action-label {
          display:none;
        }
      }

      .label {
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 16px;
      }
  `],
})
export class CommCenter2ClaimsComponent implements OnInit {
  @ViewChild('conversationsList', { static: false })
  conversationsList: ListComponent;

  @Input()
  mode: CommCenterMode;

  @Output()
  conversationSelected: EventEmitter<ConversationSummary> = new EventEmitter();

  conversations: ConversationSummary[] = [];

  selected: ConversationSummary;

  loading = true;

  loadingError: any;

  modestyle: string;

  constructor(
    public session: Session,
    private commCenterService: CommCenterService,
    public claimService: ClaimService,
    private userRole: UserRoleService,
    public messageBus: MessageBus
  ) {}

  async ngOnInit() {
    if (!this.userRole.auditor) {
      await this.loadConversations();
    }
    if (this.mode === 'Manager') {
      this.modestyle = 'tw-w-full md:tw-w-[30%]';
    }
    if (this.mode === 'MyClaims') {
      this.modestyle = 'tw-w-full md:tw-w-[50%]';
    }
  }

  async loadConversations() {
    this.loading = true;
    this.selected = this.conversationsList?.clickedItem;
    this.conversations = [];
    setTimeout(async () => {
      try {
        await this.getConversations();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.loadingError = error;
      }

      this.loading = false;
    }, 0);
  }

  // This handles loading a conversation from search results list
  async loadConversation(result: SearchResult) {
    this.loading = true;

    let summary: ConversationSummary;
    summary = await this.commCenterService.getClaimConversation(
      result.source,
      result.claimId
    );

    // no conversation exists, create a new conversation summary
    if (!summary) {
      // calling getSummary, to populate companionClaims property
      const claimSummary = await this.claimService.getSummary(
        result.source,
        result.claimId,
        true,
        true
      );

      // The status from claim summary will sometimes contain the
      // substatus as well. If a hypen exists, set subStatus to null.
      // Using deepCopy to avoid mutating a cached object
      const claimSummaryCopy = deepCopy(claimSummary);
      if (claimSummaryCopy.status?.indexOf('-') > -1) {
        claimSummaryCopy.subStatus = null;
      }

      summary = await this.commCenterService.createClaimConversation(
        claimSummaryCopy
      );
    }

    this.loading = false;

    setTimeout(() => {
      this.conversationsList.updateDataSource();
      this.conversationsList.clickedItem = summary;
      this.conversationSelected.emit(summary);
      this.conversationsList.scrollTo(summary);
    }, 0);
  }

  private async getConversations() {
    const messagelst =
      this.mode === 'MyClaims'
        ? await this.commCenterService.getMyConversations()
        : await this.commCenterService.getManagerConversations();

    let message1 = messagelst;
    if (message1) {
      message1 = messagelst.filter(
        (x) =>
          x.status?.toUpperCase().indexOf('CLOSE') === -1 ||
          x.details.length > 0
      );
    }

    this.conversations = message1.sort((m1, m2) => {
      const date1 = m1.date ?? new Date(0);
      const date2 = m2.date ?? new Date(0);
      return date2.getTime() - date1.getTime();
    });
  }

  flagMessages(messageId: string): void {
    const flagConversation = this.conversations.filter(
      (m) => m.messageId === messageId
    )[0];

    flagConversation.details.forEach((d) => {
      if (d.unread) {
        const saveMessageActionRequest: SaveMessageActionRequest =
          new SaveMessageActionRequest();
        saveMessageActionRequest.ClaimNumber = flagConversation.claimNumber;
        saveMessageActionRequest.ContNum = flagConversation.contNum;
        saveMessageActionRequest.CorrelationId = d.correlationId;
        saveMessageActionRequest.ConversationDetailId = d.detailId;
        saveMessageActionRequest.MessageId = flagConversation.messageId;
        saveMessageActionRequest.SourceSystem = flagConversation.source;

        this.commCenterService
          .readMessage(
            flagConversation.source,
            flagConversation.claimId,
            flagConversation.lob,
            saveMessageActionRequest
          )
          .then(() => {
            d.unread = false;
            d.read = true;
            flagConversation.unread =
              flagConversation.details.filter((fd) => fd.unread)?.length > 0;
          });
      }
    });
  }
}

export type CommCenterMode = 'MyClaims' | 'Manager';
