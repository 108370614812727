import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { LearningCenter } from '../../shared/models/learning-center';

@Injectable({
  providedIn: 'root',
})
export class LearningCenterService {
  constructor(private http: Http) {}

  async getLearningCenter(
    source: string,
    claimId: string | null,
    lob: string,
    lcType: string,
    terminated: boolean
  ) {
    const url = claimId
      ? `/api/learningcenter/${source}/${claimId}/${lob}/${lcType}/GetLearningCenterClaim`
      : `/api/learningcenter/${source}/${lob}/${lcType}/${terminated}/GetLearningCenter`;
    return this.http.get<LearningCenter>(url, {
      cache: false,
      timeout: 0,
    });
  }
}
