import { Component, Input, OnInit } from '@angular/core';
import {
  BaseComponent,
  ClaimDataService,
  ConfigurationSetting,
  SearchResult,
  getUrlParams,
} from 'src/app/shared';
import { ClaimService, ClaimantService, Session, UserRoleService } from '../..';
import { PendingAccommodationPanels } from '../../models/accommodation';
import { AccommodationsService } from '../../services/accommodations-service';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { EncryptionService } from '../../services/encryption.service';

@Component({
  selector: 'app-pending-accommodations-page',
  template: `
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb class="tw-w-[90%]"></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        title="Accommodation Requests"
        class="tw-w-full app-pb4"
        [showTitle]="true"
        [canExpand]="false"
      >
        <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'">
          <ng-template #content>
            <div class="tw-flex tw-flex-wrap tw-w-full tw-mt-4">
              <app-value label="Name" class="tw-w-[34%]">
                <app-claimant-label
                  value
                  [claimant]="claim"
                  [disabled]="false"
                  layout="col"
                ></app-claimant-label>
              </app-value>
              <app-value label="Claim" class="tw-w-[34%]">
                <app-claim-label
                  value
                  [claim]="claim"
                  [disabled]="false"
                  [hideWatchList]="true"
                  [noLink]="false"
                  layout="col"
                ></app-claim-label>
              </app-value>
            </div>
            <app-custom-message
              [listClientMessage]="this.claim?.listClientMessage"
              [keyForMessage]="'PendingAccommodation'"
            >
            </app-custom-message>
            <app-approved-job-functions-panel
              [claim]="claim"
              [claimConfig]="claimConfig"
              [expanded]="expandApprovedJob"
              [approvedJobFunctionData]="interactionsData.approvedJobFunctions"
            >
            </app-approved-job-functions-panel>
            <app-unapproved-job-functions-panel
              *ngIf="
                userrole.manager && userOwns === 'false' && isBusinessReview
              "
              [claim]="claim"
              [claimConfig]="claimConfig"
              [expanded]="expandUnapprovedJob"
              [unApprovedJobFunctionData]="
                interactionsData.unapprovedJobFunctions
              "
            >
            </app-unapproved-job-functions-panel>
            <app-unapproved-accommodations-panel
              *ngIf="
                userrole.manager && userOwns === 'false' && isBusinessReview
              "
              [claim]="claim"
              [claimConfig]="claimConfig"
              [expanded]="expandUnapproveAccomm"
              [unApprovedAccommodations]="
                interactionsData.unapprovedAccomodations
              "
            >
            </app-unapproved-accommodations-panel>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: [``],
})
export class PendingAccommodationsPageComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  claim: SearchResult = <SearchResult>{};

  claimId: string;
  source: string;
  empUnum: string;
  claimConfig: ConfigurationSetting;
  loading: boolean = true;

  panel?: PendingAccommodationPanels;
  expandApprovedJob: boolean = false;
  expandUnapprovedJob: boolean = false;
  expandUnapproveAccomm: boolean = false;
  interactionsData: any;
  isBusinessReview: boolean = false;
  userOwns: string = 'false';

  constructor(
    private claimDataService: ClaimDataService,
    public claimService: ClaimService,
    private accommodationsService: AccommodationsService,
    public userrole: UserRoleService,
    private session: Session,
    private claimantService: ClaimantService,
    private userAction: UserActionService,
    private encrypt: EncryptionService
  ) {
    super();
  }

  async ngOnInit() {
    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.panel = params.panel;
    this.empUnum = this.encrypt.decrypt(params.empUnum);
    this.userOwns = params.userOwns;
    this.interactionsData =
      await this.accommodationsService.getAccommodationsInteractions(
        this.claimId,
        this.empUnum
      );
    //console.log('pendadc', this.interactionsData);
    if (this.userAction.getBustCache()) {
      this.userAction.setBustCache(false);
    }

    if (this.panel && !isNaN(this.panel)) {
      switch (Number(this.panel)) {
        case PendingAccommodationPanels.ApproveJobFunctions:
          this.expandApprovedJob = true;
          break;
        case PendingAccommodationPanels.UnapproveJobFunctions:
          this.expandUnapprovedJob = true;
          break;
        case PendingAccommodationPanels.UnapprovedAccommodations:
          this.expandUnapproveAccomm = true;
          break;
      }
    }

    this.subs.sink = this.claimDataService.currentClaim$.subscribe((claim) => {
      if (claim) {
        this.claim = claim;
        this.loading = false;
      }
    });

    if (!this.claim || Object.keys(this.claim).length === 0) {
      this.claim = await this.claimService.getSummary(
        this.source,
        this.claimId
      );
      this.loading = false;
    }
    if (this.claim.subStatus === 'Pending business response') {
      this.isBusinessReview = true;
    }
  }
}
