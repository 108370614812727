import { Component, Input, OnInit } from '@angular/core';
import { Session } from '../../services/session.service';
import { ConfigurationSetting, SearchResult } from 'src/app/shared';
import { ApprovedJobFunction } from '../../models/accommodations-interactions';
import {
  CsvParams,
  CsvService,
  SnackbarService,
  SnackbarType,
} from '../../../shared';
import { AccommodationsService } from '../../services/accommodations-service';

@Component({
  selector: 'app-approved-job-functions-panel',
  template: `
    <app-panel
      title="Approved Job Functions"
      class="tw-w-full"
      [expanded]="expanded"
      titleCollapse="Collapse approved Job Functions"
      titleExpand="List of approved Job Functions"
    >
      <div class="tw-flex tw-flex-wrap tw-w-full">
        <app-value
          *ngIf="listData.jobTitle"
          class="tw-w-full md:tw-w-[34%]"
          layout="row"
          label="Job Title"
          [value]="listData.jobTitle"
        ></app-value>
        <app-value
          *ngIf="listData.createdDate"
          class="tw-w-full md:tw-w-[33%]"
          layout="row"
          label="Employment Start"
          [value]="listData.createdDate | format : 'date'"
        ></app-value>
        <app-value
          *ngIf="listData.employerProvided"
          class="tw-w-full md:tw-w-[33%]"
          layout="row"
          label="Employer Provided Job Functions"
          [value]="listData.employerProvided"
        ></app-value>
      </div>
      <ng-container>
        <app-approved-job-function
          [approvedJobFunction]="listData"
        ></app-approved-job-function>
      </ng-container>
      <div *ngIf="hasEssentialData" class="tw-flex tw-justify-end tw-pt-2">
        <button
          mat-icon-button
          class="mat-datepicker-toggle tw-self-center"
          title="Export"
          [attr.aria-label]="exportAriaLabel"
          *ngIf="exportEnabled"
          (click)="export()"
        >
          <mat-icon class="app-color-input-grey">file_download</mat-icon>
        </button>
      </div>
      <div
        class="tw-w-full tw-flex tw-justify-center tw-pt-4"
        *ngIf="!hasEssentialData"
      >
        <app-empty-state-label
          [icon]="noDataIcon"
          [message]="noDataMessage"
        ></app-empty-state-label>
      </div>
    </app-panel>
  `,
  styles: [``],
})
export class ApprovedJobFunctionsPanelComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  expanded: boolean = false;

  listData: ApprovedJobFunction;

  @Input()
  approvedJobFunctionData: ApprovedJobFunction;

  noDataIcon: string = 'data_usage';

  noDataMessage: string = '<strong>No approved job functions.</strong><br>';

  exportEnabled = true;

  exportAriaLabel = 'Export records';

  hasEssentialData : boolean = true;

  constructor(
    public session: Session,
    private accommodationsService: AccommodationsService,
    private snackbar: SnackbarService,
    private csvService: CsvService
  ) {}

  async ngOnInit() {
    /*await this.accommodationsService
      .getAccommodationsInteractions(this.claim.claimId)
      .then((data) => {
        this.listData = data.approvedJobFunctions;
      });*/

    this.listData = this.approvedJobFunctionData;
    if(this.listData != undefined && this.listData != null && this.listData.essentialJobFunctions != undefined && this.listData.essentialJobFunctions != null)
    {
      this.hasEssentialData = this.listData.essentialJobFunctions.length > 0;
    }
    else 
    {
      this.hasEssentialData = false;
    }
  }

  export() {
    this.exportAriaLabel = 'Export file is downloading';
    if (!this.listData) {
      this.snackbar.show('No records to export!', SnackbarType.Info);
    }
    const currentDate = new Date();
    const shortDateString = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const csvParams: CsvParams = {
      fileName: `Approved_Job_Functions_${shortDateString}`,
      rows: this.listData.essentialJobFunctions.map((ef) => ({
        jobTitle: this.listData.jobTitle,
        createdDate: this.listData.createdDate,
        employerProvided: this.listData.employerProvided,
        jobFunctions: ef.approvedTask,
        impactBeginDate: ef.impactStart,
        impactendDate: ef.impactEnd,
        impactLongTerm: ef.impactedLongTerm,
        restrictionBeginDate: ef.restrictionBegin,
        restrictionEndDate: ef.restrictionEnd,
        restrictionLongTerm: ef.restrictionLongTerm,
        medicalNeeded: ef.medicalNeeded,
        majorLife: ef.majorLifeActivity.join(', '),
        physicalRestriction: ef.physicalRestriction.join(', '),
        cognitiveRestriction: ef.cognitiveRestriction.join(', '),
        bodilyRestriction: ef.bodyPartImpacted.join(', '),
        restrictionDescription: ef.restrictionDescription,
      })),
      keys: [
        'jobTitle',
        'createdDate',
        'employerProvided',
        'jobFunctions',
        'impactBeginDate',
        'impactendDate',
        'impactLongTerm',
        'restrictionBeginDate',
        'restrictionEndDate',
        'restrictionLongTerm',
        'medicalNeeded',
        'majorLife',
        'physicalRestriction',
        'cognitiveRestriction',
        'bodilyRestriction',
        'restrictionDescription',
      ],
      headers: [
        'Job Title',
        'Employment Start',
        'Employer Provided Job Function',
        'Job Function',
        'Impacted Period Begin',
        'Impacted Period End',
        'Impacted Period Long Term',
        'Restriction Period Begin',
        'Restriction Period End',
        'Restriction Period Long Term',
        'Medical Needed',
        'Major Life Activities',
        'Physical Restrictions',
        'Cognitive Restrictions',
        'Body Parts Impacted',
        'Restriction Description',
      ],
      footerNote:
        'This report contains information that may be confidential and/or privileged. The information is intended solely for the individual or entity running the report and access by anyone else is unauthorized.\n If you are not the individual to whom this information refers any disclosure copying distribution or use of the contents of this information is prohibited and may be unlawful.',
      includeTimeWithDates: false,
      footerAfterRows: 2,
      headerNote: [],
      headerNoteBefore: 0,
    };
    this.csvService.download(csvParams);
    this.exportAriaLabel = 'Export file is downloaded';
  }
}
