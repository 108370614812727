import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  ActAsDelegateGuard,
  ActDelegatePageComponent,
  AuditorDashboardPageComponent,
  ChangeAuthenticationPageComponent,
  ClaimHistoryPageComponent,
  ClaimantGuard,
  ClaimsPageComponent,
  ControllerGuard,
  DashboardPageComponent,
  DesignPageComponent,
  DirectDepositPageComponent,
  EmptyPageComponent,
  HelpPageComponent,
  LayoutComponent,
  LeaveBalanceComponent,
  ManageDelegateGuard,
  ManageDelegatePageComponent,
  ManagementNotificationPageComponent,
  ManagerGuard,
  MileageEditPageComponent,
  MileageReimbursementPageComponent,
  MileageReviewPageComponent,
  NewClaimsPageComponent,
  NotFoundPageComponent,
  NotificationsPageComponent,
  OffWorkPageComponent,
  OpenClaimsPageComponent,
  PendingAccomodationsPageComponent,
  PreferencesPageComponent,
  ReturnToWorkListPageComponent,
  SearchPageComponent,
  SecurityQuestionsPageComponent,
  SiteMapPageComponent,
  SummaryDashboardComponent,
  UnauthorizedPageComponent,
  WatchListPageComponent,
  EmployeeSearchPageComponent,
} from 'src/app/my-sedgwick';
import { AbsenceDashboardPageComponent } from './components/absence/absence-dashboard-page.component';
import { AbsencePageComponent } from './components/absence/absence-page.component';
import { MyAbsenceComponent } from './components/absence/my-absence.component';
import { PendingAccommodationsPageComponent } from './components/accommodation/pending-accommodations-page.component';
import { ConfirmReturnToWorkComponent } from './components/activity/confirm-return-to-work.component';
import { ReturnToWorkPageComponent } from './components/activity/return-to-work-page.component';
import { MyWorkSchedulePageComponent } from './components/claim/my-work-schedule.component';
import { PharmacyCardPageComponent } from './components/claim/pharmacy-card-page.component';
import { ReportAnAbsencePageComponent } from './components/claim/report-an-absence-page.component';
import { CommCenter2PageComponent } from './components/comm-center2/comm-center2-page.component';
import { ContactPreferencesEditComponent } from './components/contacts/contact-preferences-edit.component';
import { ControllerDashboardPageComponent } from './components/dashboard/controller-dashboard-page.component';
import { ManagerDashboardPageComponent } from './components/dashboard/manager-dashboard-page.component';
import { ManagerDashboardComponent } from './components/dashboard/manager-dashboard.component';
import { FormPageComponent } from './components/form/form-page.component';
import { LandingPageComponent } from './components/landing/landing-page.component';
import { ChangePasswordPageComponent } from './components/preferences/change-password-page.component';
import { TechnicalDifficultiesComponent } from './components/technical-difficulties/technical-difficulties.component';
import { SupervisorGuard } from './core/guards/supervisor.guard';
//import { PtoComponent } from './components/pto/pto.component';
const titleSuffix = ' - mySedgwick';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
        title: 'Home' + titleSuffix,
      },
      {
        path: 'yourcontactinformation',
        component: ContactPreferencesEditComponent,
        title: 'Your Contact Information' + titleSuffix,
      },
      {
        path: 'dashboard',
        component: DashboardPageComponent,
        title: 'My claims' + titleSuffix,
      },
      {
        path: 'managerdashboard',
        component: ManagerDashboardPageComponent,
        title: 'Manager View' + titleSuffix,
      },
      {
        path: 'controller',
        component: ControllerDashboardPageComponent,
        canActivate: [ControllerGuard],
        title: 'Manager view' + titleSuffix,
      },
      {
        path: 'absences',
        component: AbsencePageComponent,
        title: 'Absences' + titleSuffix,
      },
      {
        path: 'myabsences',
        component: MyAbsenceComponent,
        title: 'My Absences' + titleSuffix,
      },
      {
        path: 'absence-dashboard',
        component: AbsenceDashboardPageComponent,
        title: 'Absences' + titleSuffix,
      },
      {
        path: 'supervisor',
        component: ManagerDashboardComponent,
        canActivate: [SupervisorGuard],
        title: 'Manager view' + titleSuffix,
      },
      {
        path: 'summary',
        component: SummaryDashboardComponent,
        title: 'Summary view' + titleSuffix,
      },
      {
        path: 'auditor',
        component: AuditorDashboardPageComponent,
        title: 'Search' + titleSuffix,
      },
      {
        path: 'summary/new-claims',
        component: NewClaimsPageComponent,
        title: 'New claims' + titleSuffix,
      },
      {
        path: 'summary/open-claims',
        component: OpenClaimsPageComponent,
        title: 'Open claims' + titleSuffix,
      },
      {
        path: 'summary/return-to-work',
        component: ReturnToWorkListPageComponent,
        title: 'Returning to work' + titleSuffix,
      },
      {
        path: 'summary/off-work',
        component: OffWorkPageComponent,
        title: 'Off work' + titleSuffix,
      },
      {
        path: 'summary/pending-accommodations',
        component: PendingAccomodationsPageComponent,
        title: 'Pending Accommodations' + titleSuffix,
      },
      {
        path: 'summary/notifications',
        component: NotificationsPageComponent,
        title: 'Notifications' + titleSuffix,
      },
      {
        path: 'claims',
        component: ClaimsPageComponent,
        title: 'Claim summary' + titleSuffix,
      },
      /*       {
        path: 'claim-communication',
        component: ClaimCommPageComponent,
        title: 'Communication center' + titleSuffix,
      },
 */ {
        path: 'mileage-reimbursement',
        component: MileageReimbursementPageComponent,
        title: 'Mileage reimbursement' + titleSuffix,
      },
      {
        path: 'myclaims',
        component: ClaimsPageComponent,
        canActivate: [ClaimantGuard],
        title: 'My claims' + titleSuffix,
      },
      {
        path: 'search',
        component: SearchPageComponent,
        //canActivate: [ManagerGuard],
        title: 'Search' + titleSuffix,
      },
      {
        path: 'employeeSearch',
        component: EmployeeSearchPageComponent,
        title: 'Employee Search' + titleSuffix,
      },
      {
        path: 'watchlist',
        component: WatchListPageComponent,
        canActivate: [ManagerGuard],
        title: 'Watch List' + titleSuffix,
      },
      {
        path: 'sitemap',
        component: SiteMapPageComponent,
        title: 'Site Map' + titleSuffix,
      },
      {
        path: 'history',
        component: ClaimHistoryPageComponent,
        canActivate: [ManagerGuard],
        title: 'History' + titleSuffix,
      },
      {
        path: 'PreferencesPage',
        component: PreferencesPageComponent,
        title: 'Account settings' + titleSuffix,
      },

      {
        path: 'securityquestions',
        component: SecurityQuestionsPageComponent,
        title: 'Security questions' + titleSuffix,
      },

      {
        path: 'changepassword',
        component: ChangePasswordPageComponent,
        title: 'Change password' + titleSuffix,
      },

      {
        path: 'managementnotification',
        component: ManagementNotificationPageComponent,
        title: 'Management notification' + titleSuffix,
      },
      {
        path: 'changeauthentication',
        component: ChangeAuthenticationPageComponent,
        title: 'Change authentication' + titleSuffix,
      },

      {
        path: 'help',
        component: HelpPageComponent,
        title: 'Helpful resources' + titleSuffix,
      },
      {
        path: 'design',
        component: DesignPageComponent,
        title: 'Design' + titleSuffix,
      },
      {
        path: 'pharmacy-card',
        component: PharmacyCardPageComponent,
        title: 'Pharmacy card' + titleSuffix,
      },
      {
        path: 'technicaldifficulties',
        component: TechnicalDifficultiesComponent,
        title: 'Technical difficulties',
      },
      {
        path: 'return-to-work',
        component: ReturnToWorkPageComponent,
        title: 'Return to work' + titleSuffix,
      },
      {
        path: 'confirm-return-to-work',
        component: ConfirmReturnToWorkComponent,
        title: 'Confirm return to work' + titleSuffix,
      },
      {
        path: 'managedirectdeposit',
        component: DirectDepositPageComponent,
        title: 'Manage direct deposit',
      },
      {
        path: 'leaveBalanceSummary',
        component: LeaveBalanceComponent,
        title: 'Leave balance summary',
      },
      {
        path: 'reportanabsence',
        component: ReportAnAbsencePageComponent,
        title: 'Report an absence',
      },
      {
        path: 'e-sign',
        component: FormPageComponent,
        title: 'E-Sign' + titleSuffix,
      },
      {
        path: 'upload-documents-in-documentstab',
        component: ClaimsPageComponent,
        title: 'Upload documents' + titleSuffix,
      },
      {
        path: 'mileage-reimbursement-edit',
        component: MileageEditPageComponent,
        title: 'Mileage reimbursement' + titleSuffix,
      },
      {
        path: 'mileage-reimbursement-review',
        component: MileageReviewPageComponent,
        title: 'Mileage reimbursement' + titleSuffix,
      },
      {
        path: 'act-as-delegate',
        component: ActDelegatePageComponent,
        canActivate: [ActAsDelegateGuard],
        title: 'Act as delegate' + titleSuffix,
      },
      {
        path: 'manage-delegates',
        component: ManageDelegatePageComponent,
        canActivate: [ManageDelegateGuard],
        title: 'Manage delegate' + titleSuffix,
      },
      {
        path: 'my-work-schedule',
        component: MyWorkSchedulePageComponent,
        title: 'Work schedule' + titleSuffix,
      },
      {
        path: 'communication-center',
        component: CommCenter2PageComponent,
        title: 'Communication center' + titleSuffix,
      },
      {
        path: 'accommodations-pending',
        component: PendingAccommodationsPageComponent,
        title: 'Accommodation Requests' + titleSuffix,
      },
    ],
  },
  {
    path: 'pharmacy-card-print',
    component: PharmacyCardPageComponent,
    title: 'Print Pharmacy Card' + titleSuffix,
  },
  {
    path: 'empty',
    component: EmptyPageComponent,
    title: 'Empty' + titleSuffix,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent,
    title: 'Unauthorized' + titleSuffix,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    title: 'Not Found' + titleSuffix,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MySedgwickRoutingModule {}
