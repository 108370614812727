import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BreadcrumbItem,
  BreadcrumbService,
} from '../../services/breadcrumb.service';
import { UserRoleService } from '../../services/user-role.service';
import { UserActions } from 'src/app/shared/models/user';

@Component({
  selector: 'app-claim-breadcrumb',
  template: `
    <nav
      class="tw-w-full tw-flex tw-flex-wrap container tw-items-center"
      aria-label="Breadcrumb"
      role="navigation"
    >
      <ng-container
        *ngFor="let item of breadcrumb.items | async; let last = last"
      >
        @if(!item?.label?.includes('ndefined')){
        <span
          role="navigation"
          class="tw-font-bold app-color-slate"
          *ngIf="last; else link"
        >
          <div>{{ item.label }} <span class="sr-only">Current Page </span></div>
        </span>
        <ng-template #link>
          <a
            [attr.id]="item.label"
            (click)="navigate(item)"
            (keyup.enter)="navigate(item)"
            tabindex="0"
            [attr.aria-label]="'Navigate to ' + item.label"
            >{{ item.label }}</a
          >
          <span class="delimiter">
            <mat-icon class="delimiter-icon app-color-slate"
              >chevron_right</mat-icon
            >
          </span>
        </ng-template>
        }
      </ng-container>
      <span [attr.id]="'labelledbybreadcrumb'" hidden>Navigate to </span>
      <app-help-button
        *ngIf="helpText"
        [message]="helpText"
        [size]="24"
        [id]="'breadCrumb'"
      ></app-help-button>
    </nav>
  `,
  styles: [
    `
      .container {
        font-size: 15px;
        min-height: 56px;
        padding-left: 10px;
      }

      .delimiter {
        padding-left: 2px;
        padding-right: 2px;
      }

      .delimiter-icon {
        margin-top: 6px;
        height: 16px;
        width: 16px;
        font-size: 16px;
      }

      .helpIcon {
        vertical-align: top;
      }
      @media (max-width: 800px) {
        .hideIconMobile {
          display: none;
          visibility: hidden;
        }
      }

      .smallhelpicon {
        font-size: 25px !important;
        margin-left: 5px;
      }
    `,
  ],
})
export class ClaimBreadcrumbComponent implements OnInit {
  @Input()
  helpText: string;

  constructor(
    public breadcrumb: BreadcrumbService,
    private router: Router,
    public userRole: UserRoleService
  ) {}

  async ngOnInit() {
    this.breadcrumb.build();

    this.breadcrumb.items.forEach((item) => {
      if (item.length && item[0].label.includes('Search')) {
        if (item[0].label !== 'Employee Search') {
          if (this.userRole.auditor) {
            item[0].label = 'Search';
          } else {
            item[0].label = 'Claim search';
          }
        }
      }
    });
  }

  navigate(item: BreadcrumbItem) {
    if (this.userRole.manager && item.path == '/dashboard') {
      sessionStorage.setItem('mgrAction', JSON.stringify(UserActions[0]));
    }
    this.router.navigate([item.path], {
      queryParams: item.queryParams,
    });
  }
}
