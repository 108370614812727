import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Random {
  constructor() {}

  public getBool(): boolean {
    return Math.random() < 0.5;
  }

  public getString(maxLength: number): string {
    const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;
    const loremIpsumLength = 445;
    const length = this.getNumber(maxLength);
    const start = this.getNumber(loremIpsumLength - length - 1);
    let result = loremIpsum.slice(start, start + length).trim();

    if (result.length === 0) {
      result = this.getString(maxLength);
    }

    return result;
  }

  public getValue(...values: any[]): string {
    return values[this.getNumber(values.length)];
  }

  public getNumber(maxExclusive: number): number {
    return Math.floor(Math.random() * maxExclusive);
  }

  public getDate(): Date {
    const start = new Date(2022, 0, 1);
    return new Date(
      start.getTime() + Math.random() * (new Date().getTime() - start.getTime())
    );
  }
}
