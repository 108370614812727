
export class DirectDeposit{
     accountNumber:string;
     routingNumber : string;
     accountType : string;
     accountStatus : string;
     legalText : string = '';
    paymentMethod : string;

     address : string;
    city : string;
    state : string;
    zip : string;
    country : string;
    isOwnClaim : string;
    hasAccessToDirectDeposit : string;
}

export class DirectDepostRequest
{
   constructor()
   {
    this.ClaimUID = '';
    this.LineOfBusiness = '';
   }
    ClaimUID  :string;
    LineOfBusiness : string;
}

export class UpdateDirectDepositRequest
{
 ClaimUID :  string;
 AccountNumber :  string;
 AccountType :  string;
 Action :  string;
 RoutingNumber :  string;
 DataSet :  string;
 IsDSLOB : boolean;
}
