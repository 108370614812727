import { Address, SearchResult } from 'src/app/shared';
import { ClaimInfo } from '../../shared/models/search-result';
import { MileageDetail, UserMileageReimbursement } from './Mileages';
import { NoteRequest } from './NoteRequest';

export class MileageRequest {
  claimId: string;
  source: string;
  travelDate: Date;
  travelType: string;
  travelReason: string;
  travelDestination: string;
  startAddress: Address;
  endAddress: Address;
  expenses: ExpenseRequest[] = [];
}

export class ExpenseRequest {
  expenseType: string;
  amountPaid: number;
  receipt: any;
}

export class SubmitMileageRequest {
  jurisNoteRequest: NoteRequest;
  mileageReimbursementDetail: MileageDetail;
  userMileageReimbursement: UserMileageReimbursement;
  lob: string;
  source: string;
  vendorId: string;
  procUnit: string;
  signature: string;
}

export class SubmitMileageResponse {
  confirmationNumber: string;
}

export class MileageReceipts {
  fileName: string;
  status: string;
  file?: File;
  statusMessage?: string;
  progress: number;
}
