/**
 * Create a deep copy of the source object.
 * @param source
 * @returns
 */
export function deepCopy(source: any): any {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == source || 'object' != typeof source) {
    return source;
  }

  // Handle Date
  if (source instanceof Date) {
    copy = new Date();
    copy.setTime(source.getTime());
    return copy;
  }

  // Handle Array
  if (source instanceof Array) {
    copy = [];
    for (let i = 0, len = source.length; i < len; i++) {
      copy[i] = deepCopy(source[i]);
    }
    return copy;
  }

  // Handle Object
  if (source instanceof Object) {
    copy = {};
    for (const attr in source) {
      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(attr)) {
        copy[attr] = deepCopy(source[attr]);
      }
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}
