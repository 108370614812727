import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RouterService, SearchResult, SelectOption } from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import {
  MileageDetail,
  MileageTravelExpense,
  UserMileageReimbursementDetail,
} from '../../models/Mileages';
import { HistoryInfo } from '../../models/historyinfo';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { UserDetails } from '../../services/userdetails.service';

@Component({
  selector: 'app-mileage-expense-dialog',
  template: `<div id="rtweditpnl">
    <app-dialog
      title="Add expense"
      ariaLabel="Add expense dialog"
      confirmLabel="Save"
      cancelLabel="Close"
      [width]="700"
      (afterClosed)="resetData($event)"
      [SaveAlt]="successAlt"
      [CancelAlt]="cancelAlt"
      (confirmEvent)="updateExpenseData($event)"
      [showConfirm]="!isDisplaySuccess"
      #rtweditdialog
    >
      <ng-container *ngIf="!isDisplaySuccess">
        <form [formGroup]="formGroup">
          <div class="app-header-2">{{ getHeaderDesc() }}</div>
          <br />
          <div class="tw-flex tw-flex-wrap tw-w-full">
            <app-select-control
              formControlName="expenseType"
              [formGroup]="formGroup"
              label="Expense type?"
              [options]="expenseTypeOptions"
              [multiple]="false"
              ngDefaultControl
              class="tw-w-full md:tw-w-[80%]"
            ></app-select-control>
            <app-text-control
              formControlName="amountPaid"
              [formGroup]="formGroup"
              label="Amount paid"
              [validationControl]="'Total miles'"
              ngDefaultControl
              class="tw-w-full md:tw-w-[80%]"
            ></app-text-control>
            <app-mileage-receipt-upload
              class="tw-w-full"
              [travelExpenseData]="getExpenseDetails()"
              [claim]="claim"
              [claimId]="claim.claimId"
              [source]="claim.source"
              [dialogRef]="rtweditdialog.dialogRef"
              [hideHeader]="true"
            >
            </app-mileage-receipt-upload>
          </div>
        </form>
      </ng-container>

      <div *ngIf="isDisplaySuccess">
        <br />
        <app-toast type="Success">
          {{ afterRtwSuccessMessage }}
        </app-toast>

        <app-upload
          [claim]="claim"
          [claimId]="claim.claimId"
          [source]="claim.source"
          [dialogRef]="rtweditdialog.dialogRef"
          [hideHeader]="true"
          [showClose]="true"
        >
        </app-upload>
      </div>
    </app-dialog>
  </div> `,
  styles: [
    `
      @media (min-width: 700px) {
        .CommCenterIcon {
          float: right;
          margin-right: 15px;
          height: 20px;
        }
      }
      .prevpage {
        color: #007dbc;
        font-weight: bold;
        font-size: 14pt;
      }
      .prevpage:hover {
        text-decoration: none;
        border-bottom: 1px solid #035e81;
      }
      .currentpage {
        color: #171c20 !important;
        opacity: 1 !important;
      }

      .content {
        overflow-y: scroll;
        background-color: #f0f4f7;
      }
    `,
  ],
})
export class MileageReimbursementExpenseComponent implements OnInit {
  /*
  @Input()
  mileageData: MileageDetail;
  */
  @Input()
  dialogRef: MatDialogRef<any>;

  @Input()
  allowTotalCost: boolean;

  @Output()
  onUpdateExpense: EventEmitter<any> = new EventEmitter();

  isDisplaySuccess: boolean = false;
  isUploadDisplay: boolean = false;
  afterRtwSuccessMessage: string;

  formGroup = this.fb.group({
    expenseType: ['', [Validators.required]],
    amountPaid: ['', [Validators.required, customvalidations.IsDecimal()]],
  });
  expenseTypeOptions: SelectOption[] = [];
  claimId: string = '';

  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);

  claims: SearchResult[] = null;

  claim: SearchResult = <SearchResult>{};
  hasUploadDocs: boolean;
  historyinfo: HistoryInfo = <HistoryInfo>{};
  successAlt: string = '';
  cancelAlt: string = '';

  getHeaderDesc(): string {
    if (this.expenseDefaultData.expenseType !== null) {
      return 'Edit Expense';
    }

    return 'Add Expense';
  }
  updateExpenseData(eve: any) {
    if (this.formGroup.invalid) {
      return;
    }
    if (eve === 'confirm') {
      const data: any = {
        amountPaid: Number(this.formGroup.controls['amountPaid'].value),
        expenseType: this.formGroup.controls['expenseType'].value,
        Documents: this.expenseDefaultData.Documents.filter(
          (d) => d.status === 'success'
        ),
        closeDialog: true,
      };
      this.onUpdateExpense.emit([data]);
    }
  }

  resetData(event: string) {
    this.isDisplaySuccess = false;
    this.afterRtwSuccessMessage = '';
  }

  mileageData: MileageDetail;
  reimbursementDetailData: UserMileageReimbursementDetail;
  expenseIndex: number;
  expenseDefaultData: MileageTravelExpense;
  searching: boolean = true;
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public claimService: ClaimService,
    public session: Session,
    public routerService: RouterService,
    public userdetails: UserDetails,
    public alertService: ActivityService,
    public claimConfigService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  async ngOnInit() {
    this.searching = true;
    this.mileageData = this.dialogData.mileageData;
    if (this.dialogData.expenseIndex) {
      this.expenseIndex = this.dialogData.expenseIndex;
    }

    this.getExpenseTypeOptions();
    //debugger;
    if (this.expenseIndex >= 0) {
      //debugger;
      setTimeout(() => {
        this.cdr.detach();
        /*       this.formGroup.controls["expenseType"].setValue(this.reimbursementDetailData.Expenses[this.expenseIndex].expenseType);
      this.formGroup.controls["amountPaid"].setValue(this.reimbursementDetailData.Expenses[this.expenseIndex].amountPaid?.toString());
 */

        this.formGroup.controls['expenseType'].setValue(
          this.expenseDefaultData.expenseType
        );
        this.formGroup.controls['amountPaid'].setValue(
          this.expenseDefaultData.amountPaid.toString()
        );

        this.cdr.reattach();
        this.cdr.detectChanges();
      }, 10);
    }
  }

  getExpenseDetails() {
    return this.expenseDefaultData;
    if (this.expenseIndex >= 0) {
      return this.reimbursementDetailData.Expenses[this.expenseIndex];
    } else {
      const data: MileageTravelExpense = {
        amountPaid: Number(this.formGroup.controls['amountPaid'].value),
        expenseType: this.formGroup.controls['expenseType'].value,
        Documents: [],
      };

      //this.reimbursementDetailData.Expenses.push(data);
      //this.expenseIndex = this.reimbursementDetailData.Expenses.length - 1;

      return data;
      //return this.reimbursementDetailData.Expenses[this.expenseIndex];
    }
  }
  getExpenseTypeOptions(): SelectOption[] {
    if (
      !this.mileageData.expenseTypes ||
      this.mileageData.expenseTypes.length === 0
    ) {
      if (this.allowTotalCost) {
        const defaultOpt = [
          {
            label: 'Total cost',
            value: 'Total cost',
          },
        ];
        this.expenseTypeOptions = defaultOpt;
        return this.expenseTypeOptions;
      } else {
        this.expenseTypeOptions = [];
        return [];
      }
    }
    const opts = this.mileageData.expenseTypes.map((r) => {
      return {
        label: r,
        value: r,
      } as SelectOption;
    });

    if (this.allowTotalCost) {
      opts.unshift({
        label: 'Total cost',
        value: 'Total cost',
      });
    }
    this.expenseTypeOptions = opts;
    return opts;
  }
}
