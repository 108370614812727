import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageBus {
  topics = new Map<string, Subject<any>>();

  constructor() {}

  public publish(topic: string, value: any = null): void {
    this.createTopic(topic);
    this.topics.get(topic).next(value);
  }

  public topic(topic: string): Observable<any> {
    this.createTopic(topic);
    return this.topics.get(topic).asObservable();
  }

  private createTopic(topic: string) {
    if (!this.topics.has(topic)) {
      this.topics.set(topic, new Subject<any>());
    }
  }
}
