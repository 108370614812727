import { HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogService, Session, isManagement } from 'src/app/my-sedgwick';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { HelpfulLinkService } from '../../services/helpful-link.service';
import { UserRoleService } from '../../services/user-role.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-help-page',
  template: `
    <app-section>
      <app-claim-breadcrumb></app-claim-breadcrumb>
      <app-panel
        title="Helpful resources"
        [showTitle]="true"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
        *ngIf="loaded"
      >
        <app-tab-group
          [tabGroupId]="'helpTabGroup'"
          [selectedIndex]="selectedIndex"
          animationDuration="0"
          class="app-pt1"
          sectionName="Help"
          (tabChange)="resetVideo()"
        >
          <app-tab
            class="app-pb2"
            label="Learning Center"
            featureName="LearningCenter"
            *ngIf="showLearningCenter"
            [template]="learningCenter"
          >
            <ng-template #learningCenter>
              <app-learning-center></app-learning-center>
            </ng-template>
          </app-tab>

          <app-tab
            *ngIf="links?.count"
            class="app-pb2"
            [label]="links?.description ? links?.description : 'Helpful Links'"
            featureName="HelpfulLinks"
            [template]="linkTemplate"
          >
            <ng-template #linkTemplate>
              <ng-container *ngIf="links?.items">
                <div *ngFor="let lob of links?.items" class="app-pt1">
                  <h2
                    class="app-header-2 app-pt3"
                    [ngClass]="lob?.items.length > 0 ? 'tw-block' : 'tw-hidden'"
                  >
                    {{
                      lob?.name === 'CS'
                        ? ('Client Specific' | sentenceCase)
                        : (lob?.description | sentenceCase)
                    }}
                  </h2>
                  <div
                    *ngFor="let link of lob?.items; let i = index"
                    class="app-pt2 app-pl2"
                  >
                    <ng-container *ngIf="link?.url && link?.url !== '#'">
                      <a
                        class="link"
                        tabindex="0"
                        (click)="goToLink(link)"
                        (keydown.enter)="goToLink(link)"
                        target="_blank"
                        >{{ link.displayUrlText }}</a
                      >
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <div class="app-pt4"></div>
            </ng-template>
          </app-tab>

          <app-tab
            *ngIf="videos?.count"
            class="app-pb2"
            [label]="
              videos?.description ? videos?.description : 'Helpful Videos'
            "
            featureName="HelpfulVideos"
            [template]="videoTemplate"
          >
            <ng-template #videoTemplate>
              <ng-container *ngIf="videos?.items">
                <div *ngFor="let lob of videos?.items" class="app-pt1">
                  <h2
                    class="app-header-2 app-pt3"
                    [ngClass]="lob?.items.length > 0 ? 'tw-block' : 'tw-hidden'"
                  >
                    {{
                      lob?.name === 'CS'
                        ? ('Client Specific' | sentenceCase)
                        : (lob?.description | sentenceCase)
                    }}
                  </h2>

                  <div *ngFor="let link of lob?.items">
                    <ng-container *ngIf="link?.url && link?.url !== '#'">
                      <div class=" app-pt2 app-pl2">
                        <a
                          class="link"
                          role="button"
                          tabindex="0"
                          [attr.aria-label]="
                            'Expand video ' + link?.displayUrlText
                          "
                          [attr.aria-expanded]="
                            currentVideoId === link?.id ? 'true' : 'false'
                          "
                          [routerLink]=""
                          (click)="playVideo(link)"
                          (keydown)="playVideoCloseTabOut($event, link)"
                          >{{ link?.displayUrlText }}</a
                        >
                      </div>
                    </ng-container>
                    <ng-template #plainVideoText>
                      <div class="app-pt2 app-pl2" *ngIf="!lob?.description">
                        <span class="app-header-h4">{{
                          link?.displayUrlText
                        }}</span>
                      </div>
                    </ng-template>

                    <iframe
                      *ngIf="currentVideoId === link?.id"
                      [src]="link?.url | safeUrl"
                      allowfullscreen="true"
                      height="338"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      frameBorder="0"
                      class="player app-pb3"
                    ></iframe>
                  </div>
                </div>
              </ng-container>
              <div class="app-pt4"></div>
            </ng-template>
          </app-tab>

          <app-tab
            *ngIf="documents?.count"
            class="app-pb2"
            [label]="'Helpful Documents'"
            featureName="DocsFaq"
            [template]="documentTemplate"
          >
            <ng-template #documentTemplate>
              <ng-container *ngIf="showDocument">
                <div class="app-pt2 app-pb2">
                  <a
                    [routerLink]=""
                    (click)="showDocument = false"
                    (keydown.enter)="showDocument = false"
                  >
                    Helpful Documents
                  </a>
                  > View Document
                </div>

                <div class="app-header-2 app-pt2 app-pb2">
                  {{ currentDocument?.description }}
                </div>

                <ng-container
                  #pdfContainer
                  create
                  (created)="pdfContainerCreated($event)"
                >
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!showDocument">
                <ng-container *ngIf="documents?.items">
                  <div *ngFor="let lob of documents?.items" class="app-pt1">
                    <h2
                      class="app-header-2 app-pt3"
                      [ngClass]="
                        lob?.items.length > 0 ? 'tw-block' : 'tw-hidden'
                      "
                    >
                      {{
                        lob?.name === 'CS'
                          ? ('Client Specific' | sentenceCase)
                          : (lob?.description | sentenceCase)
                      }}
                    </h2>
                    <div
                      *ngFor="let link of lob?.items; let i = index"
                      class="app-pt2 app-pl2"
                    >
                      <ng-container *ngIf="link?.url && link?.url !== '#'">
                        <a
                          class="link"
                          tabindex="0"
                          (click)="downloadFile(link)"
                          (keydown.enter)="downloadFile(link)"
                          [routerLink]=""
                          target="_blank"
                          >{{ link?.displayUrlText }}</a
                        >
                      </ng-container>
                    </div>
                  </div>
                  <div *ngFor="let lob of faqs?.items" class="app-pt1">
                    <h2
                      class="app-header-2 app-pt3"
                      [ngClass]="
                        lob?.items.length > 0 ? 'tw-block' : 'tw-hidden'
                      "
                    >
                      {{
                        lob?.name === 'CS'
                          ? ('Client Specific' | sentenceCase)
                          : (lob?.description | sentenceCase)
                      }}
                    </h2>
                    <div
                      *ngFor="let link of lob?.items; let i = index"
                      class="app-pt2 app-pl2"
                    >
                      <ng-container *ngIf="link?.url && link?.url !== '#'">
                        <a
                          tabindex="0"
                          class="link"
                          (click)="downloadFile(link)"
                          (keydown.enter)="downloadFile(link)"
                          [routerLink]=""
                          target="_blank"
                          >{{ link?.displayUrlText }}</a
                        >
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div class="app-pt4"></div>
            </ng-template>
          </app-tab>
        </app-tab-group>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      .link {
        font-size: 16px;
        color: #007dbc !important;
      }
      .link:hover {
        color: #007dbc !important;
        text-decoration: solid underline #035e81 2px !important;
        cursor: pointer;
      }

      .player {
        width: 100%;
        height: 338px;
        max-width: 600px;
      }
      .panel-header {
        font-size: 16px;
        color: var(--color-sedgwick-blue) !important;
        font-weight: bold;
        text-transform: uppercase;
      }

      .badge-header {
        margin-top: 12px;
        padding-top: 12px;
        margin-bottom: 18px;
        padding-bottom: 0px;
      }

      .bottom-border {
        border-bottom: 2px solid var(--color-light-grey);
      }

      ::ng-deep .accordion .mat-expansion-panel {
      }

      ::ng-deep .accordion .mat-expansion-panel-header {
        padding: 0 8px 0 8px;
        margin: 0;
      }
      ::ng-deep .accordion .mat-expansion-panel-body {
        padding: 8px;
        margin: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      ::ng-deep .mat-tab-label {
        color: #007dbc !important;
        font-weight: bold;
        text-transform: uppercase;
      }
      .tab-label {
        color: #007dbc !important;
        font-weight: bold;
        text-transform: uppercase;
      }
      .app-header-h3 {
        font-size: 18px !important;
        color: #171c20 !important;
        font-weight: bold !important;
      }
      .app-header-h4 {
        font-size: 16px !important;
        color: #171c20 !important;
      }
    `,
  ],
})
export class HelpPageComponent implements OnInit {
  help: HelpItem[] = [];
  focus: string;
  links: HelpItem;
  allVideos: HelpItem;
  videos: HelpItem;
  filteredVideos: HelpItem;
  documents: HelpItem;
  faqs: HelpItem;
  document: HelpItem;
  selectedIndex: number;
  showDocument = false;
  currentDocument: HelpItem;
  currentVideoId: string;
  claimId: string = '';
  source: string = '';
  lcType: string;
  claimDesc: string = '';
  previousUrlLabel: string = 'Home';
  loaded: boolean = false;
  lob: string;
  showLearningCenter: boolean;
  isMgr: boolean;
  items: any[] = [];
  claims: any[] = [];
  mgrAction: UserActions | null;

  @ViewChild('pdfContainer', { read: ViewContainerRef })
  private pdfContainer: ViewContainerRef;

  constructor(
    public helpfulLinkService: HelpfulLinkService,
    private route: ActivatedRoute,
    public session: Session,
    private utility: UtilityService,
    public config: ConfigurationService,
    public logService: LogService,
    private userRole: UserRoleService,
    private userAction: UserActionService,
    private claimService: ClaimService
  ) {}

  async ngOnInit() {
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
    this.lob = this.route.snapshot.queryParamMap.get('lob');
    const showLearningFlag = 'showLearningCenter';
    if (!sessionStorage.getItem(showLearningFlag)) {
      this.showLearningCenter =
        this.config.getConfigFeatureAccess('learningcenter');
      sessionStorage.setItem(
        showLearningFlag,
        this.showLearningCenter.toString()
      );
    } else {
      this.showLearningCenter =
        sessionStorage.getItem(showLearningFlag) === 'true';
    }
    !this.session.user ? await this.session.loadUser() : null;
    this.isMgr = isManagement(this.session.user.role.toUpperCase());
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    const lobHierarchy = ['DS', 'WC', 'LV', 'AD', 'GL', 'PR', 'AU', 'PL'];
    this.loaded = false;

    await this.loadHelpfulLinks();
    //this.help = (await this.session.GetHelpItems()) as HelpItem[];
    this.links = this.help.find((item) => item.name === 'links');

    this.allVideos = this.help.find((item) => item.name === 'videos');

    if (this.lob && this.allVideos && this.allVideos.items) {
      this.videos = { ...this.allVideos };
      this.videos.items = this.videos.items.filter(
        (item) => item.name == this.lob
      );
    } else {
      this.videos = this.allVideos;
    }

    this.documents = this.help.find(
      (item) => item.name === 'documents' || item.name === 'faqs'
    );

    this.faqs = this.help.find((item) => item.name === 'faqs');
    this.document = this.help.find((item) => item.name === 'documents');
    if (this.documents) {
      let count1 = 0;
      let count2 = 0;
      if (this.faqs) {
        count1 = this.faqs?.count;
      }
      if (this.document) {
        count2 = this.document?.count;
      }
      this.documents.count = count1 + count2;
    }

    await this.setFocus();
    this.loaded = true;
  }

  loadDocument(document: HelpItem) {
    this.currentDocument = document;
    this.showDocument = true;
  }

  async pdfContainerCreated(el: ElementRef) {
    /*     const { PdfViewerComponent } = await import(
     // '../document/pdf-viewer.component'
    );
    const viewer = this.pdfContainer.createComponent(PdfViewerComponent);
    viewer.instance.src = this.currentDocument.url; */
  }

  async downloadFile(doc: HelpItem) {
    let role = 'HRM';
    if (this.userRole.manager && this.mgrAction == UserActions.ViewOwnEmpDb) {
      role = 'HRM';
    } else {
      role = this.session.user.role;
    }
    let extension = '';
    let blob = null;
    this.logService.LogFeature(undefined, 'FAQ', 'FAQ', doc.id);
    this.session
      .downloadDocument(doc.id, role)
      .then((resp: HttpResponse<Blob>) => {
        extension = resp.headers.get('content-disposition');
        blob = resp.body;
        const ext = this.getExtension(extension);
        const type = this.utility.createFileType(ext);
        const newBlob = new Blob([blob], { type: type });
        this.utility.downloadFile(newBlob, doc.displayUrlText);
      });
  }

  goToLink(link: any) {
    if (link.displayUrlText.toLowerCase() === 'medline plus') {
      this.logService.LogFeature(
        undefined,
        'Medline Plus',
        'Helpful Links',
        link.id
      );
    } else {
      this.logService.LogFeature(
        undefined,
        'Helpful Link',
        'Helpful Link',
        link.id
      );
    }
    window.open(link.url, '_blank');
  }

  playVideo(link: any) {
    this.currentVideoId = link?.id;
    this.logService.LogFeature(
      undefined,
      'Helpful Video',
      'Helpful Video',
      link.id
    );
  }

  playVideoCloseTabOut(event: any, link: any) {
    if (event.keyCode == 13) {
      this.currentVideoId = link?.id;
      this.logService.LogFeature(
        undefined,
        'Helpful Video',
        'Helpful Video',
        link.id
      );
    }
  }
  resetVideo() {
    this.currentVideoId ? (this.currentVideoId = null) : null;
  }

  private async setFocus() {
    this.focus = this.route.snapshot.queryParamMap.get('focus');
    const hasLC = await this.config.getConfigFeatureAccess('learningcenter');
    if (hasLC) {
      if (this.focus === 'learningcenter') {
        this.selectedIndex = 0;
      } else if (this.focus === 'links') {
        this.selectedIndex = 1;
      } else if (this.focus === 'videos') {
        this.selectedIndex = 2;
      } else if (this.focus === 'documents') {
        this.selectedIndex = 3;
      } else {
        this.selectedIndex = 0;
      }
    } else {
      if (this.focus === 'links') {
        this.selectedIndex = 0;
      } else if (this.focus === 'videos') {
        this.selectedIndex = 1;
      } else if (this.focus === 'documents') {
        this.selectedIndex = 2;
      } else {
        this.selectedIndex = 0;
      }
    }
  }

  private getExtension(type: string): string {
    // This might need to change if somehow filename is not the [1] element in header
    const file = type
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim()
      .replace(/\"/g, '');
    return file.split('.').pop();
  }

  private async loadHelpfulLinks() {
    //this.loading = true;
    this.lcType = this.getLcType(this.lob);
    //this.isMgr = isManagement(this.session.user.role);
    this.help = [];

    // this.learningCenter = await this.learningCenterService.getLearningCenter(
    //   this.source,
    //   this.claimId,
    //   this.lob,
    //   this.lcType
    // );

    this.help = (await this.helpfulLinkService.getHelpfulLinks(
      this.lcType
    )) as HelpItem[];
    // this.learningCenter.claimInfo = claimObject;
    // this.loading = false;
  }
  getLcType(lob: string) {
    const lobTypes = ['WC', 'AU', 'GL', 'PR', 'PL']; // these have CLAIMANT and CONTROLLER type in setup app
    const mgrAction = this.userAction.getAction();
    if (lobTypes.includes(lob)) {
      if (
        (this.isMgr &&
          (mgrAction == UserActions.ViewOwnMgrDb ||
            mgrAction == UserActions.MgrViewEmpClaim ||
            mgrAction == UserActions.MgrViewEmpDb)) ||
        this.userRole.controller
      ) {
        return 'CONTROLLER';
      } else {
        return 'HRM';
      }
    } else {
      if (
        (this.isMgr &&
          (mgrAction == UserActions.ViewOwnMgrDb ||
            mgrAction == UserActions.MgrViewEmpClaim ||
            mgrAction == UserActions.MgrViewEmpDb ||
            mgrAction == UserActions.ManagerSummaryView)) ||
        this.userRole.controller
      ) {
        return this.session.user.role.toUpperCase();
      } else {
        return 'HRM';
      }
    }
  }
}

export class HelpItem {
  id?: string;
  name: string;
  description: string;
  items?: HelpItem[] = [];
  count?: number = 0;
  url?: string;
  type?: 'link' | 'video' | 'document';
  local?: boolean = false;
  activationDate?: Date;
  displayUrlText: string;
}

export interface DocumentOutput {
  document_UID: number;
  document_Filename: string;
  document_File: string;
  document_Filedate: Date;
}
