import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, RouterService, SearchResult } from 'src/app/shared';
import { ActivityService } from '../../services/activity.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-workstatus',
  template: `
    <h3 class="H3header">Work Status</h3>
    <app-list
      #table
      [dataSource]="workstatusinfo"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="false"
      loadingMessage=""
      noDataIcon="work_outline"
      noDataMessage="<br><strong>No work status details available</strong>"
      [tableAriaLabel]="'Work status'"
      [loaded]="isLoaded"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="workstatus"
            label="Work Status"
            class="tw-w-full md:tw-w-[40%]"
            [names]="['worsStatus']"
          ></app-list-header>

          <app-list-header
            name="benefitBegin"
            label="Begin"
            class="tw-w-full md:tw-w-[30%]"
            [names]="['benefitBegin']"
          ></app-list-header>

          <app-list-header
            name="benefitEnd"
            label="End"
            class="tw-w-full md:tw-w-[30%]"
            [names]="['benefitEnd']"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[40%]">
          <app-value
            label="Work Status"
            [value]="element.workstatus"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[30%]">
          <app-value
            label="Begin"
            [value]="element.benefitBegin | format : 'date'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[30%]">
          <app-value
            label="End"
            [value]="element.benefitEnd | format : 'date'"
            layout="row-xs"
          >
          </app-value>
        </td></tr
    ></ng-template>
  `,
  styles: [
    `
      .H3header {
        font-size: 24px;
        font-weight: bold;
      }
    `,
  ],
})
export class WorkStatusComponent extends BaseComponent implements OnInit {
  claimId: any;
  @Input()
  claim: SearchResult;
  source: any;
  isLoaded: boolean = false;

  maxBenifitDuration: any;
  workstatusinfo: WorkStatus[];
  data: any;
  userLOB: any;
  workstatusdetails: WorkStatus[];
  isToggle: boolean = false;
  hasBenefits: boolean = false;
  isWFA: boolean =
    this.session.user.role.toUpperCase() === 'HRM' &&
    this.session.user.authenticationType === 'HRM'
      ? true
      : false;

  constructor(
    private route: ActivatedRoute,
    private activityService: ActivityService,
    public routerService: RouterService,
    public session: Session,
    public claimConfigService: ConfigurationService
  ) {
    super();
  }
  async ngOnInit() {
    this.subs.sink = this.route.queryParams.subscribe(() =>
      this.loadClaimFromUrl()
    );
    this.getWorkStatusInfo();
  }
  private async loadClaimFromUrl() {
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
  }

  async getWorkStatusInfo() {
    const data = await this.activityService.getWorkStatusInfo(
      this.source,
      this.claimId,
      this.claim.lob
    );
    this.workstatusinfo = data;
    this.isLoaded = true;
  }
}

class WorkStatus {
  workStatus?: string;
  duration?: string;
  benefitBegin?: Date;
  benefitEnd?: Date;
  type?: string;
  benefitStatus?: string;
  workStatusNumber?: number;
  effectiveDate?: Date;
}
