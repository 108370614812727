import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimelineStatus } from 'src/app/shared';
import { TimelineDetail } from './timeline-detail';
import { TimelineComponent } from './timeline.component';

@Component({
  selector: 'app-timeline-item',
  template: ``,
  styles: [],
})
export class TimelineItemComponent implements OnInit, OnDestroy {
  @Input()
  label: string;

  @Input()
  description: string;

  @Input()
  description1: string;

  @Input()
  description2: string;

  @Input()
  status: TimelineStatus;

  @Input()
  details: TimelineDetail[];

  @Input()
  dateList : any[];

  @Input()
  date :Date;

  constructor(private timeline: TimelineComponent) {
    this.timeline.addItem(this);
  }
  ngOnDestroy(): void {
    this.timeline.removeItem(this);
  }

  ngOnInit(): void {}
}
