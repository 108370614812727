/**
 * Verify if value is null or empty array.
 * @param value
 * @returns boolean
 */
export function isEmpty(value: any): boolean {
    // Handle null
    if (!value){
        return true;
    }

    // Handle Array
    if (Array.isArray(value)){
        return value.length === 0;
    }

    return false;
}