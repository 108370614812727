import { Component, Input } from '@angular/core';
import { SnackbarService } from 'src/app/shared';

@Component({
  selector: 'app-watch-list-button',
  template: `
    <mat-icon
      class="app-color-sedgwick-blue"
      [ngClass]="{
        'icon-watch': !large,
        'icon-watch-large': large
      }"
      *ngIf="!watching"
      title="Add to watch list"
      >star_border</mat-icon
    >
    <mat-icon
      class="-tw-text-yellow-400 app-color-accent"
      [ngClass]="{
        'icon-watch': !large,
        'icon-watch-large': large
      }"
      title="Remove from watch list"
      *ngIf="watching"
      >star</mat-icon
    >
  `,
  styles: [
    `
      .icon-watch {
        cursor: pointer;
        font-size: 20px;
        width: 20px;
        height: 20px;
      }

      .icon-watch-large {
        cursor: pointer;
        font-size: 32px;
        width: 32px;
        height: 32px;
      }
    `,
  ],
})
export class WatchListButtonComponent {
  @Input()
  large = false;

  @Input()
  watching : boolean;

  constructor(private snackbar: SnackbarService) {}

  watch() {
    this.watching = true;
    this.snackbar.show('Claim added to watch list');
  }

  removeWatch() {
    this.watching = false;
    this.snackbar.show('Claim removed from watch list');
  }
}
