import { Component, OnInit } from '@angular/core';
import { DisplayService, OmniApi, OmniDashboard } from 'src/app/my-sedgwick';
import { BaseComponent } from 'src/app/shared';

@Component({
  selector: 'app-card-dashboard',
  template: `
    <div class="tw-w-full app-pt2 app-pb2">
      <div class="tw-w-full tw-flex tw-flex-wrap">
        <app-new-claims-card
          class="tw-w-full md:tw-w-[33%]"
          [omniDashboard]="omniDashboard"
          [error]="error"
        />
        <app-return-to-work-card
          class="tw-w-full md:tw-w-[33%]"
          [omniDashboard]="omniDashboard"
          [error]="error"
        />
        <app-off-work-card
          class="tw-w-full md:tw-w-[34%]"
          [omniDashboard]="omniDashboard"
          [error]="error"
        >
        </app-off-work-card>
        <div class="tw-w-full tw-flex tw-flex-wrap">
          @if(displayService.accommodationsCard) {
          <app-pending-accommodations-card
            class="tw-w-full md:tw-w-[33%]"
            [omniDashboard]="omniDashboard"
            [error]="error"
          />
          } @else {
          <app-report-claim-card class="tw-w-full md:tw-w-[33%]" />
          }

          <app-notifications-card
            class="tw-w-full md:tw-w-[33%]"
            [omniDashboard]="omniDashboard"
            [error]="error"
          />
          <app-open-claims-card
            class="tw-w-full md:tw-w-[34%]"
            [omniDashboard]="omniDashboard"
            [error]="error"
          />
        </div>
        <div class="tw-w-full md:tw-w-[33%] tw-flex-col tw-flex-wrap">
          @if(displayService.accommodationsCard) {
          <app-report-claim-card class="tw-w-full" />
          }
        </div>
        <div class="tw-w-full md:tw-w-[67%] tw-flex-col tw-flex-wrap">
          <app-comm-center2-card />
          <app-visit-learning-center
            class="tw-w-full containers-min-height"
            [originator]="'summaryDashboard'"
          />
        </div>
      </div>
    </div>
  `,
  styles: [``],
})
export class CardDashboardComponent extends BaseComponent implements OnInit {
  omniDashboard: OmniDashboard = null;

  error: any;

  constructor(private omniApi: OmniApi, public displayService: DisplayService) {
    super();
  }

  async ngOnInit() {
    try {
      this.omniDashboard = await this.omniApi.getOmniDashboard();
    } catch (error) {
      this.error = error;
    }
  }
}
