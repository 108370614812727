import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Claim, MyClaim, SearchResult } from 'src/app/shared';
import { ClaimInfo } from '../models/search-result';

@Injectable({
  providedIn: 'root',
})
export class ClaimDataService {
  private claimSource = new BehaviorSubject<SearchResult | null>(null);
  currentClaim$ = this.claimSource.asObservable();
  private myClaimsSource = new BehaviorSubject<MyClaim[] | null>(null);
  currentMyClaims$ = this.myClaimsSource.asObservable();
  private claimInfoSource = new BehaviorSubject<ClaimInfo | null>(null);
  currentClaimInfo$ = this.claimInfoSource.asObservable();

  private myClaimSource = new BehaviorSubject<MyClaim | null>(null);
  currentMyClaim$ = this.myClaimSource.asObservable();

  constructor() {}

  setClaim(claim: SearchResult): void {
    this.claimSource.next(claim);
  }

  setClaims(claims: MyClaim[]): void {
    this.myClaimsSource.next(claims);
  }

  setClaimInfo(currentClaimInfo: ClaimInfo): void {
    this.claimInfoSource.next(currentClaimInfo);
  }

  setMyClaim(currentMyClaim: MyClaim): void {
    this.myClaimSource.next(currentMyClaim);
  }
}
