import { Injectable, signal } from '@angular/core';
import { SearchResult } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { Http } from 'src/app/shared/services/http.service';
import { ConfigurationService } from '..';
import {
  SaveMessageActionRequest,
  SaveMessageResponse,
  SendMessage,
} from '../models/Communications';
import { ConversationSummary } from '../models/ConversasationSummary';
import { ClaimService } from '../services/claim.service';
import { Session } from './session.service';
import { isThisISOWeek } from 'date-fns';
import { UserActionService } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class CommCenterService {
  unreadMessageCount = signal<number>(null);

  conversations: ConversationSummary[] = [];

  myConversations: ConversationSummary[] = [];

  managerConversations: ConversationSummary[] = [];

  loaded: boolean = false;

  constructor(
    private http: Http,
    private session: Session,
    private claimService: ClaimService,
    private config: ConfigurationService,
    private userAction: UserActionService
  ) {
    this.session.isEmulating$.subscribe((isEmulating) => {
      if (isEmulating) {
        this.clearConversations();
      }
    });
    this.session.isDelegating$.subscribe((isDelegating) => {
      if (isDelegating) {
        this.clearConversations();
      }
    });
  }

  /**
   * Gets all converations for user's claims
   * @returns
   */
  async getMyConversations(): Promise<ConversationSummary[]> {
    await this.getConversations();
    return this.myConversations.sort((c) => c.date?.getDate());
  }

  /**
   * Gets all conversations that are not related to user's claims
   * @returns
   */
  async getManagerConversations(): Promise<ConversationSummary[]> {
    await this.getConversations();
    return this.managerConversations.sort((c) => c.date?.getDate());
  }

  async getClaimConversation(
    source: string,
    claimId: string
  ): Promise<ConversationSummary> {
    const conversations = await this.getConversations();
    return conversations.find(
      (x) => x.source === source && x.claimId === claimId
    );
  }

  /**
   * Gets a conversation for a claim
   * @param claim
   * @returns
   */
  async createClaimConversation(
    claim: SearchResult
  ): Promise<ConversationSummary> {
    await this.getConversations();
    /*
    if (!this.loaded) {
      await this.loadMessages();
    }
    */
    let summary: ConversationSummary;
    let primaryCompanionClaimSummary: SearchResult;
    const conversations = claim.userOwns
      ? this.myConversations
      : this.managerConversations;
    if (!conversations) {
      return summary;
    }

    // if claim is TAMS, lookup Juris claim for conversation summary
    if (claim.source === 'T' && claim.companionClaims?.length > 0) {
      let primaryCompanionClaim = claim.companionClaims.find(
        (cc) =>
          cc.recordSource === 'J' &&
          cc.statusCode != 'Closed' &&
          cc.statusCode != 'C' &&
          cc.status != 'Closed' &&
          conversations.find(
            (c) => c.claimId === cc.claimUid && c.source === cc.recordSource
          )
      );

      if (!primaryCompanionClaim) {
        // no juris companion claim with conversation, use first juris companion claim to track conversation
        primaryCompanionClaim = claim.companionClaims.find(
          (cc) => cc.recordSource === 'J' && cc.status != 'Closed'
        );

        // NEED TO ADD PRIMARY JURIS CONVERSATION
        if (primaryCompanionClaim) {
          const primaryCOmpanionClaimSummary =
            await this.claimService.getSummary(
              primaryCompanionClaim.recordSource,
              primaryCompanionClaim.claimUid
            );
          // need to create new conversation for this companion claim
          const newPrimaryConversation = Object.assign(
            new ConversationSummary(),
            primaryCOmpanionClaimSummary
          );
          newPrimaryConversation.details = [];
          //primaryConversation.date = primaryClaimConversation?.date;
          //primaryConversation.messageId = primaryClaimConversation?.messageId;
          //primaryConversation.sourceClaimKey = primaryCompanionClaimKey;

          if (claim.userOwns) {
            this.myConversations.push(newPrimaryConversation);
          } else {
            this.managerConversations.push(newPrimaryConversation);
          }
        }

        this.combineSortConversations();
      }

      if (primaryCompanionClaim) {
        primaryCompanionClaimSummary = await this.claimService.getSummary(
          primaryCompanionClaim.recordSource,
          primaryCompanionClaim.claimUid
        );

        let tamsConversation = conversations.find(
          (_) =>
            (_.claimId === claim.claimId && _.source === claim.source) ||
            (_.claim?.claimId === claim.claimId &&
              _.claim?.source === claim.source)
        );

        let primaryClaimConversation = conversations.find(
          (_) =>
            (_.claimId === primaryCompanionClaimSummary.claimId &&
              _.source === primaryCompanionClaimSummary.source) ||
            (_.claim?.claimId === primaryCompanionClaimSummary.claimId &&
              _.claim?.source === primaryCompanionClaimSummary.source)
        );

        if (!tamsConversation) {
          // && !primaryClaimConversation) {

          // need to create new conversation for this companion claim
          tamsConversation = Object.assign(new ConversationSummary(), claim);
          tamsConversation.details = primaryClaimConversation?.details ?? [];
          tamsConversation.date = primaryClaimConversation?.date;
          tamsConversation.messageId = primaryClaimConversation?.messageId;
          tamsConversation.sourceClaimKey = primaryCompanionClaimSummary;

          if (claim.userOwns) {
            this.myConversations.push(tamsConversation);
          } else {
            this.managerConversations.push(tamsConversation);
          }

          this.combineSortConversations();
          //summary = tamsConversation;
          //this.conversations = this.myConversations.concat(this.managerConversations);
        }

        if (!primaryClaimConversation) {
          // can't find primary claim in conversations
          // NEED TO CREATE AND ADD CONVERSATION FOR PRIMARY CLAIM

          const newPrimaryConversation = Object.assign(
            new ConversationSummary(),
            primaryCompanionClaimSummary
          );
          //newPrimaryConversation.claimNumber = newPrimaryConversation.fileNumb;
          newPrimaryConversation.details = [];
          //primaryConversation.date = primaryClaimConversation?.date;
          //primaryConversation.messageId = primaryClaimConversation?.messageId;
          //primaryConversation.sourceClaimKey = primaryCompanionClaimKey;

          primaryClaimConversation = newPrimaryConversation;
          if (claim.userOwns) {
            this.myConversations.push(newPrimaryConversation);
          } else {
            this.managerConversations.push(newPrimaryConversation);
          }

          this.combineSortConversations();
        }

        if (tamsConversation && primaryClaimConversation) {
          // put date and details from primary into tams conversation summary
          tamsConversation.details = primaryClaimConversation.details;
          tamsConversation.date = primaryClaimConversation.date;
        }
        // set the return summary to the updated  tams conversation
        summary = tamsConversation;
      }
    } // END OF TAMS CLAIM WITH COMPANION CLAIM INFO
    if (!summary) {
      const conversationKey = primaryCompanionClaimSummary ?? claim;
      summary = conversations.find(
        (_) =>
          (_.claimId === conversationKey.claimId &&
            _.source === conversationKey.source) ||
          (_.claim?.claimId === conversationKey.claimId &&
            _.claim?.source === conversationKey.source)
      );

      if (!summary) {
        summary = new ConversationSummary();
        summary = Object.assign(summary, claim);
        summary.details = [];
        summary.sourceClaimKey = primaryCompanionClaimSummary;

        let primaryConvSummary: ConversationSummary = null;
        if (primaryCompanionClaimSummary) {
          primaryConvSummary = Object.assign(
            new ConversationSummary(),
            primaryCompanionClaimSummary
          );
          primaryConvSummary.details = [];
        }
        if (claim.userOwns) {
          if (primaryConvSummary) {
            // need to add conversation for primary claim as well
            this.myConversations.push(primaryConvSummary);
          }
          this.myConversations.push(summary);
        } else {
          if (primaryConvSummary) {
            // need to add conversation for primary claim as well
            this.managerConversations.push(primaryConvSummary);
          }
          this.managerConversations.push(summary);
        }
        this.combineSortConversations();

        //this.conversations = this.myConversations.concat(this.managerConversations);

        summary.sourceClaimKey = primaryCompanionClaimSummary;
      }
    }

    return summary;
  }

  /**
   * Loads all conversations in background. Updates message count.
   */
  loadMessages() {
    this.getConversations();
  }

  combineSortConversations() {
    this.myConversations = this.myConversations.sort((c) =>
      new Date(c.date).getTime()
    );
    this.managerConversations = this.managerConversations.sort((c) =>
      new Date(c.date).getTime()
    );
    this.conversations = this.myConversations.concat(this.managerConversations);
  }

  /**
   * Clears currently cached conversations
   */
  clearConversations() {
    this.loaded = false;
    this.myConversations = [];
    this.managerConversations = [];
    this.conversations = [];
  }

  private async getConversations(): Promise<ConversationSummary[]> {
    if (this.userAction.getBustCache()) {
      this.clearConversations();
    }
    if (!this.loaded) {
      let keepCache = !this.userAction.getBustCache();
      const myRequest = !this.session.user.roles.controller
        ? this.http.post<ConversationSummary[]>(
            '/api/manager/communication/get-conversations',
            {
              supervisorAction: UserActions.ViewOwnEmpDb,
              IncludeIndirects: false,
            },
            {
              cache: keepCache,
            }
          )
        : Promise.resolve([]);

      const managerRequest =
        this.session.user.roles.manager || this.session.user.roles.controller
          ? this.http.post<ConversationSummary[]>(
              '/api/manager/communication/get-conversations',
              {
                supervisorAction: UserActions.ViewOwnMgrDb,
                IncludeIndirects: true,
              },
              {
                cache: keepCache,
              }
            )
          : Promise.resolve([]);

      [this.myConversations, this.managerConversations] = await Promise.all([
        myRequest,
        managerRequest,
      ]);

      this.myConversations = this.myConversations.filter(
        (_) =>
          _.status.toLowerCase().indexOf('close') === -1 ||
          _.details?.length > 0
      );
      // filter users own conversations from managerconversations
      this.managerConversations = this.managerConversations.filter(
        (_) =>
          _.empUnum !== this.session.user.empUnum || !this.session.user.empUnum
      );
      this.userAction.setBustCache(false);
    }

    this.combineSortConversations();
    //this.conversations = this.myConversations.concat(this.managerConversations);
    for (const conversation of this.conversations) {
      if (conversation.date) {
        conversation.date = new Date(conversation.date);
      }

      for (const detail of conversation?.details || []) {
        if (detail.date) {
          detail.date = new Date(detail.date);
        }
      }
    }

    this.updateUnreadCount();

    this.loaded = true;
    return this.conversations;
  }

  async saveMessage(
    source: string,
    claimId: string,
    lob: string,
    sendMessageRequest: SendMessage
  ): Promise<any> {
    let localLob = lob ?? 'NA';
    if (localLob === '') {
      localLob = 'NA';
    }
    sendMessageRequest.messageId = sendMessageRequest.messageId ?? '';

    const path = `/api/manager/claimcommunication/${source}/${claimId}/${localLob}`;
    this.clearCache(source, claimId, localLob);
    return this.http.post<SaveMessageResponse>(
      path + '/send-message',
      sendMessageRequest
    );
  }

  async readMessage(
    source: string,
    claimId: string,
    lob: string,
    saveMessageActionRequest: SaveMessageActionRequest
  ) {
    let localLob = lob ?? 'NA';
    if (localLob === '') {
      localLob = 'NA';
    }

    const isEmulating =
      this.session.user &&
      this.session.user?.emulatorContext &&
      this.session.user?.emulatorContext?.isEmulating;

    if (!isEmulating) {
      const path = `/api/manager/claimcommunication/${source}/${claimId}/${localLob}`;
      await this.http.post<number>(
        path + '/read-message',
        saveMessageActionRequest
      );
      this.clearCache(source, claimId, localLob);
    }
  }

  private clearCache(source: string, claimId: string, lob: string) {
    // delete both the claim level conversation and the manager level conversation.
    // The data could be stale for both of these entries.
    this.http.deleteCacheEntry('/api/manager/communication/get-conversations');
    this.http.deleteCacheEntry(
      `/api/manager/claimcommunication/${source}/${claimId}/${lob}/get-conversations`
    );
  }

  conversationExists(claimId: string, source: string): boolean {
    return this.conversations.some(
      (m) => m.claimId == claimId && m.source == source
    );
  }

  conversationDetailsExist(claimId: string, source: string): boolean {
    return this.conversations.some(
      (m) => m.claimId == claimId && m.source == source && m.details.length > 0
    );
  }

  updateUnreadCount() {
    let unreadCount: number = 0;
    for (const conversation of this.conversations) {
      for (const detail of conversation.details || []) {
        if (detail.unread) {
          unreadCount++;
        }
      }
    }

    this.unreadMessageCount.set(unreadCount > 0 ? unreadCount : 0);
  }
}
