import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  template: ` <div
    class="progress-container tw-flex tw-flex-col tw-justify-items-center tw-items-center"
  >
    <ng-container *ngIf="type === 'Bar'">
      <div>
        <h3 class="progress-bar-message" color="accent">{{ message }}</h3>
      </div>
      <mat-progress-bar
        class="progress-bar"
        mode="indeterminate"
        color="accent"
      ></mat-progress-bar>
    </ng-container>
    <ng-container *ngIf="type === 'BarDeteriminate'">
      <div class="progress-bar-message">{{ message }}</div>
      <mat-progress-bar
        class="progress-bar"
        mode="determinate"
        [value]="value"
        color="accent"
      ></mat-progress-bar>
    </ng-container>
    <ng-container *ngIf="type === 'Spinner'">
      <mat-spinner
        color="accent"
        [diameter]="30"
        [mode]="determinate ? 'determinate' : 'indeterminate'"
      ></mat-spinner>
      <div class="spinner-message">{{ message }}</div>
    </ng-container>
  </div>`,
  styles: [
    `
           .progress-container {
             padding: 16px;
           }

           .progress-bar {
             max-width: 400px;
             margin-bottom: 24px;
           }

           .spinner-message {
             font-weight: 500;
             font-size: 12px;
           }
         `,
  ],
})
export class ProgressComponent implements OnInit {
  @Input()
  message: string;

  @Input()
  value: number;

  @Input()
  determinate: boolean;

  @Input()
  type: ProgressType = 'Spinner';

  constructor() {}

  ngOnInit(): void {}
}

export type ProgressType =
  | 'Spinner'
  | 'Bar'
  | 'BarDeteriminate'
  | 'OverlaySpinner';
