import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Session } from 'src/app/my-sedgwick';
import { UserRoleService } from '../../services/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class SupervisorGuard {
  constructor(
    private userRole: UserRoleService,
    private router: Router,
    private session: Session
  ) {}

  async canActivate() {
    await this.session.loadUser();
    if (this.userRole.manager) {
      return true;
    } else {
      this.router.navigate(['/unauthorized']);
      return false;
    }
  }
}
