import { Injectable } from "@angular/core";
import { Random } from "src/app/shared";
import { Http } from "src/app/shared/services/http.service";
import { ConversationSummary } from "../models/ConversasationSummary";
import { StringChain } from "lodash";
import { SaveMessageActionRequest, SaveMessageResponse, SendMessage } from "../models/Communications";
import { MileageDetail  } from "../models/Mileages";
import { SubmitMileageRequest, SubmitMileageResponse } from "../models/mileage-request";

@Injectable({
  providedIn: 'root'
})

export class MileageService {
  constructor(private http: Http, public random: Random) { }

  async getMileageData(source: string, claimId: string, lob: string): Promise<MileageDetail>{
    let rnd = this.random.getNumber(1000);

    let path = '/api/claim/mileages';

    if(claimId !=null)
        path = `/api/claim/mileages/${source}/${claimId}/${lob}`;

    return await this.http.get<MileageDetail>(path + '?' + rnd, { cache: false, timeout: 0 });
  }

  async verifyCityStateZip(input: any) {
    /*
    {
      City: input.City,
      State: input.State,
      Zip: input.Zip,
    }
    */
    var path = '/api/claim/verifycitystatezip';
    return await this.http.post(path, input);
    var response = await this.http.post(path, input).then((r) => {
      //debugger;
      return r;
    });
  }

  async saveMessage(source: string, claimId: string, lob: String, sendMessageRequest: SendMessage): Promise<any>{
    let path = `/api/manager/claimcommunication/${source}/${claimId}/${lob}`;

    return this.http.post<SaveMessageResponse>(path + '/send-message', sendMessageRequest);
  }

  async readMessage(source: string, claimId: string, lob: string, saveMessageActionRequest: SaveMessageActionRequest){

    let path = `/api/manager/claimcommunication/${source}/${claimId}/${lob}`;

    await  this.http.post<number>(
        path + '/read-message',
        saveMessageActionRequest
      )
  }

  submitMileage = async (submitMileageRequest: SubmitMileageRequest) => {
    let path = `/api/mileagereimbursement/SubmitMileage`;
    return await this.http.post<SubmitMileageResponse>(path, submitMileageRequest);
  }
}
