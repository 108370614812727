import { Component, OnInit } from '@angular/core';
import { SearchResult } from 'src/app/shared';
import {
  CommCenter2ClaimsComponent,
  CommCenter2MessagesComponent,
  CommCenterService,
} from '../..';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';
import { ConversationSummary } from '../../models/ConversasationSummary';
@Component({
  selector: 'app-comm-center2-page',
  template: ` <div>
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb label=" Communication center" />
      </div>
      <app-panel
        title="Communication center"
        class="tw-w-full tw-min-h-[1000px]"
        [largeHeader]="true"
        [canExpand]="false"
      >
        <app-tab-group
          [hideSingleTab]="true"
          [tabGroupId]="'commCenterTabGroup'"
        >
          <app-tab
            label="my claims"
            *ngIf="showMyClaimsTab"
            [template]="myClaims"
          >
            <ng-template #myClaims>
              @if(hasMyClaims) {
              <div class="tw-pt-2"></div>
              <app-comm-center2-claims
                mode="MyClaims"
                (conversationSelected)="
                  myClaimsMessages.loadConversationMessages($event)
                "
                #myCommCenterClaimsComp
              />
              <h2 class="tw-w-full app-header-2 tw-pb-2 tw-pt-6">
                Conversation
              </h2>
              <app-comm-center2-messages
                #myClaimsMessages
                (conversationUpdated)="
                  myCommCenterClaimsComp.loadConversations()
                "
              />

              } @else {
              <div class="tw-w-full tw-flex tw-justify-center tw-pt-12">
                <app-empty-state-label
                  icon="list_alt"
                  header="No claims"
                ></app-empty-state-label>
              </div>
              }
            </ng-template>
          </app-tab>
          <app-tab
            [label]="employeeTabLabel"
            *ngIf="showEmployeeClaimsTab"
            [template]="employee"
          >
            <ng-template #employee>
              <div class="tw-pt-4"></div>

              <div [hidden]="searching">
                <div class="tw-pt-2"></div>
                <app-action-row>
                  <app-button
                    left1
                    autofocus
                    (click)="searching = true"
                    emphasis="High"
                    >Select claim</app-button
                  >
                </app-action-row>
                <app-comm-center2-claims
                  mode="Manager"
                  (conversationSelected)="
                    employeeClaimsMessages.loadConversationMessages($event)
                  "
                  #managerClaims
                />
                <h2 class="tw-w-full app-header-2 tw-pb-2 tw-pt-6">
                  Conversation
                </h2>
                <app-comm-center2-messages
                  #employeeClaimsMessages
                  (conversationUpdated)="managerClaims.loadConversations()"
                />
              </div>

              <div [hidden]="!searching">
                <app-action-row>
                  <a
                    role="button"
                    tabindex="0"
                    left1
                    (click)="searching = false"
                    (keydown.enter)="searching = false"
                    >Return to messages</a
                  >
                </app-action-row>
                <app-comm-center2-search
                  (selected)="
                    claimSelected($event, managerClaims, employeeClaimsMessages)
                  "
                />
              </div>
            </ng-template>
          </app-tab>
        </app-tab-group>

        <app-toast
          type="Info"
          *ngIf="session.user.leaveType === 'LeaveLink'"
          class="tw-flex tw-justify-center"
          >LeaveLink claims will not be displayed in the Communication Center.
        </app-toast>
      </app-panel>
    </app-section>
  </div>`,
  styles: ``,
})
export class CommCenter2PageComponent implements OnInit {
  searching = false;

  employeeTabLabel: string;

  showMyClaimsTab = false;

  showEmployeeClaimsTab = false;

  hasMyClaims = true;

  conversations: ConversationSummary[] = [];

  constructor(
    public session: Session,
    private alias: InpageAliasService,
    public commCenterService: CommCenterService
  ) {}

  async ngOnInit() {
    this.employeeTabLabel =
      (await this.alias.get('employeeSingular')) + ' claims';

    // this logic might need to be adjusted for auditor
    this.showMyClaimsTab =
      !this.session.user.roles.controller && !this.session.isDelegating();
    this.showEmployeeClaimsTab =
      this.session.user.roles.controller || this.session.user.roles.manager;
    // this.hasMyClaims =
    //   (await this.commCenterService.getMyConversations())?.length > 0;

    const messagelst = (this.conversations =
      await this.commCenterService.getMyConversations());
    let message1 = messagelst;
    if (message1) {
      //this.session.user?.emulatorContext?.isEmulating &&
      message1 = messagelst.filter(
        (x) =>
          x.status?.toUpperCase().indexOf('CLOSE') === -1 ||
          x.details.length > 0
      );
    }
    this.hasMyClaims = message1?.length > 0;
  }

  async claimSelected(
    result: SearchResult,
    managerClaims: CommCenter2ClaimsComponent,
    employeeClaimsMessages: CommCenter2MessagesComponent
  ) {
    this.searching = false;
    employeeClaimsMessages.loadingState = 'Loading';
    await managerClaims.loadConversation(result);
  }
}
