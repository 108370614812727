import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class UtilityService {
  
    constructor() { }
  
    isNull(value: any): boolean {
      return ( value === undefined || value === null)
    }
  
    isNullOrEmpty(value: any): boolean {
      return this.isNull(value) ? true : value.toString().length == 0;
    }

    downloadFile(blob: Blob, fileName: string){
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    createFileType(e: string): string {
      let fileType: string = "";
      if (e == 'pdf' || e == 'csv') {
        fileType = `application/${e}`;
      }
      else if (e == 'jpeg' || e == 'jpg' || e == 'png') {
        fileType = `image/${e}`;
      }
      else if (e == 'txt') {
        fileType = 'text/plain';
      }
  
      else if (e == 'ppt' || e == 'pot' || e == 'pps' || e == 'ppa') {
        fileType = 'application/vnd.ms-powerpoint';
      }
      else if (e == 'pptx') {
        fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      }
      else if (e == 'doc' || e == 'dot') {
        fileType = 'application/msword';
      }
      else if (e == 'docx') {
        fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      else if (e == 'xls' || e == 'xlt' || e == 'xla') {
        fileType = 'application/vnd.ms-excel';
      }
      else if (e == 'xlsx') {
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
  
      return fileType;
    }
  }