import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  Directive,
} from '@angular/core';
import { BaseComponent } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { WelcomeMessage } from '../../models/WelcomeMessageText';
import { Session } from '../../services/session.service';
import { OutboundSsoService } from '../../services/outbound-sso.service';
import { OutboundSSO } from 'src/app/shared/models/user';
import { DisplayService } from '../../services/display-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-welcome',
  template: `
    <app-panel [canExpand]="false">
      <app-loading-panel
        [state]="!welcomeMessageInfoLoaded && false ? 'Loading' : 'Loaded'"
      >
        <ng-template #content>
          <div class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <!-- <span><strong>Welcome to mySedgwick.</strong></span><span> Here you can access
          important forms, upload medical documentation, and review real-time
          claim statuses.</span><br /><br /> -->
          </div>
          <div
            [ngClass]="{
              'tw-flex tw-flex-wrap tw-w-full tw-items-center full-width-welcome':
                !showMore,
              'tw-flex tw-flex-wrap tw-w-full tw-items-center truncate-text full-width-welcome':
                showMore
            }"
            *ngFor="let item of welcomeMessage"
          >
            <div class="tw-w-full">
              <div class="app-pb2 app-pt3">
                <ng-container>
                  <div [innerHTML]="item?.welcomeMessageText | safeHtml"></div>
                </ng-container>
              </div>
            </div>
            <!-- Disabling v4 Callouts -->
            <div *ngIf="calloutsEnabled && !showMore">
              <div class="tw-w-full md:tw-w-[50%]" class="app-pl2">
                <div class="callout app-pb2 app-pt2">
                  <mat-icon color="primary" class="callout-icon"
                    >arrow_circle_right</mat-icon
                  >
                  <div class="callout-text">
                    <a href="#" class="callout-link">{{ item.callOutType1 }}</a
                    ><br />
                    {{ item.calloutMessage1 }}
                  </div>
                </div>
              </div>

              <div class="tw-w-full md:tw-w-[50%]" class="app-pl2">
                <div class="callout app-pb2 app-pt2">
                  <mat-icon color="primary" class="callout-icon"
                    >arrow_circle_right</mat-icon
                  >
                  <div class="callout-text">
                    <a href="#" class="callout-link">{{ item.callOutType2 }}</a
                    ><br />
                    {{ item.calloutMessage2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-w-full" class="showMoreLink app-pt1 ">
            <a [routerLink]="" (click)="showMore = !showMore">{{
              showMore ? 'Show more' : 'Show less'
            }}</a>
          </div>
        </ng-template>
      </app-loading-panel>
    </app-panel>
  `,

  styles: [
    `
      .text {
      }

      .callout {
        display: flex;
        align-items: top;
      }

      .callout-text {
        padding-top: 4px;
      }
      .callout-link {
        font-size: 16px;
        font-weight: bold;
      }

      .callout-icon {
        font-size: 30px;
        height: 30px;
        width: 30px;
        padding-right: 8px;
      }

      .showMore {
        height: 30px;
        overflow: hidden;
        word-wrap: break-word;
      }

      .showMoreLink {
        background-color: white;
      }

      .full-width-welcome {
        width: 100%;
        overflow: hidden;
      }

      .truncate-text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class WelcomeComponent extends BaseComponent implements OnInit {
  verizonMessage = true;

  showMore = false;
  calloutsEnabled = false;
  welcomeMessageLength = 0;
  shortWelcomeText = '';
  welcomeMessage: WelcomeMessage[];

  @Input()
  welcomeMessageInfo: any;

  @Input()
  welcomeMessageInfoLoaded: boolean;

  @Output()
  showWelcomeMessageSection = new EventEmitter<boolean>();

  outboundSSO: OutboundSSO;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private http: Http,
    private session: Session,
    public outboundSsoService: OutboundSsoService,
    public display: DisplayService,
    public sanitizer: DomSanitizer
  ) {
    super();
    this.welcomeMessage = new Array<WelcomeMessage>();
  }

  async ngOnInit() {
    this.subs.sink = this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((result) => {
        this.showMore = result.matches;
      });
    this.outboundSSO = this.display.outboundSSO;
    this.welcomeMessageInfo = await this.session.GetWelcomeMessage();

    //if (this.welcomeMessageInfo?.length > 0){
    const welcomeData = this.welcomeMessageInfo[0];
    //fconst welcomeMessage1 : Array<WelcomeMessage> = [];

    if (
      this.welcomeMessageInfo === null ||
      this.welcomeMessageInfo === undefined ||
      this.welcomeMessageInfo.length === 0
    ) {
      this.showWelcomeMessageSection.emit(false);
    }
    // if (welcomeData?.enabledLinks == 'Y') {
    //   this.calloutsEnabled = true;
    // }
    this.welcomeMessageLength = welcomeData?.welcomeMessageText.length;
    this.welcomeMessageInfoLoaded = true;
    //}
    /*     if(this.welcomeMessageLength > 100)
    {
      this.shortWelcomeText = welcomeData.welcomeMessageText.slice(0,150);
    }*/
    this.welcomeMessage.push(welcomeData);
  }

  ssoLinkClick() {
    this.outboundSsoService.openSsoUrl(
      this.outboundSSO.ssoType,
      this.outboundSSO.ssoEndPoint
    );
  }

  getInputValue(inpuValue: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(inpuValue);
  }

  reportClaimPopup() {
    // NOTE: html from database will have javascript OnClick handlers.
    // Need to replace OnClick with (click) before assigning to innerHtml.
  }
}
