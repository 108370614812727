import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { BaseComponent } from '../..';
export interface EmailFormValues {
  emailaddress: string;
  confirmEmailAddress: string;
}
@Component({
  selector: 'app-email-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container role="none">
      <br />
      <br />
      <br />
      <span *ngIf="!isFromCommPrefComponent" class="text-email"
        >Enter email address where you would like to recieve Multi-Factor
        Authentication Codes :
      </span>

      <span *ngIf="isFromCommPrefComponent" class="text-email"
        >Enter the email address where you would like to receive your status
        updates:</span
      >
      <div>
        <br />
        <form [formGroup]="emailGroup">
          <div class="tw-w-[60%]" id="EmailComp">
            <ul role="none" class="md:tw-w-[70%]">
              <li role="none">
                <mat-form-field>
                  <mat-label>Email Address</mat-label>
                  <input
                    type="email"
                    matInput
                    required
                    id="emailAddress"
                    #emailAddress
                    formControlName="emailAddress"
                    id="emailAddress"
                    formControlName="emailAddress"
                    ngDefaultControl
                    fxFlex="33"
                    fxFlex.lt-md="100"
                  />
                  <mat-error
                    *ngIf="
                      emailGroup.get('emailAddress').errors ||
                      emailGroup.get('emailAddress').invalid ||
                      emailGroup.get('emailAddress').hasError('email') ||
                      emailGroup.get('emailAddress').hasError('pattern')
                    "
                  >
                    <span>Error : Please enter a proper email address.</span>
                  </mat-error>
                  <mat-error
                    *ngIf="emailGroup.get('emailAddress').hasError('required')"
                  >
                    <span>Error : Email Address is Required.</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Confirm Email Address</mat-label>
                  <input
                    type="email"
                    matInput
                    required
                    id="confirmEmailAddress"
                    formControlName="confirmEmailAddress"
                    ngDefaultControl
                    fxFlex="33"
                    fxFlex.lt-md="100"
                  />
                  <mat-error
                    *ngIf="
                      emailGroup
                        .get('confirmEmailAddress')
                        .hasError('required') ||
                      emailGroup.get('confirmEmailAddress').hasError('email') ||
                      emailGroup.get('confirmEmailAddress').hasError('pattern')
                    "
                  >
                    <span>Please enter a proper email address.</span>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      emailGroup.get('confirmEmailAddress').hasError('required')
                    "
                  >
                    <span>Error : Email Address is Required.</span>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      (!emailMatch ||
                        emailGroup
                          .get('confirmEmailAddress')
                          .hasError('missMatchControl')) &&
                      (emailGroup.get('emailAddress').dirty ||
                        emailGroup.get('emailAddress').touched)
                    "
                  >
                    <span>The email addresses do not match.</span>
                  </mat-error>
                </mat-form-field>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </ng-container>
  `,

  styles: [
    `
      #EmailComp ::ng-deep .mat-mdc-form-field-error-wrapper {
        margin-top: -24px !important;
        position: inherit !important;
        margin-bottom: 7px !important;
      }

      .input {
        color: #67707a;
        border-color: #67707a;
      }
      .input:focus {
        color: #171c20;
        border: #007dbc;
      }

      .input:hover {
        color: #67707a;
        border: #3f464d;
      }

      .mat-mdc-form-field {
        width: 350px !important;
      }

      .text-email {
        font-weight: bold;
      }
    `,
  ],
})
export class EmailComponent extends BaseComponent implements OnInit {
  @Input() formGroupName: string;
  emailGroup: FormGroup;
  emailGroupEnterCode: FormGroup;
  @Input()
  toggleFormGroup: FormGroup;
  @Output() onFormGroupChange: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  @Input()
  isCommPrefComp: boolean = false;
  @Input()
  showEmailAddressForm: boolean = true;
  @Output()
  emailAuthCodeChanged = new EventEmitter();
  @Input()
  showErrorMessage: boolean;
  @Input()
  emailMatch: boolean = true;

  @ViewChild('emailAddress')
  emailAddressInput: ElementRef;

  isFromCommPrefComponent: boolean = false;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change2: SimpleChange = changes['showEmailAddressForm'];
    const change3: SimpleChange = changes['showErrorMessage'];
    const change4: SimpleChange = changes['isCommPrefComp'];
    const change5: SimpleChange = changes['emailMatch'];
    if (change2 != undefined) {
      if (change2.currentValue != null && change2.currentValue != undefined) {
        this.showEmailAddressForm = change2.currentValue;
      }
    }
    if (change3 != undefined) {
      if (change3.currentValue != null && change3.currentValue != undefined) {
        this.showErrorMessage = change3.currentValue;
      }
    }
    if (change4 != undefined) {
      if (change4.currentValue != null && change4.currentValue != undefined) {
        this.isFromCommPrefComponent = change4.currentValue;
      }
    }
    if (change5 != undefined) {
      if (change5.currentValue != null && change5.currentValue != undefined) {
        this.emailMatch = change5.currentValue;
      }
    }
  }

  ngOnInit(): void {
    this.emailGroup = this.rootFormGroup.control.get('emailGroup') as FormGroup;
    this.emailGroupEnterCode = this.rootFormGroup.control.get(
      'emailGroupEnterCode'
    ) as FormGroup;

    this.subs.sink = this.emailGroup
      .get('emailAddress')
      ?.valueChanges.subscribe(() => {
        this.emailGroup.get('confirmEmailAddress')?.updateValueAndValidity();
        this.emailGroup.get('confirmEmailAddress')?.markAsTouched();
      });
    this.changeDetector.detectChanges();
  }
  onValueChange() {
    this.emailAuthCodeChanged.emit(true);
  }
}
