export class GlobalIntakeData {
    giftCard?: boolean;
    redirectUrl?: string;
    emplId?: string;
    emplUnum?: string;
    emplFirstName?: string;
    emplHrMasterUid?: string;
    emplLastName?: string;
    timeZone?: string;

    constructor(values: GlobalIntakeData){
        Object.assign(this, values);
    }
}