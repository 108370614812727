import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserActions } from '../../../shared/models/user';
import { UserActionService } from '../../../shared/services/user-action.service';
import { EncryptionService } from '../../services/encryption.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-claimant-label',
  template: `
    <div [ngClass]="enabled ? 'claimNumberbold colorswblue' : ''">
      <span [ngClass]="nameClass" *ngIf="showNameLabel"
        >NAME
        <br />
      </span>

      <span
        *ngIf="!enabled"
        [ngClass]="valueClass"
        [innerHTML]="claimantLabel | titlecase"
      ></span>

      <a
        routerLink=""
        (keydown.enter)="handleClick(claimant)"
        *ngIf="enabled"
        [ngClass]="valueClass"
        (click)="handleClick(claimant)"
        [innerHTML]="claimantLabel | titlecase"
      >
      </a>
    </div>
  `,
  styles: [``],
})
export class ClaimantLabelComponent {
  @Input({ required: true })
  claimant: IClaimant;

  @Input()
  showNameLabel: boolean = false;

  @Input()
  nameClass: string = '';

  @Input()
  valueClass: string = '';

  @Input()
  disabled: boolean = false;

  get enabled(): boolean {
    return !this.disabled;
  }

  get claimantLabel() {
    if (!this.claimant) {
      return '';
    }

    let name = this.claimant.name;
    const temp: any = this.claimant;
    const empId = temp.empId ?? temp.employeeID ?? temp.employeeId;
    if (!this.userRole.claimant && empId) {
      name += ' - ' + empId;
    }
    return name;
  }

  constructor(
    private router: Router,
    public session: Session,
    private userAction: UserActionService,
    private userRole: UserRoleService,
    private encrypt: EncryptionService
  ) {}

  async handleClick(claimant: IClaimant) {
    if (
      claimant.empUnum?.length > 0 &&
      claimant.empUnum === this.session.user.empUnum
    ) {
      this.userAction.setAction(UserActions.ViewOwnEmpDb);
      this.router.navigate(['/dashboard']);
    } else if (this.userRole.manager) {
      const encryptedEmpUnum = this.encrypt.encrypt(claimant.empUnum);
      await this.userAction.setClaimant(claimant);
      await this.userAction.setAction(UserActions.MgrViewEmpDb);
      this.router.navigate(['/dashboard'], {
        queryParams: { emp: encryptedEmpUnum },
      });
    } else if (this.session.user.roles.auditor) {
      this.redirectToClaimant(claimant);
    }
  }

  async redirectToClaimant(claimant: IClaimant) {
    try {
      await this.session.emulateUser(
        claimant.empId,
        claimant.claimId,
        this.session.user.contNum,
        claimant.empUnum,
        claimant.hrMasterUid,
        claimant.lineOfBusiness
      );
      !this.session.user.emulatorContext ? await this.session.loadUser() : null;
      if (this.session.user.emulatorContext) {
        if (claimant.claimId) {
          await this.session.emulateLogStart(claimant.claimId);
        } else if (claimant.hrMasterUid) {
          await this.session.emulateLogStart(null);
        }
      }
    } catch (error) {
      console.error('Error during redirection:', error);
    }
  }
}

export interface IClaimant {
  firstName?: string;
  lastName?: string;
  name?: string;
  empId?: string;
  maskedEmpId?: string;
  claimId?: string;
  empUnum?: string;
  employeeID?: string;
  hrMasterUid?: string;
  lineOfBusiness?: string;
}
