import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { isEmpty } from 'src/app/shared';
import { Filter, FilterType } from '../filter/filter';
import { ListComponent } from './list.component';

@Component({
  selector: 'app-list-header',
  template: `
    <th scope="col">
      <span class="header-label">{{ label }}</span>
      <ng-container
        *ngIf="labelTemplate"
        [ngTemplateOutlet]="labelTemplate"
      ></ng-container>
    </th>
  `,
  styles: [
    `
      .header-label {
        color: var(--color-teal);
        font-weight: bold;
        font-size: 12px;
        margin-right: 14px;
        text-transform: uppercase;
      }
    `,
  ],
})
export class ListHeaderComponent implements OnInit {
  /**
   * The object property to bind to.
   */
  @Input()
  name: string;

  /**
   * Additional object properties to include in filtering. Do not include the value of name in the names array. It's included by default.
   */
  @Input()
  names: string[];

  /**
   * Displayed label on header and sort menu
   */
  @Input()
  label: string;

  /**
   * Overrides label on sort menu. Optional.
   */
  @Input()
  sortLabel: string;

  /**
   * Controls filter strategy. Should match data type of value. Default is Text.
   */
  @Input()
  filterType: FilterType = 'Text';

  /**
   * Enables sorting for this header
   */
  @Input()
  sortEnabled: boolean = true;

  /**
   * Enables list filtering for this header
   */
  @Input()
  filterEnabled: boolean = true;

  /**
   * Template is displayed to the right of label
   */
  @Input()
  labelTemplate: TemplateRef<any>;

  /**
   * When readOnly is provided, sorting and filtering are disabled.
   * Useful for columns that just contain action items.
   */
  @Input()
  set readOnly(value: any) {
    if (coerceBooleanProperty(value)) {
      this.sortEnabled = false;
      this.filterEnabled = false;
    }
  }

  filter: Filter;

  /**
   * Returns sortLabel if not empty, else returns label
   */
  get sortLabelComputed(): string {
    return isEmpty(this.sortLabel) ? this.label : this.sortLabel;
  }

  constructor(public list: ListComponent) {}

  ngOnInit(): void {
    this.filter = new Filter(
      this.name,
      this.label,
      this.filterType,
      this.filterType === 'Text' ? 'Contain' : 'Equal'
    );
    this.filter.names = this.names;
    this.list.addHeader(this);
  }
}
