import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ControlContainer,
} from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Session } from '../../services/session.service';
import { ToastType } from 'src/app/shared';
import { Schedule } from '../../models/Schedule';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { AccountSettingsService } from '../../services/accountsettings.service';
import { emulator } from '../../services/emulator.service';
@Component({
  selector: 'app-management-notification',

  template: `
    <div class="app-pb2" id="manNotDiv">
      <form
        role="none"
        class="tw-flex tw-flex-wrap tw-flex-col"
        [formGroup]="managementNotificationForm"
        autocomplete="off"
      >
        <div class="app-pt2 app-pb2">
          <app-header-label type="Header2" class="app-header-2"
            >Manager Text Preferences</app-header-label
          >
        </div>
        <div class="md:tw-block">
          <div class="flex-container">
            <p class="heading2">
              If you would like to receive text messages regarding your
              employees claims/absences, please provide your mobile number,
              delivery frequency and your time zone in the options below.
            </p>
            <div class="app-pt1 app-pb2 radioBtnHeading">
              <span>Would you like to receive these notifications?</span
              ><mat-radio-group
                role="radiogroup"
                aria-label="Select an option"
                [(ngModel)]="ReceiveNotificationRadio"
                (change)="onSelectedValueChange($event.value)"
                formControlName="receiveNotificationRadioform"
              >
                <mat-radio-button value="yes" class="app-pl2"
                  >Yes</mat-radio-button
                >
                <mat-radio-button value="no" class="app-pl2" checked="true"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="flex-left-col hdgTextMsg">
              <strong>Text Message</strong><br aria-hidden="true" />
              <label
                >Please enter your mobile number including area code. Standard
                text messages rates will apply</label
              >
            </div>
            <br />
            <div class="md:tw-w-[30%] txtinputfield">
              <mat-form-field>
                <mat-label>Mobile Number</mat-label>
                <input
                  matInput
                  name="mobilenumber"
                  id="mobilenumber"
                  [required]="isRequired"
                  tabindex="0"
                  aria-label="Mobile Number"
                  formControlName="mobilenumberform"
                  placeholder="123-456-7890"
                  [maxLength]="12"
                  (keypress)="insertMobileDash('mobilenumberform', $event)"
                />
                <mat-error
                  class="error-message-style"
                  *ngIf="
                    managementNotificationForm
                      .get('mobilenumberform')
                      .hasError('required')
                  "
                >
                  <span class="error-message"
                    >Error : Please enter mobile number</span
                  >
                </mat-error>
                <mat-error
                  class="error-message-style"
                  *ngIf="
                    managementNotificationForm
                      .get('mobilenumberform')
                      .hasError('pattern')
                  "
                >
                  <span class="error-message"
                    >Error : A mobile number in a valid format must be
                    entered.</span
                  >
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex-left-col">
              <strong>Your Time Zone</strong><br aria-hidden="true" />
            </div>
            <br />
            <div class="md:tw-w-[30%] txtinputfield">
              <mat-form-field>
                <mat-label>Time Zone</mat-label>
                <mat-select
                  class="ddltimezone"
                  tabIndex="0"
                  id="timezone"
                  [required]="isRequired"
                  aria-label="Time Zone"
                  formControlName="timezoneform"
                  (selectionChange)="UpdateSubmitButtonDisable($event)"
                >
                  <mat-option
                    *ngFor="let zones of timezonelist"
                    [value]="zones.Value"
                  >
                    {{ zones.Text }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="error-message-style"
                  *ngIf="
                    managementNotificationForm
                      .get('timezoneform')
                      .hasError('required')
                  "
                >
                  <span class="error-message"
                    >Error : A time zone from the dropdown list will need to be
                    selected. Blank is not valid.</span
                  >
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex-left-col">
              <strong>Message Delivery Frequency</strong
              ><br aria-hidden="true" />
            </div>
            <br />
            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Sunday"
                      id="chekboxSunday"
                      formControlName="sundaycheckboxform"
                      (change)="
                        fnCheckWeek('sundaystartform', 'sundayendform', $event)
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Sunday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="sundaystart"
                      id="sundaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="sundaystartform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('sundaystartform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('sundaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('sundaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('sundaystartform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>

                <div
                  class="dx-field flex-row md:tw-w-[18%]  messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="sundayend"
                      id="sundayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="sundayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('sundayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('sundayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('sundayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('sundayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Monday"
                      formControlName="mondaycheckboxform"
                      (change)="
                        fnCheckWeek('mondaystartform', 'mondayendform', $event)
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Monday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="mondaystart"
                      id="mondaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="mondaystartform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('mondaystartform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('mondaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message"> Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('mondaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="mondayend"
                      id="mondayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="mondayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('mondayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('mondayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('mondayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('mondayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Tuesday"
                      formControlName="tuesdaycheckboxform"
                      (change)="
                        fnCheckWeek(
                          'tuesdaystartform',
                          'tuesdayendform',
                          $event
                        )
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Tuesday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="tuesdaystart"
                      id="tuesdaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="tuesdaystartform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('tuesdaystartform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('tuesdaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('tuesdaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="tuesdayend"
                      id="tuesdayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="tuesdayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('tuesdayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('tuesdayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('tuesdayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('tuesdayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Wednesday"
                      formControlName="wednesdaycheckboxform"
                      (change)="
                        fnCheckWeek(
                          'wednesdaystartform',
                          'wednesdayendform',
                          $event
                        )
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Wednesday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="wednesdaystart"
                      id="wednesdaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="wednesdaystartform"
                      placeholder="HH:MM am"
                      (keypress)="
                        insertTimingColon('wednesdaystartform', $event)
                      "
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('wednesdaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('wednesdaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="wednesdayend"
                      id="wednesdayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="wednesdayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('wednesdayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('wednesdayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('wednesdayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('wednesdayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Thursday"
                      formControlName="thursdaycheckboxform"
                      (change)="
                        fnCheckWeek(
                          'thursdaystartform',
                          'thursdayendform',
                          $event
                        )
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Thursday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="thursdaystart"
                      id="thursdaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="thursdaystartform"
                      placeholder="HH:MM am"
                      (keypress)="
                        insertTimingColon('thursdaystartform', $event)
                      "
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('thursdaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('thursdaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="thursdayend"
                      id="thursdayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="thursdayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('thursdayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('thursdayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('thursdayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('thursdayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Friday"
                      formControlName="fridaycheckboxform"
                      (change)="
                        fnCheckWeek('fridaystartform', 'fridayendform', $event)
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Friday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="fridaystart"
                      id="fridaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="fridaystartform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('fridaystartform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('fridaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('fridaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="fridayend"
                      id="fridayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="fridayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('fridayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('fridayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('fridayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('fridayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="dx-fieldset flex-column MDFWeek">
              <div class="flex-row-container">
                <div class="dx-field flex-row md:tw-w-[17%] MDFCheckbox">
                  <div dir="ltr" style="padding-top: 10px">
                    <mat-checkbox
                      labelPosition="after"
                      aria-label="Saturday"
                      formControlName="saturdaycheckboxform"
                      (change)="
                        fnCheckWeek(
                          'saturdaystartform',
                          'saturdayendform',
                          $event
                        )
                      "
                    ></mat-checkbox>
                    <span class="weekDaysMargin">Saturday</span>
                  </div>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield txtStartMobMargin"
                >
                  <mat-form-field>
                    <mat-label>Start time</mat-label>
                    <input
                      matInput
                      name="saturdaystart"
                      id="saturdaystart"
                      tabindex="0"
                      aria-label="Start time"
                      class="form-control"
                      formControlName="saturdaystartform"
                      placeholder="HH:MM am"
                      (keypress)="
                        insertTimingColon('saturdaystartform', $event)
                      "
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('saturdaystartform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('saturdaystartform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="dx-field flex-row md:tw-w-[18%] messagedelivery-style txttimefield"
                >
                  <mat-form-field>
                    <mat-label>End time</mat-label>
                    <input
                      matInput
                      name="saturdayend"
                      id="saturdayend"
                      tabindex="0"
                      aria-label="End time"
                      class="form-control"
                      formControlName="saturdayendform"
                      placeholder="HH:MM am"
                      (keypress)="insertTimingColon('saturdayendform', $event)"
                    />
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('saturdayendform')
                          .hasError('required')
                      "
                    >
                      <span class="error-message">Error : Required </span>
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('saturdayendform')
                          .hasError('pattern')
                      "
                    >
                      <span class="error-message"
                        >Error : A valid start time and end time must be
                        entered. <br />
                        The time of the day entered cannot exceed 24 hours in a
                        day (ie: 12:00 am - 11:59 pm).</span
                      >
                    </mat-error>
                    <mat-error
                      class="error-message-style"
                      *ngIf="
                        managementNotificationForm
                          .get('saturdayendform')
                          .hasError('timeGreaterThan')
                      "
                    >
                      <span class="error-message"
                        >Error : The End time cannot be prior to the Start
                        time.</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="mediaToast" *ngIf="this.toastMessage != ''">
              <br />
              <app-toast id="successMsg" [type]="toastType" #toastMsg>
                {{ this.toastMessage }}
              </app-toast>
            </div>
            <div
              fxLayoutAlign="end start"
              style="float: right"
              class="btnStyleMob"
            >
              <button
                type="submit"
                autofocus
                emphasis="High"
                mat-raised-button
                tabindex="0"
                class="btn-info"
                (click)="onSubmit()"
                aria-label="Save manager preferences."
                [disabled]="this.submitDisabled"
              >
                Save
              </button>

              <button
                type="button"
                mat-raised-button
                class="btn-cancel"
                emphasis="Low"
                color="whiteButton"
                tabindex="0"
                (click)="onCancel()"
                aria-label="Cancel saving manager preferences."
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [
    `
      .app-header-2 {
        font-size: 20px;
        color: #007dbc;
        font-weight: 700;
      }

      #manNotDiv ::ng-deep .mat-mdc-form-field-error-wrapper {
        margin-top: -24px !important;
        position: inherit !important;
        margin-bottom: 7px !important;
      }

      .messagedelivery-style {
        padding-left: 2%;
      }
      .dx-fieldset.flex-column {
        display: flex;
        flex-direction: column;
      }
      .flex-row-container {
        display: flex;
        flex-direction: row;
      }
      .dx-field.flex-row {
        display: flex;
        flex-direction: row;
      }

      .btn-info:hover {
        background-color: #035e81;
      }
      .btn-info {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background: #244578;
        background-color: #007dbc;
        font-weight: bold;
      }

      .btn-cancel:hover {
        background-color: #f1f4f7;
      }
      .btn-cancel {
        font-size: 15px;
        color: #3f464d;
        line-height: 30px;
        border-radius: 40px;
        box-shadow: none;
        font-weight: bold;
        background-color: #ffffff;
        border-color: #3f464d;
      }

      @media screen and (min-width: 355px) and (max-width: 400px) {
        .heading2 {
          width: 55%;
        }
        .radioBtnHeading {
          width: 35%;
        }
        .hdgTextMsg {
          width: 55%;
        }
        .txtinputfield {
          width: 50%;
        }
        .txttimefield {
          width: 21%;
        }
        .txtStartMobMargin {
          margin-left: -10px;
        }
        .MDFWeek {
          margin-right: 20px;
          margin-left: -20px;
        }
        .MDFCheckbox {
          width: 23%;
        }
        .weekDaysMargin {
          margin-left: -7px;
        }
        .btnStyleMob {
          float: left !important;
        }
        .mediaToast {
          max-width: 60%;
        }
      }

      @media screen and (min-width: 401px) and (max-width: 550px) {
        .heading2 {
          width: 55%;
        }
        .radioBtnHeading {
          width: 35%;
        }
        .hdgTextMsg {
          width: 55%;
        }
        .txtinputfield {
          width: 50%;
        }
        .txttimefield {
          width: 21%;
        }
        .txtStartMobMargin {
          margin-left: -10px;
        }
        .MDFWeek {
          margin-right: 20px;
          margin-left: -20px;
        }
        .MDFCheckbox {
          width: 23%;
        }
        .weekDaysMargin {
          margin-left: -7px;
        }
        .btnStyleMob {
          float: left !important;
        }
        .mediaToast {
          max-width: 60%;
        }
      }
    `,
  ],
  animations: [], //if needed trigger conditions here
})
export class ManagementNotificationPageComponent implements OnInit {
  pattern = {
    0: { pattern: new RegExp('[01]') },
    1: { pattern: new RegExp('[012345]') },
    2: { pattern: new RegExp('[0123456789]') },
    X: { pattern: new RegExp('[apAP]') },
    M: { pattern: new RegExp('[mM]') },
  };

  managementNotificationForm: FormGroup = this.fb.group({
    //saturdayendform: [],
  });
  schedule: Schedule;
  isUpdate: boolean = false;
  toastMessage: string = '';
  toastType: ToastType;
  isSuccess: boolean = false;
  showErrorMsg: boolean = false;
  ReceiveNotificationRadio: String = 'no';
  isRequired = false;
  timerfix: any;
  submitDisabled = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private el: ElementRef,
    public session: Session,
    private accountService: AccountSettingsService,
    private enumalatorService: emulator
  ) {}

  async ngOnInit() {
    this.createManagementNotificationForm();
    await this.GetManagementNotifications();
    let manageDelivery = [
      'sundaycheckboxform',
      'mondaycheckboxform',
      'tuesdaycheckboxform',
      'wednesdaycheckboxform',
      'thursdaycheckboxform',
      'fridaycheckboxform',
      'saturdaycheckboxform',
    ];
    if (
      this.managementNotificationForm.get('receiveNotificationRadioform')
        .value == 'yes'
    ) {
      this.managementNotificationForm.controls['timezoneform'].enable();
      this.managementNotificationForm.controls['mobilenumberform'].enable();
      manageDelivery.forEach((type) => {
        this.managementNotificationForm.controls[type].enable();
      });
    } else if (
      this.managementNotificationForm.get('receiveNotificationRadioform')
        .value == 'no'
    ) {
      this.managementNotificationForm.controls['timezoneform'].disable();
      this.managementNotificationForm.controls['mobilenumberform'].disable();
      manageDelivery.forEach((type) => {
        this.managementNotificationForm.controls[type].disable();
      });
      this.disableAllTimeValues();
    }
  }

  disableAllTimeValues() {
    for (let index = 0; index < 7; index++) {
      var day =
        index == 0
          ? 'sunday'
          : index == 1
          ? 'monday'
          : index == 2
          ? 'tuesday'
          : index == 3
          ? 'wednesday'
          : index == 4
          ? 'thursday'
          : index == 5
          ? 'friday'
          : 'saturday';
      this.managementNotificationForm.controls[day + 'startform'].disable();
      this.managementNotificationForm.controls[day + 'endform'].disable();
    }
  }

  async onSelectedValueChange(event: string) {
    let manageDelivery = [
      'sundaycheckboxform',
      'mondaycheckboxform',
      'tuesdaycheckboxform',
      'wednesdaycheckboxform',
      'thursdaycheckboxform',
      'fridaycheckboxform',
      'saturdaycheckboxform',
    ];
    if (event === 'no') {
      this.submitDisabled = false;
      this.isRequired = false;

      //this.onCancel();

      //this.onCancel();

      this.disableAllTimeValues();

      Object.keys(this.managementNotificationForm.controls).forEach((key) => {
        this.managementNotificationForm.controls[key].setErrors(null);
      });
      this.managementNotificationForm.controls['timezoneform'].disable();
      this.managementNotificationForm.controls['mobilenumberform'].disable();
      manageDelivery.forEach((type) => {
        this.managementNotificationForm.controls[type].disable();
      });
      this.managementNotificationForm
        .get('receiveNotificationRadioform')
        .setValue('no');
    } else if (event === 'yes') {
      await this.GetManagementNotifications();
      this.isRequired = true;
      this.toastMessage = '';
      this.managementNotificationForm.controls['timezoneform'].enable();
      this.managementNotificationForm.controls['mobilenumberform'].enable();
      manageDelivery.forEach((type) => {
        this.managementNotificationForm.controls[type].enable();
      });
      this.managementNotificationForm
        .get('receiveNotificationRadioform')
        .setValue('yes');
    }
  }

  createManagementNotificationForm() {
    this.managementNotificationForm.addControl(
      'receiveNotificationRadioform',
      new FormControl('no')
    );

    this.managementNotificationForm.addControl(
      'mobilenumberform',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
      ])
    );

    this.managementNotificationForm.addControl(
      'timezoneform',
      new FormControl('', [Validators.required])
    );

    this.managementNotificationForm.addControl(
      'sundaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'sundaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'sundayendform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'mondaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'mondaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'mondayendform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'tuesdaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'tuesdaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'tuesdayendform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'wednesdaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'wednesdaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'wednesdayendform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'thursdaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'thursdaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'thursdayendform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'fridaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'fridaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'fridayendform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'saturdaycheckboxform',
      new FormControl('')
    );

    this.managementNotificationForm.addControl(
      'saturdaystartform',
      new FormControl({ value: '', disabled: true })
    );

    this.managementNotificationForm.addControl(
      'saturdayendform',
      new FormControl({ value: '', disabled: true })
    );
  }

  timeZones: { Value: string; Text: string }[] = [
    {
      Value: 'AKST',
      Text: 'Alaska Standard Time',
    },
    {
      Value: 'AST',
      Text: 'Atlantic Standard Time',
    },
    {
      Value: 'CST',
      Text: 'Central Standard Time',
    },
    {
      Value: 'CHST',
      Text: 'Chamorro Standard Time',
    },
    {
      Value: 'EST',
      Text: 'Eastern Standard Time',
    },
    {
      Value: 'HAST',
      Text: 'Hawaii-Aleutian Standard Time',
    },
    {
      Value: 'HST',
      Text: 'Hawaii Standard Time',
    },
    {
      Value: 'MHT',
      Text: 'Marshall Islands Time',
    },
    {
      Value: 'MST',
      Text: 'Mountain Standard Time',
    },
    {
      Value: 'PST',
      Text: 'Pacific Standard Time',
    },
    {
      Value: 'PWT',
      Text: 'Palau Time',
    },
    {
      Value: 'SST',
      Text: 'Samoa Standard Time',
    },
  ];

  timezonelist = cloneDeep(this.timeZones);

  async onSubmit() {
    let receiveNotification = this.managementNotificationForm.get(
      'receiveNotificationRadioform'
    ).value;

    if (receiveNotification === 'yes' || receiveNotification === 'no') {
      for (const key of Object.keys(this.managementNotificationForm.controls)) {
        if (this.managementNotificationForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl.focus();
          break;
        }
      }
      //this.ConditionallyRequiredValidator(this.managementNotificationForm);
      for (let index = 0; index < 7; index++) {
        var day =
          index == 0
            ? 'sunday'
            : index == 1
            ? 'monday'
            : index == 2
            ? 'tuesday'
            : index == 3
            ? 'wednesday'
            : index == 4
            ? 'thursday'
            : index == 5
            ? 'friday'
            : 'saturday';
        if (
          this.managementNotificationForm.get(day + 'checkboxform').value ==
          false
        ) {
          if (index >= 6) {
            this.toastMessage =
              'At least one day of the week will need to be checked.';
            this.toastType = 'Error';
            this.isSuccess = true;
            return;
          }
        } else {
          break;
        }
      }
      if (this.managementNotificationForm.valid) {
        this.enumalatorService.showAuditorInfo();
        var request: any = {
          CreatedBy:
            this.schedule && this.schedule.createdBy
              ? this.schedule.createdBy
              : this.session.user.userName,
          CreatedSource:
            this.schedule && this.schedule.createdSource
              ? this.schedule.createdSource
              : 'mySedgwick',
          CreatedTimestamp:
            this.schedule && this.schedule.createdTimestamp
              ? this.schedule.createdTimestamp
              : new Date(),
          LastUpdatedBy: this.session.user.userName,
          LastUpdatedSource: 'mySedgwick',
          LastUpdatedTimestamp: new Date(),
          RequestTextMessages: receiveNotification === 'yes' ? true : false, // need to get radio button value
          UserType: 'Supervisor',
          TextPhone: this.managementNotificationForm.get('mobilenumberform')
            .value
            ? this.managementNotificationForm.get('mobilenumberform').value
            : '',
          Timezone: this.managementNotificationForm.get('timezoneform').value
            ? this.managementNotificationForm.get('timezoneform').value
            : '',
          MonEnabled: this.managementNotificationForm.get('mondaycheckboxform')
            .value
            ? this.managementNotificationForm.get('mondaycheckboxform').value
            : false,
          MonStartTime: this.managementNotificationForm.get('mondaystartform')
            .value
            ? this.managementNotificationForm.get('mondaystartform').value
            : '',
          MonEndTime: this.managementNotificationForm.get('mondayendform').value
            ? this.managementNotificationForm.get('mondayendform').value
            : '',
          TueEnabled: this.managementNotificationForm.get('tuesdaycheckboxform')
            .value
            ? this.managementNotificationForm.get('tuesdaycheckboxform').value
            : false,
          TueStartTime: this.managementNotificationForm.get('tuesdaystartform')
            .value
            ? this.managementNotificationForm.get('tuesdaystartform').value
            : '',
          TueEndTime: this.managementNotificationForm.get('tuesdayendform')
            .value
            ? this.managementNotificationForm.get('tuesdayendform').value
            : '',
          WedEnabled: this.managementNotificationForm.get(
            'wednesdaycheckboxform'
          ).value
            ? this.managementNotificationForm.get('wednesdaycheckboxform').value
            : false,
          WedStartTime: this.managementNotificationForm.get(
            'wednesdaystartform'
          ).value
            ? this.managementNotificationForm.get('wednesdaystartform').value
            : '',
          WedEndTime: this.managementNotificationForm.get('wednesdayendform')
            .value
            ? this.managementNotificationForm.get('wednesdayendform').value
            : '',
          ThuEnabled: this.managementNotificationForm.get(
            'thursdaycheckboxform'
          ).value
            ? this.managementNotificationForm.get('thursdaycheckboxform').value
            : false,
          ThuStartTime: this.managementNotificationForm.get('thursdaystartform')
            .value
            ? this.managementNotificationForm.get('thursdaystartform').value
            : '',
          ThuEndTime: this.managementNotificationForm.get('thursdayendform')
            .value
            ? this.managementNotificationForm.get('thursdayendform').value
            : '',
          FriEnabled: this.managementNotificationForm.get('fridaycheckboxform')
            .value
            ? this.managementNotificationForm.get('fridaycheckboxform').value
            : false,
          FriStartTime: this.managementNotificationForm.get('fridaystartform')
            .value
            ? this.managementNotificationForm.get('fridaystartform').value
            : '',
          FriEndTime: this.managementNotificationForm.get('fridayendform').value
            ? this.managementNotificationForm.get('fridayendform').value
            : '',
          SatEnabled: this.managementNotificationForm.get(
            'saturdaycheckboxform'
          ).value
            ? this.managementNotificationForm.get('saturdaycheckboxform').value
            : false,
          SatStartTime: this.managementNotificationForm.get('saturdaystartform')
            .value
            ? this.managementNotificationForm.get('saturdaystartform').value
            : '',
          SatEndTime: this.managementNotificationForm.get('saturdayendform')
            .value
            ? this.managementNotificationForm.get('saturdayendform').value
            : '',
          SunEnabled: this.managementNotificationForm.get('sundaycheckboxform')
            .value
            ? this.managementNotificationForm.get('sundaycheckboxform').value
            : false,
          SunStartTime: this.managementNotificationForm.get('sundaystartform')
            .value
            ? this.managementNotificationForm.get('sundaystartform').value
            : '',
          SunEndTime: this.managementNotificationForm.get('sundayendform').value
            ? this.managementNotificationForm.get('sundayendform').value
            : '',
        };

        let test = this.accountService.SetManagementNotifications(request);

        this.toastMessage =
          'Your Manager Preferences have been successfully updated, please allow 15 minutes for the updates to be effective.';
        this.toastType = 'Success';
        this.isSuccess = true;
        this.submitDisabled = true;
      }
    } else {
      this.toastMessage =
        'You have not selected to receive these notification, review the notifications and select Yes or Cancel this request. ';
      this.toastType = 'Error';
      this.isSuccess = true;
      return;
    }
  }

  onCancel(): void {
    try {
      this.managementNotificationForm.reset();
      this.toastMessage = '';
      this.managementNotificationForm.controls[
        'receiveNotificationRadioform'
      ].setValue('no');
      for (let index = 0; index < 7; index++) {
        var day =
          index == 0
            ? 'sunday'
            : index == 1
            ? 'monday'
            : index == 2
            ? 'tuesday'
            : index == 3
            ? 'wednesday'
            : index == 4
            ? 'thursday'
            : index == 5
            ? 'friday'
            : 'saturday';

        this.managementNotificationForm
          .get(day + 'startform')
          .clearValidators();
        this.managementNotificationForm
          .get(day + 'startform')
          .updateValueAndValidity();
        this.managementNotificationForm.get(day + 'endform').clearValidators();
        this.managementNotificationForm
          .get(day + 'endform')
          .updateValueAndValidity();
        this.managementNotificationForm.controls[day + 'startform'].disable();
        this.managementNotificationForm.controls[day + 'endform'].disable();
      }
    } catch (err) {
      var aa = err;
    }
  }

  fnCheckWeek(start: string, end: string, event: any): void {
    this.submitDisabled = false;
    if (event.checked != true) {
      this.managementNotificationForm.get(start).setValue(null);
      this.managementNotificationForm.get(end).setValue(null);
      this.managementNotificationForm.get(start).setValidators(null);
      this.managementNotificationForm.get(start).clearValidators();
      this.managementNotificationForm.get(start).updateValueAndValidity();
      this.managementNotificationForm.get(end).setValidators(null);
      this.managementNotificationForm.get(end).clearValidators();
      this.managementNotificationForm.get(end).updateValueAndValidity();
      this.managementNotificationForm.controls[start].disable();
      this.managementNotificationForm.controls[end].disable();
    }
    if (event.checked === true) {
      this.managementNotificationForm
        .get(start)
        .setValidators([
          Validators.required,
          Validators.pattern(
            /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/i
          ),
        ]);

      this.managementNotificationForm.get(start).updateValueAndValidity();

      this.managementNotificationForm
        .get(end)
        .setValidators([
          Validators.required,
          Validators.pattern(
            /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/i
          ),
        ]);
      this.managementNotificationForm.controls[end].addValidators(
        customvalidations.timeGreaterThan(
          this.managementNotificationForm.controls[start]
        )
      );
      this.managementNotificationForm.get(end).updateValueAndValidity();

      this.managementNotificationForm.controls[start].enable();
      this.managementNotificationForm.controls[end].enable();
    }
  }

  async GetManagementNotifications() {
    this.schedule = await this.accountService.getManagementNotifications();
    if (this.schedule) {
      if (this.schedule?.requestTextMessages) {
        this.managementNotificationForm
          .get('receiveNotificationRadioform')
          .setValue('yes');
        this.submitDisabled = true;
      } else {
        this.managementNotificationForm
          .get('receiveNotificationRadioform')
          .setValue('no');
        this.submitDisabled = true;
      }
      if (this.schedule?.textPhone) {
        this.isUpdate = true;
        const modifiedNum = this.formatPhoneNumber(this.schedule.textPhone);
        if (modifiedNum)
          this.managementNotificationForm
            .get('mobilenumberform')
            .setValue(modifiedNum);
      }
      if (this.schedule?.timezone)
        this.managementNotificationForm
          .get('timezoneform')
          .setValue(this.schedule.timezone);
      if (this.schedule?.monEnabled)
        this.managementNotificationForm
          .get('mondaycheckboxform')
          .setValue(this.schedule.monEnabled);
      if (this.schedule?.monStartTime) {
        const monStart = this.TwelveHoursTimeFormat(this.schedule.monStartTime);
        if (monStart) {
          this.managementNotificationForm
            .get('mondaystartform')
            .setValue(monStart);
          if (this.schedule.monEnabled)
            this.managementNotificationForm.controls[
              'mondaystartform'
            ].enable();
        }
      }
      if (this.schedule?.monEndTime) {
        const monEnd = this.TwelveHoursTimeFormat(this.schedule.monEndTime);
        if (monEnd) {
          this.managementNotificationForm.get('mondayendform').setValue(monEnd);
          if (this.schedule.monEnabled)
            this.managementNotificationForm.controls['mondayendform'].enable();
        }
      }
      if (this.schedule?.tueEnabled)
        this.managementNotificationForm
          .get('tuesdaycheckboxform')
          .setValue(this.schedule.tueEnabled);
      if (this.schedule?.tueStartTime) {
        const tueStart = this.TwelveHoursTimeFormat(this.schedule.tueStartTime);
        if (tueStart) {
          this.managementNotificationForm
            .get('tuesdaystartform')
            .setValue(tueStart);
          if (this.schedule.tueEnabled)
            this.managementNotificationForm.controls[
              'tuesdaystartform'
            ].enable();
        }
      }
      if (this.schedule?.tueEndTime) {
        const tueEnd = this.TwelveHoursTimeFormat(this.schedule.tueEndTime);
        if (tueEnd) {
          this.managementNotificationForm
            .get('tuesdayendform')
            .setValue(tueEnd);
          if (this.schedule.tueEnabled)
            this.managementNotificationForm.controls['tuesdayendform'].enable();
        }
      }
      if (this.schedule?.wedEnabled)
        this.managementNotificationForm
          .get('wednesdaycheckboxform')
          .setValue(this.schedule.wedEnabled);
      if (this.schedule?.wedStartTime) {
        const wedStart = this.TwelveHoursTimeFormat(this.schedule.wedStartTime);
        if (wedStart) {
          this.managementNotificationForm
            .get('wednesdaystartform')
            .setValue(wedStart);
          if (this.schedule.wedEnabled)
            this.managementNotificationForm.controls[
              'wednesdaystartform'
            ].enable();
        }
      }
      if (this.schedule?.wedEndTime) {
        const wedEnd = this.TwelveHoursTimeFormat(this.schedule.wedEndTime);
        if (wedEnd) {
          this.managementNotificationForm
            .get('wednesdayendform')
            .setValue(wedEnd);
          if (this.schedule.wedEnabled)
            this.managementNotificationForm.controls[
              'wednesdayendform'
            ].enable();
        }
      }
      if (this.schedule?.thuEnabled)
        this.managementNotificationForm
          .get('thursdaycheckboxform')
          .setValue(this.schedule.thuEnabled);
      if (this.schedule?.thuStartTime) {
        const thuStart = this.TwelveHoursTimeFormat(this.schedule.thuStartTime);
        if (thuStart) {
          this.managementNotificationForm
            .get('thursdaystartform')
            .setValue(thuStart);
          if (this.schedule.thuEnabled)
            this.managementNotificationForm.controls[
              'thursdaystartform'
            ].enable();
        }
      }
      if (this.schedule?.thuEndTime) {
        const thuEnd = this.TwelveHoursTimeFormat(this.schedule.thuEndTime);
        if (thuEnd) {
          this.managementNotificationForm
            .get('thursdayendform')
            .setValue(thuEnd);
          if (this.schedule.thuEnabled)
            this.managementNotificationForm.controls[
              'thursdayendform'
            ].enable();
        }
      }
      if (this.schedule?.friEnabled)
        this.managementNotificationForm
          .get('fridaycheckboxform')
          .setValue(this.schedule.friEnabled);
      if (this.schedule?.friStartTime) {
        const friStart = this.TwelveHoursTimeFormat(this.schedule.friStartTime);
        if (friStart) {
          this.managementNotificationForm
            .get('fridaystartform')
            .setValue(friStart);
          if (this.schedule.friEnabled)
            this.managementNotificationForm.controls[
              'fridaystartform'
            ].enable();
        }
      }
      if (this.schedule?.friEndTime) {
        const friEnd = this.TwelveHoursTimeFormat(this.schedule.friEndTime);
        if (friEnd) {
          this.managementNotificationForm.get('fridayendform').setValue(friEnd);
          if (this.schedule.friEnabled)
            this.managementNotificationForm.controls['fridayendform'].enable();
        }
      }
      if (this.schedule?.satEnabled)
        this.managementNotificationForm
          .get('saturdaycheckboxform')
          .setValue(this.schedule.satEnabled);
      if (this.schedule?.satStartTime) {
        const satStart = this.TwelveHoursTimeFormat(this.schedule.satStartTime);
        if (satStart) {
          this.managementNotificationForm
            .get('saturdaystartform')
            .setValue(satStart);
          if (this.schedule.satEnabled)
            this.managementNotificationForm.controls[
              'saturdaystartform'
            ].enable();
        }
      }
      if (this.schedule?.satEndTime) {
        const satEnd = this.TwelveHoursTimeFormat(this.schedule.satEndTime);
        if (satEnd) {
          this.managementNotificationForm
            .get('saturdayendform')
            .setValue(satEnd);
          if (this.schedule.satEnabled)
            this.managementNotificationForm.controls[
              'saturdayendform'
            ].enable();
        }
      }
      if (this.schedule?.sunEnabled)
        this.managementNotificationForm
          .get('sundaycheckboxform')
          .setValue(this.schedule.sunEnabled);
      if (this.schedule?.sunStartTime) {
        const sundayStart = this.TwelveHoursTimeFormat(
          this.schedule.sunStartTime
        );
        if (sundayStart) {
          this.managementNotificationForm
            .get('sundaystartform')
            .setValue(sundayStart);
          if (this.schedule.sunEnabled)
            this.managementNotificationForm.controls[
              'sundaystartform'
            ].enable();
        }
      }
      if (this.schedule?.sunEndTime) {
        const sundayEnd = this.TwelveHoursTimeFormat(this.schedule.sunEndTime);
        if (sundayEnd) {
          this.managementNotificationForm
            .get('sundayendform')
            .setValue(sundayEnd);
          if (this.schedule.sunEnabled)
            this.managementNotificationForm.controls['sundayendform'].enable();
        }
      }
    } else this.submitDisabled = false;
  }

  insertTimingColon(text: string, event: any) {
    this.submitDisabled = false;
    var key = event.keyCode || event.which;
    var inputValue = this.managementNotificationForm.get(text).value;
    if (inputValue != null && inputValue != '') {
      if (inputValue.trim().length == 2 && key !== 8) {
        inputValue = inputValue + ':';
        this.managementNotificationForm.get(text).setValue(inputValue);
      }
      if (inputValue.trim().length == 5 && key !== 8) {
        inputValue = inputValue + ' ';
        this.managementNotificationForm.get(text).setValue(inputValue);
      }
    }
  }

  insertMobileDash(text: string, event: any) {
    this.submitDisabled = false;
    var key = event.keyCode || event.which;

    if (key > 47 && key < 58) {
      var inputValue = this.managementNotificationForm.get(text).value;
      if (inputValue != null && inputValue != '') {
        if (inputValue.trim().length == 3 && key !== 8) {
          inputValue = inputValue + '-';
          this.managementNotificationForm.get(text).setValue(inputValue);
        }
        if (inputValue.trim().length == 7 && key !== 8) {
          inputValue = inputValue + '-';
          this.managementNotificationForm.get(text).setValue(inputValue);
        }
      }
    } else {
      return false;
    }
    return true;
  }

  formatPhoneNumber(phoneNumberString: string): string {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  }

  TwelveHoursTimeFormat(timeFormat: string): string {
    this.timerfix = timeFormat;

    this.timerfix = this.timerfix
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [this.timerfix];

    if (this.timerfix.length > 1) {
      // If time format correct
      this.timerfix = this.timerfix.slice(1); // Remove full string match value
      this.timerfix[5] = +this.timerfix[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      this.timerfix[0] = +this.timerfix[0] % 12 || 12; // Adjust hours
    }
    const timeFormated = this.timerfix.join('');
    console.log(this.timerfix.join(''));
    return timeFormated;
  }
  UpdateSubmitButtonDisable(e: any) {
    this.submitDisabled =
      this.schedule && this.schedule.timezone == e.value ? true : false;
  }
}
