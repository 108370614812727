export interface Alert {
  claimId: string;
  message: string;
  date: string;
}

export class SaveAlertActionRequest {
  alertMasterUid: number;
  action: string;
  sourceSystem: string;
  claimNumber: string;
  claimId: string;
  item?: any;
  type?: AlertActionType;
  fdaDate?: string;
  hoursWorked?: string;
  ldwDate?: string;
  lob?: string;
  userOwns?: boolean;
  NoteHeader?: string;
  examinerId?: string;

  constructor(values: SaveAlertActionRequest) {
    Object.assign(this, values);
  }
}

export type AlertActionType =
  | 'NewClaim'
  | 'Notification'
  | 'Rtw'
  | 'CommPreference';
