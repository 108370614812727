import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section',
  template: `
    <div
      class="tw-flex tw-flex-wrap tw-max-w-screen-xl"
      title=""
      [ngClass]="IsSmallBox ? 'page-container':'page-container-mobile'"
    >
      <ng-content class="tw-w-full"></ng-content>
    </div>
  `,
  styles: [
    `
      .page-container {
        margin: auto;
        padding: 20px;
      }
      @media (max-width: 480px) {
        .page-container {
          margin: 0px;
          padding: 4px;
        }
        .page-container-mobile {
          margin: 0px;
          padding: 4px;
        }
      }
    `,
  ],
})
export class SectionComponent {
  @Input()
  IsSmallBox : boolean = true;
}
