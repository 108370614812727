import { Component, Input } from '@angular/core';
import { OmniDashboard } from 'src/app/my-sedgwick';

@Component({
  selector: 'app-new-claims-card',
  template: `
    <app-panel
      title="New Claims"
      [labelTemplate]="details"
      [headerLevel]="2"
      [largeHeader]="false"
      class="tw-cursor-pointer"
      routerLink="/summary/new-claims"
      [canExpand]="false"
      tabindex="-1"
    >
      <ng-template #details>
        <div class="tw-w-full tw-flex tw-justify-end">
          <a
            class="tw-flex tw-items-center"
            *ngIf="omniDashboard"
            aria-label="Go to New claims details page"
            routerLink="/summary/new-claims"
          >
            <div class="app-pr1">DETAILS</div>
          </a>
        </div>
      </ng-template>
      <div class="container">
        <app-loading-panel
          [error]="error"
          [state]="omniDashboard ? 'Loaded' : 'Loading'"
          loadingStyle="Dot"
        >
          <ng-template #content>
            <div class="tw-flex tw-justify-center tw-items-center app-pt3">
              <div class="tw-text-center tw-font-bold">
                <div class="tw-text-[18px] tw--mb-9">TODAY</div>
                <div class="tw-text-[140px] app-color-sedgwick-blue">
                  {{ omniDashboard.newClaimsToday }}
                </div>
              </div>
            </div>
            <div class="tw-flex tw-justify-center tw-items-center app-pb3 ">
              <div class="tw-text-center tw-font-bold app-pr2">
                <div class="tw-text-[13px] tw--mb-3">LAST 7 DAYS</div>
                <div class="tw-text-[40px] app-color-sedgwick-blue">
                  {{ omniDashboard.newClaims7Days }}
                </div>
              </div>

              <div class="tw-text-center tw-font-bold">
                <div class="tw-text-[13px] tw--mb-3">LAST 30 DAYS</div>
                <div class="tw-text-[40px] app-color-sedgwick-blue">
                  {{ omniDashboard.newClaims30Days }}
                </div>
              </div>
            </div>
          </ng-template>
        </app-loading-panel>
      </div>
    </app-panel>
  `,
  styles: [
    `
      .container {
        height: 345px;
      }
    `,
  ],
})
export class NewClaimsCardComponent {
  @Input()
  omniDashboard: OmniDashboard;

  @Input()
  error: any;
}
