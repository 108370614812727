import { AbstractControl, ValidationErrors } from '@angular/forms';

export function alphanumericValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (/^[A-Za-z0-9]*$/.test(value)) {
    return null;
  } else {
    return { alpha: 'Error : Letters and digits only' };
  }
}
