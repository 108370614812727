import { Component } from '@angular/core';

@Component({
  selector: 'app-auditor-dashboard',
  template: ` <app-search-page></app-search-page> `,
  styles: [``],
})
export class AuditorDashboardPageComponent {
  constructor() {}
}
