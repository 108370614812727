import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Alert } from 'src/app/shared';

@Component({
  selector: 'app-notification',
  template: `
    <app-list
      [dataSource]="notifications"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      [tableAriaLabel]="'Notificationss'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimNumber"
            label="Claim"
            class="tw-w-full md:tw-w-[30%]"
            [names]="['claimDescription']"
          ></app-list-header>
          <app-list-header
            name="lastName"
            label="Name"
            class="tw-w-full md:tw-w-[15%]"
            [names]="['firstName', 'empId']"
          ></app-list-header>
          <app-list-header
            name="message"
            label="Message"
            class="tw-w-full md:tw-w-[30%]"
          ></app-list-header>
          <app-list-header
            name="date"
            label="Date"
            filterType="Date"
            class="tw-w-full md:tw-w-[15%]"
          ></app-list-header>
          <app-list-header
            label="Dismiss"
            readOnly
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[30%]">
          <app-value label="Claim" layout="row-xs" [tabIndex]="0">
            <span value>
              <app-claim-label [claim]="element" layout="row"></app-claim-label>
            </span>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value label="Name" layout="row-xs" [tabIndex]="0">
            <span value
              ><app-claimant-label [claimant]="element"></app-claimant-label
            ></span>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[30%]">
          <app-value label="Message" layout="col-xs" [tabIndex]="0">
            <div value class="message">{{ element.message }}</div>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Date"
            [tabIndex]="0"
            [value]="element.date | format : 'date'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%]">
          <app-icon-button
            icon="highlight_off"
            tabindex="0"
            role="button"
            label="Dismiss"
            (click)="dismiss()"
            (keypress.enter)="dismiss()"
            [attr.aria-label]="
              'Dismiss notification for claim - ' + element.claimLabel
            "
          ></app-icon-button>
        </td>
      </tr>
    </ng-template>
  `,
  styles: [],
})
export class NotificationComponent {
  @Input()
  notifications: Alert[];

  constructor(public dialog: MatDialog) {}
  dismiss() {}
}
