import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  NG_VALIDATORS,
  AbstractControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ErrorMessageLogicService } from 'src/app/shared/services/error-message-logic.service';
import { FormValidatorsService } from 'src/app/shared/services/form-validators.service';

export interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-password',
  template: `<ng-container [formGroup]="passwordGroup" role="none">
    <div class="tw-w-full" id="PasswordComp">
      <ul role="none" class="md:tw-w-[60%]">
        <li role="none">
          <mat-form-field [@.disabled]="animationsDisabled">
            <mat-label>Old password</mat-label>
            <input
              matInput
              required
              autocomplete="old-password"
              id="oldPassword"
              [type]="showPassword ? 'password' : 'text'"
              formControlName="oldPassword"
              [formGroup]="passwordGroup"
              [attr.aria-label]="oldPasswordAriaLabel"
              ngDefaultControl
              fxFlex="33"
              fxFlex.lt-md="100"
              maxlength="16"
            />
            <mat-error
              class="error-message-style"
              *ngIf="oldPassword.hasError('required')"
            >
              <span class="error-message">
                Error : Please type old password</span
              >
            </mat-error>
            <mat-error
              class="error-message-style"
              *ngIf="oldPassword.hasError('minlength')"
            >
              <span class="error-message">
                Error : Please enter at least 8 characters</span
              >
            </mat-error>
            <!-- <mat-error
              class="error-message-style"
              *ngIf="oldPassword.hasError('pattern')"
            >
              <span class="error-message">
                The password doesn't meet the requirements</span
              >
            </mat-error> -->
            <mat-error
              class="error-message-style"
              *ngIf="oldPassword.hasError('maxlength')"
            >
              <span class="error-message">
                The maximum length for this field is
                {{ oldPassword.errors['maxlength'].requiredLength }}
                characters.</span
              >
            </mat-error>
          </mat-form-field>

          <button
            *ngIf="showEyeIcon"
            (click)="showPasswordIcon()"
            mat-icon-button
            matSuffix
            style="margin-left: -6%;"
            [attr.aria-label]="'Hide Answer'"
            [attr.aria-pressed]="showPassword"
          >
            <mat-icon style="font-size: 14px!important;">{{
              showPassword ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </button>
        </li>
        <li role="none">
          <mat-form-field [@.disabled]="animationsDisabled">
            <mat-label>New password</mat-label>
            <input
              matInput
              required
              formControlName="newPassword"
              [formGroup]="passwordGroup"
              label="New Password"
              autocomplete="new-password"
              id="newPassword"
              [type]="showPassword ? 'password' : 'text'"
              ngDefaultControl
              fxFlex="33"
              fxFlex.lt-md="100"
              maxlength="16"
              [attr.aria-label]="newPasswordAriaLabel"
              [ngClass]="{
                'is-invalid': showErrorField('newPassword')
              }"
            />

            <mat-error
              class="error-message-style"
              *ngIf="newPassword.hasError('required')"
            >
              <span class="error-message">
                Error : Please type New password</span
              >
            </mat-error>
            <mat-error
              class="error-message-style"
              *ngIf="newPassword.hasError('pattern')"
            >
              <span class="error-message">
                The password doesn't meet the requirements</span
              >
            </mat-error>
            <mat-error
              class="error-message-style"
              *ngIf="newPassword.hasError('minlength')"
            >
              <span class="error-message">
                Error : Please enter at least 8 characters</span
              >
            </mat-error>
            <mat-error
              class="error-message-style"
              *ngIf="newPassword.hasError('maxlength')"
            >
              <span class="error-message">
                The maximum length for this field is
                {{ newPassword.errors['maxlength'].requiredLength }}
                characters.</span
              >
            </mat-error>
          </mat-form-field>

          <button
            *ngIf="showEyeIcon"
            (click)="showPasswordIcon()"
            mat-icon-button
            matSuffix
            style="margin-left: -6%;"
            [attr.aria-label]="'Hide Answer'"
            [attr.aria-pressed]="showPassword"
          >
            <mat-icon style="font-size: 14px!important;">{{
              showPassword ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </button>
        </li>
        <li role="none">
          <mat-form-field [@.disabled]="animationsDisabled">
            <mat-label>Retype New Password</mat-label>
            <input
              type="text"
              matInput
              required
              formControlName="confirmPassword"
              [formGroup]="passwordGroup"
              label="Retype New Password"
              autocomplete="retype new-password"
              id="confirmPassword"
              [type]="showPassword ? 'password' : 'text'"
              ngDefaultControl
              fxFlex="33"
              fxFlex.lt-md="100"
              maxlength="16"
              [attr.aria-label]="retypePasswordAriaLabel"
            />
            <mat-error
              class="error-message-style"
              *ngIf="passwordGroup.get('confirmPassword').hasError('required')"
            >
              <span class="error-message"
                >Error : Please re-type new password</span
              >
            </mat-error>

            <mat-error
              class="error-message-style"
              *ngIf="
                passwordGroup.get('confirmPassword').hasError('not_matching')
              "
            >
              <span class="error-message">Error : Passwords Must Match</span>
            </mat-error>
          </mat-form-field>

          <button
            *ngIf="showEyeIcon"
            (click)="showPasswordIcon()"
            mat-icon-button
            matSuffix
            style="margin-left:-6%"
            [attr.aria-label]="'Hide Answer'"
            [attr.aria-pressed]="showPassword"
          >
            <mat-icon style="font-size: 14px!important;">{{
              showPassword ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </button>
        </li>
      </ul>
    </div>
    <div class="md:tw-w-[60%] tw-w-full">
      <div dir="ltr" style="float: right">
        <mat-checkbox
          color="primary"
          labelPosition="after"
          aria-label="Show Passwords"
          (change)="showHidePassword()"
        ></mat-checkbox>
        <span style="color: #67707A Slate 300"> Show Passwords </span>
      </div>
    </div>
  </ng-container>`,
  styles: [
    `
      #PasswordComp ::ng-deep .mat-mdc-form-field-error-wrapper {
        margin-top: -24px !important;
        position: inherit !important;
        margin-bottom: 7px !important;
      }

      .input {
        color: #67707a;
        border-color: #67707a;
      }
      .input:focus {
        color: #171c20;
        border: #007dbc;
      }

      .input:hover {
        color: #67707a;
        border: #3f464d;
      }

      label {
        margin-left: 3%;
      }

      .mat-mdc-form-field {
        width: 350px !important;
      }
    `,
  ],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
  ],
})
export class PasswordComponent implements ControlValueAccessor, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() parentFormGroupName: string;
  showPassword: boolean = true;
  @Input() oldPasswordText: string = 'Old Password:';
  @Input() newPasswordText: string = 'Password:';
  @Input() retypePasswordText: string = 'Retype Password:';
  @Input() showEyeIcon: boolean = false;

  oldPasswordAriaLabel: string = this.oldPasswordText.replace(':', '');
  newPasswordAriaLabel: string = this.newPasswordText.replace(':', '');
  retypePasswordAriaLabel: string = this.retypePasswordText.replace(':', '');
  @Output() private onChangeShowHide = new EventEmitter<any>();
  subscriptions: Subscription[] = [];
  passwordGroup: UntypedFormGroup;
  animationsDisabled: true;
  hidePwd: true;

  get value(): PasswordFormValues {
    return this.passwordGroup.value;
  }

  set value(value: PasswordFormValues) {
    if (this.passwordGroup.value != value) {
      this.passwordGroup.setValue(value);
    }
    this.onChange(value);
    this.onTouched();
  }

  get newPassword() {
    return this.passwordGroup.get('newPassword');
  }

  get oldPassword() {
    return this.passwordGroup.get('oldPassword');
  }

  get confirmPassword() {
    return this.passwordGroup.get('confirmPassword');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private formValidator: FormValidatorsService,
    private errorService: ErrorMessageLogicService
  ) {
    this.passwordGroup = this.buildPasswordGroup();
    this.subscriptions.push(
      this.passwordGroup.valueChanges
        .pipe(
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
          )
        )
        .subscribe((value) => {
          this.onChange(value);
          this.onTouched();
          this.passwordGroup.updateValueAndValidity();
          this.parentForm
            .get(this.parentFormGroupName)
            .setValue(this.passwordGroup);
          this.parentForm.updateValueAndValidity();
        })
    );
  }

  buildPasswordGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        oldPassword: [
          '',
          {
            validators: [
              Validators.minLength(8),
              Validators.maxLength(16),
              Validators.required,
              // Validators.pattern(
              //   '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{0,16}$'
              // ),
            ],
            updateOn: 'blur',
          },
        ],
        newPassword: [
          '',
          {
            validators: [
              Validators.minLength(8),
              Validators.maxLength(16),
              Validators.required,
              Validators.pattern(
                '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{0,16}$'
              ),
            ],
            updateOn: 'blur',
          },
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ],
        ],
        updateOn: 'submit',
      },
      { validator: CustomValidators.MatchingPasswords }
    );
  }

  showPasswordIcon(): void {
    this.showPassword = !this.showPassword;
  }

  showErrorField(formControl: string): boolean {
    return this.errorService.showErrorMessage(this.passwordGroup, formControl);
  }

  showHidePassword(): void {
    this.showPassword = this.showPassword ? false : true;
    this.onChangeShowHide.emit(this.showPassword);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.passwordGroup.reset;
    }
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.passwordGroup.valid ? null : { passwords: { valid: false } };
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}

export class CustomValidators {
  static MatchingPasswords(control: AbstractControl) {
    const password = control.get('newPassword').value;
    const confirmPassword = control.get('confirmPassword').value;
    const currentErrors = control.get('confirmPassword').errors;
    const confirmControl = control.get('confirmPassword');

    if (compare(password, confirmPassword)) {
      confirmControl.setErrors({ ...currentErrors, not_matching: true });
    } else {
      confirmControl.setErrors(currentErrors);
    }
  }
}

function compare(password: string, confirmPassword: string) {
  return password !== confirmPassword && confirmPassword !== '';
}
