import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { SearchResult, deepCopy } from 'src/app/shared';
import { AbsenceService, Excel, ExportRequest, UserRoleService } from '../..';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EncryptionService } from '../../services/encryption.service';

@Component({
  selector: 'app-employee-search-list',
  template: `
    <app-list
      [dataSource]="searchResults"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      loadingMessage=""
      noDataIcon="search"
      noDataMessage="No results"
      noDataThinMessage="There are no results that match the search"
      [pageSizeOptions]="[5, 10, 25]"
      [pageSize]="10"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these employees as a XLSX file"
      (export)="_export()"
      [tableAriaLabel]="'Search Results'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[25%]"
            [names]="['name', 'empId']"
          ></app-list-header>
          <app-list-header
            name="submit"
            label="Submit Absence"
            class="tw-w-full md:tw-w-[25%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element role="rowgroup">
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[25%]">
          <a
            (click)="
              openClient(
                element.hrMasterUid,
                element.empUnum,
                element.name,
                element.employeeId
              )
            "
          >
            <app-value
              class="tw-w-full"
              [value]="element.name + ' - ' + element.employeeId"
            >
            </app-value>
          </a>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <button
            mat-menu-item
            (click)="
              submitAbsence(
                element.employeeId,
                element.name,
                element.hrMasterUid,
                element.empUnum
              )
            "
          >
            <mat-icon color="accent">check</mat-icon>
          </button>
        </td>
      </tr>
    </ng-template>

    <ng-template #confirmDialog>
      <app-absence-add-dialog></app-absence-add-dialog>
    </ng-template>
  `,
  styles: ``,
})
export class EmployeeSearchListComponent {
  @Input()
  searchResults: SearchResult[];

  @Input()
  excelName: string = 'Employee Search.xlsx';

  @Input()
  sheetName: string = 'Employee Search';

  @Output()
  export = new EventEmitter<ExportRequest>();

  @ViewChild('confirmDialog') confirmDialogRef: TemplateRef<any>;

  claimantLabelLink = false;

  constructor(
    private excel: Excel,
    public userRole: UserRoleService,
    private dialog: MatDialog,
    private router: Router,
    private encrypt: EncryptionService,
    public absenceService: AbsenceService
  ) {
    this.claimantLabelLink = this.userRole.manager || this.userRole.auditor;
  }

  submitAbsence(empId: string, empName: string, uid: string, empUnum: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      employeeId: empId,
      employeeName: empName,
      employeeUid: uid,
      empUnum: empUnum,
    };
    this.dialog.open(this.confirmDialogRef, dialogConfig);
  }

  openClient(
    hrMasterUid: string,
    empUnum: string,
    name: string,
    empId: string
  ) {
    if (this.userRole.auditor) {
      const encryptedEmpUnum = this.encrypt.encrypt(hrMasterUid);
      this.router.navigate(['/dashboard'], {
        queryParams: { emp: encryptedEmpUnum },
      });
    } else {
      this.absenceService.navigateToAbsences(empUnum, name, empId);
    }
  }

  _export() {
    const request = new ExportRequest();
    const claimRows = deepCopy(this.searchResults);
    request.fileName = this.excelName;
    request.sheets = [
      {
        name: this.sheetName,
        rows: claimRows,
        headers: [
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'employeeId' },
        ],
      },
    ];

    this.export.emit(request);
    this.excel.export(request);
  }
}
