import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestrictionTable } from '../../models/Restrictions';
import { ReturnToWorkEditComponent } from './return-to-work-edit.component';

@Component({
  selector: 'app-submit-return-to-work-dialog',
  template: `
  <div id="restrictionpnl">
    <app-dialog
      title="Restrictions"
      ariaLabel="Restrictions dialog"
      confirmLabel="Close"
      [showCancel]="false"
      (confirmEvent)="close()">
    <app-restrictions [restrictionTable]="dialogData"></app-restrictions>
    </app-dialog>
  </div>
  `,
  styles: [`
      #restrictionpnl ::ng-deep .mat-accent
      {
        background-color:#fff !important;
        color:#3F464D!important;
        margin-left:3px;
      }
      #restrictionpnl ::ng-deep .mat-accent:hover
      {
        background-color:#F1F4F7 !important;
      }

      #restrictionpnl ::ng-deep .mat-mdc-unelevated-button
      {
        background-color:#007DBC !important;
        color:#fff!important;
      }
      #restrictionpnl ::ng-deep .mat-mdc-unelevated-button:hover
      {
        background-color:#035E81 !important;
      }
    `
  ]
})
export class RestrictionsDialogComponent {
  dialogData: RestrictionTable[];

  constructor(public dialogRef: MatDialogRef<RestrictionsDialogComponent>,
            @Inject(MAT_DIALOG_DATA) public data: any)
    {
        this.dialogData = data != null ? data.restrctions : null;
    }

  close() {
    this.dialogRef.close();
  }

}
