import {
  alphaValidator,
  alphanumdashValidator,
  alphanumericValidator,
  arrayRequiredValidator,
  minDateValidator,
} from '..';
import { dateOrderValidator } from './date-order.validator';
import { futureDateValidator } from './future-date.validator';
import { existingDateValidator } from './existing-date.validator';

export class ValidatorsEx {
  static readonly arrayRequired = arrayRequiredValidator;
  static readonly dateOrder = dateOrderValidator;
  static readonly minDate = minDateValidator;
  static readonly futureDate = futureDateValidator;
  static readonly existingDate = existingDateValidator;
  static readonly alpha = alphaValidator;
  static readonly alphanumeric = alphanumericValidator;
  static readonly alphanumdash = alphanumdashValidator;
}
