import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { HistoryInfo } from '../../models/historyinfo';
import { ConfigurationService } from '../../services/configuration.service';
import { LogService } from '../../services/log.service';
import { RecentClaimService } from '../../services/recent-claim.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { UserDetails } from '../../services/userdetails.service';

@Component({
  selector: 'app-claim-history',
  template: `
    <app-panel
      title="History"
      [headerLevel]="overlay ? 2 : 1"
      [largeHeader]="!overlay"
      [minHeight]="minHeightPnl"
      [canExpand]="false"
      [attr.role]="overlay ? 'dialog' : 'null'"
      [attr.aria-modal]="overlay ? true : false"
      [ariaLabel]="'History'"
    >
      <app-loading-panel [state]="isLoading ? 'Loading' : 'Loaded'">
        <ng-template #content> </ng-template>
      </app-loading-panel>
      <app-list
        [dataSource]="claims"
        [template]="row"
        class="app-pt2"
        [filterEnabled]="false"
        [ShowSort]="!overlay"
        loadingMessage=""
        [noDataIcon]="isLoading ? '' : 'history'"
        [noDataMessage]="
          isLoading ? '' : '<br><strong>No claims have been viewed.</strong>'
        "
        [loaded]="isLoading"
        [paginatorEnabled]="false"
        [pageSize]="overlay ? 3 : 25"
        [toastErrorMessage]="toasErrorMsg"
        [displayErrorToastMsg]="displayErrorToastMsg"
        [overlay]="overlay"
        [DefaultSortColumn]="isSupervisorRole ? 'lastVisited' : ''"
        [DefaultSortDir]="isSupervisorRole ? 'desc' : ''"
        [tableAriaLabel]="'Visited claims history'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full">
            <app-list-header
              name="description"
              label="Claim"
              class="tw-w-full md:tw-w-[40%]"
              [names]="['claimDescription']"
              [sortEnabled]="!overlay"
            ></app-list-header>
            <app-list-header
              name="name"
              label="Name"
              class="tw-w-full md:tw-w-[40%]"
              [names]="['name', 'employeeID']"
              [sortEnabled]="!overlay"
            ></app-list-header>
            <app-list-header
              name="lastVisited"
              label="Date Viewed"
              class="tw-w-full md:tw-w-[20%]"
              [sortEnabled]="!overlay"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>

      <ng-template #row let-element role="rowgroup">
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <td class="tw-w-full md:tw-w-[40%]">
            <app-value label="Claim" layout="row-xs">
              <app-claim-label
                value
                [claim]="element"
                layout="row"
                (displayWatchListErrorMsg)="displayWatchListErrorMsg($event)"
              ></app-claim-label>
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[40%]">
            <app-value label="Name" layout="row-xs">
              <app-claimant-label
                value
                [claimant]="element"
                [disabled]="!userRole.manager"
              ></app-claimant-label>
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[20%]">
            <app-value
              label="Date"
              [tabindex]="element.lastVisited ? '0' : '1'"
              [value]="element.lastVisited | format : 'date'"
              layout="row-xs"
              tabindex="0"
            >
            </app-value>
          </td>
        </tr>
      </ng-template>
      <a routerLink="history" *ngIf="overlay && claims && claims.length > 0"
        >View All</a
      >
    </app-panel>
  `,
  styles: [``],
})
export class ClaimHistoryComponent implements OnInit {
  @Input()
  showTitle = true;

  @Input()
  overlay = false;
  isLoading: boolean = true;

  claims: HistoryInfo[];
  isLoaded: boolean = false;

  minHeightPnl: string = '70px';
  displayErrorToastMsg: boolean = false;
  toasErrorMsg: string;
  isSupervisorRole: boolean = false;
  isController: boolean = false;
  mgrAction: UserActions | null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public session: Session,
    public userdetails: UserDetails,
    public routerService: RouterService,
    private configurationService: ConfigurationService,
    private recentClaimService: RecentClaimService,
    public userRole: UserRoleService,
    public logService: LogService
  ) {}

  async ngOnInit() {
    this.isController = this.userRole.controller;
    this.isSupervisorRole = this.userRole.supervisor;

    this.isLoading = true;
    this.claims = await this.recentClaimService.getToHistory();
    this.isLoading = false;
    this.LogFeature();
    if (this.claims == null) {
      this.isLoaded = true;
    }
    this.mgrAction = this.isSupervisorRole ? UserActions.ViewOwnMgrDb : null;
  }

  LogFeature() {
    const sectionName = this.overlay
      ? 'Recent Claims' + ' - ' + 'History - Overlay'
      : 'Recent Claims' + ' - ' + 'History';
    this.logService.LogFeature(undefined, 'History', sectionName);
  }

  displayWatchListErrorMsg(event: any) {
    this.displayErrorToastMsg = true;
    this.toasErrorMsg =
      this.recentClaimService.displayWatchListErrorMsg1(event);
  }
}
