import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      //avoid validation if empty, we are not testing for required here        return null;
    }
    const errors: ValidationErrors = {};
    const startDate = new Date(control.value);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 731);
    if (startDate > tomorrow) {
      errors['futureDate'] = `Future date is invalid`;
    }
    return Object.keys(errors).length ? errors : null;
  };
}
