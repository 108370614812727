import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toast',
  template: `
    <div class="tw-flex">
      <div
        class="toast-container tw-grow-0 app-mb2 tw-flex"
        [ngClass]="toastClass"
        [style.margin-bottom]="disableMarginBottom ? 0 : 40"
      >
        <span class="left-border tw-justify-center" *ngIf="toastIcon">
          <mat-icon aria-hidden="true" tabindex="-1">{{ toastIcon }}</mat-icon>
        </span>

        <div class="toast-text tw-grow">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .toast-container {
        border-radius: 4px;
      }
      .toast-text {
        padding: 10px 10px 10px 0px;
      }
      .left-border {
        border-radius: 4px 0px 0px 4px;
        min-width: 40px;
        display: flex;
        align-items: center;
      }

      .toast-warning {
        background: #edaa2c;
        color: #000000;
      }

      .toast-success {
        background: var(--color-green);
        color: #ffffff;
      }

      .toast-info {
        background: var(--color-sedgwick-blue);
        color: #ffffff;
      }

      .toast-error {
        background: var(--color-red);
        color: #ffffff;
      }
    `,
  ],
})
export class ToastComponent {
  @Input() type: ToastType;

  @Input() icon: string;

  @Input() disableMarginBottom: boolean;

  get toastClass(): string {
    switch (this.type) {
      case 'Error':
        return 'toast-error';
      case 'Success':
        return 'toast-success';
      case 'Info':
        return 'toast-info';
      case 'Warning':
        return 'toast-warning';
      default:
        return 'toast-info';
    }
  }

  get toastIcon(): string {
    if (this.icon) {
      return this.icon;
    }

    switch (this.type) {
      case 'Error':
        return 'cancel';
      case 'Success':
        return 'thumb_up';
      case 'Info':
        return 'info';
      case 'Warning':
        return 'warning';
      default:
        return 'info';
    }
  }
}

export type ToastType = 'Info' | 'Success' | 'Warning' | 'Error';
