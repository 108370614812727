import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchResult } from '../../../shared';

@Component({
  selector: 'app-action-link',
  template: `
    <div class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
      <div class="" style="text-align:center" *ngIf="imageUrl">
        <img
          [src]="imageUrl"
          [attr.width]="imageWidth ? imageWidth : null"
          [attr.height]="imageHeight ? imageHeight : null"
          loading="lazy"
        />
      </div>
      <div class="tw-w-1/5" style="text-align:center" *ngIf="icon">
        <mat-icon class="icon">{{ icon }}</mat-icon>
      </div>
      <div class="tw-w-1/5" style="text-align:center" *ngIf="clickableicon">
        <button
          tabindex="0"
          (keydown.enter)="onLinkClick()"
          [attr.aria-label]="title"
        >
          <a>
            <mat-icon class="icon">{{ clickableicon }}</mat-icon>
          </a>
        </button>
      </div>
      <div class="tw-w-4/5 ">
        <div>
          <button
            tabindex="0"
            (keydown.enter)="onLinkClick()"
            [attr.aria-label]="title"
            (click)="onLinkClick()"
          >
            <b
              ><a>{{ title }}</a></b
            >
          </button>

          <div style="font-size:small;" *ngIf="helpText">
            {{ helpText }}
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .icon {
        font-size: 48px;
        width: 48px;
        height: 48px;
        vertical-align: center;
        color: var(--color-accent);
      }
    `,
  ],
})
export class ActionLinkComponent {
  @Input()
  imageUrl: string;

  @Input()
  icon: string;

  @Input()
  clickableicon: string;

  @Input()
  title: string;

  @Input()
  helpText: string;

  @Input()
  imageWidth: number;

  @Input()
  imageHeight: number;

  @Input()
  claim: SearchResult | null;

  @Output()
  linkClick = new EventEmitter();

  constructor() {}

  onLinkClick() {
    if (this.claim) {
      this.linkClick.emit(this.claim);
    } else {
      this.linkClick.emit();
    }
  }
}
