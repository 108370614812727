import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  template: `
    <span class="error tw-px-2 tw-py-0.5 tw-italic" *ngIf="_error">
      An error has occurred
    </span>
  `,
  styles: [
    `
      .error {
        background-color: var(--color-light-grey);
        color: var(--color-slate-600);
      }
    `,
  ],
})
export class ErrorComponent {
  _error: any = null;

  @Input()
  set error(value: any) {
    this._error = value?.rejection ?? value;
  }

  constructor() {}
}
