import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService, SnackbarType } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { SnackbarRef } from 'src/app/shared/services/snackbar.service';
import { User } from '../..';

@Component({
  selector: 'app-design-page',
  template: `
    <app-section>
      <div class="tw-grid tw-grid-cols-1">
        <app-panel title="Dismiss snackbar" class="tw-w-full ">
          <div class="tw-grid tw-grid-cols-1">
            <a (click)="showSnackbar()">Show basic snackbar</a>
            <a (click)="cancelSnackbar()">Cancel snackbar</a>
          </div>
        </app-panel>

        <app-panel title="Front end error" class="tw-w-full ">
          <div class="tw-grid tw-grid-cols-1">
            <a (click)="errorTest('js-error')">Throw JS error.</a>
            <a (click)="errorTest('http-error')">Throw http error.</a>
          </div>
        </app-panel>

        <app-panel title="HTTP Testing" class="tw-w-full ">
          <div class="tw-grid tw-grid-cols-1">
            <a (click)="httpTest('delete-cache')"
              >Deletes GetUser cache entry.</a
            >
            <a (click)="httpTest('single')"
              >Makes one GetUser request. Makes http request if cache is
              empty.</a
            >
            <a (click)="httpTest('parallel')"
              >Makes three GetUser requests in parallel. Should make one http
              request.</a
            >
            <a (click)="httpTest('refresh-cache')"
              >Makes one GetUser request with refreshCache=true. Should make one
              http request.
            </a>
            <a (click)="httpTest('text')"
              >Makes one GetUser request with responseType=text. Retrieves data
              as string rather than object.
            </a>
          </div>
        </app-panel>
      </div>
    </app-section>
  `,
  styles: [],
})
export class DesignPageComponent {
  snackbarRef: SnackbarRef;

  constructor(
    private http: Http,
    private router: Router,
    private snackbar: SnackbarService
  ) {
    if (window.location.hostname !== 'localhost') {
      this.router.navigate(['not-found']);
    }
  }

  async httpTest(test: string) {
    let user: User;
    if (test === 'delete-cache') {
      this.http.deleteCacheEntry('/api/user');
    } else if (test === 'single') {
      user = await this.http.get<User>('/api/user');
      this.log(user);
    } else if (test === 'parallel') {
      this.http.get<User>('/api/user').then((u1) => this.log(u1));
      this.http.get<User>('/api/user').then((u1) => this.log(u1));
      this.http.get<User>('/api/user').then((u1) => this.log(u1));
    } else if (test === 'refresh-cache') {
      user = await this.http.get<User>('/api/user', {
        refreshCache: true,
      });
    } else if (test === 'text') {
      const value = await this.http.get<string>('/api/user', {
        refreshCache: true,
        responseType: 'text',
      });
      this.log(value);
    }
  }

  async errorTest(test: string) {
    if (test === 'js-error') {
      throw new Error('test js error');
    } else if (test === 'http-error') {
      await this.http.get<string>('/api/does-not-exist');
    }
  }

  private log(message: any) {
    // eslint-disable-next-line no-console
    console.log(message);
  }

  showSnackbar() {
    this.snackbarRef = this.snackbar.show({
      message: 'Im basic',
      dismiss: true,
      delayMs: 1000,
      type: SnackbarType.Basic,
    });
  }

  cancelSnackbar() {
    this.snackbar.cancel(this.snackbarRef);
  }
}
