import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { ActivityService } from '../../services/activity.service';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-dismiss-dialog',
  template: `
    <div id="dismisspnl">
      <app-dialog
        title="Dismiss Item"
        ariaLabel="Dismiss item dialog"
        [btnConfirmAltText]="confirmAltText"
        [btnConfirmTitleText]="confirmTitleText"
        [btnCloseAltText]="closeAltText"
        [btnCloseTitleText]="closeTitleText"
        (confirmEvent)="submitAlertAction(saveAlertAction)"
        [showHeader]="true"
      >
        Press Confirm to dismiss this item.
      </app-dialog>
    </div>
  `,
  styles: [
    `
      #dismisspnl ::ng-deep .mat-accent {
        background-color: #fff !important;
        color: #3f464d !important;
        border: 1px solid #3f464d;
        margin-left: 3px;
      }
      #dismisspnl ::ng-deep .mat-accent:hover {
        background-color: #f1f4f7 !important;
      }
    `,
  ],
})
export class DismissDialogComponent {
  confirmAltText: string = 'Confirm';
  confirmTitleText: string = '';
  closeAltText: string = 'Cancel';
  closeTitleText: string = '';
  saveAlertAction: SaveAlertActionRequest;

  constructor(
    public dialogRef: MatDialogRef<DismissDialogComponent>,
    private activityService: ActivityService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.saveAlertAction = data != null ? data.saveAlertRequest : null;
  }

  close() {
    this.dialogRef.close('closed');
  }

  submitAlertAction(request: SaveAlertActionRequest) {
    this.activityService.saveAlertAction(request);
    this.activityService.alertSelected.emit(request.alertMasterUid.toString());
    this.dialogRef.close('dismissed');
    srSpeak('Item dismissed', 'polite');
  }
}
