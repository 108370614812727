import { Component, Input } from '@angular/core';
import { stripPeriod } from 'src/app/shared';
import { stripHtml } from 'src/app/shared/functions/strip-html';

@Component({
  selector: 'app-empty-state-label',
  template: `
    <div class="tw-flex tw-flex-col tw-items-center">
      <div *ngIf="icon">
        <mat-icon class="icon-md" [style.color]="iconColor">{{
          icon
        }}</mat-icon>
      </div>
      <div *ngIf="loader === 'Dot'" class="dot-loader"></div>
      <ng-container *ngIf="header && message; else smallHeader">
        <div class="tagline-header app-pb1">{{ header }}</div>
        <div class="tagline-message app-pb3" *ngIf="message">
          {{ message }}
        </div>
        <div class="tagline-message-second app-pb3" *ngIf="thinMessage">
          {{ thinMessage }}
        </div>
      </ng-container>
      <ng-template #smallHeader>
        <div class="tagline-header-sm app-pt1 app-pb3 word">
          {{ header }}{{ message }}
        </div>
        <div class="tagline-message-second" *ngIf="thinMessage">
          {{ thinMessage }}
        </div>
      </ng-template>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .icon-lg {
        font-size: 100px;
        width: 100px;
        height: 100px;
      }

      .icon-md {
        font-size: 60px;
        width: 60px;
        height: 60px;
      }

      .tagline-header {
        font-size: 18px;
        font-weight: bold;
        color: var(--color-slate);
      }

      .tagline-header-sm {
        font-size: 14px;
        font-weight: bold;
        color: var(--color-slate);
      }

      .tagline-message {
        color: var(--color-slate);
      }

      .tagline-message-second {
        color: var(--color-slate);
        font-weight: normal;
        position: relative;
        top: -20px;
      }

      .dot-loader {
        width: 50px;
        height: 24px;
        background: radial-gradient(circle closest-side, #67707a 90%, #0000) 0%
            50%,
          radial-gradient(circle closest-side, #67707a 90%, #0000) 50% 50%,
          radial-gradient(circle closest-side, #67707a 90%, #0000) 100% 50%;
        background-size: calc(100% / 3) 12px;
        background-repeat: no-repeat;
        animation: d3 1s infinite linear;
      }
      .word {
        white-space: pre-wrap;
        text-align: center;
      }
      @keyframes d3 {
        20% {
          background-position: 0% 0%, 50% 50%, 100% 50%;
        }
        40% {
          background-position: 0% 100%, 50% 0%, 100% 50%;
        }
        60% {
          background-position: 0% 50%, 50% 100%, 100% 0%;
        }
        80% {
          background-position: 0% 50%, 50% 50%, 100% 100%;
        }
      }
    `,
  ],
})
export class EmptyStateLabelComponent {
  @Input()
  icon: string;

  private _header: string;

  @Input()
  get header(): string {
    return this._header;
  }
  set header(value: string) {
    this._header = stripPeriod(stripHtml(value));
  }

  private _message: string;
  private _noDataThinMessage: string;

  @Input()
  get message(): string {
    return this._message;
  }

  @Input()
  get thinMessage(): string {
    return this._noDataThinMessage;
  }

  set message(value: string) {
    this._message = stripPeriod(stripHtml(value));
  }

  set thinMessage(value: string) {
    this._noDataThinMessage = stripPeriod(stripHtml(value));
  }

  @Input()
  iconColor = 'var(--color-slate)';

  @Input()
  loader: EmptyStateLoader;
}

export type EmptyStateLoader = 'Dot';
