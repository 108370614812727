import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-label',
  template: `
    <div>{{ fullName }}</div>
    <div class="empId">{{ empId }}</div>
  `,
  styles: [
    `
           .empId {
             font-size: 11px;
           }
         `,
  ],
})
export class EmployeeLabelComponent implements OnInit {
  fullName: string;

  empId: string;

  @Input()
  set name(value: string) {
    const items = value.split('-');
    this.fullName = items[0].trim();
    if (items.length === 2) {
      this.empId = items[1].trim();
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
