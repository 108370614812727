import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import {
  DirectDeposit,
  DirectDepostRequest,
  UpdateDirectDepositRequest,
} from '../models/directdeposit';
@Injectable({
  providedIn: 'root',
})
export class DirectDepositService {
  constructor(private http: Http) {}

  async getDirectDeposit(ddRequest: DirectDepostRequest) {
    return this.http.post<DirectDeposit>(
      `/api/managedirectdeposit/GetDirectDeposit`,
      ddRequest
    );
  }

  async updateDirectDeposit(ddRequest: UpdateDirectDepositRequest) {
    return this.http.post<DirectDeposit>(
      `/api/managedirectdeposit/UpdateDirectDeposit`,
      ddRequest
    );
  }

  async hasAccessDirectDeposit(ddRequest: DirectDepostRequest) {
    return this.http.post<boolean>(
      `/api/managedirectdeposit/HasAccessDirectDeposit`,
      ddRequest,
      {
        cache: true,
      }
    );
  }
}
