import { Component, Input, OnInit } from "@angular/core";
import { ClientMessage } from "src/app/shared/models/search-result";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: 'app-custom-message',
  template: `
  <p class="app-pb1  tw-w-full">
    <br>
    <b><div [innerHTML] = "clientMessage"></div></b>
  </p>`,
  styles: []
})

export class CustomMessageComponent implements OnInit {

  constructor(private utility: UtilityService){
  }
  @Input()
  listClientMessage? : ClientMessage[];

  clientMessage: string;

  @Input()
  keyForMessage: string;


  ngOnInit(): void {
      if (this.listClientMessage && this.listClientMessage.length > 0 && !this.utility.isNullOrEmpty(this.keyForMessage)) {
        this.clientMessage = this.listClientMessage.find(
          (x) => x.keyTab == this.keyForMessage
        )?.message;
      }

  }
}
