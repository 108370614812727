import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-http-error',
  template: `
    <app-toast type="Error" *ngIf="httpErrorResponse" class="tw-w-full">
      <ng-container *ngIf="httpErrorResponse.status === 400">
        <div class="tw-flex tw-flex-wrap tw-flex-col">
          <div class="tw-w-full">
            <span class="header-font">{{
              httpErrorResponse?.error?.title
            }}</span>
          </div>
          <div class="tw-w-full">
            <ul>
              <li *ngFor="let error of getErrors()">
                {{ error.key }}: {{ error.message }}
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="httpErrorResponse.status === 401">
        Unauthorized
      </ng-container>
      <ng-container *ngIf="httpErrorResponse.status >= 500">
        {{ httpErrorResponse | json }}
      </ng-container>
    </app-toast>
  `,
  styles: [
    `
           .header-font {
             font-size: 16px;
           }
         `,
  ],
})
export class HttpErrorComponent implements OnInit {
  @Input()
  httpErrorResponse: HttpErrorResponse;

  constructor() {}

  ngOnInit(): void {}

  getErrors(): ValidationError[] {
    let result: ValidationError[] = [];
    if (!this.httpErrorResponse) {
      return result;
    }

    let errors = this.httpErrorResponse.error.errors;

    for (const key in errors) {
      result.push(new ValidationError(key, errors[key][0]));
    }

    return result;
  }
}

class ValidationError {
  constructor(public key: string, public message: string) {}
}
