import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BaseComponent,
  CsvParams,
  CsvService,
  SearchResult,
  SnackbarService,
  SnackbarType,
  UserActionService,
} from 'src/app/shared';
import { ConfigurationService } from '../../services/configuration.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { WorkScheduleService } from '../../services/workschedule.service';
import { PtoInfoDetails } from '../../models/ptoInfoDetails';

@Component({
  selector: 'app-pto',
  template: `
    <div class="tw-w-full tw-flex tw-flex-wrap">
      <app-value
        label="{{ employeeAlias }} PTO as reported on"
        [value]="reportedDate | format : 'date'"
        layout="row"
        class="tw-w-full"
      ></app-value>

      <app-value
        label="PTO days reported"
        [value]="daysReported"
        layout="row"
        class="tw-w-full"
      ></app-value>

      <app-value
        label="PTO time available"
        [value]="availablePTO"
        layout="row"
        class="tw-w-full tw-pb-2"
      ></app-value>

      <app-action-row class="tw-w-full">
        <div left1>
          <app-button
            emphasis="High"
            icon="open_in_new"
            *ngIf="IsUpdatePto"
            (click)="openEditPto(editPto)"
            left1
            >Update PTO</app-button
          >
        </div>
        <div right1 class="tw-pr-2">
          <app-toast icon="info" type="Info"
            >It may take the {{ examinerLabel }} up to 24 hours to update the
            information.
          </app-toast>
        </div>
      </app-action-row>
      <!-- <div
        class="tw-w-full  app-pt2 tw-flex tw-justify-between tw-flex-wrap tw-gap-1"
      >
        <app-button
          emphasis="High"
          icon="open_in_new"
          (click)="openEditPto(editPto)"
          >Update PTO</app-button
        >
        <app-toast icon="info" type="Info"
          >It may take the examiner up to 24 hours to update the information.
        </app-toast>
      </div> -->

      <app-list
        [dataSource]="Ptos?.ptoInformation"
        [template]="row"
        [paginatorEnabled]="false"
        [exportEnabled]="true"
        exportButtonAriaLabel="Export and download these PTO records as a XLSX file"
        [fixScrollOverflowHeight]="'210px'"
        overflowHeight="190px"
        (export)="_exportData()"
        class="tw-w-full app-pr1 app-pb2"
        [noDataIcon]="'date_range'"
        [noDataMessage]="'No paid time off information available'"
        [tableAriaLabel]="'Paid time off'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full">
            <app-list-header
              name="ptoeffective"
              label="pto effective"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="ptoexpire"
              label="pto expire"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="paycyclestartdate"
              label="pay cycle start date"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="paycycleenddate"
              label="pay cycle end date"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="initialbalance"
              label="initial balance"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="wpptoapplied"
              label="wp pto applied"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="unpaidptoapplied"
              label="unpaid pto applied"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="ptobalance"
              label="pto balance"
              class="tw-w-[11%]"
            ></app-list-header>
            <app-list-header
              name="ptotype"
              label="pto type"
              class="tw-w-[11%]"
            ></app-list-header>
          </tr>
        </ng-container>
        <ng-template #row let-element>
          <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="PTO EFFECTIVE"
                layout="row-xs"
                [value]="element.ptoEffective | date : 'MM/dd/yyyy'"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="PTO EXPIRE"
                layout="row-xs"
                [value]="element.ptoExpire | date : 'MM/dd/yyyy'"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="PAY CYCLE START DATE"
                layout="row-xs"
                [value]="element.periodStart | date : 'MM/dd/yyyy'"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="PAY CYCLE END DATE"
                layout="row-xs"
                [value]="element.periodEnd | date : 'MM/dd/yyyy'"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="INITIAL BALANCE"
                layout="row-xs"
                [value]="element.initialBalance"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                class="tw-w-full md:tw-w-[11%]"
                label="WP PTO APPLIED"
                layout="row-xs"
                [value]="element.wpPtoApplied"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="UNPAID PTO APPLIED"
                layout="row-xs"
                [value]="element.unpaidPtoApplied"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="PTO BALANCE"
                layout="row-xs"
                [value]="element.ptoBalance"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[11%]">
              <app-value
                [tabindex]="0"
                label="PTO TYPE"
                layout="row-xs"
                [value]="element.ptoType"
              >
              </app-value>
            </td>
          </tr>
        </ng-template>
      </app-list>

      <ng-template #editPto>
        <app-dialog-container
          label="Submit PTO Update"
          [headerLevel]="2"
          [headerId]="'submitPTO'"
          aria-labelledby="'submitPTO'"
        >
          <form
            class="tw-max-w-md tw-flex tw-flex-wrap"
            [formGroup]="formGroup"
          >
            <div class="tw-w-full app-header-3 tw-py-2">
              Paid Time Off (PTO)
            </div>
            <div *ngIf="isDisplaySuccess" class="tw-w-full tw-py-2">
              <app-toast type="Success">
                Thank you for your update. A note will be sent to the
                {{ examinerLabel }} who will execute the change in our claims
                system. The {{ examinerLabel }} will contact you as needed for
                additional information.
              </app-toast>
            </div>
            <div class="tw-w-[70%] tw-pr-4">
              How many available days would you like to use? The number of PTO
              days that you provide will help us determine your payment amount;
              therefore, it is important that you provide us with an accurate
              number of PTO days you have available.
            </div>
            <div class="tw-w-[30%]">
              <app-text-control
                formControlName="days"
                [formGroup]="formGroup"
                label="PTO Days"
                ngDefaultControl
                mask="90"
                class="tw-w-full"
              ></app-text-control>
            </div>
            <div class="tw-w-full tw-pb-4"></div>
            <app-action-row class="tw-w-full">
              <app-button emphasis="High" right2 (click)="submitPto()"
                >Submit</app-button
              >
              <app-button
                emphasis="Low"
                right1
                matDialogClose
                (click)="closePTO()"
                >Close</app-button
              >
            </app-action-row>
          </form>
        </app-dialog-container>
      </ng-template>
    </div>
  `,
  styles: [``],
})
export class PtoComponent extends BaseComponent implements OnInit {
  @Input()
  claim: SearchResult;

  editPtoDialog: MatDialogRef<any, any>;
  Ptos: PtoInfoDetails;
  IsUpdatePto: boolean = false;
  isDisplaySuccess: boolean = false;
  availablePTO: string = '0';
  daysReported: string = '0';
  examinerLabel: string;
  employeeAlias: string;
  reportedDate: string;
  formGroup = this.fb.group({
    days: [null, [Validators.required, Validators.min(1), Validators.max(99)]],
  });

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public session: Session,
    public userRole: UserRoleService,
    public configurationService: ConfigurationService,
    public userAction: UserActionService,
    public inpageAliasService: InpageAliasService,
    private snackBarService: SnackbarService,
    private csvService: CsvService,
    private wsService: WorkScheduleService
  ) {
    super();
  }

  async ngOnInit() {
    this.IsUpdatePto =
      this.claim.userOwns &&
      this.configurationService.getFeatureAccess('updatepto');

    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingular',
      this.configurationService.GetConfig.configFeatures.aliases
    );

    this.employeeAlias = await this.inpageAliasService.replaceAliases(
      'employeePlural',
      this.configurationService.GetConfig.configFeatures.aliases
    );
    this.reportedDate = this.claim.dateReported;
    const contNum = this.session.user.contNum;
    const empUnum = this.claim.empUnum;
    this.Ptos = await this.wsService.getPto(
      contNum,
      empUnum,
      this.claim.lob,
      this.claim.claimId
    );
    //console.log(this.Ptos);
    this.daysReported = this.Ptos.ptoDaysReported;
    if (
      this.Ptos &&
      this.Ptos.ptoInformation &&
      this.Ptos.ptoInformation.length > 0
    ) {
      this.availablePTO =
        this.Ptos.ptoInformation[this.Ptos.ptoInformation.length - 1]
          .ptoBalance == null
          ? '0'
          : this.Ptos.ptoInformation[this.Ptos.ptoInformation.length - 1]
              .ptoBalance;
    }
    this.session.loadUser();

    this.subs.sink = this.formGroup.controls.days.valueChanges.subscribe(
      (day) => {
        if (day == '') {
          this.formGroup.controls.days.setErrors({
            customMsg: 'Required',
          });
        } else if (Number(day) > 99 || Number(day) < 1) {
          this.formGroup.controls.days.setErrors({
            customMsg: 'Value must be between 1 - 99',
          });
        } else {
          this.formGroup.controls.days.setErrors(null);
        }
      }
    );
  }

  openEditPto(templateRef: TemplateRef<any>) {
    this.editPtoDialog = this.dialog.open(templateRef);
  }

  submitPto() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const ptoData = {
        Lob: this.claim.lob,
        ClaimId: this.claim.claimId,
        FileNumber: this.claim.claimNumber,
        UserOwns: this.claim.userOwns,
        NoteType: 'PTO',
        PtoDays: this.formGroup.controls.days.value,
        WorkSchedule: {
          IntakeWorkWeeks: [
            {
              WeekNumber: 0,
              DaysWorked: 0,
              IntakeDays: [
                {
                  DayOfWeek: 0,
                  Minutes: 0,
                  Hours: 0,
                  FormattedHours: '',
                  McfNumber: '',
                },
              ],
            },
          ],
        },
      };

      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.snackBarService.show(
          'Preview-only mode. Information not sent.',
          SnackbarType.Info
        );
      } else {
        this.wsService.updateWeeklyWorkSchedule(ptoData).then((res) => {
          this.isDisplaySuccess = true;
          this.formGroup.reset();
        });
      }
    }
  }
  private emptyData(): any {
    return null;
  }
  closePTO() {
    this.formGroup.reset();
    this.editPtoDialog.close();
  }
  _exportData() {
    const csvParams = new CsvParams();
    csvParams.fileName = 'PTO';
    csvParams.rows = this.Ptos.ptoInformation;
    csvParams.keys = [
      'ptoEffective',
      'ptoExpire',
      'periodStart',
      'periodEnd',
      'initialBalance',
      'wpPtoApplied',
      'unpaidPtoApplied',
      'ptoBalance',
      'ptoType',
    ];
    csvParams.headers = [
      'PTO EFFECTIVE',
      'PTO EXPIRE',
      'PAY CYCLE START DATE',
      'PAY CYCLE END DATE',
      'INITIAL BALANCE',
      'WP PTO APPLIED',
      'UN PAID PTO APPLIED',
      'PTO BALANCE',
      'PTO TYPE',
    ];
    csvParams.headerNote = [];
    csvParams.headerNote.push(
      this.employeeAlias + ' PTO reported on : ' + this.reportedDate
    );
    csvParams.headerNote.push('PTO days reported : ' + this.daysReported);
    csvParams.headerNote.push('PTO time available : ' + this.availablePTO);

    this.csvService.download(csvParams);
  }
}
