import { Component, Input, OnDestroy, inject } from '@angular/core';
import { SubSink } from 'subsink';
import { StateService } from '..';

@Component({
  selector: 'app-base',
  template: ` <p>base works!</p> `,
  styles: [],
})
export class BaseComponent implements OnDestroy {
  subs = new SubSink();

  @Input()
  tag: string;

  public state = inject(StateService);

  scrollIntoView(elementId: string) {
    const el = document.getElementById(elementId);
    if (!el) {
      return;
    }

    const rect = el.getBoundingClientRect();
    const y = el.getBoundingClientRect().top + window.pageYOffset - 80;
    const isVisible = this.isElementInViewport(rect);
    if (!isVisible) {
      document.querySelector('.mat-sidenav-content').scrollTo({
        top: y,
        behavior: 'smooth',
      });
    }
  }

  private isElementInViewport(rect: DOMRect) {
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top + 100 <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
