import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import {
  AbsenceReport,
  ReportAbsenceInfoRequest,
  ReportIntermittentAbsenceResponse,
} from '../models/Absence-Report';
import { formatDate } from '@angular/common';
import { IntermittentAbsenceSetup } from 'src/app/shared/models/intermittent-absence-setup';

@Injectable({
  providedIn: 'root',
})
export class ReportAbsenceService {
  result: ReportIntermittentAbsenceResponse =
    new ReportIntermittentAbsenceResponse();

  constructor(private http: Http) {}

  reportAbsenceAsync(
    absenceReport: AbsenceReport,
    employeeId: string
  ): Promise<ReportIntermittentAbsenceResponse> {
    let request = new ReportAbsenceInfoRequest();
    request.claimId = absenceReport.claimId;
    request.source = absenceReport.source;
    request.message = absenceReport.message;
    request.absences = [];

    for (let i = 0; i < absenceReport.absences.length; i++) {
      const dateOfAbsence = new Date(absenceReport.absences[i].dateOfAbsence);

      request.absences.push({
        date: dateOfAbsence.toLocaleDateString('en-US'),
        hours: absenceReport.absences[i].hours,
        minutes: absenceReport.absences[i].minutes,
        reason: absenceReport.absences[i].reason,
        id: this.randomId(),
        reasonText: absenceReport.absences[i].reasonText,
        employeeId: employeeId,
        confirmation: '',
        messageId: '',
      });
    }

    let result = this.http.post<ReportIntermittentAbsenceResponse>(
      '/api/claim/submit-intermittent-absence',
      request
    );
    return result;
  }

  async getIntermittentAbsenceSetupAsync(
    contNum: string,
    lob: string,
    featureName: string
  ): Promise<IntermittentAbsenceSetup> {
    const url = `/api/claim/${contNum}/${lob}/submit-intermittent-absence-setup/${featureName}`;

    let result = await this.http.get<IntermittentAbsenceSetup>(url);

    return result;
  }

  randomId() {
    var text = 'cbft';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
}
