import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return value;
    }

    const items = value.split(' ');
    const temp: string[] = [];
    let first = true;
    for (const item of items) {
      // if the item contains a number, do not transform it
      if (/\d/.test(item)) {
        temp.push(item);
      } else {
        if (first) {
          temp.push(this.titleCasePipe.transform(item));
        } else {
          temp.push(item.toLowerCase());
        }
        first = false;
      }
    }

    return temp.join(' ');
  }
}
