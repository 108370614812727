import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateValidator(maxBackDays: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      //avoid validation if empty, we are not testing for required here        return null;
    }
    const errors: ValidationErrors = {};
    const startDate = control.value;
    const backDays = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ).setDate(new Date().getDate() - maxBackDays);
    if (startDate && maxBackDays && Date.parse(startDate) < backDays) {
      errors['minDate'] = `Error : Exceeds ${maxBackDays} days`;
    }
    return Object.keys(errors).length ? errors : null;
  };
}
