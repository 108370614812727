export class IncidentalAbsence {
  firstName: string;
  lastName: string;
  absenceType: string;
  absenceNumber: string;
  absenceStartDate: Date;
  absenceStartTime?: string;
  absenceEndDate: Date;
  absenceEndTime?: string;
  shiftsMissed: number;
  estimatedRTWDate?: Date;
  estimatedRTWTime?: string;
  absenceReason: string;
  typeOfCallOff: string;
  dateReported: Date;
  empUnum: string;
  absenceStatus: string;
  absenceReportedBy: string;
  referenceNumber: string;
  empId?: string;
  absenceStartDateStr?: string;
  absenceEndDateStr?: string;
  estimatedRTWDateStr?: string;
  ClaimLevelFeatures: string[];
  duration: string;
  absenceUid: string;
  rtwChanged?: boolean;
  absenceDurations?: AbsenceDuration[];
}

export interface AbsenceDuration {
  dateOfAbsence: Date;
  duration: number;
}
