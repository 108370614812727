import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { Delegatee } from '../components/delegate/act-delegate-page.component';
import { HelpItem } from '../models/HelpItem';
import { LogoResults } from '../models/LogoResults';
import { WelcomeMessage } from '../models/WelcomeMessageText';
import { UserLogout } from '../models/user-logout';
import { an } from '@fullcalendar/core/internal-common';
import { UserActionService } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class Session {
  get user() {
    return this._user;
  }

  get hideAnnouncements(): boolean {
    return sessionStorage.getItem('hide-announcements') === 'true';
  }
  set hideAnnouncements(value: boolean) {
    sessionStorage.setItem('hide-announcements', `${value}`);
  }

  private _user: User;
  private userLogout: UserLogout;
  private auditorRedirectLocation: string;
  supervisorEmpEmpUNum: string;
  currentAuditLogId: number = -1;
  public currentDelegatee: Delegatee | null;

  isEmulating$ = new EventEmitter<boolean>();
  isDelegating$ = new EventEmitter<boolean>();
  updateActivity = new EventEmitter<boolean>();
  isDelegating = signal<boolean>(false);
  currDelegatee = signal<Delegatee | null>(null);

  constructor(
    private http: HttpClient,
    private http1: Http,
    private userAction: UserActionService
  ) {
    this.supervisorEmpEmpUNum = sessionStorage.getItem('empEmUNum');
    this.currentDelegatee = this.delegatee;
  }

  async loadUser() {
    const options = { cache: true };
    if (!this._user) {
      this._user = await this.http1.get<User>('/api/user');

      await this.http1.get<string>('/api/user/antiforgery');

      this.isDelegating.set(this._user.isDelegating);
      this.currDelegatee.set(this.delegatee);
      if (this._user.timezone == undefined) {
        this._user.timezone =
          new Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }
  }

  async emulateUser(
    empId: string,
    claimId: string,
    contNum: string,
    empUnum: string,
    hrMasterUid: string,
    lob?: string
  ) {
    this._user = null;
    await this.http1.clearCache();

    this._user = await lastValueFrom(
      this.http.post<User>('/api/emulation/EmulateUser', {
        EmpId: empId,
        ClaimId: claimId,
        ContNum: contNum,
        EmpUnum: empUnum,
        HrMasterUid: hrMasterUid,
        Lob: lob ?? null,
      })
    );
    await this.http1.get<string>('/api/user/antiforgery');

    if (this._user.timezone == undefined) {
      this._user.timezone =
        new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.isEmulating$.next(true);
  }

  async emulateLogStart(claimId: string) {
    this.currentAuditLogId = -1;
    const emulateRequest: any = {
      AuditorLogId: 0,
      AuditorUserId: '',
      EmulateeClaimUid: claimId,
      EmulateeContNum: 0,
    };
    await this.http1.get<string>('/api/user/antiforgery');
    await this.http
      .post<any>('/api/emulation/EmulationStartLog', emulateRequest)
      .subscribe((result) => {
        this.currentAuditLogId = parseInt(result);
      });
  }

  async emulateLogEnd() {
    const emulateRequest: any = {
      AuditorLogId: this.currentAuditLogId,
      AuditorUserId: '',
      EmulateeEmpId: 0,
      EmulateeClaimUid: '',
      EmulateeContNum: 0,
    };
    await this.http
      .post<any>('/api/emulation/EmulationEndLog', emulateRequest)
      .subscribe((result) => {
        this.currentAuditLogId = -1;
      });
  }

  async stopEmulation(redirectUrl: string) {
    this._user = null;
    const options = { cache: true };
    sessionStorage.setItem('closed-announcements', 'false');
    await this.http1.get<string>('/api/user/antiforgery');
    await this.emulateLogEnd();
    this._user = await this.http
      .post<User>('/api/emulation/StopEmulation', {})
      .toPromise();

    if (this._user.timezone == undefined) {
      this._user.timezone =
        new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    this.isEmulating$.next(false);
    window.location.href = redirectUrl ? redirectUrl : '/';
  }

  async startDelegation(delegatee: Delegatee): Promise<boolean | string> {
    try {
      await this.http1.clearCache();
      await this.userAction.setBustCache(true);
      const emulationUser = {
        EmpId: delegatee.delegatorEmpId,
        HrMasterUid: delegatee.delegatorHrMasterUid,
        ContNum: delegatee.contNum.toString(),
        EmpUnum: delegatee.delegatorEmpUnum,
        CanReportClaim: delegatee.reportClaim,
        CanAddAbsence: delegatee.addAbsences,
        CanGetEmailCorrespondence: delegatee.emailCorrespondence,
      };
      const body: any = {
        emulateUser: emulationUser,
        delegator: delegatee,
      };
      await this.http1.get<string>('/api/user/antiforgery');
      const response = await this.http
        .post<User>('/api/delegation/start-delegation', body)
        .toPromise();

      if (response.error != null) {
        return 'Error';
      } else {
        this.isDelegating.set(true);
        this.isDelegating$.next(true);
        sessionStorage.setItem('currentDelegatee', JSON.stringify(delegatee));
        this.currDelegatee.set(delegatee);
        this._user = response;
        await this.startDelegationLog(delegatee);

        if (this._user.timezone == undefined) {
          this._user.timezone =
            new Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        await this.userAction.setBustCache(true);
        this.isDelegating.set(true);
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async endDelegation(): Promise<boolean> {
    await this.http1.clearCache();
    await this.userAction.setBustCache(true);
    this.isDelegating.set(false);
    this._user = null;

    await this.http1.get<string>('/api/user/antiforgery');
    this._user = await this.http
      .post<User>('/api/delegation/stop-delegation', {})
      .toPromise();
    await this.stopDelegationLog(
      this.currDelegatee ? this.currDelegatee() : null
    );

    sessionStorage.removeItem('currentDelegatee');
    this.currDelegatee.set(null);
    if (this._user.timezone == undefined) {
      this._user.timezone =
        new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.isDelegating$.next(false);
    return true;
  }

  async logoff() {
    if (this.user.delegateeContext != null) {
      this.endDelegation();
    }
    sessionStorage.clear();
    await this.http.get<string>('/api/user/antiforgery');
    this.userLogout = await this.http1.post<UserLogout>(`/api/auth/sign-out`, {
      ...this._user,
    });

    window.location.href = this.userLogout.logoutURL;
  }

  GetLogoDetails() {
    return this.http1.get<LogoResults>(`/api/user/GetLogoDetails`);
  }

  async GetWelcomeMessage(): Promise<WelcomeMessage[]> {
    const actionString = sessionStorage.getItem('mgrAction');
    const action = JSON.parse(actionString);
    let role = this.user.role;
    if (
      (this.user.role == 'SUPERVISOR' ||
        this.user.role == 'OMNI' ||
        this.user.role == 'OVERSEER') &&
      action == 'ViewOwnEmpDb'
    ) {
      role = 'HRM';
    }
    return await this.http1.get<WelcomeMessage[]>(
      `/api/claimant/${role}/GetWelcomeMessage`
    );
  }

  async GetHelpItems() {
    return await this.http1.get<HelpItem[]>(`/api/user/GetHelpfulLinks`);
  }

  async downloadDocument(dcn: string, role: string) {
    return await lastValueFrom(
      this.http.get(`/api/user/Document/${dcn}/${role}GetFaqDocument`, {
        responseType: 'blob',
        observe: 'response',
      })
    );
  }

  async GetAuditorRedirectLocation() {
    const temp = this.auditorRedirectLocation;
    this.auditorRedirectLocation = null;
    return temp;
  }

  async SetAuditorRedirectLocation(value: string) {
    this.auditorRedirectLocation = value;
  }

  async startDelegationLog(
    delegatee: Delegatee
  ): Promise<boolean | { Message: string }> {
    try {
      await this.http1.get<string>('/api/user/antiforgery');
      await this.http
        .post<any>('/api/delegation/start-delegation-log', delegatee)
        //.toPromise();
        .subscribe((result) => {
          return result;
        });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async stopDelegationLog(delegatee: Delegatee | null): Promise<boolean> {
    try {
      await this.http1.get<string>('/api/user/antiforgery');
      await this.http
        .post<boolean>('/api/delegation/stop-delegation-log', delegatee)
        .toPromise();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  get delegatee(): Delegatee | null {
    if (!this.currentDelegatee) {
      const storedDelegatee = sessionStorage.getItem('currentDelegatee');
      if (storedDelegatee) {
        this.currentDelegatee = JSON.parse(storedDelegatee);
      }
    }
    return this.currentDelegatee;
  }
}

export class emulateUserRequest {
  empId: string;
  claimId: string;

  constructor(values: emulateUserRequest) {
    Object.assign(this, values);
  }
}
