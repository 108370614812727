import { Component, Input, TemplateRef } from '@angular/core';
import { TabGroupComponent } from 'src/app/shared';

@Component({
  selector: 'app-tab',
  template: ``,
  styles: [],
})
export class TabComponent {
  @Input({ required: true })
  label: string;

  @Input()
  badge: string | number;

  /**
   * Assign featureName to trigger feature logging on tab visit
   */
  @Input()
  featureName: string;

  /**
   * Name is used to uniquely identify a tab in a tab group.
   * Used by TabGroup.selectTab for example
   */
  @Input()
  name: string;

  @Input()
  visible = true;

  @Input({ required: true })
  template: TemplateRef<any>;

  visited = false;

  constructor(private tabGroup: TabGroupComponent) {
    this.tabGroup.addTab(this);
  }
}
