import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import {
  SaveMessageActionRequest,
  SaveMessageResponse,
  SendMessage,
} from '../models/Communications';
import { ConversationSummary } from '../models/ConversasationSummary';
import { UserActions } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(private http: Http) {}

  async getMessages(
    source: string,
    claimId: string,
    lob: string,
    empUnum: string = null,
    userAction: UserActions,
    includeIndirects: boolean
  ): Promise<ConversationSummary[]> {
    const path =
      claimId != null && empUnum != null
        ? `/api/manager/claimcommunication/${source}/${claimId}/${lob}/${empUnum}`
        : empUnum != null
        ? `/api/manager/communication/${empUnum}`
        : claimId != null
        ? `/api/manager/claimcommunication/${source}/${claimId}/${lob}`
        : '/api/manager/communication';

    const request = {
      supervisorAction: userAction,
      IncludeIndirects: includeIndirects,
    };
    return await this.http.post<ConversationSummary[]>(
      path + '/get-conversations',
      request
    );
  }

  async saveMessage(
    source: string,
    claimId: string,
    lob: string,
    sendMessageRequest: SendMessage
  ): Promise<any> {
    const path = `/api/manager/claimcommunication/${source}/${claimId}/${lob}`;
    this.clearCache(source, claimId, lob);
    return this.http.post<SaveMessageResponse>(
      path + '/send-message',
      sendMessageRequest
    );
  }

  async readMessage(
    source: string,
    claimId: string,
    lob: string,
    saveMessageActionRequest: SaveMessageActionRequest
  ) {
    const path = `/api/manager/claimcommunication/${source}/${claimId}/${lob}`;
    await this.http.post<number>(
      path + '/read-message',
      saveMessageActionRequest
    );
    this.clearCache(source, claimId, lob);
  }

  private clearCache(source: string, claimId: string, lob: string) {
    // delete both the claim level conversation and the manager level conversation.
    // The data could be stale for both of these entries.
    this.http.deleteCacheEntry('/api/manager/communication/get-conversations');
    this.http.deleteCacheEntry(
      `/api/manager/claimcommunication/${source}/${claimId}/${lob}/get-conversations`
    );
  }
}
