import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { MileageRequest } from 'src/app/my-sedgwick/models/mileage-request';
import { ListComponent } from 'src/app/shared';
import {
  MileageDetail,
  UserMileageReimbursement,
  UserMileageReimbursementDetail,
} from '../../models/Mileages';

@Component({
  selector: 'app-mileage-review-page',
  template: `
   
      <div class="app-pt3"></div>
      <div class="app-header-2 pdb15">
        Please review the trips you have entered before submitting:
      </div>

      <app-list
        #tripsList
        [dataSource]="details"
        [template]="row"
        [filterEnabled]="false"
        [paginatorEnabled]="false"
        class="app-pt2 pdt15"
        loadingMessage=""
        noDataIcon="attachment"
        [loaded]="true"
        noDataHeader="No trips available for this reimbursement"
        noDataMessage="Click 'Add trip' to add a new trip for this reimbursement to continue"
        [tableAriaLabel]="'Trips'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <app-list-header
              name="travelDate"
              label="Date"
              class="tw-w-full md:tw-w-[20%]"
            ></app-list-header>
            <app-list-header
              name="travelType"
              label="Travel Type"
              readOnly
              [sortEnabled]="false"
              class="tw-w-full md:tw-w-[30%]"
            ></app-list-header>
            <app-list-header
              name="travelReason"
              label="Travel Reason"
              readOnly
              class="tw-w-full md:tw-w-[30%]"
            ></app-list-header>
            <app-list-header
              label="Actions"
              readOnly
              class="tw-w-full md:tw-w-[20%]"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>

      <ng-template #row let-element let-index="index">
        <tr
          class="app-pt2 app-pb2 tw-flex tw-flex-wrap tw-w-full tw-items-center"
        >
          <td class="tw-w-full md:tw-w-[20%]">
            <app-value
              label="Travel Date"
              [value]="element.travelDate | format : 'date'"
              [tabIndex]="0"
              layout="row-xs"
            ></app-value>
          </td>
          <td class="tw-w-full md:tw-w-[30%]">
            <app-value
              label="Travel Type"
              [value]="element.travelType"
              [tabIndex]="0"
              layout="row-xs"
            ></app-value>
          </td>
          <td class="tw-w-full md:tw-w-[30%]">
            <app-value
              label="Travel Reason"
              [value]="element.travelReason"
              [tabIndex]="0"
              layout="row-xs"
            ></app-value>
          </td>
          <td class="tw-w-full md:tw-w-[20%] disabled-text">
            <app-value label="" layout="row-xs">
              <div value class="tw-w-full content-center">
                <app-icon-button
                  icon="edit"
                  label="Edit"
                  tabindex="0"
                  role="button"
                  [ariaLabelText]="'Edit trip'"
                  (click)="EditTripCallback(index)"
                  (keydown.enter)="EditTripCallback(index)"
                  [disabled]="isSubmitting"
                ></app-icon-button>
                <app-icon-button
                  icon="delete_outline"
                  tabindex="0"
                  role="button"
                  label="Delete"
                  [attr.aria-label]="'Remove trip'"
                  (click)="RemoveTripCallback(index)"
                  (keydown.enter)="RemoveTripCallback(index)"
                  [disabled]="isSubmitting"
                ></app-icon-button>
              </div>
            </app-value>
          </td>
        </tr>
      </ng-template>
      <div class="tw-w-full bottom-border">
        <app-button
          left1
          emphasis="Medium"
          (click)="AddTripCallback()"
          [disabled]="isSubmitting"
          >Add trip</app-button
        >
        <br />
        <br />
        <br />
      </div>
      <div class="app-pt3 app-pb3">
        <span class="total"
          ><b>Total estimated reimbursement:</b>
          {{ totalAmount() | format : 'currency' }}</span
        >
      </div>
      <div class="app-pb3" *ngIf="mileageData.stateAckLanguage">
        <span
          ><i>{{ mileageData.stateName }} residents only: </i>&nbsp;&nbsp;
          {{ mileageData.stateAckLanguage }}</span
        >
      </div>

      <div class="app-pb3">
        <span
          >I here by certify or affirm that the above travel and mileage was
          incurred by me as neccessary travel related to my above reference
          workers' compensation claim.</span
        >
      </div>
      <div>
        <form
          [formGroup]="reviewFormGroup"
          class="tw-w-full tw-flex tw-flex-wrap"
        >
          <app-text-control
            formControlName="signature"
            [formGroup]="reviewFormGroup"
            [disabled]="isSubmitting"

            label="Name"
            ngDefaultControl
            class="tw-w-full md:tw-w-[33%]"
            [attr.disabled]="isSubmitting"
          ></app-text-control>
          <div class="app-pt2 app-pl2">
            <span
              ><b>Date electronically signed:</b>
              {{ signatureDate | format : 'date' }}</span
            >
          </div>
        </form>
    
    </div>
  `,
  styles: [
    `
      .bdrbtm {
        border-bottom: 1px solid --color-border-grey;
      }
      .total {
        font-size: 18px;
      }
      .bottom-border {
        border-bottom: 1px solid var(--color-border-grey);
      }
      .pdb15 {
        padding-bottom: 15px;
      }
    `,
  ],
})
export class MileageReviewPageComponent implements OnInit {
  @Input()
  reimbursementData: UserMileageReimbursement;

  @Input()
  mileageData: MileageDetail;

  @Input()
  isSubmitting: boolean = false;

  @ViewChild('tripsList', { static: false })
  tripsListComponent: ListComponent;

  @Output()
  onAddTrip: EventEmitter<any> = new EventEmitter();

  @Output()
  onDeleteTrip: EventEmitter<any> = new EventEmitter();

  @Output()
  signatureChange = new EventEmitter<string>();

  @Output()
  getSignature(): string {
    return this.reviewFormGroup.controls['signature'].value;
  }
  @Output()
  validateData(): boolean {
    this.reviewFormGroup.markAllAsTouched();
    this.reviewFormGroup.updateValueAndValidity();
    if (!this.reviewFormGroup.valid) {
      return false;
    }
    return true;
  }
  details: UserMileageReimbursementDetail[] = [];

  signatureDate: Date = new Date();

  totalAmount = () => {
    let total = 0;
    this.reimbursementData.Details.forEach((d) => {
      if (d.travelType === 'Personal') {
        //"Other") {
        total += d.travelDistance * Number(this.mileageData.mileageRate);
      }
      d.Expenses.forEach((e) => (total += e.amountPaid));
    });

    return total;
  };

  reviewFormGroup = this.fb.group({
    signature: [, Validators.required],
    /*date: [, Validators.required],*/
  });

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.cdr.detach();
    // remove "Total Cost" expenses when travel type is "Personal" in case user add an expense under a different travel type
    // and then put it back to Personal, which doesn't allow Total Cost
    for (let d = 0; d < this.reimbursementData.Details.length; d++) {
      if (this.reimbursementData.Details[d].travelType === 'Personal') {
        this.reimbursementData.Details[d].Expenses =
          this.reimbursementData.Details[d].Expenses.filter(
            (e) => e.expenseType !== 'Total cost'
          );
      }
    }
    this.details = this.reimbursementData.Details;

    if (this.tripsListComponent) {
      this.tripsListComponent.updateDataSource();
    }
    this.cdr.reattach();
    this.cdr.detectChanges();
    this.reviewFormGroup.get('signature')?.valueChanges.subscribe(value => {
      this.signatureChange.emit(value);
    });
  }

  AddTripCallback() {
    this.onAddTrip.emit([-1]);
  }

  EditTripCallback(index: any) {
    this.onAddTrip.emit([index]);
  }

  RemoveTripCallback(index: any) {
    this.onDeleteTrip.emit([index]);
  }
}
