import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import {
  ArcElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

@Component({
  selector: 'app-card-chart',
  template: `
    <div
      class="tw-w-full app-blue-container tw-flex tw-flex-col tw-items-center app-pt3 app-pb3"
    >
      <div class="chart">
        <canvas role="img" [attr.id]="chartId"></canvas>
      </div>
      <div
        class="tw-flex tw-flex-col tw-font-bold tw-tracking-wide app-color-slate tw-uppercase tw-text-[13px] app-pt2"
      >
        <div
          class="tw-flex tw-items-center"
          *ngFor="let series of config.series"
        >
          <div class="tw-flex-none app-pr2 tw-mt-1">
            <mat-icon class="legend-icon" [style.color]="series.color"
              >circle</mat-icon
            >
          </div>
          <div class="tw-flex-grow">
            {{ series.value }} - {{ series.label }}
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .chart {
        height: 150px;
      }

      .legend-icon {
        width: 13px;
        height: 13px;
        font-size: 13px;
      }
    `,
  ],
})
export class CardChartComponent implements AfterViewInit, OnDestroy {
  @Input()
  config: ChartConfig;

  chartId: string;

  private chart: any;

  constructor() {
    this.chartId = `chart-${Math.floor(Math.random() * 1000000)}`;
  }

  ngAfterViewInit(): void {
    Chart.register(
      DoughnutController,
      CategoryScale,
      LinearScale,
      ArcElement,
      Legend,
      Title,
      Tooltip
    );

    const labels = this.config.series.map((x) => x.label);
    const values = this.config.series.map((x) => x.value);
    const colors = this.config.series.map((x) => x.color);

    this.chart = new Chart(this.chartId, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
          },
        ],
      },
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        cutout: 48,
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              font: {
                size: 14,
                weight: 'bold',
                family: '"Source Sans Pro", "Helvetica Neue", sans-serif',
              },
            },
          },
          title: {
            display: false,
            text: '',
          },
        },
      },
    });
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
  }
}

export class ChartConfig {
  series: ChartSeries[] = [];

  addSeries(label: string, value: number, color: string): ChartConfig {
    this.series.push(new ChartSeries(label, value, color));
    return this;
  }
}

export class ChartSeries {
  constructor(
    public label: string,
    public value: number,
    public color: string
  ) {}
}
