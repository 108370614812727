import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  template: `
    <div class="container-header app-pb1">
      <div class="tw-flex tw-w-full tw-items-center">
        <div class="tw-flex-grow">
          <ng-content select="[label]"></ng-content>
        </div>
        <div class="tw-flex-none action">
          <ng-content select="[action]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .container-header {
        width: 100%;
        padding-left: 14px;
        padding-right: 14px;
      }
      .action {
        text-align: right;
      }
    `,
  ],
})
export class PageHeaderComponent {
  @Input()
  label: string;
}
