import { Address } from './address';
import { ClaimKey } from './claim-key';

export interface PaymentInfo {
  nextPaymentDue: Date;
  payments: Payment[];
  showFromToDate: boolean;
}

export class Payment {
  checkNumber: string;
  name: string;
  issueDate: Date;
  amount: number;
  paymentMethod: string;
  fromDate: Date;
  toDate: Date;
  voided: boolean;
  mailingAddress: Address;
  showAddress: boolean;
  icn: string;
  vendorId: string;
  claim: string;
  fromTo: string;
  claimId: string;
  source: string;
  lob: string;
  nameType: string;
  payee: string;
  issueDateHelpText: string;
  documentType: string;
  billedAmount: number;

  constructor(values: Payment) {
    Object.assign(this, values);
  }
}

export class PaymentRequest {
  claimKeys: ClaimKey[];
  constructor(values: PaymentRequest) {
    Object.assign(this, values);
  }
}
