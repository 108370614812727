import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastType, hideString } from 'src/app/shared';
import { UserPreference } from '../../models/UserPreference';
import { AccountSettingsService } from '../../services/accountsettings.service';
import { ActivityService } from '../../services/activity.service';
import { emulator } from '../../services/emulator.service';
import { Session } from '../../services/session.service';
@Component({
  selector: 'app-communication-pref-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<app-section title="Communication Preferences"></app-section>
    <div class="changeAuthenticationPreferencesContent" id="changeCommPrefDiv">
      <div class="app-pb2">
        <form
          role="none"
          class="tw-flex tw-flex-wrap tw-flex-col"
          [formGroup]="commPreferenceGroup"
        >
          <div class="md:tw-block">
            <div class="flex-container">
              <p class="heading2">
                We would like to send you electronic status updates regarding
                your claim, payments, and receipt of substantiating
                documentation so that you will have this information as quickly
                as it is available.
              </p>
              <div class="app-pt1 app-pb2 radioBtnHeading">
                <span class="tw-w-full"
                  ><strong
                    >Please choose your preferred language below.</strong
                  ></span
                >
                <br />
                <mat-radio-group
                  aria-label="Select a language options"
                  (change)="onSelectedValueChange($event.value)"
                  formControlName="languagePrefFrom"
                  role="radiogroup"
                >
                  <mat-radio-button value="ENG" class="app-pl2" checked="true"
                    >English</mat-radio-button
                  >
                  <mat-radio-button value="SPA" class="app-pl2"
                    >Spanish</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <div class="app-pt1 app-pb2">
                <span class="tw-w-full"
                  ><strong
                    >Please specify the methods and contact information to
                    receive your updates:</strong
                  ></span
                >
                <app-toggle-component
                  [emailAddressText]="emailAddress"
                  [phoneNumberText]="mobileNumber"
                  formGroupName="toggleGroup"
                  (displayButtons)="displayButtons($event)"
                  (clearFields)="clearFields($event)"
                  (AuthCodeChanged)="AuthCodeChanged($event)"
                  (editComp)="editComp($event)"
                  (closeToastMessage)="closeToastMessage($event)"
                  [commPrefComp]="true"
                  [editEmailComponent]="closeFieldsEmail"
                  [editPhoneComponent]="closeFieldsphone"
                  [shwErrMsg]="isSuccess"
                  [changeTrigger]="changeTrigger"
                  *ngIf="viewModelLoaded"
                  [showEditEmail]="showEditEmail"
                  [showEditPhone]="showEditPhone"
                >
                </app-toggle-component>
                <div
                  class="tw-w-[70%] toaster"
                  *ngIf="this.toastMessage !== ''"
                >
                  <br />
                  <div class="md:tw-w-[90%] ">
                    <app-toast
                      *ngIf="enableToast"
                      id="successMsg"
                      [type]="toastType"
                      #toastMsg
                    >
                      {{ this.toastMessage }}
                    </app-toast>
                  </div>
                </div>
                <br />
                <div
                  fxLayoutAlign="end start"
                  style="float: right"
                  class="btnStyleMob"
                >
                  <app-button
                    [id]="'saveCommPreferences'"
                    emphasis="High"
                    mat-raised-button
                    (click)="OnSubmit()"
                    (keydown.enter)="OnSubmit()"
                    [arialabel]="'Save communication preferences'"
                  >
                    Save
                  </app-button>
                  <span class="app-button-margin"></span>

                  <app-button
                    [id]="'cancelCommPrefs'"
                    mat-raised-button
                    emphasis="Low"
                    color="whiteButton"
                    (click)="onCancelMFA()"
                    (keydown.enter)="onCancelMFA()"
                    [arialabel]="'Cancel communication preferences changes'"
                  >
                    Cancel
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>`,
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #changeCommPrefDiv ::ng-deep .mat-form-field-outline {
        color: #67707a;
        border-color: #67707a;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #changeCommPrefDiv ::ng-deep .mat-form-field-outline:focus {
        color: #171c20;
        border-color: #007dbc;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #changeCommPrefDiv ::ng-deep .mat-form-field-outline:hover {
        color: #67707a;
        border-color: #3f464d;
      }

      .btn-info:hover {
        background-color: #035e81;
      }
      .btn-info {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background-color: #007dbc;
        font-weight: bold;
      }

      .btn-cancel:hover {
        background-color: #f1f4f7;
      }
      .btn-cancel {
        font-size: 15px;
        color: #3f464d;
        line-height: 30px;
        border-radius: 40px;
        box-shadow: none;
        font-weight: bold;
        background-color: #ffffff;
        border: #3f464d;
      }

      .input {
        color: #67707a;
        border-color: #67707a;
      }
      .input:focus {
        color: #171c20;
        border: #007dbc;
      }

      .input:hover {
        color: #67707a;
        border: #3f464d;
      }

      .button {
        background-color: var(--dark-blue);
        margin: 5px;
        color: white;
      }
    `,
  ],
})
export class CommunicationPrefPageComponent implements OnInit {
  editPhoneComponent: boolean = false;
  emailAddress: string;
  confirmEmailAddress: string;
  emailToggleUpdated: boolean = false;
  mobileToggleUpdated: boolean = false;
  toastType: ToastType;
  toastMessage: string = '';
  isSuccess: boolean = false;
  commPreferenceGroup: FormGroup;
  editEmailComponent: boolean = false;
  mobileNumber: string;
  commPreference: UserPreference;
  checkForSubmit: boolean = false;
  isDisplayButtons: boolean = false;
  displaySave: boolean = false;
  toastIcon: string;
  enableToast: boolean = false;
  clearEmailFields: boolean;
  clearMobileFields: boolean;
  closeFieldsEmail: boolean;
  closeFieldsphone: boolean;
  isEmailAuthCode: boolean;
  isPhoneAuthCode: boolean;
  isEmailComp: boolean;
  isPhoneComp: boolean;
  showEditEmail: boolean = false;
  showEditPhone: boolean = false;
  viewModelLoaded: boolean = true;
  initialFormValue: any;
  @Output()
  mfaPanelExpanded = new EventEmitter();
  isDataExisting = false;
  changeTrigger = 1;
  showErrorMessage: boolean;
  showToggleComp: boolean;
  constructor(
    private fb: FormBuilder,
    private activityService: ActivityService,
    private accountService: AccountSettingsService,
    private el: ElementRef,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private emulatorService: emulator,
    public session: Session
  ) {}

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change: SimpleChange = changes['isDisplayButtons'];
    const change1: SimpleChange = changes['displaySave'];
    const change2: SimpleChange = changes['enableToast'];
    const change3: SimpleChange = changes['emailAddress'];
    const change4: SimpleChange = changes['mobileNumber'];
    if (change != undefined) {
      if (change.currentValue != null && change.currentValue != undefined) {
        this.isDisplayButtons = change.currentValue;
      }
    }
    if (change1 != undefined) {
      if (change1.currentValue != null && change1.currentValue != undefined) {
        this.displaySave = change1.currentValue;
      }
    }
    if (change2 != undefined) {
      if (change2.currentValue != null && change2.currentValue != undefined) {
        this.enableToast = change2.currentValue;
      }
    }
    if (change3 != undefined) {
      if (change3.currentValue != null && change3.currentValue != undefined) {
        this.emailAddress = change3.currentValue;
      }
    }
    if (change4 != undefined) {
      if (change4.currentValue != null && change4.currentValue != undefined) {
        this.mobileNumber = change4.currentValue;
      }
    }
  }
  async ngOnInit() {
    this.commPreferenceGroup = this.fb.group({
      languagePrefFrom: [''],
      toggleGroup: this.fb.group({
        emailEnabledForm: [false],
        mobileEnabledForm: [false],
      }),
    });
    await this.GetCommPreferences();
    this.initialFormValue = this.commPreferenceGroup.value;
  }
  async onSelectedValueChange(event: string) {
    this.toastMessage = '';
    if (event === 'English') {
      this.commPreferenceGroup.get('languagePrefFrom').setValue('ENG');
    } else if (event === 'Spanish') {
      this.commPreferenceGroup.get('languagePrefFrom').setValue('SPAN');
    }
  }

  formatPhoneNumber(phoneNumberString: string): string {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  }
  displayButtons(event: any) {
    this.isDisplayButtons = event;
  }
  clearFields(event: any) {
    if (event == 'email') {
      this.clearEmailFields = true;
    }
    if (event == 'phone') {
      this.clearMobileFields = true;
    }
  }
  AuthCodeChanged(event: any) {
    if (event == 'emailAuth') {
      this.isEmailAuthCode = true;
      this.isPhoneAuthCode = false;
    }
    if (event == 'phoneAuth') {
      this.isEmailAuthCode = false;
      this.isPhoneAuthCode = true;
    }
  }
  editComp(event: any) {
    if (event == 'email') {
      this.isEmailComp = true;
    }
    if (event == 'phone') {
      this.isPhoneComp = true;
    }
  }
  onCancelMFA() {
    this.router.navigate(['/']);
  }
  async GetCommPreferences() {
    this.commPreference = await this.accountService.getCommPreferences();
    if (this.commPreference) {
      if (this.commPreference?.langPref) {
        this.commPreferenceGroup
          .get('languagePrefFrom')
          .setValue(
            this.commPreference.langPref === 'ENG' ||
              this.commPreference.langPref === 'English' ||
              this.commPreference.langPref === null
              ? 'ENG'
              : 'SPA'
          );
      } else {
        this.commPreferenceGroup.get('languagePrefFrom').setValue('ENG');
      }
      if (this.commPreference?.isEmail) {
        this.commPreferenceGroup
          .get('toggleGroup')
          .get('emailEnabledForm')
          .setValue(this.commPreference.isEmail);
        this.emailToggleUpdated = this.commPreference.isEmail;
      }
      if (this.commPreference?.isMessage) {
        this.commPreferenceGroup
          .get('toggleGroup')
          .get('mobileEnabledForm')
          .setValue(this.commPreference.isMessage);
        this.mobileToggleUpdated = this.commPreference.isMessage;
      }
      if (this.commPreference?.email) {
        this.emailAddress = this.commPreference.email;
        this.viewModelLoaded = true;
        this.commPreferenceGroup
          .get('toggleGroup')
          .get('emailGroup')
          .get('emailAddress')
          .setValue(this.commPreference.email);
        this.commPreferenceGroup
          .get('toggleGroup')
          .get('emailGroup')
          .get('confirmEmailAddress')
          .setValue(this.commPreference.email);
      }
      if (this.commPreference?.messagePhone) {
        const formatedphone = this.formatPhoneNumber(
          this.commPreference.messagePhone
        );
        this.mobileNumber = formatedphone;
        this.showToggleComp = true;
        this.viewModelLoaded = true;
        this.commPreferenceGroup
          .get('toggleGroup')
          .get('phoneGroup')
          .get('mobilenumber')
          .setValue(formatedphone);
      }
      if (this.emailAddress && this.emailToggleUpdated) {
        this.showEditEmail = true;
      }
      if (this.mobileNumber && this.mobileToggleUpdated) {
        this.showEditPhone = true;
      }

      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.mobileNumber = hideString(this.mobileNumber, '*');
        this.emailAddress = hideString(this.emailAddress, '*');
      }
    } else {
      this.commPreferenceGroup.get('languagePrefFrom').setValue('ENG');
    }
  }
  checkBeforeSubmit(): boolean {
    this.checkForSubmit = false;
    const dataTogGrp = this.commPreferenceGroup.controls[
      'toggleGroup'
    ] as FormGroup;
    const dataphoneGroup = dataTogGrp.controls['phoneGroup'] as FormGroup;
    const dataemailGroup = dataTogGrp.controls['emailGroup'] as FormGroup;
    this.emailAddress = dataemailGroup.get('emailAddress').value;
    this.confirmEmailAddress = dataemailGroup.get('confirmEmailAddress').value;

    if (
      dataTogGrp.get('emailEnabledForm').value == false &&
      dataTogGrp.get('mobileEnabledForm').value == false
    ) {
      this.checkForSubmit = true;
    }
    this.mobileNumber = dataphoneGroup.get('mobilenumber').value;
    if (dataTogGrp.get('mobileEnabledForm').value == true) {
      if (this.mobileNumber != '') {
        this.checkForSubmit = true;
      } else {
        this.checkForSubmit = false;
      }
    }
    if (dataTogGrp.get('emailEnabledForm').value == true) {
      if (
        this.emailAddress != '' &&
        this.confirmEmailAddress != '' &&
        dataemailGroup.get('emailAddress').value ===
          dataemailGroup.get('confirmEmailAddress').value
      ) {
        this.checkForSubmit = true;
      } else {
        this.checkForSubmit = false;
      }
    }
    return this.checkForSubmit;
  }

  formHasNotChanged(): boolean {
    return (
      JSON.stringify(this.commPreferenceGroup.value) ===
      JSON.stringify(this.initialFormValue)
    );
  }

  checkValidity(group: FormGroup, controlName: string) {
    if (!group.valid) {
      const control = group.get(controlName);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
      }
      this.changeDetector.detectChanges();
    }
  }

  async OnSubmit() {
    if (this.formHasNotChanged()) {
      return;
    }

    this.toastMessage = '';
    const dataTogGrp = this.commPreferenceGroup.controls[
      'toggleGroup'
    ] as FormGroup;
    const dataemailGroup = dataTogGrp.controls['emailGroup'] as FormGroup;
    const dataphoneGroup = dataTogGrp.controls['phoneGroup'] as FormGroup;

    this.checkValidity(dataphoneGroup, 'mobilenumber');
    this.checkValidity(dataemailGroup, 'emailAddress');

    if (dataTogGrp.valid) {
      this.emulatorService.showAuditorInfo();
      if (this.checkBeforeSubmit() === true) {
        const request = {
          isEmail:
            dataTogGrp.get('emailEnabledForm').value == true ? true : false,
          isPhone:
            this.commPreference && this.commPreference.isPhone
              ? this.commPreference.isPhone
              : false,
          langPref: this.commPreferenceGroup.get('languagePrefFrom').value
            ? this.commPreferenceGroup.get('languagePrefFrom').value
            : '',
          email: dataemailGroup.get('emailAddress').value,
          phone:
            this.commPreference && this.commPreference.phone
              ? this.commPreference.phone
              : '',
          isMessage:
            dataTogGrp.get('mobileEnabledForm').value == true ? true : false,
          messagePhone: dataphoneGroup.get('mobilenumber').value,
          isNotification:
            dataTogGrp.get('emailEnabledForm').value == false &&
            dataTogGrp.get('mobileEnabledForm').value == false
              ? false
              : true,
          preferenceKeyType: '',
          isDetailedMessage: false,
          hasWcLegalRepresentation: false,
          claimntUid: '',
        };
        const result = await this.accountService.setCommPreferences(request);
        this.activityService.DismissNotificationAlert(
          'OPT_IN_A',
          '',
          '',
          '',
          true,
          'CommPreference'
        );
        this.viewModelLoaded = true;
        this.enableToast = true;
        this.toastIcon = 'thumb_up';
        this.toastMessage =
          'Your notification preferences have been submitted. It may take up to 15 minutes for your changes to be processed.';
        this.toastType = 'Success';
        this.isSuccess = true;
        this.changeDetector.detectChanges();
      } else {
        return;
      }
    } else {
      return;
    }
  }

  closeToastMessage(event: any) {
    this.enableToast = false;
    this.toastMessage = '';
  }
}
