import { isEqual, isValid, parse } from 'date-fns';
import { SelectOption } from '../forms/select-control.component';

export class Filter {
  value?: any;

  /**
   * Set names when filtering needs to search multiple properties on
   * an object. An example would be a claimant name. The UI might
   * present firstName, lastName and empId. lastName would be assigned
   * to name for sorting and names would be assigned [firstName, empId]
   */
  names?: any[];

  editMode: boolean;

  options: SelectOption[] = [];

  get displayLabel(): string {
    if (!this.value) {
      return this.label;
    }

    let result = this.label;
    switch (this.condition) {
      case 'Contain':
        result += ' contains';
        break;
      case 'NotContain':
        result += ' not contains';
        break;
      case 'Equal':
        result += ' equals';
        break;
      case 'NotEqual':
        result += ' not equal';
        break;
      case 'StartWith':
        result += ' starts with';
        break;
      case 'EndWith':
        result += ' ends with';
        break;
      case 'GreaterThan':
        result += this.type === 'Date' ? ' after' : ' greater than';
        break;
      case 'LessThan':
        result += this.type === 'Date' ? ' before' : ' less than';
        break;
      default:
        break;
    }

    return result;
  }

  get width(): number {
    const iconWidth = 48;
    let result =
      this.label.length * 12 + iconWidth + (this.type === 'Date' ? 40 : 0);
    result = Math.max(160, result);
    return result;
  }

  constructor(
    public name: string,
    public label: string,
    public type: FilterType,
    public condition: FilterCondition
  ) {}
}

export type FilterType = 'None' | 'Text' | 'Date' | 'Number' | 'Select';

export type FilterCondition =
  | 'Contain'
  | 'NotContain'
  | 'Equal'
  | 'StartWith'
  | 'EndWith'
  | 'NotEqual'
  | 'GreaterThan'
  | 'LessThan';

export class FilterPredicate {
  constructor(public filters: Filter[]) {}

  textPredicate(data: any, textFilter: string): boolean {
    let textFilterLower = textFilter.trim().toLowerCase();

    // filter is a date, only compare with date filters
    let date = parse(textFilterLower, 'MM/dd/yyyy', new Date());
    if (isValid(date)) {
      for (const filter of this.filters) {
        if (filter.type === 'Date' && data[filter.name]) {
          var tempData = new Date(data[filter.name]);
          let dateData = new Date(tempData.toDateString());
          if (isEqual(dateData, date) ) {
            return true;
          }
        }
      }
    } else {
      // string comparison
      for (const filter of this.filters) {
        // check the name first
        if (this.compare(data[filter.name], textFilterLower)) {
          return true;
        }

        // check any additional names next
        if (filter.names) {
          for (const name of filter.names) {
            if (this.compare(data[name], textFilterLower)) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  private compare(value: any, filter: string): boolean {
    if (!value) {
      return false;
    }

    let v: string = value.toString().toLowerCase();
    if (v.includes(filter)) {
      return true;
    }

    return false;
  }

  filterPredicate(data: any, filter_: string): boolean {
    let result = true;
    for (const filter of this.filters) {
      if (!filter.value) {
        continue;
      }

      if (!data[filter.name]) {
        return false;
      }

      if (filter.type === 'Text') {
        let v: string = data[filter.name].toString();
        v = v.toLowerCase();
        const fv = filter.value.toLowerCase();
        switch (filter.condition) {
          case 'Contain':
            result = v.includes(fv);
            break;
          case 'NotContain':
            result = !v.includes(fv);
            break;
          case 'Equal':
            result = v == fv;
            break;
          case 'NotEqual':
            result = v != fv;
            break;
          case 'StartWith':
            result = v.startsWith(fv);
            break;
          case 'EndWith':
            result = v.endsWith(fv);
            break;
          default:
            break;
        }
      } else if (filter.type === 'Number') {
        let v: number = data[filter.name];
        const num = Number.parseFloat(filter.value);
        switch (filter.condition) {
          case 'Equal':
            result = num == v;
            break;
          case 'NotEqual':
            result = num != v;
            break;
          case 'GreaterThan':
            result = num < v;
            break;
          case 'LessThan':
            result = num > v;
            break;
          default:
            break;
        }
      } else if (filter.type === 'Date') {
        let v = new Date(data[filter.name]);
        v.setHours(0, 0, 0, 0);
        let vTime = v.getTime();

        const date = new Date(filter.value);
        date.setHours(0, 0, 0, 0);
        let time = date.getTime();

        switch (filter.condition) {
          case 'Equal':
            result = time == vTime;
            break;
          case 'GreaterThan':
            result = time < vTime;
            break;
          case 'LessThan':
            result = time > vTime;
            break;
          default:
            break;
        }
      } else if (filter.type === 'Select') {
        let v: string = data[filter.name].toString();
        const fv: string[] = filter.value;
        if (!fv || fv.length === 0) {
          return true;
        }

        return fv.indexOf(v) >= 0;
      }

      if (!result) {
        break;
      }
    }

    return result;
  }
}
