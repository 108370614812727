import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { LoadingLabelType } from 'src/app/shared';

@Component({
  selector: 'app-loading-panel',
  template: `
    <ng-container *ngIf="error; else noError">
      <app-error [error]="error"></app-error>
    </ng-container>
    <ng-template #noError>
      <ng-container *ngIf="state === 'Init'">
        <ng-content select="[init]"></ng-content>
      </ng-container>
      <div
        class="tw-h-full fadeInLoader"
        *ngIf="state === 'Loading'"
        [style.width.em]="width"
      >
        @if(loadingTemplate) {
        <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
        } @else {
        <app-loading-label
          [header]="loadingLabel"
          [type]="loadingStyle"
          [height]="skeletonLines ? skeletonLines * 40 : loadingHeight"
          [imageSrc]="loadingImageSrc"
        ></app-loading-label>
        }
      </div>
      <ng-container *ngIf="state === 'Loaded'">
        <ng-container
          *ngIf="contentTemplate"
          [ngTemplateOutlet]="contentTemplate"
        ></ng-container>
        <ng-content *ngIf="!contentTemplate" select="[content]"></ng-content>
      </ng-container>
      <ng-container *ngIf="state === 'Empty'">
        <ng-container
          *ngIf="emptyTemplate"
          [ngTemplateOutlet]="emptyTemplate"
        ></ng-container>
        <ng-content *ngIf="!emptyTemplate" select="[empty]"></ng-content>
      </ng-container>
    </ng-template>
  `,
  styles: [
    `
      .fadeInLoader {
        animation-duration: 0.25s;
        animation-timing-function: steps(1, end);
        animation-name: fadeInLoader;
        animation-iteration-count: 1;
      }

      @keyframes fadeInLoader {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class LoadingPanelComponent {
  /**
   * Number of lines to display on skeleton loader.
   * Each line height is roughly 40px. If the
   * content is static in height, try to set the
   * number of lines to match the content heigh t to
   * avoid layout shift.
   */
  @Input()
  skeletonLines: number;

  @Input()
  state: LoadingState = 'Init';

  /**
   * Does not display on loadingStyle=Skeleton
   */
  @Input()
  loadingLabel: string;

  @Input()
  loadingStyle: LoadingLabelType = 'Skeleton';

  @Input()
  loadingHeight: number = 200;

  @Input()
  loadingImageSrc: string;

  @ContentChild('content')
  contentTemplate: TemplateRef<any>;

  @ContentChild('empty')
  emptyTemplate: TemplateRef<any>;

  @ContentChild('loadingTemplate')
  loadingTemplate: TemplateRef<any>;

  @Input()
  error: any;

  @Input()
  width: number;
}

export type LoadingState = 'Init' | 'Loading' | 'Loaded' | 'Empty';
