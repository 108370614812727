import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-action-row',
  template: `
    <div class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
      <div class="tw-pr-2">
        <ng-content select="[left1]"></ng-content>
      </div>
      <div *ngIf="!hasTwoColHeader" class="tw-pr-2">
        <ng-content select="[left2]"></ng-content>
      </div>
      <div *ngIf="!hasTwoColHeader" class="tw-pr-2">
        <ng-content select="[left3]"></ng-content>
      </div>

      <div class="tw-grow"></div>
      <div *ngIf="!hasTwoColHeader" class="tw-pr-2">
        <ng-content select="[right3]"></ng-content>
      </div>
      <div *ngIf="!hasTwoColHeader" class="tw-pr-2">
        <ng-content select="[right2]"></ng-content>
      </div>
      <div class="tw-pr-2">
        <ng-content select="[right1]"></ng-content>
      </div>
    </div>
  `,
  styles: [``],
})
export class ActionRowComponent   {
  @Input()
  hasTwoColHeader: boolean = false;

  constructor() {}
}
