import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  BaseComponent,
  NotificationItem,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { ClaimKey } from 'src/app/shared/models/claim-key';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { ActivityRequest } from '../../../shared/models/activity';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { Session } from '../../services/session.service';
import { DismissDialogComponent } from '../activity/dismiss-dialog.component';
// import { ManagerApi, NotificationItem } from '../..';

@Component({
  selector: 'app-notifications-panel',
  template: `
    <app-panel
      title="Claim activity"
      class="tw-w-full"
      [headerLevel]="2"
      [largeHeader]="false"
      [expanded]="notifications?.length > 0"
      [canExpand]="true"
    >
      <app-list
        [dataSource]="notifications"
        [template]="row"
        class="app-pt2"
        [filterEnabled]="showFilter"
        [noDataIcon]="noDataIcon"
        [noDataMessage]="noDataMessage"
        [noDataThinMessage]="noDataThinMessage"
        [toastInfoMessage]="toastInfoMessage"
        [displayInfoToastMsg]="displayInfoToastMsg"
        [toastErrorMessage]="toasErrorMsg"
        [displayErrorToastMsg]="displayErrorToastMsg"
        [tableAriaLabel]="'Claim activity'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full" >
            <app-list-header
              name="date"
              label="Date"
              filterType="Date"
              class="tw-w-full md:tw-w-[25%]"
            ></app-list-header>
            <app-list-header
              name="message"
              label="Message"
              class="tw-w-full md:tw-w-[75%]"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>

      <ng-template #row let-element role=>
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center" >
          <td class="tw-w-full md:tw-w-[25%] cellValue">
            <app-value
              label="Date"
              [value]="element.date | date : 'MM/dd/yyyy'"
              layout="row-xs"
              [tabIndex]="0"
              class="cellValue"
            >
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[75%]">
            <app-value label="Message" layout="row-xs"  [tabIndex]="0">
              <div value class="message" >
                {{ element.message }}
              </div>
            </app-value>
          </td>
        </tr>
      </ng-template>
    </app-panel>
  `,
  styles: [
    `
      .message {
        max-width: 60%;
        word-wrap: normal;
        word-break: normal;
        font-size: 13px;
        padding: 4px 12px 0 0;
      }
      .cellValue {
      }
      .cellValue:focus-visible {
        outline: 2px solid black;
      }
    `,
  ],
})
export class NotificationsPanelComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  notifications: NotificationItem[];

  @Input()
  claimInfo$ = new Observable<ClaimInfo>();

  @Input()
  showFilter: boolean = true;

  @Input()
  showClaimLink: boolean = true;

  saveAlertRequest: SaveAlertActionRequest;

  displayErrorToastMsg: boolean = false;
  displayInfoToastMsg: boolean = false;
  toastInfoMessage: string;
  toasErrorMsg: string;
  noDataIcon: string = 'list_alt';
  noDataMessage: string = 'No claim activity';
  noDataThinMessage: string = 'You can expect future milestones to appear here';
  activityRequest: ActivityRequest;

  constructor(
    public dialog: MatDialog,
    private activityService: ActivityService,
    public session: Session,
    private router: Router,
    private snackbarService: SnackbarService
  ) {
    super();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.subs.sink = this.claimInfo$.subscribe((claimInfo) => {
      const claimKeys: ClaimKey[] = [];
      const claimKey = new ClaimKey();
      claimKey.claimId = claimInfo.claimId;
      claimKey.source = claimInfo.source;
      claimKey.lob = claimInfo.lob;
      claimKey.userOwns = true;
      claimKeys.push(claimKey);

      this.activityRequest = {
        includeIndirects: false,
        userActions: UserActions.ViewOwnClaim,
        claimKeys: claimKeys,
        userOwns: true,
      };

      const fullPath = 'api/manager/claim/claim-activity';
      //setting action to empty string
      this.activityService
        .postActivities(fullPath, this.activityRequest)
        .then((x) => {
          //this.notifications = x.notifications
          //console.log(x);
          this.notifications = x;
        });
    });
  }

  dismiss(claim: any) {
    if (
      this.session.user &&
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackbarService.show(
        'Preview only mode.  dismiss action was not actually taken.',
        SnackbarType.Info
      );
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      saveAlertRequest: this.saveAlertRequest,
    };
    this.dialog.open(DismissDialogComponent, dialogConfig);
  }
}
