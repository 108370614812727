import { Injectable } from '@angular/core';
import { GlobalIntakeData } from '../models/globalintakedata';
import { GlobalIntakeResponse } from '../models/globalintakeresponse';
import { Session } from './session.service';
import { Http } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class IntakeService {
  constructor(private http: Http, private session: Session) {}

  async getSmartlyUrl(globalIntakeData?: GlobalIntakeData) {
    await this.session.loadUser();
    if (!globalIntakeData) {
      globalIntakeData = new GlobalIntakeData({
        giftCard: false,
        timeZone: this.session.user.timezone,
      });
    }
    return await this.http.post<GlobalIntakeResponse>(
      '/api/intake/BeginGlobalIntake',
      globalIntakeData
    );
  }
}
