import { Pipe, PipeTransform } from '@angular/core';
import { join } from '..';

/**
 * Joins values with seperator. Empty values are not included. If error, returns values?.toString.  Default seperator is ' - '.
 */
@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(values: string[], seperator: string = ' - '): string {
    return join(values, seperator);
  }
}
