import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  BaseComponent,
  RouterService,
  SnackbarService,
  SnackbarType,
  getUrlParams,
  scrollToElement,
  setUrlParam,
} from 'src/app/shared';
import { SearchResult } from 'src/app/shared/models/search-result';
import { CommunicationService } from '../..';
import { SendMessage } from '../../models/Communications';
import { ClaimService } from '../../services/claim.service';
import { emulator } from '../../services/emulator.service';
import { FormService } from '../../services/form.service';
import { Session } from '../../services/session.service';

/**
 *
 */
@Component({
  selector: 'app-forms-view',
  template: `
    <div class="app-pl2 tw-w-full">
      <app-claim-breadcrumb></app-claim-breadcrumb>
    </div>
    <app-panel class="form-header" [showTitle]="false" [canExpand]="false">
      <div
        class="tw-flex tw-flex-wrap tw-w-full tw-items-center full-width-welcome"
      >
        <h1 class="div-header-h1">
          {{ this.name.replaceAll('%20', ' ').replaceAll('_', ' ') }}
        </h1>
        <ng-container content *ngIf="formService.loaded">
          <app-section class="tw-w-full">
            <div class="app-pt1 fourty-stack">
              <app-claimant-label
                value
                [claimant]="claim"
                [nameClass]="'commCenterName'"
                [showNameLabel]="true"
                [disabled]="true"
              >
              </app-claimant-label>
            </div>
            <div class="app-pb2 sixty-stack">
              <div class="sixty-right">
                <app-claim-label
                  value
                  [claim]="claim"
                  layout="row"
                  [showNameLabel]="true"
                  [disabled]="false"
                  [noLink]="false"
                ></app-claim-label>
              </div>
            </div>
          </app-section>
          <div class="form-timeline tw-w-full" *ngIf="status !== 'Download'">
            <app-timeline>
              <app-timeline-item
                description1="Edit"
                [status]="
                  status === 'Entry' || status === 'Authorize'
                    ? 'Pending'
                    : 'Success'
                "
              ></app-timeline-item>
              <app-timeline-item
                description1="Review"
                [status]="
                  status === 'Entry' || status === 'Authorize'
                    ? 'Unknown'
                    : status === 'Review'
                    ? 'Pending'
                    : 'Success'
                "
              ></app-timeline-item>
              <app-timeline-item
                description1="SUBMITTED"
                [status]="status !== 'Submitted' ? 'Unknown' : 'Success'"
              ></app-timeline-item>
            </app-timeline>
          </div>
          <div class="tw-w-full" *ngIf="status === 'Authorize'">
            <div *ngIf="!formService.needsAuthorize(type)">
              To complete this form, please download and print the form,
              complete and sign. Once the form has been completed you can upload
              it through mySedgwick's Upload Document feature using the Upload
              button below.
            </div>
            <div *ngIf="formService.needsAuthorize(type)">
              To help expedite the claim process, please read and authorize the
              following document.
            </div>
          </div>
        </ng-container>
      </div>
    </app-panel>
    <app-loading-panel
      [skeletonLines]="8"
      id="formPanel"
      [state]="formService.loaded ? 'Loaded' : 'Loading'"
    >
      <ng-container content *ngIf="formService.loaded" class="outer-buttons">
        <div *ngIf="status === 'Review'" class="review">
          Review form then press Submit
        </div>
        <form *ngIf="status !== 'Submitted'">
          <div class="outer-container app-mt2">
            <div
              class="form-container mat-elevation-z2 letter"
              id="form-container"
              [innerHTML]="formService.html | safeHtml"
            ></div>
          </div>
          <div class="app-pt4"></div>
        </form>
        <div
          class="tw-w-full tw-max-w-screen-xl"
          *ngIf="
            status === 'Download' ||
            status === 'Review' ||
            status === 'Entry' ||
            status === 'Authorize'
          "
          style="text-align: right;"
        >
          <div *ngIf="status === 'Download'">
            <app-button right2 emphasis="High" (click)="onDownloadPdf()">
              Download
            </app-button>
            &nbsp;
            <app-button
              right2
              emphasis="High"
              routerLink="/upload-documents-in-documentstab"
              [queryParams]="{
              focus: 'documents',
              claimId: claim.claimId,
              source: claim.source,
              showDialog: true,
            }"
              >Upload</app-button
            >
          </div>
          <div *ngIf="formService.needsAuthorize(type)" class="outer-buttons">
            <table class="letter">
              <tr>
                <td colspan="2" class="auth-ipsum">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </td>
              </tr>
              <tr *ngIf="formService.needsAuthorizeAndYesNo(type)">
                <td style="text-align:right;">
                  Do you authorize the form as indicated above?
                </td>
                <td class="auth-right-col">
                  <span class="sub-text"
                    ><span class="web-form-group"
                      ><input
                        type="radio"
                        id="authorizeYes"
                        name="authorize"
                        class="web-form-required"
                        [value]="true"
                        [ngModel]="authorize"
                        (input)="toggleInitials()"
                      /><label for="authorizeYes">Yes</label>
                      &nbsp;
                      <input
                        type="radio"
                        id="authorizeNo"
                        name="authorize"
                        class="web-form-required"
                        [value]="false"
                        [ngModel]="authorize"
                        (input)="toggleInitials()"
                      /><label for="authorizeNo" class="blue-required"
                        >No</label
                      ></span
                    ></span
                  >
                </td>
              </tr>
              <tr *ngIf="showInitials">
                <td style="text-align:right;">
                  <span *ngIf="!formService.needsAuthorizeAndYesNo(type)">
                    If you authorize the {{ type.toLowerCase() }} as indicated
                    above, enter
                  </span>
                  <span *ngIf="formService.needsAuthorizeAndYesNo(type)">
                    Enter
                  </span>
                  your initials:
                </td>
                <td>
                  &nbsp;
                  <input
                    id="initials"
                    placeholder=" Initial*"
                    type="text"
                    class="web-form-required"
                    [class]="needInitials ? 'web-form-error' : ''"
                    [class]="
                      !formService.needsAuthorizeAndYesNo(type)
                        ? 'initials-lift'
                        : 'initials-drop'
                    "
                    style="width:75px;"
                    required="required"
                    [value]="initials"
                    (keyup)="onInitialsChange($event)"
                  /><span
                    class="required"
                    *ngIf="needInitials"
                    [class]="
                      !formService.needsAuthorizeAndYesNo(type)
                        ? 'required-low'
                        : 'required-lower'
                    "
                    >Required</span
                  >
                </td>
              </tr>
              <tr>
                <td style="text-align:right;" colspan="2">
                  {{ currentTime | date : 'MMMM d, yyyy h:mm a' }} &nbsp;&nbsp;
                </td>
              </tr>
            </table>
          </div>
        </div>
        <app-action-row
          class="tw-w-full letter"
          *ngIf="status === 'Entry' || status === 'Authorize'"
        >
          <app-button
            right1
            emphasis="Low"
            (click)="dialog.open(cancelTemplate)"
          >
            Cancel
          </app-button>
          <app-button right2 emphasis="High" (click)="onValidate()">
            Review
          </app-button>
        </app-action-row>
        <app-action-row class="tw-w-full letter" *ngIf="status === 'Review'">
          <app-button
            right1
            emphasis="Low"
            (click)="dialog.open(cancelTemplate)"
          >
            Cancel
          </app-button>
          <app-button right3 emphasis="Low" (click)="onEdit()">
            Edit
          </app-button>
          <app-button right2 emphasis="High" type="submit" (click)="onSubmit()">
            Submit
          </app-button>
        </app-action-row>
        <div class="app-pt2"></div>
        <div *ngIf="status === 'Submitted'" class="app-pt4 form-icon">
          <app-empty-state-label
            icon="task"
            header="Form Submitted"
            message="Your form has been submitted."
          >
            <!--<app-button (click)="onSaveTxt()">Save txt</app-button>-->
            <div *ngif="formService.notSIR(this.type)">
              <app-button (click)="onSavePdf()">Save pdf</app-button>
            </div>
          </app-empty-state-label>
        </div>
      </ng-container>
    </app-loading-panel>
    <ng-template #cancelTemplate>
      <app-dialog
        title="Cancel form"
        ariaLabel="Cancel dialog"
        [showHeader]="true"
        [showActions]="true"
        [confirmLabel]="'Confirm'"
        [cancelLabel]="'Close'"
        (confirmEvent)="cancelConfirm()"
        (cancelEvent)="cancelClosed()"
      >
        Press Confirm to cancel form.
      </app-dialog>
    </ng-template>
  `,
  styles: [
    `
      .prevpage {
        color: #007dbc;
        font-weight: bold;
        font-size: 12pt;
      }
      .prevpage:hover {
        text-decoration: none;
        border-bottom: 1px solid #035e81;
      }
      .currentpage {
        color: #171c20 !important;
        opacity: 1 !important;
        font-size: 12pt;
      }

      .pre {
        white-space: pre-wrap;
      }

      button {
        border: 1px solid silver;
        border-radius: 15px;
        padding: 5px;
        color: #244578;
        font-weight: bold;
      }

      .review {
        font-size: 22px;
        font-weight: bold;
        padding-top: 32px;
      }

      .outer-container {
        background-color: var(--color-light-grey);
        padding: 40px;
      }

      .form-container {
        background-color: white;
        padding: 20px;
      }

      .div-header-h1 {
        padding-top: 20px !important;
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 20px;
      }

      .dialog-title {
        color: black;
        font-weight: bold;
        font-size: 18px;
      }

      .dialog-x {
        float: right;
        cursor: pointer;
        color: black;
        font-weight: bold;
        font-size: 18px;
      }

      .formcrumb {
        font-family: 'Source Sans Pro', 'helvetica neue', Arial, Helvetica,
          sans-serif;
      }

      .letter {
        max-width: 1000px;
        margin: auto;
      }

      .web-form-required {
        border: 2px solid #a0d1ec;
        border-radius: 5px;
      }

      .web-form-error {
        border-color: #f44336;
      }

      .required {
        color: #f44336;
        position: relative;
        left: -50px;
        font-size: 12px;
        width: 0;
        display: inline-block;
        overflow: visible;
      }

      .required-low {
        top: 14px;
      }

      .required-lower {
        top: 23px;
      }

      .web-form-readonly {
        border-color: #d0d0d0;
      }

      .outer-buttons {
        padding: 10px;
      }

      .auth-right-col {
        width: 100px;
      }

      .auth-ipsum {
        display: block;
        height: 1px;
        overflow: hidden;
      }

      .initials-lift {
        position: relative;
        top: -5px;
      }
      .initials-drop {
        position: relative;
        top: 5px;
      }

      @media (min-width: 640px) {
        .fourty-stack {
          display: inline-block;
          width: 40%;
        }
        .sixty-stack {
          display: inline-block;
          width: 60%;
        }
        .sixty-right {
          float: right;
        }
      }
      @media (max-width: 639px) {
        .fourty-stack {
          display: block;
          width: 100%;
        }
        .sixty-stack {
          display: block;
          width: 100%;
        }
      }
      @media (max-width: 480px) {
        .outer-container {
          background-color: var(--color-light-grey);
          padding: 10px;
        }
        .form-container {
          background-color: white;
          padding: 10px;
        }
      }
    `,
  ],
})
export class FormPageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input()
  claim: SearchResult = <SearchResult>{};
  status: FormStatus = 'Entry';
  previousUrlLink: string;
  previousUrlLabel: string;
  name: string;
  type: string;
  downloadOnly: string;
  flNum: string;
  authorize: boolean = false;
  needInitials: boolean = false;
  showInitials: boolean = false;
  initials: string = '';
  currentTime: number = Date.now();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public formService: FormService,
    private snackbar: SnackbarService,
    public routerService: RouterService,
    private claimService: ClaimService,
    public session: Session,
    private enumalatorService: emulator,
    private communicationService: CommunicationService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    window.requestAnimationFrame(() => {
      this.authorize = !this.formService.needsAuthorizeAndYesNo(this.type);
      if (this.formService.needsAuthorize(this.type)) {
        this.setStatus('Authorize');
      } else if (
        this.flNum.toLowerCase().includes('.pdf') &&
        !this.flNum.toLowerCase().includes('.htm')
      ) {
        this.setStatus('Download');
      } else if (this.downloadOnly === 'true') {
        this.setStatus('Download');
      } else if (this.status !== 'Entry' && !this.formService.pdfBin) {
        this.setStatus('Entry');
      } else {
        this.formService.setReadOnly(this.status !== 'Entry');
      }
      this.formService.setDownloadOnly(this.downloadOnly === 'true');
    });
  }

  onEdit() {
    this.setStatus('Entry');
    this.formService.setReadOnly(false);
    this.setAuthReadOnly(false);
  }

  setAuthReadOnly(readOnly: boolean) {
    const initialsElement = document.getElementById('initials');
    const yesElement = document.getElementById('authorizeYes');
    const noElement = document.getElementById('authorizeNo');
    this.formService.setReadOnlyElement(readOnly, initialsElement);
    this.formService.setReadOnlyElement(readOnly, yesElement);
    this.formService.setReadOnlyElement(readOnly, noElement);
    if (readOnly) {
      this.setStatus('Review');
    }
  }

  async onValidate() {
    if (this.formService.needsAuthorize(this.type)) {
      if (this.showInitials) {
        this.onInitialsChange(null);
        if (this.needInitials) {
          this.snackbar.show(
            'Please correct missing or invalid values',
            SnackbarType.Error
          );
        } else {
          this.setAuthReadOnly(true);
        }
      } else {
        this.setAuthReadOnly(true);
      }
    } else {
      this.setStatus((await this.formService.validate()) ? 'Review' : 'Entry');
      if (this.status === 'Entry') {
        this.snackbar.show(
          'Please correct missing or invalid values',
          SnackbarType.Error
        );
      } else {
        this.formService.setReadOnly(true);
        scrollToElement(null, true);
      }
    }
  }

  async onSubmit() {
    const authorizeYes = document.getElementById(
      'authorizeYes'
    ) as HTMLInputElement;
    if (await this.formService.validate()) {
      this.enumalatorService.showAuditorInfo();
      if (this.formService.needsAuthorize(this.type)) {
        const message = new SendMessage();
        message.authorization = authorizeYes.checked ? 'Yes' : 'No';
        message.initials = this.initials;
        message.procUnit = this.claim.procUnit;
        message.vendorId = this.claim.vendorId;
        message.formType = this.type;
        message.claimNumber = this.claim.claimNumber;
        message.messageId = '';
        message.examinerName = this.claim.examinerName;
        message.messageText = `
Claimant: ${this.claim.name}
DateOfResponce: ${new Date(this.currentTime).toUTCString()}
ClaimId: ${this.claim.claimId},
LineOfBusiness: ${this.claim.lob},
AgreementType: ${this.name},
VendorId: ${this.claim.vendorId},
ClaimNumber: ${this.claim.claimNumber},
Examiner: ${this.claim.examinerName},
        `;
        if (authorizeYes && authorizeYes.checked) {
          // Submit to SMART Accepted
          message.messageText += `
AuthorizationResponse: Yes,
Initials: ${this.initials},
        `;
          await this.formService.saveMessage(
            this.claim.source,
            this.claim.claimId,
            this.claim.lob,
            message
          );
          this.setStatus('Submitted');
        } else {
          // submit to SMART Rejected
          message.messageText += `
Authorization Response: No,
        `;
          await this.formService.saveMessage(
            this.claim.source,
            this.claim.claimId,
            this.claim.lob,
            message
          );
          this.setStatus('Submitted');
        }
      } else {
        this.formService.submit();
        this.setStatus('Submitted');
      }
    }
  }

  onSaveTxt() {
    this.formService.savetoText();
  }

  onSavePdf() {
    this.formService.savetoPdf();
  }

  async onDownloadPdf() {
    if (
      !this.flNum.toLowerCase().includes('.pdf') &&
      !this.flNum.toLowerCase().includes('.htm')
    ) {
      await this.formService.createBlobs(true);
    }
    this.formService.savetoPdf();
  }

  async ngOnInit() {
    this.formService.init();
    const params = getUrlParams();
    this.name = params.name;
    this.type = params.type;
    this.downloadOnly = params.downloadOnly;
    this.flNum = params.flNum;
    if (params.status) {
      this.setStatus(params.status);
    }
    this.claim = await this.claimService.getSummary(
      params.source,
      params.claimId
    );
    //this.setBreadcrumb(this.routerService.previousUrl);
  }

  cancelConfirm() {
    this.dialog.closeAll();
    const params = getUrlParams();
    this.router.navigate(['/claims'], {
      queryParams: {
        source: params.source,
        claimId: params.claimId,
        focus: 'documents',
      },
    });
  }

  cancelClosed() {
    this.dialog.closeAll();
  }

  setStatus(status: FormStatus) {
    this.status = status;
    setUrlParam('status', this.status);
  }

  onInitialsChange(e: any) {
    if (e && e.srcElement) {
      this.initials = e.srcElement.value;
    }
    this.toggleInitials();
  }

  toggleInitials() {
    const authorizeYes = document.getElementById(
      'authorizeYes'
    ) as HTMLInputElement;
    this.showInitials = authorizeYes.checked;
    this.needInitials = authorizeYes.checked && this.initials.length === 0;
  }

  private setBreadcrumb(prevUrl: string) {
    this.previousUrlLink = prevUrl;
    this.previousUrlLabel =
      prevUrl === '/search'
        ? 'Search'
        : prevUrl === '/history'
        ? 'History'
        : prevUrl === '/watchlist'
        ? 'Watch List'
        : 'Home';

    if (this.previousUrlLabel === 'Home') {
      this.previousUrlLink = '';
    }
  }
}

type FormStatus = 'Entry' | 'Authorize' | 'Review' | 'Submitted' | 'Download';
