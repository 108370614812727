import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpenseRequest } from '../../models/mileage-request';
import { UserMileageReimbursementDetail } from '../../models/Mileages';

@Component({
  selector: 'app-expense-edit',
  template: `
    <div class="app-p3 tw-w-full tw-flex tw-flex-wrap">
      <div class="app-pb2 tw-w-full">
        <app-header-label type="Header1">{{ title }}</app-header-label>
      </div>

      <form
        [formGroup]="formGroup"
        (ngSubmit)="update()"
        class="tw-w-full tw-flex tw-flex-wrap"
      >
        <!-- <app-select-control
          formControlName="reasonPrimary"
          [formGroup]="formGroup"
          label="Primary Reason"
          [options]="[{ label: 'Other Time Off', value: 'OTH' }]"
          ngDefaultControl
          class="tw-w-full"
        ></app-select-control>

        <app-select-control
          formControlName="reasonSecondary"
          [formGroup]="formGroup"
          label="Secondary Reason"
          [options]="[{ label: 'Misc', value: 'MSC' }]"
          ngDefaultControl
          class="tw-w-full"
        ></app-select-control>
        <app-date-control
          formControlName="beginDate"
          [formGroup]="formGroup"
          label="Begin Date"
          ngDefaultControl
          class="tw-w-full"
        ></app-date-control>
        <app-date-control
          formControlName="endDate"
          [formGroup]="formGroup"
          label="End Date"
          ngDefaultControl
          class="tw-w-full"
        ></app-date-control>
        <app-text-control
          formControlName="shifts"
          [formGroup]="formGroup"
          label="Shifts"
          ngDefaultControl
          class="tw-w-full"
        ></app-text-control>
        <app-date-control
          formControlName="estimatedRtw"
          [formGroup]="formGroup"
          label="Estimated Return To Work"
          ngDefaultControl
          class="tw-w-full"
        ></app-date-control> -->

        <app-text-control
          formControlName="expenseType"
          [formGroup]="formGroup"
          label="Expense Type"
          ngDefaultControl
          class="tw-w-full"
        ></app-text-control>

        <app-text-control
          formControlName="amountPaid"
          [formGroup]="formGroup"
          label="Amount Paid"
          ngDefaultControl
          mask="separator.2"
          class="tw-w-full"
        ></app-text-control>

        <app-action-row class="tw-w-full">
          <app-button right2 emphasis="High" type="submit">Save</app-button>
          <app-button right1 emphasis="Low" (click)="dialogRef.close()">
            Cancel
          </app-button>
        </app-action-row>
      </form>
    </div>
  `,
  styles: [],
})
export class ExpenseEditComponent implements OnInit {
  @Input()
  reimbursementDetail: UserMileageReimbursementDetail;
  expense: ExpenseRequest;

  title: string;

  formGroup = this.fb.group({
    expenseType: this.fb.control<string | null>(null, Validators.required),
    amountPaid: this.fb.control<number | null>(null, Validators.required),
  });
  async ngOnInit() {}
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.expense = data.expense;
    this.title = data.title;
    this.formGroup.value.amountPaid = this.expense.amountPaid;
    this.formGroup.value.expenseType = this.expense.expenseType;
  }

  update() {
    if (this.formGroup.valid) {
      this.expense.amountPaid = Number.parseFloat(
        this.formGroup.value.amountPaid.toString()
      );
      this.expense.expenseType = this.formGroup.value.expenseType;
      this.dialogRef.close(this.expense);
    }
  }
}
