import { Component, Input } from '@angular/core';
import { ChartConfig, OmniDashboard, Session } from 'src/app/my-sedgwick';
import { LoadingState } from 'src/app/shared';

@Component({
  selector: 'app-open-claims-card',
  template: `
    <app-panel
      title="Open claims"
      [labelTemplate]="details"
      class="tw-cursor-pointer"
      routerLink="/summary/open-claims"
      [headerLevel]="2"
      [largeHeader]="false"
      [canExpand]="false"
      tabindex="-1"
    >
      <ng-template #details>
        <div class="tw-w-full tw-flex tw-justify-end">
          <a
            class="tw-flex tw-items-center"
            *ngIf="omniDashboard"
            routerLink="/summary/open-claims"
            aria-label="Go to Open claims details page"
          >
            <div class="app-pr1">DETAILS</div>
          </a>
        </div>
      </ng-template>
      <div class="tw-h-[345px]">
        <app-loading-panel
          loadingStyle="Box"
          [loadingHeight]="300"
          [state]="loadingState"
          class="tw-h-full"
          [error]="error"
        >
          <ng-template #empty>
            <div
              class="tw-flex tw-items-center tw-w-full tw-h-full tw-justify-around"
            >
              <app-empty-state-label
                icon="data_usage"
                header="No open claims"
                x-message="Open claims will appear here"
              >
              </app-empty-state-label>
            </div>
          </ng-template>
          <ng-template #content>
            <app-card-chart [config]="config"></app-card-chart>
          </ng-template>
        </app-loading-panel>
      </div>
    </app-panel>
  `,
  styles: [``],
})
export class OpenClaimsCardComponent {
  config: ChartConfig;

  private _omniDashboard: OmniDashboard;

  lobs: any[];

  @Input()
  error: any;

  constructor(private session: Session) {
    this.lobs = this.session.user.lineOfBusiness;
  }

  @Input()
  set omniDashboard(value: OmniDashboard) {
    this._omniDashboard = value;
    if (!value) {
      return;
    }
    this.loadingState =
      value.openClaimsWc +
        value.openClaimsDs +
        value.openClaimsLv +
        value.openClaimsAd >
      0
        ? 'Loaded'
        : 'Empty';
    this.config = new ChartConfig();
    if (this.lobs.filter((x) => x.lob === 'WC').length > 0) {
      this.config.addSeries(
        'Workers Compensation',
        value.openClaimsWc,
        '#3f7c4f'
      );
    }
    if (this.lobs.filter((x) => x.lob === 'DS').length > 0) {
      this.config.addSeries('Disability', value.openClaimsDs, '#0775b2');
    }
    if (this.lobs.filter((x) => x.lob === 'LV').length > 0) {
      this.config.addSeries('Leave', value.openClaimsLv, '#6E3882');
    }
    if (this.lobs.filter((x) => x.lob === 'AD').length > 0) {
      this.config.addSeries(
        'Accommodation Request',
        value.openClaimsAd,
        '#01334c'
      );
    }
  }
  get omniDashboard(): OmniDashboard {
    return this._omniDashboard;
  }

  loadingState: LoadingState = 'Loading';
}
