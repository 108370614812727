import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastType, hideString } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { AuthPreference } from '../../models/AuthPreference';
import { AccountSettingsService } from '../../services/accountsettings.service';
import { emulator } from '../../services/emulator.service';
import { Session } from '../../services/session.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-change-authentication-preferences-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-section title="Authentication Preferences"></app-section>
    <div
      class="changeAuthenticationPreferencesContent"
      id="changeAuthDiv"
      *ngIf="initialLoad"
    >
      <div class="authenticationpreferences-container">
        <div class="tw-flex tw-flex-wrap tw-flex-col">
          <span class="tw-w-full">
            The email address and mobile number below will be used to receive
            multi-factor authentication codes.<br />
            Standard text messaging rates will apply.
            <br /><br />
            <strong
              >Please specify the methods and contact information to receive
              your codes:</strong
            >
          </span>
          <br />
          <form [formGroup]="MFAGroup">
            <!--<div *ngIf="authPreference.email!=null || authPreference.email!=undefined">-->
            <app-toggle-component
              [emailAddressText]="emailAddress"
              [phoneNumberText]="mobileNumber"
              formGroupName="toggleGroup"
              (displayButtons)="displayButtons($event)"
              (clearFields)="clearFields($event)"
              (AuthCodeChanged)="AuthCodeChanged($event)"
              (editComp)="editComp($event)"
              [mfaComp]="isMFAComp"
              [editEmailComponent]="closeFieldsEmail"
              [editPhoneComponent]="closeFieldsphone"
              [shwErrMsg]="showErrorMessage"
              [changeTrigger]="changeTrigger"
              [changeAuthenticationComp]="true"
              [emailAddMatch]="emailMatch"
              [showEditPhone]="showEditPhone"
              [showEditEmail]="showEditEmail"
              *ngIf="viewModelLoaded"
              (enableSaveButton)="enableSaveButton($event)"
            >
            </app-toggle-component>
            <br />
            <div class="tw-w-[70%] toaster" *ngIf="enableInitialToast">
              <div class="md:tw-w-[90%] ">
                <app-toast type="Info">
                  <div [innerHTML]="verifyToastMessage"></div>
                </app-toast>
              </div>
            </div>
            <div class="tw-w-[70%] toaster" *ngIf="showErrorToast">
              <div class="md:tw-w-[90%] ">
                <app-toast type="Error" icon="close">
                  <div [innerHTML]="errorToastMessage"></div>
                </app-toast>
              </div>
            </div>
            <br />
            <div class="tw-w-[60%]" id="AuthCode" *ngIf="showVerifyCode">
              <span class="text-email"
                >Enter the verification code that you recieved :
              </span>
              <form [formGroup]="verifyCodeGroup">
                <ul role="none" class="md:tw-w-[70%]">
                  <li role="none">
                    <mat-form-field>
                      <mat-label class="text-email">Enter code</mat-label>
                      <input
                        type="text"
                        matInput
                        required
                        id="verifyCodeField"
                        formControlName="verifyCodeField"
                        ngDefaultControl
                        fxFlex="33"
                        fxFlex.lt-md="100"
                      />
                    </mat-form-field>
                  </li>
                </ul>
              </form>
            </div>
            <br />
            <div>
              <app-toast
                *ngIf="enableToast"
                [type]="toastType"
                [icon]="toastIcon"
              >
                <div [innerHTML]="toastMessage"></div>
              </app-toast>
            </div>
            <br />
            <div class="flex-left"></div>
            <div class="flex-right">
              <app-button
                [id]="'saveAuthPreferences'"
                emphasis="High"
                (click)="OnSave()"
                (keydown.enter)="OnSave()"
                type="submit"
                mat-raised-button
                [loading]="sending"
                [arialabel]="'Save Authentication Preferencs'"
              >
                Save
              </app-button>
              <span class="app-button-margin"></span>

              <app-button
                [id]="'cancelAuthPrefs'"
                emphasis="Low"
                (click)="onCancelMFA()"
                (keydown.enter)="onCancelMFA()"
                type="submit"
                mat-raised-button
                [arialabel]="'Cancel Authentication Preferences Changes'"
              >
                Cancel
              </app-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #changeAuthDiv ::ng-deep .mat-form-field-outline {
        color: #67707a;
        border-color: #67707a;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #changeAuthDiv ::ng-deep .mat-form-field-outline:focus {
        color: #171c20;
        border-color: #007dbc;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #changeAuthDiv ::ng-deep .mat-form-field-outline:hover {
        color: #67707a;
        border-color: #3f464d;
      }

      .btn-info:hover {
        background-color: #035e81;
      }
      .btn-info {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background-color: #007dbc;
        font-weight: bold;
      }

      .btn-cancel:hover {
        background-color: #f1f4f7;
      }
      .btn-cancel {
        font-size: 15px;
        color: #3f464d;
        line-height: 30px;
        border-radius: 40px;
        box-shadow: none;
        font-weight: bold;
        background-color: #ffffff;
        border: #3f464d;
      }

      .button {
        background-color: var(--dark-blue);
        margin: 5px;
        color: white;
      }

      .text-email {
        font-weight: bold;
      }
      .flex-right {
        float: right;
      }
      .flex-left {
        width: 70%;
      }
    `,
  ],
})
export class ChangeAuthenticationPageComponent implements OnInit {
  getAuthenticationForm: FormGroup = this.fb.group({});
  emailCheck: boolean;
  mobileCheck: boolean;
  emailAddress: string;
  confirmEmailAddress: string;
  mobileNumber: string;
  authPreference: AuthPreference;
  checkForSubmit: boolean = true;
  isDataExisting = false;
  toastMessage: string = '';
  toastType: ToastType;
  MFAGroup: FormGroup;
  verifyCodeGroup: FormGroup;
  request: any;
  isDisplayButtons: boolean = false;
  displayResendCode: boolean = false;
  displaySave: boolean = false;
  isMFAComp: boolean;
  isEmailPhoneChecked: boolean;
  viewModelLoaded: boolean = false;
  clearEmailFields: boolean;
  clearMobileFields: boolean;
  closeFieldsEmail: boolean;
  closeFieldsphone: boolean;
  @Output()
  mfaPanelExpanded = new EventEmitter();
  toastIcon: string;
  enableToast: boolean = false;
  enableInitialToast: boolean = false;
  isEmailAuthCode: boolean;
  isPhoneAuthCode: boolean;
  isEmailComp: boolean;
  isPhoneComp: boolean;
  showErrorMessage: boolean;
  showToggleComp: boolean;
  changeTrigger = 1;
  emailNoMatch: boolean;
  emailToggleUpdated: boolean = false;
  mobileToggleUpdated: boolean = false;
  showEditEmail: boolean = false;
  showEditPhone: boolean = false;
  result: any;
  initialLoad: boolean = false;
  verifyToastMessage: string;
  errorToastMessage: string;
  showErrorToast: boolean;
  showVerifyCode: boolean;
  emailMatch: boolean = true;
  sending: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private el: ElementRef,
    private accountService: AccountSettingsService,
    private changeDetector: ChangeDetectorRef,
    private http: Http,
    private httpclient: HttpClient,
    private util: UtilityService,
    private enumalatorService: emulator,
    public session: Session
  ) {}

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change: SimpleChange = changes['isDisplayButtons'];
    const change1: SimpleChange = changes['displaySave'];
    const change2: SimpleChange = changes['enableToast'];
    const change3: SimpleChange = changes['emailAddress'];
    const change4: SimpleChange = changes['mobileNumber'];
    if (change != undefined) {
      if (change.currentValue != null && change.currentValue != undefined) {
        this.isDisplayButtons = change.currentValue;
      }
    }
    if (change1 != undefined) {
      if (change1.currentValue != null && change1.currentValue != undefined) {
        this.displaySave = change1.currentValue;
      }
    }
    if (change2 != undefined) {
      if (change2.currentValue != null && change2.currentValue != undefined) {
        this.enableToast = change2.currentValue;
      }
    }
    if (change3 != undefined) {
      if (change3.currentValue != null && change3.currentValue != undefined) {
        this.emailAddress = change3.currentValue;
      }
    }
    if (change4 != undefined) {
      if (change4.currentValue != null && change4.currentValue != undefined) {
        this.mobileNumber = change4.currentValue;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    await this.GetAuthPreferences();
    this.MFAGroup = this.fb.group({
      toggleGroup: this.fb.group({
        emailEnabledForm: [this.authPreference.emailAuthEnabled],
        mobileEnabledForm: [this.authPreference.textAuthEnabled],
      }),
    });

    const toggleGroup = this.fb.group({
      emailEnabledForm: [this.authPreference.emailAuthEnabled],
      mobileEnabledForm: [this.authPreference.textAuthEnabled],
    });

    this.MFAGroup.addControl('toggleGroup', toggleGroup);
    this.initialLoad = true;
    this.viewModelLoaded = true;
    this.changeDetector.detectChanges();

    this.verifyCodeGroup = this.fb.group({
      verifyCodeField: ['', Validators.required],
    });

    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.emailAddress = hideString(this.emailAddress, '*');
      this.mobileNumber = hideString(this.mobileNumber, '*');
    }
  }

  enableSaveButton(event: any) {
    this.displaySave = event;
  }

  checkBeforeSubmitEmail(): boolean {
    this.emailMatch = false;
    const dataTogGrp = this.MFAGroup.controls['toggleGroup'] as FormGroup;
    const dataemailGroup = dataTogGrp.controls['emailGroup'] as FormGroup;

    if (dataemailGroup.valid) {
      this.emailAddress = dataemailGroup.controls['emailAddress'].value;
      this.confirmEmailAddress =
        dataemailGroup.controls['confirmEmailAddress'].value;

      if (
        this.emailAddress != '' &&
        this.confirmEmailAddress != '' &&
        dataemailGroup.controls['emailAddress'].value ===
          dataemailGroup.controls['confirmEmailAddress'].value
      ) {
        this.checkForSubmit = true;
      } else {
        this.checkForSubmit = false;
        this.emailMatch = false;
        this.viewModelLoaded = true;
        this.changeDetector.detectChanges();
      }
      return this.checkForSubmit;
    } else {
      return false;
      this.sending = false;
    }
  }

  checkBeforeSubmitPhone(): boolean {
    const dataTogGrp = this.MFAGroup.controls['toggleGroup'] as FormGroup;
    const dataphoneGroup = dataTogGrp.controls['phoneGroup'] as FormGroup;
    if (dataphoneGroup.valid) {
      this.mobileNumber = dataphoneGroup.controls['mobilenumber'].value;
      if (this.mobileNumber != '') {
        this.checkForSubmit = true;
      } else {
        this.checkForSubmit = false;
      }
    } else {
      dataphoneGroup.controls['mobilenumber'].markAsTouched();
      this.changeDetector.detectChanges();
      this.sending = false;
      this.checkForSubmit = false;
    }
    return this.checkForSubmit;
  }

  clearFields(event: any) {
    if (event == 'email') {
      this.clearEmailFields = true;
    }
    if (event == 'phone') {
      this.clearMobileFields = true;
    }
  }
  AuthCodeChanged(event: any) {
    if (event == 'emailAuth') {
      this.isEmailAuthCode = true;
      this.isPhoneAuthCode = false;
    }
    if (event == 'phoneAuth') {
      this.isEmailAuthCode = false;
      this.isPhoneAuthCode = true;
    }
  }

  onCancel(): void {
    const dataTogGrp = this.MFAGroup.controls['toggleGroup'] as FormGroup;
    const dataemailGroup = dataTogGrp.controls['emailGroup'] as FormGroup;
    const dataphoneGroup = dataTogGrp.controls['phoneGroup'] as FormGroup;
    if (this.clearEmailFields) {
      dataemailGroup.reset();
      this.closeFieldsEmail = false;
      this.viewModelLoaded = true;
      this.checkForSubmit = true;
      this.mfaPanelExpanded.emit(false);
    }

    if (this.clearMobileFields) {
      dataphoneGroup.reset();
      this.closeFieldsphone = false;
      this.viewModelLoaded = true;
      this.mfaPanelExpanded.emit(false);
    }
    this.isDisplayButtons = false;
  }

  onCancelResend(): void {
    const dataTogGrp = this.MFAGroup.controls['toggleGroup'] as FormGroup;
    const dataemailGroup = dataTogGrp.controls['emailGroup'] as FormGroup;
    const dataphoneGroup = dataTogGrp.controls['phoneGroup'] as FormGroup;
    if (this.clearEmailFields) {
      dataemailGroup.reset();
      this.closeFieldsEmail = false;
      this.viewModelLoaded = true;
      this.mfaPanelExpanded.emit(false);
      this.changeDetector.detectChanges();
    }

    if (this.clearMobileFields) {
      dataphoneGroup.reset();
      this.closeFieldsphone = false;
      this.viewModelLoaded = true;
      this.mfaPanelExpanded.emit(false);
      this.changeDetector.detectChanges();
    }
    this.verifyCodeGroup.reset();
    this.isDisplayButtons = false;
    this.displayResendCode = false;
  }

  async GetAuthPreferences() {
    this.authPreference = await this.accountService.getAuthPreferences();
    if (this.authPreference) {
      if (this.authPreference.email) {
        this.emailAddress = this.authPreference.email;
        this.showEditEmail = true;
      }
      if (this.authPreference.twoFactorPhone) {
        const formatedphone = this.formatPhoneNumber(
          this.authPreference.twoFactorPhone
        );
        this.mobileNumber = formatedphone;
        this.showToggleComp = true;
        this.showEditPhone = true;
      }
    }
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.emailAddress = hideString(this.emailAddress, '*');
      this.mobileNumber = hideString(this.mobileNumber, '*');
    }
  }

  onCancelMFA() {
    this.router.navigate(['/']);
  }

  buildRequest(
    dataemailGroup: FormGroup,
    dataphoneGroup: FormGroup,
    sendEmail: boolean,
    sendText: boolean,
    emailAuthEnabled: boolean,
    textAuthEnabled: boolean
  ) {
    this.request = {
      Email: sendEmail
        ? dataemailGroup.get('emailAddress').value
        : this.authPreference.email,
      TwoFactorPhone: sendText
        ? dataphoneGroup.get('mobilenumber').value
        : this.authPreference.twoFactorPhone,
      Status: '',
      VerificationCode: '',
      SendEmail: sendEmail,
      SendText: sendText,
      PrefVerify: '',
      EmailAuthEnabled: emailAuthEnabled,
      TextAuthEnabled: textAuthEnabled,
    };
  }

  async OnSave() {
    this.sending = true;
    this.enableToast = false;
    const dataTogGrp = this.MFAGroup.controls['toggleGroup'] as FormGroup;
    const dataemailGroup = dataTogGrp.controls['emailGroup'] as FormGroup;
    const dataphoneGroup = dataTogGrp.controls['phoneGroup'] as FormGroup;

    const isEmailEnabled = dataTogGrp.get('emailEnabledForm').value;
    const isphoneEnabled = dataTogGrp.get('mobileEnabledForm').value;
    if (dataphoneGroup.touched) {
      if (!dataphoneGroup.valid) {
        const mobilenumberControl = dataphoneGroup.get('mobilenumber');
        if (mobilenumberControl) {
          mobilenumberControl.markAsTouched();
          mobilenumberControl.markAsDirty();
          mobilenumberControl.updateValueAndValidity();
        }
        this.changeDetector.detectChanges();
        this.sending = false;
        return;
      }
    }
    if (dataemailGroup.touched) {
      if (!dataemailGroup.valid) {
        this.sending = false;
        return;
      }
    }

    this.enumalatorService.showAuditorInfo();
    const sendEmail =
      this.checkBeforeSubmitEmail() &&
      this.authPreference.email != dataemailGroup.get('emailAddress').value;
    const sendText =
      this.checkBeforeSubmitPhone() &&
      this.authPreference.twoFactorPhone !=
        dataphoneGroup.get('mobilenumber').value;
    this.isEmailAuthCode = sendEmail;
    this.isPhoneAuthCode = sendText;
    this.isEmailPhoneChecked = sendEmail && sendText;
    if (sendEmail || sendText || isEmailEnabled || isphoneEnabled) {
      this.buildRequest(
        dataemailGroup,
        dataphoneGroup,
        sendEmail,
        sendText,
        isEmailEnabled,
        isphoneEnabled
      );
      if (this.request.Email == undefined) {
        this.request.Email = '';
      }
      if (this.request.TwoFactorPhone == undefined) {
        this.request.TwoFactorPhone = '';
      }
      this.result = await this.accountService.editPreference(this.request);
      this.request = null;
      this.sending = false;
    }
    if (this.result != undefined) {
      if (this.result.status == 'SUCCESS') {
        this.enableToast = true;
        this.toastIcon = 'thumb_up';
        this.toastMessage =
          'Your authentication preferences have been submitted. \n It may take up to 15 minutes for your changes to be processed.';
        this.toastType = 'Success';
        this.displaySave = false;
        this.viewModelLoaded = true;
        this.toastMessage.replace('\n', '<br>');
        this.changeDetector.detectChanges();
      }
    }
  }

  displayButtons(event: any) {
    this.isDisplayButtons = event;
  }

  editComp(event: any) {
    if (event == 'email') {
      this.isEmailComp = true;
    }
  }

  formatPhoneNumber(phoneNumberString: string): string {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  }

  //not used
  setVerifyToast(sendEmail: boolean, sendText: boolean) {
    let message = `A verification code has been sent to your new PLACEHOLDER. Confirmation is required to save this new PLACEHOLDER2.<br>
    The verification code is only valid for 10 minutes.<br><br>
    If you do not receive your verification code within 10 minutes, please check your
    PLACEHOLDER for errors and your junk/spam folder.<br><br>
    If the code was not received, try re-sending the code or contact Sedgwick Support Services.<br>
    `;

    if (sendEmail && sendText) {
      message = message.replaceAll('PLACEHOLDER2', 'address/number');
      message = message.replaceAll('PLACEHOLDER', 'email address/phone number');
    } else if (sendEmail) {
      message = message.replaceAll('PLACEHOLDER2', 'address');
      message = message.replaceAll('PLACEHOLDER', 'email address');
    } else {
      message = message.replaceAll('PLACEHOLDER2', 'number');
      message = message.replaceAll('PLACEHOLDER', 'phone number');
    }

    this.verifyToastMessage = message;
  }

  //not used
  setShowErrorToast() {
    this.errorToastMessage = `The verification code does not match.<br><br>
    Ensure that you have entered correct code and press the verify button.<br><br>
    If you feel the code you recieved was in error, try Re-sending the code.`;
    this.showErrorToast = true;
    this.enableInitialToast = false;
  }
}
