import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  BaseComponent,
  SelectOption,
  SnackbarService,
  SnackbarType,
  States,
  TextControlComponent,
} from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ContactInformation } from '../../models/ContactInformation';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-contact-preferences-edit',
  template: `
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        title="Your Contact Information"
        class="tw-w-full"
        [canExpand]="false"
      >
        <p class="app-pb2">
          <b><div [innerHTML]="subHeading"></div></b>
        </p>

        <form [formGroup]="formGroup" class="tw-w-full tw-flex tw-flex-wrap">
          <app-http-error
            class="tw-w-full"
            [httpErrorResponse]="httpErrorResponse"
          ></app-http-error>
          <div class=" md:tw-w-[33%]">
            <app-text-control
              formControlName="street1"
              [formGroup]="formGroup"
              label="Street 1"
              [validationControl]="'Street 1'"
              ngDefaultControl
              class="tw-w-full"
              (keypress)="onTouched()"
              (click)="onTouched()"
              #street1
            ></app-text-control>

            <app-text-control
              formControlName="street2"
              [formGroup]="formGroup"
              label="Street 2"
              [validationControl]="'Street 2'"
              ngDefaultControl
              class="tw-w-full"
              id="idStreet2"
              (keypress)="onTouched()"
              (click)="onTouched()"
            ></app-text-control>

            <app-text-control
              formControlName="city"
              #city
              [formGroup]="formGroup"
              label="City"
              [validationControl]="'City'"
              ngDefaultControl
              class="tw-w-full"
              (keypress)="onTouched()"
              (click)="onTouched()"
            ></app-text-control>

            <app-select-control
              formControlName="state"
              #state
              [formGroup]="formGroup"
              label="State"
              [options]="states"
              ngDefaultControl
              class="tw-w-full"
              [(ngModel)]="stateVal"
              (keypress)="onTouched()"
              (click)="onTouched()"
            ></app-select-control>

            <app-text-control
              formControlName="zipCode"
              #zipcode
              [formGroup]="formGroup"
              [validationControl]="'Zip Code'"
              label="Zip Code"
              ngDefaultControl
              class="tw-w-full"
              [errorValidationMessage]="'Enter valid zip code'"
              (keypress)="onTouched()"
              (click)="onTouched()"
            ></app-text-control>
          </div>

          <div class="tw-w-full" *ngIf="isDisplaySuccess">
            <br />
            <app-toast type="Success" *ngIf="removeSuccess === false">
              {{ afterMessage }}
            </app-toast>
            <br />
            <app-toast type="Error" *ngIf="removeSuccess === true">
              {{ afterMessage }}
            </app-toast>
          </div>

          <app-action-row class="tw-w-full">
            <app-button right2 emphasis="High" type="submit" (click)="update()"
              >Update</app-button
            >
            <app-button right1 emphasis="Low" (click)="onCancel()">
              Close
            </app-button>
          </app-action-row>
        </form>
      </app-panel>
    </app-section>
  `,
  styles: [],
})
export class ContactPreferencesEditComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  httpErrorResponse: HttpErrorResponse;

  states: SelectOption[] = States.options;

  formGroup: FormGroup = this.fb.group({
    street1: ['', Validators.required],
    street2: [''],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zipCode: ['', Validators.required],
  });

  @ViewChild('street1')
  idstreet1: TextControlComponent;
  @ViewChild('city')
  idcity: TextControlComponent;
  @ViewChild('state')
  idstate: TextControlComponent;
  @ViewChild('zipcode')
  idzipcode: TextControlComponent;

  @Input()
  claim: SearchResult;

  @Input()
  noteAndDiary: ContactInformation;

  contactInformation: any;
  subHeading: string;

  street1Val: string;
  street2Val: string;
  cityVal: string;
  zipCodeVal: string;
  stateVal: string;

  isDisplaySuccess?: boolean;
  afterMessage: string;
  removeSuccess: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackbar: SnackbarService,
    private session: Session,
    public claimConfigService: ConfigurationService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.subHeading =
      'Update the mailing address where you would like to receive documents regarding this claim';

    this.formGroup.controls['zipCode'].addValidators(
      customvalidations.validZipCode
    );
  }

  onTouched(): void {
    this.removeSuccess = null;
  }

  ngOnInit(): void {
    this.subs.sink = this.route.queryParams.subscribe((p) =>
      this.loadClaimFromUrl()
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  ngAfterViewInit(): void {
    if (this.idstreet1) {
      this.idstreet1.focus();
    }
  }

  private async loadClaimFromUrl() {
    const claimId = this.route.snapshot.queryParamMap.get('claimId');
    const source = this.route.snapshot.queryParamMap.get('source');
    if (claimId) {
      this.claim = await lastValueFrom(
        this.http.get<SearchResult>(
          `/api/claim/${source}/${claimId}/${'NA'}/summary`
        )
      );
    }
    if (this.claim.claimId && source != 'L') {
      const list = await this.claimConfigService.GetExistingContactPrefernces(
        this.claim
      );
      if (list != undefined) {
        this.contactInformation = list;
        if (
          this.contactInformation != undefined &&
          this.contactInformation != null
        ) {
          this.street1Val = this.contactInformation.street1;
          this.street2Val = this.contactInformation.street2;
          this.zipCodeVal = this.contactInformation.zipcode;
          this.stateVal = this.contactInformation.state;
          this.cityVal = this.contactInformation.city;
        }

        this.formGroup.controls['street1'].setValue(this.street1Val);
        this.formGroup.controls['street2'].setValue(this.street2Val);
        this.formGroup.controls['city'].setValue(this.cityVal);
        this.formGroup.controls['zipCode'].setValue(this.zipCodeVal);
      }
    }
  }

  async update() {
    this.afterMessage = '';
    if (
      this.formGroup.get('street1').value == '' ||
      this.formGroup.get('street1').value == undefined
    ) {
      this.formGroup
        .get('street1')
        .setErrors({ 'Error : street1 is required.': true });
      this.formGroup.get('street1').markAsTouched();
      this.idstreet1.focus();
      return;
    }
    if (
      this.formGroup.get('city').value == '' ||
      this.formGroup.get('city').value == undefined
    ) {
      this.formGroup
        .get('city')
        .setErrors({ 'Error : City is required.': true });
      this.formGroup.get('city').markAsTouched();
      this.idcity.focus();
      return;
    }
    if (
      this.formGroup.get('state').value == '' ||
      this.formGroup.get('state').value == undefined
    ) {
      this.formGroup
        .get('state')
        .setErrors({ 'Error : state is required.': true });
      this.formGroup.get('state').markAsTouched();
      this.idstate.focus();
      return;
    }
    if (
      this.formGroup.get('zipCode').value == '' ||
      this.formGroup.get('zipCode').value == undefined
    ) {
      this.formGroup
        .get('zipCode')
        .setErrors({ 'Error : zipCode is required.': true });
      this.formGroup.get('zipCode').markAsTouched();
      this.idzipcode.focus();
      return;
    }
    if (
      this.formGroup.get('zipCode').value != '' ||
      this.formGroup.get('zipCode').value != undefined
    ) {
      const str = this.formGroup.get('zipCode').value;
      const res = /^\d{5}$/.test(str);
      if (res == false) {
        this.formGroup
          .get('zipCode')
          .setErrors({ 'Error : Enter a valid zip code.': true });
        this.formGroup.get('zipCode').markAsTouched();
        this.idzipcode.focus();
        return;
      }
    }
    if (this.formGroup.valid) {
      this.street1Val = this.formGroup.controls['street1'].value;
      this.street2Val = this.formGroup.controls['street2'].value;
      this.cityVal = this.formGroup.controls['city'].value;
      this.stateVal = this.formGroup.controls['state'].value;
      this.zipCodeVal = this.formGroup.controls['zipCode'].value;

      const updatedContactInformation: ContactInformation = <
        ContactInformation
      >{};
      updatedContactInformation.Street1 = this.street1Val;
      updatedContactInformation.Street2 = this.street2Val;
      updatedContactInformation.City = this.cityVal;
      updatedContactInformation.State = this.stateVal;
      updatedContactInformation.Zipcode = this.zipCodeVal;
      updatedContactInformation.ExaminerId =
        this.claim.examinerId == null ? '' : this.claim.examinerId;
      updatedContactInformation.Country = '';
      updatedContactInformation.Address = '';

      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.snackbar.show(
          'Preview-only mode. Information not sent.',
          SnackbarType.Info
        );
      } else {
        const list = await this.claimConfigService.UpdateContactsInfo(
          this.claim,
          updatedContactInformation
        );
        if (list == true) {
          this.isDisplaySuccess = true;
          this.removeSuccess = false;
          this.afterMessage =
            'Contact information updates have been submitted for review';
        }
        if (list == false) {
          this.isDisplaySuccess = true;
          this.removeSuccess = true;
          this.afterMessage = 'Contact information not updates';
        }
      }
    }
  }

  onCancel() {
    this.router.navigate(['claims'], {
      queryParams: {
        claimId: this.claim.claimId,
        source: this.claim.source,
        lob: this.claim.lob,
      },
    });
  }
}
