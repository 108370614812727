import { Injectable } from '@angular/core';
import {
  Claim,
  SearchInclude,
  SearchParameters,
  SearchResult,
} from 'src/app/shared';
import { CertificationDetails } from 'src/app/shared/models/CertificationDetails';
import { LeaveBalanceInformation } from 'src/app/shared/models/LeaveBalanceInformation';
import { StatusEvent } from 'src/app/shared/models/StatusEvent';
import { PaymentInfo, PaymentRequest } from 'src/app/shared/models/payment';
import { PharmacyCard } from 'src/app/shared/models/pharmacy-card';
import { Http } from 'src/app/shared/services/http.service';
import {
  MileageReimbursement,
  MileageReimbursementRequest,
} from '../../shared/models/MileageReimbursement';
import { ClaimKey } from '../../shared/models/claim-key';
import { DocumentRequest } from '../models/document';
import { RTWResponseStatus } from '../models/rtw-response';
import { WorkSchedule } from '../models/work-schedule';
import { ConfigurationService } from './configuration.service';
import { InpageAliasService } from './inpage-alias.service';
import { Session } from './session.service';
import { SubmittedFormsRequest } from '../models/submittedFormsRequest';
import { SubmitedFormsDetails } from '../models/submitedFormsDetails';
import { Router } from '@angular/router';
import { OutboundSsoService } from './outbound-sso.service';
import { DisplayService } from './display-service';
import { OutboundSSO } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  constructor(
    private http: Http,
    public inpageAliasService: InpageAliasService,
    public configurationService: ConfigurationService,
    public session: Session,
    public outboundSsoService: OutboundSsoService,
    public display: DisplayService
  ) {}
  examinerLabel: string;
  isEmpDb: boolean = false;
  outboundSSO: OutboundSSO;

  getClaimList(claimId: string) {
    return this.http.post<SearchResult[]>('/api/Search', <SearchParameters>{
      firstName: 'TODO: REMOVE REQUIREMENT',
      claimId: claimId,
      include: <SearchInclude>{
        activities: false,
        payments: false,
        notifications: false,
      },
    });
  }

  async getClaims(): Promise<Claim[]> {
    return this.http.get<Claim[]>(`/api/claimant/GetClaims`);
  }

  async getListClaimsExceptCurrent(
    claimId: string,
    source: string
  ): Promise<any[]> {
    return this.http.get<Claim[]>(
      `/api/claimant/GetListClaimsExceptCurrent/${claimId}/${source}`
    );
  }

  async getClaimsEmpUNum(empUnum: string): Promise<Claim[]> {
    if (
      (this.session.user.role.toUpperCase() === 'HRM' &&
        this.session.user.authenticationType === 'CLM' &&
        !this.session.user.emulatorContext?.isEmulating) ||
      this.session.user.roles.manager
    ) {
      return this.http.get<Claim[]>(`/api/claimant/GetClaims/${empUnum}`, {
        cache: true,
      });
    } else {
      // NOTE: cache this call. Please reach out if you need to change this.
      // It's a deeper issue if we need to not cache here.
      return this.http.get<Claim[]>(`/api/claimant/GetClaims`, {
        cache: true,
        timeout: 0,
      });
    }
  }

  getPayments(request: PaymentRequest) {
    return this.http.post<PaymentInfo>(`/api/Claim/payments`, request);
  }

  async getSummary(
    source: string,
    claimId: string,
    excludeClaimTracker: boolean = false,
    excludeFeatureLog: boolean = false,
    lob: string = 'NA'
  ): Promise<SearchResult> {
    return await this.http.get<SearchResult>(
      `/api/claim/${source}/${claimId}/${lob}/summary`,
      {
        queryParams: {
          excludeClaimTracker,
          excludeFeatureLog,
        },
      }
    );
  }

  async getDocuments(claim: SearchResult): Promise<any[]> {
    const docReqObj = {
      ClaimUid: claim.claimId,
      LineOfBusiness: claim.lob,
      ClaimNumber: claim.claimNumber,
      source: claim.source,
    };

    return await this.http.get<any[]>(`/api/claim/GetDocuments`, {
      queryParams: docReqObj,
    });
  }

  async getOnlineForm(claim: SearchResult): Promise<any[]> {
    const formReqObj = {
      ClaimNumber: claim.claimNumber,
      Lob: claim.lob,
      State:
        claim.stateofJurisdiction == claim.stateofJurisdiction
          ? claim.stateofJurisdiction
          : claim.lossState
          ? claim.lossState
          : 'XX',
      ClaimId: claim.claimId,
    };

    const forms = await this.http.get<any[]>(`/api/claim/GetOnlineForms`, {
      queryParams: formReqObj,
    });
    return forms;
  }

  async submitReturnToWork(
    isLiabilityClaim: boolean,
    lob: string,
    RtwData: any
  ): Promise<RTWResponseStatus> {
    const rtwResponseStatus = await this.http.post<RTWResponseStatus>(
      '/api/returntowork',
      RtwData
    );

    const isLiability = isLiabilityClaim == true || lob == 'WC' ? true : false;

    if (
      !(
        rtwResponseStatus.requestValid &&
        rtwResponseStatus.jurisReqStatus &&
        rtwResponseStatus.tamsReqStatus
      ) &&
      !isLiability
    ) {
      rtwResponseStatus.msg =
        'The return to work date was previously reported. Thank you for ensuring we have your most recent return to work status.';
      rtwResponseStatus.isSuccess = false;
    } else if (
      (rtwResponseStatus.requestValid &&
        rtwResponseStatus.jurisReqStatus &&
        rtwResponseStatus.tamsReqStatus) ||
      isLiability
    ) {
      this.examinerLabel = await this.inpageAliasService.replaceAliases(
        'examinerSingular',
        this.configurationService.GetConfig.configFeatures.aliases
      );
      rtwResponseStatus.msg =
        'Your return to work date has been submitted. The ' +
        this.examinerLabel +
        ' will contact you if they have any further questions.  To provide any supporting documents for the return to work status, click the Upload Documents link below.';
      rtwResponseStatus.isSuccess = true;
    } else {
      rtwResponseStatus.msg = 'Your return to work date has been submitted.';
      rtwResponseStatus.isSuccess = false;
    }
    return rtwResponseStatus;
  }

  getPharmacyCard(source: string, claimId: string, lob: string) {
    return this.http.get<PharmacyCard>(
      `/api/Claim/${source}/${claimId}/${lob}/pharmacy-card`
    );
  }
  async downloadPaymentDocument(
    claimId: string,
    icn: string,
    vendorId: string
  ) {
    return await this.http.get<any>(
      `/api/Claim/${claimId}/${icn}/${vendorId}/GetExplanationDocument`
    );
  }

  getTimeline(source: string, claimId: string, lob: string) {
    return this.http.get<PharmacyCard>(
      `/api/Claim/${source}/${claimId}/${lob}/timeline`
    );
  }

  async getMileageReimbursement(
    request: MileageReimbursementRequest,
    source: string,
    claimId: string,
    lob: string
  ) {
    const mileageRequest = {
      ...request,
      ClaimKey: {
        Source: source,
        ClaimId: claimId,
        Lob: lob,
      },
    };
    return await this.http.post<MileageReimbursement>(
      `/api/Claim/${source}/${claimId}/${lob}/GetMileageReimbursement`,
      mileageRequest
    );
  }

  getLeaveBalanceSummary(hrMasterUid?: string) {
    return this.http.get<LeaveBalanceInformation>(
      `/api/Claim/${hrMasterUid}/GetLeaveBalancesSummary`
    );
  }

  async getLeaveLinkBalanceSummary(employeeId: string) {
    return await this.http.get<LeaveBalanceInformation>(
      `/api/claim/${employeeId}/leavelink-leavebalance`
    );
  }

  getPolicies(claimNum: string) {
    const policiesReqObj = {
      ClaimNumber: claimNum,
    };

    const url =
      this.session.user.leaveType !== 'LeaveLink'
        ? `/api/claim/GetPolicies`
        : `/api/claim/GetLeaveLinkPolicies`;
    return this.http.get<any[]>(url, {
      queryParams: policiesReqObj,
    });
  }

  async getOffWorkCalendarEntries(
    claims: ClaimKey[],
    year: string,
    month: string,
    hrMasterUid: string,
    empUnum: string
  ) {
    return await this.http.post<StatusEvent[]>(
      `/api/claim/${year}/${month}/${hrMasterUid}/${empUnum}/off-work-calendar-entries`,
      claims,
      {
        cache: true,
      }
    );
  }

  /**
   * Work schedules are only supported by TAMS. Calling this for other sources
   * returns null.
   * @param source
   * @param claimId
   * @param lob
   * @param claimNumber
   * @returns
   */
  async getWorkScheduleInfo(
    source: string,
    claimId: string,
    lob: string,
    claimNumber: string
  ) {
    return source === 'T' || source === 'L'
      ? this.http.get<WorkSchedule>(
          `/api/Claim/${source}/${claimId}/${lob}/${claimNumber}/work-schedule`
        )
      : null;
  }
  getCertifications(
    claimNumber: string,
    source: string,
    claimId: string,
    lob: string
  ) {
    return this.http.get<CertificationDetails>(
      `/api/Claim/${claimNumber}/${source}/${claimId}/${lob}/GetCertifications`
    );
  }

  async markAsRead(path: string, document: DocumentRequest) {
    return this.http.post<boolean>(path, document);
  }

  loadIntermittentAbsence(
    claimId: string,
    source: string,
    router: Router
  ): any {
    if (this.configurationService.getConfigFeatureAccess('leavelinksso')) {
      this.outboundSSO = this.display.outboundSSO;
      this.outboundSsoService.openSsoUrl(
        this.outboundSSO.ssoType,
        this.outboundSSO.ssoEndPoint
      );
    } else {
      const queryParams: any = {};
      queryParams.claimId = claimId;
      queryParams.source = source;
      router.navigate(['/reportanabsence'], {
        queryParams,
      });
    }
  }

  async getSubmittedForms(
    source: string,
    claimId: string,
    lob: string,
    request: SubmittedFormsRequest
  ): Promise<SubmitedFormsDetails> {
    return this.http.post<SubmitedFormsDetails>(
      `/api/claim/${source}/${claimId}/${lob}/GetSubmittedForms`,
      request
    );
  }
}
