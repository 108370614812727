import { Component, OnInit } from '@angular/core';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { getUrlParams } from '../../../shared';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-comm-center2-card',
  template: `
    <app-panel
      title=""
      class="tw-cursor-pointer "
      border="Empty"
      [canExpand]="false"
      tabindex="-1"
    >
      <div class=" container ">
        <div
          class="tw-w-full tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-h-full "
        >
          <div class="tw-w-[30%] tw-place-self-center">
            <div class="tw-grid tw-grid-cols-1">
              <div class="tw-place-self-center">
                <img
                  src="./assets/images/comm-center/comm-center.png"
                  class="icon"
                  width="160px"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="tw-px-4 tw-w-[70%]">
            <div class="tw-flex tw-flex-col ">
              <div class=" tw-leading-loose tw-tracking-wide text-container">
                <h2
                  class="header1-text  tw-text-[20px] md:tw-text-[26px] "
                  role="heading"
                >
                  Communication center
                </h2>
                <br />
                <span class="header2-text"
                  >Have questions regarding a claim?</span
                ><br />
                <span class="header3-text ">
                  Visit the communication center to send messages directly to
                  {{ examinerLabel }}. Responses are provided within one
                  business day.
                </span>
              </div>
              <div class="app-mb1 app-pt2" tabindex="-1">
                <app-button
                  emphasis="High"
                  tabindex="-1"
                  [arialabel]="'Visit Communication center'"
                  routerLink="/communication-center"
                  [queryParams]="urlParams"
                  [role]="'link'"
                >
                  Visit
                </app-button>
              </div>
            </div>
          </div>
          <div>
            <app-toast
              class=" tw-place-self-center"
              type="Info"
              *ngIf="session.user.leaveType === 'LeaveLink'"
              >LeaveLink claims will not be displayed in the Communication
              Center.
            </app-toast>
          </div>
        </div>
      </div>
    </app-panel>
  `,
  styles: `
  .icon {

    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 54px;
  }

  .header1-text  {
    letter-spacing: 0.8px;
    font-weight: 300;
    color: var(--color-input-grey);
  }

  .header2-text  {
    letter-spacing: 0.5px;
    font-size: 15px;
    font-weight: bold;
    color: var(--color-input-grey);
  }

  .header3-text  {
    font-size: 14px;
    color: var(--color-input-grey);
  }

  .container {
    padding: 10px 16px 16px 16px;
    height: 425px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px !important;
  }`,
})
export class CommCenter2CardComponent implements OnInit {
  examinerLabel: string;
  urlParams: any;

  constructor(private alias: InpageAliasService, public session: Session) {}

  async ngOnInit() {
    const examinerAlias = await this.alias.get('examinerSingular');
    this.examinerLabel =
      (examinerAlias.match(/^[aeiou]/i) ? 'an ' : 'a ') + examinerAlias;
    this.urlParams = getUrlParams();
  }
}
