import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DismissDialogComponent } from 'src/app/my-sedgwick/components/activity/dismiss-dialog.component';
import {
  ExpenseRequest,
  MileageRequest,
} from 'src/app/my-sedgwick/models/mileage-request';
import {
  BaseComponent,
  SearchResult,
  States,
  getUrlParams,
} from 'src/app/shared';
import { ClaimService } from '../../services/claim.service';
import { ExpenseEditComponent } from './expense-edit.component';

@Component({
  selector: 'app-mileage-edit-page',
  template: `
    <app-section>
      <app-claim-breadcrumb></app-claim-breadcrumb>
      <app-panel
        title="Mileage Reimbursement - Entry"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
      >
        <app-claim-header
          [summary]="claim"
          class="tw-w-full"
        ></app-claim-header>
        <form [formGroup]="formGroup" class="tw-w-full tw-flex tw-flex-wrap">
          <!-- Travel Info -->
          <app-header-label type="Header2" class="tw-w-full app-pb2 app-pt3"
            >Please enter travel information</app-header-label
          >
          <app-date-control
            formControlName="travelDate"
            [formGroup]="formGroup"
            label="Date of service"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-date-control>
          <app-select-control
            formControlName="travelType"
            [formGroup]="formGroup"
            label="How did you get there?"
            [options]="[{ label: 'Other Time Off', value: 'OTH' }]"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-select-control>
          <app-select-control
            formControlName="travelReason"
            [formGroup]="formGroup"
            label="Reason for travel?"
            [options]="[{ label: 'Other Time Off', value: 'OTH' }]"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-select-control>
          <app-text-control
            formControlName="travelDestination"
            [formGroup]="formGroup"
            label="Where did you go?"
            ngDefaultControl
            class="tw-w-full md:tw-w-[25%]"
          ></app-text-control>

          <!-- Starting Address -->
          <app-header-label type="Header2" class="tw-w-full app-pb2 app-pt3"
            >Starting address</app-header-label
          >

          <div
            formGroupName="startAddress"
            class="tw-w-full tw-flex tw-flex-wrap"
          >
            <app-text-control
              formControlName="street1"
              [formGroup]="formGroup.controls.startAddress"
              label="Address Line 1"
              class="tw-w-full md:tw-w-[50%]"
              ngDefaultControl
            ></app-text-control>
            <app-text-control
              formControlName="street2"
              [formGroup]="formGroup.controls.startAddress"
              label="Address Line 2"
              ngDefaultControl
              class="tw-w-full md:tw-w-[50%]"
            ></app-text-control>

            <app-text-control
              formControlName="city"
              [formGroup]="formGroup.controls.startAddress"
              label="City"
              ngDefaultControl
              class="tw-w-full md:tw-w-[33%]"
            ></app-text-control>
            <app-select-control
              formControlName="state"
              [formGroup]="formGroup.controls.startAddress"
              label="State"
              [options]="states"
              ngDefaultControl
              class="tw-w-full md:tw-w-[34%]"
            ></app-select-control>
            <app-text-control
              formControlName="zipCode"
              [formGroup]="formGroup.controls.startAddress"
              label="Zip Code"
              mask="00000"
              ngDefaultControl
              class="tw-w-full md:tw-w-[33%]"
            ></app-text-control>
          </div>

          <!-- Ending Address -->
          <app-header-label type="Header2" class="tw-w-full app-pb2 app-pt3"
            >Ending address</app-header-label
          >

          <div
            formGroupName="endAddress"
            class="tw-w-full tw-flex tw-flex-wrap"
          >
            <app-text-control
              formControlName="street1"
              [formGroup]="formGroup.controls.endAddress"
              label="Address Line 1"
              class="tw-w-full md:tw-w-[50%]"
              ngDefaultControl
            ></app-text-control>
            <app-text-control
              formControlName="street2"
              [formGroup]="formGroup.controls.endAddress"
              label="Address Line 2"
              ngDefaultControl
              class="tw-w-full md:tw-w-[50%]"
            ></app-text-control>

            <app-text-control
              formControlName="city"
              [formGroup]="formGroup.controls.endAddress"
              label="City"
              ngDefaultControl
              class="tw-w-full md:tw-w-[33%]"
            ></app-text-control>
            <app-select-control
              formControlName="state"
              [formGroup]="formGroup.controls.endAddress"
              label="State"
              [options]="states"
              ngDefaultControl
              class="tw-w-full md:tw-w-[34%]"
            ></app-select-control>
            <app-text-control
              formControlName="zipCode"
              [formGroup]="formGroup.controls.endAddress"
              label="Zip Code"
              mask="00000"
              ngDefaultControl
              class="tw-w-full md:tw-w-[33%]"
            ></app-text-control>
          </div>
          <app-header-label type="Header2" class="tw-w-full app-pb2 app-pt3"
            >Additional expenses</app-header-label
          >

          <app-loading-panel
            [skeletonLines]="8"
            [state]="expenses.length > 0 ? 'Loaded' : 'Empty'"
            class="tw-w-full "
          >
            <app-empty-state-label
              empty
              icon="receipt_long"
              header="No expenses"
              message="Additional expenses can be added here."
            >
              <div class="app-pb2">
                <app-button emphasis="Medium" (click)="addExpense()"
                  >Add expense</app-button
                >
              </div>
            </app-empty-state-label>

            <ng-template #content>
              <div>
                <div class="">
                  <app-button emphasis="Medium" (click)="addExpense()"
                    >Add expense</app-button
                  >
                </div>
                <app-list
                  [dataSource]="expenses$ | async"
                  [template]="row"
                  [tableAriaLabel]="'Mileage Expenses'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full">
                      <app-list-header
                        name="expenseType"
                        label="Expense Type"
                        class="tw-w-[50%]"
                      ></app-list-header>
                      <app-list-header
                        name="amountPaid"
                        label="Amount Paid"
                        class="tw-w-[50%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                </app-list>

                <ng-template #row let-element>
                  <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                    <td class="tw-w-full md:tw-w-[50%]">
                      <app-value
                        label="Expense Type"
                        layout="row-xs"
                        [value]="element.expenseType"
                        [tabindex]="0"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[25%]">
                      <app-value
                        label="Amount Paid"
                        layout="row-xs"
                        [tabindex]="0"
                        [value]="element.amountPaid | format : 'currency'"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[25%]">
                      <app-value label="Actions" layout="row-xs">
                        <app-action-row value>
                          <app-icon-button
                            icon="delete"
                            label="Delete"
                            tabindex="0"
                            role="button"
                            right1
                            (click)="deleteExpense(element)"
                            (keydown.enter)="deleteExpense(element)"
                            [attr.aria-label]="'Delete Expense for trip'"
                          ></app-icon-button>
                          <app-icon-button
                            icon="edit"
                            label="Edit"
                            tabindex="0"
                            role="button"
                            right2
                            [attr.aria-label]="'Edit Expense for trip'"
                            (click)="deleteExpense(element)"
                            (keydown.enter)="editExpense(element)"
                          ></app-icon-button>
                        </app-action-row>
                      </app-value>
                    </td>
                  </tr>
                </ng-template>
              </div>
            </ng-template>
          </app-loading-panel>

          <app-action-row class="tw-w-full">
            <app-button right2 emphasis="High" type="submit" (click)="review()"
              >Review</app-button
            >
            <app-button right1 emphasis="Low" (click)="cancel()">
              Cancel
            </app-button>
          </app-action-row>
        </form>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      .total {
        font-size: 18px;
      }
    `,
  ],
})
export class MileageEditPageComponent extends BaseComponent implements OnInit {
  states = States.options;

  requests: MileageRequest[] = [];

  request: MileageRequest;

  expenses: ExpenseRequest[] = [];

  expenses$ = new BehaviorSubject<ExpenseRequest[]>([]);

  claim: SearchResult = <SearchResult>{};

  claimId: string;

  source: string;

  formGroup = this.fb.group({
    travelDate: this.control<Date>(null, Validators.required),
    travelType: this.control<string>(null, Validators.required),
    travelReason: this.control<string>(null, Validators.required),
    travelDestination: this.control<string>(null, Validators.required),
    startAddress: this.fb.group({
      street1: this.control<string>(null, Validators.required),
      street2: this.control<string>(null),
      city: this.control<string>(null, Validators.required),
      state: this.control<string>(null, Validators.required),
      zipCode: this.control<string>(null, Validators.required),
    }),
    endAddress: this.fb.group({
      street1: this.control<string>(null, Validators.required),
      street2: this.control<string>(null),
      city: this.control<string>(null, Validators.required),
      state: this.control<string>(null, Validators.required),
      zipCode: this.control<string>(null, Validators.required),
    }),
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public claimService: ClaimService
  ) {
    super();
  }

  async ngOnInit() {
    // this.requests = this.cache.get('mileage-request');

    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.claim = await this.claimService.getSummary(this.source, this.claimId);

    // TODO: find clean way to assigning object to form
    let r: MileageRequest;
    if (this.requests?.length === 1) {
      r = this.requests[0];
      this.request = r;
      this.expenses = r.expenses;
      this.expenses$.next(this.expenses);
    }

    if (r) {
      const c = this.formGroup.controls;
      c.travelDate.setValue(r.travelDate);
      c.travelType.setValue(r.travelType);
      c.travelDestination.setValue(r.travelDestination);
      c.travelReason.setValue(r.travelReason);
      c.startAddress.controls.street1.setValue(r.startAddress.street1);
      c.startAddress.controls.street2.setValue(r.startAddress.street2);
      c.startAddress.controls.city.setValue(r.startAddress.city);
      c.startAddress.controls.state.setValue(r.startAddress.state);
      c.startAddress.controls.zipCode.setValue(r.startAddress.zipCode);
      c.endAddress.controls.street1.setValue(r.endAddress.street1);
      c.endAddress.controls.street2.setValue(r.endAddress.street2);
      c.endAddress.controls.city.setValue(r.endAddress.city);
      c.endAddress.controls.state.setValue(r.endAddress.state);
      c.endAddress.controls.zipCode.setValue(r.endAddress.zipCode);
    }
  }

  control<T>(
    value: T,
    validators?: ValidatorFn | ValidatorFn[]
  ): FormControl<T> {
    return new FormControl<T>(value, {
      nonNullable: false,
      validators,
    });
  }

  review() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.router.navigate(['/mileage-reimbursement-review'], {
        queryParamsHandling: 'merge',
      });
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(DismissDialogComponent, {
      data: {
        title: 'Confirmation',
        message: 'Press Confirm to cancel this mileage reimbursement.',
      },
    });

    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.router.navigate(['/mileage-reimbursement'], {
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  deleteExpense(expense: ExpenseRequest) {
    this.expenses = this.expenses.filter((item) => item !== expense);
  }

  addExpense() {
    const dialogRef = this.dialog.open(ExpenseEditComponent, {
      data: {
        expense: new ExpenseRequest(),
        title: 'Add Expense',
      },
    });

    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.expenses.push(result);
        this.expenses$.next(this.expenses);
      }
    });
  }

  editExpense(expense: ExpenseRequest) {
    const index = this.expenses.indexOf(expense);
    const dialogRef = this.dialog.open(ExpenseEditComponent, {
      data: {
        expense,
        title: 'Edit Expense',
      },
    });

    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      // TODO: update the expense
    });
  }
}
