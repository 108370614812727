import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ConfigurationSetting,
  OffWork,
  UserActionService,
  deepCopy
} from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { Excel, ExportRequest } from '../..';
import { ConfigurationService } from '../../services/configuration.service';
import { RecentClaimService } from '../../services/recent-claim.service';
import { UserRoleService } from '../../services/user-role.service';
import { RestrictionsDialogComponent } from './restrictions-dialog.component';
import { SubmitReturnToWorkDialogComponent } from './submit-return-to-work-dialog.component';
import { TitleCasePipe } from '@angular/common';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-off-work',
  template: `
    <app-list
      [dataSource]="offWork"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      [noDataIcon]="noDataIcon"
      [noDataMessage]="noDataMessage"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these off works as a XLSX file"
      (export)="_export()"
      [DefaultSortColumn]="isSupervisorRole ? 'from' : ''"
      [DefaultSortDir]="isSupervisorRole ? 'desc' : ''"
      [toastInfoMessage]="toastInfoMessage"
      [displayInfoToastMsg]="displayInfoToastMsg"
      [toastErrorMessage]="toasErrorMsg"
      [displayErrorToastMsg]="displayErrorToastMsg"
      [tableAriaLabel]="'OffWork'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimDescription"
            label="Claim"
            class="tw-w-full md:tw-w-[40%]"
            [names]="['claimNumber']"
          ></app-list-header>
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[20%]"
            [names]="['firstName', 'empId']"
          ></app-list-header>
          <app-list-header
            name="from"
            label="From"
            filterType="Date"
            class="tw-w-full md:tw-w-[15%]"
          ></app-list-header>
          <app-list-header
            name="to"
            label="To"
            filterType="Date"
            class="tw-w-full md:tw-w-[15%]"
          ></app-list-header>
          <app-list-header
            label="Actions"
            readOnly
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>
    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[40%]">
          <app-value label="Claim" layout="row-xs">
            <app-claim-label
              value
              [claim]="element"
              layout="row"
              (displayWatchListErrorMsg)="displayWatchListErrorMsg($event)"
            >
            </app-claim-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label
              value
              [claimant]="element"
              [disabled]="isDisabled"
            ></app-claimant-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="From"
            [tabindex]="element.from ? '0' : '1'"
            [value]="element.from | date : 'MM/dd/yyyy'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="To"
            [tabindex]="element.to ? '0' : '1'"
            [value]="element.to | date : 'MM/dd/yyyy'"
            layout="row-xs"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%] md:tw-block tw-hidden">
          <app-icon-button
            [matMenuTriggerFor]="menu"
            *ngIf="(element.allowRtw || element.allowRestriction) && (element.source != 'L')"
            [ariaLabelText]="'Actions menu for claim - ' + element.claimLabel"
            label="Actions"
            icon="more_vert"
            tabindex="0"
            role="button"
            (click)="toggleMenu()"
            (keydown.enter)="toggleMenu()"
            [attr.aria-label]="'Actions menu for claim - ' + element.claimLabel"
            [attr.aria-expanded]="isActionsMenuExpanded"
          />
          <mat-menu #menu="matMenu" role="menu" (closed)="toggleMenu()">
            <div class="radius-10">
              <a
                *ngIf="element.allowRtw"
                class="tw-w-full app-pl2 app-lc-sticky-link"
                label="Report return to Work Date"
                mat-menu-item
                role="menuitem"
                routerLink="/return-to-work"
                [queryParams]="{
                  claimId: element.claimId,
                  source: element.source,
                  rtw: 'submit'
                }"
              >
                Report return to work
              </a>

              <a
                *ngIf="
                  (isSupervisorRole || isController) &&
                  element.lineOfBusiness === 'WC' &&
                  element.allowRestriction
                "
                class="tw-w-full app-pl2 app-lc-sticky-link"
                mat-menu-item
                role="menuitem"
                label="View Restrictions"
                (click)="openRestrictionComponent(element)"
                (keypress.enter)="openRestrictionComponent(element)"
              >
                View Restrictions
              </a>
            </div>
          </mat-menu>
        </td>
        <div
          class="tw-w-full md:tw-w-[15%] md:tw-hidden tw-block"
          *ngIf="element.allowRtw"
        >
          <app-button
            emphasis="Low"
            (click)="openRtwComponent(element)"
            (keydown.enter)="openRtwComponent(element)"
            >report to Return to work</app-button
          >
        </div>

        <div
          class="tw-w-full md:tw-w-[15%] md:tw-hidden tw-block"
          *ngIf="
            (isSupervisorRole || isController) &&
            element.lineOfBusiness === 'WC' &&
            element.allowRestriction
          "
        >
          <app-button emphasis="Low" (click)="openRestrictionComponent(element)"
            >View restrictions</app-button
          >
        </div>
      </tr>
    </ng-template>
  `,
  styles: [
    `
      #restrictionpnl ::ng-deep .mat-mdc-unelevated-button.mat-accent {
        background-color: #007dbc !important;
        color: #fff;
      }
      .radius-10 {
        width: 240px !important;
      }
      .pd-10 {
        padding-right: 10px;
      }
      #restrictionpnl ::ng-deep .mat-mdc-unelevated-button.mat-accent:hover {
        background-color: #035e81 !important;
      }
      ::ng-deep .mat-mdc-menu-panel.mat-mdc-menu-panel {
        border-radius: 10px !important;
      }
      ::ng-deep .mat-mdc-menu-item-text
     {
      color: #007dbc !important;
      font-weight: bold !important;
      text-decoration: none !important;
      }
      ::ng-deep .mat-mdc-menu-item-text:hover 
      {
        color: #035e81 !important;
        text-decoration: solid underline #035e81 2px;
        cursor: pointer;
      }
    `,
  ],
})
export class OffWorkComponent implements OnInit {
  @Input()
  offWork: OffWork[];

  @Input()
  yourClaim?: boolean = false;

  displayToastMsg: boolean = false;
  displayInfoToastMsg: boolean = false;
  displayErrorToastMsg: boolean = false;
  toastInfoMessage: string;
  toasErrorMsg: string;
  userAction = UserActions.ViewOwnMgrDb;
  isDisabled: boolean;
  noDataIcon: string = 'work_outline';
  noDataMessage: string = '<strong>No employees off work</strong>';
  isActionsMenuExpanded: boolean = false;

  @Input()
  ClientLevelConfig: ConfigurationSetting;

  @Output()
  export = new EventEmitter<ExportRequest>();

  isSupervisorRole: boolean = false;
  isController: boolean = false;
  constructor(
    public dialog: MatDialog,
    public ClientLevelConfigService: ConfigurationService,
    private recentClaimService: RecentClaimService,
    public userRole: UserRoleService,
    private excel: Excel,
    private userActions: UserActionService
  ) {}

  ngOnInit(): void {
    this.isSupervisorRole = this.userRole.manager;
    this.isController = this.userRole.controller;
    if (
      (this.userActions.getAction() == UserActions.ViewOwnMgrDb ||
        this.userRole.manager) &&
      !this.userRole.controller
    ) {
      this.isDisabled = window.location.pathname === '/dashboard';
    } else {
      this.isDisabled = true;
    }
    if (this.offWork?.length > 200) {
      this.displayInfoToastMsg = true;
      this.toastInfoMessage =
        'Please use the Search page to find a claim that does not have an off work record in this list.';
    }
  }

  openRtwComponent(claim: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      claim: claim,
      yourClaim: this.yourClaim,
      uploadFile: claim.allowUploadDocuments,
    };

    this.dialog.open(SubmitReturnToWorkDialogComponent, dialogConfig);
  }

  openRestrictionComponent(claim: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      restrctions: claim.restrictionsTable,
    };

    this.dialog.open(RestrictionsDialogComponent, dialogConfig);
  }

  displayWatchListErrorMsg(event: any) {
    this.displayErrorToastMsg = true;
    this.toasErrorMsg =
      this.recentClaimService.displayWatchListErrorMsg1(event);
  }

  _export() {
    const formattedData = deepCopy(this.offWork);
    const titleCase = new TitleCasePipe();
    for (const off of formattedData) {
      const formatName = (off.name += '');
      if (formatName && formatName.length > 0 && formatName.indexOf(' ') > -1) {
        const arr1 = formatName.split(' ').map(item => item.trim());
        off.name = arr1.map(x => titleCase.transform(x)).join(' ');
      }
    }
    const request = new ExportRequest();
    request.fileName = 'Employees off work.xlsx';
    request.sheets = [
      {
        name: 'Employees off work',
        rows: formattedData,
        headers: [
          { label: 'Line of Business', value: 'lineOfBusiness' },
          { label: 'Claim Type', value: 'claimDescription' },
          { label: 'Claim Number', value: 'claimNumber' },
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'empId' },
          {
            label: 'From date',
            value: 'from',
            format: 'date',
          },
          {
            label: 'To date',
            value: 'to',
            format: 'date',
          },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
