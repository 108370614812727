import { Injectable } from '@angular/core';
import { ConfigurationService } from '..';

@Injectable({
  providedIn: 'root',
})
export class InpageAliasService {
  constructor(private configurationService: ConfigurationService) {}

  async get(aliasKey: string) {
    const userInfo =
      await this.configurationService.getAdditionalUserInformation();
    return await this.getAlias(aliasKey, userInfo.configFeatures.aliases);
  }

  private isVowel(char: string): boolean {
    return ['a', 'e', 'i', 'o', 'o'].includes(char.toLowerCase());
  }

  private getAlias(input: string, aliases: any[]): string {
    const alias = aliases.find(
      (a) => a.key?.toLowerCase() === input?.toLowerCase()
    );
    return alias?.value ?? null;
  }

  public replaceAliases(input: string, aliases: any[]): string {
    return this.getAlias(input, aliases);
  }

  public replaceAliasesInSentence(input: string, aliases: any[]): string {
    const alias = this.getAlias(input, aliases);
    const article = this.isVowel(alias.charAt(0)) ? 'an' : 'a';
    return article + ' ' + alias;
  }
}
