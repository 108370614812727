import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OffWork } from 'src/app/shared';
import { Excel, ExportRequest } from '../..';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-return-to-work',
  template: `
    <app-list
      [dataSource]="returnToWork"
      [template]="template1"
      [filterEnabled]="true"
      [DefaultSortColumn]="isManager ? 'from' : ''"
      [exportEnabled]="true"
      exportButtonAriaLabel="Export and download these return to works as a XLSX file"
      (export)="_export()"
      DefaultSortDir="desc"
      [noDataIcon]="noDataIcon"
      [noDataMessage]="noDataMessage"
      [tableAriaLabel]="'Return to work'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimNumber"
            label="Claim"
            class="tw-w-full md:tw-w-[40%]"
            [names]="['claimDescription']"
          ></app-list-header>
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[25%]"
            [names]="['firstName', 'empId']"
          ></app-list-header>
          <app-list-header
            name="rtwDate"
            label="Detail"
            filterType="Date"
            class="tw-w-full md:tw-w-[25%]"
            [names]="['rtwDate', 'from', 'rtwType']"
          ></app-list-header>
          <app-list-header
            name=""
            label="Confirm"
            readOnly
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
        </tr> </ng-container
    ></app-list>

    <ng-template #template1 let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full  tw-items-center">
        <td class="tw-w-full md:tw-w-[40%]">
          <app-value label="Claim" layout="row-xs">
            <app-claim-label
              value
              [claim]="element"
              layout="row"
            ></app-claim-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label
              value
              [claimant]="element"
              [disabled]="isDisabled"
            ></app-claimant-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[25%]">
          <app-value
            *ngIf="element.from"
            label="Return to work"
            [tabindex]="0"
            [value]="element.from | date : 'MM/dd/yyyy'"
          >
          </app-value>
          <app-value
            *ngIf="element.rtwDate"
            [tabindex]="0"
            layout="row"
            label="Return to work Date"
            [value]="element.rtwDate | format : 'date'"
          >
          </app-value>
          <app-value
            *ngIf="element.rtwType"
            [tabindex]="0"
            layout="row"
            label="Return to work Type"
            [value]="element.rtwType"
          >
          </app-value>
          <app-value
            *ngIf="element.lob === 'DS'"
            layout="row"
            [tabindex]="0"
            label="Last Day Worked"
            [value]="element.lastDayWorked | format : 'date'"
          >
          </app-value>
          <app-value
            *ngIf="element.lob === 'LV'"
            layout="row"
            [tabindex]="0"
            label="Hours Worked"
            [value]="element.hoursWorked | number"
          >
          </app-value>
          <app-value
            [tabindex]="0"
            *ngIf="element.lob === 'WC'"
            layout="row"
            label="Date of Loss"
            [value]="element.dateLoss | format : 'date'"
          >
          </app-value>
          <app-value
            [tabindex]="0"
            *ngIf="element.lob === 'WC'"
            layout="row"
            label="Date Reported To Sedgwick"
            [value]="element.dateReported | format : 'date'"
          >
          </app-value>
          <app-value
            [tabindex]="0"
            *ngIf="element.lob === 'WC'"
            layout="row"
            label="Claim Reopened"
            [value]="element.reopened | titlecase"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[10%]">
          <app-icon-button
            (click)="redirectToRtw(element)"
            (keydown.enter)="redirectToRtw(element)"
            label="Confirm"
            tabindex="0"
            role="button"
            icon="done"
            [attr.aria-label]="
              'Confirm Return to work for claim - ' +
              element.claimDescription +
              ' - ' +
              element.claimNumber
            "
          ></app-icon-button>
        </td>
      </tr>
    </ng-template>
    <ng-template #nameTemplate let-element
      ><app-employee-label [name]="element.name"></app-employee-label
    ></ng-template>
    <ng-template #reportTemplate let-element
      ><button
        mat-icon-button
        color="accent"
        (click)="dialog.open(rtwTemplate)"
      >
        <mat-icon>done</mat-icon>
      </button></ng-template
    >
    <ng-template #rtwTemplate>
      <app-dialog
        title="Confirm Return To Work"
        ariaLabel="Confirm retunr to work dialog"
      >
        <app-return-to-work-edit
          [showDidNotReturn]="true"
        ></app-return-to-work-edit>
      </app-dialog>
    </ng-template>
  `,
  styles: [],
})
export class ReturnToWorkComponent implements OnInit {
  @Input()
  returnToWork: OffWork[];

  mgrAction = UserActions.ViewOwnMgrDb;
  isBlueLabel: boolean = false;

  @Output()
  export = new EventEmitter<ExportRequest>();

  isManager: boolean = false;
  isDisabled: boolean = false;

  noDataIcon: string = 'work_outline';
  noDataMessage: string = 'No return to work confirmations are needed.';

  constructor(
    public dialog: MatDialog,
    public userRole: UserRoleService,
    private excel: Excel,
    private userAction: UserActionService,
    private router: Router,
    private userActions: UserActionService
  ) {}

  ngOnInit(): void {
    this.isManager = this.userRole.manager;
    if (
      this.userActions.getAction() == UserActions.ViewOwnMgrDb ||
      this.userRole.manager
    ) {
      this.isDisabled = window.location.pathname === '/dashboard';
    } else {
      this.isDisabled = true;
    }
  }

  async redirectToRtw(claimant: any) {
    if (this.userRole.manager) {
      //this.userAction.setAction(UserActions.MgrViewEmpDb);
      await this.userAction.setClaimant(claimant);
      this.router.navigate(['/return-to-work'], {
        queryParams: {
          claimId: claimant.claimId,
          source: claimant.source,
          rtw: 'confirm',
          rtwType: claimant.rtwType,
          alertMasterUid: claimant.alertMasterId,
          type: claimant.type,
          rtwDate: this.userRole.supervisor
            ? claimant.rtwDate
            : this.userRole.omni || this.userRole.overseer
            ? claimant.rtwDate
            : '',
        },
      });
    }
  }
  _export() {
    const request = new ExportRequest();
    request.fileName = 'Return to work.xlsx';
    request.sheets = [
      {
        name: 'Return to work',
        rows: this.returnToWork,
        headers: [
          { label: 'Line of Business', value: 'lineOfBusiness' },
          { label: 'Claim Type', value: 'claimDescription' },
          { label: 'Claim Number', value: 'claimNumber' },
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'empId' },
          {
            label: 'Return to work',
            value: 'rtwDate',
            format: 'date',
          },
          { label: 'Return to Work Type', value: 'rtwType' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }
}
