import { Component, Input } from '@angular/core';
import { ChartConfig, OmniDashboard } from 'src/app/my-sedgwick';
import { LoadingState } from 'src/app/shared';

@Component({
  selector: 'app-return-to-work-card',
  template: `
    <app-panel
      title="Returning to work"
      [labelTemplate]="details"
      [headerLevel]="2"
      [largeHeader]="false"
      class="tw-cursor-pointer"
      routerLink="/summary/return-to-work"
      [canExpand]="false"
      tabindex="-1"
    >
      <ng-template #details>
        <div class="tw-w-full tw-flex tw-justify-end">
          <a
            class="tw-flex tw-items-center"
            *ngIf="omniDashboard"
            routerLink="/summary/return-to-work"
            aria-label="Go to Employees returning to work details page"
          >
            <div class="app-pr1">DETAILS</div>
          </a>
        </div>
      </ng-template>
      <div class="tw-h-[345px]">
        <app-loading-panel
          loadingStyle="Box"
          [loadingHeight]="300"
          [state]="loadingState"
          [error]="error"
          class="tw-h-full"
        >
          <ng-template #empty>
            <div
              class="tw-flex tw-items-center tw-w-full tw-h-full tw-justify-around"
            >
              <app-empty-state-label
                icon="data_usage"
                header="No employees found"
                x-message="No employees returning today"
              >
              </app-empty-state-label>
            </div>
          </ng-template>
          <ng-template #content>
            <app-card-chart
              [config]="config"
              class="tw-h-full"
            ></app-card-chart>
          </ng-template>
        </app-loading-panel>
      </div>
    </app-panel>
  `,
  styles: [``],
})
export class ReturnToWorkCardComponent {
  @Input()
  error: any;

  private _omniDashboard: OmniDashboard;

  @Input()
  set omniDashboard(value: OmniDashboard) {
    this._omniDashboard = value;
    if (!value) {
      return;
    }

    this.loadingState =
      value.rtwFull + value.rtwPartial > 0 ? 'Loaded' : 'Empty';
    this.config = new ChartConfig()
      .addSeries('Full Day', value.rtwFull, '#3f7c4f')
      .addSeries('Reduced/Restricted Work', value.rtwPartial, '#6E3882');
  }
  get omniDashboard(): OmniDashboard {
    return this._omniDashboard;
  }

  config: ChartConfig;

  loadingState: LoadingState = 'Loading';
}
