import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  BaseComponent,
  ConfigurationSetting,
  OverlayMode,
  ProgressService,
  SearchResult,
} from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { ActivityService } from '../../services/activity.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LogService } from '../../services/log.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-confirm-return-to-work-page',
  template: `
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb class="tw-w-[90%]"></app-claim-breadcrumb>
      </div>

      <app-panel
        [largeHeader]="true"
        title="Return to work"
        class="tw-w-full app-pb4"
        [canExpand]="false"
      >
        <div class="app-pb2 tw-w-full" #rtweditdialog>
          <form [formGroup]="formGroup">
            <div id="rtweditpnl">
              <div class="tw-flex tw-flex-wrap">
                <div class="tw-w-[50%] ">
                  <app-claimant-label
                    value
                    [claimant]="claim"
                    [showNameLabel]="true"
                    [nameClass]="'headingbold'"
                    class="app-uppercase"
                    [disabled]="!userRole.manager"
                  >
                  </app-claimant-label>
                </div>

                <div class="app-pb2 tw-w-[50%]">
                  <app-claim-label
                    value
                    [claim]="claim"
                    layout="row"
                    [showNameLabel]="true"
                    [showNameLabel]="true"
                    [noLink]="false"
                  ></app-claim-label>
                </div>
                <div class="tw-w-full tw-flex tw-flex-wrap">
                  <div class="tw-w-full md:tw-w-[40%]">
                    <div class="tw-w-full">
                      <app-container-control
                        ngDefaultControl
                        [formGroup]="formGroup"
                        formControlName="duty"
                        class="app-pb3 headingbold"
                        label="Confirm return to work date11 *"
                      >
                        <div *ngIf="claim.anticipatedReturnToWorkDate !== ''">
                          <mat-label
                            >{{
                              claim.anticipatedReturnToWorkDate
                                | format : 'date'
                            }}
                          </mat-label>
                        </div>
                        <mat-radio-group
                          aria-label="Confirm Return to work"
                          role="radiogroup"
                        >
                          <mat-radio-button
                            (change)="toggleMethod(1)"
                            value="Yes"
                            >Yes</mat-radio-button
                          >
                          <span class="app-pl2"></span>
                          <mat-radio-button
                            (change)="toggleMethod(2)"
                            value="No"
                            >No</mat-radio-button
                          >
                        </mat-radio-group>
                      </app-container-control>
                    </div>

                    <div class="tw-w-full" *ngIf="showCRTWDate">
                      <mat-label class="headingbold"
                        >Change my Return to Work Date to *</mat-label
                      >
                      <app-date-control
                        label="Return to Work Date"
                        ngDefaultControl
                        [formGroup]="formGroup"
                        formControlName="rtwDate"
                        class="app-pt1"
                      ></app-date-control>
                    </div>
                  </div>
                  <div class="tw-w-full app-pt4">
                    <app-action-row>
                      <!-- <app-button right2 emphasis="High" (click)="submitreturntowork()">Submit</app-button>-->
                      <button
                        type="submit"
                        right2
                        autofocus
                        emphasis="High"
                        mat-raised-button
                        tabindex="0"
                        [ngClass]="
                          btnDisabled == true ? 'btn-info-disable' : 'btn-info'
                        "
                        [disabled]="btnDisabled"
                        (click)="submitreturntowork()"
                      >
                        Submit
                      </button>
                      <app-button right1 emphasis="Low" (click)="clearForm()"
                        >Clear</app-button
                      >
                    </app-action-row>
                  </div>
                </div>
                <div *ngIf="isDisplaySuccess">
                  <br />
                  <app-toast type="Success">
                    {{ afterRtwSuccessMessage }}
                  </app-toast>
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      #rtweditpnl ::ng-deep .mat-mdc-radio-group {
        margin-left: -3%;
      }

      #rtweditpnl ::ng-deep .mat-accent {
        background-color: #fff !important;
        color: #3f464d !important;
        margin-left: 3px;
      }
      #rtweditpnl ::ng-deep .mat-accent:hover {
        background-color: #f1f4f7 !important;
      }

      #rtweditpnl ::ng-deep .mat-mdc-unelevated-button {
        background-color: #007dbc !important;
        color: #fff !important;
      }
      #rtweditpnl ::ng-deep .mat-mdc-unelevated-button:hover {
        background-color: #035e81 !important;
      }

      .mat-mdc-radio-button:focus {
        border: 1px solid #007dbc;
      }
      .errormsg {
        color: #c24533;
      }
      @media (max-device-width: 480px) {
        ::ng-deep .mat-mdc-text-field-wrapper {
          /* width : 250%;*/
        }
      }

      .btn-info:hover {
        background-color: #035e81;
      }
      .btn-info {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background: #244578;
        background-color: #007dbc;
        font-weight: bold;
      }

      .btn-info-disable {
        font-size: 15px;
        color: gray;
        line-height: 30px;
        border-radius: 40px;
        background-color: lightgray;
        font-weight: bold;
      }
    `,
  ],
})
export class ConfirmReturnToWorkComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  LOB: string;

  claimId: string = '';
  claimConfig: ConfigurationSetting;
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);

  claim: SearchResult = <SearchResult>{};
  isDisplaySuccess: boolean = false;
  afterRtwSuccessMessage: string;
  IsValidDuty: boolean = false;
  showCRTWDate: boolean = false;
  btnDisabled: boolean = true;

  formGroup: FormGroup = this.fb.group({
    comments: [],
    noReturn: [],
  });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private activityService: ActivityService,
    private configurationService: ConfigurationService,
    public overlayService: ProgressService,
    public logService: LogService,
    public dialog: MatDialog,
    public userRole: UserRoleService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  toggleMethod(val1: number) {
    if (val1 == 1) {
      this.showCRTWDate = false;
      this.btnDisabled = false;
      this.formGroup.get('rtwDate').clearValidators();
      this.formGroup.get('rtwDate').updateValueAndValidity();
    }
    if (val1 == 2) {
      this.showCRTWDate = true;
      this.btnDisabled = false;
    }
  }

  createManagementNotificationForm() {
    this.formGroup.addControl('rtwDate', new FormControl());
    this.formGroup.addControl('duty', new FormControl());
  }

  async ngOnInit() {
    this.subs.sink = this.route.queryParams.subscribe((p) =>
      this.loadClaimFromUrl()
    );
    this.createManagementNotificationForm();
  }

  private async loadClaimFromUrl() {
    const claimId = this.route.snapshot.queryParamMap.get('claimId');
    const source = this.route.snapshot.queryParamMap.get('source');
    if (claimId) {
      const claim = await lastValueFrom(
        this.http.get<SearchResult>(
          `/api/claim/${source}/${claimId}/${'NA'}/summary`
        )
      );
      this.setClaim(claim);
    }
  }

  private async setClaim(claim: SearchResult) {
    if (claim === null) {
      return;
    }
    this.claimId = claim.claimId;
    this.claimInfo$.next(claim);
    this.claim = claim;
    this.claimConfig = await this.configurationService.getClaimConfiguration(
      claim
    );
  }

  clearForm() {
    this.formGroup.reset();
    this.isDisplaySuccess = false;
    this.afterRtwSuccessMessage = '';
  }

  async submitreturntowork() {
    this.afterRtwSuccessMessage = '';
    if (this.showCRTWDate == true) {
      if (
        this.formGroup.get('rtwDate').value == '' ||
        this.formGroup.get('rtwDate').value == undefined
      ) {
        this.formGroup.get('rtwDate').setErrors({
          'Error : Change my Return to Work Date to is required.': true,
        });
        this.formGroup.get('rtwDate').markAsTouched();
      }
    }

    if (this.formGroup.valid) {
      this.overlayService.show(OverlayMode.Spinner, 'Please wait..');

      // var rtwData: any = {
      //   RtwDate: this.formGroup.get('rtwDate').value != undefined ? this.formGroup.get('rtwDate').value : new Date(this.claim.anticipatedReturnToWorkDate),
      //   RtwType: this.claim.type,
      //   Message: this.claim.listClientMessage[0].message,
      //   EmpUnum: '',
      //   LineOfBusiness: this.claim.lob,
      //   UniqueId: this.claim.claimId,
      //   ClaimNumber: this.claim.claimNumber,
      //   ClientNumber: this.claim.client,
      //   UserClaim: this.claim.userClaim,
      //   ExaminerName: this.claim.examinerName,
      //   ExaminerEmail: this.claim.examinerEmail != undefined ? this.claim.examinerEmail : '',
      //   LossDate: this.claim.lossDate,
      //   Name: this.claim.name,
      //   Type: this.claim.type,
      //   Status: this.claim.status,
      //   SubStatus: this.claim.subStatus != undefined ? this.claim.subStatus : ''

      // };

      const request = {
        ClaimUid:
          this.claim.lob == 'LV' ? this.claim.claimNumber : this.claim.claimId,
        UniqueId:
          this.claim.lob == 'LV' ? this.claim.claimNumber : this.claim.claimId,
        LineOfBusiness: this.claim.lob,
        NewRtwDate:
          this.formGroup.get('rtwDate').value != undefined
            ? this.formGroup.get('rtwDate').value
            : new Date(this.claim.anticipatedReturnToWorkDate),
      };

      this.http.post<any>('/api/returntowork/NewRTWDate', request).subscribe(
        (result) => {
          this.overlayService.hide();

          //this.formGroup.reset();

          const isLiability = this.claim.lob != 'DS' && this.claim.lob != 'LV';
          this.isDisplaySuccess = true;
          const CRTWRBval = this.formGroup.get('duty').value;

          if (CRTWRBval == 'Yes') {
            this.afterRtwSuccessMessage =
              'Thank you.  Your Return to Work Date has been successfully confirmed.';
          }
          if (CRTWRBval == 'No') {
            this.afterRtwSuccessMessage =
              'Thank you.  Your Return to Work Date has been successfully updated.';
          }

          // if (!(result.requestValid && result.jurisReqStatus && result.tamsReqStatus) && !isLiability) {
          //   if (CRTWRBval == "Yes") {
          //     this.afterRtwSuccessMessage = 'Thank you.  Your Return to Work Date has been successfully confirmed.';
          //   }
          //   if (CRTWRBval == "No") {
          //     this.afterRtwSuccessMessage = 'Thank you.  Your Return to Work Date has been successfully updated.';
          //   }
          // }
          // else if ((result.requestValid && result.jurisReqStatus && result.tamsReqStatus) || isLiability) {
          //   if (CRTWRBval == "Yes") {
          //     this.afterRtwSuccessMessage = 'Thank you.  Your Return to Work Date has been successfully confirmed.';
          //   }
          //   if (CRTWRBval == "No") {
          //     this.afterRtwSuccessMessage = 'Thank you.  Your Return to Work Date has been successfully updated.';
          //   }
          // }
          this.activityService.DismissNotificationAlert(
            'RTW_CONFIRM_NEEDED_A',
            this.claim.claimNumber,
            this.claim.claimId,
            this.claim.source,
            this.claim.userOwns
          );
          //this.LogFeature();
        },
        (error) => {
          console.log(error);
          this.overlayService.hide();
        }
      );
    }
  }
}
