import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { PtoInfoDetails } from '../models/ptoInfoDetails';
import { WorkSchedule } from '..';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleService {
  constructor(private http: Http) {}

  async getPto(contNum: string, empUnum: string, lob: string, claimId: string) {
    return await this.http.get<PtoInfoDetails>(
      `/api/workschedule/${contNum}/${empUnum}/${lob}/${claimId}/getpto`
    );
  }

  updateWeeklyWorkSchedule = async (WorkScheduleData: any) => {
    return await this.http.post<any>(
      `/api/workschedule/UpdateWorkScheduleNotes`,
      WorkScheduleData
    );
  };

  getcutomworkschedule = async (workscheduledata: any) => {
    return await this.http.post<any[]>(
      `/api/workschedule/GetWorkSchedule`,
      workscheduledata
    );
  };

  async GetIncidentalWorkSchedule(empUnum: string, absenceNumber: string) {
    return await this.http.get<WorkSchedule>(
      `/api/workschedule/${empUnum}/${absenceNumber}/GetIncidentalWorkSchedule`,
      {
        refreshCache: true,
      }
    );
  }

  async UpdateIncidentalWorkSchedule(
    workSchedule: WorkSchedule,
    empUnum: string
  ) {
    return await this.http.post<boolean>(
      `/api/workschedule/${empUnum}/UpdateIncidentalWorkSchedule`,
      workSchedule
    );
  }
}
