import { AbstractControl, ValidationErrors } from '@angular/forms';

export function arrayRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (value && value.length > 0) {
    return null;
  } else {
    return { required: true };
  }
}
