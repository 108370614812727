import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { LogMessage } from '../..';

@Injectable({
  providedIn: 'root',
})
export class LogApi {
  constructor(private http: Http) {}

  log(message: Partial<LogMessage>, level: LogLevel) {
    const temp = Object.assign(new LogMessage(), message);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.http.post(`/api/log/${level}`, temp).then(() => {});
  }

  trace(message: LogMessage) {
    this.log(message, 'Trace');
  }

  debug(message: LogMessage) {
    this.log(message, 'Debug');
  }

  info(message: LogMessage) {
    this.log(message, 'Information');
  }

  warn(message: LogMessage) {
    this.log(message, 'Warning');
  }

  error(message: LogMessage) {
    this.log(message, 'Error');
  }

  fatal(message: LogMessage) {
    message.message = 'FATAL: ' + message;
    this.log(message, 'Fatal');
  }
}

export type LogLevel =
  | 'Trace'
  | 'Debug'
  | 'Information'
  | 'Warning'
  | 'Error'
  | 'Fatal';
