import { Injectable } from '@angular/core';
import { Session } from 'src/app/my-sedgwick';
import { SnackbarService, SnackbarType } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class emulator {
  constructor(
    public session: Session,
    public snackbarService: SnackbarService
  ) {}

  public showAuditorInfo(): boolean {
    if (
      this.session.user &&
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackbarService.show(
        'Preview only mode. Information not sent',
        SnackbarType.Info
      );
      return true;
    } else {
      return false;
    }
  }
}
