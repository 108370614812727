export class CalendarEvent {
  start: Date;
  end: Date;
  display?: string;
  title?: string;
  backgroundColor?: string;
  color?: string;
  allDay?: boolean;
  extendedProps? : any;
  data?:any;
}
