import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  BaseComponent,
  ConfigurationSetting,
  DialogComponent,
  MyClaim,
  SearchResult,
  ToastType,
} from 'src/app/shared';
import { ClaimDataService } from 'src/app/shared/services/claim-data.service';
import { ClaimInfo } from '../../../shared/models/search-result';
import { RTWResponseStatus } from '../../models/rtw-response';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DirectDepositService } from '../../services/directdeposit.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { OpenFileService } from '../../services/openfile.service';
import { RecentClaimService } from '../../services/recent-claim.service';
import { Session } from '../../services/session.service';
import { ReturnToWorkEditComponent } from '../activity/return-to-work-edit.component';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'app-actions',
  template: `
    <app-panel title="Actions">
      <ng-container
        *ngIf="this.claimConfigService.getFeatureAccess('ReturnToWork')"
      >
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4  inline-block app-pb1"
          imageUrl="./assets/images/text_60x.png"
          title="Submit a return to work date"
          helpText=""
          [imageWidth]="60"
          [imageHeight]="53"
          routerLink="/return-to-work"
          [queryParams]="{
            rtw: 'submit',
            claimId: claim.claimId,
            source: 'J'
          }"
        ></app-action-link>

        <!--<ng-template #rtwTemplate>
          <app-dialog title="Submit Return To Work" confirmLabel="Submit" cancelLabel="Close"
          (afterClosed)="resetData($event)" [SaveAlt]="successAlt" [CancelAlt]="cancelAlt"
           (confirmEvent)="submitedit($event)" [showConfirm]="!isDisplaySuccess" #rtweditdialog>
          <ng-container *ngIf="!isDisplaySuccess" >
            <app-return-to-work-edit [claim] = "claim"  #returntoworkedit (AfterSuccess)="confirmationMessage($event)"
            ></app-return-to-work-edit>
          </ng-container>


           <div *ngIf="isDisplaySuccess">
              <br/>
              <app-toast type="Success">
                  {{afterRtwSuccessMessage}}
              </app-toast>

              <app-upload  [claim] = "claim" [claimId]="claim.claimId" [showClose]="true"
            [source]="claim.source" [dialogRef]="rtweditdialog.dialogRef" [hideHeader] = true
                            *ngIf="this.claimConfigService.getFeatureAccess('UploadDocuments')">
              </app-upload>
           </div>
          </app-dialog>
        </ng-template>-->
      </ng-container>

      <div class="border-actions md:hidden"></div>
      <ng-container
        *ngIf="this.claimConfigService.getFeatureAccess('UploadDocuments')"
      >
        <app-action-link
          class=" tw-w-full md:tw-w-1/2 lg:tw-w-1/4  inline-block app-pb1"
          imageUrl="./assets/images/upload.png"
          title="Upload documents"
          helpText=""
          [routerLink]=""
          [imageWidth]="60"
          [imageHeight]="53"
          [queryParams]="{
            focus: 'documents',
            source: claim.source,
            claimId: claim.claimId,
            showDialog: true
          }"
          routerLink="/upload-documents-in-documentstab"
        ></app-action-link>
        <ng-template #uploadTemplate>
          <app-dialog
            title="Upload Documents"
            ariaLabel="Upload documents dialog"
            confirmLabel="Upload"
            cancelLabel="Close"
            [showActions]="true"
            [showConfirm]="false"
            [width]="700"
            #dialog
          >
            <app-upload
              [dialogRef]="dialog.dialogRef"
              [claim]="claim"
              [claimId]="claim.claimId"
              [source]="claim.source"
            ></app-upload>
          </app-dialog>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="
          claim.userOwns &&
          this.claimConfigService.getFeatureAccess('DirectDeposit')
        "
      >
        <div class="border-actions md:hidden"></div>
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          imageUrl="./assets/images/text_60x.png"
          title="Enroll in Electronic Updates"
          helpText=""
          [imageWidth]="60"
          [imageHeight]="53"
        ></app-action-link>
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <ng-container *ngIf="isManageDDVisiable">
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 inline-block app-pb1"
          icon="account_balance"
          title="Manage direct deposit"
          routerLink="/managedirectdeposit"
          [queryParams]="{
            claimId: claim.claimId,
            source: claim.source
          }"
          [imageWidth]="100"
          [imageHeight]="46"
        ></app-action-link>
      </ng-container>
      <div class="border-actions md:hidden"></div>
      <ng-container
        *ngIf="this.claimConfigService.getFeatureAccess('mileagereimbursement')"
      >
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="drive_eta"
          title="Mileage reimbursement"
          [imageWidth]="100"
          [imageHeight]="46"
          routerLink="/mileage-reimbursement"
          [queryParams]="{
            claimId: claim.claimId,
            source: claim.source
          }"
        ></app-action-link>
      </ng-container>
      <div class="border-actions md:hidden"></div>
      <ng-container
        *ngIf="
          this.claimConfigService.getFeatureAccess('submitintermittentabsences')
        "
      >
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="calendar"
          title="Report an absence1"
          [imageWidth]="100"
          [imageHeight]="46"
          routerLink="/reportanabsence"
          [queryParams]="{
            claimId: claim.claimId,
            source: claim.source
          }"
        ></app-action-link>
      </ng-container>
      <div class="border-actions md:hidden"></div>
      <ng-container *ngIf="isPharmCardVisible">
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="local_pharmacy"
          (linkClick)="onPharmacyCardClick()"
          title="View pharmacy card"
          [imageWidth]="42"
          [imageHeight]="47"
          [claim]="claim"
          routerLink="/pharmacy-card"
          [queryParams]="{ source: source, claimId: claimId }"
        ></app-action-link>
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <ng-container>
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          imageUrl="./assets/images/learningcenter_60x.png"
          [imageWidth]="60"
          [imageHeight]="45"
          title="Visit helpful resources"
          routerLink="/help"
          [queryParams]="{
            focus: 'learningcenter',
            claimId: claim.claimId,
            source: claim.source,
            claim: claim.claimDescription + ' - ' + claim.claimNumber,
            lob: claim.lob,
            origin: 'claimSummary'
          }"
        ></app-action-link>
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <!-- <ng-container *ngIf="this.claimConfigService.getFeatureAccess('contactexaminer')"> -->
      <ng-container *ngIf="!(claim.status.toUpperCase().indexOf('CLOSE') >= 0)">
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="mail_outline"
          title=" Contact {{ examinerLabel }}"
          [imageWidth]="42"
          [imageHeight]="47"
          routerLink="/claim-communication"
          [queryParams]="{ claimId: claimId, source: source }"
        ></app-action-link>
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <ng-container
        *ngIf="
          this.claimConfigService.getConfigFeatureAccess(
            'notificationpreferences'
          )
        "
      >
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="alternate_email"
          title="Change communication preferences"
          [imageWidth]="42"
          [imageHeight]="47"
          routerLink="../PreferencesPage"
          [queryParams]="{ ExpandCCP: 'true', focus: 'changepreferences' }"
        ></app-action-link>
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <ng-container>
        <!--         <app-action-link
           class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="note_alt"
          title="Claim forms"
          [imageWidth]="42"
          [imageHeight]="47"
        ></app-action-link> -->
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <ng-container
        *ngIf="this.claimConfigService.getFeatureAccess('FindaDoctor')"
      >
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 inline-block app-pb1"
          icon="medical_services"
          title="Find a medical provider"
          [imageWidth]="42"
          [imageHeight]="47"
          (click)="openFileLink()"
        ></app-action-link>
      </ng-container>
      <div class="md:hidden border-actions"></div>
      <ng-container
        *ngIf="
          this.session.user.role.toUpperCase() === 'CONTROLLER' ||
          this.session.user.role.toUpperCase() === 'MANAGER'
        "
      >
        <app-action-link
          class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4   inline-block app-pb1"
          icon="bookmarks"
          title="Add to watch list"
          [imageWidth]="42"
          [imageHeight]="47"
          (click)="addToWatchList()"
        ></app-action-link>

        <app-toast [type]="toastErrorType" #toastMsg *ngIf="toastErrorMessage">
          {{ toastErrorMessage }}
        </app-toast>
      </ng-container>
    </app-panel>
  `,
  styles: [
    `
      .border-actions {
        margin: 10px 0px 10px 0px;
        border-bottom: 1px solid #0000001f;
      }
      .inline-block {
        display: inline-block;
      }
      @media only screen and (min-device-width: 480px) {
        .border-actions {
          display: none;
        }
      }
    `,
  ],
})
export class ActionsComponent extends BaseComponent implements OnInit {
  @Input()
  claim: SearchResult;

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  source: string;

  @Input()
  claimId: string;

  @Input()
  lob: string;

  claimInfo: ClaimInfo;

  myClaim: MyClaim;

  myClaims: MyClaim[];
  @Output() displayWatchListErrorMsg = new EventEmitter<string>();

  afterRtwSuccessMessage: string;

  toastErrorType: ToastType = 'Warning';

  isDisplaySuccess: boolean = false;
  isUploadDisplay: boolean = false;
  successAlt: string = 'Submit Return to Work';
  cancelAlt: string = 'Cancel Return to Work';
  isManageDDVisiable: boolean = true;
  isPharmCardVisible: boolean = false;
  toastErrorMessage: string = '';

  examinerLabel: string;

  @ViewChild('returntoworkedit') returntoworkedit: ReturnToWorkEditComponent;
  @ViewChild('rtweditdialog') rtwdialoag: DialogComponent;

  isManageDDAccess = async () => {
    if (this.claim != null && this.claim != undefined) {
      if (this.claimConfigService.getFeatureAccess('DirectDeposit')) {
        const isAccess = await this.ddService.hasAccessDirectDeposit({
          ClaimUID: this.claim.claimId,
          LineOfBusiness: this.claim.lob,
        });

        return isAccess;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  constructor(
    public session: Session,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public claimConfigService: ConfigurationService,
    private claimDataService: ClaimDataService,
    public claimService: ClaimService,
    public ddService: DirectDepositService,
    public inpageAliasService: InpageAliasService,
    public recentClaimService: RecentClaimService,
    public openFileService: OpenFileService,
    private logService: LogService
  ) {
    super();
  }

  async ngOnInit() {
    this.subs.sink = this.claimDataService.currentMyClaim$.subscribe(
      (claim) => {
        if (claim) {
          this.myClaim = claim;
        }
      }
    );

    if (this.myClaim && this.myClaim.claimLevelFeatures) {
      this.isPharmCardVisible =
        this.myClaim.claimLevelFeatures.indexOf('medicalcard') > -1
          ? true
          : false;
    } /*else {
      this.myClaims = await (<any>this.claimService.getClaims());
      if (
        this.myClaims.filter(
          (claim) => claim.claimNumber === this.claim.claimNumber
        ).length > 0
      ) {
        this.isPharmCardVisible =
          this.myClaims
            .filter((claim) => claim.claimNumber === this.claim.claimNumber)[0]
            .claimLevelFeatures.indexOf('medicalcard') > -1
            ? true
            : false;
      } else {
        this.isPharmCardVisible = false;
      }
    }*/

    this.afterRtwSuccessMessage = '';
    this.subs.sink = this.route.queryParams.subscribe(
      (querystr: { [x: string]: string }) => {
        this.claimId = querystr['claimId'];
        this.source = querystr['source'];
      }
    );
    this.isManageDDVisiable = await this.isManageDDAccess();
    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingular',
      this.claimConfigService.GetConfig.configFeatures.aliases
    );
  }

  onPharmacyCardClick(): void {
    this.claimDataService.setClaim(this.claim);
  }

  submitedit(eve: any) {
    this.afterRtwSuccessMessage = '';
    this.returntoworkedit.ClaimID = this.claim.claimNumber;
    this.returntoworkedit.LOB = this.claim.lob;
    this.returntoworkedit.submitreturntowork();
  }

  confirmationMessage(event: RTWResponseStatus) {
    this.isDisplaySuccess = true;
    this.afterRtwSuccessMessage = event.msg;
    this.isUploadDisplay = event.isSuccess;
    this.rtwdialoag.showActions = !event.isSuccess;
  }

  async addToWatchList() {
    const str = await this.recentClaimService.addToWatchList(this.claim);
    if (str != 'success') {
      this.toastErrorMessage =
        'The Watch List is full. Additional claims can be added when one or more claims have been removed.';
    }
  }

  resetData(event: string) {
    this.isDisplaySuccess = false;
    this.afterRtwSuccessMessage = '';
  }

  openFileLink() {
    this.openFileService.openfile();
  }
}
