import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/shared';
import { Session, isManagement } from '../..';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { ClaimService } from '../../services/claim.service';
import { LearningCenterService } from '../../services/learning-center.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-learning-center',
  template: `
    <div class="tw-w-full app-pt3">
      <app-learning-center-header
        *ngIf="!loading"
        [isMgr]="isMgr"
        [items]="items"
        [lob]="lob"
        (lobChange)="lobChange($event)"
      ></app-learning-center-header>
      <div class="app-pb1"></div>
      <ng-container [ngSwitch]="lob">
        <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'">
          <ng-template #content>
            <div *ngSwitchCase="'WC'">
              <app-learning-center-wc
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [claimClosed]="claimClosed"
                [lcType]="lcType"
              ></app-learning-center-wc>
            </div>
            <div *ngSwitchCase="'DS'">
              <app-learning-center-ds
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [lcType]="lcType"
              ></app-learning-center-ds>
            </div>
            <div *ngSwitchCase="'LV'">
              <app-learning-center-lv
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [lcType]="lcType"
              ></app-learning-center-lv>
            </div>
            <div *ngSwitchCase="'AD'">
              <app-learning-center-ada
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [lcType]="lcType"
              ></app-learning-center-ada>
            </div>
            <div *ngSwitchCase="'GL'">
              <app-learning-center-gl
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [claimClosed]="claimClosed"
                [lcType]="lcType"
              ></app-learning-center-gl>
            </div>
            <div *ngSwitchCase="'AU'">
              <app-learning-center-au
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [claimClosed]="claimClosed"
                [lcType]="lcType"
              ></app-learning-center-au>
            </div>
            <div *ngSwitchCase="'PL'">
              <app-learning-center-pl
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [claimClosed]="claimClosed"
                [lcType]="lcType"
              ></app-learning-center-pl>
            </div>
            <div *ngSwitchCase="'PR'">
              <app-learning-center-pr
                [learningCenter]="learningCenter"
                [isMgr]="isMgr"
                [loading]="loading"
                [claimClosed]="claimClosed"
                [lcType]="lcType"
              ></app-learning-center-pr>
            </div>
            <div *ngSwitchDefault>Select a line of business</div>
          </ng-template>
        </app-loading-panel>
      </ng-container>
    </div>
  `,
  styles: [],
})
export class LearningCenterComponent extends BaseComponent implements OnInit {
  @Input()
  lob: string;

  @Input()
  origin: string;

  claimId: string;
  isMgr: boolean;
  lcType: string;
  source: string;
  loading: boolean = true;
  learningCenter: any;
  items: any[] = [];
  claims: any[] = [];
  claimClosed: boolean = false;
  isManagement: boolean;

  constructor(
    public learningCenterService: LearningCenterService,
    private session: Session,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private userRole: UserRoleService,
    private userAction: UserActionService
  ) {
    super();
  }

  public lobChange(lob: string) {
    if (this.lob !== lob) {
      this.lob = lob;
      this.loadLearningCenter();
    }
  }

  async ngOnInit() {
    this.claimClosed =
      this.userRole.claimant && this.session.user.isClaimClosed;
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
    this.lob =
      this.route.snapshot.queryParamMap.get('lob')?.toString() === 'LA'
        ? 'LV'
        : this.route.snapshot.queryParamMap.get('lob');
    this.claims = await (<any>this.claimService.getClaims());
    this.isMgr = this.userRole.manager || this.userRole.controller;
    await this.setLobs();
    await this.setLobHierarchy();

    if (!this.lob) {
      this.lob = this.items[0].value;
      this.source = this.items[0].source;
      if (!this.isMgr && this.claimId) {
        this.claimId = this.claims[0]?.claimId;
        this.source = this.claims[0]?.source;
      }
    }
    await this.loadLearningCenter();
    console.log(this.learningCenter);
    this.loading = false;
  }

  private includesTerminatedEmployee() {
    return (
      this.session.user.emulatorContext?.isTerminated ||
      this.session.user.isTerminated
    );
  }

  private async loadLearningCenter() {
    this.loading = true;
    try {
      this.lcType = this.getLcType(this.lob);
      this.isMgr = ['MANAGER', 'CONTROLLER'].includes(this.lcType);
      this.learningCenter = {};
      const claimObject = {
        source: this.source,
        claimId: this.claimId,
        lob: this.lob,
      };

      this.learningCenter = await this.learningCenterService.getLearningCenter(
        this.source,
        this.claimId,
        this.lob,
        this.lcType,
        this.includesTerminatedEmployee()
      );
      this.learningCenter.claimInfo = claimObject;
    } finally {
      this.loading = false;
    }
  }

  getLcType(lob: string) {
    const lobTypes = ['WC', 'AU', 'GL', 'PR', 'PL']; // these have CLAIMANT and CONTROLLER type in setup app
    const mgrAction = this.userAction.getAction();
    const origin = this.origin
      ? this.origin
      : this.route.snapshot.queryParamMap.get('origin');
    if (lobTypes.includes(lob)) {
      if (
        ((this.userRole.manager || this.userRole.controller) &&
          (mgrAction == UserActions.ViewOwnMgrDb ||
            mgrAction == UserActions.MgrViewEmpClaim ||
            mgrAction == UserActions.MgrViewEmpDb ||
            origin === 'leftNav' ||
            origin === 'summaryDashboard' ||
            origin === 'supervisor' ||
            origin === 'managerDashboard' ||
            origin === 'controllerDashboard')) ||
        this.userRole.controller
      ) {
        return 'CONTROLLER';
      } else {
        return 'CLAIMANT';
      }
    } else {
      if (
        ((this.userRole.manager || this.userRole.controller) &&
          (mgrAction == UserActions.ViewOwnMgrDb ||
            mgrAction == UserActions.MgrViewEmpClaim ||
            mgrAction == UserActions.MgrViewEmpDb ||
            mgrAction == UserActions.ManagerSummaryView ||
            origin === 'leftNav' ||
            origin === 'summaryDashboard' ||
            origin === 'supervisor' ||
            origin === 'managerDashboard' ||
            origin === 'controllerDashboard')) ||
        this.userRole.controller
      ) {
        return 'MANAGER';
      } else {
        return 'EMPLOYEE';
      }
    }
  }

  async setLobs() {
    if (
      this.isMgr ||
      (this.session.user.role === 'HRM' &&
        (this.session.user.authenticationType === 'HRM' ||
          this.session.user.authenticationType === 'SAML' ||
          this.session.user.authenticationType === 'EMULATEE'))
    ) {
      this.items = await this.session.user.lineOfBusiness.map((u) => ({
        label: u.lobName,
        value: u.lob,
      }));
    } else {
      this.claims = await (<any>this.claimService.getClaims());
      if (this.claims.length == 0) {
        this.items = this.session.user.clientLobs.map((u) => ({
          label: u.lobName,
          value: u.lob,
          source:
            u.recordSource === 'JURIS'
              ? 'J'
              : u.recordSource === 'TAMS'
              ? 'T'
              : u.recordSource,
        }));
      } else {
        this.items = [];
        for (const claim of this.claims) {
          if (this.items.findIndex((x) => x.value === claim.lob) === -1) {
            this.items.push({
              label: claim.Description,
              value: claim.lineOfBusiness,
            });
          }
        }
      }
    }
  }

  setLobHierarchy() {
    const lobHierarchy = ['DS', 'WC', 'LV', 'AD', 'GL', 'PR', 'AU', 'PL'];
    if (this.items.length > 1) {
      this.items.sort((a, b) => {
        const indexA = lobHierarchy.indexOf(a.value);
        const indexB = lobHierarchy.indexOf(b.value);
        return indexA - indexB;
      });
    }
  }
}
