import { Component } from '@angular/core';

@Component({
  selector: 'app-watch-list-page',
  template: `
    <app-section>
      <app-claim-breadcrumb></app-claim-breadcrumb>
      <app-watch-list class="tw-w-full"></app-watch-list>
    </app-section>
  `,
  styles: [],
})
export class WatchListPageComponent {}
