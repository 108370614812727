import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-learning-center-section',
  template: `
    <ng-container *ngIf="layout === 'icon'">
      <div class="tw-w-full tw-flex app-pb1 app-pt1">
        <div class="tw-grow-0 app-pr2 tw-hidden md:tw-block">
          <mat-icon class="icon-big">{{ icon }} </mat-icon>
        </div>
        <div class="tw-grow">
       <h3 class="app-lc-header-3" role="heading">
            {{ label }}
          </h3>
          <div [ngTemplateOutlet]="content"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="layout === 'bullet'">
      <div class="tw-w-full tw-flex app-pb1 app-pt1">
        <div class="tw-grow-0 app-pr2 tw-items-stretch">
          <div class="bullet-big tw-align-middle">
            {{ icon }}
          </div>
        </div>

        <div class="tw-grow">
          <h3 class="app-lc-header-3"  role="heading">
            {{ label }}
          </h3>
          <div [ngTemplateOutlet]="content"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="layout === 'icon-small'">
      <div class="tw-flex tw-flex-col tw-flex-wrap app-p1">
        <div class="tw-text-center">
          <mat-icon class="">{{ icon }} </mat-icon>
        </div>

        <div class="tw-text-center">
          <div [ngTemplateOutlet]="content"></div>
        </div>
      </div>
    </ng-container>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [
    `
      .app-lc-header-2 {
        color: var(--color-teal);
        font-size: 18px;
        font-weight: bold;
      }
      .app-lc-header-3 {
        color: var(--color-teal);
        font-size: 15px;
        font-weight: bold;
      }  
      .margin {
        margin: 10px;
      }

      .icon-big {
        width: 48px;
        height: 48px;
        font-size: 48px;
        color: var(--color-teal);
      }

      .bullet-big {
        background-color: var(--color-teal);
        color: white;
        padding: 10px;
        border-radius: 5px;
        font-size: 24px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }
    `,
  ],
})
export class LearningCenterSectionComponent {
  @Input()
  label: string;

  @Input()
  icon: string;

  @Input()
  layout: SectionLayout;
}

export type SectionLayout = 'icon' | 'bullet' | 'icon-small';
