import { Injectable } from '@angular/core';
import { SearchResult } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private http: Http) {}

  async LogFeature(
    claim: SearchResult,
    FeatureName: string,
    SectionName: string,
    DocumentId: string = ''
  ): Promise<boolean> {
    if (claim != null && claim != undefined) {
      const claimId = claim.claimId;
      const source = claim.source;
      const lob = claim.lob;

      return await this.http.get<boolean>(
        `/api/features/${source}/${claimId}/${FeatureName}/${lob}/${SectionName}/FeatureLogRequest`
      );
    } else if (DocumentId == '') {
      return await this.http.get<boolean>(
        `/api/features/${FeatureName}/${SectionName}/FeatureLogRequestNoClaim`
      );
    } else {
      return await this.http.get<boolean>(
        `/api/features/${FeatureName}/${SectionName}/${DocumentId}/FeatureLogRequestNoClaimWithDocId`
      );
    }
  }

  async LogFeatureWithClaim(
    claimId: string,
    source: string,
    lob: string,
    featureName: string,
    sectionName: string
  ): Promise<boolean> {
    return await this.http.get<boolean>(
      `/api/features/${source}/${claimId}/${featureName}/${lob}/${sectionName}/FeatureLogRequest`
    );
  }
}
