import { Component, Input } from '@angular/core';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-container-control',
  template: `
    <mat-label [ngClass]="{ boldLabel: isLabelBold }">{{ label }}</mat-label>
    <div class="radio-container">
      <ng-content></ng-content>
      <mat-hint class="hint">{{ hintLabel }}</mat-hint>
      <mat-error
        *ngIf="
          (formControl.dirty || formControl.touched) && formControl.invalid
        "
        >{{ errorMessage }}</mat-error
      >
    </div>
  `,
  styles: [
    `
      .hint {
        font-size: 11px;
      }
      .radio-container {
        color: #67707a;
        border-color: #67707a;
      }
      .radio-container:focus {
        color: #171c20;
        border-color: #007dbc;
      }
      .boldLabel {
        font-weight: 600;
      }
    `,
  ],
})
export class ContainerControlComponent extends BaseControlComponent {
  ngOnInit(): void {}

  @Input()
  isLabelBold: boolean = false;
}
