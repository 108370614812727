import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DelegationService } from '../../services/delegation.service';
import { Session } from '../../services/session.service';
import { LoadingState, deepCopy } from 'src/app/shared';
import { DisplayService, Excel, ExportRequest } from '../..';
import { Router } from '@angular/router';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-act-delegate-page',
  template: `
    <app-section class="tw-w-full">
      <app-claim-breadcrumb class="tw-w-[90%]"></app-claim-breadcrumb>
      <app-panel
        [largeHeader]="true"
        title="Act as delegate"
        class="tw-w-full"
        [labelTemplate]="labelTemplate"
        [canExpand]="false"
      >
        <ng-template #labelTemplate>
          <div
            class="tw-grow tw-items-start tw-justify-between tw-flex"
            *ngIf="showManageDelegates"
          >
            <a class="app-pl2 app-header-link" routerLink="/manage-delegates"
              >Manage delegates</a
            >
          </div>
        </ng-template>
        <app-loading-panel [skeletonLines]="8" [state]="loading">
          <ng-template #empty>
            <div
              class="tw-flex tw-items-center tw-w-full tw-h-full tw-justify-around"
            >
              <app-empty-state-label
                icon="group_off"
                header="You are not assigned to any current Delegators"
              >
              </app-empty-state-label>
            </div>
          </ng-template>
          <ng-template #content>
            <app-list
              class="tw-w-full"
              [dataSource]="delegators.delegatees"
              [template]="row"
              [filterEnabled]="true"
              [exportEnabled]="true"
              exportButtonAriaLabel="Export and download these delegates as a XLSX file"
              [noDataIcon]="noDataIcon"
              noDataHeader="No new delegates"
              noDataMessage="Use the Search feature to find a delegates"
              (export)="_export()"
              [tableAriaLabel]="'Delegators'"
            >
              <ng-container header>
                <tr class="tw-flex tw-flex-wrap">
                  <app-list-header
                    name="empId"
                    label="Act as delegate"
                    class="tw-w-[25%]"
                    [sortEnabled]="false"
                  ></app-list-header>
                  <app-list-header
                    name="delegatorLabel"
                    label="Name"
                    class="tw-w-[25%]"
                  ></app-list-header>
                  <app-list-header
                    name="dateEffective"
                    label="Delegation start date"
                    class="tw-w-[25%]"
                  ></app-list-header>
                  <app-list-header
                    name="dateExpire"
                    label="Delegation end date"
                    class="tw-w-[25%]"
                  ></app-list-header>
                </tr>
              </ng-container>

              <ng-template #row let-element role="rowgroup">
                <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                  <td class="tw-w-full sm:tw-w-[25%]">
                    <app-icon-button
                      *ngIf="
                        !element.permissionExpired && element.permissionActive
                      "
                      icon="play_circle"
                      label="Begin Delegate Session"
                      tabindex="0"
                      role="button"
                      title="Begin Delegate Session"
                      [attr.aria-label]="
                        'Act as delegate for ' + element.delegatorLabel
                      "
                      (click)="startDelegation(element)"
                      (keydown.enter)="startDelegation(element)"
                    ></app-icon-button>
                    <app-icon-button
                      *ngIf="
                        element.permissionExpired && element.permissionActive
                      "
                    ></app-icon-button>
                  </td>
                  <td class="tw-w-full sm:tw-w-[25%] ">
                    <app-value
                      [value]="element.delegatorLabel"
                      label="Delegator"
                      [tabindex]="element.delegatorLabel ? '0' : '1'"
                      layout="row-xs"
                    ></app-value>
                  </td>
                  <td class="tw-w-full sm:tw-w-[25%]">
                    <app-value
                      label="Delegation Start Date"
                      [tabindex]="element.dateEffective ? '0' : '1'"
                      layout="row-xs"
                      [value]="element.dateEffective | format : 'date'"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full sm:tw-w-[25%]">
                    <app-value
                      label="Delegation End Date"
                      [tabindex]="element.dateExpire ? '0' : '1'"
                      layout="row-xs"
                      [value]="element.dateExpire | format : 'date'"
                    >
                    </app-value>
                  </td>
                </tr>
              </ng-template>
            </app-list>
            <div *ngIf="!startDelegationSuccess">
              <br />
              <app-toast type="Error">
                Error starting delegation. The delegator no longer has the
                proper role.
              </app-toast>
            </div>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: ``,
})
export class ActDelegatePageComponent implements OnInit {
  @Output()
  export = new EventEmitter<ExportRequest>();

  delegators: Delegatees;

  loading: LoadingState;

  showManageDelegates = false;

  noDataIcon: string = 'list_alt';

  startDelegationSuccess: boolean = true;

  constructor(
    private delegateService: DelegationService,
    private session: Session,
    private excel: Excel,
    private router: Router,
    private userRole: UserRoleService,
    private display: DisplayService
  ) {}

  async ngOnInit() {
    this.loading = 'Loading';
    const delegationFeatures = await this.display.delegationFeatures();
    this.showManageDelegates =
      delegationFeatures.manageOthersDelegates ||
      delegationFeatures.manageOwnDelegates;
    this.delegators = await this.delegateService.getDelegators(
      this.session.user.empUnum
    );
    this.loading =
      this.delegators && this.delegators.delegatees.length > 0
        ? 'Loaded'
        : this.delegators && this.delegators.delegatees.length === 0
        ? 'Empty'
        : 'Loading';
  }

  async startDelegation(delegatee: Delegatee) {
    const result = await this.session.startDelegation(delegatee);

    if (result == 'Error') {
      this.startDelegationSuccess = false;
    } else {
      this.delegateService.updateDelegateName(delegatee.delegatorName); // update the delegate name
      this.startDelegationSuccess = true;
      switch (true) {
        case this.userRole.supervisor:
          this.router.navigate(['/supervisor']);
          break;
        case this.userRole.overseer:
        case this.userRole.omni:
          this.router.navigate(['/summary']);
          break;
        default:
          this.router.navigate(['/supervisor']);
          break;
      }
    }
  }

  _export() {
    const claimRows = deepCopy(this.delegators.delegatees);

    const request = new ExportRequest();
    request.fileName = 'Delegates.xlsx';
    request.sheets = [
      {
        name: 'Deligates',
        rows: claimRows,
        headers: [
          { label: 'NAME', value: 'delegatorLabel' },
          {
            label: 'DELEGATION START DATE',
            value: 'dateEffective',
            format: 'date',
          },
          { label: 'DELEGATION END DATE', value: 'dateExpire', format: 'date' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }
}

export interface Delegatee {
  contNum: number;
  delegatePk: number;
  delegatorEmpUnum?: string;
  delegatorFirstName?: string;
  delegatorLastName?: string;
  delegatorName?: string;
  delegatorLabel?: string;
  delegatorEmpId?: string;
  delegatorHrMasterUid?: string;
  delegateeEmpUnum: string;
  delegateeFirstName: string;
  delegateeLastName: string;
  delegateeName: string;
  delegateeEmpId: string;
  delegateeLabel: string;
  delegateeHrMasterUid: string;
  managerFirstName?: string;
  managerLastName?: string;
  managerLabel?: string;
  dateEffective: string;
  dateExpire: string;
  reportClaim: boolean;
  addAbsences: boolean;
  emailCorrespondence: boolean;
  deletedFlag: boolean;
  permissionExpired: boolean;
  permissionActive: boolean;
}

export interface Delegator {
  delegatorEmpUnum: string;
  delegatorFirstName: string;
  delegatorLastName: string;
  delegatorName?: string;
  delegatorLabel?: string;
  delegatorEmpId: string;
  delegatorHrMasterUid: string;
}

export interface Delegatees {
  isMaxCount: boolean;
  delegatees: Delegatee[];
}
