import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { srSpeak } from 'src/app/shared';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from '../../base.component';
import { Filter, FilterPredicate } from '../filter/filter';
import { ToastType } from '../toast.component';
import { ListHeaderComponent } from './list-header.component';

@Component({
  selector: 'app-list',
  template: `
    <!-- For Screen reader to announce sorted status -->
    <div
      id="listSortDescription"
      aria-live="polite"
      aria-atomic="true"
      class="sr-only"
    >
      {{ announceMessage }}
    </div>
    <div
      id="listSearchDescription"
      aria-live="polite"
      aria-atomic="true"
      class="sr-only"
    >
      {{ searchAnnounceMessage }}
    </div>
    <!-- Info toast -->
    <div *ngIf="displayInfoToastMsg" class="tw-w-full">
      <app-toast type="Info">
        {{ toastInfoMessage }}
      </app-toast>
    </div>

    <!-- Filter and sort controls -->
    <div class="tw-flex tw-pt-4 t-w-full" *ngIf="hadData || forceHeader">
      <div class="text-filter tw-w-full tw-pb-2">
        <form
          class="tw-flex md:tw-flex-wrap tw-items-center md:tw-justify-between"
        >
          <div *ngIf="!filterEnabled"></div>
          <div
            *ngIf="filterEnabled"
            class="tw-flex tw-items-center tw-w-full md:tw-w-[75%]"
          >
            <mat-icon
              class="icon app-color-teal tw-cursor-pointer"
              (click)="toggleFilter()"
              role="button"
              >filter_alt</mat-icon
            >
            <span (click)="toggleFilter()" (keydown.enter)="toggleFilter()">
              <a
                tabindex="0"
                role="button"
                class="app-pr2 tw-text-[16px] tw-pl-1 app-color-teal"
                class="app-pr2 tw-text-[16px] tw-pl-1 app-color-teal"
                >{{ filterShowEdit ? 'Close' : 'Filter' }}</a
              >
            </span>
            <mat-form-field
              *ngIf="filterShowEdit"
              class=" remove-field-padding"
              subscriptSizing="dynamic"
            >
              <mat-label>{{ searchLabel }}</mat-label>
              <input
                matInput
                [formControl]="textFilterControl"
                #search
                autocomplete="off"
                (keydown.enter)="$event.preventDefault()"
                (keydown.shift.enter)="$event.preventDefault()"
                [attr.aria-describedby]="'listSearchDescription'"
              />
              <button
                mat-icon-button
                matSuffix
                aria-label="Clear Filter"
                color="accent"
                [attr.aria-label]="clearFilterAriaLabel"
                [disabled]="!textFilterControl.value"
                (click)="textFilterControl.setValue(null)"
              >
                <mat-icon *ngIf="textFilterControl.value"
                  >highlight_off</mat-icon
                >
              </button>
            </mat-form-field>
          </div>
          <button
            class="tw-flex tw-items-center tw-cursor-pointer"
            tabindex="0"
            [attr.aria-label]="sortButtonAriaLabel"
            role="button"
            *ngIf="ShowSort"
            [attr.aria-controls]="sortMenuId"
            [attr.aria-haspopup]="menu"
            (click)="toggleSort()"
            (keydown.enter)="toggleSort()"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon class="icon" *ngIf="!sortDir">sort</mat-icon>
            <mat-icon
              class="icon"
              role="img"
              aria-label="sorted in ascending order"
              *ngIf="sortDir === 'asc'"
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="icon"
              role="img"
              aria-label="sorted in descending order"
              *ngIf="sortDir === 'desc'"
              >arrow_downward</mat-icon
            >

            <a class="app-pr2 tw-text-[16px] tw-pl-1">{{ getSortLabel() }}</a>
            <mat-menu #menu="matMenu" [id]="sortMenuId">
              <ng-container *ngFor="let header of headers">
                <button
                  mat-menu-item
                  role="menuitem"
                  *ngIf="header.sortEnabled"
                  (click)="
                    setSort(
                      header.name,
                      sortDir === 'asc' ? 'desc' : 'asc',
                      header.names
                    )
                  "
                >
                  {{ header.sortLabelComputed }}
                </button>
              </ng-container>
            </mat-menu>
          </button>
        </form>
      </div>
    </div>
    <table role="table" class="tw-w-full" [attr.aria-label]="tableAriaLabel">
      <!-- Header controls -->
      @if(hasData) {
      <div class="header" [class.overflow-padding]="_isScrollVisible">
        <div class="tw-w-full mobile-header">
          <th class="select-all md:tw-block tw-hidden" *ngIf="showRowSelection">
            <mat-checkbox
              class="mat-mdc-checkbox mat-accent "
              (change)="$event ? toggleAllRows() : null"
              [checked]="_selection.hasValue() && isAllSelected()"
              [indeterminate]="_selection.hasValue() && !isAllSelected()"
              [aria-label]="ariaLabelSelectAll"
            >
            </mat-checkbox>
          </th>
        </div>
        <tr class="desktop-header tw-pl-2">
          <th
            class="select-all tw-grow-0 chk-fl-lt md:tw-block tw-hidden"
            *ngIf="showRowSelection"
            scope="col"
          >
            <mat-checkbox
              class="mat-mdc-checkbox mat-accent "
              (change)="$event ? toggleAllRows() : null"
              [checked]="_selection.hasValue() && isAllSelected()"
              [indeterminate]="_selection.hasValue() && !isAllSelected()"
              [aria-label]="ariaLabelSelectAll"
            >
            </mat-checkbox>
          </th>
          <div class="select-all tw-grow">
            <ng-content select="[header]"></ng-content>
          </div>
        </tr>
      </div>
      <mat-divider aria-hidden="true"></mat-divider>
      }

      <div
        class="tw-w-full"
        #overflowContainer
        [ngClass]="{
          'tw-overflow-y-scroll': fixScrollOverflowHeight,
          'tw-overflow-y-auto': overflowHeight
        }"
        [style.height]="
          overflowHeight !== '' ? overflowHeight : fixScrollOverflowHeight
        "
        [attr.aria-describedby]="'listSortDescription'"
      >
        <!-- Empty state controls (v2) - Refactor to remove empty state from list control -->
        <div
          class="tw-w-full tw-flex tw-justify-center tw-pt-4"
          *ngIf="_loaded && emptyData"
        >
          <app-empty-state-label
            [icon]="noDataIcon"
            [message]="noDataMessage"
            [thinMessage]="noDataThinMessage"
            [header]="noDataHeader"
          ></app-empty-state-label>
        </div>

        <!-- Data row controls -->
        <div *ngIf="hasData" #rowContainer>
          <ng-container *ngFor="let item of viewData; let itemIndex = index">
            <tr
              class="tw-w-full flex-row"
              [ngClass]="{
                'app-bg-color-selected':
                  rowSelectEnabled && clickedItem === item,
                'tw-cursor-pointer': rowSelectEnabled,
                'hover:tw-bg-[#f1f4f7]': rowSelectEnabled
              }"
              (click)="clickedItem = item; rowClick.emit(item)"
              (keydown.enter)="clickedItem = item; rowClick.emit(item)"
            >
              <td *ngIf="showRowSelection" class="md:tw-block tw-hidden flex-1">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? _selection.toggle(item) : null"
                  [checked]="_selection.isSelected(item)"
                  [aria-label]="
                    ariaLabelSelect +
                    ' - ' +
                    item.claimDescription +
                    ' - ' +
                    item.claimNumber
                  "
                >
                </mat-checkbox>
              </td>

              <div
                class="tw-pl-2 tw-my-1 flex-most"
                [attr.list-index]="itemIndex"
                [ngClass]="showRowSelection === true ? 'heading-fl-rt' : ''"
              >
                <ng-container
                  *ngTemplateOutlet="
                    template;
                    context: { $implicit: item, index: itemIndex }
                  "
                ></ng-container>
              </div>
            </tr>
            <mat-divider aria-hidden="true"></mat-divider>
          </ng-container>
        </div>
      </div>
    </table>
    <!-- Footer controls: error message, paginator template, export and paginator -->
    <div *ngIf="hasData">
      <div class="tw-flex tw-flex-wrap tw-w-full tw-items-center app-pt1">
        <div *ngIf="displayErrorToastMsg">
          <app-toast [type]="toastErrorType" #toastMsg>
            {{ toastErrorMessage }}
          </app-toast>
        </div>
        <div class="tw-grow">
          <ng-container *ngTemplateOutlet="paginatorTemplate"> </ng-container>
        </div>
        <button
          mat-icon-button
          class="mat-datepicker-toggle tw-self-center"
          alt="Export"
          [attr.aria-label]="exportButtonAriaLabel"
          *ngIf="exportEnabled"
          (click)="export.emit()"
        >
          <mat-icon class="app-color-input-grey">file_download</mat-icon>
        </button>
        <div class="clear">
          <mat-paginator
            [pageSizeOptions]="pageSizeOptions"
            #paginator
            [pageSize]="pageSize"
            showFirstLastButtons
            [length]="_dataSource.filteredData.length"
            (page)="pageChange($event)"
            *ngIf="showPaginator()"
            role="navigation"
            aria-label="pagination"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .nodataclr {
        color: #67707a;
      }
      @media (min-width: 768px) {
        .nodata {
          margin-bottom: 8px;
        }
        .minRowHeight {
          min-height: 35px !important;
        }
      }

      .mobile-header {
        display: none;
        padding: 0px !important;
      }

      @media (max-width: 730px) {
        .mobile-header {
          display: block;
        }
      }

      .desktop-header {
        display: block;
      }

      @media (max-width: 730px) {
        .desktop-header {
          display: none;
        }
      }

      .header {
        background-color: #f1f4f7;
        padding: 8px 0 8px 0px;
        -padding: 16px 0px 16px 0px;
        -height: 58px;
      }

      @media (max-width: 768px) {
        .header {
          padding: 0px !important;
        }
      }

      .text-filter {
        min-width: 250px;
      }
      .remove-field-padding {
        -margin-bottom: -1em;
      }
      .flex-left {
        width: 30%;
      }
      .flex-right {
        width: 30%;
      }
      .flex-mid {
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .flex-container {
        display: flex;
        flex-direction: row;
      }
      .icon-text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon-size {
        font-size: 60px;
        height: 60px;
        width: 60px;
      }

      .select-all {
        /* padding: 0 16px 0 16px;*/
        -height: 42px;
      }

      .select-all mat-checkbox {
        padding-top: 0px;
      }

      .flex-row {
        display: flex;
        flex-direction: row;
      }
      .flex-1 {
        width: 40px;
      }
      .flex-most {
        flex-grow: 9;
      }

      .chk-fl-lt {
        float: left;
      }
      .heading-fl-rt {
        float: right;
      }
      .no-contentdata {
        float: left;
      }
      .error-validation {
        color: red;
      }
      toast-display {
        width: 40%;
      }

      .sortIcon {
        font-size: 20px !important;
      }
      .sortIconbtn {
        padding-left: 1px !important;
        width: 0px !important;
        vertical-align: middle;
      }

      .icon {
        color: var(--color-sedgwick-blue);
        font-size: 16px;
        width: 16px;
        height: 16px;
        -padding-top: 2px;
      }

      .overflow-padding {
        padding-right: 12px;
      }
    `,
  ],
})
export class ListComponent extends BaseComponent implements OnInit {
  @ViewChild('paginator')
  paginator: MatPaginator;

  @ViewChild('rowContainer')
  rowContainer: ElementRef;

  @ViewChild('overflowContainer')
  overflowContainer: ElementRef;

  @Input()
  textFilter: boolean = true;

  @Input()
  customNoDataLayoutClass: string;

  @Input()
  includeNoDataIcon: boolean = true;

  @Input()
  showRowSelection: boolean = false;

  @Input()
  rowSelectEnabled: boolean = false;

  @Input()
  deleteEnabled: boolean = false;

  @Input()
  paginatorTemplate: TemplateRef<any>;

  @Input()
  displayErrorMessage: boolean = false;

  @Input()
  displayErrorToastMsg: boolean = false;

  @Input()
  exportButtonAriaLabel: string;

  @Input()
  searchLabel = 'Search results for ....';

  @Input()
  filterShowEdit = false;

  clearFilterAriaLabel: string = 'Clear filter search';

  private _data: any[];
  formGroup: any;

  @Input()
  set dataSource(value: any[]) {
    this._data = value;
    this._loaded = true;
    this.updateDataSource();
  }
  get dataSource(): any[] {
    return this._data;
  }

  @Input()
  pageSizeOptions: number[] = [5, 10, 25];

  @Input()
  pageSize: number = 5;

  @Input()
  filterEnabled = false;

  @Input()
  paginatorEnabled = true;

  @Input()
  exportEnabled = false;

  @Input()
  template: TemplateRef<any>;

  @Input()
  noDataMessage: string;

  @Input()
  noDataThinMessage: string;

  @Input()
  noDataHeader: string;

  @Input()
  loadingMessage = 'Loading...';

  @Input()
  noDataIcon = '';

  @Input()
  loaded = false;

  @Input()
  overlay: boolean = false;

  @Input()
  toastInfoMessage: string;

  @Input()
  toastErrorMessage: string;

  @Input()
  displayInfoToastMsg: boolean = false;

  @Input() // To Show and apply Sort Controls
  ShowSort: boolean = true;

  @Input()
  DefaultSortColumn: string = '';

  @Input()
  DefaultSortDir: SortDirection = '';

  /**
   * Enables vertical overflow with scrollbar. If set, then disable
   * paging. Value should contain units. Example: '100px'
   */
  @Input()
  overflowHeight: string;

  @Input()
  fixScrollOverflowHeight: string;

  /**
   * Stores current selected item when RowClickEnabled is true.
   * rowClick event is also emitted.
   */
  @Input()
  clickedItem: any;

  @Input()
  forceHeader: boolean = false;

  @Input()
  ariaLabelSelectAll: string = 'Select All';

  @Input()
  ariaLabelSelect: string = 'Select this';

  @Input()
  tableAriaLabel: string = 'Items';

  @Output()
  export = new EventEmitter();

  @Output()
  rowClick = new EventEmitter<any>();

  hadData: boolean = false;

  get hasData(): boolean {
    return this.viewData && this.viewData.length > 0;
  }

  get emptyData(): boolean {
    return this.viewData && this.viewData.length === 0;
  }

  toastErrorType: ToastType = 'Warning';

  private readonly _maxPageSize = 1000;

  private _textFilterValue: string;

  selectedPageSize: number;

  textFilterControl = new UntypedFormControl();

  _selection = new SelectionModel<any>(true, []);

  _dataSource = new MatTableDataSource<any>([]);

  sortValue: string = '';

  sortDir: SortDirection = 'asc';

  sortTest: string = '';

  sortTries: number = 0;

  viewData: any[];

  _loaded = false;

  _isScrollVisible = false;

  announceMessage: string = '';

  searchAnnounceMessage: string = '';

  sortButtonAriaLabel: string = `Select a column header for sorting, sorted by ${
    this.DefaultSortColumn ? this.DefaultSortColumn : 'none'
  } in ${this.DefaultSortDir ? this.DefaultSortDir : 'no'} order`;

  get selectedItems(): any[] {
    return this._selection.selected;
  }

  get unselectedItems(): any[] {
    return this._data.filter((value) => !this._selection.isSelected(value));
  }

  headers: ListHeaderComponent[] = [];

  isSortClicked: boolean = false;

  sortMenuOpen = false;

  sortMenuId = 'sortMenuId' + Math.random().toString(36).substring(2, 9);

  private _filterPredicate = new FilterPredicate([]);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.textFilterControl.valueChanges.subscribe((value) => {
      this._textFilterValue = value;
      this.setFilter();
    });

    this.viewData = this._dataSource.filteredData;
    if (this.viewData && this.viewData.length) {
      this.hadData = true;
    }

    this.paginate(
      0,
      this.paginatorEnabled || this.overlay ? this.pageSize : this._maxPageSize
    );

    if (this.DefaultSortColumn != '') {
      this.sortValue = this.DefaultSortColumn;
      this.sortDir = this.DefaultSortDir;
      this.isSortClicked = true;
      this.setFilter();
    }
  }

  updateDataSource() {
    this._dataSource.data = this._data;
    this._dataSource.filterPredicate = this._filterPredicate.textPredicate.bind(
      this._filterPredicate
    );
    if (this.overlay || this.paginatorEnabled) {
      this.paginate(0, this.pageSize);
    } else {
      this.paginate(0, this._maxPageSize);
    }
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.pageSize = this.paginator.pageSize;
    }
    this.scrollVisible();
  }

  addHeader(header: ListHeaderComponent) {
    this.headers.push(header);
    this._filterPredicate.filters.push(header.filter);
  }

  getFilters(): Filter[] {
    return this.headers.map((header) => header.filter);
  }

  setSort(sortValue: string, sortDir: SortDirection, names: any = ['']) {
    if (!this.sortTest.length && this._dataSource.filteredData.length) {
      this.sortTries = 0;
      this.sortTest = JSON.stringify(this._dataSource.filteredData[0]);
    }
    this.sortValue = sortValue;
    this.sortDir = sortDir;
    this.isSortClicked = true;
    this.setFilter();
    this.updateDataSource();
    if (
      this.sortTries < 2 &&
      this.sortTest.length &&
      (this.sortTest === '' ||
        this.sortTest === JSON.stringify(this._dataSource.filteredData[0])) // here its failing in certifications tab when 1st time clicked
    ) {
      if (names.length > this.sortTries) {
        sortValue = names[this.sortTries];
      }
      this.sortTries++;
      this.setSort(sortValue, sortDir === 'asc' ? 'desc' : 'asc', names);
    } else {
      this.sortTest = '';
    }
    this.updateSortAriaLabel(sortValue, sortDir);
    this.updateSortAnnouncement(sortValue, sortDir);
  }

  toggleSort() {
    this.sortMenuOpen = !this.sortMenuOpen;
    //console.log(this.sortMenuOpen);
  }

  pushDistinctArray(original: Array<any>, additions: Array<any>) {
    additions.forEach((_) => {
      if (!original.includes(_)) {
        original.push(_);
      }
    });
  }

  pushDistinctString(original: Array<any>, additions: string) {
    if (!original.includes(additions)) {
      original.push(additions);
    }
  }

  setFilter() {
    this._dataSource.filter = this.textFilter ? this._textFilterValue : '';
    this.updateDataSource();
    this._dataSource.filteredData = this._dataSource.data;

    if (this._dataSource.filter && this._dataSource.data.length) {
      const props: string[] = [];
      for (let h = 0; h < this.headers.length; h++) {
        if (this.headers[h].name) {
          this.pushDistinctString(props, this.headers[h].name);
        }
        if (this.headers[h].names) {
          this.pushDistinctArray(props, this.headers[h].names);
        }
      }

      const filtered: Array<any> = [];
      let dateAsString: string;
      try {
        dateAsString = new DatePipe('en-US').transform(
          this._dataSource.filter,
          'yyyy-MM-dd'
        );
      } catch (e) {
        dateAsString = null;
      }

      for (let p = 0; p < props.length; p++) {
        try {
          let temp = this._dataSource.data.filter((_) => {
            return `${_[props[p]]}`
              ?.toLowerCase()
              ?.includes(this._dataSource.filter.toLowerCase());
          });
          this.pushDistinctArray(filtered, temp);
          if (dateAsString) {
            temp = [];
            for (const item of this._dataSource.data) {
              const value = item[props[p]];
              if (value) {
                // comparing dates as iso strings.
                const dateValue = new Date(value?.toString());
                if (
                  !isNaN(dateValue.getTime()) &&
                  new DatePipe('en-US')
                    .transform(dateValue, 'yyyy-MM-dd')
                    .startsWith(dateAsString)
                ) {
                  temp.push(item);
                }
              }
            }

            this.pushDistinctArray(filtered, temp);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      this._dataSource.filteredData = filtered;
      const filterText =
        this._dataSource.filteredData.length == 1
          ? this._dataSource.filteredData.length + ' result filtered'
          : this._dataSource.filteredData.length + ' results filtered';
      srSpeak(filterText, 'polite');
    }

    this._dataSource.filteredData = this._dataSource.filteredData.sort(
      (a, b) => {
        if (!this.sortValue) {
          return 0;
        }

        // sortvalues have to be converted in cases where detail data has null values
        if (!a[this.sortValue] || !b[this.sortValue]) {
          switch (this.sortValue) {
            case 'description':
            case 'claimFullDescription':
              this.sortValue = 'claimNumber';
              break;
            case 'claimLabel':
              this.sortValue = 'claimLabel';
              break;
            case 'lastName':
              this.sortValue = 'name';
              break;
            case 'benefitBegin':
            case 'detail':
              this.sortValue = 'fromDate';
              break;
            case 'benefitEnd':
              this.sortValue = 'thruDate';
              break;
            default:
          }
        }

        const aVal =
          typeof a[this.sortValue] === 'string'
            ? a[this.sortValue]?.trimStart()
            : a[this.sortValue];
        const bVal =
          typeof b[this.sortValue] === 'string'
            ? b[this.sortValue]?.trimStart()
            : b[this.sortValue];
        let result: number = 0;
        if (aVal instanceof Date && bVal instanceof Date) {
          result = aVal.getTime() - bVal.getTime();
        } else if (typeof aVal === 'number' && typeof bVal === 'number') {
          result = <number>aVal - <number>bVal;
        } else {
          result = (aVal == null || undefined ? '' : aVal)
            .toString()
            .localeCompare(bVal == null || undefined ? '' : bVal);
        }

        return result * (this.sortDir === 'asc' ? 1 : -1);
      }
    );

    if (this.paginator) {
      this.paginator.firstPage();
      if (this.selectedPageSize) {
        this.paginator.pageSize = this.selectedPageSize;
        this.paginate(this.paginator.pageIndex, this.selectedPageSize);
      } else {
        this.paginate(this.paginator.pageIndex, this.paginator.pageSize);
      }
    } else {
      this.paginate(
        0,
        this.paginatorEnabled ? this.pageSize : this._maxPageSize
      );
    }

    this.updateSearchAnnouncement(
      this._textFilterValue,
      this._dataSource.filteredData.length
    );
  }

  pageChange(event: PageEvent) {
    this.selectedPageSize = event.pageSize;
    this.paginate(event.pageIndex, event.pageSize);
  }

  paginate(pageIndex: number, pageSize: number) {
    if (!this._data) {
      return;
    }

    const startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    if (endIndex > this._data.length) {
      endIndex = this._data.length;
    }
    this.viewData = [];
    this.viewData = this._dataSource.filteredData.slice(startIndex, endIndex);
  }

  getSortLabel(): string {
    if (!this.sortValue) {
      if (this.DefaultSortColumn != '') {
        const header = this.headers.find(
          (x) => x.name === this.DefaultSortColumn
        );
        return header ? header.sortLabelComputed : 'Sort by';
      } else {
        return 'Sort by';
      }
    } else {
      const header = this.headers.find((x) => x.name === this.sortValue);
      return header ? header.sortLabelComputed : 'Sort by';
    }
  }

  toggleAllRows() {
    this.isAllSelected()
      ? this._selection.clear()
      : this._data.forEach((row) => this._selection.select(row));
  }

  isAllSelected() {
    const numSelected = this._selection.selected.length;
    const numRows = this._data.length;
    return numSelected == numRows;
  }

  showPaginator(): boolean {
    if (
      !this.paginatorEnabled ||
      this._data?.length <= this.pageSizeOptions[0]
    ) {
      return false;
    } else {
      return true;
    }
  }

  toggleFilter() {
    this.filterShowEdit = !this.filterShowEdit;
    if (!this.filterShowEdit) {
      this.textFilterControl.setValue(null);
    }
  }

  scrollVisible() {
    setTimeout(() => {
      const el = this.overflowContainer?.nativeElement;
      if (el) {
        this._isScrollVisible = el.scrollHeight > el.clientHeight;
      } else {
        this._isScrollVisible = false;
      }
    }, 0);
  }

  scrollTo(item: any) {
    const index = this.viewData?.indexOf(item);
    if (index >= 0) {
      const element = this.rowContainer?.nativeElement.querySelector(
        '[list-index="' + index + '"]'
      );
      element?.scrollIntoView();
    }
  }

  updateSortAnnouncement(sortValue: string, sortDir: SortDirection) {
    const header = this.headers.find((x) => x.name === sortValue);
    const sortLabel = header ? header.sortLabelComputed : sortValue;
    this.announceMessage = `List sorted by ${sortLabel} in ${
      sortDir === 'asc' ? 'ascending' : 'descending'
    } order,`;
  }

  updateSortAriaLabel(sortValue: string, sortDir: SortDirection) {
    const header = this.headers.find((x) => x.name === sortValue);
    const sortLabel = header ? header.sortLabelComputed : sortValue;
    const direction =
      sortDir === 'asc'
        ? 'ascending'
        : sortDir === 'desc'
        ? 'descending'
        : 'none';
    this.sortButtonAriaLabel = `Select a column header for sorting, sorted ${sortLabel} in ${direction} order`;
  }

  updateSearchAnnouncement(value: string, count: number) {
    this.searchAnnounceMessage = `${count} Results ${
      value ? 'for ' + value : 'No filter applied'
    }`;
  }
}
