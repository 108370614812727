import { Component, OnInit } from '@angular/core';
import {
  DetailSearchConfiguration,
  OmniApi,
  SearchRequest,
} from 'src/app/my-sedgwick';
import {
  BaseComponent,
  LoadingState,
  MessageBus,
  NewClaim,
  UserActionService,
} from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';

@Component({
  selector: 'app-new-claims-page',
  template: `
    <app-section>
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        title="New claims"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
      >
        <div class="tw-pb-2 app-color-input-grey tw-text-base">
          New claims past 30 days
        </div>
        <div class="tw-min-h-[510px] tw-w-full">
          <app-detail-search-form
            #searchForm
            [config]="config"
            (search)="search($event)"
            [loading]="loading"
          >
            <ng-template #results>
              <app-search-count-row
                [count]="newClaims.length"
              ></app-search-count-row>
              <app-new-claims
                [newClaims]="newClaims"
                class="tw-w-full"
                (export)="searchForm.appendExport($event)"
              />
            </ng-template>
          </app-detail-search-form>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: ``,
})
export class NewClaimsPageComponent extends BaseComponent implements OnInit {
  config = new DetailSearchConfiguration();

  mgrAction: UserActions | null;

  newClaims: NewClaim[];

  count: number;

  loading: LoadingState;

  request: SearchRequest;

  constructor(
    private omniApi: OmniApi,
    private userAction: UserActionService,
    private messageBus: MessageBus
  ) {
    super();
    this.config.key = 'omni-dashboard:new-claims';
    this.config.ssn.visible = false;
    this.config.supervisor.visible = false;
    this.config.includeIndirect.visible = false;
    this.config.employeeId.widthPercent = 25;
    this.config.initialSearch = true;
    this.config.initialSearchResultsOnClear = true;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = true;
    this.config.expanded = false;
    this.config.status.visible = false;
    this.config.payType.visible = false;
  }

  async ngOnInit() {
    this.mgrAction = UserActions.ViewOwnMgrDb;
    this.userAction.setAction(this.mgrAction);
    this.subs.sink = this.messageBus.topic('alert-action').subscribe(() => {
      this.search(this.request);
    });
  }

  async search(request: SearchRequest) {
    this.request = request;
    this.loading = 'Loading';
    this.newClaims = await this.omniApi.getNewClaims(request.getParameters());
    this.loading = this.newClaims.length > 0 ? 'Loaded' : 'Empty';
  }
}
