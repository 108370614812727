import { Component, OnInit } from '@angular/core';
import {
  ConfigurationService,
  DetailSearchConfiguration,
  IncidentalAbsenceSearch,
  InpageAliasService,
  OmniApi,
  SearchRequest,
} from 'src/app/my-sedgwick';
import { LoadingState } from 'src/app/shared';

@Component({
  selector: 'app-off-work-page',
  template: `
    <app-section>
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [title]="employeeTitle"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
      >
        <div class="tw-min-h-[510px] tw-w-full">
          <app-tab-group [hideSingleTab]="true" [selectedIndex]="0">
            <app-tab label="Claims" [template]="offWorkClaims">
              <ng-template #offWorkClaims>
                <div class="tw-pt-4" *ngIf="showAbsences"></div>
                <app-detail-search-form
                  #searchForm
                  [config]="config"
                  (search)="search($event)"
                  [loading]="loading"
                >
                  <ng-template #results>
                    <app-search-count-row
                      [count]="offWorks.length"
                      [detail]="employeeLabel"
                    ></app-search-count-row>
                    <app-off-work
                      [offWork]="offWorks"
                      class="tw-w-full"
                      (export)="searchForm.appendExport($event)"
                    />
                  </ng-template>
                </app-detail-search-form>
              </ng-template>
            </app-tab>
            <app-tab
              label="Absences"
              [badge]=""
              [visible]="showAbsences"
              [template]="offWorkAbsences"
            >
              <ng-template #offWorkAbsences>
                <app-absence-case-search
                  [initialSearch]="true"
                  [initialValue]="absenceSearch"
                ></app-absence-case-search>
              </ng-template>
            </app-tab>
          </app-tab-group>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: ``,
})
export class OffWorkPageComponent implements OnInit {
  config = new DetailSearchConfiguration();

  loading: LoadingState;

  offWorks: any[];

  employeeLabel: string;

  employeeTitle: string = '';

  showAbsences = false;

  absenceSearch = new IncidentalAbsenceSearch();

  constructor(
    private omniApi: OmniApi,
    private aliases: InpageAliasService,
    private configuration: ConfigurationService
  ) {
    this.config.key = 'omni-dashboard:off-work';
    this.config.setVisibility(false);
    this.config.firstName.visible = true;
    this.config.lastName.visible = true;
    this.config.employeeId.visible = true;
    this.config.initialSearch = true;
    this.config.initialSearchResultsOnClear = true;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = true;
    this.config.expanded = false;
    this.config.claimNumber.visible = true;
    this.config.payType.visible = false;
  }

  async ngOnInit() {
    const employeeAlias = await this.aliases.get('employeePlural');
    this.omniApi.getOmniDashboard().then((dashboard) => {
      this.employeeTitle =
        dashboard.employeesOffWork + ' ' + employeeAlias + ' off work';
    });
    this.showAbsences = this.configuration.getConfigFeatureAccess(
      'IncidentalAbsences5'
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.absenceSearch.startDate = today;
    this.absenceSearch.endDate = today;
  }

  async search(request: SearchRequest) {
    this.loading = 'Loading';
    this.offWorks = await this.omniApi.getOffWorks(request.getParameters());
    this.loading = this.offWorks.length > 0 ? 'Loaded' : 'Empty';
  }
}
