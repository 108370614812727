import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type } from 'os';
import { SnackbarService, SnackbarType } from 'src/app/shared';

@Component({
  selector: 'app-send-document-dialog',
  template: `
    <div class="tw-min-w-[400px] app-p2">
      <div>
        <app-action-row>
          <span left1 class="app-header-2">{{ title }}</span>
          <div right1 style="text-align: left">
            <button mat-icon-button (click)="dialogRef.close()" tabindex="0">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </app-action-row>
      </div>

      <div class="app-p2">
      <div class="app-pb2">
      <b>Send document to the following recipient.</b>
    </div>
    <form [formGroup]="formGroup">
    <app-radio-button-control
      formControlName="type"
      [formGroup]="formGroup"
      [items]="['email', 'fax']"
      ngDefaultControl
      class="tw-w-full"
      ariaLabel="type"
    ></app-radio-button-control>
    <div class="app-pb2"></div>
    <app-text-control
      formControlName="fax"
      [formGroup]="formGroup"
      *ngIf="formGroup.controls.type.value === 'fax'"
      label="Fax"
      mask="000-000-0000"
      [maxLength]="12"
      ngDefaultControl
      class="tw-w-full"
    ></app-text-control>

    <app-text-control
      formControlName="email"
      [formGroup]="formGroup"
      *ngIf="formGroup.controls.type.value === 'email'"
      label="Email"
      ngDefaultControl
      class="tw-w-full"
    ></app-text-control>
  </form>
        </div>

        <app-action-row>
        <app-button emphasis="High" right2 (click)="send()">Send</app-button>
        <app-button emphasis="Low" right1 (click)="dialogRef.close()"
          >Cancel</app-button
        >
      </app-action-row>
    </div>
  `,
  styles: [],
})
export class SendDocumentDialogComponent {
  @Input()
  title: string = 'Send Document';

  formGroup = this.fb.group({
    type: ['email'],
    fax: [, Validators.required],
    email: [, Validators.required, Validators.email],
  });

  constructor(
    public dialogRef: MatDialogRef<SendDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackbar: SnackbarService
  ) {}

  send() {
    this.snackbar.show('Document has been sent.', SnackbarType.Success);
    this.dialogRef.close();
  }
}
